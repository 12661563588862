import React, { FC, useCallback, useEffect, useState } from "react";

//!ANT DESIGN
import {
  ArrowLeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Steps,
  message,
} from "antd";
import * as CHAPTERS_API from "api/services/chapters/chapters.services";
import { CreateCourse } from "api/services/courses/courses.interface";
import * as appRoutes from "config/routes.config";
import * as TOPICS_SERVICES from "../../../api/services/topics/topics.services";

import "katex/dist/katex.min.css";
import Latex from "react-latex-next";

import { HandlerInputError, StatusCode, StatusEntity } from "common/enums";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { CKEditor } from "ckeditor4-react";
import Breadcrumbs from "components/breadcrumbs";
import HelpPopover from "components/help";
import AppButton from "components/libs/button";
import AppSelectGroup from "components/libs/selectGroup";
import H2 from "components/libs/subtitle";
import H1 from "components/libs/title";
import MathScan from "components/maths";
import UploadCloudinary from "components/uploadCloudinary";
import AppScreen from "../ScreenWithSidebar";
import s from "./assets/createCours.module.css";
import TableCours from "./table";

const { Step } = Steps;

const TeacherCreateCours: FC = () => {
  const { user } = useSelector((state) => state.auth);
  //!HOOKS
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  //!STATE
  const [coursesOptions, setCoursesOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [addCourseLoading, setAddCourseLoading] = useState(false);
  const [courses, setCourses] = useState<any>({});
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [firstStepData, setFirstStepData] = useState<any>({});
  const [display, setDisplay] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [tempTopic, setTempTopic] = useState<any>();
  const [tempForm, setTempForm] = useState<any>([]);
  const [isAddingCourseBrouillon, setIsAddingCourseBrouillon] =
    useState<boolean>(false);

  const [first, setfirst] = useState("1");
  const [isAddingCourse, setIsAddingCourse] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [isloadingTopic, setIsloadingTopic] = useState<boolean>(false);
  const [localData, setLocalData] = useState<any>({});

  //!FUNCTION
  const { Search } = Input;
  const { Option } = Select;

  const createCourse = useCallback(async (course: CreateCourse) => {
    try {
      setCourses(course);
      setCurrentStep((curr) => curr + 1);
    } catch (error) {
      console.log(error);
    } finally {
      setAddCourseLoading(false);
    }

    localStorage.setItem("firstStepTempData", JSON.stringify(course));
  }, []);

  const successMessageCopy = () => {
    message.success("Copier avec succès");
  };
  const createChapters = async (values: any) => {
    const createStatus =
      first === "1" ? StatusEntity.PENDING : StatusEntity.DRAFT;

    try {
      const response = await CHAPTERS_API.create({
        ...values,
        status: createStatus,
      });

      if (response?.statusCode === StatusCode.CREATED) {
        const successMessage =
          first === "1"
            ? "Cours créé avec succès"
            : "Cours enregistré dans les brouillons";

        message.success(successMessage);

        localStorage.removeItem("firstStepTempData");
        localStorage.removeItem("cours");

        navigate(appRoutes.TEACHER_COURSES);
      } else {
        message.warning("Une erreur est survenue lors de la création du cours");
      }
    } catch (error) {
      console.error(error);
      message.error("Une erreur est survenue lors de la création du cours");
    } finally {
      if (first === "1") {
        setIsAddingCourse(false);
      } else {
        setIsAddingCourseBrouillon(false);
      }
    }
  };

  const handleTempForm = useCallback((_: any, allFields: any) => {
    let handleError = allFields;
    let content = allFields
      .filter((field: any) => {
        return field.name.join() === "contents";
      })
      .map((c: any) => c.value);
    setTempForm(content);

    localStorage.setItem("cours", JSON.stringify(content));

    handleError?.filter((x: any) => {
      if (x?.errors[0] === HandlerInputError.title) {
        message.warn(HandlerInputError.title);
      } else if (x?.errors[0] === HandlerInputError.subtitles) {
        message.warn(HandlerInputError.subtitles);
      } else if (x?.errors[0] === HandlerInputError.section) {
        message.warn(HandlerInputError.section);
      } else if (x?.errors[0] === HandlerInputError.content) {
        message.warn(HandlerInputError.content);
      }
    });
  }, []);

  const showLargeDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const OnSelectLevel = useCallback(
    async (levelId: string) => {
      setIsloadingTopic(true);
      try {
        const response = await TOPICS_SERVICES.findAll(levelId);
        if (response?.statusCode === StatusCode.OK) {
          setTempTopic(response?.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsloadingTopic(false);
      }
    },
    [tempTopic]
  );

  useEffect(() => {
    setLoader(true);

    let firstStep = JSON.parse(
      localStorage.getItem("firstStepTempData") || "{}"
    );
    let data = JSON.parse(localStorage.getItem("cours") || "{}");

    setLocalData({ contents: data[0] });
    setTempForm(data);
    setCourses(firstStep);
    setLoader(false);
  }, [createCourse]);

  return (
    <React.Fragment>
      <Breadcrumbs isCreateCourse={true} isBack={true} />

      {currentStep === 1 && (
        <AppScreen className={s.cours}>
          <div style={{ position: "relative", top: "10px", marginLeft: "95%" }}><HelpPopover id={"creer_un_cours"} /></div>
          <H1>Créer un cours</H1>
          <div className={s.teacher__maa} />
          <div className={s.form__container}>
            <Form autoComplete="off" form={form} onFinish={createCourse}>
              <Form.Item>
                <Steps current={currentStep}>
                  <Step />
                  <Step />
                </Steps>
              </Form.Item>

              <div className={`${s.select}`}>
                <Form.Item
                  name="levelId"
                  style={{ marginBottom: 0 }}
                  rules={[
                    {
                      required: true,
                      message: "Ce champ est requis",
                    },
                  ]}
                >
                  <AppSelectGroup
                    placeholder="Choisir un niveau"
                    onChange={(value: string) => OnSelectLevel(value)}
                  />
                </Form.Item>
              </div>

              <div className={`${s.select}`}>
                <Form.Item
                  name="courseId"
                  style={{ marginBottom: 0 }}
                  rules={[
                    {
                      required: true,
                      message: "Ce champ est requis",
                    },
                  ]}
                >
                  <Select
                    placeholder="Choisir une matière"
                    allowClear
                    loading={isloadingTopic}
                    style={{ marginBottom: "var(--mb-3)" }}
                    bordered={false}
                  >
                    {tempTopic?.map((topic: any) => (
                      <Option key={topic._id} value={topic._id}>
                        {topic.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div className={s.field}>
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Ce champ est requis",
                    },
                  ]}
                >
                  <Input
                    bordered={false}
                    type="text"
                    placeholder={"Titre du cours "}
                  />
                </Form.Item>
              </div>

              <Row justify="center" style={{ marginTop: "1.5em" }}>
                <Form.Item>
                  <AppButton htmlTypeSubmit={true} loading={addCourseLoading}>
                    suivant
                  </AppButton>
                </Form.Item>
              </Row>
            </Form>
          </div>
        </AppScreen>
      )}

      {currentStep === 2 && (
        <div style={{ overflow: "hidden" }}>
          <AppScreen>
            <div className={s.header}>
              <div className={s.left}>
                <H2 className={s.subtitle}>
                  Titre du cours : {courses?.title}
                </H2>
              </div>

              <div className={s.center} />

              <div style={{ position: "relative", top: "0px", marginRight: "10px" }}><HelpPopover id={"creer_un_cours_apercu"} /></div>

              <div className={s.right}>
                <AppButton
                  // onClick={() => navigate(appRoutes.TEACHER_COURSES)}
                  onClick={() => setCurrentStep((curr) => curr - 1)}
                  icon={<ArrowLeftOutlined />}
                >
                  Retour
                </AppButton>
              </div>
            </div>

            <div className={s.main}>
              <Form
                form={form}
                name="dynamic_form_item"
                onFieldsChange={handleTempForm}
                onFinish={createChapters}
                initialValues={localData}
              >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={12} className={s.____left}>
                    <Form.Item
                      name="title"
                      hidden
                      initialValue={courses?.title}
                      noStyle
                    >
                      <Input
                        bordered={false}
                        className={s.field}
                        value={courses?.title}
                        placeholder="titre"
                      />
                    </Form.Item>

                    <Form.Item name="description" hidden>
                      <Input className={s.field} placeholder="description" />
                    </Form.Item>

                    <Form.Item
                      name="language"
                      hidden
                      initialValue={courses?.language}
                    >
                      <Input className={s.field} placeholder="language" />
                    </Form.Item>

                    <Form.List name="contents">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }, h1Key) => (
                            <div className={s.__list__input}>
                              <div className={s.field}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "title"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: HandlerInputError.title,
                                    },
                                  ]}
                                >
                                  <Input
                                    bordered={false}
                                    placeholder="Ajouter un grand titre"
                                  />
                                </Form.Item>
                              </div>

                              <Form.Item
                                {...restField}
                                name={[name, "type"]}
                                className={s.__input__type}
                                initialValue="h1"
                              ></Form.Item>
                              <MinusCircleOutlined
                                style={{
                                  position: "absolute",
                                  right: "-18px",
                                  top: "15px",
                                }}
                                onClick={() => remove(name)}
                              />

                              <Form.Item name={[name, "subtitles"]}>
                                <Form.List name={[name, "subtitles"]}>
                                  {(fields, { add, remove }) => (
                                    <>
                                      {fields?.map(
                                        (
                                          { key, name, ...restField },
                                          h2Key
                                        ) => (
                                          <div
                                            style={{ position: "relative" }}
                                            key={key}
                                          >
                                            <div className={s.field}>
                                              <Form.Item
                                                {...restField}
                                                name={[name, "title"]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      HandlerInputError.subtitles,
                                                  },
                                                ]}
                                              >
                                                <Input
                                                  bordered={false}
                                                  placeholder="Ajouter un sous titre"
                                                />
                                              </Form.Item>
                                            </div>

                                            <Form.Item
                                              {...restField}
                                              className={s.__input__type}
                                              name={[name, "type"]}
                                              initialValue="h2"
                                            ></Form.Item>
                                            <MinusCircleOutlined
                                              style={{
                                                position: "absolute",
                                                right: "-18px",
                                                top: "14px",
                                              }}
                                              onClick={() => remove(name)}
                                            />

                                            <Form.Item
                                              name={[name, "subtitles"]}
                                            >
                                              <Form.List
                                                name={[name, "subtitles"]}
                                              >
                                                {(fields, { add, remove }) => (
                                                  <>
                                                    {fields.map(
                                                      (
                                                        {
                                                          key,
                                                          name,
                                                          ...restField
                                                        },
                                                        sectionKey
                                                      ) => (
                                                        <div
                                                          key={key}
                                                          style={{
                                                            position:
                                                              "relative",
                                                          }}
                                                        >
                                                          <div
                                                            className={s.field}
                                                          >
                                                            <Form.Item
                                                              {...restField}
                                                              name={[
                                                                name,
                                                                "title",
                                                              ]}
                                                              rules={[
                                                                {
                                                                  required:
                                                                    true,
                                                                  message:
                                                                    HandlerInputError.section,
                                                                },
                                                              ]}
                                                            >
                                                              <Input
                                                                bordered={false}
                                                                placeholder=" Ajouter une section"
                                                              />
                                                            </Form.Item>
                                                          </div>

                                                          <div
                                                            className={
                                                              s.__custom__ck_editor
                                                            }
                                                          >
                                                            <Space>
                                                              <UploadCloudinary />
                                                              <MathScan />
                                                            </Space>

                                                            <Form.Item
                                                              name={[
                                                                name,
                                                                "content",
                                                              ]}
                                                              valuePropName="data"
                                                              getValueFromEvent={(
                                                                event
                                                              ) => {
                                                                const data =
                                                                  event?.editor?.getData();
                                                                console.log(
                                                                  data,
                                                                  "data ckEditor"
                                                                );
                                                                return data;
                                                              }}
                                                              rules={[
                                                                {
                                                                  required:
                                                                    true,
                                                                  message:
                                                                    HandlerInputError.content,
                                                                },
                                                              ]}
                                                            >
                                                              <CKEditor
                                                                initData={
                                                                  localData?.contents
                                                                    ? localData
                                                                      ?.contents[
                                                                      h1Key
                                                                    ]
                                                                      ?.subtitles[
                                                                      h2Key
                                                                    ]
                                                                      ?.subtitles[
                                                                      sectionKey
                                                                    ]?.content
                                                                    : ""
                                                                }
                                                                config={{
                                                                  language: 'fr',
                                                                }}
                                                                style={{
                                                                  border:
                                                                    "none",
                                                                }}
                                                              />
                                                            </Form.Item>
                                                          </div>

                                                          <Form.Item
                                                            {...restField}
                                                            name={[
                                                              name,
                                                              "type",
                                                            ]}
                                                            className={
                                                              s.__input__type
                                                            }
                                                            initialValue="section"
                                                          ></Form.Item>
                                                          <MinusCircleOutlined
                                                            style={{
                                                              position:
                                                                "absolute",
                                                              right: "-20px",
                                                              top: "15px",
                                                            }}
                                                            onClick={() =>
                                                              remove(name)
                                                            }
                                                          />
                                                        </div>
                                                      )
                                                    )}
                                                    <Form.Item>
                                                      <Button
                                                        className={
                                                          s.__add__input__button
                                                        }
                                                        onClick={() => {
                                                          add();
                                                        }}
                                                        block
                                                        icon={<PlusOutlined />}
                                                      >
                                                        ajouter une section
                                                      </Button>
                                                    </Form.Item>
                                                  </>
                                                )}
                                              </Form.List>
                                            </Form.Item>
                                          </div>
                                        )
                                      )}
                                      <Form.Item>
                                        <Button
                                          className={s.__add__input__button}
                                          onClick={() => {
                                            add();
                                          }}
                                          block
                                          icon={<PlusOutlined />}
                                        >
                                          ajouter un sous-titre
                                        </Button>
                                      </Form.Item>
                                    </>
                                  )}
                                </Form.List>
                              </Form.Item>
                            </div>
                          ))}
                          <Form.Item>
                            <Button
                              className={s.__add__input__button}
                              onClick={() => {
                                add();
                                setDisplay(true);
                              }}
                              block
                              icon={<PlusOutlined />}
                            >
                              Ajouter un Grand titre
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Col>

                  <Drawer
                    title={null}
                    placement="right"
                    onClose={onClose}
                    width="70%"
                    visible={visible}
                    footer={false}
                  >
                    <div style={{ overflow: "hidden" }}>
                      <div className={s.appercue__continute}>
                        <h3>Aperçu : {courses?.title}</h3>
                        {tempForm[0]?.map((field: any, i: number) => (
                          <div key={i}>
                            <div className={s.__big__title__content}>
                              <h3 className={s.__big__title}>
                                {i + 1} - {field?.title}
                              </h3>
                            </div>

                            <div className={s.appercue__continute__content}>
                              {field?.subtitles?.map((x: any) => (
                                <>
                                  <h4>{x?.title}</h4>
                                  {x?.subtitles?.map((item: any) => (
                                    <>
                                      <div className={s.section__content}>
                                        <h4>{item?.title}</h4>
                                      </div>

                                      <div className={s.__latext__content}>
                                        <Latex>
                                          {item?.content
                                            ? item?.content
                                              ?.replaceAll("amp;", "")
                                              ?.replaceAll("<br />", "")
                                            : ""}
                                        </Latex>
                                      </div>
                                    </>
                                  ))}
                                </>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Drawer>

                  <Col span={12}>
                    <div className={s.appercue__continute}>
                      <h3>Aperçu : {courses?.title}</h3>
                      {tempForm[0]?.map((field: any, i: number) => (
                        <div key={i}>
                          <div className={s.__big__title__content}>
                            <h3 className={s.__big__title}>
                              {i + 1} - {field?.title}
                            </h3>
                          </div>

                          <div className={s.appercue__continute__content}>
                            {field?.subtitles?.map((x: any) => (
                              <>
                                <h4>{x?.title}</h4>
                                {x?.subtitles?.map((item: any) => (
                                  <>
                                    <div className={s.section__content}>
                                      <h4>{item?.title}</h4>
                                    </div>

                                    {console.log(
                                      item?.content
                                        ?.split(" ")
                                        ?.filter((x: any) => x != "&amp;")
                                        ?.join(),
                                      "item?.content"
                                    )}

                                    <div className={s.__latext__content}>
                                      <Latex>
                                        {item?.content
                                          ? item?.content
                                            ?.replaceAll("amp;", "")
                                            ?.replaceAll("<br />", "")
                                          : // ?.split(" ")
                                          // ?.filter((x: any) => x != "&bmp;")
                                          // ?.join()
                                          " "}
                                      </Latex>
                                    </div>
                                  </>
                                ))}
                              </>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>

                <Form.Item name="courseId" initialValue={courses?.courseId}>
                  <Input placeholder="sous titre" hidden />
                </Form.Item>

                <Row justify="center" gutter={12}>
                  <Col>
                    <AppButton
                      icon={<ArrowLeftOutlined />}
                      onClick={() => setCurrentStep((c) => c - 1)}
                    >
                      Précédent
                    </AppButton>
                  </Col>

                  <Col style={{ textAlign: "center" }}>
                    <AppButton
                      className={s.__submit__validation}
                      onClick={() => setVisible(!visible)}
                    >
                      aperçu
                    </AppButton>
                  </Col>

                  <Col style={{ textAlign: "center" }}>
                    <AppButton
                      className={s.__submit__validation}
                      loading={isAddingCourseBrouillon}
                      htmlTypeSubmit={true}
                      onClick={() => setfirst("2")}
                    >
                      enregistrer
                    </AppButton>
                  </Col>

                  <Col style={{ textAlign: "center" }}>
                    <AppButton
                      className={s.__submit__validation}
                      loading={isAddingCourse}
                      htmlTypeSubmit={true}
                      onClick={() => setfirst("1")}
                    >
                      Soumettre pour validation
                    </AppButton>
                  </Col>
                </Row>
                <Form.Item name="teacherId" initialValue={user?._id}>
                  <Input placeholder="sous titre" hidden />
                </Form.Item>
              </Form>

              <TableCours />
            </div>
          </AppScreen>
        </div>
      )}
    </React.Fragment>
  );
};

export default TeacherCreateCours;
