import { SearchOutlined } from "@ant-design/icons";
import {
  Skeleton,
  Typography
} from "antd";
import {
  Fragment,
  useEffect,
  useState
} from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

//!STYLES
import { isToday } from "common/enums";
import AppButton from "components/libs/button";
import * as appRoutes from "config/routes.config";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetHistoriqueStudentQuery } from "store/services/teacher/classes.services";
import s from "../assets/calendar.module.css";
import CardReservations from "./card";

import EmptyIcon from "../../../../assets/images/app/empty_icon.svg";

const { Text } = Typography;

const CalendarOfReservationStudent = () => {

  const { user } = useSelector((state) => state.auth);
  const { isReservationsUpdated } = useSelector((state) => state.app);
  const [courses, setCourses] = useState<any>([]);
  const {
    data: reservations,
    isLoading,
    isError,
    refetch,
  } = useGetHistoriqueStudentQuery(user?._id);

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(appRoutes.STUDENT_SEARCH_BEST_TEACHER);
  }

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    refetch();
  }, [isReservationsUpdated]);

  useEffect(() => {
    if (reservations?.data) {
      const sortedCourses = [...reservations?.data].sort(
        (a: any, b: any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      );

      const groupedByDate = sortedCourses.reduce((acc: any, course: any) => {
        const date = new Date(course.startDate).toISOString().split("T")[0];
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(course);
        return acc;
      }, {});

      setCourses(groupedByDate);
    }
  }, [reservations]);

  return (
    <div>
      {isLoading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <Fragment>
          <div className={s.calendar}>
            {reservations?.data?.length > 0 ? (
              courses && Object.keys(courses)?.map((date) => {
                return (
                  <>
                    <div key={date} style={{ marginTop: "10px" }}>
                      <div className={s.dateBox}>
                        {new Intl.DateTimeFormat("fr-FR", {
                          weekday: "long",
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        }).format(new Date(date))}
                        {isToday(date) && <div className={s.todayBox}>Aujourd’hui</div>}
                        <div className={s.horizontalLine}></div>
                      </div>
                      {courses[date].map((course: any) => (
                        <div key={course.id}>
                          <CardReservations course={course} isHistorique={false} />
                        </div>
                      ))}
                    </div>
                  </>
                );
              })
            ) : (
              <div className={s.emptyClass}>
                Aucune réservation
                <img src={EmptyIcon} height={120} style={{ marginTop: "20px" }}></img>
                <div style={{ marginBottom: "30px", display: "flex", alignItems: "center", justifyContent: "center", }}>
                  <AppButton icon={<SearchOutlined />} onClick={handleClick}>
                    Trouver un prof
                  </AppButton>
                </div>
              </div>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default CalendarOfReservationStudent;
