import * as AUTH_SERVICES from "api/services/auth/auth.services";
import * as CHALLENGES_SERVICES from "api/services/challenges/challenges.services";
import * as ROOMS_SERVICES from "api/services/rooms/rooms.services";
import Breadcrumbs from "components/breadcrumbs";
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import AppScreen from "screens/teacher/ScreenWithSidebar";

//!STYLES
import styles from "../Quizz/assets/quiz.module.css";
import s from "./style.module.css";

//IMAGES
import { Button, Col, Drawer, Form, Input, Pagination, Rate, Row, Select, Skeleton, Tabs, Tooltip, message } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { StatusCode, setIcon } from "common/enums";
import AppResult from "components/appResults";

import { RedoOutlined } from "@ant-design/icons";

import { getStatsData } from "api/services/studentQuizz/studentQuizz.services";
import ParentOrganismeStudentStats from "components/ParentOrganismeStudentPerformance";
import AppDrawer from "components/appDrawer";
import AppButton from "components/libs/button";
import CardMenu from "components/libs/readyToUse/challengerClasse/card/CardMenu";
import CardMenuLoader from "components/libs/readyToUse/challengerClasse/card/CardMenuLoader";
import AppSelectGroup from "components/libs/selectGroup";
import H2 from "components/libs/subtitle";
import H1 from "components/libs/title";
import {
    Icourses,
    useGetAllQuizzMutation,
} from "store/services/teacher/courses";
import * as TOPICS_SERVICES from "../../../api/services/topics/topics.services";
import Check from "../../../assets/images/app/check.svg";
import { ReactComponent as SearchIcon } from "../../../assets/images/app/search.svg";
import Star from "../../../assets/images/app/start.svg";
import { ReactComponent as ChallengeIcon } from "./assets/images/challenge.svg";

const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;

const TeacherStudentHistory = () => {

    const auth = useSelector((state: RootStateOrAny) => state.auth);
    const { user } = useSelector((state) => state.auth);
    const params = useParams<"id">();
    const navigate = useNavigate();
    const location = useLocation();
    const studentData = location.state;
    const [formInfos] = Form.useForm();

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    const [loading, setLoading] = useState<boolean>(true);
    const [statsData, setStatsData] = useState<any>([]);
    const [userData, setUserData] = useState<any>();
    const [studentId, setStudentId] = useState<any>('');
    const [studentLevelId, setStudentLevelId] = useState<any>('');
    const [page, setPage] = useState<any>("1");

    const [userCourseRate, setCourseRate] = useState<any>(0);
    const [userApplictionRate, setApplictionRate] = useState<any>(0);
    const [userExerciceRate, setExerciceRate] = useState<any>(0);

    const [historyData, setHistoryData] = useState<any>([]);
    const [currentTab, handleTabChange] = useState<string>("1");

    const [rateDrawerVisibility, setRateDrawerVisibility] = useState<boolean>(false);
    const closeRateDrawer = async () => {
        setRateDrawerVisibility(false);
        setCourseDate("");
        setCourseTopic("");
        setCourseRating(0);
        setApplicationRating(0);
        setExerciceRating(0);
    }

    const [courseDate, setCourseDate] = useState<any>('');
    const [courseTopic, setCourseTopic] = useState<any>('');
    const [historyID, setHistoryID] = useState<any>('');

    const openRateDrawer = async (date: any, topic: any, id: any) => {
        setCourseDate(date);
        setCourseTopic(topic);
        setHistoryID(id);
    }

    const [courseRating, setCourseRating] = useState<any>(0);
    const [applicationRating, setApplicationRating] = useState<any>(0);
    const [exerciceRating, setExerciceRating] = useState<any>(0);

    const [generalStatsData, setGeneralStatsData] = useState<any>([]);
    const [radarAvg, setRadarAvg] = useState<any>();

    const handleCourseChange = (value: any) => {
        setCourseRating(value);
    };
    const handleApplicationChange = (value: any) => {
        setApplicationRating(value);
    };
    const handleExerciceChange = (value: any) => {
        setExerciceRating(value);
    };

    const [isDataLoading, setIsLoading] = useState<boolean>(false);

    const [isDrawerChallenge, setIsDrawerChallenge] = useState<boolean>(false);

    const [drawerConfirm, setDrawerConfirm] = useState<boolean>(false);
    const [tempsChallenge, setTempsChallenge] = useState<number>(0);
    const [classe, setClasse] = useState<any>();

    const fetchClasse = useCallback(async (id: string | undefined) => {
        try {
            const response = await ROOMS_SERVICES.findOne(id);
            if (response?.statusCode === StatusCode.OK) setClasse(response.data);
        } catch (error: any) {
            //console.log(error);
        }
    }, []);

    const sendRemarks = async (values: any) => {
        setIsLoading(true);
        try {
            const response = await AUTH_SERVICES.rateStudent(historyID, values?.courseRating, values?.applicationRating, values?.exerciceRating, values?.message);
            if (response?.statusCode === StatusCode.CREATED) {
                message.success({
                    content: "Note envoyée ",
                });
                setIsLoading(false);
                setCourseRate(response?.data['rateCours']);
                setApplictionRate(response?.data['rateApplication']);
                setExerciceRate(response?.data['rateExercice']);
                setRateDrawerVisibility(false);
            }
            else {
                message.warning({
                    content: "Ooops, Une erreur est survenue",
                });
                setIsLoading(false);
            }
        } catch (error: any) {
            let content = "";
            for (
                let index = 0;
                index < error?.response?.data?.message?.length;
                index++
            ) {
                content = error?.response?.data?.message[index] + ", " + content;
            }
            message.error({
                content: content,
            });
        }
        finally {
            getStudentHistoryData();
        }
    }

    const getStudentStatData = async () => {
        try {

            const res = await getStatsData(studentId, studentLevelId);
            const data = res?.data;
            const generalData = data.courseData;

            setGeneralStatsData(generalData);

            if (data.avg !== null) {
                const floatValue: number = data.avg;
                const stringValue: string = floatValue.toFixed(2);
                setRadarAvg(stringValue);
            }

        } catch (error) { }
    }

    const getStudentHistoryData = async () => {
        try {
            const history = await AUTH_SERVICES.getTeacherStudentHistoryData(studentId, user?._id, page);
            if (history?.data) {
                setHistoryData(history['data']['results']);
            } else {
                throw new Error("L'utilisateur n'existe pas");
            }
        } catch (error) { }
        finally {
            setLoading(false);
        }
    }

    const getStudentData = async () => {
        try {
            const finalUser = await AUTH_SERVICES.getUserByUid(params.id!);
            if (finalUser?.data) {
                setUserData(finalUser['data']);
                setCourseRate(finalUser['data']['rateCours']);
                setApplictionRate(finalUser['data']['rateApplication']);
                setExerciceRate(finalUser['data']['rateExercice']);
                setStudentId(finalUser['data']['_id']);
                setStudentLevelId(finalUser['data']['levelId']);
            } else {
                throw new Error("L'utilisateur n'existe pas");
            }
        } catch (error) { }
    }

    const fetchStatsData = async () => {
        try {
            const res = await AUTH_SERVICES.getStudentStats(studentId);
            const data = res?.data?.userStatsData;
            setStatsData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const [tempTopic, setTempTopic] = useState<any>();
    const [currentPage, setcurrentPage] = useState<number>(1);
    const submit = useRef<any>(null);

    const [getAllQuizz, { data, isLoading, isError }] = useGetAllQuizzMutation();

    const [form] = Form.useForm();

    const [hidePanel, setHidePanel] = useState(true);
    const [challenge, setChallenge] = useState<any>();

    const filterQuiz = useCallback(async (values: any) => {
        if (values?.levelId === undefined) {
            values.topicId = undefined
            form.setFieldsValue({
                ['topicId']: undefined,
            });
        }
        await getAllQuizz(values);
    }, []);

    const onReset = () => {
        form.resetFields();
        getAllQuizz({
            title: "",
            levelId: "",
            topicId: "",
            status: "",
            self: false,
            page: 1,
            duration: "",
            quizzLevel: ""
        } as Icourses);
    };

    const OnSelectLevel = useCallback(
        async (levelId: any) => {
            if (levelId) {
                const response = await TOPICS_SERVICES.findAll(levelId);
                if (response?.statusCode === StatusCode.OK) {
                    setTempTopic(response?.data);
                    form.setFieldsValue({
                        ['topicId']: undefined,
                    });
                    //call static get quizz
                    getAllQuizz({
                        title: form.getFieldValue('title') === undefined ? "" : form.getFieldValue('title'),
                        levelId: levelId,
                        topicId: "",
                        status: "",
                        self: false,
                        page: currentPage,
                        duration: form.getFieldValue('duration') === undefined ? "" : form.getFieldValue('duration'),
                        quizzLevel: form.getFieldValue('quizzLevel') === undefined ? "" : form.getFieldValue('quizzLevel')
                    } as Icourses);
                }
            }
        },
        [tempTopic]
    );

    const OnSubmitChallengeHandler = (infos: any) => {
        setHidePanel(!hidePanel);
        setChallenge(infos);
    };

    const fetchChallengesByIdClass = useCallback(
        async (id: string | undefined) => {
            try {
                const response = await CHALLENGES_SERVICES.findByIdClasse(id);
                if (response?.statusCode === StatusCode.OK) {
                    setHidePanel(!hidePanel);
                    setChallenge(response?.data);
                }
            } catch (error: any) { }
        },
        []
    );

    useEffect(() => {
        getAllQuizz({
            title: "",
            levelId: "",
            topicId: "",
            status: "",
            self: false,
            page: currentPage,
            duration: "",
            quizzLevel: ""
        } as Icourses);
        window.scrollTo(0, 0);
    }, [currentPage]);

    /* useEffect(() => {
      if (window.location.pathname.includes("challenge"))
        setIsDrawerChallenge(true);
      fetchClasse(id);
  
      fetchChallengesByIdClass(id);
    }, []); */

    useEffect(() => {
        getStudentData();
    }, [params]);

    useEffect(() => {
        if (studentId !== '' && studentLevelId !== '') {
            fetchStatsData();
            getStudentStatData();
            getStudentHistoryData();
        }
    }, [studentId, studentLevelId]);

    useEffect(() => {
        if (courseDate !== "" && courseTopic !== "" && historyID !== "") {
            setRateDrawerVisibility(true);
        }
    }, [courseDate, courseTopic, historyID]);

    return (
        <React.Fragment>
            <Breadcrumbs isStudents isBack={true} />
            <AppScreen>
                <div style={{ display: "flex", flexDirection: "column", margin: "0px", padding: "0px", position: "relative", top: "-30px" }}>
                    {loading ? <Skeleton active /> : <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", height: "100px", marginTop: "40px" }}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <img src={userData['profile']} height={80}></img>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontSize: "1.3em", fontWeight: "bold", color: "var(--title-color)", textTransform: "uppercase", marginTop: "10px" }}>{userData['firstname']} {userData['lastname']}</div>
                                {studentData !== null ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: "#D5D5D5", paddingTop: "2.5px", paddingBottom: "2.5px", borderRadius: "8px", width: "100px" }}>
                                    {studentData['levelId']['title']}
                                </div> : <div></div>}
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", marginLeft: "30px" }}>
                                <div className={s.rateBox}>
                                    <div className={s.dashedBox}>
                                        <span style={{ color: "#7FB1B2" }}>Cours</span>
                                        <span className={s.rateBoxSpan}><img src={Star} height={10} className={s.rateBoxStar}></img>{userCourseRate}</span>
                                    </div>
                                    <div className={s.dashedBox}>
                                        <span style={{ color: "#7FB1B2" }}>Application</span>
                                        <span className={s.rateBoxSpan}><img src={Star} height={10} className={s.rateBoxStar}></img>{userApplictionRate}</span>
                                    </div>
                                    <div className={s.dashedBox}>
                                        <span style={{ color: "#7FB1B2" }}>Exercice</span>
                                        <span className={s.rateBoxSpan}><img src={Star} height={10} className={s.rateBoxStar}></img>{userExerciceRate}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", visibility: "hidden" }}>
                            <AppButton
                                className={`${s.__btn__challenge}`}
                                icon={
                                    <ChallengeIcon
                                        style={{
                                            position: "absolute",
                                            top: "4px",
                                            left: "10%",
                                            width: "18px",
                                        }}
                                    />
                                }
                                onClick={() => setIsDrawerChallenge(!isDrawerChallenge)}
                            >
                                Challenger cet élève
                            </AppButton>
                        </div>
                    </div>}
                    <Tabs activeKey={currentTab} onChange={(key) => { handleTabChange(key) }}>
                        <TabPane tab={<span className={currentTab === "1" ? s.currentTab : s.defaultTab}>HISTORIQUE DES COURS</span>} key="1">
                            <div className={s.lineBox}>
                                {loading ? <Skeleton active /> : <div
                                    style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        background: "#fafafa",
                                        marginTop: "20px"
                                    }}
                                >
                                    {historyData.length > 0 ? (
                                        <Fragment>
                                            {historyData.map((historique: any, index: number) => (
                                                <div key={index} style={{ marginBottom: "2em" }} className={s.card}>
                                                    <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }} >
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontSize: "1.2em" }}>
                                                            <div><span style={{ color: "green", fontWeight: "600" }}><img src={Check} height={15}></img> Cours terminé </span>le {historique['date']}</div>
                                                            <div style={{ display: "flex", flexDirection: "row", paddingLeft: "10px", paddingRight: "10px", paddingTop: "5px", paddingBottom: "5px", background: "#D5D5D5", marginLeft: "20px", borderRadius: "8px" }}>
                                                                <img src={setIcon(historique['topic'])} height={30} /><span style={{ position: "relative", top: "2px" }}>{historique['topic']}</span>
                                                            </div>
                                                        </div>
                                                        {historique['noted'] ? <div className={s.rateBox}>
                                                            <div className={s.dashedBox}>
                                                                <span style={{ color: "#7FB1B2" }}>Cours</span>
                                                                <span className={s.rateBoxSpan}><img src={Star} height={10} className={s.rateBoxStar}></img>{historique['rateCours']}</span>
                                                            </div>
                                                            <div className={s.dashedBox}>
                                                                <span style={{ color: "#7FB1B2" }}>Application</span>
                                                                <span className={s.rateBoxSpan}><img src={Star} height={10} className={s.rateBoxStar}></img>{historique['rateApplication']}</span>
                                                            </div>
                                                            <div className={s.dashedBox}>
                                                                <span style={{ color: "#7FB1B2" }}>Exercice</span>
                                                                <span className={s.rateBoxSpan}><img src={Star} height={10} className={s.rateBoxStar}></img>{historique['rateExercice']}</span>
                                                            </div>
                                                        </div> : <button className={s.defaultButton} onClick={() => openRateDrawer(historique['date'], historique['topic'], historique['id'])}>Noter l'élëve sur ce cours</button>}
                                                    </div>

                                                    {historique['comment'] !== "" && <div style={{ fontSize: "1.2em", display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                                        {historique['comment']}
                                                    </div>}
                                                </div>
                                            ))}
                                        </Fragment>
                                    ) : (
                                        <AppResult status={"info"} title="Aucune réservation" />
                                    )}
                                </div>}
                            </div>
                        </TabPane>
                        <TabPane tab={<span className={currentTab === "2" ? s.currentTab : s.defaultTab}>PERFORMANCE</span>} key="2" style={{ marginTop: "30px" }}>
                            {generalStatsData.length > 0 ? (<ParentOrganismeStudentStats userData={userData} statsData={statsData} studentId={studentId} loading={loading} user={user} generalStatsData={generalStatsData} avgGeneral={radarAvg} />) : <div></div>}
                        </TabPane>
                    </Tabs>
                </div>
                <Drawer
                    placement="right"
                    closable={true}
                    onClose={closeRateDrawer}
                    visible={rateDrawerVisibility}
                    width={isMobile ? "100%" : "50%"}
                    headerStyle={{ display: "flex", justifyContent: "space-between" }}
                    bodyStyle={{ background: "#FFF", padding: "0px" }}
                    title={<H1>Noter ce cours</H1>}
                >
                    {loading ? <Skeleton active /> : <div style={{ alignItems: "flex-start", margin: "40px auto" }}>
                        <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                            <img src={userData['profile']} height={80}></img>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontSize: "1.3em", fontWeight: "bold", color: "var(--title-color)", textTransform: "uppercase", marginTop: "10px" }}>{userData['firstname']} {userData['lastname']}</div>
                                {studentData !== null ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: "#D5D5D5", paddingTop: "2.5px", paddingBottom: "2.5px", borderRadius: "8px", width: "100px" }}>
                                    {studentData['levelId']['title']}
                                </div> : <div></div>}
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", fontSize: "1em", marginLeft: "20px" }}>
                            <div><span style={{ color: "green", fontWeight: "600" }}><img src={Check} height={15}></img> Cours </span>le {courseDate}</div>
                            <div style={{ display: "flex", flexDirection: "row", paddingLeft: "10px", paddingRight: "10px", paddingTop: "5px", paddingBottom: "5px", background: "#D5D5D5", marginLeft: "20px", borderRadius: "8px" }}>
                                <img src={setIcon(courseTopic)} height={30} /><span style={{ position: "relative", top: "2px" }}>{courseTopic}</span>
                            </div>
                        </div>
                        <Form
                            validateTrigger={["onFinish"]}
                            name="registerParent"
                            autoComplete="off"
                            form={formInfos}
                            onFinish={sendRemarks}
                        >
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", fontSize: "1em", marginLeft: "20px", marginTop: "10px" }}>
                                <span>Cours</span>
                                <Form.Item name="courseRating" initialValue={courseRating} rules={[{ required: true, message: 'Choisi une note' }]} style={{ marginBottom: "0px" }}>
                                    <Rate onChange={handleCourseChange} />
                                </Form.Item>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", fontSize: "1em", marginLeft: "20px", marginTop: "10px" }}>
                                <span>Application</span>
                                <Form.Item name="applicationRating" initialValue={applicationRating} rules={[{ required: true, message: 'Choisi une note' }]} style={{ marginBottom: "0px" }}>
                                    <Rate onChange={handleApplicationChange} />
                                </Form.Item>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", fontSize: "1em", marginLeft: "20px", marginTop: "10px" }}>
                                <span>Exercice</span>
                                <Form.Item name="exerciceRating" initialValue={exerciceRating} rules={[{ required: true, message: 'Choisi une note' }]} style={{ marginBottom: "0px" }}>
                                    <Rate onChange={handleExerciceChange} />
                                </Form.Item>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", fontSize: "1em", marginLeft: "20px", marginTop: "10px" }}>
                                <span>Mon avis sur ce cours</span>
                            </div>
                            <div style={{ width: "80%", marginLeft: "20px", marginTop: "10px" }}>
                                <Form.Item
                                    name="message"
                                    rules={[{ required: true }]}
                                    style={{ marginBottom: "20px" }}
                                >
                                    <Input.TextArea
                                        className={s.field}
                                        maxLength={255}
                                        rows={3}
                                    />
                                </Form.Item>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                <Form.Item>
                                    <AppButton loading={isDataLoading} htmlTypeSubmit={true}>Envoyer mon avis</AppButton>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>}
                </Drawer>
                {hidePanel && <AppDrawer
                    title={<H2>CHALLENGER L'ÉLÈVE</H2>}
                    headerStyle={{ border: "none" }}
                    onClose={() => setIsDrawerChallenge(!isDrawerChallenge)}
                    visible={isDrawerChallenge}
                >
                    <div className={s.__first_drawer}>
                        <div className={s.drawer__content}>

                            <Form
                                layout="inline"
                                name="search"
                                autoComplete="off"
                                onFinish={filterQuiz}
                                form={form}
                            >
                                <div className={styles.filter}>
                                    <Tooltip placement="left" title={"Réinitialiser tous les filtres"}>
                                        <Button
                                            className={styles.__reset__btn}
                                            onClick={onReset}
                                            shape="circle"
                                            htmlType="button"
                                        >
                                            <RedoOutlined />
                                        </Button>
                                    </Tooltip>

                                    <Row gutter={12}>
                                        <Col>
                                            <div className={styles.filed__search}>
                                                <Form.Item name="title">
                                                    <Search
                                                        onChange={() => submit.current.click()}
                                                        placeholder="Tape ici ta recherche"
                                                        size="large"
                                                        bordered={false}
                                                        enterButton={
                                                            <button type="submit">
                                                                <SearchIcon
                                                                    style={{
                                                                        position: "relative",
                                                                        top: "3px",
                                                                        left: "10px",
                                                                    }}
                                                                />
                                                            </button>
                                                        }
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className={`${styles.select}`}>
                                                <Form.Item name="levelId">
                                                    <AppSelectGroup
                                                        placeholder="Niveau"
                                                        style={{ width: "150px" }}
                                                        onChange={(value: string) => (
                                                            OnSelectLevel(value)
                                                        )}
                                                        onClear={() => (setTempTopic(undefined), submit.current.click())}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Col>

                                        <Col>
                                            <div className={`${styles.select}`}>
                                                <Form.Item name="topicId">
                                                    <Select
                                                        placeholder="Matière"
                                                        allowClear
                                                        onChange={(value: string) => (
                                                            submit.current.click()
                                                        )}
                                                        bordered={false}
                                                        style={{ width: "150px" }}
                                                    >
                                                        {tempTopic?.map((topic: any) => (
                                                            <Option key={topic._id} value={topic._id}>
                                                                {topic.title}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Col>

                                        <Col>
                                            <div className={`${styles.select}`}>
                                                <Form.Item name="quizzLevel">
                                                    <Select
                                                        onChange={(e: string) => {
                                                            submit.current.click()
                                                        }}
                                                        placeholder="Difficulté"
                                                        allowClear
                                                        bordered={false}
                                                    >
                                                        <Option key={"1"} value={"Facile"}>
                                                            Facile
                                                        </Option>
                                                        <Option key={"2"} value={"Moyen"}>
                                                            Moyen
                                                        </Option>
                                                        <Option key={"3"} value={"Difficile"}>
                                                            Difficile
                                                        </Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Col>

                                        <Col>
                                            <div className={`${styles.select}`}>
                                                <Form.Item name="duration">
                                                    <Select
                                                        placeholder="Durée"
                                                        allowClear
                                                        bordered={false}
                                                        onChange={(e: string) => {
                                                            submit.current.click()
                                                        }}
                                                    >
                                                        <Option key={"topic._id"} value={"5"}>
                                                            5 mins
                                                        </Option>
                                                        <Option key={"topic._id"} value={"10"}>
                                                            10 mins
                                                        </Option>
                                                        <Option key={"topic._id"} value={"15"}>
                                                            15 mins
                                                        </Option>
                                                        <Option key={"topic._id"} value={"20"}>
                                                            20 mins
                                                        </Option>
                                                        <Option key={"topic._id"} value={"30"}>
                                                            30 mins
                                                        </Option>
                                                        <Option key={"topic._id"} value={"45"}>
                                                            45 mins
                                                        </Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <Button
                                    ref={submit}
                                    htmlType="submit"
                                    className={styles.__submit__hidden}
                                ></Button>
                            </Form>
                        </div>
                        {!isDataLoading ? (
                            <>
                                <CardMenu
                                    setDrawerConfirm={setDrawerConfirm}
                                    setTempsChallenge={setTempsChallenge}
                                    data={data?.data}
                                    someClasseDataNeeded={classe}
                                    handler={OnSubmitChallengeHandler}
                                />
                                <Row justify="center" style={{ marginTop: "2em" }}>
                                    <Pagination
                                        showSizeChanger={false}
                                        onChange={(curr: number) => setcurrentPage(curr)}
                                        defaultCurrent={currentPage}
                                        total={data?.data?.count * 10}
                                    />
                                </Row>
                            </>
                        ) : (
                            <CardMenuLoader />
                        )}
                    </div>
                </AppDrawer>}
                <AppDrawer
                    onClose={() => setDrawerConfirm(false)}
                    visible={drawerConfirm}
                >
                    <H1>
                        Challenge valide pendant {tempsChallenge}{" "}
                        {tempsChallenge > 1 ? "heures" : "heure"}{" "}
                    </H1>

                    <AppResult status="success" title=" Challenge envoyé aux élèves" />
                </AppDrawer>
            </AppScreen>
        </React.Fragment>
    );

}

export default TeacherStudentHistory;