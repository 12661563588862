import FadeInOnScroll from "components/FadeIn";
import FadeInUpOnScroll from "components/FadeInUp";
import * as appRoutes from "config/routes.config";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import arrow_need_help from "../../../../assets/images/app/home/arrow_need_help.svg";
import ellipse_history_title from "../../../../assets/images/app/home/ellipse_history_title.svg";
import ellipse_need_help from "../../../../assets/images/app/home/ellipse_need_help.svg";
import join_community_background from "../../../../assets/images/app/parents/join_community_background.svg";
import join_community_parent from "../../../../assets/images/app/parents/join_community_parent.png";
import ellipse_instaprof from "../../../../assets/images/app/teachers/ellipse_instaprof.svg";
import join_community_lines from "../../../../assets/images/app/teachers/join_community_lines.svg";
import trace_instaprof from "../../../../assets/images/app/teachers/trace_instaprof.svg";
import st from "../../../app/common.module.css";
import s from "./style.module.css";

const JoinCommunity = () => {

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate(appRoutes.LOGINS);
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.mainContainer}>
            <div className={s.needHelpBox}>
                {!isMobile && <img src={trace_instaprof} style={{ position: "relative", right: "-19%", top: "5px", height: "180px", zIndex: "1" }}></img>}
                <div className={s.mainBox}>
                    <div className={s.leftBox}>
                        <FadeInUpOnScroll>
                            <h1 className={s.title}>Rejoignez<br />la communauté<br />Mon Ami Albert</h1>
                        </FadeInUpOnScroll>
                        <img src={ellipse_history_title} className={s.ellipseTitle}></img>
                        <FadeInOnScroll>
                            {isMobile ? <p className={s.message}>Chers parents, faites partie d'une communauté engagée qui se consacre à l'éducation de vos enfants. En rejoignant Mon Ami Albert, vous aurez accès à des ressources pédagogiques de qualité, un suivi personnalisé pour le progrès de votre enfant, et la possibilité de communiquer avec des enseignants expérimentés. Ensemble, soutenons l'apprentissage et offrons à chaque élève la chance de réussir.</p> : <p className={s.message}>Chers parents, faites partie d'une communauté engagée<br />qui se consacre à l'éducation de vos enfants. En rejoignant<br />Mon Ami Albert, vous aurez accès à des ressources<br />pédagogiques de qualité, un suivi personnalisé<br />pour le progrès de votre enfant, et la possibilité de communiquer avec des enseignants expérimentés. Ensemble, soutenons l'apprentissage et offrons à chaque élève la chance de réussir.</p>}
                        </FadeInOnScroll>
                        <img src={arrow_need_help} className={s.buttonArrow}></img>
                        <button className={st.button} style={{ marginTop: "0px", position: "relative", top: isMobile ? "0px" : "-45px" }} onClick={handleButtonClick}>
                            Créer votre compte
                        </button>
                        <img src={join_community_lines} className={s.ellipseLines}></img>
                    </div>
                    <div className={s.rightBox}>
                        <img src={ellipse_need_help} className={s.ellipseImage}></img>
                        <img src={ellipse_instaprof} className={s.ellipseInstaProf}></img>
                        <img src={join_community_parent} className={s.parentImage}></img>
                        <img src={join_community_background} className={s.backgroundParent}></img>
                    </div>
                </div>
                {!isMobile && <a className={s.quote}>“</a>}
            </div>
        </div>
    );
};

export default JoinCommunity;