import { Col, Form, Row, Select, Skeleton } from "antd";
import React, { FC, useCallback, useRef } from "react";
import AppScreen from "screens/teacher/ScreenWithSidebar";

//!COMPONENTS
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import Breadcrumbs from "components/breadcrumbs";
import CardQuiz from "../components/cardQuiz";
import HeaderComponents from "../components/headerComponents";

//!STYLES
import s from "./assets/easyQuiz.module.css";

import { findFilterQuizzOfCours } from "api/services/studentQuizz/studentQuizz.services";
import { PlayLevel, StatusCode } from "common/enums";
import AppResult from "components/appResults";
import AppButton from "components/libs/button";
import Flex from "components/libs/flex";
import useGetQuiz from "hooks/useGetQuizz";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setIsBotCalled } from "store/app/appSlice";
import TabsStudent from "../components/tabs";

const EasyQuiz: FC = () => {
  const { idCours } = useSelector((state: any) => state.courses);
  const { id, level } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, data, setData, setLoading } = useGetQuiz(id!, level!);
  // const { quizForCours, isloading, error } = useQuizzForCours(idCours);
  //
  const refSubmited = useRef<any>();

  const { Option } = Select;
  function handleChange(value: string) {
    console.log(`selected ${value}`);
  }

  const filterQuiz = useCallback(
    async (field) => {
      setLoading(true);
      try {
        const response = await findFilterQuizzOfCours(
          // field[0]?.name[0] === "idCours" ? field[0]?.value : "",
          id!,
          field[0]?.name[0] === "quizzLevel" ? field[0]?.value : "",
          field[0]?.name[0] === "duration" ? field[0]?.value : ""
        );

        if (response?.statusCode === StatusCode.OK) {
          setData(response?.data);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [data]
  );

  const onChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const handleClick = () => {
    dispatch(setIsBotCalled(true));
  }

  // const onFilterQuiz = async (values?: any) => {
  //   console.log(values);
  //   try {
  //     const response = await findQuizzDifficulty(
  //       id!,
  //       values.Facile,
  //       values.Moyen,
  //       values?.Difficile
  //     );
  //     setData(response?.data);
  //   } catch (error: any) {
  //     console.log(error);
  //     // setError(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   onFilterQuiz();
  // }, []);

  return (
    <React.Fragment>
      <Breadcrumbs isMycontent={true} />
      {/* <PageHeaderBack title={data[0]?.course?.title} /> */}
      <AppScreen className={s.easyQuiz}>
        <HeaderComponents />

        <TabsStudent
          level={level}
          onRequest={() => { }}
          onExit={() => { }}
        />

        <div className={s.header}>
          <div className={s.left}>
            <span style={{ color: "var(--first-color)" }}>
              {data.length} Quiz trouvé(s) - {level}
            </span>
          </div>

          <div>
            <Form
              name="basic"
              // onFinish={onFinish}
              autoComplete="off"
              onFieldsChange={filterQuiz}
            >
              <Row gutter={18}>
                <div className={`${s.select}`}>
                  <Form.Item name="quizzLevel">
                    <Select placeholder="Niveau quiz" bordered={false}>
                      <Option value={PlayLevel.EASY}>{PlayLevel.EASY}</Option>
                      <Option value={PlayLevel.MEDIUM}>
                        {PlayLevel.MEDIUM}
                      </Option>
                      <Option value={PlayLevel.HARD}>{PlayLevel.HARD}</Option>
                      <Option value="">Tous les quiz</Option>
                    </Select>
                  </Form.Item>
                </div>

                <Col>
                  <div className={`${s.select}`}>
                    <Form.Item name="duration">
                      <Select placeholder="Durée" bordered={false}>
                        <Option value={15}>15 min</Option>
                        <Option value={30}>30 min</Option>
                        <Option value={45}>45 min</Option>
                        <Option value={60}>1h</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>

        <div className="main">
          {loading && (
            <div>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </div>
          )}

          {!loading && (
            <Flex>
              {data?.result?.map((quiz: any, i: number) => (
                <React.Fragment key={i}>
                  <CardQuiz quiz={quiz} />
                </React.Fragment>
              ))}
            </Flex>
          )}

          {!loading && data.result.length <= 0 && (
            <AppResult status="info" title={
              <>
                Oups, aucun quiz n’est disponible pour ce niveau. <br />
                Mais tu peux toujours demander à Albert de t’en créer un.
              </>
            } />
          )}

          {!loading && data.result.length <= 0 && <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "120px" }}>
            <AppButton onClick={handleClick}>
              Démarrer le chat
            </AppButton>
          </div>}
        </div>
      </AppScreen>
    </React.Fragment>
  );
};

export default EasyQuiz;
