import { createApi, BaseQueryFn } from "@reduxjs/toolkit/query";
import { getToken } from "api";
import axios, { AxiosRequestConfig, AxiosError } from "axios";

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data }) => {
    try {
      const token = await getToken();
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: { status: err.response?.status, data: err.response?.data },
      };
    }
  };

export const roomsApi: any = createApi({
  reducerPath: "roomsApi",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL || "",
  }),
  endpoints: (build) => ({
    getTeachersRooms: build.query({
      query: (id) => ({ url: `/users/teachers/${id}/rooms`, method: "get" }),
    }),
  }),
});

export const { useGetTeachersRooms } = roomsApi;
