import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Avatar,
  Button,
  Divider,
  Popconfirm,
  Result,
  Row,
  Skeleton,
  Typography,
} from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import AppDrawer from "components/appDrawer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import TEACHER from "assets/images/teacher/teacher.svg";

//!STYLES
import s from "../assets/calendar.module.css";
import Col from "antd/es/grid/col";
import H2 from "components/libs/subtitle";
import moment from "moment";
import { useSelector } from "react-redux";
import { useGetHistoriqueStudentQuery } from "store/services/teacher/classes.services";
import AppResult from "components/appResults";
import CardReservations from "./card";

const { Text } = Typography;

const CalendarOfReservationStudent = () => {
  const { user } = useSelector((state) => state.auth);
  const {
    data: reservations,
    isLoading,
    isError,
    refetch,
  } = useGetHistoriqueStudentQuery(user?._id);

  useEffect(() => {
    refetch();
  }, []);

  const reservationsData =
    reservations?.data.reduce((acc: any, curr: any) => {
      const index = acc.findIndex((x: any) => x.startDate === curr.startDate);

      if (index > -1) {
        acc[index] = {
          ...acc[index],
          data: [...acc[index].data, curr],
        };
        return acc;
      }

      return [
        ...acc,
        {
          startDate: curr.startDate,
          data: [curr],
        },
      ];
    }, []) || [];

  return (
    <div>
      {isLoading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <Fragment>
          {reservationsData?.length > 0 ? (
            <div className={s.calendar}>
              {reservationsData?.map((reservation: any, index: number) => (
                <div key={index}>
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "2em" }}
                  >
                    <Col>
                      <H2>{moment(reservation?.startDate).format("LL")}</H2>
                    </Col>
                  </Row>

                  {reservation?.data.map((cren: any, index: number) => (
                    <CardReservations
                      key={index}
                      cren={cren}
                      isHistorique={false}
                    />
                  ))}

                  <Divider />
                </div>
              ))}
            </div>
          ) : (
            <AppResult status={"info"} title="Aucune réservation" />
          )}
        </Fragment>
      )}
    </div>
  );
};

export default CalendarOfReservationStudent;
