import FadeInOnScroll from "components/FadeIn";
import FadeInUpOnScroll from "components/FadeInUp";
import { useState } from "react";
import ellipse_history_title from "../../../../assets/images/app/home/ellipse_history_title.svg";
import hands_message from "../../../../assets/images/app/parents/hands_message.svg";
import man_stairs from "../../../../assets/images/app/parents/man_stairs.svg";
import people from "../../../../assets/images/app/parents/people.svg";
import ellipse_advantages from "../../../../assets/images/app/teachers/ellipse_advantages.svg";
import s from "./style.module.css";

const Advantages = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.mainContainer}>
            <div className={s.advantageContainer}>
                <div className={s.mainBox}>
                    <FadeInUpOnScroll>
                        <h1 className={s.title}>Nos engagements envers<br />les parents</h1>
                    </FadeInUpOnScroll>
                    <img src={ellipse_history_title} className={s.ellipseTitle}></img>
                    <img src={ellipse_advantages} className={s.ellipseAdvantage}></img>
                    <div className={s.boxes}>
                        <div className={s.subBox}>
                            <FadeInOnScroll>
                                <div className={s.circleOne}>
                                    <img src={man_stairs} style={{ height: isMobile ? "24px" : "auto" }}></img>
                                </div>
                            </FadeInOnScroll>
                            <div className={s.circleTitle}>
                                <FadeInOnScroll>Suivi des Progrès<br />de votre Enfant</FadeInOnScroll>
                            </div>
                            <FadeInOnScroll>
                                <div style={{ marginTop: isMobile ? "10px" : "20px" }}>
                                    <a className={s.circleMessage}>Tableaux de bord personnalisés & Suivi<br />des performances</a>
                                </div>
                            </FadeInOnScroll>
                        </div>
                        <div className={s.subBox}>
                            <FadeInOnScroll>
                                <div className={s.circleTwo}>
                                    <img src={people} style={{ height: isMobile ? "24px" : "auto" }}></img>
                                </div>
                            </FadeInOnScroll>
                            <div className={s.circleTitle}>
                                <FadeInOnScroll>Accès aux Profils<br />des Enseignants</FadeInOnScroll>
                            </div>
                            <FadeInOnScroll>
                                <div style={{ marginTop: isMobile ? "10px" : "20px" }}>
                                    <a className={s.circleMessage}>Interaction & Sélection des professeurs<br />les plus compatibles aux besoins et<br />préférences de votre enfant</a>
                                </div>
                            </FadeInOnScroll>
                        </div>
                        <div className={s.subBox}>
                            <FadeInOnScroll>
                                <div className={s.circleThree}>
                                    <img src={hands_message} style={{ height: isMobile ? "24px" : "auto" }}></img>
                                </div>
                            </FadeInOnScroll>
                            <div className={s.circleTitle}>
                                <FadeInOnScroll>Assistance<br />Personnalisée</FadeInOnScroll>
                            </div>
                            <FadeInOnScroll>
                                <div style={{ marginTop: isMobile ? "10px" : "20px" }}>
                                    <a className={s.circleMessage}>Une équipe dédiée pour répondre à<br />toutes vos questions et vous<br />accompagner dans la réussite scolaire<br />de votre enfant</a>
                                </div>
                            </FadeInOnScroll>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Advantages;