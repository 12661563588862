import { Table } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIdReceiver, setSelectUserChat, toggleAppChat } from "store/AppChat/appChat";
import "./myStyle.css";

const ArrayRegistredStudents: React.FC<any> = (props) => {
  const data = props.data;
  const roomRecovery = useSelector((state) => state.room);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const handleMessageClick = (id: any) => {
    /// e.preventDefault();
    handleDisplayTeacherMSG(id);
  };

  const handleDisplayTeacherMSG = (idStudent: string) => {
    dispatch(setIdReceiver(idStudent));
    dispatch(setSelectUserChat(true));
    dispatch(toggleAppChat(true));
  };

  const col = auth?.user?.roles.includes("teacher")
    ? [
      {
        title: "Élèves",
        dataIndex: "fullname",
        render: (text: string) => (
          <div style={{ textTransform: "capitalize" }}>{text}</div>
        ),
        key: "fullname",
      },
      {
        title: "Points",
        dataIndex: "score",
        key: "score",
        render: (text: string) => text + " Points",
      },
      {
        title: "Classement",
        dataIndex: "classement",
        key: "classement",
      },
      {
        title: "Action",
        dataIndex: "id",
        key: "id",
        render: (text: string) => (
          <a
            style={{ color: "#80B1B2" }}
            href="/"
            onClick={(e) => {
              e.preventDefault();
              handleMessageClick(text);
              /* dispatch(
                getRoomByIdProfTypeRelationIdEtudiant({
                  actor1: auth?.user?._id,
                  actor2: text,
                  flag: FLAGS.SINGLE_FLAG,
                  type: "",
                  instaprof: "",
                })
              ); */
              // let temp2 = roomRecovery.items.data?.receiverId
              //   ? roomRecovery.items.data?.receiverId
              //   : roomRecovery.items.data?.receiverIdC;
              // let temp1 = roomRecovery.items.data?.senderId;
              // if (temp2?._id === auth?.user?._id) {
              //   temp2 = roomRecovery.items.data?.senderId;
              //   temp1 = roomRecovery.items.data?.receiverId
              //     ? roomRecovery.items.data?.receiverId
              //     : roomRecovery.items.data?.receiverIdC;
              // }

              // if (roomRecovery.items.data?.receiverId) {
              //   dispatch(
              //     addConversationTemporary({
              //       conversationId: Math.random() + "",
              //       createdAt: new Date().toString(),
              //       message: "message",
              //       receiverId: temp2,
              //       roomId: roomRecovery.items.data?.talkId,
              //       seen: false,
              //       senderId: temp1,
              //       updatedAt: new Date().toString(),
              //       __v: 0,
              //       _id: Math.random() + "",
              //     })
              //   );

              //   dispatch(
              //     updateRoomsTemporary({
              //       lastReceiverId: temp2?._id,
              //       lastMessage: "message",
              //       createdAt: new Date().toString(),
              //       message: "qsdqsd",
              //       receiverId: temp2,
              //       talkId: roomRecovery.items.data?.talkId,
              //       seen: false,
              //       senderId: temp1,
              //       updatedAt: new Date().toString(),
              //       __v: 0,
              //       _id: roomRecovery.items.data?._id,
              //     })
              //   );
              // } else {
              //   dispatch(
              //     addConversationTemporary({
              //       conversationId: Math.random() + "",
              //       createdAt: new Date().toString(),
              //       message: "message",
              //       receiverIdC: temp2,
              //       roomId: roomRecovery.items.data?.talkId,
              //       seen: false,
              //       senderId: auth.user,
              //       updatedAt: new Date().toString(),
              //       __v: 0,
              //       _id: Math.random() + "",
              //     })
              //   );

              //   dispatch(
              //     updateRoomsTemporary({
              //       lastReceiverId: temp2?._id,
              //       lastMessage: "message",
              //       createdAt: new Date().toString(),
              //       message: "message",
              //       receiverIdC: temp2,
              //       talkId: roomRecovery.items.data?.talkId,
              //       seen: false,
              //       senderId: temp1,
              //       updatedAt: new Date().toString(),
              //       __v: 0,
              //       _id: roomRecovery.items.data?._id,
              //     })
              //   );
              // }

              /// dispatch(openChatDrawer());
            }}
          >
            Envoyer un message
          </a>
        ),
      },
    ]
    : [
      {
        title: "Élèves",
        dataIndex: "fullname",
        render: (text: string) => (
          <div style={{ textTransform: "capitalize" }}>{text}</div>
        ),
        key: "fullname",
      },
      {
        title: "Points",
        dataIndex: "score",
        key: "score",
        render: (text: string) => text + " Points",
      },
      {
        title: "Classement",
        dataIndex: "classement",
        key: "classement",
      },
    ];
  return (
    <Table
      className="myArray"
      columns={col}
      dataSource={data}
      pagination={{ pageSize: 5 }}
    />
  );
};

export default ArrayRegistredStudents;
