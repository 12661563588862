import * as appRoutes from "config/routes.config";
import { Link, useLocation } from "react-router-dom";

//!IMAGES
import Cours from "assets/images/teacher/cours.svg";
import Exo from "assets/images/teacher/exo.svg";
import Quiz from "assets/images/teacher/quiz.svg";

//!STYLE
import s from "./style.module.css";

const HeaderTabContent = () => {

  //!HOOKS
  const location = useLocation();

  return (
    <div className={s.tabs}>
      <Link to={appRoutes.TEACHER_COURSES}>
        <div className={`${s.tab} ${location.pathname === appRoutes.TEACHER_COURSES && s.tabActive}`}>
          <img height={45} src={Cours} ></img> Cours
        </div>
      </Link>
      <Link to={appRoutes.TEACHER_QUIZZ}>
        <div className={`${s.tab} ${location.pathname === appRoutes.TEACHER_QUIZZ && s.tabActive}`}>
          <img height={45} src={Quiz} ></img> Quiz
        </div>
      </Link>
      <Link to={appRoutes.TEACHER_EXERCICES}>
        <div className={`${s.tab} ${location.pathname === appRoutes.TEACHER_EXERCICES && s.tabActive}`}>
          <img height={30} src={Exo} style={{ marginRight: "10px" }}></img> Exercices
        </div>
      </Link>
    </div>
  );
};

export default HeaderTabContent;
