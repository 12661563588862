import { useState, useEffect } from "react";
import { findExerciceClues } from "api/services/studentExercice/studentExercice";

const useStudentExerciceClues = (id: string) => {
  //!STATE
  const [clues, setClues] = useState<any>([]);
  const [loadingClues, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  const fetchExercice = async (idExercice: string) => {
    setLoading(true);
    try {
      const response = await findExerciceClues(idExercice);
      if (response) {
        return setClues(response.data);
      }
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExercice(id);
  }, [id]);

  return { clues, loadingClues, error };
};

export default useStudentExerciceClues;
