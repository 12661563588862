import FadeInOnScroll from "components/FadeIn";
import FadeInUpOnScroll from "components/FadeInUp";
import { useState } from "react";
import ellipse_ressources from "../../../../../assets/images/app/home/ellipse_ressources.svg";
import ellipse_valeur from "../../../../../assets/images/app/home/ellipse_valeur.svg";
import st from "../../../common.module.css";
import s from "./style.module.css";

const OurValues = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.mainContainer}>
            <div className={s.mainBox}>
                <FadeInUpOnScroll>
                    <h1 className={s.title}>Nos valeurs</h1>
                </FadeInUpOnScroll>
                <img src={ellipse_ressources} className={s.ellipseTitle}></img>
                <FadeInOnScroll>
                    <p className={s.subtitle}>Façonner l’Excellence à Travers Nos Convictions</p>
                </FadeInOnScroll>
                <div className={s.animatedBoxes}>
                    <div className={`${st.containerOneStyle} ${st.scaleAnimationSmall}`}>
                        <div className={st.cornerStyleUp} style={{ bottom: "0px", right: "-3px" }}></div>
                        <div className={st.cornerStyleUp} style={{ top: "0px", left: "-3px" }}></div>
                        <div className={st.cornerStyleDown} style={{ top: "-4px", left: "-4px" }}></div>
                        <div className={st.cornerStyleDown} style={{ bottom: "-4px", right: "-3px" }}></div>
                        <div className={s.boxTitle}>Engagement<br />envers l'Excellence</div>
                        <div className={s.boxMessage}>Trouvez l’enseignant qu’il vous faut<br /> en quelques clics, sans délai,<br /> 24h/24h.</div>
                    </div>
                    <div className={`${st.containerTwoStyle} ${st.scaleAnimationSmall}`}>
                        <div className={st.cornerStyleUp} style={{ bottom: "0px", right: "-3px" }}></div>
                        <div className={st.cornerStyleUp} style={{ top: "0px", left: "-3px" }}></div>
                        <div className={st.cornerStyleDown} style={{ top: "-4px", left: "-4px" }}></div>
                        <div className={st.cornerStyleDown} style={{ bottom: "-4px", right: "-3px" }}></div>
                        <div className={s.boxTitle}>Réactivité<br />et Adaptabilité</div>
                        <div className={s.boxMessage}>Choisissez parmi une variété<br />de professeurs dans toutes<br />les matières pour toute urgence<br />scolaire.</div>
                    </div>
                    <div className={`${st.containerThreeStyle} ${st.scaleAnimationSmall}`}>
                        <div className={st.cornerStyleUp} style={{ bottom: "0px", right: "-3px" }}></div>
                        <div className={st.cornerStyleUp} style={{ top: "0px", left: "-3px" }}></div>
                        <div className={st.cornerStyleDown} style={{ top: "-4px", left: "-4px" }}></div>
                        <div className={st.cornerStyleDown} style={{ bottom: "-4px", right: "-3px" }}></div>
                        <div className={s.boxTitle}>Empathie<br />et Compréhension</div>
                        <div className={s.boxMessage}>Une interface utilisateur conviviale<br />et intuitive, facile à naviguer,<br />qui simplifie la selection des profs<br /> et la réservation des cours.</div>
                    </div>
                    <div className={`${st.containerFourStyle} ${st.scaleAnimationSmall}`}>
                        <div className={st.cornerStyleUp} style={{ bottom: "0px", right: "-3px" }}></div>
                        <div className={st.cornerStyleUp} style={{ top: "0px", left: "-3px" }}></div>
                        <div className={st.cornerStyleDown} style={{ top: "-4px", left: "-4px" }}></div>
                        <div className={st.cornerStyleDown} style={{ bottom: "-4px", right: "-3px" }}></div>
                        <div className={s.boxTitle}>Innovation<br />Continue</div>
                        <div className={s.boxMessage}>Consultez les évaluations et les<br />commentaires pour choisir en<br />toute confiance.</div>
                    </div>
                </div>
                {!isMobile && <img src={ellipse_valeur} style={{ position: "relative", left: "-35%", width: "20px", bottom: "10px", zIndex: "-1" }}></img>}
            </div>
        </div>
    );
};

export default OurValues;