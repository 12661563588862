import React, { useMemo, useState } from "react";
import { Button, Col, Form, Input, Modal, Row } from "antd";

//!COMPONENTS
import H2 from "components/libs/subtitle";

//!ASSETS
import Student from "assets/images/students/student.svg";
import WarningIcon from "assets/images/app/warning.svg";
import AstuceIcon from "assets/images/app/astuce.svg";
import AlbertSad from "assets/images/albert/albert-sad.svg";

//!STYLES
import s from "./style.module.css";
import AppButton from "components/libs/button";
import LottieAnimate from "components/lotties";
import Matiers from "components/matiers";
import H1 from "components/libs/title";
import { useSelector, useDispatch } from "react-redux";
import { INIT_MODAL } from "store/notification";

enum InstaProfStates {
  INIT_MODAL = "INIT_MODAL",
}

type Props = {};

const ModalInstaProfBadResp: React.FC<Props> = ({}) => {
  //!HOOKS
  const dispatch = useDispatch();
  const { currentStudentInstaProf } = useSelector((state) => state.auth);
  const { openModalBadResp } = useSelector((state) => state.notifications);

  const handleClose = () => {
    dispatch(INIT_MODAL());
  };

  return useMemo(() => {
    return (
      <Modal
        title={false}
        visible={openModalBadResp}
        footer={false}
        width={"60%"}
        onOk={handleClose}
        onCancel={handleClose}
        centered
        className={s.modal}
      >
        <div className={s.content}>
          <Row
            justify="center"
            align="middle"
            gutter={12}
            style={{ marginBottom: "1.5em" }}
          >
            <Col>
              <div className={s.avatar}>
                <img
                  src={
                    currentStudentInstaProf?.data?.student?.profile || Student
                  }
                  alt=""
                />
              </div>
            </Col>
            <Col>
              <H1>
                {currentStudentInstaProf?.data?.student?.firstname} a choisi un
                autre prof
              </H1>
            </Col>
          </Row>

          <Row
            justify="center"
            align="middle"
            gutter={12}
            style={{ marginBottom: "0.5em" }}
          >
            <Col>
              <div className={s.albert}>
                <img src={AlbertSad} alt="" width={"55%"} />
              </div>
            </Col>
          </Row>

          <div className={s.main}>
            <img src={AstuceIcon} alt="" />
            <h3>Astuce</h3>
            <p>
              Invitez vos élèves à donner des avis sur vos cours, participer à
              améliorer <br /> notre contenu pour gagner des points et augmenter
              vos changes
            </p>
          </div>
        </div>
      </Modal>
    );
  }, [openModalBadResp, currentStudentInstaProf]);
};

export default ModalInstaProfBadResp;
