export const studentParser = (input: any, search?: any) => {
  console.log("my serach", search);
  if (Array.isArray(input) && input.length > 0)
    return input.map((e: any, index: number) => {
      return {
        id: e._id,
        fullname: e.firstname + " " + e.lastname,
        classement: index + 1,
        score: e.points,
      };
    });
};
export const studentsFilter = (input: any, search?: any) => {
  if (Array.isArray(input) && input.length > 0)
    return input.filter((e: any) => {
      console.log(
        "testing",
        e?.fullname?.toLowerCase().indexOf(search.toLowerCase())
      );
      return e?.fullname?.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });
};
