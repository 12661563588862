import { FC } from "react";
import classes from "./style.module.css";

type TypeContainer = {
  className?: string;
};

const Container: FC<TypeContainer> = ({ children, className }) => {
  return <div className={`${classes.container} ${className}`}>{children}</div>;
};

export default Container;
