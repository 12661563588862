import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Avatar,
  Card,
  Col,
  Image,
  Popconfirm,
  Result,
  Row,
  Skeleton,
  Tooltip,
  message,
} from "antd";
import { getUserByUid } from "api/services/auth/auth.services";
import { postStudentChallengeQuizResponses } from "api/services/challenges/challenges.services";
import { findFinalQuiz } from "api/services/studentQuizz/studentQuizz.services";
import AlbertHappy from "assets/images/albert/status/Happy.svg";
import AlbertNeutre from "assets/images/albert/status/Neutre.svg";
import AlbertSad from "assets/images/albert/status/Sad.svg";
import AlbertVeryHappy from "assets/images/albert/status/Very happy.svg";
import { StatusCode } from "common/enums";
import AppDrawer from "components/appDrawer";
import AppLatex from "components/appLatex";
import { AppNotification } from "components/appNotification";
import Breadcrumbs from "components/breadcrumbs";
import AppButton from "components/libs/button";
import H2 from "components/libs/subtitle";
import H1 from "components/libs/title";
import SkillBar from "components/skillbar";
import { Field, Form, Formik } from "formik";
import useGetChallengeOfClass from "hooks/useGetChallengeOfClass.hook";
import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Latex from "react-latex-next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AppScreen from "screens/teacher/ScreenWithSidebar";
import { updateUser } from "store/auth/authSlice";
import { useSendRealisationTimeMutation } from "store/services/student/quizz.services";
import CountdownTimer from "../coursQuiz/components/CountdownTimer";
import Clock from "./assets/images/clock.svg";
import Trophe from "./assets/images/trophe.svg";
import s from "./assets/quiz.module.css";
import CardResult from "./components/cardResult";

const StudentChallengeQuiz: FC = () => {
  //!HOOK
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sendRealisationTime, { isError, isLoading, isSuccess }] =
    useSendRealisationTimeMutation();
  const ref = useRef(null);
  const { idChallenge } = useParams();
  const { quiz, loading, error } = useGetChallengeOfClass(idChallenge!);
  const { user } = useSelector((state) => state.auth);
  const [validation, setValidatation] = useState<boolean>(false);
  //!STATE
  const [current, setCurrent] = useState<number>(0);
  const [tempIdQuestion, setTempId] = useState<string>("");
  const [progress, setProgress] = useState<any>(0);
  const [isVisible, setIsVisble] = useState<boolean>(true);
  const [isFinish, setIsFinish] = useState<boolean>(false);
  const [drawer, setDrawer] = useState<boolean>(false);
  const [response, setResponse] = useState<any>([]);
  const [TimeElapsed, setTimeElapsed] = useState<boolean>(false);
  const [finalResultQuiz, setFinalResultQuiz] = useState<any>();
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const [_, setStartTime] = useState<Date | null>(null);

  //!FUNCTION
  const showDrawer = () => {
    setDrawer(!drawer);
    setIsVisble(false);
    setIsFinish(true);
  };

  const fetchResultQuiz = async () => {
    try {
      const res = await findFinalQuiz(user?._id, idChallenge!);
      setFinalResultQuiz(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleProgress = async () => {
    let widthProgress = 100 / quiz?.quizz?.questions?.length;
    setProgress((curr: number) => curr + widthProgress);

    if (current + 1 >= quiz?.quizz?.questions?.length) {
      showDrawer();
      setIsFinish(true);
      sendRealisationTime({
        studentId: user?._id,
        quizzId: quiz?._id,
        realisationTime: elapsedTime,
      });
      localStorage.setItem("quizTime", elapsedTime as any);
    }
  };

  const handleClick = (event: any) => {
    setValidatation(true);
    // let checkboxCL = event.target.classList,
    //   pState = `${s.pristine}`;
    // if (checkboxCL.contains(pState)) checkboxCL.remove(pState);
  };

  const handleSubmite = useCallback(
    async (values?: any) => {
      if (quiz?.quizz?.questions) {
        setTempId(quiz?.quizz?.questions[current]?._id);
      }

      const finalData = {
        ...values,
        questionId: quiz?.quizz?.questions[current]?._id,
      };

      try {
        if (values) {
          const res = await postStudentChallengeQuizResponses(
            idChallenge!,
            user?._id,
            finalData
          );

          if (res) {
            setCurrent((curr) => curr + 1);
            handleProgress();
            setResponse([...response, res.data]);
            const getInfosResponse = await getUserByUid(user?.uid);
            if (getInfosResponse?.statusCode === StatusCode.OK) {
              dispatch(updateUser(getInfosResponse?.data));
            }
          }
        }
      } catch (error) {
        console.log(error);
        message.error("Error");
      }
    },
    [
      current,
      tempIdQuestion,
      handleProgress,
      progress,
      isVisible,
      response,
      TimeElapsed,
    ]
  );

  const TEMPS_QUIZZ =
    quiz?.quizzDuration * 60 * 1000 || quiz?.quizz?.duration * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();

  const dateTimeAfterThreeDays = NOW_IN_MS + TEMPS_QUIZZ;

  const handleConfirm = () => {
    AppNotification(
      <span>
        J’espère te revoir prochainement
        <strong style={{ textTransform: "capitalize" }}>
          {" " + user?.firstname}
        </strong>{" "}
        👋
      </span>,
      "AlbertNeutre",
      "Quiz"
    );
    navigate(-1);
  };

  const handleCancel = () => {
    AppNotification(
      <span>
        Allez{" "}
        <strong style={{ textTransform: "capitalize" }}>
          {user?.firstname}!"
        </strong>{" "}
        Persévère! un peu d'effort 🙂
      </span>,
      "AlbertHappy",
      "Quiz"
    );
  };

  useEffect(() => {
    // Date et heure de début du quiz
    const quizStartTime = new Date();

    setStartTime(quizStartTime);
    const interval = setInterval(() => {
      const currentTimestamp = Date.now();
      const elapsed = currentTimestamp - quizStartTime.getTime();
      setElapsedTime(elapsed);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTimestamp = (timestamp: number): string => {
    const seconds = Math.floor((timestamp / 1000) % 60);
    const minutes = Math.floor((timestamp / (1000 * 60)) % 60);
    const hours = Math.floor((timestamp / (1000 * 60 * 60)) % 24);

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <React.Fragment>
      {/* {formatTimestamp(30002)} */}
      <Breadcrumbs isMycontent={true} />
      {/* <Popconfirm
        title="Tu veux vraiment quitter ."
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        okText="Oui"
        cancelText="Non"
      >
        <TabsStudent
          textBack="RETOUR À la liste des quizz"
          isPageStudentQuiz={true}
        />
      </Popconfirm> */}

      {quiz?.questions ? (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      ) : (
        <>
          <Formik
            initialValues={{
              answer: "",
            }}
            onSubmit={handleSubmite}
          >
            {({ values }) => (
              <AppScreen className={s.quiz}>
                <Card loading={loading} bordered={false}>
                  <H1>{"Challenge " + quiz?.quizz?.title}</H1>

                  <Form className={s.quiz__content}>
                    <div
                      style={{
                        textAlign: "right",
                        marginBottom: "20px"
                      }}
                    >
                      <Popconfirm
                        title="Tu veux vraiment quitter ?"
                        onConfirm={handleConfirm}
                        onCancel={handleCancel}
                        okText="Oui"
                        cancelText="Non"
                      >
                        <AppButton className={s.__btn__back}>Quitter</AppButton>
                      </Popconfirm>
                    </div>
                    {loading && (
                      <div>
                        <Skeleton active />
                        <Skeleton active />
                        <Skeleton active />
                      </div>
                    )}
                    {!loading && (
                      <Fragment>
                        <Row justify="space-between">
                          <Col span={6}></Col>
                          <Col span={12}>
                            <Tooltip title={quiz?.title}>
                              <H2 className={s.__title}>
                                {/* {quiz?.title}{" "} */}
                                <Latex>
                                  {quiz?.title
                                    ? quiz?.title
                                      ?.replaceAll("amp;", "")
                                      ?.replaceAll("<br />", "")
                                    : ""}
                                </Latex>
                              </H2>
                            </Tooltip>
                          </Col>
                        </Row>
                        <div className={s.header}>
                          <div className={s.left}>
                            <Avatar
                              size={84}
                              src={user?.profile}
                              alt="teacher avatar"
                              style={{
                                position: "relative",
                              }}
                            />
                          </div>
                          <div className={s.center}>
                            {!isFinish && !TimeElapsed && (
                              <Fragment>
                                <Row justify="center">
                                  <Image
                                    preview={false}
                                    src={Clock}
                                    alt="teacher avatar"
                                  />
                                </Row>
                                <Row justify="space-between" align="middle">
                                  <Col></Col>
                                  <Col span={2}>
                                    <div className={s.count__down}>
                                      <CountdownTimer
                                        targetDate={dateTimeAfterThreeDays}
                                        setIsVisble={setIsVisble}
                                        setTimeElapsed={setTimeElapsed}
                                        setIsFinish={setIsFinish}
                                        setDrawer={setDrawer}
                                      />
                                    </div>
                                  </Col>
                                  <Col>
                                    <span>
                                      {current}/{quiz?.quizz.questions?.length}
                                    </span>
                                  </Col>
                                </Row>
                              </Fragment>
                            )}

                            <div>
                              <SkillBar width={progress} />
                            </div>
                          </div>
                          <div className={s.right}>
                            <Image
                              preview={false}
                              // width={100}
                              className={s.__iconTrophy}
                              src={Trophe}
                              alt="teacher avatar"
                            />
                          </div>
                        </div>

                        <div className={s.main}>
                          <input type="studentId" hidden value={user?._id} />
                          <h3>
                            <AppLatex
                              content={quiz?.quizz?.questions[current]?.title}
                            />
                          </h3>

                          {quiz?.quizz?.questions[current]?.propositions?.map(
                            (proposition: any) => (
                              <>
                                <div
                                  className={s.card}
                                  ref={ref}
                                  onClick={handleClick}
                                >
                                  <label>
                                    <Field
                                      name="answer"
                                      id={proposition?._id}
                                      value={proposition?._id}
                                      type="radio"
                                      className={`${s.cb} ${s.pristine}`}
                                    />{" "}
                                    <span className={s.proposition}>
                                      {proposition?.title}
                                    </span>
                                  </label>
                                  {/* <div className={s.checkbox}>
                                  <Field
                                    name="answer"
                                    id={proposition?._id}
                                    className={s.chk}
                                    type="radio"
                                    value={proposition?._id}
                                  />
                                  <label htmlFor={proposition?._id}>
                                    {proposition?.title}
                                  </label>
                                </div> */}
                                </div>
                              </>
                            )
                          )}
                        </div>

                        {isVisible && (
                          <Row
                            justify="center"
                            style={{ marginTop: "var(--mb-1)" }}
                          >
                            <AppButton
                              // onClick={handleProgress}
                              disabled={!validation}
                              htmlTypeSubmit={true}
                            >
                              valider
                            </AppButton>
                          </Row>
                        )}

                        {isFinish && (
                          <Row justify="center" align="middle">
                            <Col>
                              <Result
                                status="success"
                                title="Quiz terminé !"
                                extra={[
                                  <div
                                    style={{
                                      display: "flex",
                                      transform: "translateX(-15px)",
                                    }}
                                  >
                                    <AppButton
                                      onClick={() => setDrawer(!drawer)}
                                      className={s.___result__btn}
                                    >
                                      Resultat du quiz
                                    </AppButton>
                                    <AppButton
                                      style={{
                                        marginLeft: "10px",
                                      }}
                                      className={s.___result__btn}
                                      onClick={() => navigate(-1)}
                                      icon={<ArrowLeftOutlined />}
                                    >
                                      Retour à la liste
                                    </AppButton>
                                  </div>,
                                ]}
                              />
                            </Col>
                          </Row>
                        )}
                      </Fragment>
                    )}

                    <input type="studentId" hidden value={user?._id} />
                  </Form>
                </Card>
              </AppScreen>
            )}
          </Formik>
        </>
      )}

      {/* <Modal
        title={false}
        centered
        visible={ModalResult}
        footer={false}
        width={1000}
        onCancel={() => setModalResult(false)}
      >
        <Row justify="center">
          <H2>Quiz terminé</H2>
        </Row>

        <Result
          status="success"
          title={finalResultQuiz?.content}
          // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
          extra={[
            <AppButton onClick={() => setModalResult(false)}>Fermer</AppButton>,
          ]}
        />
      </Modal> */}
      <AppDrawer
        onClose={() => setDrawer(!drawer)}
        visible={drawer}
        title={
          <H2 className={s.__result__title}>{`${finalResultQuiz?.content || "Quiz terminé"
            }`}</H2>
        }
      >
        <div style={{ overflow: "hidden" }}>
          <div style={{ textAlign: "center" }}>
            <H2>
              Temps de quiz :{" "}
              {formatTimestamp(localStorage.getItem("quizTime") as any)}
            </H2>
          </div>

          <Row justify="center">
            <Col>
              <Result
                style={{ padding: 0 }}
                icon={
                  <>
                    {finalResultQuiz?.max <= 24 && (
                      <Image src={AlbertSad} width={100} preview={false} />
                    )}
                    {finalResultQuiz?.max >= 25 &&
                      finalResultQuiz?.max <= 49 && (
                        <Image src={AlbertNeutre} width={100} preview={false} />
                      )}

                    {finalResultQuiz?.max >= 50 &&
                      finalResultQuiz?.max <= 74 && (
                        <Image src={AlbertHappy} width={100} preview={false} />
                      )}

                    {finalResultQuiz?.max >= 75 &&
                      finalResultQuiz?.max <= 100 && (
                        <Image
                          src={AlbertVeryHappy}
                          width={100}
                          preview={false}
                        />
                      )}
                  </>
                }
              />
            </Col>
          </Row>
          {response?.map((res: any) => (
            <CardResult res={res} />
          ))}
        </div>
      </AppDrawer>
    </React.Fragment>
  );
};

export default StudentChallengeQuiz;
