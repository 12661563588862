export const API_BASE_URL = (idCours: string, difficulty: string) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES
  }/quizz?chapterId=${idCours}&quizzLevel=${difficulty === undefined ? "Facile,Moyen,Difficile" : difficulty
  }&status=PUBLISHED`;

export const FIND_QUIZ_FOR_COURS = (idCours: string) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/chapters?course=${idCours}&status=PUBLISHED`;

export const FIND_FILTER_QUIZ_FOR_COURS = (
  idCours: string,
  difficulty: string,
  duration: string
) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/quizz?chapterId=${idCours}&quizzLevel=${difficulty}&duration=${duration}&status=PUBLISHED`;

export const FIND_FINISH_QUIZ = (idUser: string, idQuiz: string) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/quizz/finish/feedback/${idUser}/${idQuiz}`;

export const API_RESSOURCES = `${process.env.REACT_APP_API_URL_RESSOURCES}`;
