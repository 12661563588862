import { FC } from "react";

import StartIcon from "assets/images/students/teacherStart.svg";
import Teacher from "assets/images/teacher/teacher.svg";
import QuoteIcon from "../../../../../assets/images/app/quote_icon.svg";

//!STYLES
import s from "./assets/style.module.css";

type Props = {
  av: any;
};
const CardAvis: FC<Props> = ({ av }) => {
  return (
    <div className={s.card}>
      <div className={s.avatarBox}>
        <img src={av?.studId?.profile || Teacher} alt="" className={s.avatarPicture} />
      </div>
      <div className={s.description}>
        <span className={s.name}>{av?.studId?.firstname?.split(" ")[0]}{" "} {av?.studId?.lastname?.split(" ")[0]}</span>
        <span className={s.content}>{av?.content}</span>
        <span>
          <strong>{av?.rate || 0}</strong>
          <img
            src={StartIcon}
            alt=""
            height={15}
            style={{ position: "relative", top: "-2px", marginLeft: "5px" }}
          />
        </span>
      </div>
      <img src={QuoteIcon}></img>
    </div>
  );
};

export default CardAvis;
