import axios from "axios";
import { ApiResponse } from "common/interfaces";
import { API_BASE_URL } from "./conversation.api.routes";
import { CreateConversationDto,UpdateConversationDto} from "./conversation.interface";

export const CreateConversation = async (
  conversation: CreateConversationDto
): Promise<ApiResponse | undefined> => {
  try {
    // const token = await getToken();
    const response = await axios.post(API_BASE_URL, conversation);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const updateConversation = async (
  conversationId:string, seen: UpdateConversationDto
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.patch(`${API_BASE_URL}/${conversationId}`, seen);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getConversation = async (talkId:string,senderId:string): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/getConversation`, {talkId,senderId});
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
