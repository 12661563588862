export const findAll = (id: string | undefined) =>
  `${process.env.REACT_APP_API_URL}/classes`;

export const CREATE_ROOM = `${process.env.REACT_APP_API_URL_RESSOURCES}/classes`;

export const getRoom = `${process.env.REACT_APP_API_URL_RESSOURCES}/classes`;

export const getRoomById = (id: string) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/classes/${id}`;

export const getRegistredStudents = (id: string, search?: string) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/classes/${id}/registrations${search !== undefined ? "?search=" + search : ""
  }`;

export const findByLevel = (id: any | undefined, teacher: any | undefined) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/classes?levelId=${id}&teacher=${teacher}`;

export const findByUser = (teacher: any | undefined) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/classes?teacher=${teacher}`;

export const API_RESSOUCES = `${process.env.REACT_APP_API_URL_RESSOURCES}`;
export const API_CLASSES = `${process.env.REACT_APP_API_URL_RESSOURCES}/classes`;
