import FadeInOnScroll from "components/FadeIn";
import FadeInUpOnScroll from "components/FadeInUp";
import { useState } from "react";
import ellipse_mission from "../../../../../assets/images/app/home/ellipse_mission.svg";
import fleche_mission_verte from "../../../../../assets/images/app/home/fleche_mission_verte.svg";
import icon_formation from "../../../../../assets/images/app/home/icon_formation.png";
import icon_innovation from "../../../../../assets/images/app/home/icon_innovation.png";
import icon_soutien from "../../../../../assets/images/app/home/icon_soutien.png";
import student_mission_1 from "../../../../../assets/images/app/home/student_mission_1.png";
import student_mission_2 from "../../../../../assets/images/app/home/student_mission_2.png";
import student_mission_3 from "../../../../../assets/images/app/home/student_mission_3.png";
import student_mission_4 from "../../../../../assets/images/app/home/student_mission_4.png";
import trace_mission from "../../../../../assets/images/app/home/trace_mission.svg";
import st from "../../../common.module.css";
import s from "./style.module.css";


const OurMission = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.mainContainer}>
            <div className={s.mainBox}>
                <FadeInUpOnScroll>
                    <h1 className={s.title}>Notre Mission</h1>
                </FadeInUpOnScroll>
                <img src={ellipse_mission} className={s.titleEllipse}></img>
                <FadeInOnScroll>
                    {isMobile ? <p className={s.subtitle}>Inspirer et transformer l’éducation, en rendant<br />chaque jour d’apprentissage plus impactant,<br />plus enrichissant et accessible à tous.</p> : <p className={s.subtitle}>Inspirer et transformer l’éducation, en rendant chaque jour d’apprentissage<br />plus impactant, plus enrichissant et accessible à tous.</p>}
                </FadeInOnScroll>
                <div className={s.boxes}>
                    <div className={`${st.scaleAnimation} ${s.boxOneTwo}`}>
                        <img src={icon_formation} style={{ height: "40px" }}></img>
                        <div className={s.box}>
                            <div className={s.boxTitle}>Excellence</div>
                            <a className={s.boxSubtitle}>Pour tous</a>
                        </div>
                    </div>
                    <div className={`${st.scaleAnimation} ${s.boxOneTwo}`}>
                        <img src={icon_innovation} style={{ height: "40px" }}></img>
                        <div className={s.box}>
                            <div className={s.boxTitle}>Innovation</div>
                            <a className={s.boxSubtitle}>Dans l’apprentissage</a>
                        </div>
                    </div>
                    <div className={`${st.scaleAnimation} ${s.boxThree}`}>
                        <img src={icon_soutien} style={{ height: "40px" }}></img>
                        <div className={s.box}>
                            <div className={s.boxTitle}>Soutien</div>
                            <a className={s.boxSubtitle}>Personnalisé</a>
                        </div>
                    </div>
                </div>
                <img src={fleche_mission_verte} className={s.flecheVerte}></img>
                <div className={s.messageBox}>
                    <div className={s.secondBox}>
                        <div className={s.textBox}>
                            {!isMobile ? <p className={s.textBoxDescription}>
                                <span style={{ fontWeight: "bold", color: "rgb(8, 7, 7)" }}>« Mon ami Albert »</span> aspire à redéfinir<br />l’apprentissage en fusionnant innovation<br />pédagogique et technologie, pour éliminer les<br />obstacles à une éducation de premier<br />choix et préparer une génération prête à<br />relever les défis de demain.
                            </p> : <p className={s.textBoxDescription}>
                                <span style={{ fontWeight: "bold", color: "rgb(8, 7, 7)" }}>« Mon ami Albert »</span> aspire à redéfinir l’apprentissage<br />en fusionnant innovationpédagogique et<br />technologie, pour éliminer les obstacles à une<br />éducation de premier choix et préparer une<br />génération prête à relever les défis de demain.
                            </p>}
                        </div>
                        <div className={s.imagesBox}>
                            <div className={s.studentOneBox}>
                                <img src={student_mission_1} className={s.studentOneBoxImage}></img>
                            </div>
                            <div className={s.mainImageBox}>
                                <div className={s.secondaryImageBox}>
                                    <div className={s.studentTwoBox}>
                                        <img src={student_mission_2} className={s.studentTwoBoxImage}></img>
                                    </div>
                                    <div className={s.studentThreeBox}>
                                        <img src={student_mission_3} className={s.studentThreeBoxImage}></img>
                                    </div>
                                </div>
                                <div className={s.studentFourBox}>
                                    <img src={student_mission_4} className={s.studentFourBoxImage}></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!isMobile && <img src={trace_mission} style={{ position: "relative", left: "50px", width: "50px", top: "-160px" }}></img>}
            </div>
        </div>
    );
};

export default OurMission;