import { Col, Form, Input, Row, Select, Space, Steps } from "antd";
import React, { FC, useCallback, useState } from "react";

//API
import * as EXERCISES_API from "api/services/exercises/exercises.services";
import { findAllExercises } from "store/exercises/exercisesSlice";
import * as TOPICS_SERVICES from "../../../api/services/topics/topics.services";

//!REDUX
import { useDispatch, useSelector } from "react-redux";

//!COMPONENTS
import H1 from "components/libs/title";
import AppScreen from "../ScreenWithSidebar";

//!CONFIGS
import * as appRoutes from "config/routes.config";

//STYLES
import { CHOICE, StatusCode, TypeExercices } from "common/enums";
import Breadcrumbs from "components/breadcrumbs";
import AppButton from "components/libs/button";
import H2 from "components/libs/subtitle";
import s from "./assets/cerateExo.module.css";

import { fetchCoursForTopic } from "api/services/courses/courses.services";
import { CKEditor } from "ckeditor4-react";
import Container from "components/container";
import HelpPopover from "components/help";
import AppSelectGroup from "components/libs/selectGroup";
import MathScan from "components/maths";
import ExercicePreview from "components/previewExo";
import UploadCloudinary from "components/uploadCloudinary";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../../assets/images/app/back.svg";

const { Option } = Select;
const { Step } = Steps;
const { Search } = Input;

interface ExerciseData {
  exercise: {
    courseId: string;
    teacherId: string;
    chapterId: string;
    type: string;
    // add more properties here as needed
  };
  clues: any[];
  solutions: any[];
}

const CreateExercice: FC = () => {
  //!HOOKS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const topics = useSelector((state) => state.topics);
  const [form] = Form.useForm();

  //!STATE
  const [tempTopic, setTempTopic] = useState<any>();
  const [course, setCourse] = useState<any>(null);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [firstStepData, setFirstStepData] = useState<any>({});
  const [isAddingExercise, setIsAddingExercise] = useState<boolean>(false);
  const [isAddingExerciseBrouillon, setIsAddingExerciseBrouillon] =
    useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [preview, setPreview] = useState<any>([]);
  const [currentCourses, setCurrentCourses] = useState<any>(undefined);
  const [first, setfirst] = useState<string>("1");

  const OnSelectCours = useCallback(
    async (courseId: any) => {
      setIsLoading(true);
      try {
        if (courseId) {
          const response = await fetchCoursForTopic(courseId);
          if (response?.statusCode === StatusCode.OK) {
            setCourse(response?.data);
            setIsDisabled(false);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    },
    [course, isDisabled]
  );

  const { user } = useSelector((s) => s.auth);

  const createExercise = async (exercise: any) => {
    if (currentStep === 1) {
      setFirstStepData(exercise);
      return setCurrentStep(2);
    }
    exercise.exercise.courseId = firstStepData?.courseId;

    try {
      const finalData = {
        exercise: {
          ...exercise?.exercise,
          teacherId: user?._id,
          chapterId: firstStepData?.chapterId,
          type: firstStepData?.type,
        },
        clues: [exercise?.clues],
        solutions: [exercise?.solutions],
      };
      let response: any;
      if (first === "1") {
        setIsAddingExercise(true);
        response = await EXERCISES_API.create(
          CHOICE.SAVE_PERMANENTLY,
          finalData
        );
      }

      if (first === "2") {
        setIsAddingExerciseBrouillon(true);
        response = await EXERCISES_API.create(CHOICE.SAVE_AS_DRAFT, finalData);
      }

      if (response?.statusCode === StatusCode.CREATED) {
        setCurrentStep(1);
        setIsAddingExercise(false);
        setIsAddingExerciseBrouillon(false);
        dispatch(findAllExercises());
        navigate(appRoutes.TEACHER_EXERCICES);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsAddingExercise(false);
      setIsAddingExerciseBrouillon(false);
    }
  };

  const OnSelectLevel = useCallback(
    async (levelId: string) => {
      setIsLoading(true);
      try {
        const response = await TOPICS_SERVICES.findAll(levelId);

        if (response?.statusCode === StatusCode.OK) {
          setTempTopic(response?.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    },
    [tempTopic]
  );

  const previewEdit = (_: any, allFields: any) => {
    let content = allFields.filter((x: any) => {
      return x?.touched === true;
    });

    setPreview(content);
  };

  return (
    <Form
      form={form}
      onFinish={createExercise}
      style={{ padding: 20 }}
      layout="vertical"
      onFieldsChange={previewEdit}
    >
      <React.Fragment>
        <Breadcrumbs isCreateExo={true} isBack />
        {currentStep === 1 && (
          <AppScreen className={s.exo}>
            <H1>Nouvel exercice</H1>
            <div className={s.teacher__maa} />
            <div className={s.form__container}>
              <div className={`${s.select}`}>
                <Form.Item
                  name="levelId"
                  style={{ marginBottom: 0 }}
                  rules={[
                    { required: true, message: "Veuillez choisir un niveau" },
                  ]}
                >
                  <AppSelectGroup
                    placeholder="Choisir un niveau"
                    onChange={(value: string) => OnSelectLevel(value)}
                  />
                </Form.Item>
              </div>

              <div className={`${s.select}`}>
                <Form.Item
                  name="courseId"
                  style={{ marginBottom: 0 }}
                  rules={[
                    { required: true, message: "Veuillez choisir un niveau" },
                  ]}
                >
                  <Select
                    placeholder="Choisir une matière"
                    allowClear
                    bordered={false}
                    loading={topics.loading}
                    onChange={(courseId: string) => OnSelectCours(courseId)}
                  >
                    {tempTopic?.map((topic: any) => (
                      <Option key={topic._id} value={topic._id}>
                        {topic.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div className={`${s.select}`}>
                <Form.Item
                  name={"chapterId"}
                  rules={[
                    { required: true, message: "Veuillez choisir un niveau" },
                  ]}
                >
                  <Select
                    placeholder="Choisir un cours"
                    allowClear
                    showSearch
                    bordered={false}
                    loading={isDisabled}
                    disabled={isDisabled}
                    optionFilterProp="children"
                    filterOption={(input: string, option: any) =>
                      (option?.label.toLowerCase() ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={course?.map((course: any) => {
                      return {
                        label: course.title,
                        value: course._id,
                      };
                    })}
                    onChange={(_, value: string) => setCurrentCourses(value)}
                  />
                </Form.Item>
              </div>

              <div className={`${s.select}`}>
                <Form.Item
                  name={"type"}
                  rules={[
                    { required: true, message: "Veuillez choisir un type" },
                  ]}
                >
                  <Select
                    placeholder="Choisir un type"
                    allowClear
                    bordered={false}
                  // loading={allCourses.loading}
                  // disabled={isDisabled}
                  >
                    <Option value={TypeExercices.ApplicationsDeCours}>
                      {TypeExercices.ApplicationsDeCours}
                    </Option>
                    <Option value={TypeExercices.Classiques}>
                      {TypeExercices.Classiques}
                    </Option>
                    <Option value={TypeExercices.Annales}>
                      {TypeExercices.Annales}
                    </Option>
                    <Option value={TypeExercices.Entraînement}>
                      {TypeExercices.Entraînement}
                    </Option>
                  </Select>
                </Form.Item>
              </div>

              <Row justify="center">
                <AppButton htmlTypeSubmit={true}>suivant</AppButton>
              </Row>
            </div>
          </AppScreen>
        )}

        {currentStep === 2 && (
          <Container>
            <div className={s.header}>
              <div className={s.left}>
                <H2 className={s.subtitle}>Nouvel exercice</H2>
              </div>

              <div className={s.center} />

              <div className={s.right}>
                <AppButton
                  onClick={() => setCurrentStep((c) => c - 1)}
                  icon={
                    <BackIcon
                      style={{
                        position: "relative",
                        top: "4px",
                        left: "-10px",
                        width: "20px",
                      }}
                    />
                  }
                  className={s.header__button}
                >
                  Retour
                </AppButton>
              </div>
            </div>

            <div className={s.main__title}>
              <h3>Cours associé : {currentCourses?.label}</h3>
              <div style={{ position: "relative", top: "-30px", left: "95%" }}><HelpPopover id={"nouvel_exercice"} /></div>
            </div>

            <div className={s.main}>
              <div className={s.form}>
                <div className={s.label}>
                  <label htmlFor="">Titre de l’exercice</label>
                </div>
                <div className={s.input}>
                  <Form.Item
                    name={["exercise", "title"]}
                    rules={[
                      {
                        required: true,
                        message: "Ce champ est requis",
                      },
                    ]}
                  >
                    <Input placeholder="" bordered={false} />
                  </Form.Item>
                </div>

                <div>
                  <div className={s.label}>
                    {" "}
                    <label htmlFor="">Contenu de l’énoncé</label>{" "}
                  </div>

                  <div className={s.__custom__ck_editor}>
                    <Space>
                      <UploadCloudinary />
                      <MathScan />
                    </Space>
                    <Form.Item
                      name={["exercise", "description"]}
                      valuePropName="data"
                      getValueFromEvent={(event) => {
                        const data = event.editor.getData();
                        return data;
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Ce champ est requis",
                        },
                      ]}
                    >
                      <CKEditor config={{
                        language: 'fr',
                      }} style={{ border: "none" }} />
                    </Form.Item>
                  </div>
                </div>
                {/* indice */}
                <div className={s.label}>
                  <label htmlFor="">Intitulé indice</label>
                </div>
                <div className={s.__custom__ck_editor}>
                  <Space>
                    <UploadCloudinary />
                    <MathScan />
                  </Space>
                  <Form.Item
                    name={["clues", "title"]}
                    valuePropName="data"
                    getValueFromEvent={(event) => {
                      const data = event.editor.getData();
                      return data;
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Ce champ est requis",
                      },
                    ]}
                  >
                    <CKEditor config={{
                      language: 'fr', // Set the language to French
                    }} style={{ border: "none" }} />
                  </Form.Item>
                </div>
                {/* solution */}
                <div className={s.label}>
                  <label htmlFor="">Intitulé solution</label>
                </div>
                <div className={s.__custom__ck_editor}>
                  <Space>
                    <UploadCloudinary />
                    <MathScan />
                  </Space>
                  <Form.Item
                    name={["solutions", "title"]}
                    valuePropName="data"
                    getValueFromEvent={(event) => {
                      const data = event.editor.getData();
                      return data;
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Ce champ est requis",
                      },
                    ]}
                  >
                    <CKEditor config={{
                      language: 'fr', // Set the language to French
                    }} style={{ border: "none" }} />
                  </Form.Item>
                </div>
              </div>

              <div className={s.preview}>
                <ExercicePreview data={preview} />
              </div>
            </div>

            <Row justify="center" gutter={12}>
              <Col>
                <AppButton
                  className={s.__submit__validation}
                  loading={isAddingExerciseBrouillon}
                  htmlTypeSubmit={true}
                  onClick={() => setfirst("2")}
                >
                  enregistrer
                </AppButton>
              </Col>
              <Col>
                <AppButton
                  className={s.__submit__validation}
                  loading={isAddingExercise}
                  htmlTypeSubmit={true}
                  onClick={() => setfirst("1")}
                >
                  Soumettre pour validation
                </AppButton>
              </Col>
            </Row>
          </Container>
        )}
      </React.Fragment>

      <Form.Item
        noStyle
        name="chapterId"
        initialValue={firstStepData?.chapterId}
      >
        <Input placeholder="sous titre" hidden />
      </Form.Item>
    </Form>
  );
};

export default CreateExercice;
