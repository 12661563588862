export const API_BASE_URL = (idChapter: string) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/exercices?chapterId=${idChapter}&status=PUBLISHED`;

export const API_BASE_URL_EXO = (exercice: string) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/exercices/${exercice}`;

export const API_BASE_URL_EXO_SOLUTION = (exercice: string) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/exercices/${exercice}/solutions`;

export const API_BASE_URL_EXO_CLUES = (exercice: string) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/exercices/${exercice}/clues`;
