import { findOne } from "api/services/chapters/chapters.services";
import { useState, useEffect } from "react";

type Chapter = {
  _id?: string;
  title?: string;
  description?: string;
  createdAt?: Date;
  updatedAt?: Date;
};

const useChapter = (id: string) => {
  //!STATE
  const [data, setData] = useState<Chapter>({});
  const [loading, setLoading] = useState<Boolean>(true);
  const [error, setError] = useState<string>("");

  const fetchCourse = async (passedId: string) => {
    try {
      const response = await findOne(passedId);
      setData(response?.data);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCourse(id);
  }, [id]);

  return { data, loading, error };
};

export default useChapter;
