import { Drawer, Image, Skeleton } from 'antd';
import * as appRoutes from "config/routes.config";
import React, { useEffect, useState } from 'react';

import s from "./style.module.css";

//IMAGES
import { UserRoles, setIcon } from 'common/enums';
import StudentNavBar from 'components/StudentNavBar';
import { SponsorDrawerPaiement } from 'components/paiementSponsor';
import { useNavigate } from 'react-router-dom';
import ParentStudentCalendar from 'screens/student/studentCalendar';

import Ads from "assets/images/app/header/ads.svg";
import Calendar from "assets/images/app/header/calendar.svg";
import Performance from "assets/images/app/header/performance.svg";
import Wallet from "assets/images/app/header/wallet.svg";
import SendUpdatePasswordRequestDrawer from 'components/updatePasswordDrawer/sendUpdateLink';
import LineChartComponent from 'screens/student/stats/components/lineChart';
import RadarChartComponent from 'screens/student/stats/components/radarChart';
import Access from "../../assets/images/app/access.svg";

type Props = {
    userData: any;
    statsData: any;
    studentId: any;
    loading: boolean;
    user: any;
    generalStatsData?: any;
    avgGeneral?: any;
}

const ParentOrganismeStudentStats: React.FC<Props> = ({ userData, statsData, studentId, loading, user, generalStatsData, avgGeneral }) => {

    const navigate = useNavigate();
    const isParent = user?.roles?.includes(UserRoles.PARENT);
    const isTeacher = user?.roles?.includes(UserRoles.TEACHER);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    const [calendarDrawerVisibility, setCalendarDrawerVisibility] = useState<boolean>(false);
    const closeCalendarDrawer = async () => {
        setCalendarDrawerVisibility(false);
    }

    const [walletDrawerVisibility, setWalletDrawerVisibility] = useState<boolean>(false);
    const closeWalletDrawer = async () => {
        setWalletDrawerVisibility(false);
    }

    const [passwordDrawerVisible, setPasswordDrawerVisible] = useState(false);
    const onClosePasswordDrawer = () => {
        setPasswordDrawerVisible(false);
    };

    const MENU = [
        {
            id: 1,
            name: "Accès",
            icon: Access,
            iconSize: 25,
            link: "",
        },
        {
            id: 2,
            name: "Historique",
            icon: Ads,
            iconSize: 35,
            link: `${appRoutes.STUDENT_HISTORY}/${studentId}`,
        },
        {
            id: 3,
            name: "Performance",
            icon: Performance,
            iconSize: 35,
            link: {
                pathname: `${appRoutes.STUDENT_STATISTIQUES}/${studentId}`,
                state: generalStatsData,
            },
        },
        {
            id: 4,
            name: "Portefeuille",
            icon: Wallet,
            iconSize: 35,
            link: "",
        },
        {
            id: 5,
            name: "Calendrier",
            icon: Calendar,
            iconSize: 35,
            link: "",
        },
    ];

    const doAction = async (name: string) => {

        if (name === "Accès") {
            setPasswordDrawerVisible(true)
        }
        else if (name === "Calendrier") {
            setCalendarDrawerVisibility(true)
        }
        else if (name === "Portefeuille") {
            setWalletDrawerVisibility(true)
        }
        else if (name === "Performance") {

        }
        else {
            isParent ? navigate(`${appRoutes.STUDENT_HISTORY}/${studentId}`, { state: generalStatsData }) : navigate(`${appRoutes.ORGANISME_STUDENT_HISTORY}/${studentId}`, { state: generalStatsData });
        }
    }

    useEffect(() => {
        console.log(generalStatsData);
    }, [generalStatsData]);

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", margin: "0px", padding: "0px", position: "relative", top: "-30px" }}>
                {!isTeacher && <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", height: "100px" }}>
                    {loading ? <Skeleton active /> : <StudentNavBar userData={userData} />}
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {MENU.map((item) => (
                            <ul style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", fontSize: "1.2em", fontWeight: "bolder", marginLeft: "10px" }}>
                                <li>
                                    <Image
                                        preview={false}
                                        src={item.icon}
                                        width={item?.iconSize || 29}
                                        onClick={() => doAction(item?.name)}
                                        style={{ cursor: "pointer" }}
                                    />
                                </li>
                                <li>
                                    {" "}
                                    <span style={{ fontWeight: item.name === "Performance" ? "500" : "normal", color: "#7FB1B2", cursor: "pointer" }} onClick={() => doAction(item?.name)}>{item.name}</span>
                                </li>
                            </ul>
                        ))}
                    </div>
                </div>}
                <div className={!isTeacher ? s.lineBox : s.lineBoxTeacher}>
                    {loading ? <Skeleton active /> : <div className={s.statsContainer}>
                        <div className={s.radarContainer}>
                            <a className={s.boxTitle}>Performance</a>
                            <div className={s.radarBox}>
                                <a className={s.radarTitle}>Moyenne générale</a>
                                <div className={s.radarMarkBox}>
                                    {<a className={s.radarAVG}>{generalStatsData?.avgGenerale ?? "0"} </a>}
                                    <a className={s.radarMaxAVG}> / 20</a>
                                </div>
                                {<div className={s.radarChartBox}><RadarChartComponent data={generalStatsData?.courseData ?? {}} /></div>}
                            </div>
                        </div>
                        <div className={s.lineContainer}>
                            <div className={!isTeacher ? s.lineBox : s.lineBoxTeacher}>
                                {statsData.map((userData: any) => (
                                    <div className={s.lineChartCard}>
                                        <div className={s.lineChartCardHeader}>
                                            <div className={s.lineTitle} onClick={() => { }}><img src={setIcon(userData.icon)} height={30} /> {userData.title}</div>
                                            {userData.avg !== 0 && <div className={s.lineChartCardAVG}>{userData.avg}</div>}
                                        </div>
                                        <LineChartComponent data={userData.stats} chartTitle={userData.title} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
            <Drawer
                placement="right"
                closable={true}
                onClose={closeCalendarDrawer}
                visible={calendarDrawerVisibility}
                width={isMobile ? "100%" : "70%"}
                headerStyle={{ display: "flex", justifyContent: "space-between" }}
                bodyStyle={{ background: "#FFF", padding: "0px" }}
            >
                <div style={{ alignItems: "flex-start", margin: "40px auto" }}>
                    <ParentStudentCalendar user={userData} />
                </div>
            </Drawer>
            <Drawer
                placement="right"
                closable={true}
                onClose={closeWalletDrawer}
                visible={walletDrawerVisibility}
                width={isMobile ? "100%" : "40%"}
                headerStyle={{ display: "flex", justifyContent: "space-between" }}
                bodyStyle={{ background: "#FFF", padding: "0px" }}
            >
                <div style={{ alignItems: "flex-start", margin: "40px auto" }}>
                    <SponsorDrawerPaiement currentUser={userData} type="student" />
                </div>
            </Drawer>
            <SendUpdatePasswordRequestDrawer visible={passwordDrawerVisible} onClose={onClosePasswordDrawer} userData={userData} />
        </>
    );
};

export default ParentOrganismeStudentStats;