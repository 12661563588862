import { FormOutlined } from "@ant-design/icons";
import { Button, Drawer, Popconfirm, Row, Skeleton, Space, Typography } from "antd";
import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

//!STYLES
import Col from "antd/es/grid/col";
import { findReservationStudent } from "api/services/studentReservation/studentReservation.services";
import { API_RESSOURCES } from "api/services/teacher/teacher.routes";
import axios from "axios";
import { StatusCode } from "common/enums";
import { AppNotification } from "components/appNotification";
import AppResult from "components/appResults";
import AppButton from "components/libs/button";
import useWebhook from "hooks/useWebHook";
import { badRequest } from "messages";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setUserConnect } from "store/auth/authSlice";
import * as TEACHER_SERVICES from "../../../../api/services/teacher/teacher.services";
import TeacherCalendarDetails from "./drawerDetails";
import s from "./style.module.css";

//!IMAGES
import CertificationBadge from "components/certificationBadge";
import { Link } from "react-router-dom";
import IconCourse from "screens/student/searchTeacher/components/iconCourse";
import { handleClearNiveau, handleClearTopicStrings } from "utils/formats";
import ArrowBack from "../../../../assets/images/app/arrow_back.svg";
import FileDocument from "../../../../assets/images/app/file_document.svg";
import starCoins from "../../../../assets/images/app/star-coins.png";
import EnglishIcon from "../../../../assets/images/students/englishIcon.svg";
import FrenchIcon from "../../../../assets/images/students/frenchIcon.svg";
import StartIcon from "../../../../assets/images/students/teacherStart.svg";
import { default as Teacher } from "../../../../assets/images/teacher/teacher.svg";

type Props = {
  open: boolean;
  currentTeacher: any;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

interface Niche {
  date: string;
  level: {
    _id: string;
    title: string;
    levelGroup: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
  topic: {
    _id: string;
    title: string;
    level: string;
    status: string;
    position: number;
    language: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
    description: string;
    price: string;
  };
  timeRanges: string[];
  idCreneau: string;
  reservations: any[];
  status: any[];
  grouplevels: any[];
  id: number;
  day: string;
}

interface Obj {
  day: string;
  niches: Niche[];
}

const { Text } = Typography;

const morningHours = [
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
];

const afternoonHours = [
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
];

const nightHours = [
  "19:00",
  "19:30",
  "20:00",
  "20:30",
];

export const handleRemoveDoublonNiveau = (res: any[]) => {
  return res?.filter(
    (arr: any, index: number, self: any[]) =>
      index ===
      self?.findIndex(
        (t: any) =>
          t?.levelGroup === arr?.levelGroup &&
          t?.levelGroup?._id === arr?.levelGroup?._id
      )
  );
};

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  className: s.slider,
  responsive: [
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 3,
      },
    },
  ],
};

const TeacherCalendarForStudent: FC<Props> = ({
  setOpen,
  open,
  currentTeacher,
}) => {
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [sendWebhook] = useWebhook();
  const [creneau, setCreneau] = useState<any>([]);
  const [index, setIndex] = useState<number>(0);
  const [allData, setAllData] = useState<any>([]);
  const [allSuccessRes, setAllSuccessRes] = useState<any>([]);
  const [allCreneaux, setAllCreneaux] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAllData, setIsAllData] = useState<boolean>(true);
  const [drawerRes, setDrawerRes] = useState<boolean>(false);
  const [creditAllowed, setCreditAllowed] = useState<boolean>(false);
  const dateRef = useRef<(HTMLDivElement | null)[]>([]);
  const [confirmReservations, setConfirmRes] = useState<any[]>([]);

  const [currentCreneau, setCurrentCreneau] = useState<any>();
  const [selectedDay, setSelectedDay] = useState<any>();
  const [currentCreneauLength, setCurrentCreneauLength] = useState<number>(0);
  const [selectedTime, setSelectedTime] = useState<{ [day: string]: string[] }>({});

  // const {
  //   data: calendar,
  //   isLoading,
  //   isSuccess,
  //   refetch,
  // } = useGetCalendarTeacherQuery(currentTeacher?.teacher?._id);

  const isTimeInRange = (time: string, userData: any[]) => {
    return userData.some((data) => data.timeRanges.includes(time) && data.status !== "deleted");
  };

  const generateNiches = useCallback(
    (data: any) => {
      const niches = [];
      if (Array.isArray(data?.niches) && data?.niches?.length > 0) {
        const topics = Array.isArray(data?.niches[0]?.topic)
          ? data?.niches[0]?.topic
          : [];
        const levels = Array.isArray(data?.niches[0]?.level)
          ? data?.niches[0]?.level
          : [];
        const topicsLength = topics?.length;
        const levelsLength = levels?.length;

        for (let i = 0; i < topicsLength; i++) {
          for (let j = 0; j < levelsLength; j++) {
            const filteredTopics = data?.niches.map((n: any) =>
              Array.isArray(n.topic) && Array.isArray(n?.level)
                ? n?.topic.filter(
                  (t: any) =>
                    t?.level === n?.level[j]._id && t?.position === i + 1
                )
                : []
            );

            const foundTopic = filteredTopics?.find((n: any) => n?.length > 0);

            if (foundTopic) {
              const niche = {
                date: data.niches[0].date,
                level: data.niches[0].level[j],
                topic: foundTopic[j],
                timeRanges: data.niches[0].timeRanges[j],
                idCreneau: data.niches[0].idCreneau,
                reservations:
                  data.niches[0] && data.niches[0].reservations
                    ? data.niches[0].reservations[j] || []
                    : [],
                status: data.niches[0].status[j] || [],
                annonce: data.annonce,
                id: j + 1,
                creneauTypes: data.niches[0].creneauTypes[j] || [],
                grouplevels: data.niches[0].grouplevels[j] || "",
                day: data.day,
              };
              niches.push(niche);
            }
          }
        }
      }

      const output = { day: data.day, niches: niches };
      setCreneau(output);
      return output;
    },
    [creneau]
  );

  const sumPrices = useCallback(
    (arr: any[]) => {
      let total = 0;

      for (let obj of arr) {

        let price = obj?.topic?.price;

        if (typeof price !== "number") {

          price = Number(price);

          if (isNaN(price)) {
            throw new Error(
              "Chaque objet doit avoir une propriété 'price' numérique"
            );
          }
        }

        total += price;
      }

      return total;
    },
    [confirmReservations]
  );

  const fetchCalender = useCallback(
    async (idTeacher: string) => {
      setLoading(true);
      if (currentTeacher?.teacher?._id) {
        try {
          const response = await axios.get(
            `${API_RESSOURCES}/advertisements/fetch/ReelCalender/${idTeacher}/${user?._id}`
          );
          setAllData(response.data?.data || []);

          if (allCreneaux?.length <= 0) {
            const result = response.data?.data
              .filter((data: any) => data?.niches.length > 0)
              .map((niche: any) => generateNiches(niche));

            setAllCreneaux(result);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    },
    [currentTeacher?.teacher?._id]
  );

  const handleConfirmReservations = useCallback(async () => {
    if (!currentTeacher?._id || !creneau?.day || !confirmReservations?.length) {
      console.error("Missing required variables for confirming reservations");
      return;
    }

    try {
      const requests = confirmReservations.map((reservation) => {
        const finalData = {
          studentId: user?._id,
          profId: currentTeacher?.teacher?._id,
          advertisementId: reservation?.annonce?._id,
          level: reservation?.level?._id,
          topic: reservation?.topic?._id,
          range: reservation?.timeRanges,
          idCreneau: reservation?.idCreneau,
          grouplevels: reservation?.grouplevels,
          startDate:
            reservation?.day?.split("T")[0] +
            "T" +
            `${reservation?.timeRanges[0]}:00.000Z`,
          type:
            typeof reservation?.creneauTypes === "string"
              ? reservation?.creneauTypes
              : reservation?.creneauTypes.creneauTypes[0],
          grouplevel: reservation?.grouplevel,
        };

        return findReservationStudent(currentTeacher?._id, finalData);
      });

      const responses = await Promise.all(requests);

      responses.forEach((response, i) => {
        if (response?.status === "OK") {
          AppNotification(
            `Réservation ${i + 1} effectuée avec succès`,
            "AlbertHappy"
          );
          fetchCalender(currentTeacher?.teacher?._id);
          setAllSuccessRes((res: any) => [...res, response.data]);
          sendWebhook(response.data);
          localStorage.setItem("reservations", allSuccessRes);
          // setDrawerRes(true);
          setConfirmRes([]);
        } else {
          console.error(`Error confirming reservation ${i + 1}`, response);
          AppNotification(badRequest.error, "AlbertSad");
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.data?.status === "KO") {
          AppNotification(
            "Ce prof a déjà une réservation en cours.",
            "AlbertSad"
          );
          fetchCalender(currentTeacher?.teacher?._id);
        }
      } else {
        console.error("Error confirming reservations", error);
        AppNotification(badRequest.error, "AlbertSad");
      }
    }
  }, [currentTeacher, creneau, confirmReservations, allSuccessRes]);

  const handleStudentAttribution = async (teacherId: string) => {
    try {
      const response = await TEACHER_SERVICES.getStudentsAttibutions(teacherId, user?._id);
      if (response?.statusCode === StatusCode.OK) {
        const credit = response?.data[0]?.creditsForStudent;
        if (typeof credit === 'number') {
          setCreditAllowed(true);
        } else {
          setCreditAllowed(false);
        }
      } else {
        throw new Error("Error");
      }
    } catch (error) { }
  }

  /* const getButtonClassName = (time: string, userData: any[]) =>
    `${isTimeInRange(time, userData) ? selectedTime.includes(time) ? s.timeButtonActive : s.timeButton : s.timeButtonDisabled}`; */
  const getButtonClassName = (time: string, userData: any[], day: string) =>
    `${isTimeInRange(time, userData) ? (selectedTime[day]?.includes(time) ? s.timeButtonActive : s.timeButton) : s.timeButtonDisabled}`;

  const handleTimeClick = (time: string, creneau: any) => {
    /* setSelectedTime((prev: string[]) => {
      if (prev.includes(time)) {
        return prev.filter((item) => item !== time);
      } else {
        return [...prev, time];
      }
    }); */
    setSelectedTime((prev) => {
      const dayTimes = prev[creneau?.day] || [];
      const allTimes = [...morningHours, ...afternoonHours, ...nightHours]; // Combine all time ranges

      if (dayTimes.includes(time)) {
        // Remove time if it's already selected
        const updatedDayTimes = dayTimes.filter((t) => t !== time);
        return {
          ...prev,
          [creneau?.day]: updatedDayTimes,
        };
      } else {
        // Add time and calculate the range
        const updatedDayTimes = [...dayTimes, time];

        // Find the indices of all selected times
        const selectedIndices = updatedDayTimes.map((t) => allTimes.indexOf(t)).sort((a, b) => a - b);

        // Calculate the full range of times between the smallest and largest indices
        const rangeStart = selectedIndices[0];
        const rangeEnd = selectedIndices[selectedIndices.length - 1];
        const rangeTimes = allTimes.slice(rangeStart, rangeEnd + 1);

        return {
          ...prev,
          [creneau?.day]: rangeTimes,
        };
      }
    });

    setCurrentCreneau(creneau?.niches[0]);
    setCurrentCreneauLength(creneau?.niches?.length);
    setSelectedDay(creneau?.day);
  };

  const getTimeDifferenceInMinutes = (time1: string, time2: string) => {
    const [hours1, minutes1] = time1.split(":").map(Number);
    const [hours2, minutes2] = time2.split(":").map(Number);

    const totalMinutes1 = hours1 * 60 + minutes1;
    const totalMinutes2 = hours2 * 60 + minutes2;

    return Math.abs(totalMinutes2 - totalMinutes1);
  };

  const checkTimeDifferences = (selectedTimes: string[], allTimes: string[]) => {
    if (selectedTimes.length <= 1) return false;

    // Get indices of selected times in the allTimes array
    const selectedIndices = selectedTimes.map((time) => allTimes.indexOf(time)).sort((a, b) => a - b);

    // Check differences between consecutive selected times
    for (let i = 0; i < selectedIndices.length - 1; i++) {
      const currentIndex = selectedIndices[i];
      const nextIndex = selectedIndices[i + 1];

      // Calculate time difference
      const currentTime = allTimes[currentIndex];
      const nextTime = allTimes[nextIndex];
      const differenceInMinutes = getTimeDifferenceInMinutes(currentTime, nextTime);

      if (differenceInMinutes > 30) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    (async () => {
      if (open) {
        await fetchCalender(currentTeacher?.teacher?._id);
      }
    })();
  }, [open, fetchCalender]);

  useEffect(() => {
    if (open === false) {
      setAllData([]);
      setAllCreneaux([]);
      setConfirmRes([]);
      setAllSuccessRes([]);
    }
  }, [open]);

  useEffect(() => {
    console.log(currentTeacher);
    if (currentTeacher?.teacher?._id) {
      if (creneau?.day === confirmReservations[0]?.day) {
        // dateRef.current[index]?.click();
      }
    }
  }, [currentTeacher]);

  useEffect(() => {
    const filterReservations = (allCreneaux: any[]): any[] => {
      const reservations: any[] = allCreneaux.filter((creneau: any) => {
        return creneau.niches.some((niche: any) => {
          return niche.reservations && niche.reservations._id;
        });
      });

      const successReservations: any[] = reservations.reduce(
        (acc: any[], creneau: any) => {
          const niche = creneau.niches[0];
          if (niche.reservations) {
            acc.push({
              ...niche.reservations,
              topic: niche.topic,
            });
          }
          return acc;
        },
        []
      );

      return successReservations;
    };

    const myReservations: any[] = filterReservations(allCreneaux);

    setAllSuccessRes((res: any) => [...res, myReservations]);
  }, [open, allCreneaux]);

  useEffect(() => {
    if (open) {
      if (!isLoggedIn) {
        setOpen(false);
        dispatch(setUserConnect(true));
      }
    }
  }, [isLoggedIn, open]);

  useEffect(() => {
    handleStudentAttribution(currentTeacher?.teacher?._id);
  }, [currentTeacher, user]);

  useEffect(() => {
    // Logic to run whenever selectedTime is updated
    console.log("Selected times updated:", selectedTime);
    console.log("Current creneau:", currentCreneau);

    const times = selectedTime[selectedDay] || [];
    // Example: Check if any day has times with differences > 30 minutes
    if (times.length > 1) {
      // Sort the times to ensure the range is correct
      const sortedTimes = [...times].sort();

      const startTime = sortedTimes[0];
      const endTime = sortedTimes[sortedTimes.length - 1];

      // Calculate the difference in minutes
      const timeDifference = getTimeDifferenceInMinutes(startTime, endTime);

      console.log(`Day: ${selectedDay}, Start: ${startTime}, End: ${endTime}, Difference: ${timeDifference} minutes`);

      // Example condition: Warn if the difference exceeds 30 minutes
      if (timeDifference > 30) {
        console.warn(`Time difference exceeds 30 minutes for day: ${selectedDay}`);

        // Check if creditAllowed is defined and modify the item price if necessary
        if (creditAllowed && currentTeacher?.credits !== 0) {
          currentCreneau.topic.price = 1;

        }
        else {
          const level = currentCreneau?.level?.levelGroup;
          const matchingLevel = currentTeacher?.teacher?.groupLevels.find((l: any) => l.levelGroup === level);
          const price = matchingLevel ? matchingLevel.price : 0;
          console.log(price);
          currentCreneau.topic.price = (price * timeDifference) / 60;
          currentCreneau.timeRanges = [startTime, endTime];
        }
        console.log(currentCreneau);
        let prev = confirmReservations;
        let itemIndex = prev.indexOf(currentCreneau);
        if (itemIndex !== -1) {
          prev.splice(itemIndex, 1);
        } else {
          prev.push(currentCreneau);
        }
        setConfirmRes([...prev]);
      }
    }
  }, [selectedTime, currentCreneau, currentCreneauLength, selectedDay]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [isXsScreen, setIsXsScreen] = useState(window.innerWidth > 767 && window.innerWidth <= 1151);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth > 1151 && window.innerWidth <= 1321);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 1321 && window.innerWidth <= 1606);
  const [isNormalScreen, setIsNormalScreen] = useState(window.innerWidth > 1606 && window.innerWidth <= 1799);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
    setIsXsScreen(window.innerWidth > 767 && window.innerWidth <= 1151);
    setIsSmallScreen(window.innerWidth > 1151 && window.innerWidth <= 1321);
    setIsMediumScreen(window.innerWidth > 1321 && window.innerWidth <= 1606);
    setIsNormalScreen(window.innerWidth > 1606 && window.innerWidth <= 1799);
  };
  window.addEventListener('resize', handleResize);

  return (
    <Drawer
      className="drawer"
      onClose={() => {
        setOpen(false);
        setAllSuccessRes([]);
        setCreneau([]);
      }}
      visible={open}
      width={isMobile ? "100%" : isXsScreen ? "100%" : isSmallScreen ? "100%" : isMediumScreen ? "95%" : isNormalScreen ? "90%" : "70%"}
      title={
        <div className={s.drawerTitle}>
          Planning du prof
        </div>
      }
      headerStyle={{ border: "none", paddingTop: "50px", paddingLeft: "40px", backgroundColor: "transparent" }}
      closeIcon={
        <img
          src={ArrowBack}
          height={18}
          style={{}}
        ></img>
      }
      drawerStyle={{ backgroundColor: "#FFF", borderTopLeftRadius: "30px", borderBottomLeftRadius: "30px", boxShadow: "-3px 3px 34px #39393929" }}
    >
      {loading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <Fragment>
          <div className={s.mainContainer}>
            <div className={s.headerBox}>
              <div className={s.detailsBox}>
                <div className={s.avatarBox}>
                  <img src={currentTeacher?.teacher?.profile || Teacher} alt="Teacher" className={s.avatarPicture} />
                </div>
                <div className={s.nameBox}>
                  <div className={s.nameDetails}>
                    <span className={s.name}>{currentTeacher?.teacher?.firstname + " " + currentTeacher?.teacher?.lastname} {currentTeacher?.teacher?.certified && <CertificationBadge />}</span>
                    <span className={s.rateLanguages}>
                      {currentTeacher?.teacher?.rate || 0}
                      <img src={StartIcon} style={{ position: "relative", top: "-2px", marginRight: "10px", marginLeft: "5px" }} alt="Star" />
                      Je parle {currentTeacher?.teacher?.langues?.includes("fr") && <img src={FrenchIcon} alt="French" />} {currentTeacher?.teacher?.langues?.includes("en") && <img src={EnglishIcon} alt="English" />}
                    </span>
                    {currentTeacher?.teacher?.career !== "" && <span className={s.title} dangerouslySetInnerHTML={{ __html: currentTeacher?.teacher?.career?.split("<p>")[1].split("</p>")[0] }}></span>}
                    <span className={s.levels}>
                      {handleClearNiveau(currentTeacher?.teacher?.nivMat)?.map((niv: any) => {
                        return (
                          (niv?.niveau?.levelGroup?.includes("Primaire") && (
                            <>
                              <span>Primaire </span> <span>- </span>
                            </>
                          )) ||
                          (niv?.niveau?.levelGroup?.includes("Collège") && (
                            <>
                              <span>Collège </span>
                              <span>- </span>
                            </>
                          )) ||
                          (niv?.niveau?.levelGroup?.includes("Lycée") && (
                            <>
                              <span>Lycée </span>
                              <span>- </span>
                            </>
                          )) ||
                          (niv?.niveau?.levelGroup?.includes("Prépa") && (
                            <>
                              <span>Prépa </span>
                              <span>- </span>
                            </>
                          ))
                        );
                      })}
                      {currentTeacher?.teacher?.nivMat?.length <= 0 && (
                        <>
                          <span>Non renseigné</span>
                          <span>-</span>
                        </>
                      )}
                    </span>
                  </div>
                  <div className={s.buttonsRow}>
                    <Link to={`/prof/${currentTeacher?.teacher?._id}/${currentTeacher?.teacher?.firstname ? currentTeacher?.teacher?.firstname : "nom"}.${currentTeacher?.teacher?.lastname ? currentTeacher?.teacher?.lastname : "complet"}`}>
                      <Button className={s.newMoveButton}>
                        Voir mon profil
                      </Button>
                    </Link>

                    <Button className={s.newProfileButton} style={{ marginLeft: "5px" }}>
                      <img src={starCoins} height={18} style={{ marginRight: "5px" }} />
                      {currentTeacher?.teacher?.credits} <span style={{ marginLeft: "5px" }}> Crédits</span>
                    </Button>
                  </div>
                </div>
              </div>

              <div className={s.descriptionPriceBox}>
                <div className={s.descriptionBox}>
                  {currentTeacher?.teacher?.career?.split("<p>")[2].split("</p>")[0].length > 250 ? <div className={s.description}>
                    <div
                      style={{ whiteSpace: 'pre-wrap', textAlign: "left" }}
                      dangerouslySetInnerHTML={{ __html: currentTeacher?.teacher?.career?.split("<p>")[2].split("</p>")[0].substring(0, 250) + "..." }}
                    />
                  </div> : <div className={s.description}>
                    <div
                      style={{ whiteSpace: 'pre-wrap', textAlign: "left" }}
                      dangerouslySetInnerHTML={{ __html: currentTeacher?.teacher?.career?.split("<p>")[2].split("</p>")[0] }}
                    />
                  </div>}
                  <div className={s.docsBox}>
                    <Space>
                      <FormOutlined style={{ color: "var(--first-color)" }} />{" "}
                      <div className={s.textStats} style={{ cursor: "pointer" }}>
                        {" "}
                        {currentTeacher?.teacher?.postsNum === 0 || currentTeacher?.teacher?.postsNum === 1 ? (
                          <span>{currentTeacher?.teacher?.postsNum} publication en ligne</span>
                        ) : (
                          <span>{currentTeacher?.teacher?.postsNum} publications en ligne</span>
                        )}
                      </div>
                    </Space>
                    <Space>
                      <img src={FileDocument} height={15} ></img>{" "}
                      <div className={s.textStats}>
                        {" "}
                        {currentTeacher?.teacher?.docsNum === 0 || currentTeacher?.teacher?.docsNum === 1 ? (
                          <span>{currentTeacher?.teacher?.docsNum} document publié</span>
                        ) : (
                          <span>{currentTeacher?.teacher?.docsNum} documents publiés</span>
                        )}
                      </div>
                    </Space>
                  </div>
                </div>

                <div className={s.coursesPicesBox}>
                  <div className={s.coursesBox}>
                    {handleClearTopicStrings(currentTeacher?.teacher?.matieres)?.map((item: any) => (
                      <div className={s.courses}>
                        <IconCourse course={item} />
                        <span>{item.includes("-") ? item.split("-")[0] : item.substring(0, 4)}</span>
                      </div>
                    ))}
                  </div>
                  <div className={s.priceBox}>
                    <span className={s.priceTitle}>Mes cours à partir de</span>
                    <span className={s.price}>{currentTeacher?.minPrice}€/séance</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={s.lineSeparator}></div>

            {allData?.length > 0 && <div className={s.weekBox}>
              <div style={{ width: "5%" }}>
                <Button
                  onClick={() => {
                    setConfirmRes([]);
                    setIsAllData(true);
                  }}
                  style={{ backgroundColor: isAllData ? "var(--first-color)" : "transparent", color: isAllData ? "#fff" : "#333", borderRadius: "16px" }}
                >
                  Afficher tout
                </Button>
              </div>
              <div style={{ width: "83%", marginRight: "20px" }}>
                <Slider {...settings} arrows={true}>
                  {allData?.map((item: any, index: number) => (
                    <div
                      key={index}
                      ref={(el) => (dateRef.current[index] = el)}
                      onClick={() => {
                        generateNiches(item);
                        setConfirmRes([]);
                        setIndex(index);
                        setIsAllData(false);
                      }}
                      className={`${s.date} ${creneau?.day === item?.day && s.active}`}
                    >
                      <span>{moment(item?.day).format('dddd D MMMM YYYY')}</span>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>}

            {allData?.length > 0 ? <div className={s.daysBox}>
              {isAllData ? (
                allCreneaux?.map((cren: any, index: number) => {
                  return (<div key={index} className={s.dayBox}>
                    <Row
                      justify="space-between"
                      align="middle"
                      style={{ marginBottom: "2em" }}
                    >
                      <Col>
                        <div className={s.calendarDay}>{moment(cren?.day).format('dddd D MMMM YYYY')}</div>
                      </Col>
                      <div className={s.reservationBox}>
                        <span className={s.reservationMessage}>Réservation minimum 1 heure</span>
                        {confirmReservations.reduce((acc, reservation) => {
                          if (reservation.day === cren.day) {
                            return (
                              creditAllowed && currentTeacher?.credits !== 0 ? <Fragment>
                                {confirmReservations.length > 0 && (
                                  <Fragment>
                                    {currentTeacher?.credits <
                                      sumPrices(confirmReservations) ? (
                                      <Text type="danger">
                                        Vous n'avez pas suffisamment de "crédit"
                                        pour effectuer cette réservation.
                                      </Text>
                                    ) : (
                                      <Fragment>
                                        <Popconfirm
                                          placement="top"
                                          title={
                                            "Veux-tu vraiment faire la réservation ?"
                                          }
                                          onConfirm={handleConfirmReservations}
                                          okText="Oui"
                                          cancelText="Non"
                                        >
                                          <AppButton className={s.newProfileButton}>
                                            Réserver{" "}
                                            {confirmReservations?.length > 1
                                              ? "les" +
                                              " " +
                                              confirmReservations?.length
                                              : " "}{" "}
                                            pour{" "}
                                            {sumPrices(confirmReservations)}{" "}
                                            {confirmReservations?.length > 1 ? "crédits" : "crédit"}
                                          </AppButton>
                                        </Popconfirm>
                                      </Fragment>
                                    )}
                                  </Fragment>
                                )}
                              </Fragment> : <Fragment>
                                {confirmReservations.length > 0 && (
                                  <Fragment>
                                    {user.coins <
                                      sumPrices(confirmReservations) ? (
                                      <Text type="danger">
                                        Vous n'avez pas suffisamment de "coins"
                                        pour effectuer cette réservation. Coût :{" "}
                                        {sumPrices(confirmReservations)} euros
                                      </Text>
                                    ) : (
                                      <Fragment>
                                        <Popconfirm
                                          placement="top"
                                          title={
                                            "Veux-tu vraiment faire la réservation ?"
                                          }
                                          onConfirm={handleConfirmReservations}
                                          okText="Oui"
                                          cancelText="Non"
                                        >
                                          <AppButton className={s.newProfileButton}>
                                            Réserver{" "}
                                            {confirmReservations?.length > 1
                                              ? "les" +
                                              " " +
                                              confirmReservations?.length
                                              : " "}{" "}
                                            pour{" "}
                                            {sumPrices(confirmReservations)}{" "}
                                            €
                                          </AppButton>
                                        </Popconfirm>
                                      </Fragment>
                                    )}
                                  </Fragment>
                                )}
                              </Fragment>
                            );
                          }
                          return acc;
                        }, [])}
                      </div>
                    </Row>

                    <div className={s.dayTimeBox}>
                      <div className={s.momentBox}>Matin</div>
                      <div className={s.timeBox}>
                        {morningHours.map((time: string) => (
                          <button
                            key={time}
                            onClick={() => handleTimeClick(time, cren)}
                            className={getButtonClassName(time, cren?.niches, cren?.day)}
                            disabled={isTimeInRange(time, cren?.niches) ? false : true}
                          >
                            {time}
                          </button>
                        ))}
                      </div>
                    </div>

                    <div className={s.dayTimeBox}>
                      <div className={s.momentBox}>Aprés-midi</div>
                      <div className={s.timeBox}>
                        {afternoonHours.map((time: string) => (
                          <button
                            key={time}
                            onClick={() => handleTimeClick(time, cren)}
                            className={getButtonClassName(time, cren?.niches, cren?.day)}
                            disabled={isTimeInRange(time, cren?.niches) ? false : true}
                          >
                            {time}
                          </button>
                        ))}
                      </div>
                    </div>

                    <div className={s.dayTimeBox}>
                      <div className={s.momentBox}>Soir</div>
                      <div className={s.timeBox}>
                        {nightHours.map((time: string) => (
                          <button
                            key={time}
                            onClick={() => handleTimeClick(time, cren)}
                            className={getButtonClassName(time, cren?.niches, cren?.day)}
                            disabled={isTimeInRange(time, cren?.niches) ? false : true}
                          >
                            {time}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>);
                })
              ) : (
                <div className={s.dayBox}>
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "2em" }}
                  >
                    <Col>
                      <div className={s.calendarDay}>{moment(creneau?.day).format('dddd D MMMM YYYY')}</div>
                    </Col>
                    <div className={s.reservationBox}>
                      <span className={s.reservationMessage}>Réservation minimum 1 heure</span>
                      {confirmReservations.length > 0 && (
                        creditAllowed && currentTeacher?.credits !== 0 ? <Fragment>
                          {currentTeacher?.credits < sumPrices(confirmReservations) ? (
                            <Text type="danger">
                              Vous n'avez pas suffisamment de "crédit" pour
                              effectuer cette réservation.
                            </Text>
                          ) : (
                            <Fragment>
                              <Popconfirm
                                placement="top"
                                title={
                                  "Veux-tu vraiment faire la réservation ?"
                                }
                                onConfirm={handleConfirmReservations}
                                okText="Oui"
                                cancelText="Non"
                              >
                                <AppButton className={s.newProfileButton}>
                                  Réserver{" "}
                                  {confirmReservations?.length > 1
                                    ? "les" +
                                    " " +
                                    confirmReservations?.length
                                    : " "}{" "}
                                  pour{" "}
                                  {sumPrices(confirmReservations)}{" "}
                                  {confirmReservations?.length > 1 ? "crédits" : "crédit"}
                                </AppButton>
                              </Popconfirm>
                            </Fragment>
                          )}
                        </Fragment> : <Fragment>
                          {user.coins < sumPrices(confirmReservations) ? (
                            <Text type="danger">
                              Vous n'avez pas suffisamment de "coins" pour
                              effectuer cette réservation. Coût :{" "}
                              {sumPrices(confirmReservations)} euros
                            </Text>
                          ) : (
                            <Fragment>
                              <Popconfirm
                                placement="top"
                                title={
                                  "Veux-tu vraiment faire la réservation ?"
                                }
                                onConfirm={handleConfirmReservations}
                                okText="Oui"
                                cancelText="Non"
                              >
                                <AppButton className={s.newProfileButton}>
                                  Réserver{" "}
                                  {confirmReservations?.length > 1
                                    ? "les" + " " + confirmReservations?.length
                                    : " "}{" "}
                                  pour {sumPrices(confirmReservations)} €
                                </AppButton>
                              </Popconfirm>
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    </div>
                  </Row>

                  {creneau?.niches?.length <= 0 ? (
                    <AppResult
                      status="info"
                      title={`Ce professeur n'a aucune disponibilité pour le ${moment(
                        creneau?.day
                      ).format("LL")}`}
                    />
                  ) : (
                    <Fragment>
                      <>
                        <div className={s.dayTimeBox}>
                          <div className={s.momentBox}>Matin</div>
                          <div className={s.timeBox}>
                            {morningHours.map((time: string) => (
                              <button
                                key={time}
                                onClick={() => handleTimeClick(time, creneau)}
                                className={getButtonClassName(time, creneau?.niches, creneau?.day)}
                                disabled={isTimeInRange(time, creneau?.niches) ? false : true}
                              >
                                {time}
                              </button>
                            ))}
                          </div>
                        </div>

                        <div className={s.dayTimeBox}>
                          <div className={s.momentBox}>Aprés-midi</div>
                          <div className={s.timeBox}>
                            {afternoonHours.map((time: string) => (
                              <button
                                key={time}
                                onClick={() => handleTimeClick(time, creneau)}
                                className={getButtonClassName(time, creneau?.niches, creneau?.day)}
                                disabled={isTimeInRange(time, creneau?.niches) ? false : true}
                              >
                                {time}
                              </button>
                            ))}
                          </div>
                        </div>

                        <div className={s.dayTimeBox}>
                          <div className={s.momentBox}>Soir</div>
                          <div className={s.timeBox}>
                            {nightHours.map((time: string) => (
                              <button
                                key={time}
                                onClick={() => handleTimeClick(time, creneau)}
                                className={getButtonClassName(time, creneau?.niches, creneau?.day)}
                                disabled={isTimeInRange(time, creneau?.niches) ? false : true}
                              >
                                {time}
                              </button>
                            ))}
                          </div>
                        </div>
                      </>
                    </Fragment>
                  )}
                </div>
              )}
            </div> :
              <AppResult
                status={"info"}
                title="Ce professeur n'a aucune disponibilité"
              />}
          </div>
        </Fragment>
      )}

      <TeacherCalendarDetails
        currentTeacher={currentTeacher}
        drawerRes={drawerRes}
        allSuccessRes={allSuccessRes}
        setDrawerRes={setDrawerRes}
      />
    </Drawer>
  );
};

export default TeacherCalendarForStudent;
