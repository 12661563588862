import { findOneCourse } from "api/services/StudentCours/studentCours";
import { useState, useEffect } from "react";

const useStudentOneCourse = (id: any) => {
  //!STATE
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  const fechStudentCourse = async (passedId: string) => {
    setLoading(true);
    try {
      const response = await findOneCourse(passedId);
      setData(response?.data);
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fechStudentCourse(id);
  }, [id]);

  return { data, loading, error };
};

export default useStudentOneCourse;
