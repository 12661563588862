import FadeInUpOnScroll from "components/FadeInUp";
import { useRef, useState } from "react";
import arrow_left from "../../../../assets/images/app/home/arrow_left_teachers.png";
import arrow_right from "../../../../assets/images/app/home/arrow_right_teachers.png";
import ellipse_history_title from "../../../../assets/images/app/home/ellipse_history_title.svg";
import ellipse_advantages from "../../../../assets/images/app/teachers/ellipse_advantages.svg";
import s from "./style.module.css";

interface DataItem {
    number: string;
    title: string;
    subtitle: string;
    boxColor: string;
    numberColor: string;
}

const Advantages = () => {

    const data: DataItem[] = [
        {
            number: "1",
            title: "Accès à une Éducation<br/>de Qualité",
            subtitle: "Votre soutien permet d'offrir à vos filleuls<br/>un accès à des professeurs qualifiés issus<br/>des meilleures Grandes Écoles,<br/>garantissant ainsi un enseignement<br/>sérieux et adaptatif.",
            boxColor: "#FAE4C6",
            numberColor: "#E0AD6A",
        },
        {
            number: "2",
            title: "Soutien Personnalisé",
            subtitle: "Chaque élève bénéficie d'un<br/>accompagnement individualisé,<br/>permettant de cibler ses besoins et<br/>lacunes et d'optimiser ses chances de<br/>réussite académique.",
            boxColor: "#D1D7D4",
            numberColor: "#8AB0B1",
        },
        {
            number: "3",
            title: "Innovation Technologique",
            subtitle: "L’intégration d'outils technologiques<br/>avancés sur la plateforme offre une<br/>expérience d'apprentissage interactive et<br/>engageante pour les élèves.",
            boxColor: "#FFD5C9",
            numberColor: "#F56666",
        },
        {
            number: "4",
            title: "Suivi des Progrès",
            subtitle: "Des outils de suivi en temps réel et des<br/>rapports réguliers permettent de mesurer<br/>la progression des élèves, assurant<br/>ainsi la transparence et l'efficacité des<br/>fonds investis.",
            boxColor: "#EADDFF",
            numberColor: "#9F7ED8",
        },
        {
            number: "5",
            title: "Impact Social Mesurable",
            subtitle: "En parrainant des filleuls, vous contribuez<br/>directement à réduire les inégalités<br/>éducatives, offrant à des élèves<br/>les ressources nécessaires pour réussir<br/>et exceller!",
            boxColor: "#C9E0FF",
            numberColor: "#4579BE",
        },
    ];

    const containerRef = useRef<HTMLDivElement>(null);

    const scrollContainerRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({
                top: 0,
                left: isMobile ? 350 : 150,
                behavior: 'smooth'
            });
        }
    };

    const scrollContainerLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({
                top: 0,
                left: isMobile ? -350 : -150,
                behavior: 'smooth'
            });
        }
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.mainContainer}>
            <div className={s.advantageContainer}>
                <div className={s.mainBox}>
                    <FadeInUpOnScroll>
                        {isMobile ? <h1 className={s.title}>Nos avantages<br />pour les enseignants sur<br />Mon Ami Albert</h1> : <h1 className={s.title}>Nos avantages pour les enseignants sur<br />Mon Ami Albert</h1>}
                    </FadeInUpOnScroll>
                    <img src={ellipse_history_title} className={s.ellipseTitle}></img>
                    {!isMobile && <img src={ellipse_advantages} style={{ position: "relative", left: "11%", height: "144px", bottom: "60px", zIndex: "2" }}></img>}
                    <div className={s.sildeBox} ref={containerRef}>
                        {data.map((item) => {
                            const isEven = parseInt(item.number) % 2 === 0;
                            return (
                                <div key={item.number} className={s.advantageBox} style={{ marginTop: isEven && !isMobile ? '40px' : '0' }}>
                                    <div style={{ height: "80px", width: "80px", background: `${item.boxColor} 0% 0% no-repeat padding-box`, borderRadius: "27px", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", fontFamily: "Poppins", fontSize: "30px", lineHeight: "60px", fontWeight: "bolder", color: `${item.numberColor}` }}>{item.number}</div>
                                    <h2 style={{ marginTop: "20px", fontFamily: "Poppins" }} dangerouslySetInnerHTML={{ __html: `${item.title}` }} />
                                    <div style={{ marginTop: "20px", fontFamily: "Poppins", fontWeight: "400", color: "#2F2F2F" }} dangerouslySetInnerHTML={{ __html: `${item.subtitle}` }} />
                                </div>
                            )
                        })}
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: isMobile ? "50px" : "0px" }}>
                        <div style={{ display: "flex", height: "40px", width: "70px", borderRadius: "15px", background: "#D1D7D4", position: "relative", bottom: "80px", marginRight: "20px", alignItems: "center", justifyContent: "center", textAlign: "center", cursor: "pointer" }} onClick={scrollContainerLeft}>
                            <img src={arrow_left} style={{ height: "15px" }}></img>
                        </div>
                        <div style={{ display: "flex", height: "40px", width: "70px", borderRadius: "15px", background: "#D1D7D4", position: "relative", bottom: "80px", alignItems: "center", justifyContent: "center", cursor: "pointer" }} onClick={scrollContainerRight}>
                            <img src={arrow_right} style={{ height: "15px" }}></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Advantages;