import { useState, useCallback, useEffect } from "react";
import { message } from "antd";
import { findQuizOfCours } from "./../api/services/quizz/quizz.services";

const useGetQuizOfCours = (id: string) => {
  const [quiz, setQuiz] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any[]>();

  const getQuizOfQuiz = useCallback(async (idQuiz: string) => {
    setLoading(true);
    try {
      const response = await findQuizOfCours(idQuiz);
      if (response) {
        setQuiz(response.data);
      }
    } catch (error: any) {
      setError(error);
      console.log(error)
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getQuizOfQuiz(id);
  }, [id]);

  return { quiz, loading, error };
};

export default useGetQuizOfCours;
