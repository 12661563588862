import { useState } from "react";
import axios from "axios";

function useWebhook(): [(reservatio: any) => void, any, boolean, any] {
  const [response, setResponse] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const sendWebhook = async (reservation: any): Promise<void> => {
    setLoading(true);
    setError(null);
    try {
      const result = await axios.post(
        "https://n8n.dba.ma/webhook/f333bb0e-404f-4678-afdb-ecaabff38191",
        reservation
      );
      setResponse(result.data);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  return [sendWebhook, response, loading, error];
}

export default useWebhook;
