import React from "react";
import { Table, Tag, Space, Row, Col } from "antd";
import AppButton from "components/libs/button";
import s from "./assets/table.module.css";

const columns = [
  {
    title: "Tags",
    key: "tags",
    dataIndex: "tags",
    render: (tags: any) => (
      <>
        {tags.map((tag: any) => {
          let color = tag.length > 5 ? "geekblue" : "green";
          if (tag === "loser") {
            color = "volcano";
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </>
    ),
  },
  {
    title: "Action",
    key: "action",
    render: (text: string, record: any) => (
      <Space size="middle">
        <a>Invite {record.name}</a>
        <a>Delete</a>
      </Space>
    ),
  },
];

const data = [];

const TableCours = () => {
  return (
    <React.Fragment>
      <Table
        columns={columns}
        //  dataSource={data}
      />
      {/* <Row justify="center">
        <Col>
          {" "}
          <AppButton className={s.button}>Soumettre pour validation</AppButton>
        </Col>
      </Row> */}
    </React.Fragment>
  );
};

export default TableCours;
