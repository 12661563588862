import FadeInOnScroll from "components/FadeIn";
import FadeInUpOnScroll from "components/FadeInUp";
import * as appRoutes from "config/routes.config";
import Lottie from "lottie-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import arrow_right_button from "../../../../../assets/images/app/home/arrow_right_button.svg";
import ellipse_ressources from "../../../../../assets/images/app/home/ellipse_ressources.svg";
import icon_economie from "../../../../../assets/images/app/home/icon_economie.png";
import icon_informatique from "../../../../../assets/images/app/home/icon_informatique.png";
import icon_langues from "../../../../../assets/images/app/home/icon_langues.png";
import icon_math from "../../../../../assets/images/app/home/icon_math.png";
import icon_physique_chimie from "../../../../../assets/images/app/home/icon_physique_chimie.png";
import icon_sciences_sociales from "../../../../../assets/images/app/home/icon_sciences_sociales.png";
import traceAnimation from "../../../../../assets/images/app/home/trace_resources.json";
import st from "../../../common.module.css";
import s from "./style.module.css";

const OurResources = () => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(appRoutes.STUDENT_MY_CONTENT);
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.mainContainer}>
            <div className={s.mainBox}>
                <FadeInUpOnScroll>
                    <h1 className={s.title}>Essayez nos ressources gratuites</h1>
                </FadeInUpOnScroll>
                <img src={ellipse_ressources} className={s.ellipseTitle}></img>
                <Lottie animationData={traceAnimation} loop={true} className={s.lottieTitle} />
                <FadeInOnScroll>
                    {isMobile ? <p className={s.message}>Des cours, des quiz et des exercices bien<br />pensés pour donner un vrai coup de pouce<br />à votre réussite!</p> : <p className={s.message}>Des cours, des quiz et des exercices bien pensés pour donner<br />un vrai coup de pouce à votre réussite!</p>}
                </FadeInOnScroll>
                <div className={s.boxesOne}>
                    <div className={`${st.scaleAnimation} ${s.boxOneTwo}`}>
                        <img src={icon_math} style={{ height: "50px" }}></img>
                        <div className={s.boxTitle}>Mathématiques</div>
                    </div>
                    <div className={`${st.scaleAnimation} ${s.boxOneTwo}`}>
                        <img src={icon_physique_chimie} style={{ height: "50px" }}></img>
                        <div className={s.boxTitle}>Physique-Chimie</div>
                    </div>
                    <div className={`${st.scaleAnimation} ${s.boxThree}`}>
                        <img src={icon_informatique} style={{ height: "50px" }}></img>
                        <div className={s.boxTitle}>Informatique</div>
                    </div>
                </div>
                <div className={s.boxesTwo}>
                    <div className={`${st.scaleAnimation} ${s.boxOneTwo}`}>
                        <img src={icon_sciences_sociales} style={{ height: "50px" }}></img>
                        <div className={s.boxTitle}>Sciences Sociales</div>
                    </div>
                    <div className={`${st.scaleAnimation} ${s.boxOneTwo}`}>
                        <img src={icon_langues} style={{ height: "50px" }}></img>
                        <div className={s.boxTitle}>Langues</div>
                    </div>
                    <div className={`${st.scaleAnimation} ${s.boxThree}`}>
                        <img src={icon_economie} style={{ height: "50px" }}></img>
                        <div className={s.boxTitle}>Economie</div>
                    </div>
                </div>
                <div className={s.seeMoreButton} onClick={handleClick}>
                    <a>Voir plus</a>
                    <div className={s.seeMoreButtonArrow}>
                        <img src={arrow_right_button} style={{ height: "15px" }}></img>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurResources;