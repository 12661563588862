import { FC, useEffect } from "react";
import ReactAudioPlayer from "react-audio-player";

//!STYLE
import s from "../assets/quiz.module.css";

type Props = {
  value: any;
  type: any;
  isDanger: boolean;
};

const DateTimeDisplay: FC<Props> = ({ value, type, isDanger }) => {
  const sound = "/assets/avatars/test.mp3";
  const audio = new Audio(sound);
  audio.loop = true;

  useEffect(() => {}, [isDanger]);

  return (
    <div className={isDanger ? `${s.countdown} ${s.danger}` : `${s.countdown}`}>
      <span>{value}</span>
      <span>{type}</span>

      {isDanger && (
        <ReactAudioPlayer
          src={sound}
          autoPlay={true}
          controls
          loop={true}
          className={s.__sound}
        />
      )}
    </div>
  );
};

export default DateTimeDisplay;
