import { FC } from "react";
import s from "./assets/grid.module.css";

type Props = {
  className?: string;
};
const GridInstaProf: FC<Props> = ({ children, className }) => {
  return <div className={`${s.grid} ${className}`}>{children}</div>;
};

export default GridInstaProf;
