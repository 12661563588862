import { findOneTeacher } from "./../api/services/quizz/quizz.services";
import { findOne } from "api/services/quizz/quizz.services";
import { useState, useEffect } from "react";

type Quizz = {
  _id?: string;
  title?: string;
  description?: string;
  propositions?: any[];
  createdAt?: Date;
  updatedAt?: Date;
  questions?: any[];
  quizzLevel?: string;
  duration: number;
};

const useQuizzTeacher = (id: string) => {
  //!STATE
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<Boolean>(true);
  const [error, setError] = useState<string>("");

  const fetchData = async (passedId: string) => {
    try {
      setLoading(true);
      const response = await findOneTeacher(passedId);
      setData(response?.data);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  return { data, loading, error };
};

export default useQuizzTeacher;
