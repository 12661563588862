//!COMPONENTS
//!IMAGES
//!STYLES
import { Skeleton } from "antd";
import AppResult from "components/appResults";
import { FC, Fragment } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CardContribution from "screens/teacher/components/cardContribution";
import { useGetContributionsQuery } from "store/services/teacher/contributions.services";
import s from "./assets/style.module.css";


type Props = {
  publications: any;
};

const PublicationsProfileTeacherCard: FC<Props> = ({ publications }) => {

  const params = useParams<"id">();
  const { user, idTeacher } = useSelector((state) => state.auth);
  const { data, isLoading, isError } = useGetContributionsQuery({
    profId: params.id! || (user && user?.roles?.includes("teacher") && user?._id),
    self: !!(user?.roles?.includes("teacher") && user._id),
  });

  return (
    <div className={s.card__content}>
      <h3>Mes Publications</h3>
      {isLoading ? (
        <>
          <Fragment>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Fragment>
        </>
      ) : (
        <>
          {!isLoading && data?.data?.results.length > 0 ? (
            <div className={s.grid}>
              {data?.data?.results?.map((contribution: any) => (
                <CardContribution contribution={contribution} />
              ))}
            </div>
          ) : (
            <AppResult status="info" title="Aucune publication" />
          )}
        </>
      )}
    </div>
  );
};

export default PublicationsProfileTeacherCard;
