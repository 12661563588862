import { Avatar, Image } from "antd";
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { openProfileDrawer } from 'store/auth/authSlice';
import st from "./style.module.css";

type Props = {
    user: any;
}

const ParentOrganismNavBar: React.FC<Props> = ({ user }) => {

    const dispatch = useDispatch();

    const openDrawer = () => dispatch(openProfileDrawer());

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <>
            <div className={st.navbar} style={{ cursor: "pointer" }}>
                <div style={{ height: "auto", width: "120px", display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div className={st.avatar} onClick={openDrawer}>
                        <Avatar
                            style={{ backgroundColor: "#FFF" }}
                            size={35}
                            src={
                                <Image
                                    fallback={`http://gravatar.com/avatar${user?.uid}?d=identicon`}
                                    preview={false}
                                    src={
                                        !!user?.profile
                                            ? user?.profile
                                            : `https://gravatar.com/avatar/${user?._id}?d=identicon`
                                    }
                                />
                            }
                        >
                            {""}
                        </Avatar>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", marginLeft: "5px", color: "#FFF", alignItems: "start", justifyContent: "center" }} onClick={openDrawer}>
                        <span>{user?.lastname}</span>
                        <span style={{ fontSize: "14px", fontWeight: "600" }}>Mon Profil</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ParentOrganismNavBar;