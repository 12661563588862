export const findAll = (id: string | undefined) =>
  `${process.env.REACT_APP_API_URL}/users/teachers/${id}/rooms`;

export const CREATE_AD = `${process.env.REACT_APP_API_URL_RESSOURCES}/advertisements`;

export const getTeacherAds = (id: string | undefined) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/advertisements/?teacherId=${id}`;

export const getAd = (id: string | undefined) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/advertisements/${id}`;

export const getBookedReservations = (id: string | undefined) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/advertisements/advertisementsBuys/${id}`;

export const GET_ADS = (id: string) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/advertisements/?teacherId=${id}`;

export const DELETE_ADS = (id: string | undefined) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/advertisements/${id}`;

export const SEARCH_FILTER = `${process.env.REACT_APP_API_URL_RESSOURCES}/advertisements/reserve/search`;

export const SEARCH_INSTA_PROF = `${process.env.REACT_APP_API_URL_RESSOURCES}/advertisements/instaProf/newRequest`;

export const STUDENT_ACCEPY_A_PROF = () =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/advertisements/instaProf/reserve`;

export const STUDENT_CHECK_SESSION = (idTeacher: string) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/advertisements/instaProf/newRequest/prof/checkSaisson/${idTeacher}`;

export const ACTIVE_INSTA_PROF = (id: string) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/users/${id}/instaProf/active`;

export const SHUTDOWN_INSTAPROF_REQUEST = (idStudent: string, code: string) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/instaProf/newRequest/shutdown/${idStudent}/${code}`;

export const DISABLED_INSTA_PROF = (id: string) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/users/${id}/instaProf/desactive`;

export const STUDENT_GET_INSTA_PROF = (idStudent: string) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/advertisements/instaProf/newRequest/show/${idStudent}`;

export const STUDENT_GET_INSTA_PROF_KILL = (idStudent: string, code: string) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/advertisements/instaProf/newRequest/shutdown/${idStudent}/${code}`;

export const UPDATE_NIVMAT = () =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/advertisements/update/nivmat`;

export const UPDATE_CRENEAU = (idCalendar: string) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/advertisements/${idCalendar}`;
