import { FC } from "react";
import { TypeMatiers } from "common/enums";

//IMAGES
import MATH from "assets/images/app/iconMatiers/Mathematiques.png";
import PHILO from "assets/images/app/iconMatiers/Philosophie.png";
import PHYSIQUE from "assets/images/app/iconMatiers/Physique-chimie.png";
import SVT from "assets/images/app/iconMatiers/SVT.png";
import ANGLAIS from "assets/images/app/iconMatiers/Anglais.png";
import HISTOIREGEO from "assets/images/app/iconMatiers/Histoire-geo.png";
import FRANCAIS from "assets/images/app/iconMatiers/Francais.png";

type Props = {
  data: any;
};
const IconTopic: FC<Props> = ({ data }) => {
  return (
    <img
      style={{
        position: "relative",
        width: "18px",
        height: "18px",
        top: "-1px",
      }}
      src={
        data?.description == TypeMatiers.mathematique
          ? MATH
          : data?.description == TypeMatiers.SVT
          ? SVT
          : data?.description == TypeMatiers.PhysiqueChimie
          ? PHYSIQUE
          : data?.description == TypeMatiers.Philosophie
          ? PHILO
          : data?.description == TypeMatiers.HistoireGeo
          ? HISTOIREGEO
          : data?.description == TypeMatiers.Francais
          ? FRANCAIS
          : data?.description == TypeMatiers.Anglais
          ? ANGLAIS
          : ""
      }
    />
  );
};

export default IconTopic;
