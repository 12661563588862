import { TypeMatiers } from "common/enums";
import { FC } from "react";

//IMAGES
import ANGLAIS from "assets/images/app/iconMatiers/Anglais.png";
import FRANCAIS from "assets/images/app/iconMatiers/Francais.png";
import HISTOIREGEO from "assets/images/app/iconMatiers/Histoire-geo.png";
import MATH from "assets/images/app/iconMatiers/Mathematiques.png";
import PHILO from "assets/images/app/iconMatiers/Philosophie.png";
import PHYSIQUE from "assets/images/app/iconMatiers/Physique-chimie.png";
import SVT from "assets/images/app/iconMatiers/SVT.png";

type Props = {
    course: string;
};

const IconCourse: FC<Props> = ({ course }) => {
    return (
        <img
            style={{
                position: "relative",
                width: "18px",
                height: "18px",
                top: "-1px",
            }}
            src={
                course == TypeMatiers.mathematique || "Mathématiques"
                    ? MATH
                    : course == TypeMatiers.SVT
                        ? SVT
                        : course == TypeMatiers.PhysiqueChimie
                            ? PHYSIQUE
                            : course == TypeMatiers.Philosophie
                                ? PHILO
                                : course == TypeMatiers.HistoireGeo
                                    ? HISTOIREGEO
                                    : course == TypeMatiers.Francais
                                        ? FRANCAIS
                                        : course == TypeMatiers.Anglais
                                            ? ANGLAIS
                                            : ""
            }
        />
    );
};

export default IconCourse;
