import { FC } from "react";
import { motion } from "framer-motion";

const animations = {
  // initial: { opacity: 0, x: 100 },
  // animate: { opacity: 1, x: 0 },
  // exit: { opacity: 0, x: -100 },

  pageInitial: {
    opacity: 0,
    // x: -50,
    // scale: 1.9,
    transition: {
      when: "afterChildren",
      delay: 200,
      duration: 5,
      type: "spring",
      stiffness: 200,
    },
  },
  pageAnimate: {
    opacity: 1,
    // x: 0,
    // scale: 1,
    transition: {
      duration: 0.2,
      when: "beforeChildren",
      staggerChildren: 0.2,
    },
  },
  exit: { opacity: 0, x: 0 },
};

const AnimatedPage: FC = ({ children }) => {
  return (
    <motion.div
      variants={animations}
      initial="pageInitial"
      animate="pageAnimate"
      exit="exit"
      // transition={{ duration: 0.8 }}
      transition={{ duration: 0.3 }}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedPage;
