import { FC, useState } from "react";

//!COMPONENTS

//!ANTD

//!STYLES
import s from "./assets/footerFooter.module.css";

//!IMAGES
import Container from "components/container";
import albert_head from "./assets/images/albert_head.svg";
import icon_facebook from "./assets/images/icon_facebook.svg";
import icon_instagram from "./assets/images/icon_instagram.svg";
import icon_linkedin from "./assets/images/icon_linkedin.svg";
import icon_youtube from "./assets/images/icon_youtube.svg";

import { DeleteOutlined, EditOutlined, MessageOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Drawer, Input, List, message, Popconfirm, Spin, Tooltip } from "antd";
import { API_RESSOURCES } from "api/services/teacher/teacher.routes";
import axios from "axios";
import { useEffect, useRef } from "react";
import Latex from "react-latex-next";
import { useDispatch, useSelector } from "react-redux";
import StudentCoursQuiz from "screens/student/coursQuizBot";
import StudentExercices from "screens/student/studentExosBot";
import { setIsBotCalled } from "store/app/appSlice";
import sStyle from "../../../../screens/student/cours/assets/cours.module.css";

const FooterFooter: FC = () => {

  const dispatch = useDispatch();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [drawerVisible, setDrawerVisible] = useState<any>(false);
  const [drawerCoursVisible, setDrawerCoursVisible] = useState<any>(false);
  const [drawerQuizzVisible, setDrawerQuizzVisible] = useState<any>(false);
  const [quizzId, setQuizzId] = useState<any>(null);
  const [drawerExerciceVisible, setDrawerExerciceVisible] = useState<any>(false);
  const [exerciceId, setExerciceId] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);
  const [coursFetched, setCoursFetched] = useState<any>(null);
  const [messages, setMessages] = useState<any>([]);
  const [inputValue, setInputValue] = useState<any>('');
  const [conversations, setConversations] = useState<any>([]);
  const [currentConversationIndex, setCurrentConversationIndex] = useState<any>(null);
  const [showSuggestedQuestions, setShowSuggestedQuestions] = useState<any>(true);
  const [suggestedQuestions, setSuggestedQuestions] = useState<any>([]);
  const [suggestedQuestionsClicked, setSuggestedQuestionsClicked] = useState<any>(false);
  const [showLoaderNewConversation, setShowLoaderNewConversation] = useState<any>(false);

  // Ref for scrolling to bottom
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const conversationsEndRef = useRef<HTMLDivElement>(null);

  const [topics, setTopics] = useState<any>([]); // State to store fetched topics
  const [selectedTopic, setSelectedTopic] = useState<any>('Auto'); // State to track selected topic
  const [textInput, setTextInput] = useState('');
  const { isBotCalled } = useSelector((state) => state.app);

  const handleTextInputChange = (e: any) => {
    // Only allow numeric input
    const value = e.target.value.trim();
    setTextInput(value);
  };

  useEffect(() => {
    // Fetch topics from API on component mount
    fetchTopics();
  }, []);

  useEffect(() => {
    if (isBotCalled) {
      setDrawerVisible(true);
    }
  }, [isBotCalled]);

  const fetchTopics = async () => {
    try {
      let matieres: any = {
        _id: 'auto',
        description: 'Auto',
      }
      const response = await axios.get(`${API_RESSOURCES}/courses?levelId=${user.levelId}`);
      const newArray = [matieres].concat(response.data['data'])
      setTopics(newArray);
    } catch (error) {
      console.error('Error fetching topics:', error);
      // Handle error if needed
    }
  };

  const handleTopicClick = (topicId: any) => {
    setSelectedTopic(topicId); // Update selected topic state on button click
  };

  useEffect(() => {
    if (isLoggedIn && user.roles.includes("student")) {
      fetchConversations()
    }
  }, []);

  useEffect(() => {
    setInputValue('');
    handleSendMessage();
  }, [suggestedQuestionsClicked]);

  useEffect(() => {
    scrollToBottom();
  }, [currentConversationIndex]);

  useEffect(() => {
    scrollToBottom();
  }, [conversations]);

  const handleIconClick = () => {
    setDrawerVisible(true);
  };

  const handleCloseDrawer = () => {
    setDrawerVisible(false);
    dispatch(setIsBotCalled(false));
  };

  const handleShowCoursClick = async (chapterId: any) => {
    const responseChapter = await axios.get(
      `${API_RESSOURCES}/chapters/${chapterId}`
    );
    if (responseChapter?.data) {
      setCoursFetched(responseChapter?.data['data'])
    }
    setDrawerCoursVisible(true);
  };

  const handleShowCoursCloseDrawer = () => {
    setDrawerCoursVisible(false);
  };

  const handleShowQuizzClick = async (quizzId: any) => {
    setQuizzId(quizzId)
    setDrawerQuizzVisible(true);
  };

  const handleShowQuizzCloseDrawer = () => {
    setDrawerQuizzVisible(false);
    setQuizzId(null)
  };

  const handleShowExerciceClick = (exerciceId: any) => {
    setExerciceId(exerciceId)
    setDrawerExerciceVisible(true);
  };

  const handleShowExerciceCloseDrawer = () => {
    setDrawerExerciceVisible(false);
    setExerciceId(null)
  };

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const getMessages = async (indexConv: any): Promise<any> => {
    try {
      const idConv = indexConv;
      let response = await axios.get(
        `${API_RESSOURCES}/openai/ask/user/convresastions/getOne/${idConv}`
      );

      if (response?.data) {
        if (response?.data['data'].length !== 0) {
          if (response?.data['data'].length === 2) {
            return []; // Return an empty array if length is 2
          }
          if (response?.data['data'].length > 2) {
            return response?.data['data'].slice(2); // Return sliced array if length > 2
          }
          return response.data['data']; // Return original data if other conditions don't apply
        } else {
          // Handle case where data is empty
          message.warning({
            content: "getMessages: Data is empty.",
          });
          return []; // Return empty array if data is empty
        }
      } else {
        // Handle case where response.data is undefined or null
        message.warning({
          content: "getMessages: An error occurred! Please try again later.",
        });
        return []; // Return empty array in case of error
      }
    } catch (error) {
      // Handle any errors that occur during the axios call
      message.warning({
        content: "getMessages: An error occurred! Please try again later.",
      });
      console.error("An error occurred while fetching messages:", error);
      return []; // Return empty array in case of error
    }
  };


  const fetchConversations = () => {
    (async () => {
      try {
        const response = await axios.get(
          `${API_RESSOURCES}/openai/ask/user/convresastions/getAll/${user._id}`
        );

        if (response?.data) {
          if (response?.data['data'].length !== 0) {
            let apiConvs: any = []
            for (let index = 0; index < response?.data['data'].length; index++) {
              let conv = {
                messages: [],
                title: response?.data['data'][index].title,
                id: response?.data['data'][index]._id
              };
              if (index === response?.data['data'].length - 1) {
                const messages = await getMessages(response?.data['data'][index]._id)
                conv.messages = messages
                if (messages.length === 0) {
                  handle3Questions(true);
                  setShowSuggestedQuestions(true);
                }
              }
              apiConvs.push(conv)
            }

            setConversations(apiConvs);
            setCurrentConversationIndex(apiConvs.length - 1);
          } else {
            const idConv: any = await handle3Questions(false);
            const newConversation = {
              messages: [],
              title: 'Nouvelle conversation',
              id: idConv
            };
            setConversations([...conversations, newConversation]);
            setCurrentConversationIndex(conversations.length);
            setShowSuggestedQuestions(true);
          }
        }
        else {
          message.warning({
            content: "fetch conversations: An error occurred! Please try again later.",
          });
        }
      } catch (error) {
        message.warning({
          content: "fetch conversations 2: An error occurred! Please try again later.",
        });
        console.error(
          "An error occurred while fetching the conversations",
          error
        );
      }
    })();
  }

  const formatTextWithLineBreaks = (text: any) => {
    return text.replace(/(\.)(?=\s|$)/g, '.$1<br />');
  };

  const deleteConversation = (idDelete: any) => {
    (async () => {
      try {
        const response = await axios.delete(
          `${API_RESSOURCES}/openai/ask/convresastions/${idDelete}`
        );

        if (response?.data) {
          //delete the exact same conversation from local
          let updatedConversations = [...conversations];
          console.log('updatedConversations idDelete', idDelete)
          console.log('updatedConversations', updatedConversations)
          let newConvs: any = []
          for (let index = 0; index < updatedConversations.length; index++) {
            if (updatedConversations[index].id !== idDelete) {
              newConvs.push(updatedConversations[index])
            }
          }
          console.log('updatedConversations newConvs', newConvs)
          if (newConvs.length !== 0) {
            setCurrentConversationIndex(newConvs.length - 1);
            setConversations(newConvs);
          } else {
            const idConv: any = await handle3Questions(false);
            const newConversation = {
              messages: [],
              title: 'Nouvelle conversation',
              id: idConv
            };
            setConversations([...newConvs, newConversation]);
            setCurrentConversationIndex(conversations.length - 1);
            setShowSuggestedQuestions(true);
            setSuggestedQuestionsClicked(false);
          }

          message.warning({
            content: "delete conversation: success",
          });
        }
        else {
          message.warning({
            content: "delete conversation: An error occurred! Please try again later.",
          });
        }
      } catch (error) {
        message.warning({
          content: "delete conversation 2: An error occurred! Please try again later.",
        });
        console.error(
          "An error occurred while deleting the conversation",
          error
        );
      }
    })();
  }

  const updateConversation = (idUpdate: any, text: any) => {
    (async () => {
      try {
        //trim the text first
        const textTrim = text?.substring(0, 30) + '...'
        const response = await axios.patch(
          `${API_RESSOURCES}/openai/ask/convresastions/${idUpdate}`, {
          text: textTrim
        }
        );

        if (response?.data) {
          //update the exact same conversation from local
          let updatedConversations = [...conversations];
          for (let index = 0; index < updatedConversations.length; index++) {
            if (updatedConversations[index].id === idUpdate) {
              updatedConversations[index].title = textTrim
            }
          }
          console.log('updatedConversations é', updatedConversations)
          setConversations(updatedConversations);

          message.warning({
            content: "update conversation: success",
          });
        }
        else {
          message.warning({
            content: "update conversation: An error occurred! Please try again later.",
          });
        }
      } catch (error) {
        message.warning({
          content: "update conversation 2: An error occurred! Please try again later.",
        });
        console.error(
          "An error occurred while updating the conversation",
          error
        );
      }
    })();
  }

  const fetchConversation = (index: any) => {
    (async () => {
      try {
        const idConv = conversations[index].id;
        let messages: any = []
        if (idConv !== '') {
          messages = await getMessages(idConv);
        }

        // Update messages for the selected conversation
        let updatedConversations = [...conversations];
        updatedConversations[index].messages = messages;
        setConversations(updatedConversations);
        setCurrentConversationIndex(index); // Ensure the correct index is set
      } catch (error) {
        // Handle error
        console.error("Error fetching conversation:", error);
        message.warning({
          content: "An error occurred while fetching the conversation.",
        });
      }
    })();
  };


  const handle3Questions = async (exists: any): Promise<any> => {
    try {
      const response = await axios.post(
        `${API_RESSOURCES}/openai/ask/initial/getQuestions`,
        {
          id: user._id,
          exists: exists
        }
      );

      if (response?.data) {
        setSuggestedQuestions(response?.data['data']['suggestedQuestionsRender']);
        console.log('here sssddd rrererr', response?.data['data']['idConv']);
        return response?.data['data']['idConv']; // Return idConv here
      } else {
        message.warning({
          content: "An error occurred! Please try again later.",
        });
        return ''; // Return empty string or handle error case
      }
    } catch (error) {
      message.warning({
        content: "An error occurred! Please try again later.",
      });
      console.error(
        "An error occurred while updating the conversation",
        error
      );
      return ''; // Return empty string or handle error case
    }
  };

  const handleSendMessage = () => {
    if (inputValue.trim() !== '') {
      setShowLoaderNewConversation(true);
      const newMessage = {
        text: inputValue,
        sender: 'user',
      };

      let updatedConversations = [...conversations];
      if (updatedConversations[currentConversationIndex].messages.length === 0) {
        updatedConversations[currentConversationIndex].title = inputValue?.substring(0, 30) + '...'
      }
      updatedConversations[currentConversationIndex].messages.push(newMessage);
      setConversations(updatedConversations);
      setInputValue('');

      // Send to backend
      (async () => {
        try {
          const response = await axios.post(
            `${API_RESSOURCES}/openai/ask`,
            {
              id: updatedConversations[currentConversationIndex].id,
              question: inputValue,
              special: selectedTopic,
              idUser: user._id
            }
          );

          if (response?.data) {

            //try get the chapter by ID if not empty
            let chapterContentInstance: any = {}
            if (response?.data['data']['chapterId'] !== '') {
              //try get chapter
              const responseChapter = await axios.get(
                `${API_RESSOURCES}/chapters/${response?.data['data']['chapterId']}`
              );
              if (responseChapter?.data) {
                if (responseChapter?.data['data']['contents'].length !== 0) {
                  chapterContentInstance = responseChapter?.data['data']['contents'][0]

                  //update the message in backend in order to update chapterContent
                  await axios.patch(
                    `${API_RESSOURCES}/openai/ask/convresastions/message/${response?.data['data']['idMessage']}`, {
                    text: chapterContentInstance
                  }
                  );
                }
              }
            }

            let updatedConversations = [...conversations];
            let newMessageGPT = {
              text: response?.data['data']['text'],
              sender: 'bot',
              _id: response?.data['data']['idMessage'],
              chapterId: response?.data['data']['chapterId'],
              chapterContent: chapterContentInstance,
              quizzId: response?.data['data']['quizzId'],
              exerciceId: response?.data['data']['exerciceId'],
            };
            updatedConversations[currentConversationIndex].messages.push(newMessageGPT);
            setConversations(updatedConversations);
            setInputValue('');
            setShowLoaderNewConversation(false);
          }
          else {
            message.warning({
              content: "An error occurred! Please try again later.",
            });
            setShowLoaderNewConversation(false);
          }
        } catch (error) {
          message.warning({
            content: "An error occurred! Please try again later.",
          });
          console.error(
            "An error occurred while updating the conversation",
            error
          );
          setShowLoaderNewConversation(false);
        }
      })();

      setSuggestedQuestionsClicked(false)
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToBottomConversations = () => {
    if (conversationsEndRef.current) {
      conversationsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleNewConversation = async () => {
    try {
      setShowLoaderNewConversation(true);

      const idConv: any = await handle3Questions(false); // Await handle3Questions call

      const newConversation = {
        messages: [],
        title: 'Nouvelle conversation',
        id: idConv
      };

      setConversations([...conversations, newConversation]);
      setCurrentConversationIndex(conversations.length);
      setInputValue('');
      setShowSuggestedQuestions(true);
      setSuggestedQuestionsClicked(false);

      setShowLoaderNewConversation(false);

      scrollToBottomConversations()
    } catch (error) {
      // Handle error if handle3Questions fails
      console.error('Error while handling new conversation:', error);
      message.warning({
        content: "An error occurred! Please try again later.",
      });
      setShowLoaderNewConversation(false);
    }
  };


  const handleQuestionSelection = (question: any) => {
    setInputValue(question);
    setShowSuggestedQuestions(false);
    setSuggestedQuestionsClicked(true)
  };

  const renderSuggestedQuestions = () => {
    if (showSuggestedQuestions && conversations[currentConversationIndex]?.messages.length === 0) {
      return (
        <div style={{ padding: '10px', overflowY: 'auto', position: 'sticky', marginBottom: '60px' }}>
          <strong>Suggested Questions:</strong>
          <ul style={{ listStyleType: 'none', paddingInlineStart: '0', marginTop: '10px' }}>
            {suggestedQuestions.map((question: any, index: any) => (
              <li
                key={index}
                style={{
                  cursor: 'pointer',
                  marginBottom: '8px',
                  padding: '10px',
                  borderRadius: '5px',
                  backgroundColor: '#f0f0f0',
                  transition: 'background-color 0.3s ease',
                }}
                onClick={() => handleQuestionSelection(question)}
                onMouseEnter={(e: any) => { e.target.style.backgroundColor = '#e6e6e6'; }}
                onMouseLeave={(e: any) => { e.target.style.backgroundColor = '#f0f0f0'; }}
              >
                {question}
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return null;
  };


  const renderMessages = () => {
    if (currentConversationIndex !== null && conversations.length > 0) {
      return conversations[currentConversationIndex]?.messages.map((message: any, index: any) => (
        <List.Item key={index} style={{ display: 'flex', justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start' }}>
          <div style={{ maxWidth: '70%', borderRadius: '10px', padding: '8px', backgroundColor: message.sender === 'user' ? '#e6f7ff' : '#f0f0f0' }}>
            {message.sender === 'user' ? '' : <MessageOutlined />} {message.text}
          </div>
        </List.Item>
      ));
    }
    return null;
  };

  const renderConversations = () => (
    conversations.map((conversation: any, index: any) => (
      <List.Item
        key={index}
        onClick={() => setCurrentConversationIndex(index)}
        style={{ cursor: 'pointer' }}
      >
        Conversation {index + 1}
      </List.Item>
    ))
  );

  return (
    <div className={s.footerFooter}>
      {isMobile ? <div className={s.footerBox}>
        <div className={s.footerBoxTop}>
          <div className={s.footerBoxTopMain}>
            <div className={s.logoContainer}>
              {!isMobile && <div className={s.logoBox}>
                <img src={albert_head} style={{ height: "85px" }}></img>
              </div>}
              <a className={s.logoTitle}>MON AMI<br />ALBERT</a>
            </div>
            <p className={s.footerMessage}>La réussite et l’excellence académiques<br /> à portée de tous!</p>
            <p className={s.followUsTitle}>Suivez-nous</p>
            <div className={s.socialBox}>
              <img src={icon_facebook} style={{ height: "21px", marginRight: "30px" }}></img>
              <img src={icon_instagram} style={{ height: "21px", marginRight: "30px" }}></img>
              <img src={icon_linkedin} style={{ height: "21px", marginRight: "30px" }}></img>
              <img src={icon_youtube} style={{ height: "21px" }}></img>
            </div>
          </div>
          <div className={s.footerBoxTopMain}>
            <p className={s.menuTitle}>Explorer</p>
            {isMobile ? <div className={s.menuBox}>
              <div style={{ display: "flex", flexDirection: "column", marginRight: "40px" }}>
                <a href="#" style={{ color: "rgb(204, 204, 204)" }}>Qui Suis-je ?</a>
                <a href="/parent" style={{ color: "rgb(204, 204, 204)" }}>Parent</a>
              </div>
              <div style={{ display: "flex", flexDirection: "column", marginRight: "40px" }}>
                <a href="/teacher" style={{ color: "rgb(204, 204, 204)" }}>Enseignant</a>
                <a href="#" style={{ color: "rgb(204, 204, 204)" }}>Méthodologie</a>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <a href="#" style={{ color: "rgb(204, 204, 204)" }}>Contact</a>
                <a href="/faq" style={{ color: "rgb(204, 204, 204)" }}>Aide</a>
              </div>
            </div> : <div className={s.menuBox}>
              <a href="#" style={{ color: "rgb(204, 204, 204)" }}>QUI SUIS-JE ?</a>
              <a href="/parent" style={{ color: "rgb(204, 204, 204)" }}>PARENT</a>
              <a href="/teacher" style={{ color: "rgb(204, 204, 204)" }}>ENSEIGNANT</a>
              <a href="#" style={{ color: "rgb(204, 204, 204)" }}>MÉTHODOLOGIE</a>
              <a href="#" style={{ color: "rgb(204, 204, 204)" }}>CONTACT</a>
              <a href="/faq" style={{ color: "rgb(204, 204, 204)" }}>AIDE</a>
            </div>}
          </div>
          <div className={s.footerBoxTopMain}>
            <p className={s.menuTitle}>Newsletter</p>
            <p className={s.footerMessage}>Inscrivez-vous à notre newsletter<br />pour découvrir des astuces pédagogiques,<br />accéder à des ressources exclusives,<br />suivre nos consignes et rester<br />informé des dernières nouveautés.</p>
            <div className={s.formBox}>
              <form className={s.newslettersForm}>
                <input className={s.newslettersFormEmail} type="email" id="newsletterEmail" name="newsletterEmail" placeholder="Entrez votre email ici" required></input>
                <button type="submit" className={s.newslettersButton}>Je m’inscris</button>
              </form>
            </div>
          </div>
        </div>
        <div className={s.footerBoxBottom}>
          <div className={s.footerSeparator}></div>
          <div className={s.footerBottomBox}>
            <a className={s.footerCopyrights}>Ⓒ Mon ami Albert 2024  </a>
            <div className={s.copyrightsSeparator}></div>
            <a className={s.legalMention}> Mentions légales</a>
          </div>
        </div>
      </div> : <Container>
        <div className={s.footerBox}>
          <div className={s.footerBoxTop}>
            <div className={s.footerBoxTopMain}>
              <div className={s.logoContainer}>
                {!isMobile && <div className={s.logoBox}>
                  <img src={albert_head} style={{ height: "85px" }}></img>
                </div>}
                <a className={s.logoTitle}>MON AMI<br />ALBERT</a>
              </div>
              <p className={s.footerMessage}>La réussite et l’excellence académiques<br /> à portée de tous!</p>
              <p className={s.followUsTitle}>Suivez-nous</p>
              <div className={s.socialBox}>
                <img src={icon_facebook} style={{ height: "21px", marginRight: "30px" }}></img>
                <img src={icon_instagram} style={{ height: "21px", marginRight: "30px" }}></img>
                <img src={icon_linkedin} style={{ height: "21px", marginRight: "30px" }}></img>
                <img src={icon_youtube} style={{ height: "21px" }}></img>
              </div>
            </div>
            <div className={s.footerBoxTopMain}>
              <p className={s.menuTitle}>Explorer</p>
              {isMobile ? <div className={s.menuBox}>
                <div style={{ display: "flex", flexDirection: "column", marginRight: "40px" }}>
                  <a href="#" style={{ color: "rgb(204, 204, 204)" }}>Qui Suis-je ?</a>
                  <a href="/parent" style={{ color: "rgb(204, 204, 204)" }}>Parent</a>
                </div>
                <div style={{ display: "flex", flexDirection: "column", marginRight: "40px" }}>
                  <a href="/teacher" style={{ color: "rgb(204, 204, 204)" }}>Enseignant</a>
                  <a href="#" style={{ color: "rgb(204, 204, 204)" }}>Méthodologie</a>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <a href="#" style={{ color: "rgb(204, 204, 204)" }}>Contact</a>
                  <a href="/faq" style={{ color: "rgb(204, 204, 204)" }}>Aide</a>
                </div>
              </div> : <div className={s.menuBox}>
                <a href="#" style={{ color: "rgb(204, 204, 204)" }}>QUI SUIS-JE ?</a>
                <a href="/parent" style={{ color: "rgb(204, 204, 204)" }}>PARENT</a>
                <a href="/teacher" style={{ color: "rgb(204, 204, 204)" }}>ENSEIGNANT</a>
                <a href="#" style={{ color: "rgb(204, 204, 204)" }}>MÉTHODOLOGIE</a>
                <a href="#" style={{ color: "rgb(204, 204, 204)" }}>CONTACT</a>
                <a href="/faq" style={{ color: "rgb(204, 204, 204)" }}>AIDE</a>
              </div>}
            </div>
            <div className={s.footerBoxTopMain}>
              <p className={s.menuTitle}>Newsletter</p>
              <p className={s.footerMessage}>Inscrivez-vous à notre newsletter<br />pour découvrir des astuces pédagogiques,<br />accéder à des ressources exclusives,<br />suivre nos consignes et rester<br />informé des dernières nouveautés.</p>
              <div className={s.formBox}>
                <form className={s.newslettersForm}>
                  <input className={s.newslettersFormEmail} type="email" id="newsletterEmail" name="newsletterEmail" placeholder="Entrez votre email ici" required></input>
                  <button type="submit" className={s.newslettersButton}>Je m’inscris</button>
                </form>
              </div>
            </div>
          </div>
          <div className={s.footerBoxBottom}>
            <div className={s.footerSeparator}></div>
            <div className={s.footerBottomBox}>
              <a className={s.footerCopyrights}>Ⓒ Mon ami Albert 2024  </a>
              <div className={s.copyrightsSeparator}></div>
              <a className={s.legalMention}> Mentions légales</a>
            </div>
          </div>
        </div>
      </Container>}

      {!isMobile && <div>
        {user?.roles?.includes("student") ? (
          <Tooltip title="Start chatting with your assistant">
            <Button
              type="primary"
              shape="circle"
              icon={<MessageOutlined />}
              size="large"
              style={{ position: 'fixed', bottom: 20, right: 60 }}
              onClick={handleIconClick}
            />
          </Tooltip>
        ) : (
          <></>
        )}

        <Drawer
          title="Chat with Assistant"
          placement="right"
          width="95%"
          onClose={handleCloseDrawer}
          visible={drawerVisible}
        >
          <div style={{ height: '100%', display: 'flex' }}>
            <div style={{ width: '25%', borderRight: '1px solid #e8e8e8', padding: '10px' }}>
              <Button
                type="default"
                icon={<PlusOutlined />}
                onClick={handleNewConversation}
                style={{ marginBottom: '10px' }}
              >
                New Conversation
              </Button>
              <div style={{ height: '70vh', overflowY: 'auto' }}>
                <List
                  dataSource={conversations}
                  renderItem={(item: any, index: any) => (
                    <>
                      <div style={{
                        display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', marginTop: '10px', fontSize: '16px', paddingTop: '4px',
                        paddingBottom: '7px',
                        paddingRight: '16px',
                        paddingLeft: '16px',
                        borderRadius: '15px',
                        backgroundColor: '#f0f0f0',
                        transition: 'background-color 0.3s ease',
                      }}
                        onMouseEnter={(e: any) => { e.target.style.backgroundColor = '#e6e6e6'; }}
                        onMouseLeave={(e: any) => { e.target.style.backgroundColor = '#f0f0f0'; }}
                      >
                        <span
                          onClick={() => { setShowSuggestedQuestions(true); setCurrentConversationIndex(index); fetchConversation(index); }}
                          style={{ cursor: 'pointer', flex: '1', color: index === currentConversationIndex ? 'black' : 'grey' }}
                        >
                          {item ? item.title : 'Nouvelle conversation'}
                        </span>
                        <Popconfirm
                          title={
                            <Input
                              placeholder="Enter un titre"
                              value={textInput}
                              onChange={handleTextInputChange}
                            />
                          }
                          onConfirm={() => updateConversation(item.id, textInput?.substring(0, 30) + '...')}
                          okText="Oui"
                          cancelText="Non"
                        >
                          <EditOutlined
                            style={{ cursor: 'pointer', marginRight: '10px', marginLeft: '4px' }}
                          />
                        </Popconfirm>
                        <Popconfirm
                          title="Voulez-vous supprimer cette conversation ?"
                          onConfirm={() => deleteConversation(item.id)}
                          okText="Oui"
                          cancelText="Non"
                        >
                          <DeleteOutlined style={{ cursor: 'pointer' }} />
                        </Popconfirm>
                      </div>
                    </>
                  )}
                />
                <div ref={conversationsEndRef} />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', height: '89vh', width: '100%' }}>
              <div style={{ flex: '1', padding: '10px', overflowY: 'auto' }}>
                <List
                  dataSource={currentConversationIndex !== null ? conversations[currentConversationIndex].messages : []}
                  renderItem={(item: any, index: any) => (
                    <List.Item key={index} style={{ display: 'flex', justifyContent: item.sender === 'user' ? 'flex-end' : 'flex-start' }}>
                      <div style={{ maxWidth: '85%', borderRadius: '10px', padding: '8px', backgroundColor: item.sender === 'user' ? '#e6f7ff' : '#f0f0f0' }}>
                        {item.sender === 'user' ? '' : <MessageOutlined />}
                        <div
                          dangerouslySetInnerHTML={{ __html: formatTextWithLineBreaks(item.text) }}
                        />

                        {/* Conditionally render the second block only if chapterStatus is not empty */}
                        {(item?.chapterId && item?.chapterId !== '') && (
                          <>
                            <div><br /></div>

                            <div style={{ marginBottom: '15px' }} >Voici des suggestions de nos cours, suite à notre discussion :</div>

                            <div key={item._id} className={sStyle.__body}>
                              <div className={sStyle.__big__title__content}>
                                <h3 className={sStyle.__big__title}>
                                  {item?.chapterContent?.title}
                                </h3>
                              </div>

                              <div className={sStyle.appercue__continute__content}>
                                {item?.chapterContent?.subtitles?.slice(0, 3).map((x: any) => (
                                  <div key={x._id}>
                                    <h4>{x?.title}</h4>
                                    {x?.subtitles?.slice(0, 3).map((itemX: any) => (
                                      <>
                                        <div key={itemX._id} className={sStyle.section__content}>
                                          <h4>{itemX?.title}</h4>
                                        </div>
                                        <Latex>
                                          {itemX?.content
                                            ? itemX?.content.replace("amp;", "").replace("<br />", "")
                                            : ""}
                                        </Latex>
                                      </>
                                    ))}
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div style={{ marginTop: '40px', marginBottom: '10px', display: 'flex', justifyContent: 'space-around' }} >
                              <Button
                                key={'chapterShowMore'}
                                style={{
                                  fontSize: '16px',
                                  backgroundColor: '#f0f0f0',
                                  color: 'black',
                                  border: '1px solid #ccc',
                                  borderRadius: '5px',
                                  cursor: 'pointer',
                                  paddingLeft: '118px',
                                  paddingRight: '118px'
                                }}
                                onMouseEnter={(e: any) => { e.target.style.backgroundColor = '#e6e6e6'; }}
                                onMouseLeave={(e: any) => { e.target.style.backgroundColor = '#f0f0f0'; }}
                                onClick={() => handleShowCoursClick(item?.chapterId)}
                              >
                                {'Consulter le cours'}
                              </Button>
                            </div>
                          </>
                        )}

                        {(item?.quizzId && item?.quizzId !== '') && (
                          <>
                            <div style={{ marginTop: '5px', marginBottom: '5px', display: 'flex', justifyContent: 'space-around' }} >
                              <Button
                                key={'quizzShowMore'}
                                style={{
                                  fontSize: '16px',
                                  backgroundColor: '#f0f0f0',
                                  color: 'black',
                                  border: '1px solid #ccc',
                                  borderRadius: '5px',
                                  cursor: 'pointer',
                                  paddingLeft: '130px',
                                  paddingRight: '130px'
                                }}
                                onMouseEnter={(e: any) => { e.target.style.backgroundColor = '#e6e6e6'; }}
                                onMouseLeave={(e: any) => { e.target.style.backgroundColor = '#f0f0f0'; }}
                                onClick={() => handleShowQuizzClick(item?.quizzId)}
                              >
                                {'Faire des quiz'}
                              </Button>
                            </div>
                          </>
                        )}

                        {(item?.exerciceId && item?.exerciceId !== '') && (
                          <>
                            <div style={{ marginTop: '5px', marginBottom: '5px', display: 'flex', justifyContent: 'space-around' }} >
                              <Button
                                key={'exerciceShowMore'}
                                style={{
                                  fontSize: '16px',
                                  backgroundColor: '#f0f0f0',
                                  color: 'black',
                                  border: '1px solid #ccc',
                                  borderRadius: '5px',
                                  cursor: 'pointer',
                                  paddingLeft: '118px',
                                  paddingRight: '120px'
                                }}
                                onMouseEnter={(e: any) => { e.target.style.backgroundColor = '#e6e6e6'; }}
                                onMouseLeave={(e: any) => { e.target.style.backgroundColor = '#f0f0f0'; }}
                                onClick={() => handleShowExerciceClick(item?.chapterId)}
                              >
                                {'Faire des exercice'}
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    </List.Item>
                  )}
                />
                <div ref={messagesEndRef} />
              </div>

              {renderSuggestedQuestions()}

              <div style={{ padding: '10px', overflowY: 'auto', position: 'sticky' }}>
                <div style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '10px',
                }}>
                  {topics.map((topic: any) => (
                    <Button
                      key={topic._id}
                      style={{
                        fontSize: '16px',
                        backgroundColor: selectedTopic === topic.description ? '#1890ff' : '#f0f0f0',
                        color: selectedTopic === topic.description ? 'white' : 'black',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s ease, color 0.3s ease',
                      }}
                      onClick={() => handleTopicClick(topic.description)}
                    >
                      {topic.description}
                    </Button>
                  ))}
                </div>
              </div>

              <div style={{ padding: '10px', position: 'sticky', bottom: 0, backgroundColor: '#ffffff' }}>
                <Input.TextArea
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder="Que souhaitez-vous savoir aujourd'hui ? Posez votre question ici et obtenez une réponse rapide ! 📚"
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
                <Button type="primary" onClick={handleSendMessage} style={{ marginTop: '10px' }} >
                  Envoyer
                </Button>
              </div>
            </div>
          </div>

          {/* Loader component New Conversation*/}
          {showLoaderNewConversation && (
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <Spin size="large" />
            </div>
          )}
        </Drawer>

        <Drawer
          title="Contenu du cours"
          placement="right"
          width="70%"
          onClose={handleShowCoursCloseDrawer}
          visible={drawerCoursVisible}
        >
          <div className={sStyle.appercue__continute__bot}>
            {!loading && <h3>Cours : {coursFetched?.title}</h3>}

            {!loading &&
              coursFetched?.contents?.map((field: any, i: number) => (
                <div key={i} className={sStyle.__body}>
                  <div className={sStyle.__big__title__content}>
                    <h3 className={sStyle.__big__title}>1 - {field?.title}</h3>
                  </div>

                  <div className={sStyle.appercue__continute__content}>
                    {field?.subtitles?.map((x: any) => (
                      <>
                        <h4>{x?.title}</h4>
                        {x?.subtitles?.map((item: any) => (
                          <>
                            <div className={sStyle.section__content}>
                              <h4>{item?.title}</h4>
                            </div>

                            <Latex>{item?.content ? item?.content : ""}</Latex>
                          </>
                        ))}
                      </>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </Drawer>

        <Drawer
          title="Contenu du quiz"
          placement="right"
          width="70%"
          onClose={handleShowQuizzCloseDrawer}
          visible={drawerQuizzVisible}
        >
          <StudentCoursQuiz key={quizzId} id={quizzId} handleShowQuizzCloseDrawer={handleShowQuizzCloseDrawer} />
        </Drawer>

        <Drawer
          title="Contenu des exercices"
          placement="right"
          width="70%"
          onClose={handleShowExerciceCloseDrawer}
          visible={drawerExerciceVisible}
        >
          <StudentExercices key={exerciceId} id={exerciceId} />
        </Drawer>

      </div>}

    </div>
  );
};

export default FooterFooter;
