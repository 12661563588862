import { Table } from 'antd';
import React from 'react';

type Props = {
    payments: any;
}

const ParentOrganismTopUpTable: React.FC<Props> = ({ payments }) => {
    // Define columns for the table
    const columns = [
        {
            title: 'Date du cours',
            dataIndex: 'date',
            key: 'date',
            render: (text: string) => {
                const formattedDate = text.substring(8, 10) + '-' + text.substring(5, 7) + '-' + text.substring(0, 4) + ' ' + text.substring(11, 16);
                return formattedDate;
            }
        },
        {
            title: 'Élève',
            dataIndex: 'stud',
            key: 'stud',
            render: (stud: any) => {
                if (stud) {
                    return `${stud.lastname}, ${stud.firstname}`;
                } else {
                    return 'N/A';
                }
            }
        },
        {
            title: 'Professeur',
            dataIndex: 'prof',
            key: 'prof',
            render: (prof: any) => {
                if (prof) {
                    return `${prof.lastname}, ${prof.firstname}`;
                } else {
                    return 'N/A';
                }
            }
        },
        {
            title: 'Matière',
            dataIndex: 'matiere',
            key: 'matiere',
            render: (text: string) => {
                return `${text}`;
            }
        },
        {
            title: 'Durée',
            dataIndex: 'time',
            key: 'time',
            render: (text: string) => {
                if (text) {
                    const hours = parseInt(text.split(' ')[0]);
                    return `${hours}h`;
                } else {
                    return 'N/A';
                }
            }
        },
        {
            title: 'Coût',
            dataIndex: 'montant',
            key: 'montant',
            render: (amount: string) => {
                return `${amount} €`;
            }
        }
    ];

    return (
        <Table dataSource={payments} columns={columns} />
    );
};

export default ParentOrganismTopUpTable;
