import { Col, Row } from "antd";
import AppButton from "components/libs/button";
import H1 from "components/libs/title";
import Stylo from "components/stylo";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import AnimatedPage from "utils/animatedPage";
import AppScreen from "../ScreenWithSidebar";

//!STYLES
import s from "./assets/contact.module.css";

const Contact: FC = () => {
  //!HOOKS
  const user = useSelector((state) => state.auth);
  const teacher = user?.user;

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  return (
    <AnimatedPage>
      {/* <Breadcrumbs /> */}
      <Stylo />
      <AppScreen className={s.__contact}>
        <H1>Contact</H1>

        <div className={isMobile ? s.main__contact_mobile : s.main__contact}>
          <div className={s.__main_left}>
            <Row justify="space-between">
              <Col span={12}>
                {" "}
                <label htmlFor="">Nom</label>
                <div className={s.field}>
                  <input type="text" value={teacher?.firstname} />
                </div>
              </Col>
              <Col span={12}>
                <label htmlFor="">Prénom</label>
                <div className={s.field}>
                  <input
                    type="text"
                    value={teacher?.lastname}
                    placeholder="Entrer votre prénom"
                  />
                </div>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col span={12}>
                <label htmlFor="">Email</label>
                <div className={s.field}>
                  <input type="email" value={teacher?.email} disabled />
                </div>
              </Col>

              <Col span={12}>
                <label htmlFor="">Téléphone</label>
                <div className={s.field}>
                  <input
                    type="number"
                    name="tel"
                    // onChange={(e) => e.target.value}
                    // value={teacher?.phone}
                    placeholder="Entrer votre numéro de téléphone"
                  />
                </div>
              </Col>
            </Row>

            <div className={isMobile ? s.paper_mobile : s.paper}>
              <div className={s.paper_content}>
                <textarea autoFocus></textarea>
              </div>
            </div>
          </div>
        </div>

        <Row justify="center">
          <AppButton className={s.__submit}>Envoyer</AppButton>
        </Row>
      </AppScreen>
    </AnimatedPage>
  );
};

export default Contact;
