import React, { FC, useState } from "react";
import { Col, Drawer, Image, Result, Row } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";
import { useNavigate, useParams } from "react-router-dom";

//!COMPONENTS
import Breadcrumbs from "components/breadcrumbs";
import H1 from "components/libs/title";
import Stylo from "components/stylo";
import AppScreen from "screens/teacher/ScreenWithSidebar";
import AppButton from "components/libs/button";
import PageHeaderBack from "components/breadCrumbBack";

//!STYLE
import s from "./assets/exercice.module.css";

//!IMAGES
import { ReactComponent as Close } from "../../../assets/images/app/close.svg";
import { ReactComponent as Back } from "../../../assets/images/app/backBlack.svg";
import Check from "../../../assets/images/app/check.svg";

//!HOOK
import useStudentExercice from "hooks/useStudentExercice.hook";
import useStudentExerciceClues from "hooks/useStudentExoClues.hook";
import useStudentExerciceSolution from "hooks/useStudentExoSolution.hook";
import TabsStudent from "../components/tabs";
import HeaderComponents from "../components/headerComponents";
import H2 from "components/libs/subtitle";
import AppResult from "components/appResults";

const StudentExercice = () => {
  //!HOOK
  const { id } = useParams();
  const navigate = useNavigate();
  const { exercice, loading, error } = useStudentExercice(id!);
  const { clues, loadingClues } = useStudentExerciceClues(id!);
  const { solutions, loadingSolution } = useStudentExerciceSolution(id!);

  //!STATE
  const [drawerIndice, setDrawerIndice] = useState<boolean>(false);
  const [drawerCorrige, setDrawerCorrige] = useState<boolean>(false);

  //!FUNCTION
  const showDrawerIndice = () => setDrawerIndice(!drawerIndice);
  const showDrawerCorrige = () => setDrawerCorrige(!drawerCorrige);

  return (
    <React.Fragment>
      <Breadcrumbs title={exercice?.title} />
      <HeaderComponents />
      <AppScreen className={s.exercice}>
        <TabsStudent
          isPageStudentQuiz={true}
          onClick={() => navigate(-1)}
          textBack="RETOUR À LA LISTE DES EXERCICES"
          onRequest={() => { }}
          onExit={() => { }}
        />

        <H2 className={s.title}>{exercice?.title}</H2>
        <div className={s.main}>
          <div className={s.letter}>
            <h2 className={s.__ennnoncer}>Énoncé de l’exercice</h2>
            <Latex>
              {exercice?.description
                ? exercice?.description
                  ?.replaceAll("amp;", "")
                  ?.replaceAll("<br />", "")
                : ""}
            </Latex>
          </div>

          <Row justify="center" style={{ marginTop: "2em" }}>
            <Col>
              {" "}
              <AppButton
                onClick={showDrawerIndice}
                className={s.__button__indice}
              >
                Afficher l'indice
              </AppButton>
            </Col>
            <Col>
              {" "}
              <AppButton onClick={showDrawerCorrige}>
                {" "}
                Afficher le corriger
              </AppButton>
            </Col>
          </Row>
        </div>
      </AppScreen>

      <Drawer
        title={false}
        width={"75%"}
        closable={true}
        closeIcon={
          <Back
            style={{
              width: "20px",
            }}
          />
        }
        onClose={showDrawerIndice}
        visible={drawerIndice}
      >
        {clues.length > 0 ? (
          <div className={s.letter}>
            <h3 className={s.title__drawer}>Indice de l'exercice</h3>
            <div className={s.__icon_drawer}>
              {/* <Image preview={false} src={Check} /> */}
              <ExclamationCircleOutlined
                style={{
                  color: "#EA9610",
                  fontSize: "20px",
                }}
              />
            </div>
            {!loadingClues && (
              <Latex>
                {clues[0]?.title
                  ? clues[0]?.title
                    ?.replaceAll("amp;", "")
                    ?.replaceAll("<br />", "")
                  : ""}
              </Latex>
            )}
          </div>
        ) : (
          <AppResult status="info" title="Aucun indice renseigné" />
        )}
      </Drawer>

      {/* CORRIGE */}
      <Drawer
        title={false}
        width={"75%"}
        closable={true}
        closeIcon={
          <Back
            style={{
              width: "35px",
            }}
          />
        }
        onClose={showDrawerCorrige}
        visible={drawerCorrige}
      >
        {solutions.length > 0 ? (
          <div className={s.letter}>
            <h3 className={s.title__drawer}>Corriger de l'exercice</h3>
            <div className={s.__icon_drawer}>
              <Image preview={false} src={Check} />
            </div>
            {!loadingSolution && (
              <Latex>
                {solutions[0]?.title
                  ? solutions[0]?.title
                    ?.replaceAll("amp;", "")
                    ?.replaceAll("<br />", "")
                  : ""}
              </Latex>
            )}
          </div>
        ) : (
          <AppResult status="info" title="Aucune solution renseigné" />
        )}
      </Drawer>
    </React.Fragment>
  );
};

export default StudentExercice;
