import * as appRoutes from "config/routes.config";
import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";

//!REDUX
import { useSelector } from "react-redux";
import { useGetContentCountQuery } from "store/services/teacher/count.services";

//!COMPONENTS
import H2 from "components/libs/subtitle";

//!IMAGES
import Cours from "../../../assets/images/app/Cours.svg";
import Exo from "../../../assets/images/app/Exercices.svg";
import Quiz from "../../../assets/images/app/Quiz.svg";

//!STYLES
import { Skeleton } from "antd";
import HelpPopover from "components/help";
import CardContent from "components/libs/cardContent";
import s from "./assets/content.module.css";

type Props = {
  courses: object | any;
  quiz: any;
  exercices: any;
};
const TeacherContent: FC<Props> = ({ courses }) => {
  const goToPage = (url: string) => navigate(url);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { data, isLoading } = useGetContentCountQuery(user?._id);

  return (
    <div className={s.TeacherContent}>
      <div className={s.header}>
        <div className={s.left}>
          <H2 className={s.subtitle}>Mon contenu</H2>
        </div>
        <div style={{ position: "relative", top: "15px", marginLeft: "10px" }}><HelpPopover id={"dashboard_mon_contenu"} /></div>

        <div className={s.center} />
      </div>

      <div className={s.row}>
        {isLoading ? (
          <>
            <Skeleton active />
            <Skeleton active />
          </>
        ) : (
          <>
            <Link to={appRoutes.TEACHER_COURSES}>
              <CardContent
                text={
                  courses?.items?.length <= 0
                    ? "Liste des cours"
                    : "Gérer mes cours "
                }
                iconSize={40}
                icon={Cours}
                coursNumber={true}
                count={data?.data?.cours}
                myContent={data?.data?.mesCours}
              />
            </Link>

            <Link to={appRoutes.TEACHER_QUIZZ}>
              <CardContent
                text="gérer mes quiz"
                icon={Quiz}
                iconSize={57}
                count={data?.data?.quizz}
                coursNumber={true}
                onClick={() => goToPage(appRoutes.TEACHER_QUIZZ)}
                myContent={data?.data?.mesQuizz}
              />
            </Link>

            <Link to={appRoutes.TEACHER_EXERCICES}>
              <CardContent
                text="gérer mes exercices"
                icon={Exo}
                iconSize={47}
                count={data?.data?.exercice}
                coursNumber={true}
                onClick={() => goToPage(appRoutes.TEACHER_EXERCICES)}
                myContent={data?.data?.mesExercice}
              />
            </Link>
          </>
        )}

        <div />
      </div>
    </div>
  );
};

export default TeacherContent;
