import { useState } from "react";

//!COMPONENTS
import AppDrawer from "components/appDrawer";
import ChatSpace from "./components/chat";
import Conversation from "./components/conversation";
import Message from "./components/message";

//!REDUX
import { useDispatch, useSelector } from "react-redux";
import { toggleAppChat } from "store/AppChat/appChat";

//!ASSETS
import { ReactComponent as Back } from "../../assets/images/app/backBlack.svg";

//!STYLE
import { useGetAllRoomsQuery } from "store/services/chat";
import s from "./assets/appChat.module.css";

const AppChat = () => {
  //!HOOKS
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { openAppChat, currentChat } = useSelector((state) => state.appChat);
  const {
    data: conversations,
    isLoading,
    isError,
    refetch,
  } = useGetAllRoomsQuery(user?._id);

  //!STATES
  const [conversation, setConversation] = useState<any[]>([]);
  const [isTyping, setIsTyping] = useState<boolean>(false);

  const [isBlocked, setIsBlocked] = useState<boolean>(false);

  return (
    <AppDrawer
      title={"MESSAGERIE"}
      onClose={() => dispatch(toggleAppChat(false))}
      visible={openAppChat}
      closeIcon={<Back style={{ width: "30px" }} />}
      headerStyle={{ border: "none", marginBottom: 0 }}
      bodyStyle={{ paddingTop: "5px" }}
    >
      <div className={s.app__container}>
        <div className={s.row}>
          <div className={s.conversation}>
            <Conversation
              setConversation={setConversation}
              conversations={conversations}
              isLoading={isLoading}
              isError={isError}
            />
          </div>

          <div className={`${s.chat} ${currentChat && s.active}`}>
            <div className={s.chat__area}>
              <ChatSpace conversation={conversation} isTyping={isTyping} isBlocked={isBlocked} setIsBlocked={setIsBlocked} />
            </div>
            <div className={s.chat__area__footer}>
              <Message
                conversation={conversation}
                setConversation={setConversation}
                setIsTyping={setIsTyping}
                isBlocked={isBlocked}
              />
            </div>
          </div>
        </div>
        <div className={s.conditionsBox}>En utilisant notre chat vous acceptez nos <a href="/assets/files/mentions_legales.pdf" target="_blank" rel="noopener noreferrer">mentions légales</a> et notre <a href="/assets/files/cgu.pdf" target="_blank" rel="noopener noreferrer">politique de confidentialité</a>.</div>
      </div>
    </AppDrawer>
  );
};

export default AppChat;
