import React, { FC, useMemo, useState } from "react";
import AppDrawer from "components/appDrawer";
import H1 from "components/libs/title";

//!STYLE
import s from "./assets/cardTeacher.module.css";

//!ASSETS
import TEACHER from "assets/images/teacher/teacher.svg";

//!COMPONENTS
import { Avatar, Result, Row } from "antd";
import H2 from "components/libs/subtitle";
import AppButton from "components/libs/button";
import { killAllTeacherInsta } from "api/services/advertissements/advertissements.services";
import { useSelector } from "react-redux";
import AppResult from "components/appResults";

interface Props {
  openD: boolean;
  currentProfAccepted: any;
  infoTeacherAcceptedReservation: any;
  setOpenD: React.Dispatch<React.SetStateAction<boolean>>;
  handleDisplayTeacher: (v: any) => void;
}

const SuccesSessionInsta: FC<Props> = ({
  openD,
  setOpenD,
  handleDisplayTeacher,
  currentProfAccepted,
  infoTeacherAcceptedReservation,
}) => {
  const { user } = useSelector((state) => state.auth);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  return useMemo(() => {
    return (
      <AppDrawer
        title={isMobile ? <div>Instaprof confirmé</div> : <H1>Instaprof confirmé</H1>}
        visible={openD}
        onClose={() => {
          setOpenD(false);
        }}
      >
        <AppResult status="success" title="" isMobile={isMobile} />

        <Row justify="center" align="middle">
          <div className={s.card__teacher__reservation}>
            <div className={s.teacher__reservation__avatar}>
              <Avatar
                src={currentProfAccepted?.prof?.profile || TEACHER}
                alt=""
                size={84}
              />
            </div>
            <div className={s.description}>
              <H2>
                {currentProfAccepted?.prof?.firstname}{" "}
                {currentProfAccepted?.prof?.lastname}
              </H2>
            </div>
            <a
              href={infoTeacherAcceptedReservation?.data?.studentLink}
              target="_blank"
            >
              <AppButton>LIEN VISEOCONFÉRENCE</AppButton>
            </a>

            <div
              className={s.open__messagerie}
              onClick={() =>
                handleDisplayTeacher(currentProfAccepted?.prof?._id)
              }
            >
              <span>ENVOYER UN MESSAGE</span>
            </div>
          </div>
        </Row>
      </AppDrawer>
    );
  }, [
    openD,
    setOpenD,
    handleDisplayTeacher,
    currentProfAccepted,
    infoTeacherAcceptedReservation,
  ]);
};

export default SuccesSessionInsta;
