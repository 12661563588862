import Cercle from "components/cercle";
import H2 from "components/libs/subtitle";
import { FC } from "react";

//!ICONS
import Close from "./assets/close.svg";

//!STYLE
import s from "./assets/style.module.css";

type Props = {
  open: boolean;
  setOpen: any;
};
const MAA: FC<Props> = ({ open, setOpen }) => {
  return (
    <>
      <div className={`${s.modal_container} ${open && s.active}`}>
        <div className={s.overlay} onClick={() => setOpen(!open)} />
        <div
          className={s.modal}
          role="dialog"
          aria-labelledby="modalTitle"
          aria-describedby="dialogDesc"
        >
          <div className={s.__header}>
            <H2>Le concept Mon ami albert</H2>
            <p>
              Participe à rédiger le contenu des cours, quiz et exercices pour gagner le maximum de points et devenir mon meilleur ami
            </p>
            <p>Plus tu as de points, moins notre commission sur les cours que tu donnes est importante</p>
          </div>
          <img
            aria-label="close modal"
            className={s.close_modal}
            onClick={() => setOpen(!open)}
            src={Close}
            width={45}
          />
          {open && <Cercle />}
        </div>
      </div>
    </>
  );
};

export default MAA;
