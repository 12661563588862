import { FC, useRef, useEffect } from "react";
import { spring } from "react-flip-toolkit";
import s from "./assets/grid.module.css";

type Props = {
  className?: string;
};
const Grid: FC<Props> = ({ children, className }) => {
  const containerRef = useRef<any>(null);

  useEffect(() => {
    const squares = [...containerRef.current.querySelectorAll(".square")];
    squares.forEach((el, i) => {
      spring({
        config: "wobbly",
        values: {
          translateY: [0, 0],
          opacity: [0, 1],
        },
        onUpdate: ({ translateY, opacity }: any) => {
          el.style.opacity = opacity;
          el.style.transform = `translateY(${translateY}px)`;
        },
        delay: i * 200,
        onComplete: () => {
          // add callback logic here if necessary
        },
      });
    });
  }, []);

  return (
    <div className={`${s.grid} ${className}`} ref={containerRef}>
      {children}
    </div>
  );
};

export default Grid;
