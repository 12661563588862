import { findOneChapters } from "api/services/courses/courses.services";
import { useState, useEffect } from "react";

type Course = {
  _id?: string;
  title?: string;
  description?: string;
  createdAt?: Date;
  updatedAt?: Date;
};

const useCourseChapters = (id: string) => {
  //!STATE
  const [chapters, setChapters] = useState<Course[]>([]);
  const [loading, setLoading] = useState<Boolean>(true);
  const [error, setError] = useState<string>("");

  const fetchCourseChapters = async (passedId: string) => {
    try {
      const response = await findOneChapters(passedId);
      setChapters(response?.data);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCourseChapters(id);
  }, [id]);

  return { chapters, loading, error, fetchCourseChapters };
};

export default useCourseChapters;
