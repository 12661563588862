import { Avatar, Tooltip } from "antd";
import { FC } from "react";

//!STYLES
import s from "./style.module.css";

type Props = {
  user: any;
};

const AvatarGroup: FC<Props> = ({ user }) => {
  return (
    <Avatar.Group
      maxCount={0}
      className={s.avatar}
    >
      <Tooltip
        title={
          <span style={{ textTransform: "capitalize" }}>
            {user.firstname} {user.lastname}
          </span>
        }
        placement="top"
      >
        <Avatar size={50} className={s.avatar} src={user?.profile} />
      </Tooltip>
    </Avatar.Group>
  );
};

export default AvatarGroup;
