import {
  Avatar,
  Button,
  Drawer,
  Image
} from "antd";
import Container from "components/container";
import { FC, useEffect, useState } from "react";

import * as AUTH_SERVICES from "api/services/auth/auth.services";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/material.css";
import { useDispatch, useSelector } from "react-redux";
import { closeProfileDetailsDrawer } from "store/auth/authSlice";
import ArrowBack from "../../assets/images/app/arrow_back.png";
import BadgeInsta from "../../assets/images/students/badgeInstaProf.svg";
import cardImage from '../../assets/images/teacher/card.svg';
import AvatarTeacher from "../../assets/images/teacher/teacher.svg";
import s from "./assets/style.module.css";

//!STYLES

export const ProfileDetailsDrawer: FC = () => {
  //!HOOKS
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [teacherData, setTeacherData] = useState<any>();

  const {
    profileDetailsDrawerOpen,
    profileDetailsID,
    profileDetailsType,
    user,
  } = useSelector((state) => state.auth);

  //STATES
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  //!FUNCTIONS
  const closeDrawer = () => {
    dispatch(closeProfileDetailsDrawer());
  };

  const getTeacherData = async (teacherId: string) => {
    try {
      setIsLoading(true);
      const response = await AUTH_SERVICES.getTeacherData(user?._id, teacherId);
      console.log(response?.data);
      setTeacherData(response?.data);
    } catch (error: any) { }
    finally {
      setIsLoading(false);
    }
  };

  // !USE_EFFECT
  useEffect(() => {
    if (profileDetailsType === "teacher") {
      getTeacherData(profileDetailsID);
    }
  }, [profileDetailsID, profileDetailsType]);

  return (
    <div>
      <Container>
        <Drawer
          headerStyle={{ height: "70px", width: "100%", position: "relative", backgroundColor: "#8AB0B1" }}
          title={<div style={{ display: "flex", flexDirection: "row", background: "#8AB0B1", color: "#FFF", fontSize: "18px", fontWeight: "600", fontFamily: "Poppins" }}><Image src={ArrowBack} width={25} preview={false} onClick={closeDrawer} style={{ cursor: "pointer" }} />  <span style={{ marginLeft: "10px" }}>Détail du profil</span></div>}
          closable={false}
          width={isMobile ? "100%" : "35%"}
          onClose={closeDrawer}
          visible={profileDetailsDrawerOpen}
        >
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div style={{ height: "550px", width: "450px", background: `url(${cardImage})`, backgroundSize: "cover", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start", paddingTop: "35px", paddingLeft: "70px", paddingRight: "20px" }}>
              <img src={BadgeInsta} height={40} style={{ position: "relative", right: "-40px", top: "40px", zIndex: "2" }}></img>
              <Avatar
                src={AvatarTeacher}
                size={100}
                style={{
                  background: "#fff",
                  border: "1px solid #707070"
                }}
              />

              {/* Name and Rate */}
              <div className={s.details}>
                <h3 className={s.name}>NOM DU PROF</h3>
                <p className={s.rate}>20€/heure</p>
                <p className={s.subjects}><span style={{ fontWeight: "700" }}>MATH, PHY</span><br />Terminale, Seconde, Primaire <br /><span className={s.languages}>Français - Anglais</span></p>

                {/* Short Description */}
                <p className={s.description}>
                  Ici c’est le parcours du professeur, à limiter par ... au bout de 3 lignes
                </p>
              </div>

              {/* Buttons */}
              <div className={s.actions}>
                <Button style={{ background: "#707070", borderRadius: "50px", textTransform: "uppercase", color: "#FFF", height: "35px" }}>Planning des cours</Button>
                <Button style={{ background: "#707070", borderRadius: "50px", textTransform: "uppercase", color: "#FFF", height: "35px", marginLeft: "5px" }}>Contactez moi!</Button>
              </div>
            </div>
          </div>
        </Drawer>
      </Container>
    </div>
  );
};
