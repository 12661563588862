import { DownOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, Menu, message, Popconfirm, Spin } from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import { calculateTimeDifference, StatusCode } from 'common/enums';
import Matiers from "components/matiers";
import ScheduleMeeting from 'components/scheduleMeet';
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsReservationsUpdated } from 'store/app/appSlice';
import ClockIcon from "../../../../assets/images/app/clock_icon.svg";
import EllipseTeacherProfileFour from "../../../../assets/images/app/ellipse_teacher_profile4.png";
import EllipseTeacherProfileFive from "../../../../assets/images/app/ellipse_teacher_profile5.png";
import s from "../assets/calendar.module.css";

type Props = {
  course: any;
  isHistorique: boolean;
};

const CardReservations: FC<Props> = ({ course, isHistorique }) => {

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [popconfirmVisible, setPopconfirmVisible] = useState(false);
  const [scheduleData, setScheduleData] = useState<any>(null);
  const [openScheduleMeetingDrawer, setOpenScheduleMeetingDrawer] = useState<boolean>(false);

  const handleMenuClick = (e: any) => {
    console.log('Selected menu item:', e.key);
    if (e.key === "1") {
      console.log('Joining meet');
      openUrlInNewTab();
    } else if (e.key === "2") {
      setPopconfirmVisible(true);
      setDropdownVisible(false);
    }
    else if (e.key === "3") {
      setOpenScheduleMeetingDrawer(true);
      setDropdownVisible(false);
    }
  };

  const handleConfirm = () => {
    console.log('Canceling meet confirmed');
    setPopconfirmVisible(false);
    cancelMeeting();
  };

  const handleCancel = () => {
    console.log('Action cancelled');
    setPopconfirmVisible(false);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">Rejoindre le meet</Menu.Item>
      <Menu.Item key="2">Annuler le meet</Menu.Item>
      <Menu.Item key="3">Replanifier le meet</Menu.Item>
    </Menu>
  );

  const cancelMeeting = async () => {
    setIsLoading(true);
    try {
      const response = await AUTH_SERVICES.cancelMeeting(course?._id);
      if (response?.statusCode === StatusCode.CREATED) {
        message.success({
          content: "Meet annulé ",
        });
        setTimeout(() => {
          dispatch(setIsReservationsUpdated(true));
        }, 3000);
        setIsLoading(false);
      }
      else {
        message.warning({
          content: "Ooops, erreur lors de l'annulation",
        });
        setIsLoading(false);
      }
    } catch (error: any) {
      let content = "";
      for (
        let index = 0;
        index < error?.response?.data?.message?.length;
        index++
      ) {
        content = error?.response?.data?.message[index] + ", " + content;
      }
      message.error({
        content: content,
      });
      setIsLoading(false);
    }
  }

  const scheduleMeeting = async () => {
    setIsLoading(true);
    try {

      const response = await AUTH_SERVICES.scheduleMeeting(scheduleData);
      if (response?.statusCode === StatusCode.CREATED) {
        message.success({
          content: "Meet replanifié ",
        });
        setTimeout(() => {
          dispatch(setIsReservationsUpdated(true));
        }, 3000);
        setIsLoading(false);
      }
      else {
        message.warning({
          content: "Ooops, erreur lors de la replanification",
        });
        setIsLoading(false);
      }
    } catch (error: any) {
      let content = "";
      for (
        let index = 0;
        index < error?.response?.data?.message?.length;
        index++
      ) {
        content = error?.response?.data?.message[index] + ", " + content;
      }
      message.error({
        content: content,
      });
      setIsLoading(false);
    }
  }

  const openUrlInNewTab = () => {
    window.open(course?.studentLink, '_blank');
  };

  useEffect(() => {
    console.log(scheduleData);
    if (scheduleData !== null) {
      scheduleMeeting();
    }
  }, [scheduleData]);

  return (
    <>
      <img src={EllipseTeacherProfileFour} height={106} style={{ position: "absolute", top: "25%", left: "0px", zIndex: "-1" }}></img>
      <img src={EllipseTeacherProfileFive} height={54} style={{ position: "absolute", top: "35%", left: "94%", zIndex: "-1" }}></img>
      <div className={s.classeCard} style={{ cursor: "pointer" }}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", paddingLeft: "20px", paddingRight: "20px" }}>
          <div className={s.avatarNameBox}>
            <Avatar.Group
              maxCount={3}
              maxStyle={{
                color: "#f56a00",
                backgroundColor: "#fde3cf",
              }}
              style={{ marginRight: "5px" }}
            >
              <Avatar
                size={45}
                style={{ backgroundColor: "#FFF2E0", boxShadow: "0px 3px 6px #00000029" }}
                src={course?.profId?.profile}
              />
            </Avatar.Group>
            <div style={{ position: "relative", left: "15px", fontSize: "13px", textTransform: "capitalize" }}>{course?.profId?.firstname + " " + course?.profId?.lastname}</div>
          </div>

          <div className={s.detailsBox}>
            <div className={s.timeBox}>
              <img src={ClockIcon} height={15} style={{ marginRight: "10px" }}></img>
              <div className={s.times}>
                <span className={s.time}>De {course?.range[0]} à {course?.range[course?.range?.length - 1]}</span>
                <span className={s.timeDescription}>Séance de “{calculateTimeDifference(course?.range[0], course?.range[course?.range.length - 1])}”</span>
              </div>
            </div>
            <Matiers
              cours={course?.topic?.title.slice(0, 4) || "null"}
              level={course?.level?.title || "null"}
            />
            <div className={s.amountBox}>
              Séance réservée pour
              <div className={s.priceBox}>{course?.byCredits === "oui" ? "1 Crédit" : course?.price + " €"}</div>
            </div>
          </div>

          {isLoading ? <div>
            <Spin />
          </div> : !isHistorique && <Dropdown overlay={menu} visible={dropdownVisible} onVisibleChange={setDropdownVisible} >
            <Button onClick={() => setDropdownVisible(true)} className={s.meetButton}>
              Action <DownOutlined />
            </Button>
          </Dropdown>}
          <Popconfirm
            title={`Veux-tu annuler ce meet?`}
            visible={popconfirmVisible}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            okText="Oui"
            cancelText="Non"
          >
            <Button type="primary" />
          </Popconfirm>
        </div>
      </div>
      <ScheduleMeeting setScheduleData={setScheduleData} setOpenScheduleMeetingDrawer={setOpenScheduleMeetingDrawer} openScheduleMeetingDrawer={openScheduleMeetingDrawer} id={course?._id} />
    </>
  );
};

export default CardReservations;
