import FadeInOnScroll from "components/FadeIn";
import FadeInUpOnScroll from "components/FadeInUp";
import { useState } from "react";
import copy_book from "../../../../assets/images/app/organisme/copy_book.svg";
import diploma from "../../../../assets/images/app/organisme/diploma.png";
import ellipse_materials from "../../../../assets/images/app/organisme/ellipse_materials.svg";
import pensil from "../../../../assets/images/app/organisme/pensil.svg";
import stocks from "../../../../assets/images/app/organisme/stocks.svg";
import s from "./style.module.css";

const TeachingMaterials = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.mainContainer}>
            <div className={s.materialsContainer}>
                {isMobile && <img src={diploma} className={s.diploma}></img>}
                <div style={{ display: "flex", flexDirection: "column", textAlign: isMobile ? "center" : "left", width: isMobile ? "100%" : "50%", alignItems: isMobile ? "center" : "flex-start", justifyContent: isMobile ? "center" : "flex-start" }}>
                    <FadeInUpOnScroll>
                        <h1 className={s.title}>Nos outils<br />pédagogiques</h1>
                    </FadeInUpOnScroll>
                    <img src={ellipse_materials} className={s.ellipseTitle}></img>
                    {!isMobile && <img src={diploma} className={s.diploma}></img>}
                </div>
                <div className={s.box}>
                    <div className={s.subBox}>
                        <FadeInOnScroll>
                            <div className={s.circleOne}>
                                <img src={pensil} style={{ height: isMobile ? "20px" : "auto" }}></img>
                            </div>
                        </FadeInOnScroll>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: isMobile ? "center" : "flex-start", justifyContent: "center", marginLeft: isMobile ? "0px" : "20px" }}>
                            <div className={s.circleTitle}>
                                <FadeInOnScroll>Ressources gratuites<br />augmentées avec l’IA</FadeInOnScroll>
                            </div>
                            <FadeInOnScroll>
                                <div style={{ marginTop: isMobile ? "15px" : "0px", textAlign: isMobile ? "center" : "left" }}>
                                    <div className={s.circleMessage}>Des centaines de cours, d’exercices et d’annales proposés par niveau et par matière… et augmentés avec l’IA!</div>
                                </div>
                            </FadeInOnScroll>
                        </div>
                    </div>
                    <div className={s.subBox} style={{ marginTop: isMobile ? "30px" : "20px" }}>
                        <FadeInOnScroll>
                            <div className={s.circleTwo}>
                                <img src={copy_book} style={{ height: isMobile ? "20px" : "auto" }}></img>
                            </div>
                        </FadeInOnScroll>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: isMobile ? "center" : "flex-start", justifyContent: "center", marginLeft: isMobile ? "0px" : "20px" }}>
                            <div className={s.circleTitle}>
                                <FadeInOnScroll>Quiz interactifs</FadeInOnScroll>
                            </div>
                            <FadeInOnScroll>
                                <div style={{ marginTop: isMobile ? "15px" : "0px", textAlign: isMobile ? "center" : "left" }}>
                                    <div className={s.circleMessage}>Pour permettre aux élèves de tester leurs connaissances de manière ludique tout en recevant des retours instantanés pour valider leurs acquis.</div>
                                </div>
                            </FadeInOnScroll>
                        </div>
                    </div>
                    <div className={s.subBox} style={{ marginTop: isMobile ? "30px" : "20px" }}>
                        <FadeInOnScroll>
                            <div className={s.circleThree}>
                                <img src={stocks} style={{ height: isMobile ? "20px" : "auto" }}></img>
                            </div>
                        </FadeInOnScroll>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: isMobile ? "center" : "flex-start", justifyContent: "center", marginLeft: isMobile ? "0px" : "20px" }}>
                            <div className={s.circleTitle}>
                                <FadeInOnScroll>Tableaux de bord<br />analytiques & adaptatifs</FadeInOnScroll>
                            </div>
                            <FadeInOnScroll>
                                <div style={{ marginTop: isMobile ? "15px" : "0px", textAlign: isMobile ? "center" : "left" }}>
                                    <div className={s.circleMessage}>Une vue d'ensemble des performances des élèves afin de suivre leurs progrès et d'ajuster leur parcours d'apprentissage en temps réel.</div>
                                </div>
                            </FadeInOnScroll>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeachingMaterials;