import * as AUTH_SERVICES from "api/services/auth/auth.services";
import Loader from "components/pageLoading/Loader";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { FC, useCallback, useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { store } from "store";
import {
  isAuthenticating,
  isLoggedIn,
  logout,
  updateUser,
} from "store/auth/authSlice";
import { findAllLevels } from "store/levels/levelsSlice";

const AuthUser: FC = ({ children }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const authenticateUser = useCallback(async () => {
    try {
      dispatch(isAuthenticating(true));
      const auth = getAuth();
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
          try {
            const finalUser = await AUTH_SERVICES.getUserByUid(user.uid);
            if (finalUser?.data) {
              batch(() => {
                dispatch(updateUser(finalUser.data));
                dispatch(isLoggedIn(true));
              });
            } else {
              throw new Error("L'utilisateur n'existe pas");
            }
          } catch (error) {
            batch(() => {
              dispatch(isLoggedIn(false));
              dispatch(updateUser(null));
              dispatch(logout());
            });
          }
        } else {
          batch(() => {
            dispatch(isLoggedIn(false));
            dispatch(updateUser(null));
          });
        }

        dispatch(isAuthenticating(false));
        if (user) {
          batch(() => {
            store.dispatch(findAllLevels());
          });
        }
        unsubscribe();
      });
    } catch (error) {
      batch(() => {
        dispatch(isLoggedIn(false));
        dispatch(isAuthenticating(false));
        dispatch(updateUser(null));
      });
    }
  }, [dispatch]);

  useEffect(() => {
    authenticateUser();
  }, [authenticateUser]);

  return auth.isAuthenticating ? <Loader /> : <>{children}</>;
};

export default AuthUser;
