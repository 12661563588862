import { FC, useEffect } from "react";

//!STYLES
import s from "./assets/skillbar.module.css";

type Props = {
  width: any;
  className?: string;
  classNameCount?: string;
};

const SkillBar: FC<Props> = ({ width, className, classNameCount }) => {

  useEffect(() => { }, [width]);

  return (
    <div className={s.row}>
      <div className={`${s.skillbar} ${className}`}>
        <span className={s.bar} style={{ width: `${width}%` }} />
      </div>

      <div className={`${s.count} ${classNameCount}`}>
        <span>{parseInt(width)}%</span>
      </div>
    </div>
  );
};

export default SkillBar;
