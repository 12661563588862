import { FC, useRef, useEffect } from "react";
import s from "./assets/row.module.css";
import { spring } from "react-flip-toolkit";
// import "./assets/style.css";

interface FlexProps {
  className?: string;
  ref?: any;
}

const FlexCenter: FC<FlexProps> = ({ children, className, ref }) => {
  const containerRef = useRef<any>(null);

  useEffect(() => {
    const squares = [...containerRef.current.querySelectorAll(".square")];
    squares.forEach((el: any, i: any) => {
      spring({
        config: "wobbly",
        values: {
          translateY: [0, 0],
          opacity: [0, 1],
        },
        onUpdate: ({ translateY, opacity }: any) => {
          el.style.opacity = opacity;
          el.style.transform = `translateY(${translateY}px)`;
        },
        delay: i * 200,
        onComplete: () => {
          // add callback logic here if necessary
        },
      });
    });
  }, []);
  return (
    <div className={`${s.content} ${className}`}>
      <div className={s.grid} ref={containerRef}>
        {children}
      </div>
    </div>
  );
};

export default FlexCenter;
