import {
  ArrowLeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Space,
  Typography,
  message,
} from "antd";
import Container from "components/container";
import { FC, useEffect, useState } from "react";
import { changeFlag } from "store/courses/coursesSlice";

import * as CHAPTERS_API from "api/services/chapters/chapters.services";
import * as appRoutes from "config/routes.config";
import useCourse from "hooks/useCourse.hook";
import useCourseChapters from "hooks/useCourseChapters.hook";
import { useNavigate, useParams } from "react-router-dom";

import { CKEditor } from "ckeditor4-react";
import { CHOICE, StatusCode } from "common/enums";
import Breadcrumbs from "components/breadcrumbs";
import HelpPopover from "components/help";
import AppButton from "components/libs/button";
import H2 from "components/libs/subtitle";
import MathScan from "components/maths";
import UploadCloudinary from "components/uploadCloudinary";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";
import { useDispatch, useSelector } from "react-redux";
import s from "screens/teacher/createCours/assets/createCours.module.css";
import AppScreen from "../ScreenWithSidebar";
import TableCours from "../createCours/table";

const { Title } = Typography;

const EditCourseUpdated: FC = () => {
  //!DUMMY DATA

  //!HOOKS
  const { user } = useSelector((state) => state.auth);

  const levels = useSelector((state) => state.levels);
  const topics = useSelector((state) => state.topics);
  const navigate = useNavigate();
  const params = useParams<"id">();
  const { loading, course } = useCourse(params.id!);
  const dispatch = useDispatch();
  const {
    chapters,
    loading: chaptersLoading,
    fetchCourseChapters,
  } = useCourseChapters(params.id!);
  const [form] = Form.useForm();
  //!STATE
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [addChapterLoading, setAddChapterLoading] = useState(false);
  const [editorLoading, setEditorLoading] = useState(true);
  const { flag } = useSelector((s) => s.courses);
  const [isUpdatingCourse, setIsUpdatingCourse] = useState<boolean>(false);
  const [isUpdatingBrouillonCourse, setIsUpdatingBrouillonCourse] =
    useState<boolean>(false);

  //
  const [addCourseLoading, setAddCourseLoading] = useState(false);
  const [courses, setCourses] = useState<any>([]);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [display, setDisplay] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [tempTopic, setTempTopic] = useState<any>();
  const [tempForm, setTempForm] = useState<any>([]);
  const [tempContentCkEditor, setTempContentCkEditor] = useState<any>([]);
  const [tempValues, setTempValues] = useState<any>(null);
  const [loadingMath, setLoadingMath] = useState<boolean>(false);
  const [isVisibleCopy, setIsVisibleCopy] = useState<boolean>(false);
  const [dataMathPix, setDataMathPix] = useState<any>(null);

  //!FUNCTIONS
  const goToCourses = () => navigate(appRoutes.TEACHER_COURSES);

  const handleFilterValues = (value: any) => {
    value?.map((field: any) => {
      delete field?.updatedAt;
      delete field?.__v;
      delete field?._id;
      delete field?.createdAt;

      if (field?.subtitles?.length <= 0) {
        return false;
      }

      field.subtitles?.map((subtitle: any) => {
        delete subtitle?.updatedAt;
        delete subtitle?.__v;
        delete subtitle?._id;
        delete subtitle?.createdAt;

        handleFilterValues(field.subtitles);
      });
    });
    return value;
  };

  // const propositions = async (values: any) => {
  //   const newValues = handleFilterValues(values?.contents);
  //   values.contents = newValues;

  //   let idProposition = params.id;
  //   console.log(values, "values");
  //   const response: any = await CHAPTERS_API.update(idProposition, values);
  //   try {
  //     if (response?.statusCode === StatusCode.CREATED) {
  //       message.success("Cours modifier avec succès");
  //       navigate(appRoutes.TEACHER_COURSES);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const propositions = async (values: any) => {
    const newValues = handleFilterValues(values?.contents);
    values.contents = newValues;

    let idProposition = params.id;
    try {
      if (flag === CHOICE.SAVE_PERMANENTLY) {
        setIsUpdatingCourse(true);
      } else {
        setIsUpdatingBrouillonCourse(true);
      }
      const response: any = await CHAPTERS_API.updateChapterFunction(
        flag,
        idProposition,
        values
      );

      if (response?.statusCode === StatusCode.OK) {
        setIsUpdatingCourse(false);
        setIsUpdatingBrouillonCourse(false);

        if (flag === CHOICE.SAVE_PERMANENTLY) {
          message.success("Cours crée avec succès ");
        } else {
          message.success("Cours enregistré dans les brouillons ");
        }
        navigate(appRoutes.TEACHER_COURSES);
      } else {
        setIsUpdatingCourse(false);
        setIsUpdatingBrouillonCourse(false);
        message.error("un erreur est survenue");
      }
    } catch (error) {
      setIsUpdatingCourse(false);
      setIsUpdatingBrouillonCourse(false);
      message.error("un erreur est survenue");
    }
  };

  const handleTempForm = (_?: any, allFields?: any) => {
    console.log(allFields);
    let content = allFields
      .filter((field: any) => {
        return field.name.join() === "contents";
      })
      .map((c: any) => c.value);

    setTempForm(content);
  };

  const showLargeDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => { }, [
    handleTempForm,
    tempContentCkEditor,
    tempForm,
    tempValues,
  ]);

  const successMessageCopy = () => {
    message.success("Copier avec succès");
  };

  //!RETURN
  if (loading)
    return (
      <Container>
        <div>Loading...</div>
      </Container>
    );

  return (
    <div style={{ overflow: "hidden" }}>
      <Breadcrumbs />
      <AppScreen>
        <div className={s.header}>
          <div className={s.left}>
            <H2 className={s.subtitle}>Titre du cours : {course?.title}</H2>
          </div>

          <div className={s.center} />

          <div style={{ position: "relative", top: "0px", marginRight: "10px" }}><HelpPopover id={"creer_un_cours_apercu"} /></div>

          <div className={s.right}>
            <AppButton
              onClick={() => navigate(appRoutes.TEACHER_COURSES)}
              icon={<ArrowLeftOutlined />}
            >
              Retour à la liste
            </AppButton>
          </div>
        </div>

        <div className={s.main}>
          <Form
            name="dynamic_form_item"
            onFieldsChange={handleTempForm}
            onFinish={propositions}
            initialValues={course}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12} className={s.____left}>
                <Form.List name="contents">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, h1Key) => (
                        <div style={{ position: "relative" }}>
                          <Form.Item
                            {...restField}
                            name={[name, "title"]}
                            rules={[
                              { required: true, message: "Missing title" },
                            ]}
                          >
                            <Input
                              className={s.field}
                              placeholder="grand titre"
                            />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            name={[name, "type"]}
                            className={s.__input__type}
                            initialValue="h1"
                          ></Form.Item>
                          <MinusCircleOutlined
                            style={{
                              position: "absolute",
                              right: "-16px",
                              top: "20px",
                            }}
                            onClick={() => remove(name)}
                          />

                          <Form.Item name={[name, "subtitles"]}>
                            <Form.List name={[name, "subtitles"]}>
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map(
                                    ({ key, name, ...restField }, h2Key) => (
                                      <div
                                        style={{ position: "relative" }}
                                        key={key}
                                      >
                                        <Form.Item
                                          {...restField}
                                          name={[name, "title"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Missing subtitle",
                                            },
                                          ]}
                                        >
                                          <Input
                                            className={s.field}
                                            placeholder="sous titre"
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          {...restField}
                                          className={s.__input__type}
                                          name={[name, "type"]}
                                          initialValue="h2"
                                        ></Form.Item>
                                        <MinusCircleOutlined
                                          style={{
                                            position: "absolute",
                                            right: "-16px",
                                            top: "20px",
                                          }}
                                          onClick={() => remove(name)}
                                        />

                                        <Form.Item name={[name, "subtitles"]}>
                                          <Form.List name={[name, "subtitles"]}>
                                            {(fields, { add, remove }) => (
                                              <>
                                                {fields.map(
                                                  (
                                                    { key, name, ...restField },
                                                    sectionKey
                                                  ) => (
                                                    <div
                                                      key={key}
                                                      style={{
                                                        position: "relative",
                                                      }}
                                                    >
                                                      <Form.Item
                                                        {...restField}
                                                        name={[name, "title"]}
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message:
                                                              "Missing section",
                                                          },
                                                        ]}
                                                      >
                                                        <Input
                                                          className={s.field}
                                                          placeholder="Section"
                                                        />
                                                      </Form.Item>
                                                      <div
                                                        className={
                                                          s.__custom__ck_editor
                                                        }
                                                      >
                                                        <Space>
                                                          <UploadCloudinary />
                                                          <MathScan />
                                                        </Space>

                                                        <Form.Item
                                                          name={[
                                                            name,
                                                            "content",
                                                          ]}
                                                          valuePropName="data"
                                                          getValueFromEvent={(
                                                            event,
                                                            _
                                                          ) => {
                                                            const data =
                                                              event.editor.getData();

                                                            return data;
                                                          }}
                                                        >
                                                          {tempContentCkEditor !=
                                                            [] && (
                                                              <CKEditor
                                                                initData={
                                                                  course
                                                                    ?.contents[
                                                                    h1Key
                                                                  ]?.subtitles[
                                                                    h2Key
                                                                  ]?.subtitles[
                                                                    sectionKey
                                                                  ]?.content
                                                                }
                                                                style={{
                                                                  border: "none",
                                                                }}
                                                              />
                                                            )}
                                                        </Form.Item>
                                                      </div>
                                                      <Form.Item
                                                        {...restField}
                                                        name={[name, "type"]}
                                                        className={
                                                          s.__input__type
                                                        }
                                                        initialValue="section"
                                                      ></Form.Item>
                                                      <MinusCircleOutlined
                                                        style={{
                                                          position: "absolute",
                                                          right: "-16px",
                                                          top: "20px",
                                                        }}
                                                        onClick={() =>
                                                          remove(name)
                                                        }
                                                      />
                                                    </div>
                                                  )
                                                )}
                                                <Form.Item>
                                                  <Button
                                                    className={
                                                      s.__add__input__button
                                                    }
                                                    onClick={() => add()}
                                                    block
                                                    icon={<PlusOutlined />}
                                                  >
                                                    ajouter une section
                                                  </Button>
                                                </Form.Item>
                                              </>
                                            )}
                                          </Form.List>
                                        </Form.Item>
                                      </div>
                                    )
                                  )}
                                  <Form.Item>
                                    <Button
                                      className={s.__add__input__button}
                                      onClick={() => add()}
                                      block
                                      icon={<PlusOutlined />}
                                    >
                                      ajouter un sous-titre
                                    </Button>
                                  </Form.Item>
                                </>
                              )}
                            </Form.List>
                          </Form.Item>
                        </div>
                      ))}
                      <Form.Item>
                        <Button
                          className={s.__add__input__button}
                          onClick={() => {
                            add();
                            setDisplay(true);
                          }}
                          block
                          icon={<PlusOutlined />}
                        >
                          Ajouter un Grand titre
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>

              <Drawer
                title={null}
                placement="right"
                onClose={onClose}
                width="70%"
                visible={visible}
                footer={false}
              >
                <div className={s.appercue__continute}>
                  <h3>Aperçu : {courses?.title}</h3>
                  {tempForm[0]?.map((field: any, i: number) => (
                    <div key={i}>
                      <div className={s.__big__title__content}>
                        <h3 className={s.__big__title}>
                          {i + 1} - {field?.title}
                        </h3>
                      </div>

                      <div className={s.appercue__continute__content}>
                        {field?.subtitles?.map((x: any) => (
                          <>
                            <h4>{x?.title}</h4>
                            {x?.subtitles?.map((item: any) => (
                              <>
                                <div className={s.section__content}>
                                  <h4>{item?.title}</h4>
                                </div>
                                <Latex>
                                  {item?.content
                                    ? item?.content
                                      ?.replaceAll("amp;", "")
                                      ?.replaceAll("<br />", "")
                                    : ""}
                                </Latex>
                              </>
                            ))}
                          </>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </Drawer>

              <Col span={12}>
                <div className={s.appercue__continute}>
                  <h3>Aperçu : {courses?.title}</h3>
                  {tempForm[0]?.map((field: any, i: number) => (
                    <div key={i}>
                      <div className={s.__big__title__content}>
                        <h3 className={s.__big__title}>
                          {i + 1} - {field?.title}
                        </h3>
                      </div>

                      <div className={s.appercue__continute__content}>
                        {field?.subtitles?.map((x: any) => (
                          <>
                            <h4>{x?.title}</h4>
                            {x?.subtitles?.map((item: any) => (
                              <>
                                <div className={s.section__content}>
                                  <h4>{item?.title}</h4>
                                </div>
                                <Latex>
                                  {item?.content
                                    ? item?.content
                                      ?.replaceAll("amp;", "")
                                      ?.replaceAll("<br />", "")
                                    : ""}
                                </Latex>
                              </>
                            ))}
                          </>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>

            <Form.Item
              name="courseId"
              hidden
              initialValue={course?.course?._id}
            ></Form.Item>

            <Form.Item name="title" hidden initialValue={courses?.title}>
              <Input
                className={s.field}
                value={courses?.title}
                placeholder="titre"
              />
            </Form.Item>

            <Form.Item name="description" hidden>
              <Input className={s.field} placeholder="description" />
            </Form.Item>

            <Form.Item name="language" hidden initialValue={courses?.language}>
              <Input className={s.field} placeholder="language" />
            </Form.Item>

            <Form.Item name="courseId" initialValue={courses?.courseId}>
              <Input placeholder="sous titre" hidden />
            </Form.Item>

            <Row justify="center">
              <Col span={6}>
                <AppButton onClick={showLargeDrawer} className={s.appercue}>
                  Aperçu
                </AppButton>
              </Col>
              <Col span={6}>
                <AppButton
                  htmlTypeSubmit={true}
                  loading={isUpdatingBrouillonCourse}
                  onClick={() => dispatch(changeFlag(CHOICE.SAVE_AS_DRAFT))}
                >
                  enregistrer{" "}
                </AppButton>
              </Col>
              <Col span={6}>
                <AppButton
                  htmlTypeSubmit={true}
                  loading={isUpdatingCourse}
                  onClick={() => dispatch(changeFlag(CHOICE.SAVE_PERMANENTLY))}
                >
                  Soumettre pour validation
                </AppButton>
              </Col>
            </Row>
            <Form.Item name="teacherId" initialValue={user?._id}>
              <Input placeholder="sous titre" hidden />
            </Form.Item>
          </Form>

          <TableCours />
        </div>
      </AppScreen>
    </div>
  );
};

export default EditCourseUpdated;
