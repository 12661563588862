export const FIND_ALL = (levelId: string) =>
  `${process.env.REACT_APP_API_URL}/levels/${levelId}/courses`;

export const GET_TOPIC_BY_ID = (idTopic: string) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/courses/${
    idTopic[idTopic.length - 1]
  }`;

export const GET_TOPIC_BY_ID_STUDENT = (idTopic: string) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/courses/${idTopic}`;

export const FIND_ALL_QUERIES = (...levelId: string[]) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/courses?levelId=${levelId}`;

// https://api-ressources.monamialbert.com/api/v1/courses?levelId=626aa0e061778ae28ce0f0de,626aa17061778ae28ce0f120
