import { useEffect, useState, useCallback } from "react";
import { findQuizzDifficulty } from "./../api/services/studentQuizz/studentQuizz.services";

const useGetQuiz = (idCours: string, difficulty: string) => {
  //!STATE
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(true);

  const getQuizz = async (id: string, level: string) => {
    setLoading(true);
    try {
      const response = await findQuizzDifficulty(id, level);
      setData(response?.data);
    } catch (error: any) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getQuizz(idCours, difficulty);
  }, []);

  return { data, loading, error, setData, setLoading };
};

export default useGetQuiz;
