import React, { FC } from "react";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";

//!STYLE
import s from "./assets/style.module.css";

interface Props {
  data: any;
}
const ExercicePreview: FC<Props> = ({ data }) => {
  return (
    <div className={s.container}>
      <div className={s.__title}>
        <h3>Title : {data[0]?.name.includes("title") ? data[0]?.value : ""}</h3>
      </div>
      <div className={s.timeline}>
        <div className={`${s.timeline_container} ${s.primary}`}>
          <div className={s.timeline_icon}>
            <i className="far fa-grin-wink"></i>
          </div>
          <div className={s.timeline_body}>
            <h4 className={s.timeline_title}>
              <span className={s.badge}>Contenu de l’énoncé</span>
            </h4>
            <Latex>
              {data[1]?.name?.includes("description")
                ? data[1]?.value
                    ?.replaceAll("amp;", "")
                    ?.replaceAll("<br />", "")
                : ""}
            </Latex>
          </div>
        </div>

        <div className={`${s.timeline_container} ${s.warning}`}>
          {" "}
          <div className={s.timeline_icon}>
            <i className="far fa-grin-hearts"></i>
          </div>
          <div className={s.timeline_body}>
            <h4 className={s.timeline_title}>
              <span className={s.badge}>Indice</span>
            </h4>
            <Latex>
              {data[2]?.name[0]?.includes("clues")
                ? data[2]?.value
                    ?.replaceAll("amp;", "")
                    ?.replaceAll("<br />", "")
                : ""}
            </Latex>
          </div>
        </div>

        <div className={`${s.timeline_container} ${s.success}`}>
          <div className={s.timeline_icon}>
            <i className="far fa-grin-tears"></i>
          </div>
          <div className={s.timeline_body}>
            <h4 className={s.timeline_title}>
              <span className={s.badge}>Solution</span>
            </h4>
            <Latex>
              {data[3]?.name[0]?.includes("solutions")
                ? data[3]?.value
                    ?.replaceAll("amp;", "")
                    ?.replaceAll("<br />", "")
                : ""}
            </Latex>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExercicePreview;
