import { Drawer, Form, Input, Row } from "antd";
import AppButton from "components/libs/button";
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword } from "firebase/auth";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import s from "./password.module.css";

type Props = {
    visible: boolean,
    onClose: () => void,
}

const UpdatePasswordDrawer: React.FC<Props> = ({ visible, onClose }) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    //!HOOKS
    const { t } = useTranslation();
    //const auth = useSelector((state: RootStateOrAny) => state.auth);
    const [formInfos] = Form.useForm();
    const { user } = useSelector((state) => state.auth);

    //!STATES
    const [isLoading, setIsLoading] = useState<boolean>(false);

    //!CONSTANTS
    const validateMessages = {
        required: t("auth.error.required"),
        types: {
            email: t("auth.error.emailInvalid"),
        },
    };

    const closeDrawer = async () => {
        onClose();
    }

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [error, setError] = useState<any>(null);
    const [successMessage, setSuccessMessage] = useState<string>("");

    const handleUpdatePassword = async () => {

        setIsLoading(true);

        try {
            const auth = getAuth();
            const firebaseUser = auth.currentUser;
            const credential = EmailAuthProvider.credential(user?.email, currentPassword);
            await reauthenticateWithCredential(firebaseUser!, credential);
            await updatePassword(firebaseUser!, newPassword);
            setSuccessMessage('Mot de passe mis à jour avec succès!');
            setIsLoading(false);
        } catch (error) {
            setError(error);
            setIsLoading(false);
        }
    };

    return (
        <div>
            <Drawer
                title={<div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
                    <div style={{ textTransform: "uppercase", fontSize: "1.2em", margin: "0px auto" }}>{"Modifier le mot de passe"}</div>
                </div>}
                placement="right"
                closable={true}
                onClose={closeDrawer}
                visible={visible}
                width={isMobile ? "100%" : "70%"}
                headerStyle={{ display: "flex", justifyContent: "space-between" }}
                bodyStyle={{ background: "#FFF", padding: "0px" }}
            >
                <div style={{ alignItems: "flex-start", margin: "40px auto" }}>
                    <Form
                        validateTrigger={["onFinish"]}
                        validateMessages={validateMessages}
                        name="registerParent"
                        autoComplete="off"
                        form={formInfos}
                        onFinish={handleUpdatePassword}
                    >
                        <div style={{ width: "55%", margin: "0px auto" }}>

                            <Form.Item
                                name="oldPassword"
                                rules={[{ required: true }]}
                                style={{ marginBottom: "20px" }}
                            >
                                <Input.Password
                                    className={s.field}
                                    placeholder={"Ancien mot de passe"}
                                    value={currentPassword}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                rules={[
                                    { required: true },
                                    {
                                        pattern: /^.{6,}$/,
                                        message: "Le mot de passe doit contenir au moins 6 caractères !",
                                    },
                                ]}
                                style={{ marginBottom: "20px" }}
                            >
                                <Input.Password
                                    className={s.field}
                                    placeholder={"Nouveau mot de passe"}
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                            </Form.Item>

                            <Form.Item
                                name="confirmPassword"
                                rules={[
                                    { required: true },
                                    ({ getFieldValue }) => ({
                                        validator(_: any, value: any) {
                                            if (!value || getFieldValue("password") === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error("Les mots de passe ne correspondent :(")
                                            );
                                        },
                                    }),
                                ]}
                                style={{ marginBottom: "20px" }}
                            >
                                <Input.Password
                                    className={s.field}
                                    placeholder={t("auth.confirmPassword")}
                                    value={confirmNewPassword}
                                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                                />
                            </Form.Item>
                        </div>


                        <Row justify="center" style={{ marginTop: "30px" }}>
                            <Form.Item>
                                <AppButton loading={isLoading} htmlTypeSubmit={true}><a className={s.nextButton}>Modifier</a></AppButton>
                            </Form.Item>
                        </Row>
                    </Form>
                    {error && <div style={{ color: 'red', margin: "0px auto", textAlign: "center" }}>{error}</div>}
                    {successMessage && <div style={{ color: 'green', margin: "0px auto", textAlign: "center" }}>{successMessage}</div>}
                </div>

            </Drawer>
        </div>
    );

};

export default UpdatePasswordDrawer;