import {
    Form,
    Select,
} from "antd";
import moment from 'moment-timezone';
import React from 'react';

import st from "../../registerByEmail/assets/register.module.css";

const { Option } = Select;

const TimeZoneList: React.FC = () => {

    // Get a list of time zone names
    const timezones = moment.tz.names();

    // Get the current time for each time zone
    const currentTimeByTimezone = timezones.map(timezone => ({
        timezone,
        currentTime: moment.tz(timezone).format('HH:mm'),
        offset: moment.tz(timezone).format('Z'),
    }));

    // Filtering TimeZones
    const filteredTimeZones = currentTimeByTimezone.filter(
        (zone) => !zone.timezone.includes('Etc')
            && !zone.timezone.includes('CST6CDT')
            && !zone.timezone.includes('CET')
            && !zone.timezone.includes('EET')
            && !zone.timezone.includes('EST')
            && !zone.timezone.includes('EST5EDT')
            && !zone.timezone.includes('GMT+')
            && !zone.timezone.includes('GMT-')
            && !zone.timezone.includes('GMT0')
            && !zone.timezone.includes('Greenwich')
            && !zone.timezone.includes('HST')
            && !zone.timezone.includes('MST7MDT')
            && !zone.timezone.includes('PST8PDT')
            && !zone.timezone.includes('UCT')
            && !zone.timezone.includes('UTC')
            && !zone.timezone.includes('Universal')
    );

    return (
        <div style={{ height: "auto", width: "100%" }}>
            <Form.Item
                style={{ marginTop: "0px", marginBottom: "20px", width: "100%", background: "#FFF" }}
                name={"fuseauHorraire"}
                rules={[{ required: false }]}
            >
                <Select
                    style={{ padding: "5px 0px 5px 0px", fontSize: "16px", color: "#333" }}
                    className={st.selectInput}
                    placeholder={<div className={st.placeholderStyle}>15:02 (UTC+1) - Africa/Casablanca</div>}
                    bordered={false}
                    allowClear
                    dropdownStyle={{ padding: "3px" }}
                    showArrow={true}
                >

                    {filteredTimeZones.map(zone => (<option key={zone.timezone} value={zone.currentTime + ` (UTC${zone.offset.split(":")[0].substring(0, 1)}${zone.offset.split(":")[0].substring(2, 3)})` + " - " + zone.timezone} style={{ fontSize: "14.5px", fontStyle: "italic", color: "#333" }}>
                        {zone.currentTime + ` (UTC${zone.offset.split(":")[0].substring(0, 1)}${zone.offset.split(":")[0].substring(2, 3)})` + " - " + zone.timezone}
                    </option>))}

                </Select>
            </Form.Item>
        </div>
    );
};

export default TimeZoneList;