import React from "react";
import style from "../assets/style.module.css";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import "katex/dist/katex.min.css";
import Latex from "react-latex-next";
interface QuizMenuInterface {
  data: any;
}
export const Quiz: React.FC<QuizMenuInterface> = (props) => {
  return (
    <div className={`${style.Quiz} ${style.relative}`}>
      <div className={style.question}>
        <Latex>{props.data?.title}</Latex>
      </div>
      {props.data.length !== 0 ? (
        props.data?.propositions?.map((e: any) => (
          <div
            className={style.card}
            style={{ border: e?.isCorrect ? "solid #449F01" : "solid #FF0000" }}
          >
            <div className={style.__label__content}>
              <label className={style.label}>
                <Latex>{e.title ? e.title : e.title}</Latex>
              </label>
              <div className={style.__black}>
                {e?.isCorrect ? <CheckOutlined /> : <CloseOutlined />}
              </div>
            </div>
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
};
export default Quiz;
