import { UserRoles } from "common/enums";
import * as appRoutes from "config/routes.config";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import AnimatedPage from "utils/animatedPage";

const OrganismeRoute: FC = ({ children }) => {
    const { isLoggedIn, user } = useSelector((state) => state.auth);
    const isOrganisme = user?.roles?.includes(UserRoles.ORGANISME);
    if (!isLoggedIn) return <Navigate to={appRoutes.HOME} />;
    if (isLoggedIn && !isOrganisme) return <Navigate to={appRoutes.FORBIDDEN} />;
    return <AnimatedPage> {children} </AnimatedPage>;
};

export default OrganismeRoute;
