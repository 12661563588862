export const getFiltredQuizzes = () =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/challenge`;
export const CHALLENGE_CLASS = `${process.env.REACT_APP_API_URL_RESSOURCES}/challenges`;

export const getChallengesParClasse = (id: string | undefined) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/challenges/classe/${id}`;
export const getPieStatsParClasse = (id: string | undefined) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/challenges/answers/stats/${id}`;

export const fetchClassementByChallenge = (id: string | undefined) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/challenges/classement/${id}`;

export const getBarStatsParClasse = (id: string | undefined) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/challenges/participants/stats/${id}`;

export const StudentchallengeParticipate = () =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/challenges/participate/idStatic`;

export const StudentChallengQuizz = (idChallenge: string) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/challenges/${idChallenge}/quizz`;

export const studentChallengeQuizzResponses = (
  idChallenge: string,
  idStudent: string
) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/challenges/${idChallenge}/responses/${idStudent}`;
export const fetchChallengesByClassIde = (id: string | undefined) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/challenges?classeId=${id}`;
