import { FC, useState } from "react";
//!STYLE
import { Button } from "antd";
import s from "./style.module.css";

type Props = {
  totalPages: number;
  setcurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
};
const Pagination: FC<Props> = ({ setcurrentPage, currentPage, totalPages }) => {
  const [itemsPerPage, setitemsPerPage] = useState(5);
  const [pageNumberLimit, setpageNumberLimit] = useState(4);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(4);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const handleClick = (event: any) => {
    setcurrentPage(Number(event.target.id));
    window.scrollTo(0, 0);
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(totalPages); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

  const renderPageNumbers = pages?.map((number: any) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? `${s.active}` : ""}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  return (
    <>
      <div style={{ height: "50px", width: "61%", margin: "0px auto", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingLeft: currentPage > 1 ? "25%" : "28%", paddingRight: "10px", marginBottom: "40px" }}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
          {currentPage > 1 && totalPages > 1 && <Button className={s.newButton} onClick={handlePrevbtn} disabled={currentPage == pages[0] ? true : false} style={{ marginRight: "5px" }}>
            Précédent
          </Button>}
          {totalPages > currentPage && <Button className={s.newButton} onClick={handleNextbtn} disabled={currentPage == pages[pages.length - 1] ? true : false}>
            Suivant
          </Button>}
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontSize: "14px", fontFamily: "Poppins", fontWeight: "600", color: "#707070" }}>
          Page <span className={s.currentPagebox}>{currentPage}</span>
          Sur <span style={{ marginLeft: "10px" }}>{totalPages}</span>
        </div>
      </div>
      {/* <ul className={s.pageNumbers}>
        <li>
          <button
            onClick={handlePrevbtn}
            disabled={currentPage == pages[0] ? true : false}
          >
            <ArrowLeftOutlined />
          </button>
        </li>
        {pageDecrementBtn}
        {renderPageNumbers}
        {pageIncrementBtn}

        <li>
          <button
            onClick={handleNextbtn}
            disabled={currentPage == pages[pages.length - 1] ? true : false}
          >
            <ArrowRightOutlined />
          </button>
        </li>
      </ul> */}
    </>
  );
};

export default Pagination;
