import {
  Select,
  Typography
} from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styles from "./style.module.css";

import AppButton from "components/libs/button";
import H1 from "components/libs/title";
import * as appRoutes from "config/routes.config";
import { useNavigate } from "react-router-dom";
import AppScreen from "../ScreenWithSidebar";

//!IMAGES
import AlbertImage from "../../../assets/images/app/levieuxalbert.svg";
//
import Breadcrumbs from "components/breadcrumbs";
import MainTeacherCalendar from "../calendarTeacher";
/* import TeacherCalendarSpecific from "../components/CalendarSpecific"; */

const { Paragraph, Text } = Typography;
const { Option } = Select;
const MESSAGE_PREFIX_PARTAGE_RESEAUX_SOCIAUX =
  "Bonjour ! Rejoins ma classe sur Monamilabert.com en utilisant ce code :";

const TeacherSetCalendar: FC = () => {
  //!HOOKS
  const auth = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  //!STATE
  const [teacherData, setTeacherData] = useState<any>();

  const getTeacherData = async (teacherId: string) => {
    try {
      const response = await AUTH_SERVICES.getTeacherData(user?._id, teacherId);
      setTeacherData(response?.data);
    } catch (error: any) { }
  };

  useEffect(() => {
    getTeacherData(user?._id);
  }, []);

  return (
    <React.Fragment>
      <Breadcrumbs isCalendar={true} />
      {user?.isActive && user?.step === "completed" ?
        <AppScreen className={styles.dashboard}>
          <div className={styles.main}>
            {/* <TeacherCalendar /> */}
            <MainTeacherCalendar currentTeacher={teacherData} />
            {/* <TeacherCalendarSpecific /> */}
          </div>
        </AppScreen> :
        <AppScreen className={styles.dashboard}>
          <H1 className={styles.title}>Bienvenue {auth?.user?.firstname}</H1>
          <div className={styles.dashboardUserBox}>
            <img src={AlbertImage} width={240} height={240} style={{ marginTop: "20px", marginBottom: "10px" }}></img>
            <p className={styles.dashboardUserMessage} style={{ fontFamily: "CartoonFont" }}>Oups, tu n'as pas accès à cette fonctionnalité !<br /> ton compte est en cours de validation </p>
            {user?.step !== "completed" && <AppButton onClick={() => navigate(appRoutes.COMPLETE_PROFILE)}>Completer mon profil</AppButton>}
          </div>
        </AppScreen>
      }
    </React.Fragment>
  );
};

export default TeacherSetCalendar;
