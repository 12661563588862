import { Col, Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";

//!COMPONENTS
import H2 from "components/libs/subtitle";

//!ASSETS
import AlbertHappy from "assets/images/albert/Very happy.svg";
import Student from "assets/images/students/student.svg";

//!STYLES
import { FLAGS } from "common/enums";
import AppDrawer from "components/appDrawer";
import AppButton from "components/libs/button";
import H1 from "components/libs/title";
import { useDispatch, useSelector } from "react-redux";
import {
  setIdReceiver,
  setSelectUserChat,
  toggleAppChat,
} from "store/AppChat/appChat";
import { INIT_MODAL } from "store/notification";
import { useFindRoomMutation } from "store/services/chat";
import s from "./style.module.css";

type Props = {
  currentStudentAccepted: any;
};

const DrawerInstaProfStudentAccepted: React.FC<Props> = ({
  currentStudentAccepted,
}) => {
  //!HOOKS
  const dispatch = useDispatch();
  const { currentStudentInstaProf, user } = useSelector((state) => state.auth);
  const { openModalTeacherAccepted } = useSelector(
    (state) => state.notifications
  );
  const [findRoom, { isSuccess: isSuccessRooms }] = useFindRoomMutation();

  //!STATE
  const [state, setstate] = useState<boolean>(true);

  const handleClose = () => {
    dispatch(INIT_MODAL());
  };

  const handleDisplayTeacher = (idTeacher: string) => {
    const finalData = {
      actor1: user?._id,
      actor2: idTeacher,
      flag: FLAGS.SINGLE_FLAG,
      type: "",
      instaprof: "",
    };

    findRoom(finalData);
    dispatch(setIdReceiver(idTeacher));
    handleClose();
  };

  useEffect(() => {
    if (isSuccessRooms) {
      dispatch(setSelectUserChat(true));
      dispatch(toggleAppChat(true));
    }
  }, [isSuccessRooms]);

  console.log(currentStudentAccepted, "currentStudentAccepted");

  return useMemo(() => {
    return (
      <AppDrawer
        title={false}
        style={{ overflowX: "hidden" }}
        visible={openModalTeacherAccepted}
        onClose={handleClose}
      >
        <div className={s.content}>
          {/* <AppConfetti /> */}
          <Row
            justify="center"
            align="middle"
            gutter={12}
            style={{ marginBottom: "1.5em" }}
          >
            <Col>
              <div className={s.avatar}>
                <img
                  src={
                    currentStudentInstaProf?.data?.student?.profile || Student
                  }
                  alt=""
                />
              </div>
            </Col>
            <Col>
              <H1>
                {currentStudentInstaProf?.data?.student?.firstname} vous a
                CHOISI !
              </H1>
            </Col>
          </Row>

          <Row
            justify="center"
            align="middle"
            gutter={12}
            style={{ marginBottom: "1em" }}
          >
            <Col>
              <div className={s.albert}>
                <img src={AlbertHappy} alt="" width={"55%"} />
              </div>
            </Col>
          </Row>

          <Row
            justify="center"
            align="middle"
            gutter={12}
            style={{ marginBottom: "0.5em" }}
          >
            <Col>
              <H2>Session Instaprof confirmée</H2>
            </Col>
          </Row>
          <Row
            justify="center"
            align="middle"
            gutter={12}
            style={{ marginBottom: "1.3em" }}
          >
            <Col>
              <a
                href={currentStudentAccepted?.profLink}
                target="_blank"
                onClick={handleClose}
              >
                <AppButton>LIEN VISEOCONFÉRENCE</AppButton>
              </a>
            </Col>
          </Row>

          <Row justify="center" align="middle" gutter={12}>
            <Col>
              <span
                onClick={() => handleDisplayTeacher(currentStudentInstaProf?.data?.student?._id)}
                className={s.msg}
              >
                ENVOYER UN MESSAGE
              </span>
            </Col>
          </Row>
        </div>
      </AppDrawer>
    );
  }, [openModalTeacherAccepted, handleClose, currentStudentAccepted]);
};

export default DrawerInstaProfStudentAccepted;
