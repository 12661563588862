import FadeInOnScroll from "components/FadeIn";
import FadeInUpOnScroll from "components/FadeInUp";
import { useState } from "react";
import arrow_history from "../../../../../assets/images/app/home/arrow_history.svg";
import arrow_up_history from "../../../../../assets/images/app/home/arrow_up_history.svg";
import director from "../../../../../assets/images/app/home/director.png";
import ellipse_history from "../../../../../assets/images/app/home/ellipse_history.svg";
import ellipse_history_title from "../../../../../assets/images/app/home/ellipse_history_title.svg";
import EquipeMaa from "../../../../../assets/images/app/home/equupe_maa.png";
import student_history from "../../../../../assets/images/app/home/student_history.png";
import s from "./style.module.css";

const OurHistory = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.mainContainer}>
            <div className={s.ourHistoryBox}>
                <div className={s.mainBox}>
                    <FadeInUpOnScroll>
                        <h1 className={s.title}>Notre historique</h1>
                    </FadeInUpOnScroll>
                    <img src={ellipse_history_title} className={s.ellipseTitle}></img>
                    <FadeInOnScroll>
                        {isMobile ? <p className={s.subtitle}>Mon Ami Albert a commencé en 2011 comme un petit projet initié pour aider<br />les étudiants locaux à exceller académiquement grâce à des professeurs qualifiés<br />et un accompagnement scolaire sur mesure, 100% en ligne. Depuis, nous avons grandi<br />pour devenir aujourd’hui une plateforme globale mettant en lien les professeurs,<br />les élèves et leurs tuteurs, et impactant des milliers de vies à travers des cours personnalisés,<br />des ressources interactives et des solutions d’apprentissage innovantes augmentées avec l’IA.</p> : <p className={s.subtitle}>Mon Ami Albert a commencé en 2011 comme un petit projet initié pour aider<br />les étudiants locaux à exceller académiquement grâce à des professeurs qualifiés<br />et un accompagnement scolaire sur mesure, 100% en ligne.<br />Depuis, nous avons grandi pour devenir aujourd’hui une plateforme globale mettant<br />en lien les professeurs, les élèves et leurs tuteurs, et impactant des milliers de vies<br />à travers des cours personnalisés, des ressources interactives et des solutions<br />d’apprentissage innovantes augmentées avec l’IA.</p>}
                    </FadeInOnScroll>
                    {isMobile ? <div className={s.secondaryBox}>
                        <div className={s.directorImageBox}>
                            <img src={director} className={s.directorImage}></img>
                        </div>
                        <div className={s.directorMessageBox}>
                            <a className={s.directorName}>Equipe</a>
                            <a className={s.directorFunction}>Fondatrice</a>
                            <p className={s.directorMessage}>Derrière Mon Ami Albert, il y a d’abord un groupe d’amis diplômés des Grandes Écoles les plus prestigieuses, unis par une amitié solide et un rêve commun : transformer le soutien scolaire. Passionnés par l’éducation et déterminés à rendre l’excellence plus accessible, ils ont conçu une plateforme mettant la technologie au service de l’apprentissage.<br /><br />Leur objectif ? L’excellence et la réussite académique à portée de TOUS !<br />
                                <a className={s.quote}>‘’</a>
                            </p>
                        </div>
                        <img src={student_history} className={s.studentHistoryImage}></img>
                        <img src={arrow_up_history} style={{ height: "70px", position: "relative", right: "-84%", bottom: "335px" }}></img>
                    </div> : <div className={s.secondaryBox}>
                        <img src={student_history} className={s.studentHistoryImage}></img>
                        <img src={ellipse_history} style={{ height: "10px", position: "relative", left: "-8%", bottom: "-80px" }}></img>
                        <img src={arrow_history} style={{ height: "240px", position: "relative", left: "-4%", bottom: "25px" }}></img>
                        <div className={s.directorImageBox}>
                            <img src={EquipeMaa} className={s.directorImage}></img>
                        </div>
                        <div className={s.directorMessageBox}>
                            <a className={s.directorName}>Equipe</a>
                            <a className={s.directorFunction}>Fondatrice</a>
                            <p className={s.directorMessage}>Derrière Mon Ami Albert, il y a d’abord un groupe d’amis diplômés des Grandes Écoles les plus prestigieuses, unis par une amitié solide et un rêve commun : transformer le soutien scolaire. Passionnés par l’éducation et déterminés à rendre l’excellence plus accessible, ils ont conçu une plateforme mettant la technologie au service de l’apprentissage.<br /><br />Leur objectif ? L’excellence et la réussite académique à portée de TOUS !<br />
                                <a className={s.quote}>‘’</a>
                            </p>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default OurHistory;