import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Course } from "common/types";
import * as EXERCICES_SERVICES from "api/services/exercises/exercises.services";

export interface InitialState {
  loading: boolean;
  items: Course[];
  errors: string[];
}

const initialState: InitialState = {
  loading: false,
  errors: [],
  items: [],
};

export const findAllExercises: any = createAsyncThunk(
  "exercises/findAll",
  async (_, thunkAPI) => {
    try {
      const response = await EXERCICES_SERVICES.findAll();
      if (response?.data) return thunkAPI.fulfillWithValue(response.data);
      return thunkAPI.rejectWithValue("No data found");
    } catch (error) {
      throw thunkAPI.rejectWithValue(error);
    }
  }
);

export const ExercisesSlice = createSlice({
  name: "exercises",
  initialState,
  reducers: {},
  extraReducers: {
    [findAllExercises.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [findAllExercises.fulfilled]: (state, action) => {
      state.loading = false;
      state.items = action.payload;
    },
    [findAllExercises.rejected]: (state) => {
      state.loading = false;
      state.errors.push("Error while fetching topics");
    },
  },
});

const { reducer } = ExercisesSlice;
export default reducer;
