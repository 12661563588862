import { Col, Row } from "antd";
import AppButton from "components/libs/button";
import React, { FC } from "react";

//!STYLES
import s from "./assets/cardChallenge.module.css";

//!IMAGES
import { ReactComponent as ChallengeIcon } from "../../assets/images/challenge.svg";

type Props = {
  handleShowChildrenDrawer?: any;
};
const CardChallenge: FC<Props> = ({ handleShowChildrenDrawer }) => {
  return (
    <div className={s.card}>
      <div>
        <span>Update : 03/03/2021</span>
      </div>

      <div className={s.__matier}></div>

      <div className={s.title__cours}>
        <h3>Continuité</h3>
        <span>30mins</span>
      </div>

      <Row
        justify="space-between"
        style={{ marginTop: "4em", alignItems: "center" }}
      >
        <Col className={s.quizz}>
          <span onClick={handleShowChildrenDrawer}>Aperçu du quiz </span>
        </Col>
        <Col>
          <AppButton
            className={s.__btn}
            icon={
              <ChallengeIcon
                style={{
                  position: "absolute",
                  top: "10px",
                  left: "8%",
                  width: "20px",
                }}
              />
            }
          >
            Challenger cette classe{" "}
          </AppButton>
        </Col>
      </Row>
    </div>
  );
};

export default CardChallenge;
