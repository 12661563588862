import { formatDates } from "utils/formats";
/* import { dateFormatter } from "../Formatters"; */
import style from "./updateDate.module.css";

interface Update__date__Interface {
    date: string,
}

export const UpdateDate: React.FC<Update__date__Interface> = (props) => {
    return (
        <div className={style.update}>
            Update : {" "}
            <span className={style.date}>
                {formatDates(props.date)}
                {/* {dateFormatter(new Date(props.date), "/")} */}
            </span>
        </div>
    )
}
