import { Button, Checkbox, Col, Form, Input, Popover, Row } from "antd";
import React, { FC, useEffect, useState } from "react";

//!COMPONNETS
import AppButton from "components/libs/button";
import H2 from "components/libs/subtitle";
import AppScreen from "../../ScreenWithSidebar";

//!STYLE
import s from "./assets/quiz.module.css";

//!IMAGES
import { ReactComponent as Back } from "assets/images/app/back.svg";

import {
  MinusCircleOutlined,
  PlusOutlined,
  ScanOutlined,
} from "@ant-design/icons";
import MathScan from "components/maths";
import { useSelector } from "react-redux";

interface QuizInterface {
  setCurrentStep: any;
  firstStepData: any;
  isAddingQuizz: boolean;
  setIsStateDraft: any;
  currentCourses: any;
}

const Quiz: FC<QuizInterface> = ({
  setCurrentStep,
  firstStepData,
  isAddingQuizz,
  currentCourses,
  setIsStateDraft,
}) => {
  // !HOOKS
  const allCourses = useSelector((state) => state.courses);
  const [tempTitleCours, setTempCours] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isVisble, setIsVisible] = useState<boolean>(false);

  // !USE_EFFECTS
  useEffect(() => {
    // findCours()
    if (allCourses) {
      allCourses.items.filter((x: any) => {
        if (x?._id === firstStepData?.courseId) {
          return setTempCours(x);
        }
      });
    }
  }, [tempTitleCours]);

  // !RETURNS
  return (
    <React.Fragment>
      <AppScreen className={s.quiz}>
        <div className={s.header}>
          <div className={s.left}>
            <H2 className={s.subtitle}>Quiz</H2>
          </div>

          <div className={s.center} />

          <div className={s.right}>
            <AppButton
              icon={
                <Back
                  style={{
                    position: "relative",
                    top: "4px",
                    right: "10px",
                    width: "15px",
                  }}
                />
              }
              onClick={() => setCurrentStep((c: number) => c - 1)}
            >
              Retour
            </AppButton>
          </div>
        </div>

        <div className={s.main}>
          <div className={s.content}>
            <div className={s.content__header}>
              <div className={s.header__left}>
                <span>Niveau : {firstStepData?.quizzLevel}</span>
              </div>

              <div className={s.header__right}>
                <span>Cours associé : {currentCourses?.label}</span>
              </div>
            </div>

            <div className={s.content__main}>
              <Row justify="space-between">
                <Col span={4}>
                  {" "}
                  <span>Titre du quiz</span>
                </Col>
                <Col span={18}>
                  <div className={s.field}>
                    <Form.Item
                      name={["quizz", "title"]}
                      rules={[
                        {
                          required: true,
                          message: "Ce champ est requis",
                        },
                        {
                          max: 80,
                          message:
                            "Le titre du quiz ne peut pas comporter plus de 80 caractères.",
                        },
                      ]}
                    >
                      <Input bordered={false} placeholder="Titre du quiz" />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row justify="space-between">
                <Col span={4}>
                  <span>Question</span>
                </Col>
                <Col span={18}>
                  {" "}
                  <Form.List name="questions">
                    {(fields, { add, remove }, { errors }) => (
                      <div>
                        {fields.map((field, index) => (
                          <Form.Item required={false} key={field.key}>
                            <>
                              <Row>
                                <Col span={20}>
                                  <div className={s.field}>
                                    <Form.Item
                                      name={[field.name, "title"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Ce champ est requis",
                                        },
                                      ]}
                                    >
                                      <Input
                                        bordered={false}
                                        placeholder="Question"
                                      />
                                    </Form.Item>
                                  </div>
                                </Col>
                                <Col span={4}>
                                  {fields.length > 1 ? (
                                    <MinusCircleOutlined
                                      style={{
                                        position: "relative",
                                        left: "80px",
                                        top: "13px",
                                        color: "#FF0100",
                                        fontSize: "20px",
                                        cursor: "pointer",
                                      }}
                                      className={`dynamic-delete-button`}
                                      onClick={() => remove(field.name)}
                                    />
                                  ) : null}
                                </Col>
                              </Row>

                              <Form.List
                                name={[field.name, "propositions"]}
                                rules={[
                                  {
                                    validator: async (_, names) => {
                                      if (!names || names.length < 2) {
                                        return Promise.reject(
                                          new Error(
                                            "Ajouter au moins 2 propositions"
                                          )
                                        );
                                      }
                                    },
                                  },
                                ]}
                              >
                                {(fields, { add, remove }, { errors }) => (
                                  <>
                                    {fields.map((subfield, index) => (
                                      <div className={s.__field__content}>
                                        <Form.Item
                                          required={false}
                                          key={subfield.key}
                                          style={{
                                            margin: 0,
                                            padding: 0,
                                          }}
                                        >
                                          <Row
                                            gutter={20}
                                            justify="space-between"
                                            align="middle"
                                          >
                                            <Col span={20}>
                                              <div className={s.field}>
                                                <Row justify="space-between">
                                                  <Col span={"20"}>
                                                    <Form.Item
                                                      name={[
                                                        subfield.name,
                                                        "title",
                                                      ]}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message:
                                                            "Ce champ est requis",
                                                        },
                                                      ]}
                                                    >
                                                      <Input
                                                        bordered={false}
                                                        placeholder="Titre de la proposition"
                                                      />
                                                    </Form.Item>
                                                  </Col>

                                                  <Col span={2}>
                                                    <Popover
                                                      placement="top"
                                                      title={false}
                                                      content={<MathScan />}
                                                      trigger="click"
                                                    >
                                                      <ScanOutlined
                                                        style={{
                                                          position: "relative",
                                                          top: "5px",
                                                        }}
                                                      />
                                                    </Popover>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Col>

                                            <Col span={4}>
                                              <Row
                                                align="middle"
                                                style={{
                                                  position: "relative",
                                                  right: "-10px",
                                                  marginBottom: "1.5em",
                                                }}
                                              >
                                                <Col span={2}>
                                                  <Form.Item
                                                    name={[
                                                      subfield.name,
                                                      "isCorrect",
                                                    ]}
                                                    valuePropName="checked"
                                                    noStyle
                                                  >
                                                    <Checkbox
                                                      defaultChecked={false}
                                                    />
                                                  </Form.Item>
                                                </Col>

                                                <Col span={2}>
                                                  <div
                                                    className={`dynamic-delete-button `}
                                                    style={{
                                                      position: "relative",
                                                      right: "-30px",
                                                    }}
                                                  >
                                                    <MinusCircleOutlined
                                                      className={`dynamic-delete-button`}
                                                      onClick={() =>
                                                        remove(subfield.name)
                                                      }
                                                    />
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </Form.Item>
                                      </div>
                                    ))}
                                    <Form.Item>
                                      <Col span={24}>
                                        <Button
                                          block
                                          className={s.__add__input}
                                          onClick={() => add()}
                                          icon={<PlusOutlined />}
                                        >
                                          Ajouter une proposition
                                        </Button>
                                      </Col>
                                      <Col></Col>

                                      <Form.ErrorList errors={errors} />
                                    </Form.Item>
                                  </>
                                )}
                              </Form.List>
                            </>
                          </Form.Item>
                        ))}
                        <Form.Item>
                          <Col span={24}>
                            <Button
                              block
                              className={s.__add__input}
                              onClick={() => {
                                add();
                                setIsVisible(true);
                              }}
                              icon={<PlusOutlined />}
                            >
                              Ajouter une question
                            </Button>
                          </Col>
                          <Col></Col>

                          <Form.ErrorList errors={errors} />
                        </Form.Item>

                        {/* <Col span={2}>
                        <Form.Item
                          {...field}
                          name={[field.name, "isCorrect"]}
                          valuePropName="checked"
                          noStyle
                        >
                          <Checkbox defaultChecked={false} />
                        </Form.Item>
                      </Col> */}
                      </div>
                    )}
                  </Form.List>
                </Col>
              </Row>

              <Row
                justify="center"
                style={{ position: "relative", gap: "25px" }}
              >
                <AppButton
                  className={s.__submit__validation}
                  htmlTypeSubmit={true}
                  loading={isAddingQuizz}
                  onClick={() => setIsStateDraft(true)}
                >
                  enregistrer
                </AppButton>

                <AppButton
                  className={s.__submit__validation}
                  htmlTypeSubmit={true}
                  loading={isAddingQuizz}
                  onClick={() => setIsStateDraft(false)}
                >
                  Soumettre pour validation
                </AppButton>
              </Row>
            </div>
          </div>
        </div>
      </AppScreen>
    </React.Fragment>
  );
};

export default Quiz;
