/* import { DislikeOutlined, LikeOutlined } from '@ant-design/icons'; */
import { Collapse, Tabs } from "antd";
import { FC, useEffect, useState } from "react";
import s from "../assets/faq.module.css";

type Props = {
    data: any;
    activeIndex: any;
}

const { TabPane } = Tabs;
const { Panel } = Collapse;

const FaqsTabs: FC<Props> = ({ data, activeIndex }) => {

    const [activeTabKey, setActiveTabKey] = useState<string>('0');
    const [activeKey, setActiveKey] = useState<string | string[]>('0');

    const handleTabChange = (key: string) => {
        setActiveTabKey(key);
        setActiveKey('0');
    };

    const handlePanelChange = (key: string | string[]) => {
        setActiveKey(key);
    };

    useEffect(() => {
        setActiveTabKey('0');
        setActiveKey('0');
    }, [activeIndex]);

    return (
        <Tabs
            defaultActiveKey="0"
            activeKey={activeTabKey}
            onChange={handleTabChange}
            style={{ minWidth: "1100px", maxWidth: '1100px', marginBottom: "40px", overflowY: 'hidden', }}
            tabBarStyle={{
                display: 'flex',
                maxWidth: '100%',
                overflowY: 'hidden',
            }}
        >
            {data.map((category: any, index: any) => (
                <TabPane
                    tab={category?.title ?? "MonAmiAlbert"}
                    key={index}
                    closeIcon={false}
                    style={{ width: "100%" }}
                >
                    <Collapse accordion activeKey={activeKey} onChange={handlePanelChange} expandIconPosition="end" style={{ borderRadius: '0', background: "#FFF" }}>
                        {category.data.map((faq: any, i: any) => (
                            <Panel
                                header={
                                    <p className={s.question}>
                                        {faq.question || 'Aucune question disponible'}
                                    </p>
                                }
                                key={i}
                                style={{ borderRadius: '0', background: "#FFF" }}>
                                <p
                                    className={s.answer}
                                    dangerouslySetInnerHTML={{ __html: faq.answer || "Aucune réponse disponible" }}
                                />
                                {/* <div style={{ marginTop: '10px' }}>
                                    <p>Avez-vous trouvé ces informations utiles ?</p>
                                    <Button
                                        type="primary"
                                        shape="circle"
                                        icon={<LikeOutlined />}
                                        style={{ marginRight: '10px', background: "#F0EDED", border: "none", color: "#707070" }}
                                    />
                                    <Button
                                        type="primary"
                                        shape="circle"
                                        icon={<DislikeOutlined />}
                                        style={{ background: "#F0EDED", border: "none", color: "#707070" }}
                                    />
                                </div> */}
                            </Panel>
                        ))}
                    </Collapse>
                </TabPane>
            ))}
        </Tabs>
    );
};

export default FaqsTabs;