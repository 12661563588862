import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import AppDrawer from "components/appDrawer";
import H1 from "components/libs/title";
import { Result } from "antd";
import CardHistoryStudent from "screens/student/myClasses/components/cardHistoryCours";

//!STYLES
import s from "./style.module.css";
import FlexCenter from "components/libs/flexCenter";
import AppResult from "components/appResults";

type Props = {
  drawerRes: boolean;
  allSuccessRes: any[];
  currentTeacher: any;
  setDrawerRes: React.Dispatch<React.SetStateAction<boolean>>;
};

const TeacherCalendarDetails: FC<Props> = ({
  setDrawerRes,
  drawerRes,
  currentTeacher,
  allSuccessRes,
}) => {
  console.log(
    allSuccessRes
      .filter((allSuccessRe) => allSuccessRe.length > 0)
      .map((r) => r),
    "allSuccessRes"
  );
  return (
    <AppDrawer
      title={<H1>{"Mes réservations"}</H1>}
      visible={drawerRes}
      onClose={() => {
        setDrawerRes(false);
      }}
    >
      {allSuccessRes.filter((allSuccessRe) => allSuccessRe.length > 0).length <=
      0 ? (
        <AppResult
          status={"info"}
          title={`Tu n'as aucune réservation pour le moment avec ${currentTeacher?.teacher?.firstname}`}
        />
      ) : (
        <>
          <FlexCenter>
            {allSuccessRes
              .filter((allSuccessRe) => allSuccessRe.length > 0)
              ?.map((res: any) => (
                <CardHistoryStudent
                  className={s.card_}
                  item={res[0]}
                  teacher={currentTeacher?.teacher}
                />
              ))}
          </FlexCenter>
        </>
      )}
    </AppDrawer>
  );
};

export default TeacherCalendarDetails;
