import { FC } from "react"

//!STYLES
import s from './assets/title.module.css'

type Props = {
  className?: string,
};

const H1: FC<Props> = ({children, className}) => {
  return <h1 className={`${s.title} ${className}`}>{children}</h1>
}

export default H1