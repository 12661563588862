export const updateQuizzDtoFilter = (rQuiz: any) => {
  // delete rQuiz.chapterId;
  for (let i = 0; i < rQuiz.questions?.length; i++) {
    delete rQuiz.questions[i]?._id;
    delete rQuiz.questions[i]?.createdAt;
    delete rQuiz.questions[i]?.position;
    delete rQuiz.questions[i]?.updatedAt;
    delete rQuiz.questions[i]?.__v;
    delete rQuiz.questions[i]?.status;
    delete rQuiz.questions[i]?.quizz;
    for (let j = 0; j < rQuiz.questions[i]?.propositions.length; j++) {
      delete rQuiz.questions[i]?.propositions[j]?._id;
      delete rQuiz.questions[i]?.propositions[j]?.createdAt;
      delete rQuiz.questions[i]?.propositions[j]?.position;
      delete rQuiz.questions[i]?.propositions[j]?.updatedAt;
      delete rQuiz.questions[i]?.propositions[j]?.__v;
      delete rQuiz.questions[i]?.propositions[j]?.status;
      delete rQuiz.questions[i]?.propositions[j]?.question;
    }
  }

  rQuiz.quizz.courseId = rQuiz.courseId;
  rQuiz.quizz.teacherId = rQuiz.teacherId;
  rQuiz.quizz.quizzLevel = rQuiz.quizzLevel;
  rQuiz.quizz.duration = rQuiz.duration;
  rQuiz.quizz.chapterId = rQuiz.chapterId;

  delete rQuiz.chapterId;

  return { quizz: rQuiz.quizz, questions: rQuiz.questions };
};
