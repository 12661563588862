import { AutoComplete, Avatar, Col, Row, message, notification } from "antd";
import React, { FC, useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFindRoomMutation } from "store/services/chat";

// !SERVICES
import * as USER_SERVICES from "api/services/chat/user-chat/user.services";

//!ASSETS
import TeacherIcon from "assets/images/teacher/teacher.svg";
import CertificationBadge from "components/certificationBadge";

//!STYLE

const { Option } = AutoComplete;

type Props = {
  findConversations: (values: any) => void;
  getAllRooms: (values: any) => void;
  setIsSearch: React.Dispatch<React.SetStateAction<boolean>>;
  setUserInSearch: React.Dispatch<React.SetStateAction<any>>;
  refFirstConversation: React.RefObject<HTMLDivElement>;
};

const SearchUser: FC<Props> = ({
  findConversations,
  setIsSearch,
  getAllRooms,
  setUserInSearch,
  refFirstConversation,
}) => {
  //!HOOKS
  const dispatch = useDispatch();
  const { user: userStore } = useSelector((state) => state.auth);
  const [findRoom, { data: dataFindRoom, isSuccess }] = useFindRoomMutation();

  //!STATES

  const [users, setUsers] = useState([]);
  const refClick = useRef<HTMLDivElement>(null);

  const OnSelectUser = useCallback(async (userNameOrEmail: string, item) => {
    if (userNameOrEmail.length >= 3) {
      try {
        const response = await USER_SERVICES.fetchSuggestedUser(userStore?._id);
        if (response?.statusCode === 201) {
          setUsers(response?.data);
        } else {
          if (userNameOrEmail.length >= 10) {
            message.warning(response?.message);
          }
        }
      } catch (error) {
        if (userNameOrEmail.length >= 6) {
          notification["info"]({
            message: "Information",
            description: "Utilisateur non trouvé : )",
            style: {
              width: 300,
            },
          });
        }
      }
    }
  }, []);

  /* const OnSelectUser = useCallback(async (userNameOrEmail: string, item) => {
    if (userNameOrEmail.length >= 3) {
      const roles = userStore?.roles?.includes(UserRoles.STUDENT)
        ? [UserRoles.TEACHER]
        : [UserRoles.STUDENT];

      try {
        const response = await USER_SERVICES.findOne(userNameOrEmail, roles);

        if (response?.status === "OK") {
          setUsers(response?.data);
        } else {
          if (userNameOrEmail.length >= 10) {
            message.warning(response?.message);
          }
        }
      } catch (error) {
        if (userNameOrEmail.length >= 6) {
          notification["info"]({
            message: "Information",
            description: "Utilisateur non trouvé : )",
            style: {
              width: 300,
            },
          });
        }
      }
    }
  }, []); */

  const onFindConversation = useCallback(async (value: any) => {
    const findRoomBody = {
      actor1: userStore?._id,
      actor2: value?._id,
      flag: "SINGLE_FLAG",
      instaprof: value?.instaProf ? "find" : "",
      type: value?.type ? "instaprof" : "",
    };
    await findRoom(findRoomBody);
    getAllRooms({ actor1: userStore?._id });
    setIsSearch(true);
    findConversations(findRoomBody);
    setUserInSearch(value);
    setTimeout(() => {
      refFirstConversation.current?.click();
    }, 500);
  }, []);

  return (
    <React.Fragment>
      <AutoComplete
        bordered={false}
        style={{ width: "100%" }}
        onChange={OnSelectUser}
        placeholder="Rechercher par nom ou email"
      >
        {users.map((user: any, i: number) => (
          <Option key={i} value={user?.firstname}>
            <div
              onClick={async () => {
                await onFindConversation(user);
              }}
            >
              <Row justify="space-between">
                <Col>
                  <span style={{ textTransform: "capitalize" }}>
                    {" "}
                    {`${user?.pseudo || ""} ( ${user?.lastname || ""} ${user?.firstname || ""} )`}
                    {" "}
                    {user?.certified && <CertificationBadge />}
                  </span>
                </Col>
                <Col>
                  <Avatar src={user?.profile || TeacherIcon} />
                </Col>
              </Row>
            </div>
          </Option>
        ))}
      </AutoComplete>
    </React.Fragment>
  );
};

export default SearchUser;
