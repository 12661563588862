import { FC } from "react"

//!STYLES
import s from './assets/subtitle.module.css'

type Props = {
  className?: string,
};

const H2: FC<Props> = ({children, className}) => {
  return <h2 className={`${s.subtitle} ${className}`}>{children}</h2>
}

export default H2