import React from "react";
import ContentLoader from "react-content-loader";

export const Card: React.FC = (props) => {
  return (
    <div>
      <ContentLoader
        speed={2}
        width={515}
        height={400}
        viewBox="0 0 560 420"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        style={{ background: "#FAFAFA", borderRadius: "10px" }}
        {...props}
      >
        <rect x="205" y="48" rx="9" ry="9" width="175" height="19" />
        <rect x="200" y="100" rx="9" ry="9" width="180" height="33" />
        <rect x="160" y="177" rx="0" ry="0" width="256" height="70" />
        <rect x="254" y="260" rx="9" ry="9" width="74" height="20" />
        <rect x="20" y="360" rx="9" ry="9" width="168" height="28" />
        <rect x="261" y="340" rx="16" ry="16" width="280" height="58" />
      </ContentLoader>
    </div>
  );
};

export default Card;
