import React, { FC } from "react";
import st from "./assets/checkbox.module.css";

type Props = {
    type: string,
    icon: string,
}

const CustomCheckBox: FC<Props> = ({ type, icon }) => {
    return (
        <div className={st.validCheckbox}>
            <img
                height={20}
                width={20}
                src={icon}
                alt="CheckClose"
                className={type === "check" ? st.checkIcon : st.closeIcon}
            />
        </div>
    );
}

export default CustomCheckBox;