import { Avatar, Button, Comment, Form, Input, List, message } from "antd";
import AvatarTeacher from "assets/images/teacher/teacher.svg";
import socket from "config/api.socket";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAddCommentsMutation } from "store/services/teacher/activities.services";

const { TextArea } = Input;

interface CommentItem {
  author: string;
  avatar: string;
  content: React.ReactNode;
  datetime: string;
}

interface EditorProps {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: () => void;
  submitting: boolean;
  value: string;
}

const CommentList = ({ comments }: { comments: CommentItem[] }) => (
  <List
    dataSource={comments}
    header={`${comments.length} ${comments.length > 1 ? "réponses" : "réponse"}`}
    itemLayout="horizontal"
    renderItem={(props) => <Comment {...props} />}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }: EditorProps) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button
        htmlType="submit"
        loading={submitting}
        onClick={onSubmit}
        type="primary"
      >
        Ajouter un commentaire
      </Button>
    </Form.Item>
  </>
);

const AddComment: React.FC<{ postId: string }> = ({ postId }) => {
  const [addComments, { data, isLoading, isError, isSuccess }] =
    useAddCommentsMutation();
  const { user } = useSelector((state) => state.auth);
  const [comments, setComments] = useState<CommentItem[]>([]);

  const [value, setValue] = useState("");

  const handleSubmit = () => {
    if (!value) return;

    const finalData = {
      postId,
      userId: user?._id,
      comment: value,
    };

    addComments(finalData);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (isSuccess) {
      message.success("Commentaire ajouté");
      setValue("");
      setComments([
        ...comments
      ]);
    }
  }, [data, isError, isSuccess]);

  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        console.log("Socket connected!");
      });

      socket.on("newPostComment", (resData: any) => {
        setComments([
          ...comments,
          {
            author: resData?.comment?.userId.firstname,
            avatar: user?.profile || AvatarTeacher,
            content: <p>{resData.comment.comment}</p>,
            datetime: moment(resData?.updatedAt).fromNow(true),
          },
        ]);
      });
    }

    return () => {
      socket?.off("newPost");
    };
  }, [comments]);

  return (
    <>
      {comments.length > 0 && <CommentList comments={comments} />}
      <Comment
        style={{ marginLeft: "1.2em" }}
        avatar={
          <Avatar src={user?.profile || AvatarTeacher} alt={user?.firstname} />
        }
        content={
          <Editor
            onChange={handleChange}
            onSubmit={handleSubmit}
            submitting={isLoading}
            value={value}
          />
        }
      />
    </>
  );
};

export default AddComment;
