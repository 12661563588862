import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface InstaProfState {
  openModalSession: boolean;
  openModalBadResp: boolean;
  openModalTeacherAccepted: boolean;
  openModalAlertInstaProf: boolean;
  openDrawerAlertInstaProf: boolean;
  activeModeInstaProf: boolean;
}

const initialState: InstaProfState = {
  openModalSession: false,
  openModalBadResp: false,
  openModalTeacherAccepted: false,
  openModalAlertInstaProf: false,
  openDrawerAlertInstaProf: false,
  activeModeInstaProf: false,
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    ACTIVE_MODAL_INSTAPROF_SESSION: (state) => {
      return {
        ...state,
        openModalSession: true,
        openModalBadResp: false,
        openModalTeacherAccepted: false,
        openModalAlertInstaProf: false,
        openDrawerAlertInstaProf: false,
      };
    },
    ACTIVE_MODAL_INSTAPROF_BAD_RESP: (state) => {
      return {
        ...state,
        openModalSession: false,
        openModalBadResp: true,
        openModalTeacherAccepted: false,
        openModalAlertInstaProf: false,
        openDrawerAlertInstaProf: false,
      };
    },
    ACTIVE_MODAL_ALERT_INSTAPROF: (state) => {
      return {
        ...state,
        openModalSession: false,
        openModalBadResp: false,
        openModalTeacherAccepted: false,
        openModalAlertInstaProf: true,
        openDrawerAlertInstaProf: false,
      };
    },
    ACTIVE_DRAWER_TEACHER_ACCEPTED: (state) => {
      return {
        ...state,
        openModalSession: false,
        openModalBadResp: false,
        openModalTeacherAccepted: true,
        openModalAlertInstaProf: false,
        openDrawerAlertInstaProf: false,
      };
    },

    ACTIVE_DRAWER_ALERT_INSTAPROF: (state) => {
      return {
        ...state,
        openModalSession: false,
        openModalBadResp: false,
        openModalTeacherAccepted: false,
        openModalAlertInstaProf: false,
        openDrawerAlertInstaProf: true,
      };
    },
    ACTIVE_MODE_INSTAPROF: (state, action) => {
      state.activeModeInstaProf = action.payload;
    },
    INIT_MODAL: (state) => {
      return {
        ...state,
        openModalSession: false,
        openModalBadResp: false,
        openModalTeacherAccepted: false,
        openModalAlertInstaProf: false,
        openDrawerAlertInstaProf: false,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  ACTIVE_MODAL_INSTAPROF_SESSION,
  INIT_MODAL,
  ACTIVE_MODE_INSTAPROF,
  ACTIVE_DRAWER_ALERT_INSTAPROF,
  ACTIVE_MODAL_ALERT_INSTAPROF,
  ACTIVE_MODAL_INSTAPROF_BAD_RESP,
  ACTIVE_DRAWER_TEACHER_ACCEPTED,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
