import {
    Form,
    message,
    Skeleton
} from "antd";
import { FC, useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//!STYLES
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import { StatusCode } from "common/enums";
import AppButton from "components/libs/button";
import * as appRoutes from "config/routes.config";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateUser } from "store/auth/authSlice";
import TimeZoneList from "../components/TimeZoneList";
import st from "./assets/register.module.css";

import { API_RESSOURCES } from "api/services/teacher/teacher.routes";
import axios from "axios";
import { displayDrawer } from "store/adventissements";
import TeacherPicture from "../../../assets/images/teacher/teacher.svg";

import CheckIcon from "../../../assets/images/app/checkAlert.svg";


type Props = {
    stepSevenData: any;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
};

const StepEight: FC<Props> = ({ stepSevenData, setCurrent }) => {
    //!HOOKS
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [formInfos] = Form.useForm();

    //!STATES
    const [isChecking, setIsChecking] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCalendar, setIsCalendar] = useState<boolean>(false);
    const [profilePreview, setProfilePreview] = useState('');
    const { isCalendarUpdated } = useSelector((state) => state.app);

    //!CONSTANTS
    const validateMessages = {
        required: t("auth.error.required"),
        types: {
            email: t("auth.error.emailInvalid"),
        },
    };

    //!STATES JOFREY
    const [stepTwoData, setStepTwoData] = useState<any>({});

    //!FUNCTIONS JOFREY
    const endRegistation = async (values: any) => {
        setIsLoading(true);
        const finalData = {
            fuseauHorraire: values.fuseauHorraire,
            step: "completed",
        };

        try {
            const response = await AUTH_SERVICES.updateTeacher(user?._id, {
                ...finalData,
            });
            if (response?.statusCode === StatusCode.OK) {
                message.success({
                    content: "Modification effectuée ",
                });
                dispatch(
                    updateUser({ ...user, fuseauHorraire: values.fuseauHorraire, step: "completed" })
                );

            }
            else {
                message.warning({
                    content: "une erreur est survenue",
                });
            }
        } catch (error: any) {
            let content = "";
            for (
                let index = 0;
                index < error?.response?.data?.message?.length;
                index++
            ) {
                content = error?.response?.data?.message[index] + ", " + content;
            }
            message.error({
                content: content,
            });
        } finally {
            setIsLoading(false);
            navigate(appRoutes.TEACHER_ACTIVITIES);
        }
    };

    const passUpdateProfile = async () => {
        setIsLoading(true);
        const finalData = {
            step: "completed",
        };
        try {
            const response = await AUTH_SERVICES.updateTeacher(user?._id, {
                ...finalData,
            });
            if (response?.statusCode === StatusCode.OK) {
                message.success({
                    content: "Modification effectuée ",
                });
                dispatch(
                    updateUser({
                        ...user,
                        step: "completed",
                    })
                );

            }
            else {
                message.warning({
                    content: "une erreur est survenue",
                });
            }
        } catch (error: any) {
            let content = "";
            for (
                let index = 0;
                index < error?.response?.data?.message?.length;
                index++
            ) {
                content = error?.response?.data?.message[index] + ", " + content;
            }
            message.error({
                content: content,
            });
        } finally {
            setIsLoading(false);
            navigate(appRoutes.TEACHER_ACTIVITIES);
        }
    }

    const fetchCalender = async (idTeacher: string) => {
        try {
            setIsChecking(true);
            const response = await axios.get(`${API_RESSOURCES}/advertisements/fetch/ReelCalender/prof/instance/${idTeacher}`);
            if (response.status === StatusCode.OK) {
                if (response?.data?.message === "calender found") {
                    setIsCalendar(true);
                }
            }
        } catch (error) {
            console.log(error);
        }
        finally {
            setIsChecking(false);
        }
    };

    //!EFFECTS
    useEffect(() => {
        if (user) {
            if (user?.fuseauHorraire !== '') {
                formInfos.setFieldsValue({
                    fuseauHorraire: user?.fuseauHorraire,
                });
            }
        }
    }, [user, formInfos]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [teacherData, setTeacherData] = useState<any>();

    const getTeacherData = async (teacherId: string) => {
        try {
            const response = await AUTH_SERVICES.getTeacherData(user?._id, teacherId);
            setTeacherData(response?.data);
        } catch (error: any) { }
    };

    useEffect(() => {
        getTeacherData(user?._id);
    }, []);

    useEffect(() => {
        if (user) {
            setProfilePreview(user?.profile);
        }
    }, [user]);

    useEffect(() => {
        fetchCalender(user?._id);
    }, [user]);

    useEffect(() => {
        fetchCalender(user?._id);
    }, [isCalendarUpdated]);

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", height: "100%", textAlign: "center" }}>
            <div className={st.avatarBox}>
                {profilePreview !== "" ? <img src={profilePreview} alt="Uploaded Preview" height={140} style={{ borderRadius: "50%" }}></img> : <img src={TeacherPicture} height={140}></img>}
            </div>
            <p className={st.pageTitle}>Programme et disponibilités</p>
            <Form
                validateTrigger={["onFinish"]}
                name="registerTeacher"
                autoComplete="off"
                form={formInfos}
                validateMessages={validateMessages}
                onFinish={endRegistation}
            >
                <div className={st.HoursBox}>
                    <div className={st.titleDescriptionBox}>
                        Choisissez votre fuseau horaire
                        <span>Il est essentiel d’être sur le bon fuseau horaire pour coordonner<br />les leçons avec les élèves à l’international</span>
                    </div>
                    <div className={st.calendarTimezone}>
                        <TimeZoneList />
                    </div>
                </div>

                <div className={st.HoursBox}>
                    {isChecking ? <Skeleton></Skeleton> : <div className={st.titleDescriptionBox}>
                        <div className={st.calendarAvailability}>Mes disponibilités {isCalendar && <img src={CheckIcon} height={15} style={{ marginLeft: "5px" }}></img>}</div>
                        <span>Créer votre calendrier de disponibilité pour donner des cours</span>
                        <span>Vous pouvez remplir plus tard</span>
                    </div>}
                    {isChecking ? <Skeleton></Skeleton> : <AppButton className={st.newMoveButton} onClick={() => dispatch(displayDrawer(true))}>{isCalendar ? "Gérer mes disponibilités" : "Ajouter mes disponibilités"}</AppButton>}
                </div>

                <div className={st.rowButtons}>
                    <div className={st.rowButtonChild}>
                        <AppButton onClick={() => setCurrent((n) => n - 1)} className={st.newMoveButton}>Retour</AppButton>
                    </div>
                    <div className={st.rowButtonChild}>
                        <Form.Item style={{ margin: 0, padding: 0 }}>
                            <AppButton loading={isLoading} htmlTypeSubmit={true} className={st.newActionButton}>Terminer</AppButton>
                        </Form.Item>
                    </div>
                    <div className={st.rowButtonChild}>
                        <AppButton loading={isLoading} onClick={passUpdateProfile} className={st.newMoveButton}>Remplir plus tard</AppButton>
                    </div>
                </div>

            </Form>
        </div>
    );
};

export default StepEight;
