import { Table } from 'antd';
import React from 'react';

type Props = {
    students: any;
}

const StudentsTable: React.FC<Props> = ({ students }) => {

    const filteredStudents = students.filter((student: any) => !student.id);

    // Define columns for the table
    const columns = [
        {
            title: 'Noms',
            dataIndex: 'lastname',
            key: 'lastname',
        },
        {
            title: 'Prénom',
            dataIndex: 'firstname',
            key: 'firstname',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Statut',
            dataIndex: 'id',
            key: 'status',
            render: (status: string) => {
                return status ? status : 'Pas encore inscrit ';
            }
        }
    ];

    return (
        <Table dataSource={filteredStudents} columns={columns} />
    );
};

export default StudentsTable;
