import React, { useState } from 'react';
import Latex from 'react-latex-next';
import s from "./style.module.css";

interface ReadMoreProps {
    text: string;
    maxLength: number;
}

const ReadMore: React.FC<ReadMoreProps> = ({ text, maxLength }) => {
    const [isFullTextVisible, setIsFullTextVisible] = useState(false);

    const toggleFullText = () => {
        setIsFullTextVisible(!isFullTextVisible);
    };

    // Ensure that text is defined before using slice
    const truncatedText = text ? text.slice(0, maxLength) : '';
    const displayText = isFullTextVisible ? text : truncatedText;

    return (
        <div>
            <Latex>
                {displayText}
            </Latex>
            {text && text.length > maxLength && (
                <a onClick={toggleFullText} className={s.readMore}>
                    {isFullTextVisible ? 'Lire moins' : 'Lire plus'}
                </a>
            )}
        </div>
    );
};

export default ReadMore;
