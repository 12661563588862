import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// !SERVICES
import * as USERS_SERVICES from "api/services/auth/auth.services";

// !STYLES
import "./custom.tab.module.css";
import s from "./custom.tab.module.css";

// !ANTD
import { Avatar, Button, Form, message, Popconfirm, Select, Tabs } from "antd";
import AppButton from "components/libs/button";

// !ENUMS
import { converterMoneyCommission, StatusCode } from "common/enums";

// !STORE
import { setBookedReservations } from "store/auth/authSlice";

// !FORMATERS
import { dateFormatter } from "components/libs/readyToUse/challengerClasse/utils/Formatters";
import {
  MESSAGE_CONFIRMATION
} from "./constants/StylizedCustomTab";

import TextArea from "antd/lib/input/TextArea";
import Matiers from "components/matiers";
import { t } from "i18next";
import ClockIcon from "../../../assets/images/app/clock_icon.svg";
import EllipseWalletOne from "../../../assets/images/app/ellipse_wallet_1.svg";
import EllipseWalletTwo from "../../../assets/images/app/ellipse_wallet_2.svg";
import EllipseWalletThree from "../../../assets/images/app/ellipse_wallet_3.svg";
import EllipseWalletFour from "../../../assets/images/app/ellipse_wallet_4.svg";
import EmptyIcon from "../../../assets/images/app/empty_icon.svg";
import WalletHistory from "../../../assets/images/app/wallet_history_icon.svg";
import WalletPaiement from "../../../assets/images/app/wallet_paiement_icon.svg";
import Bank from "../accordion/assets/bank-transfer.svg";
import WesternUnion from "../accordion/assets/western-union.svg";

const { TabPane } = Tabs;

// COLUMNS
const columns = [
  {
    title: "Cours",
    dataIndex: "title",
  },
  {
    title: "Élève",
    dataIndex: "studentId",
    render: (e: any) => (
      <div>
        {e?.firstname} {e?.lastname}
      </div>
    ),
  },
  {
    title: "Date",
    dataIndex: "createdAt",
    render: (e: string) => dateFormatter(new Date(e), "/"),
  },
  {
    title: "Tarifs",
    dataIndex: "price",
    render: (e: string) => (
      <div style={{ color: "#80B1B2", fontWeight: "600" }}>{e}€</div>
    ),
  },
];

interface CustomTabInterface {
  alreadyAsking4Money?: boolean;
}

export const CustomTab: React.FC<CustomTabInterface> = ({
  alreadyAsking4Money,
}) => {
  // !HOOKS
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [rib, setRIB] = useState<string>(auth.user?.rib || "");

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  const [first, setfirst] = useState(alreadyAsking4Money);
  const [westernUnion, setWesternUnion] = useState<string>(
    auth.user?.ribExt || ""
  );
  const [score, setScore] = useState(
    converterMoneyCommission(auth.user?.coins)
  );
  const { bookedReservations } = useSelector((state) => state.auth);
  const [lodaing, setLoading] = useState(false); // !FUNCTIONS
  const [lodaingRetrait, setLoadingRetrait] = useState(false); // !FUNCTIONS

  const [formInfos] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<any>("1");
  const [selectedValue, setSelectedValue] = useState<any>("Virement bancaire");
  const validateMessages = {
    required: t("auth.error.required"),
    types: {
      email: t("auth.error.emailInvalid"),
    },
  };

  const type = [
    {
      "name": "Virement bancaire",
      "icon": Bank,
    },
    {
      "name": "Western union",
      "icon": WesternUnion,
    }
  ];

  const handleSelectChange = (value: string, option: any) => {
    setSelectedValue(value);
  };

  useEffect(() => {
    setfirst(alreadyAsking4Money);
  }, [alreadyAsking4Money]);

  const updateRIBHandler = (i: string) => setRIB(i);
  const updateWesternUnionHandler = (i: string) => setWesternUnion(i);

  const EnvoyerDemandeRetrait = useCallback(
    async (rib: string | undefined, westernUnion: string | undefined) => {
      try {
        setLoading(true);
        if (auth.user?._id) {
          const teacherId = auth.user?._id;
          await USERS_SERVICES.updateTeacher(teacherId, {
            rib: rib,
            ribExt: westernUnion,
          });
          setLoading(false);
          message.success({
            content: "Mise à jour avec succes ",
          });
        }
      } catch (error: any) {
        setLoading(false);
        console.log(error);
      }
    },
    [auth.user?._id]
  );

  const EnvoyerDemandeRetrait2 = useCallback(async (id: string) => {
    try {
      setLoadingRetrait(true);
      if (id) {
        const response = await USERS_SERVICES.createRetrait({
          teacherId: id,
        });
        setLoadingRetrait(false);
        if (response?.statusCode === StatusCode.CREATED) {
          message.success({
            content: "envoi avec succes",
          });
        }
      }
    } catch (error: any) {
      setLoadingRetrait(false);
      console.log(error);
    }
  }, []);

  function confirm() {
    if (rib !== "" && westernUnion === "")
      EnvoyerDemandeRetrait(rib, "");
    else if (rib === "" && westernUnion !== "")
      EnvoyerDemandeRetrait("", westernUnion);
    else {
      message.error({
        content: "Veuillez saisir votre RIB ou votre carte d’identité",
        style: {
          position: "absolute",
          right: "0",
        },
      });
    }
  }

  function confirm2() {
    setfirst(false);
    EnvoyerDemandeRetrait2(auth?.user?._id || "");
    /* if (rib === "" && westernUnion === "")
      message.error({
        content: "Veuillez saisir vos informations",
        style: {
          position: "absolute",
          right: "0",
        },
      }); */
    /* else {
      if (rib === "" && westernUnion !== "")
        message.error({
          content: "Veuillez saisir votre RIB",
          style: {
            position: "absolute",
            right: "0",
          },
        });
      else if (rib !== "" && westernUnion === "")
        message.error({
          content: "Veuillez saisir votre carte d’identité",
          style: {
            position: "absolute",
            right: "0",
          },
        });
      else {
        setfirst(false);
        EnvoyerDemandeRetrait2(auth?.user?._id || "");
      }
    } */
  }

  const getBookedReservations = useCallback(async () => {
    try {
      if (auth.user?._id && auth.user?.roles.includes("teacher")) {
        const teacherId = auth.user?._id;
        const response = await USERS_SERVICES.getBookedReservations(teacherId);
        if (response?.statusCode === StatusCode.OK) {
          dispatch(setBookedReservations(response?.data));
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  }, [auth.user?._id, auth.user?.roles, dispatch]);

  const updatePaiementDetails = async (values: any) => {
    console.log(values.type);
    console.log(values.infos);
    if (values === "Virement bancaire") {
      setRIB(values.infos);
    }
    else {
      setWesternUnion(values.infos);
    }
    confirm();
  };

  useEffect(() => {
    getBookedReservations();
  }, [getBookedReservations]);

  return (
    <div className={s.cardContainer}>
      <img src={EllipseWalletOne} height={50} style={{ position: "relative", top: "40px", left: "-10px", zIndex: "0" }}></img>
      <div style={{ display: "flex", flexDirection: "column", height: "auto", width: "100% !important", alignItems: "center", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "row", height: "100px", width: "100%", alignItems: "center", justifyContent: "space-between" }}>
          <div className={s.soldeBox}>
            <div className={s.soldeBoxTitle}>Ton solde actuel</div>
            <div className={s.amountBox}>
              <span style={{ color: "#4BB980", fontSize: "12px" }}>Solde du compte</span>
              <span style={{ color: "#4BB980", fontSize: "16px" }}>{score} (€)</span>
            </div>
          </div>
          {score !== null && score !== undefined && score > 0 && first ? (
            <Popconfirm
              placement="topLeft"
              title={MESSAGE_CONFIRMATION}
              onConfirm={confirm2}
              okText="Oui"
              cancelText="Non"
            >
              <Button className={s.newButton} loading={lodaingRetrait}>Demander un retrait</Button>
            </Popconfirm>
          ) : (
            <Button className={s.demandeRetraitButton}>
              Retrait en cours de traitement ...
            </Button>
          )}
        </div>
        <div className={s.detailsBox}>
          <p className={s.detailsBoxTitle}>Gérer votre portefeuille</p>
          <p className={s.detailsBoxMessage}>Votre portefeuille en ligne vous permet de suivre et de gérer<br />vos paiements en toute simplicité.</p>
          <Tabs
            tabBarStyle={{ borderRadius: "0px", overflow: "hidden", border: "0px solid transparent !important", backgroundColor: "transparent !important" }}
            activeKey={currentTab}
            onChange={(key) => setCurrentTab(key)}
            type="line"
            className="custom-tabs"
          >
            <TabPane
              tab={
                <div className={currentTab == "1" ? s.tabTitleBoxActive : s.tabTitleBox}>
                  <img src={WalletPaiement} height={18} style={{ marginRight: "10px" }}></img> Information du paiement
                </div>
              }
              key="1"
            >
              <div className={s.informationBox}>
                <Form
                  validateTrigger={["onFinish"]}
                  validateMessages={validateMessages}
                  initialValues={{ acceptedMessagesAndNotifications: true, confirmAge: true }}
                  name="paiement"
                  autoComplete="off"
                  form={formInfos}
                  onFinish={updatePaiementDetails}
                >
                  <Form.Item
                    style={{ marginBottom: "20px" }}
                    name="type"
                    rules={[{ required: true }]}
                    initialValue={type[0].name}
                  >
                    <Select
                      showSearch
                      style={{ padding: "5px 0px 5px 0px", fontSize: "16px" }}
                      className={s.selectInput}
                      placeholder={<div className={s.placeholderStyle}>Type de paiement</div>}
                      optionFilterProp="children"
                      bordered={false}
                      allowClear
                      dropdownStyle={{ padding: "3px" }}
                      showArrow={true}
                      onChange={(value, option) => handleSelectChange(value, option)}
                      value={selectedValue}
                    >
                      {type.map((item: any, i: any) => (<Select.Option key={i} value={item.name}><img src={item.icon}></img> {item.name}</Select.Option>))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="infos"
                    rules={[{ required: false }]}
                    style={{ marginBottom: "20px" }}
                  >
                    <TextArea
                      className={s.texareaField}
                      placeholder={selectedValue === "Virement bancaire" ? "Veuillez entrer votre RIB" : "Veuillez saisir votre carte d’identité …"}
                      rows={2}
                    />
                  </Form.Item>
                  <div style={{ position: "relative", top: "15px", left: "35%" }}>
                    <Form.Item>
                      <AppButton loading={isLoading} htmlTypeSubmit={true}>Enregistrer mes infos bancaires</AppButton>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </TabPane>
            <TabPane
              tab={
                <div className={currentTab == "2" ? s.tabTitleBoxActive : s.tabTitleBox}>
                  <img src={WalletHistory} height={18} style={{ marginRight: "10px" }}></img> Historique
                </div>
              }
              key="2"
            >
              {bookedReservations.length > 0 ? <div className={s.historyBox}>
                {bookedReservations?.slice(0, 8)?.map((booker: any) => (
                  <div className={s.bookingBox}>
                    <div className={s.avatarNameBox}>
                      <Avatar
                        size={30}
                        className={s.studentAvatar}
                        src={booker?.studId?.profile}
                        style={{ background: "#FFF2E0", boxShadow: "0px 3px 6px #00000029" }}
                      />
                      <div className={s.studentName}>{booker?.studId?.firstname + " " + booker?.studId?.lastname}</div>
                    </div>

                    <div className={s.dateBox}>
                      <img src={ClockIcon} height={15} style={{ marginRight: "7px" }}></img>
                      {new Intl.DateTimeFormat("fr-FR", {
                        weekday: "long",
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      }).format(new Date(booker?.day))}
                    </div>
                    <Matiers
                      cours={booker?.topic?.title.slice(0, 4) || "null"}
                      level={booker?.level?.title || "null"}
                    />
                    <div className={s.priceBox}>{booker?.price} €</div>
                  </div>
                ))}
              </div> : <div className={s.emptyClass}>
                Aucun historique
                <img src={EmptyIcon} height={80} style={{ marginTop: "20px" }}></img>
              </div>}
            </TabPane>
          </Tabs>
        </div>
      </div>
      <img src={EllipseWalletTwo} height={70} style={{ position: "relative", top: "-55%", left: "95%", zIndex: "0" }}></img>
      <img src={EllipseWalletThree} height={70} style={{ position: "relative", top: "-30%", left: "-10%", zIndex: "0" }}></img>
      <img src={EllipseWalletFour} height={50} style={{ position: "relative", top: "-30%", left: "83%", zIndex: "0" }}></img>
    </div>
  );
};
