import React, { FC } from "react";
import { useSelector } from "react-redux";

//!STYLES
import s from "./assets/stylo.module.css";

const Stylo: FC = () => {
  const { user } = useSelector((state) => state.auth);
  const progress = user?.points / 100;

  return (
    <div className={s.stylo}>
      <div className={s.__content}>
        <div className={s.__subContent}>
          <div className={s.____width}>
            <div
              className={s.__progrosss}
              style={{ height: `${progress}%` }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stylo;
