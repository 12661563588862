import axios from "axios";
import { ApiResponse } from "common/interfaces";
import { API_BASE_URL } from "./paiement.api.routes";

export const getConversatedAmount = async (
  amount: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/mock-coins/${amount}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getStripeUrl = async (
  payload: any
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/coins/`, payload);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const updateCoins = async (
  sessionId: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.put(`${API_BASE_URL}/${sessionId}/complete`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
