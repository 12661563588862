import axios from 'axios';

// Create separate Axios instances for each API
export const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // Main API URL
});

export const resourcesClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL_RESSOURCES, // Resources API URL
});

export const userDataClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL_USER_DATA, // User Data API URL
});