import { Col, Image, Row, Tooltip } from "antd";
import { FC } from "react";

//!IMAGES
import MATH from "../../../../assets/images/teacher/iconMath.svg";

//!STYLES
import s from "./assets/matiers.module.css";

interface Matires {
  className?: string;
  cours?: string;
  level?: string;
  classNameIcon?: string;
  lang?: string;
}

const MatiersAds: FC<Matires> = ({
  cours,
  level,
  className,
  classNameIcon,
  lang,
}) => {
  return (
    <div className={`${s.matiers} ${className}`}>
      <div className={s.left}>
        <Row style={{ alignItems: "center" }}>
          <Col>
            {/* <Image
              className={classNameIcon}
              preview={false}
              src={MATH}
              width={23}

            /> */}
          </Col>
          <Col>
            <Tooltip title={cours}>
              <span className={s.__cours}>{cours?.substring(0, 4) || ""}</span>
            </Tooltip>
          </Col>
        </Row>
      </div>
      <div className={s.center}>
        <span>|</span>
      </div>
      <div className={s.right}>
        <span>{level}</span>
      </div>
      <div className={s.center}>
        <span>|</span>
      </div>
      <div className={s.right}>
        <span>{lang}</span>
      </div>
    </div>
  );
};

export default MatiersAds;
