/* eslint-disable jsx-a11y/anchor-is-valid */
import { Drawer, message } from "antd";
import { API_BASE_URL_RETRAITS } from "api/services/paiement/paiement.api.routes";
import axios from "axios";
import { StatusCode } from "common/enums";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closePorteFeuilleDrawer__ } from "store/auth/authSlice";
import ArrowBack from "../../assets/images/app/arrow_back.svg";
import { CustomTab } from "./customTab/CustomTab";

export const InformationsPaiement: React.FC = () => {

  const dispatch = useDispatch();
  const { porteFeuilleDrawerOpen } = useSelector((state) => state.auth);
  const visibleHandler = () => dispatch(closePorteFeuilleDrawer__());
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [alreadyAsking4Money, setAlreadyAsking4Money] = useState(false);

  useEffect(() => {
    const retrieveFunction = async () => {
      try {
        let response = await axios.get(API_BASE_URL_RETRAITS);
        if (response.status === StatusCode.OK) {
          if (response?.data?.data.length === 0) setAlreadyAsking4Money(true);
        }
      } catch (error) {
        message.error("Une erreur inconnue s'est produite.");
      }
    };
    if (isLoggedIn) {
      retrieveFunction();
    }
  }, [isLoggedIn, porteFeuilleDrawerOpen]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [isXsScreen, setIsXsScreen] = useState(window.innerWidth > 767 && window.innerWidth <= 1151);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth > 1151 && window.innerWidth <= 1321);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 1321 && window.innerWidth <= 1606);
  const [isNormalScreen, setIsNormalScreen] = useState(window.innerWidth > 1606 && window.innerWidth <= 1799);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
    setIsXsScreen(window.innerWidth > 767 && window.innerWidth <= 1151);
    setIsSmallScreen(window.innerWidth > 1151 && window.innerWidth <= 1321);
    setIsMediumScreen(window.innerWidth > 1321 && window.innerWidth <= 1606);
    setIsNormalScreen(window.innerWidth > 1606 && window.innerWidth <= 1799);
  };
  window.addEventListener('resize', handleResize);

  return (
    <Drawer
      className="drawer"
      placement="right"
      width={isMobile ? "100%" : isXsScreen ? "100%" : isSmallScreen ? "75%" : isMediumScreen ? "70%" : isNormalScreen ? "65%" : "60%"}
      onClose={visibleHandler}
      visible={porteFeuilleDrawerOpen}
      closable={true}
      title={<div style={{ fontSize: "20px", fontFamily: "Poppins", fontWeight: "600", color: "#393E3E", backgroundColor: "transparent" }}>Portefeuille</div>}
      headerStyle={{ border: "none", paddingTop: "50px", paddingLeft: "40px", backgroundColor: "transparent" }}
      closeIcon={
        <img
          src={ArrowBack}
          height={18}
          style={{}}
        ></img>
      }
      drawerStyle={{ backgroundColor: "#FFF", borderTopLeftRadius: "30px", borderBottomLeftRadius: "30px", boxShadow: "-3px 3px 34px #39393929" }}
    >
      <div
        style={{
          display: "flex",
          padding: "0 50px",
          width: "100%",
        }}
      >
        <CustomTab alreadyAsking4Money={alreadyAsking4Money} />
      </div>
    </Drawer>
  );
};
