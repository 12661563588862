import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiResponse } from "common/interfaces";

import * as ROOM_SERVICES from "api/services/chat/room-chat/room.services";

export interface RoomState {
  loading: boolean;
  items: ApiResponse;
  errors: string[];
  isChatInstaProf: boolean;
}

const initialState: RoomState = {
  loading: false,
  errors: [],
  items: { statusCode: 400, data: [], message: "", status: "" },
  isChatInstaProf: false,
};

export const getRoomByIdProfTypeRelationIdEtudiant: any = createAsyncThunk(
  "talk/findRoom",
  async (
    yourData: {
      actor1: string;
      /*sender: string;*/ actor2: string;
      flag: string;
      type: string;
      instaprof: string;
    },
    thunkAPI
  ) => {
    const { actor1, /*sender,*/ actor2, flag, type, instaprof } = yourData;

    try {
      const response = await ROOM_SERVICES.findOne(
        actor1,
        /* sender,*/ actor2,
        flag,
        type,
        instaprof
      );
      if (response) return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const sendMessageAPI: any = createAsyncThunk(
  "talk/sendMessage",
  async (
    yourData: {
      talkId: string;
      sender: string;
      receiver: string;
      message: string;
      flag: string;
      instaprof: boolean;
    },
    thunkAPI
  ) => {
    const { talkId, sender, receiver, message, flag, instaprof } = yourData;
    console.log(yourData, "yourData");
    try {
      const response = await ROOM_SERVICES.sendMessage(
        talkId,
        sender,
        receiver,
        message,
        flag,
        instaprof
      );
      if (response) return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const roomSlice = createSlice({
  name: "room",
  initialState,
  reducers: {
    clearRoomError: (state: any) => {
      state = { ...state, initialState };
    },
    clearRoom: () => initialState,
    setIsDrawerInstaProf: (state, action) => {
      state.isChatInstaProf = action.payload;
    },
  },
  extraReducers: {
    [getRoomByIdProfTypeRelationIdEtudiant.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [getRoomByIdProfTypeRelationIdEtudiant.fulfilled]: (state, action) => {
      state.loading = false;
      state.items = action.payload;
    },
    [getRoomByIdProfTypeRelationIdEtudiant.rejected]: (state, action) => {
      state.loading = false;
      state.errors.push(
        "Error while fetching room",
        action.payload.response.data.message
      );
    },
    [sendMessageAPI.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [sendMessageAPI.fulfilled]: (state, action) => {
      state.loading = false;
      state.items = action.payload;
    },
    [sendMessageAPI.rejected]: (state, action) => {
      state.loading = false;
      state.errors.push(
        "Error while sending message",
        action.payload.response.data.message
      );
    },
  },
});
export const { clearRoomError, clearRoom, setIsDrawerInstaProf } =
  roomSlice.actions;
const { reducer } = roomSlice;
export default reducer;
