import { Avatar, Button, message, Popconfirm, Popover, Spin } from "antd";
import React, { FC, Fragment, useCallback, useEffect, useRef, useState } from "react";
import TEACHER from "../../../assets/images/teacher/teacher.svg";

//
import { calculateTimeDifference } from "common/enums";
import { badRequest } from "messages";
import { useDispatch, useSelector } from "react-redux";
import { displayDrawerAdsSpecific, setCurrentCreneau } from "store/adventissements";
import { setIsCalendarUpdated } from "store/app/appSlice";
import { useDeleteTeacherAdSpecificMutation } from "store/services/teacher/ads.services";
import s from "./style.module.css";

import ClockIcon from "../../../assets/images/app/clock_icon.svg";
import Delete from "../../../assets/images/app/delete_article.svg";
import Edit from "../../../assets/images/app/edit_article.svg";
import CancelIcon from "../../../assets/images/app/i_icon.svg";
import MoreOptions from "../../../assets/images/app/options_icon.svg";

type Props = {
  cren: any;
  currentTeacher: any;
  confirmReservations: any;
  index: number;
  isAllReservation: boolean;
  setConfirmRes: React.Dispatch<React.SetStateAction<any[]>>;
};

const CardCreneau: FC<Props> = ({
  cren,
  index,
  currentTeacher,
  isAllReservation = false,
  confirmReservations,
  setConfirmRes,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const dateRef = useRef<(HTMLDivElement | null)[]>([]);
  const refs = useRef<(HTMLDivElement | null)[]>([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleOptions, setVisibleOptions] = useState(false);

  const setRef = (index: number, element: HTMLDivElement) => {
    refs.current[index] = element;
  };

  const [deleteTeacherAdSpecific, { isLoading, isError, isSuccess }] = useDeleteTeacherAdSpecificMutation();

  const handleClick = useCallback((creneauData: any) => {
    setLoading(true);
    const finalData = {
      teacherId: user?._id,
      niches: {
        grouplevels: [creneauData?.grouplevels],
        topic: [creneauData?.topic?._id],
        level: [creneauData?.level?._id],
        date: creneauData.day,
        timeRanges: [
          {
            range: [
              {
                hour: creneauData?.timeRanges[0],
              },
              {
                hour: creneauData?.timeRanges[1],
              },
            ],
            type: creneauData?.creneauTypes,
          },
        ],
      },
    };
    deleteTeacherAdSpecific(finalData);
  },
    [user]
  );

  const showPopconfirm = () => {
    setVisible(true);
    setVisibleOptions(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (isSuccess) {
      message.success("Créneau supprimé avec succès");
      setTimeout(() => {
        dispatch(setIsCalendarUpdated(true));
        setLoading(false);
        setVisible(false);
      }, 5000);
    }
    if (isError) {
      message.error(badRequest.error);
    }
  }, [isSuccess, isLoading, isError]);

  const handleChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: any
  ) => {
    let prev = confirmReservations;
    let itemIndex = prev.indexOf(item);
    if (itemIndex !== -1) {
      prev.splice(itemIndex, 1);
    } else {
      prev.push(item);
    }
    setConfirmRes([...prev]);
  };

  const handleResetCreneau = () => {
    dispatch(displayDrawerAdsSpecific(true));
    dispatch(setCurrentCreneau(cren));
    setVisibleOptions(false);
  }

  const content = (
    <div>
      <div onClick={handleResetCreneau} style={{ fontSize: "15px", color: "#393E3E" }}><img src={Edit} height={15} ></img> Replanifier</div>
      <div style={{ height: "1px", width: "90%", margin: "0px auto", background: "#BFBCBC", marginTop: "5px", marginBottom: "5px" }}></div>
      <div onClick={showPopconfirm} style={{ fontSize: "15px", color: "#F56666" }}><img src={Delete} height={15} ></img> Supprimer</div>
    </div>
  );

  const renderElement = (cren: any) => {
    return (
      <Popover
        visible={visibleOptions}
        placement="left"
        content={content}
        trigger="click"
        style={{ height: "70px !important", borderRadius: "8px", boxShadow: "4px 8px 16px #8B8B8B38", border: "0.5px solid #BFBCBC" }}
        className={s.customPopover}
        overlayClassName={s.customPopover}
      >
        <div className={s.optionButton} onClick={() => setVisibleOptions(!visibleOptions)}><img src={MoreOptions} height={12} style={{ marginRight: "5px" }}></img> Options</div>
      </Popover>
    );
  }

  return (
    <div className={`${cren?.status === "deleted" ? s.classeCardDeleted : s.classeCard}`}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", paddingLeft: "20px", paddingRight: "20px" }}>
        <div className={s.avatarNameBox}>
          <Avatar.Group
            maxCount={3}
            maxStyle={{
              color: "#f56a00",
              backgroundColor: "#fde3cf",
            }}
            style={{ marginRight: "5px" }}
          >
            <Avatar
              size={45}
              style={{ backgroundColor: "#FFF2E0", boxShadow: "0px 3px 6px #00000029" }}
              src={currentTeacher?.teacher?.profile || TEACHER}
            />
          </Avatar.Group>
          <div style={{ position: "relative", left: "15px", fontSize: "13px", textTransform: "capitalize" }}>{"Créneau disponible"}</div>
        </div>

        <div className={s.detailsBox}>
          <div className={s.timeBox}>
            <img src={ClockIcon} height={15} style={{ marginRight: "10px" }}></img>
            <div className={s.times}>
              <span className={s.time}>De {cren?.timeRanges[0]} à {cren?.timeRanges[1]}</span>
              <span className={s.timeDescription}>Séance de “{calculateTimeDifference(cren?.timeRanges[0], cren?.timeRanges[1])}”</span>
            </div>
          </div>
          <div className={s.levelsBox}>{cren?.grouplevels !== "" ? cren?.grouplevels?.replace(/\$/g, ' - ') : "Tous les niveaux"}</div>
        </div>

        <Popconfirm
          placement="top"
          title={
            "Veux-tu vraiment supprimer cet horaire ?"
          }
          onConfirm={() => handleClick(cren)}
          visible={visible}
          okText={loading ? <Spin size="small" /> : "Oui"}
          cancelText="Non"
          onCancel={handleCancel}
          okButtonProps={{ disabled: loading }}
        >
          <Button style={{ visibility: "hidden" }}></Button>
        </Popconfirm>

        {cren?.status === "deleted" && <div className={s.cancelBox}><div className={s.horizontalLineDeleted}></div><span><img src={CancelIcon} height={18} style={{ marginRight: "5px", marginLeft: "20px" }}></img>Cours annulé</span></div>}

        <div>
          <Fragment>
            {renderElement(cren)}
          </Fragment>
        </div>
      </div>
    </div>
  );
};

export default CardCreneau;
