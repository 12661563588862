import FadeInOnScroll from "components/FadeIn";
import FadeInUpOnScroll from "components/FadeInUp";
import { useState } from "react";
import arrow_choose_us from "../../../../../assets/images/app/home/arrow_choose_us.svg";
import ellipse_history_title from "../../../../../assets/images/app/home/ellipse_history_title.svg";
import s from "./style.module.css";

const WhyChooseUs = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.mainContainer}>
            <div className={s.whyUsBox}>
                <FadeInUpOnScroll>
                    <h1 className={s.title}>Pourquoi Nous Choisir ?</h1>
                </FadeInUpOnScroll>
                <img src={ellipse_history_title} className={s.ellipseTitle}></img>
                {!isMobile && <img src={arrow_choose_us} style={{ position: "relative", right: "-35%", width: "53px", bottom: "45px" }}></img>}
                <div className={s.mainBox}>
                    <div className={s.subBox}>
                        <FadeInOnScroll>
                            <div className={s.circleOne}>
                                <div className={s.circleOneNumber}>1</div>
                            </div>
                        </FadeInOnScroll>
                        <div className={s.circleTitle}>
                            <FadeInOnScroll>Innovation continue</FadeInOnScroll>
                        </div>
                        <FadeInOnScroll>
                            <div style={{ marginTop: "20px" }}>
                                <div className={s.circleMessage}>Nous investissons dans la recherche<br />et le développement pour constamment<br />améliorer notre plateforme.</div>
                            </div>
                        </FadeInOnScroll>
                    </div>
                    <div className={s.subBox}>
                        <FadeInOnScroll>
                            <div className={s.circleTwo}>
                                <div className={s.circleTwoNumber}>2</div>
                            </div>
                        </FadeInOnScroll>
                        <div className={s.circleTitle}>
                            <FadeInOnScroll>Accessibilité</FadeInOnScroll>
                        </div>
                        <FadeInOnScroll>
                            <div style={{ marginTop: "20px" }}>
                                <div className={s.circleMessage}>Nos cours et services sont conçus pour être<br />accessibles à tous, dépassant les barrières<br />géographiques et économiques.</div>
                            </div>
                        </FadeInOnScroll>
                    </div>
                    <div className={s.subBox}>
                        <FadeInOnScroll>
                            <div className={s.circleThree}>
                                <div className={s.circleThreeNumber}>3</div>
                            </div>
                        </FadeInOnScroll>
                        <div className={s.circleTitle}>
                            <FadeInOnScroll>Communauté</FadeInOnScroll>
                        </div>
                        <FadeInOnScroll>
                            <div style={{ marginTop: "20px" }}>
                                <div className={s.circleMessage}>Nous investissons dans la recherche<br />et le développement pour constamment<br />améliorer notre plateforme.</div>
                            </div>
                        </FadeInOnScroll>
                    </div>
                </div>
                {!isMobile && <a className={s.quote}>“</a>}
            </div>
        </div>
    );
};

export default WhyChooseUs;