import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  loginUserDto,
  registerUserDto
} from "api/services/auth/dto/login-user.dto";
import { AuthMethods, UserRoles } from "common/enums";
import { User } from "common/interfaces";
import * as AUTH_SERVICES from "../../api/services/auth/auth.services";

interface AuthInitialState {
  isLoggedIn: boolean;
  user: User | any;
  isRegistering: boolean;
  isAuthenticating: boolean;
  isLogging: boolean;
  bookedReservations: any;
  loginErrors: string[];
  isLoggingOut: boolean;
  registerErrors: string[];
  updateErrors: string[];
  authMethod: string;
  profileDrawerOpen: boolean;
  porteFeuilleDrawerOpen: boolean;
  setShowDrawerPaiementOneMoreTime: boolean;
  isUpdating: boolean;
  toogleDrawer: boolean;
  setToogleWizardModal: boolean;
  idTeacher: string;
  seeAvis: boolean;
  currentStudentInstaProf: any;
  userConnect: boolean;
  lastRouteActionAuth: string;
  openModalWizard: boolean;
  profileDetailsDrawerOpen: boolean;
  profileDetailsID: string;
  profileDetailsType: string;
}

enum FIREBASE_ERRORS {
  WRONG_PASSWORD = "auth/wrong-password",
  TOO_MANY_REQUESTS = "auth/too-many-requests",
  EMAIL_ALREADY_USED = "auth/email-already-in-use",
  POPUP_CLOSED_BY_USER = "auth/popup-closed-by-user",
  USER_NOT_FOUND = "auth/user-not-found",
}

const getFirebaseError = (code: string): string => {
  switch (code) {
    case FIREBASE_ERRORS.WRONG_PASSWORD:
      return "Email ou mot de passe incorrect";
    case FIREBASE_ERRORS.TOO_MANY_REQUESTS:
      return "Ce compte a été tomp suspendu à cause du nombre répété de requete sans succes";
    case FIREBASE_ERRORS.EMAIL_ALREADY_USED:
      return "Cette adresse email est déjà utilisée";
    case FIREBASE_ERRORS.POPUP_CLOSED_BY_USER:
      return "Le popup a été fermé par l'utilisateur";
    case FIREBASE_ERRORS.USER_NOT_FOUND:
      return "Cet utilisateur n'existe pas";
    default:
      return "Une erreur est survenue, merci de réessayer :)";
  }
};

const initialState: AuthInitialState = {
  isLoggedIn: false,
  user: null,
  isRegistering: false,
  isUpdating: false,
  isLogging: false,
  loginErrors: [],
  isAuthenticating: true,
  isLoggingOut: false,
  registerErrors: [],
  updateErrors: [],
  authMethod: "",
  profileDrawerOpen: false,
  porteFeuilleDrawerOpen: false,
  toogleDrawer: false,
  setToogleWizardModal: false,
  bookedReservations: [],
  setShowDrawerPaiementOneMoreTime: false,
  idTeacher: "",
  seeAvis: false,
  currentStudentInstaProf: null,
  userConnect: false,
  lastRouteActionAuth: "",
  openModalWizard: false,
  profileDetailsDrawerOpen: false,
  profileDetailsID: "",
  profileDetailsType: "",
};

export const registerStudentWithEmailAndPassword: any = createAsyncThunk(
  "auth/register/emailPassword",
  async (user: any, thunkAPI) => {
    try {
      const response =
        await AUTH_SERVICES.registerInFirebaseWithEmailAndPassword(user);
      if (response) {
        const finalUser = await AUTH_SERVICES.registerStudent({
          uid: response.uid,
          firstname: user.firstname,
          lastname: user.lastname,
          email: user.email,
          pseudo: user.pseudo,
          levelId: user.levelId,
          profile: user.profile,
          avatar: user.avatar,
          roles: [UserRoles.STUDENT],
          pays: user.pays,
          phone: user.phone,
          education: user.education,
          etablissement: user.etablissement,
          educSystem: user.educSystem,
          password: user?.password,
        });
        // return finalUser;
        if (finalUser?.data) return thunkAPI.fulfillWithValue(finalUser.data);
        return thunkAPI.rejectWithValue(new Error("No data"));
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const logout: any = createAsyncThunk(
  "auth/logout",
  async (_, thunkAPI) => {
    try {
      const hasLoggedOut = await AUTH_SERVICES.logout();
      return hasLoggedOut;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(false);
    }
  }
);

export const registerStudentWithGoogle: any = createAsyncThunk(
  "auth/register/google",
  async (_, thunkAPI) => {
    try {
      const response = await AUTH_SERVICES.registerInFirebaseWithGoogle();
      if (response) {
        const finalUser = await AUTH_SERVICES.registerStudent({
          uid: response.uid,
        });
        if (finalUser?.data) return thunkAPI.fulfillWithValue(finalUser.data);
        return thunkAPI.rejectWithValue(
          new Error("l'utilisateur n'existe pas")
        );
      }
    } catch (error) {
      throw thunkAPI.rejectWithValue(error);
    }
  }
);

export const registerTeacherWithEmailAndPassword: any = createAsyncThunk(
  "auth/register/emailPassword",
  async (user: registerUserDto, thunkAPI) => {
    try {
      const response =
        await AUTH_SERVICES.registerInFirebaseWithEmailAndPassword(user);
      if (response) {
        await AUTH_SERVICES.registerTeacher({
          uid: response.uid,
          ...user,
          // firstname: user.firstname,
          // lastname: user.lastname,
          // email: user.email,
          // profile: `https://gravatar.com/avatar/${response?.uid}?d=identicon`,
        });

        const finalUserNewLogic = await AUTH_SERVICES.getUserByUid(
          response.uid
        );

        if (finalUserNewLogic?.data)
          return thunkAPI.fulfillWithValue(finalUserNewLogic?.data);

        return thunkAPI.rejectWithValue(new Error("No data"));
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const registerParentOrganisationWithEmailPassword: any = createAsyncThunk(
  "auth/register/emailPassword",
  async (values: registerUserDto, thunkAPI) => {
    try {
      const response = await AUTH_SERVICES.registerInFirebaseWithEmailAndPassword(values);
      console.log('User UID : ' + response?.uid);
      if (response) {
        const objectToSend = { uid: response.uid, ...values, };
        console.log(objectToSend);
        await AUTH_SERVICES.registerParentOrganisation(
          {
            uid: response.uid,
            ...values,
          }
        );

        const finalUserNewLogic = await AUTH_SERVICES.getUserByUid(
          response.uid
        );

        if (finalUserNewLogic?.data)
          return thunkAPI.fulfillWithValue(finalUserNewLogic?.data);

        return thunkAPI.rejectWithValue(new Error("No data"));
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateTeacher: any = createAsyncThunk(
  "auth/update/teacher",
  async (data: any, thunkAPI) => {
    try {
      const response = await AUTH_SERVICES.updateTeacher(data?.id, data?.user);

      if (response) {
        return thunkAPI.fulfillWithValue(response.data);
      }
    } catch (error) {
      console.log("error", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateStudent: any = createAsyncThunk(
  "auth/update/student",
  async (data: any, thunkAPI) => {
    try {
      const response = await AUTH_SERVICES.updateStudent(data?.id, data?.user);

      if (response) {
        return thunkAPI.fulfillWithValue(response.data);
      }
    } catch (error) {
      console.log("error", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const loginWithEmailAndPassword: any = createAsyncThunk(
  "auth/login/emailPassword",
  async (user: loginUserDto, thunkAPI) => {
    try {
      const response = await AUTH_SERVICES.logInFirebaseWithEmailAndPassword(
        user
      );
      if (response) {
        const finalUser = await AUTH_SERVICES.getUserByUid(response.uid);
        if (finalUser?.data) return thunkAPI.fulfillWithValue(finalUser.data);
        return thunkAPI.rejectWithValue(null);
      }
      return thunkAPI.rejectWithValue(null);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getUserInstance: any = createAsyncThunk(
  "auth/user/get",
  async (data: any, thunkAPI) => {
    try {
      const finalUser = await AUTH_SERVICES.getUserByUid(data.uid);
      if (finalUser?.data) return thunkAPI.fulfillWithValue(finalUser.data);
      return thunkAPI.rejectWithValue(null);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loginWithGoogle: any = createAsyncThunk(
  "auth/login/google",
  async (_, thunkAPI) => {
    try {
      const response = await AUTH_SERVICES.registerInFirebaseWithGoogle();

      if (response) {
        const finalUser = await AUTH_SERVICES.getUserByUid(response.uid);
        if (finalUser?.data) return thunkAPI.fulfillWithValue(finalUser.data);
      }
      return thunkAPI.rejectWithValue(new Error("l'utilisateur n'existe pas"));
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const registerTeacherWithGoogle: any = createAsyncThunk(
  "auth/register/google",
  async (_, thunkAPI) => {
    try {
      const response = await AUTH_SERVICES.registerInFirebaseWithGoogle();

      if (response) {
        const finalUser = await AUTH_SERVICES.registerTeacher(
          !response?.phoneNumber
            ? {
              uid: response.uid,
              firstname: response?.displayName,
              email: response.email,
              emailVerified: response.emailVerified,
              profile: response?.photoURL,
            }
            : {
              uid: response.uid,
              firstname: response?.displayName,
              email: response.email,
              phone: response.phoneNumber,
              emailVerified: response.emailVerified,
              profile: response?.photoURL,
            }
        );
        if (finalUser?.data) return thunkAPI.fulfillWithValue(finalUser.data);
      }
      throw new Error("l'utilisateur n'existe pas");
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUser: (state: any, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    isAuthenticating: (state: any, action: PayloadAction<boolean>) => {
      state.isAuthenticating = action.payload;
    },
    isLoggedIn: (state: any, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    openProfileDrawer: (state: any) => {
      state.profileDrawerOpen = true;
    },
    closeProfileDrawer: (state: any) => {
      state.profileDrawerOpen = false;
    },
    openPorteFeuilleDrawer__: (state: any) => {
      state.porteFeuilleDrawerOpen = true;
    },
    closePorteFeuilleDrawer__: (state: any) => {
      state.porteFeuilleDrawerOpen = false;
    },
    toogleDrawerChat: (state: any, action: any) => {
      state.toogle = action.payload;
    },
    setToogleWizardModal: (state: any, action: any) => {
      state.toogle = action.payload;
    },
    setBookedReservations: (state: any, action: PayloadAction<any>) => {
      state.bookedReservations = action.payload;
    },
    setShowDrawerPaiementOneMoreTimeHandler: (state: any) => {
      state.setShowDrawerPaiementOneMoreTime = true;
    },
    setShowDrawerPaiementOneMoreTimeHandlerToFalse: (state: any) => {
      state.setShowDrawerPaiementOneMoreTime = false;
    },

    setIdTeacher: (state: any, action: any) => {
      state.idTeacher = action.payload;
    },
    setSeeAvis: (state: any, action: any) => {
      state.seeAvis = action.payload;
    },

    setCurrentStudentInstaProf: (state: any, action: any) => {
      state.currentStudentInstaProf = action.payload;
    },
    setUserConnect: (state: any, action: any) => {
      state.userConnect = action.payload;
    },
    setLastRouteActionAuth: (state: any, action: any) => {
      state.lastRouteActionAuth = action.payload;
    },

    openProfileDetailsDrawer: (state: any) => {
      state.profileDetailsDrawerOpen = true;
    },
    closeProfileDetailsDrawer: (state: any) => {
      state.profileDetailsDrawerOpen = false;
    },
    setProfileDetailsID: (state: any, action: any) => {
      state.profileDetailsID = action.payload;
    },
    setProfileDetailsType: (state: any, action: any) => {
      state.profileDetailsType = action.payload;
    },
  },
  extraReducers: {
    [updateTeacher.pending]: (state, action) => {
      state.isUpdating = true;
      state.updateErrors = [];
    },
    [updateTeacher.fulfilled]: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
      };
      state.isUpdating = false;
    },
    [updateTeacher.rejected]: (state, action) => {
      if (action?.payload?.isAxiosError) {
        state.updateErrors = [
          action?.payload?.response?.data?.message || ["Erreur serveur"],
        ].flat();
      }

      state.isUpdating = false;
    },
    //---------- STUDENT ACTIONS ----------//
    [getUserInstance.pending]: (state, action) => {
      state.isUpdating = true;
      state.updateErrors = [];
    },
    [getUserInstance.fulfilled]: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
      };
      state.isUpdating = false;
    },
    [getUserInstance.rejected]: (state, action) => {
      if (action?.payload?.isAxiosError) {
        state.updateErrors = [
          action?.payload?.response?.data?.message || ["Erreur serveur"],
        ].flat();
      }
      state.isUpdating = false;
    },

    [updateStudent.pending]: (state, action) => {
      state.isUpdating = true;
      state.updateErrors = [];
    },
    [updateStudent.fulfilled]: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
      };
      state.isUpdating = false;
    },
    [updateStudent.rejected]: (state, action) => {
      if (action?.payload?.isAxiosError) {
        state.updateErrors = [
          action?.payload?.response?.data?.message || ["Erreur serveur"],
        ].flat();
      }
      state.isUpdating = false;
    },
    [registerStudentWithEmailAndPassword.pending]: (state, action) => {
      state.isRegistering = true;
    },
    [registerStudentWithEmailAndPassword.fulfilled]: (state, action) => {
      state.isRegistering = false;
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    [registerStudentWithEmailAndPassword.rejected]: (state, action) => {
      state.isRegistering = false;
      state.isLoggedIn = false;
    },
    [registerStudentWithGoogle.pending]: (state, action) => {
      state.isRegistering = true;
    },
    [registerStudentWithGoogle.fulfilled]: (state, action) => {
      state.isRegistering = false;
      state.user = action.payload.data;
      state.isLoggedIn = true;
    },
    [registerStudentWithGoogle.rejected]: (state, action) => {
      state.isRegistering = false;
      state.isLoggedIn = false;
    },
    //---------- TEACHER ACTIONS ----------//
    [registerTeacherWithEmailAndPassword.pending]: (state, action) => {
      state.authMethod = AuthMethods.EMAIL;
      state.isRegistering = true;
      state.registerErrors = [];
    },
    [registerTeacherWithEmailAndPassword.fulfilled]: (state, action) => {
      state.authMethod = "";
      state.isRegistering = false;
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    [registerTeacherWithEmailAndPassword.rejected]: (state, action) => {
      state.authMethod = "";
      state.isRegistering = false;
      state.isLoggedIn = false;
      if (action?.payload?.isAxiosError) {
        state.registerErrors = [
          action?.payload?.response?.data?.message || ["Erreur serveur"],
        ].flat();
      }
      if (!action?.payload?.isAxiosError && action?.payload?.code) {
        state.registerErrors = [getFirebaseError(action?.payload?.code)];
      }
    },
    [registerTeacherWithGoogle.pending]: (state, action) => {
      state.authMethod = AuthMethods.GOOGLE;
      state.isRegistering = true;
    },
    [registerTeacherWithGoogle.fulfilled]: (state, action) => {
      state.isRegistering = false;
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    [registerTeacherWithGoogle.rejected]: (state, action) => {
      state.isRegistering = false;
      state.isLoggedIn = false;
      if (action?.payload?.isAxiosError) {
        state.registerErrors = [
          action?.payload?.response?.data?.message || ["Erreur serveur"],
        ].flat();
      }
      if (!action?.payload?.isAxiosError && action?.payload?.code) {
        state.registerErrors = [getFirebaseError(action?.payload?.code)];
      }
    },
    //!LOGIN
    [loginWithEmailAndPassword.pending]: (state, action) => {
      state.authMethod = AuthMethods.EMAIL;
      state.isLogging = true;
      state.loginErrors = [];
    },
    [loginWithEmailAndPassword.fulfilled]: (state, action) => {
      state.isLogging = false;
      state.user = action.payload;
      state.isLoggedIn = true;
      state.loginErrors = [];
    },
    [loginWithEmailAndPassword.rejected]: (state, action) => {
      state.isLogging = false;
      state.isLoggedIn = false;
      if (action?.payload?.isAxiosError) {
        state.loginErrors = [action?.payload?.response?.data?.message].flat();
      }
      if (!action?.payload?.isAxiosError && action?.payload?.code) {
        state.loginErrors = [getFirebaseError(action?.payload?.code)];
      }
      if (action?.payload === null) {
        state.loginErrors = [getFirebaseError(FIREBASE_ERRORS.USER_NOT_FOUND)];
      }
    },

    [loginWithGoogle.pending]: (state, action) => {
      state.authMethod = AuthMethods.GOOGLE;
      state.isLogging = true;
      state.loginErrors = [];
    },
    [loginWithGoogle.fulfilled]: (state, action) => {
      state.isLogging = false;
      state.user = action.payload;
      state.isLoggedIn = true;
      state.loginErrors = [];
    },
    [loginWithGoogle.rejected]: (state, action) => {
      state.isLogging = false;
      state.isLoggedIn = false;
      const errors = [];
      if (action?.payload?.isAxiosError) {
        state.loginErrors = [
          action?.payload?.response?.data?.message || ["Erreur serveur"],
        ].flat();
        errors.push(
          action?.payload?.response?.data?.message || ["Erreur serveur"]
        );
      }
      if (!action?.payload?.isAxiosError && action?.payload?.code) {
        errors.push(getFirebaseError(action?.payload?.code));
        state.loginErrors = [getFirebaseError(action?.payload?.code)];
      }

      if (action?.payload?.message) {
        errors.push(action.payload.message);
      }
      state.loginErrors = errors.flat();
    },

    //!LOGOUT
    [logout.pending]: (state, action) => {
      state.isLoggingOut = true;
    },
    [logout.fulfilled]: (state, action) => {
      const hasLoggedOut = action.payload;
      if (hasLoggedOut) {
        state.isLoggedIn = !hasLoggedOut;
        state.user = null;
        state.isLoggingOut = false;
      }
    },
    [logout.rejected]: (state, action) => {
      state.isRegistering = false;
      state.isLoggedIn = false;
    },
  },
});

export const {
  updateUser,
  isAuthenticating,
  isLoggedIn,
  openProfileDrawer,
  closeProfileDrawer,
  openPorteFeuilleDrawer__,
  closePorteFeuilleDrawer__,
  setBookedReservations,
  toogleDrawerChat,
  setToogleWizardModal,
  setShowDrawerPaiementOneMoreTimeHandler,
  setShowDrawerPaiementOneMoreTimeHandlerToFalse,
  setIdTeacher,
  setSeeAvis,
  setCurrentStudentInstaProf,
  setUserConnect,
  setLastRouteActionAuth,
  openProfileDetailsDrawer,
  closeProfileDetailsDrawer,
  setProfileDetailsID,
  setProfileDetailsType,
} = authSlice.actions;

const { reducer } = authSlice;
export default reducer;
