/* import { CopyOutlined } from '@ant-design/icons'; */
/* import { Button, message } from 'antd'; */
import React from "react";
import {
  EmailIcon,
  EmailShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";
import style from "./style.module.css";
interface monInterface {
  prefix: string;
  codeAcces: string;
}
const index: React.FC<monInterface> = (props) => {

  /* const handleCopy = () => {
    const textToCopy = props.codeAcces;
    navigator.clipboard.writeText(textToCopy).then(() => {
      message.success('Code copié');
    }).catch(() => {
      message.error('Erreur lors de la copie!');
    });
  }; */

  return (
    <div className={style.flexGap}>
      <div>
        {" "}
        <WhatsappShareButton title={props.prefix} url={props.codeAcces}>
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
      </div>
      <div>
        {" "}
        <TwitterShareButton title={props.prefix} url={props.codeAcces}>
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
      </div>{" "}
      <div>
        {" "}
        <EmailShareButton title={props.prefix} url={props.codeAcces}>
          <EmailIcon size={32} round={true} />
        </EmailShareButton>
      </div>
      {/* <div>
        {" "}
        <Button
          type="primary"
          icon={<CopyOutlined />}
          onClick={handleCopy}
          style={{ borderRadius: "50%" }}
        >
        </Button>
      </div> */}
    </div>
  );
};

export default index;
