import { createSlice } from "@reduxjs/toolkit";

export interface MainState {
  chatDrawerOpen: boolean;
  chatDrawerOpenStudent: boolean;
  paiementDrawerOpen: boolean;
}

const initialState: MainState = {
  chatDrawerOpen: false,
  chatDrawerOpenStudent: false,
  paiementDrawerOpen: false,
};
export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    openChatDrawer: (state: any) => {
      state.chatDrawerOpen = true;
    },
    closeChatDrawer: (state: any) => {
      state.chatDrawerOpen = false;
    },

    openChatDrawerStudent: (state: any) => {
      state.chatDrawerOpenStudent = true;
    },
    closeChatDrawerStudent: (state: any) => {
      state.chatDrawerOpenStudent = false;
    },
    openPaiementDrawer: (state: any) => {
      state.paiementDrawerOpen = true;
    },

    closePaiementDrawer: (state: any) => {
      state.paiementDrawerOpen = false;
    },
  },
  extraReducers: {},
});
export const {
  openChatDrawer,
  closeChatDrawer,
  openChatDrawerStudent,
  closeChatDrawerStudent,
  openPaiementDrawer,
  closePaiementDrawer,
} = mainSlice.actions;

const { reducer } = mainSlice;
export default reducer;
