import Grid from "components/libs/grid";
import style from "../assets/style.module.css"
import CardLoader from "./CardLoader";
export const CardMenu: React.FC = () => {

    return (
      <div className={`main ${style.mainX}`} >
      <Grid className={style.grid}>
        <CardLoader></CardLoader>       
        <CardLoader></CardLoader>  
        <CardLoader></CardLoader>       
        <CardLoader></CardLoader>
      </Grid>
    </div>
    )
  }
  
  export default CardMenu
  