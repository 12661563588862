import { Avatar, Col, Image, Row } from "antd";
import SkillBar from "components/skillbar";
import React, { FC, useCallback, useEffect, useState } from "react";

//!STYLES
import s from "./assets/cardChapter.module.css";

interface Chapater {
  chapter?: any;
  progress?: any;
}

const CardChapter: FC<Chapater> = ({ chapter, progress }) => {

  return (
    <div className={s.cardChapter}>
      {/* <div className={s.title__chapter}>
        <span>{chapter?.course?.title}</span>
      </div> */}
      <div className="title__chapter">
        <span>{chapter?.title}</span>
      </div>

      <SkillBar width={progress[chapter?._id] || 0} />

      <Row justify="space-between" style={{ opacity: "0" }}>
        <Col></Col>
        <Col>
          <Avatar
            style={{
              backgroundColor: "#fff",
              position: "relative",
              top: "-1px",
            }}
            size={20}
            src={
              // Teacher
              <Image
                fallback={`http://gravatar.com/avatar${chapter?.teacher?.uid}?d=identicon`}
                preview={false}
                src={
                  !!chapter?.teacher?.profile
                    ? chapter?.teacher.profile
                    : `https://gravatar.com/avatar/${chapter?.teacher?._id}?d=identicon`
                }
              />
            }
          />
          by
          {chapter?.teacher?.firstname}
        </Col>
      </Row>
    </div>
  );
};

export default CardChapter;
