import { Image } from "antd";
import * as appRoutes from "config/routes.config";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { pushLevel } from "store/studentQuizz/studentQuizz.slice";

//!COMPONENTS
import Grid from "components/libs/grid";
import AppScreen from "screens/teacher/ScreenWithSidebar";

//!IMAGES
import Easy from "./assets/images/easy.svg";
import Hard from "./assets/images/hard.svg";
import Medium from "./assets/images/medium.svg";

//!STYLES
import { PlayLevel } from "common/enums";
import Breadcrumbs from "components/breadcrumbs";
import useChapters from "hooks/useChapters";
import { useNavigate, useParams } from "react-router-dom";
import HeaderComponents from "../components/headerComponents";
import TabsStudent from "../components/tabs";
import s from "./assets/level.module.css";

const Level = () => {
  //!HOOKS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { level } = useSelector((state) => state.StuedentQuzz);
  const params = useParams<"id">();
  const { loading, data } = useChapters(params.id!);

  //!FUNCTION
  const showLevelHeasy = () => {
    dispatch(pushLevel("easy"));
    navigate(
      `${appRoutes.STUDENT_SEARCH_QUIZZ}/${params.id}/${PlayLevel.EASY}`
    );
  };
  const showLevelMedium = () => {
    dispatch(pushLevel("medium"));
    navigate(
      `${appRoutes.STUDENT_SEARCH_QUIZZ}/${params.id}/${PlayLevel.MEDIUM}`
    );
  };

  const showLevelHard = () => {
    dispatch(pushLevel("hard"));
    navigate(
      `${appRoutes.STUDENT_SEARCH_QUIZZ}/${params.id}/${PlayLevel.HARD}`
    );
  };

  return (
    <React.Fragment>
      <Breadcrumbs />

      <div className={s.header}>
        <HeaderComponents />
        <TabsStudent
          textBack="RETOUR AUX CHAPITRES"
          onRequest={() => { }}
          onExit={() => { }} />
      </div>
      <AppScreen className={s.level}>
        <div className={s.level__content}>
          <div className={s.title__content}>
            {/* <h2>{data[0]?.title}</h2> */}
            <Grid className={s.row}>
              <div className={s.card} onClick={showLevelHeasy}>
                <Image
                  preview={false}
                  width={130}
                  src={Easy}
                  alt="teacher avatar"
                />
                <h3>
                  <span>GO</span>
                  <span>easy</span>
                </h3>
              </div>

              <div className={s.card} onClick={showLevelMedium}>
                <Image
                  preview={false}
                  width={130}
                  src={Medium}
                  alt="teacher avatar"
                />
                <h3>
                  <span>GO</span>
                  <span>medium</span>
                </h3>
              </div>

              <div className={s.card} onClick={showLevelHard}>
                <Image
                  preview={false}
                  width={130}
                  src={Hard}
                  alt="teacher avatar"
                />
                <h3>
                  <span>GO</span>
                  <span>hard</span>
                </h3>
              </div>
            </Grid>
          </div>
        </div>
      </AppScreen>
    </React.Fragment>
  );
};

export default Level;
