import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Topic } from "common/types";
import * as TOPICS_SERVICES from "../../api/services/topics/topics.services";

export interface TopicsState {
  loading: boolean;
  items: Topic[];
  errors: string[];
  levelId: string;
}

const initialState: TopicsState = {
  loading: false,
  errors: [],
  items: [],
  levelId: "",
};

export const findAllTopics: any = createAsyncThunk(
  "topics/findall",
  async (levelId: string, thunkAPI) => {
    try {
      const response = await TOPICS_SERVICES.findAll(levelId);
      if (response?.data) return thunkAPI.fulfillWithValue(response.data);
      return thunkAPI.rejectWithValue("No data found");
    } catch (error) {
      throw thunkAPI.rejectWithValue(error);
    }
  }
);

export const topicsSlice = createSlice({
  name: "topics",
  initialState,
  reducers: {},
  extraReducers: {
    [findAllTopics.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [findAllTopics.fulfilled]: (state, action) => {
      state.loading = false;
      state.items = action.payload;
    },
    [findAllTopics.rejected]: (state) => {
      state.loading = false;
      state.errors.push("Error while fetching topics");
    },
  },
});

const { reducer } = topicsSlice;
export default reducer;
