import { findOne } from "api/services/courses/courses.services";
import { useState, useEffect } from "react";

type Course = {
  _id?: string;
  title?: string;
  description?: string;
  createdAt?: Date;
  updatedAt?: Date;
  contents?: any;
  course?: any;
  teacherId?: string;
};

const useCourse = (id: string) => {
  //!STATE
  const [course, setCourse] = useState<Course>({});
  const [loading, setLoading] = useState<Boolean>(true);
  const [error, setError] = useState<string>("");

  const fetchCourse = async (passedId: string) => {
    try {
      const response = await findOne(passedId);
      setCourse(response?.data);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCourse(id);
  }, [id]);

  return { course, loading, error };
};

export default useCourse;
