import { configureStore } from "@reduxjs/toolkit";
import { logger } from "redux-logger";
import { LevelsApi } from "./services/app/levels.services";
import { appUserApi } from "./services/app/user.services";
import { chatApi } from "./services/chat";
import { studentAdsApi } from "./services/student/ads.services";
import { studentDashboardApi } from "./services/student/dashboard.services";
import { studentQuizzApi } from "./services/student/quizz.services";
import { adsApi } from "./services/teacher/ads.services";
import { classesApi } from "./services/teacher/classes.services";
import { contributionsApi } from "./services/teacher/contributions.services";
import { CountContentApi } from "./services/teacher/count.services";
import { coursesApi } from "./services/teacher/courses";
import { notifApi } from "./services/teacher/notification.services";
import { ProfileTeacherApi } from "./services/teacher/profileTeacher";

import appChat from "./AppChat/appChat";
import drawerAds from "./adventissements";
import app from "./app/appSlice";
import auth from "./auth/authSlice";
import conversation from "./chat/conversationSlice";
import main from "./chat/mainSlice";
import room from "./chat/roomSlice";
import sendMessage from "./chat/sendMessageSlice";
import users from "./chat/usersSlice";
import courses from "./courses/coursesSlice";
import exercises from "./exercises/exercisesSlice";
import levels from "./levels/levelsSlice";
import notifications from "./notification";
import paiement from "./paiement/paiementSlice";
import quizz from "./quizz/quizzSlice";
import StuedentQuzz from "./studentQuizz/studentQuizz.slice";
import topics from "./topics/topicsSlice";

import rooms from "./chat/roomsSlice";

import { activitiesApi } from "./services/teacher/activities.services";
import { roomsApi } from "./teacher/rooms/teacher.rooms";

const reducer = {
  auth,
  app,
  topics,
  levels,
  courses,
  quizz,
  exercises,
  users,
  paiement,
  room,
  drawerAds,
  main,
  conversation,
  sendMessage,
  rooms,
  StuedentQuzz,
  appChat,
  notifications,
  [notifApi.reducerPath]: notifApi.reducer,
  [classesApi.reducerPath]: classesApi.reducer,
  [adsApi.reducerPath]: adsApi.reducer,
  [studentAdsApi.reducerPath]: studentAdsApi.reducer,
  [CountContentApi.reducerPath]: CountContentApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  [ProfileTeacherApi.reducerPath]: ProfileTeacherApi.reducer,
  [LevelsApi.reducerPath]: LevelsApi.reducer,
  [appUserApi.reducerPath]: appUserApi.reducer,
  [coursesApi.reducerPath]: coursesApi.reducer,
  [activitiesApi.reducerPath]: activitiesApi.reducer,
  [studentQuizzApi.reducerPath]: studentQuizzApi.reducer,
  [contributionsApi.reducerPath]: contributionsApi.reducer,
  [studentDashboardApi.reducerPath]: studentDashboardApi.reducer,
};

export const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(logger)
      .concat(roomsApi.middleware)
      .concat(notifApi.middleware)
      .concat(classesApi.middleware)
      .concat(adsApi.middleware)
      .concat(studentAdsApi.middleware)
      .concat(CountContentApi.middleware)
      .concat(chatApi.middleware)
      .concat(ProfileTeacherApi.middleware)
      .concat(LevelsApi.middleware)
      .concat(coursesApi.middleware)
      .concat(appUserApi.middleware)
      .concat(studentQuizzApi.middleware)
      .concat(contributionsApi.middleware)
      .concat(activitiesApi.middleware)
      .concat(studentDashboardApi.middleware),
  devTools: process.env.NODE_ENV !== "production",
});

//!GENERAL DISPATCHES

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

declare global {
  type RootState = ReturnType<typeof store.getState>;
}

// Thanks to that you will have ability to use useSelector hook with state value
declare module "react-redux" {
  interface DefaultRootState extends RootState {}
}
