import { getAuth } from "firebase/auth";

export const getToken = async (): Promise<string | undefined> => {
  try {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken(true);
    localStorage.setItem("tokenFirebase", token as string);
    return token?.toString();
  } catch (error) {
    console.error(
      "Une erreur est survenue lors de la récupération du token d'authentification",
      error
    );
    return undefined;
  }
};
