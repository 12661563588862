import React, { FC } from "react";
import { Result } from "antd";
import Container from "components/container";
import AppButton from "components/libs/button";
import Stylo from "components/stylo";
import Breadcrumbs from "components/breadcrumbs";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";

//!ASSETS
import s from "./assets/style.module.css";

const Forbidden: FC = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Breadcrumbs />
      <Stylo />
      <div className={s.home}>
        <Container>
          <Result
            status="403"
            title="401"
            subTitle="Action non autorisée"
            extra={
              <Link to="/">
                <AppButton icon={<ArrowLeftOutlined />}>Retour</AppButton>
              </Link>
            }
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Forbidden;
