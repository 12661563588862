import Card from "components/card";
import AppButton from "components/libs/button";
import { FC } from "react";

//!STYLES
import { Avatar, Tooltip } from "antd";
import Matiers from "components/matiers";
import s from "./assets/cardHistory.module.css";

//IMAGES
import CertificationBadge from "components/certificationBadge";
import moment from "moment";
import { ReactComponent as Camera } from "../../../../../assets/images/app/camera.svg";
import TEACHER from "../../../../../assets/images/teacher/teacher.svg";

type Props = {
  item: any;
  teacher?: any;
  className?: string;
};
const CardHistoryStudent: FC<Props> = ({ item, teacher, className }) => {
  return (
    <Card className={`${s.card} ${className}`}>
      <div className={s.content}>
        <div className={s.round__user}>
          <Avatar src={item?.profId?.profile || TEACHER} size={60} />
        </div>
        <Tooltip
          placement="top"
          title={`${teacher?.firstname || item?.profId.firstname} ${teacher?.lastname || item?.profId.lastname
            }`}
        >
          <h3 style={{ textAlign: "center" }}>
            {teacher?.firstname || item?.profId.firstname}{" "}
            {teacher?.lastname || item?.profId.lastname}
            {teacher?.certified && <CertificationBadge />}
          </h3>
        </Tooltip>
        <Matiers cours={item?.topic?.title} level={item?.level?.title} />
        <div className={s.__start}>
          {/* <Image preview={false} src={START} /> */}
          <span>{item?.topic?.price || item?.price}€</span>
        </div>
        <span>
          {moment(item?.startDate).format("LL")} à{" "}
          {moment.utc(item?.startDate).format("h:mm")}
        </span>
        <a href={item?.studentLink} target="_blank">
          <AppButton
            icon={
              <Camera
                style={{
                  position: "relative",
                  width: "15px",
                  top: "3.5px",
                  left: "-5px",
                }}
              />
            }
            className={s.__button}
          >
            Lien videoconf
          </AppButton>
        </a>
      </div>
    </Card>
  );
};

export default CardHistoryStudent;
