import { FC, useRef } from "react";
import { Button } from "antd";
import { motion } from "framer-motion";

//!STYLE
import s from "./assets/button.module.css";

type Props = {
  className?: string;
  icon?: any;
  onClick?: any;
  htmlTypeSubmit?: boolean;
  loading?: any;
  style?: any;
  ref?: any;
  disabled?: boolean;
};
const AppButton: FC<Props> = ({
  children,
  className,
  icon,
  onClick,
  htmlTypeSubmit,
  loading,
  style,
  ref,
  disabled,
}) => {
  const button = {
    hidden: { rotate: 45 },
    visible: { rotate: 0 },
    hover: { scale: 1.1 },
    tap: { scale: 0.9 },
  };

  return (
    // <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }}>

    // </motion.div>
    <Button
      onClick={onClick}
      icon={icon}
      style={style}
      loading={loading}
      htmlType={htmlTypeSubmit ? "submit" : "button"}
      className={`${s.button} ${className}`}
      ref={ref}
      disabled={disabled}
    >
      {children}
    </Button>

    //   <Button
    //   onClick={onClick}
    //   icon={icon}
    //   style={style}
    //   loading={loading}
    //   htmlType={htmlTypeSubmit ? "submit" : "button"}
    //   className={`${s.button} ${className}`}
    // >
    //   {children}
    // </Button>
  );
};

export default AppButton;
