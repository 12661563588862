import { Tooltip } from 'antd';
import { FC } from 'react';
import badgeCert from '../../assets/images/app/validation.svg';

const CertificationBadge: FC = () => {

    return (
        <Tooltip
            title={
                <span style={{ textTransform: "capitalize" }}>Badge Certifié</span>
            }
        >
            <img
                height={20}
                width={20}
                src={badgeCert}
                alt="Badge"
                style={{ marginLeft: "5px" }}
            />
        </Tooltip>
    );
};

export default CertificationBadge;
