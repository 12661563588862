import FadeInUpOnScroll from "components/FadeInUp";
import { useState } from "react";
import ellipse_mission from "../../../../assets/images/app/home/ellipse_mission.svg";
import play_button from "../../../../assets/images/app/teachers/play_button.svg";
import s from "./style.module.css";

const OurPlateforme = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.mainContainer}>
            <div className={s.plateformContainer}>
                <div className={s.mainBox}>
                    <FadeInUpOnScroll>
                        {isMobile ? <h1 className={s.title}>Comprendre<br />notre plate-forme</h1> : <h1 className={s.title}>Comprendre notre plate-forme</h1>}
                    </FadeInUpOnScroll>
                    <img src={ellipse_mission} className={s.ellipseTitle}></img>
                    <div className={s.videoBox}>
                        <img src={play_button} style={{ height: isMobile ? "44px" : "155px" }}></img>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurPlateforme;