import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiResponse,} from "common/interfaces";

import * as CONVERSATION_SERVICES from "api/services/chat/conversation-chat/conversation.services";

export interface ConversationState {
  loading: boolean;
  items: ApiResponse;
  errors: string[];
}

const initialState: ConversationState = {
  loading: false,
  errors: [],
  items: {statusCode:400,data:[],message:"",status:""},
};

export const getMessages__api:any = createAsyncThunk(
  "conversation/getConversation",
  async (yourData:{roomId:string,senderId:string},thunkAPI) => {
    const {roomId,senderId} = yourData;
    try {
      const response =
        await CONVERSATION_SERVICES.getConversation(roomId,senderId);
      if (response)
      return response;
      
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {  
    clearConversationError: () => initialState,
    addConversationTemporary: (state,action) => {
      return {
        ...state,items:{...state.items,data:[...state.items.data,action.payload]}
      }
      
      }
  },
  extraReducers: {
    [getMessages__api.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [getMessages__api.fulfilled]: (state, action) => {
      state.loading = false;
      state.items = action.payload;
    },
    [getMessages__api.rejected]: (state,action) => {
      state.loading = false;
      state.errors.push("Error while fetching messages",action.payload.response.data.message);
    },
  
  },
});
export const { clearConversationError} = conversationSlice.actions;
export const { addConversationTemporary} = conversationSlice.actions;

const { reducer } = conversationSlice;
export default reducer;
