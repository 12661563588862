import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiResponse } from "common/interfaces";

import * as PAIEMENT_SERVICES from "api/services/paiement/paiement.services";

export interface paiementState {
  loading: boolean;
  items: ApiResponse;
  errors: string[];
  // stripe
  stripeInfos: ApiResponse;
  loadingStripe: boolean;
  errorsStripe: string[];
  // updateCoins
  updateCoins: ApiResponse;
  loadingUpdateCoins: boolean;
  errorsUpdateCoins: string[];
}
const initialState: paiementState = {
  loading: false,
  errors: [],
  items: { statusCode: 400, data: [], message: "", status: "" },
  // stripe
  stripeInfos: { statusCode: 400, data: [], message: "", status: "" },
  loadingStripe: false,
  errorsStripe: [],
  // updateCoins
  updateCoins: { statusCode: 400, data: [], message: "", status: "" },
  loadingUpdateCoins: false,
  errorsUpdateCoins: [],
};

export const getConvertedAmountFunction: any = createAsyncThunk(
  "talk/findConvertedAmount",
  async (
    yourData: {
      amount: string;
    },
    thunkAPI
  ) => {
    const { amount } = yourData;
    try {
      const response = await PAIEMENT_SERVICES.getConversatedAmount(amount);
      if (response) return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateCoins: any = createAsyncThunk(
  "talk/updateCoins",
  async (
    yourData: {
      sessionId: string;
    },
    thunkAPI
  ) => {
    const { sessionId } = yourData;
    try {
      const response = await PAIEMENT_SERVICES.updateCoins(sessionId);
      if (response) return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getStripeUrlFunction: any = createAsyncThunk(
  "talk/getStripeUrl",
  async (
    yourData: {
      userId: string;
      amount: number;
      success: string;
      cancel: string;
    },
    thunkAPI
  ) => {
    const { userId, amount, success, cancel } = yourData;
    try {
      const response = await PAIEMENT_SERVICES.getStripeUrl({
        userId,
        amount,
        success,
        cancel,
      });
      if (response) {
        localStorage.setItem("sessionId", response.data?.sessionId);
        return response;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const paiementSlice = createSlice({
  name: "paiement",
  initialState,
  reducers: {},
  extraReducers: {
    [getConvertedAmountFunction.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [getConvertedAmountFunction.fulfilled]: (state, action) => {
      state.loading = false;
      state.items = action.payload;
    },
    [getConvertedAmountFunction.rejected]: (state, action) => {
      state.loading = false;
      state.errors.push(
        "Error while fetching the convertedAmount",
        action.payload.response?.data?.message
      );
    },
    [getStripeUrlFunction.pending]: (state) => {
      state.errorsStripe = [];
      state.loadingStripe = true;
    },
    [getStripeUrlFunction.fulfilled]: (state, action) => {
      state.loadingStripe = false;
      state.stripeInfos = action.payload;
    },
    [getStripeUrlFunction.rejected]: (state, action) => {
      state.loadingStripe = false;
      state.errorsStripe.push(
        "Error while getting stripe url",
        action.payload.response?.data?.message
      );
    },
    [updateCoins.pending]: (state) => {
      state.errorsUpdateCoins = [];
      state.loadingUpdateCoins = true;
    },
    [updateCoins.fulfilled]: (state, action) => {
      state.loadingUpdateCoins = false;
      state.updateCoins = action.payload;
    },
    [updateCoins.rejected]: (state, action) => {
      state.loadingUpdateCoins = false;
      state.errorsUpdateCoins.push("Error while updating coins ");
    },
  },
});
const { reducer } = paiementSlice;
export default reducer;
