import { SearchOutlined } from "@ant-design/icons";
import FadeInOnScroll from "components/FadeIn";
import FadeInUpOnScroll from "components/FadeInUp";
import * as appRoutes from "config/routes.config";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import arrow_need_help from "../../../../../assets/images/app/home/arrow_need_help.svg";
import ellipse_need_help from "../../../../../assets/images/app/home/ellipse_need_help.svg";
import insta_prof from "../../../../../assets/images/app/home/insta_prof.png";
import triangle_need_help from "../../../../../assets/images/app/home/triangle_need_help.svg";
import ellipse_insta_prof from "../../../../../assets/images/app/teachers/ellipse_insta_prof.svg";
import st from "../../../common.module.css";
import s from "./style.module.css";

const NeedHelp = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(appRoutes.STUDENT_SEARCH_BEST_TEACHER);
    }

    return (
        <div className={s.mainContainer}>
            <div className={s.needHelpBox}>
                <img src={ellipse_need_help} style={{ position: "relative", right: "-20%", top: "29px", width: "25px" }}></img>
                <div className={s.mainBox}>
                    <div className={s.leftBox}>
                        <FadeInUpOnScroll>
                            <h1 className={s.title}>Besoin d’aide immédiate ?</h1>
                        </FadeInUpOnScroll>
                        <img src={ellipse_need_help} className={s.ellipseTitle}></img>
                        {isMobile ? <FadeInOnScroll>
                            <p className={s.messageOne}>Avec InstaProf, trouvez le prof qui<br />vous aidera à dépasser vos difficultés,<br />en un seul clic… et maintenant!</p>
                            <p className={s.message}>Finies les anxiétés de veille d’examen! Cette<br />fonctionnalité exclusive de Mon Ami Albert vous<br />connecte instantanément avec des enseignants qualifiés, prêts à vous accompagner en ligne,<br />quand vous en avez le plus besoin… et parfois cela signifie maintenant! Plus besoin d'attendre!</p>
                        </FadeInOnScroll> : <FadeInOnScroll>
                            <p className={s.message}>Avec InstaProf, trouvez le prof qui vous aidera à dépasser<br />vos difficultés, en un seul clic… et maintenant!</p>
                            <p className={s.message}>Finies les anxiétés de veille d’examen! Cette fonctionnalité<br />exclusive de Mon Ami Albert vous connecte instantanément avec<br />des enseignants qualifiés, prêts à vous accompagner en ligne,<br />quand vous en avez le plus besoin… et parfois cela signifie maintenant! Plus besoin d'attendre!</p>
                        </FadeInOnScroll>}
                        <img src={arrow_need_help} className={s.arrowNeedHelp}></img>
                        <button className={st.searchButton} style={{ marginTop: "0px", position: "relative", top: "-25px" }} onClick={handleClick}>
                            <SearchOutlined />  Trouvez un instaprof
                        </button>
                    </div>
                    {isMobile ? <div className={s.rightBox}>
                        <img src={insta_prof} className={s.instaProfImage}></img>
                        <img src={ellipse_insta_prof} style={{ position: "relative", right: "-27%", top: "-260px", width: "50px", zIndex: "0" }}></img>
                        <img src={triangle_need_help} style={{ position: "relative", right: "-47%", top: "-180px", width: "15px", zIndex: "2" }}></img>
                    </div> : <div className={s.rightBox}>
                        <img src={ellipse_insta_prof} style={{ position: "relative", right: "-97%", top: "-35%", width: "90px" }}></img>
                        <img src={triangle_need_help} style={{ position: "relative", right: "-110%", top: "-40%", width: "25px" }}></img>
                        <img src={insta_prof} className={s.instaProfImage}></img>
                    </div>}
                </div>
                <div className={s.callAction}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between", textAlign: "left" }}>
                        <div className={s.instaTitle}>InstaProf</div>
                        <div className={s.instaMessage}>Cours instantané</div>
                    </div>
                    <button className={st.buttonRegister} style={{ marginTop: "0px" }}>
                        <div style={{ fontFamily: "Poppins", fontWeight: "bolder", cursor: "pointer" }}>
                            Commencez le cours
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NeedHelp;