import React, { FC } from 'react';
import st from './assets/customButton.module.css'

type Props = {
    children: string,
    style: any,
    onClick: () => void,
    className: any,
    icon?: any,
    iconSize?: any,
}

const CustomButton: FC<Props> = ({ children, style, onClick, className, icon, iconSize }) => {
    return (
        <button
            className={className !== "" ? className : st.customButtonStyle}
            style={style}
            onClick={onClick}
        >
            <img src={icon} width={iconSize ? iconSize : 15} />
            {children}
        </button>
    );
};

export default CustomButton;