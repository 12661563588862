import { SearchOutlined } from "@ant-design/icons";
import * as appRoutes from "config/routes.config";
import Lottie from "lottie-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import arrowAnimation from "../../../../../assets/images/app/home/arrow_down_animation.json";
import rate_one from "../../../../../assets/images/app/home/rate_one.png";
import rate_three from "../../../../../assets/images/app/home/rate_three.png";
import rate_two from "../../../../../assets/images/app/home/rate_two.png";
import student_girl from "../../../../../assets/images/app/home/student_girl.jpg";
import student_one from "../../../../../assets/images/app/home/student_one.png";
import student_three from "../../../../../assets/images/app/home/student_three.png";
import student_two from "../../../../../assets/images/app/home/student_two.png";
import st from "../../../common.module.css";
import s from "./style.module.css";

const SlideHome = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(appRoutes.STUDENT_SEARCH_BEST_TEACHER);
    }

    return (
        <div className={s.mainContainer}>
            <div className={s.mainBox}>
                <div className={s.leftBox}>
                    <h1 className={s.title}>Élever chaque apprenant</h1>
                    <div className={s.titleBox}>
                        <h1 className={s.titleOne}>vers </h1>
                        <h1 className={`${s.titleTwo} ${st.underline}`}> l’excellence</h1>
                    </div>
                    {isMobile ? <p className={s.message}>Notre soutien scolaire innovant combine des<br />techniques d’apprentissage modernes et un<br />accompagnement personnalisé pour assurer<br />votre succès académique.</p> : <p className={s.message}>Notre soutien scolaire innovant combine des techniques<br />d’apprentissage modernes et un accompagnement personnalisé<br />pour assurer votre succès académique.</p>}
                    <button className={st.searchButton} style={{ marginTop: isMobile ? "30px" : "40px" }} onClick={handleClick}>
                        <SearchOutlined />  Trouvez un enseignant
                    </button>
                    {!isMobile && <div className={s.infoBox}>
                        <div className={s.imagesBox}>
                            <img src={student_one} className={s.studentOne}></img>
                            <img src={student_two} className={s.studentTwo}></img>
                            <img src={student_three} className={s.studentThree}></img>
                        </div>
                        <div className={s.numbersBox}>
                            Plus de 23.000+
                            <div className={s.numberTwo}>Collaborations</div>
                        </div>
                        <div className={st.animatedDiv}>
                            <div className={st.animatedDivBox}>
                                <div className={st.animatedDivBoxTitle}>+25K</div>
                                <div className={st.animatedDivBoxSubtitle}>Cours</div>
                            </div>
                            <div className={st.animatedDivSeparator}></div>
                            <div className={st.animatedDivBox}>
                                <div className={st.animatedDivBoxTitle}>+2000</div>
                                <div className={st.animatedDivBoxSubtitle}>Tuteurs</div>
                            </div>
                            <div className={st.animatedDivSeparator}></div>
                            <div className={st.animatedDivBox}>
                                <div className={st.animatedDivBoxTitle}>+300</div>
                                <div className={st.animatedDivBoxSubtitle}>Quiz</div>
                            </div>
                        </div>
                    </div>}
                </div>
                <div className={s.rightImagesBox}>
                    <img src={student_girl} className={s.studentGirl}></img>
                    <img src={rate_one} className={`${st.floatAnimation} ${s.rateOne}`}></img>
                    <img src={rate_two} className={`${st.floatAnimationOne} ${s.rateTwo}`}></img>
                    <img src={rate_three} className={`${st.floatAnimationTwo} ${s.rateThree}`}></img>
                </div>
            </div>
            {isMobile && <div className={s.infoBox}>
                <div className={s.imagesBox}>
                    <img src={student_one} className={s.studentOne}></img>
                    <img src={student_two} className={s.studentTwo}></img>
                    <img src={student_three} className={s.studentThree}></img>
                    <div className={s.numbersBox}>
                        Plus de 23.000+
                        <div className={s.numberTwo}>Collaborations</div>
                    </div>
                </div>

                <div className={st.animatedDiv}>
                    <div className={st.animatedDivBox}>
                        <div className={st.animatedDivBoxTitle}>+25K</div>
                        <div className={st.animatedDivBoxSubtitle}>Cours</div>
                    </div>
                    <div className={st.animatedDivSeparator}></div>
                    <div className={st.animatedDivBox}>
                        <div className={st.animatedDivBoxTitle}>+2000</div>
                        <div className={st.animatedDivBoxSubtitle}>Tuteurs</div>
                    </div>
                    <div className={st.animatedDivSeparator}></div>
                    <div className={st.animatedDivBox}>
                        <div className={st.animatedDivBoxTitle}>+300</div>
                        <div className={st.animatedDivBoxSubtitle}>Quiz</div>
                    </div>
                </div>
            </div>}
            <Lottie animationData={arrowAnimation} loop={true} className={s.lottieImage} />
        </div>
    );
};

export default SlideHome;