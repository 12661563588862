import { RedoOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Skeleton, Tooltip, message } from "antd";
import * as EXERCISES_API from "api/services/exercises/exercises.services";
import useFindStudentExo from "hooks/useStudentExos.hook";
import React, { FC, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

//!COMPONENTS
import Breadcrumbs from "components/breadcrumbs";
import AppScreen from "screens/teacher/ScreenWithSidebar";

//!STYLES
import { StatusCode, TypeExercices } from "common/enums";
import AppResult from "components/appResults";
import CardExoV2 from "../components/cardExoV2";
import HeaderComponents from "../components/headerComponents";
import TabsStudent from "../components/tabs";
import s from "./assets/styles.module.css";

const StudentExercices: FC<any> = ({ id }) => {
  //!HOOK
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const submit = useRef<any>(null);
  const { exercices, loading, error, setExercices, setLoading } =
    useFindStudentExo(id!);

  const filterExercices: any = useCallback(async (values: any) => {
    setLoading(true);
    try {
      const response = await EXERCISES_API.teacherFilterExercices(values);
      if (response.statusCode === StatusCode.OK) {
        setExercices(response.data);
      }
    } catch (error) {
      message.error("Une erreur inconnue à été survenue");
    } finally {
      setLoading(false);
    }
  }, []);

  const { Search } = Input;
  const { Option } = Select;

  const onReset = () => {
    form.resetFields();
    filterExercices();
  };

  return (
    <React.Fragment>
      <AppScreen>
        <div className={s.main}>
          {loading && (
            <>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </>
          )}
          {!loading && exercices?.result?.length <= 0 ? (
            <AppResult status="info" title="Aucun exercice disponible !" />
          ) : (
            <>
              {!loading && (
                <>
                  {exercices?.result?.map((exercise: any) => (
                    <CardExoV2
                      title={exercise?.title}
                      description={exercise?.description}
                      date={exercise?.updatedAt}
                      nameChapter={exercise?.chapterId?.title}
                      level={exercise?.course?.level?.title}
                      cours={exercise?.course?.title}
                      clues={exercise?.clues[0]?.title}
                      solutions={exercise?.solutions[0]?.title}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </AppScreen>
    </React.Fragment>
  );
};

export default StudentExercices;
