import { FC } from "react";
import AppScreen from "../ScreenWithSidebar";
import TeacherAds from "screens/teacher/TeacherAds/ad";
import Breadcrumbs from "components/breadcrumbs";

const AppTeacherAds: FC = () => {
  //!HOOKS

  //!STATE

  //!FUNCTIONS

  return (
    <>
      <Breadcrumbs isAds={true} />
      <AppScreen>
        <TeacherAds />
      </AppScreen>
    </>
  );
};

export default AppTeacherAds;
