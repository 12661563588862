export const FIND_ALL = (self: string) => `${process.env.REACT_APP_API_URL}/chapters?self=${self}`;

export const API_BASE_URL = `${process.env.REACT_APP_API_URL_RESSOURCES}/chapters`;

export const TEACHER_FILTER_COURSES = (value: any) => {
  const {
    title = "",
    levelId = "",
    topicId = "",
    status = "",
    self = false,
  } = value;
  const apiUrl = process.env.REACT_APP_API_URL;
  return `${apiUrl}/chapters?title=${title}&levelId=${levelId}&course=${topicId}&status=${status}&self=${self}`;
};

export const findCoursForTopic = (idTopic: string) => `${process.env.REACT_APP_API_URL}/chapters?title=&levelId=&course=${idTopic}&status=&self=false`;

export const API_GET_SCHOOLS = `${process.env.REACT_APP_API_URL_RESSOURCES}/users/inscription/student/etablissement`;
export const API_GET_OLD_PROPOSITIONS = `${process.env.REACT_APP_API_URL_RESSOURCES}/chapters/content/get/old`;
export const API_GET_NEW_PROPOSITIONS = `${process.env.REACT_APP_API_URL_RESSOURCES}/chapters/content/get/new`;
export const API_GET_CHAPTERS = `${process.env.REACT_APP_API_URL_RESSOURCES}/chapters`;
