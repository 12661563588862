import { useState, useEffect } from "react";
import { findExercice } from "api/services/studentExercice/studentExercice";

const useStudentExercice = (id: string) => {
  //!STATE
  const [exercice, setExercice] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  const fetchExercice = async (idExercice: string) => {
    setLoading(true);
    try {
      const response = await findExercice(idExercice);
      if (response) {
        return setExercice(response.data);
      }
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExercice(id);
  }, [id]);

  return { exercice, loading, error };
};

export default useStudentExercice;
