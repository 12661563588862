import axios from "axios";
import { ApiResponse } from "common/interfaces";
import {
  StudentChallengQuizz,
  StudentchallengeParticipate,
  fetchChallengesByClassIde,
  fetchClassementByChallenge,
  getBarStatsParClasse,
  getChallengesParClasse,
  getPieStatsParClasse,
  studentChallengeQuizzResponses,
} from "./challenge.api.routes";

export const findByIdClasse = async (
  id: string | undefined
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(getChallengesParClasse(id));
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const fetchChallengesByClassId = async (
  id: string | undefined
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(fetchChallengesByClassIde(id));
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const fetchClassementByChallengee = async (
  id: string | undefined
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(fetchClassementByChallenge(id));
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getPieStatsByIdClasse = async (
  id: string | undefined
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(getPieStatsParClasse(id));
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getBarStatsByIdClasse = async (
  id: string | undefined
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(getBarStatsParClasse(id));
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const postchallengeParticipate = async (
  studentId: any,
  challengeId: any
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.post(StudentchallengeParticipate(), {
      studentId: studentId,
      challengeId: challengeId,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getChallengeQuizzParticipate = async (
  idChallenge: string
): Promise<ApiResponse | any> => {
  try {
    const response = await axios.get(StudentChallengQuizz(idChallenge));
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const postStudentChallengeQuizResponses = async (
  idQuiz: string,
  idStudent: string | any,
  values: any
) => {
  try {
    const response = await axios.post(
      studentChallengeQuizzResponses(idQuiz, idStudent),
      values
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
