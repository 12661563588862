import React from "react";
import { Result } from "antd";
import Container from "components/container";
import AppButton from "components/libs/button";
import Stylo from "components/stylo";
import Breadcrumbs from "components/breadcrumbs";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

//!ASSETS
import s from "./assets/style.module.css";
import AppResult from "components/appResults";

const NotFound = () => {
  return (
    <React.Fragment>
      <Breadcrumbs />
      <Stylo />
      <div className={s.home}>
        <Container>
          <AppResult
            status="info"
            title="404"
            subTitle="Cette page n'existe pas !"
            extra={
              <Link to="/">
                <AppButton icon={<ArrowLeftOutlined />}>Retour</AppButton>
              </Link>
            }
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NotFound;
