// LineChart.tsx
import React from 'react';
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

interface LineChartProps {
    data: { quiz: number; score: number }[];
    chartTitle: string;
}

const LineChartComponent: React.FC<LineChartProps> = ({ data, chartTitle }) => {
    return (
        <ResponsiveContainer width="100%" height={200}>
            <LineChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 10 }}>
                <XAxis dataKey="quiz" label={{ value: 'Quiz', position: 'insideBottom', offset: 0 }} />
                <YAxis label={{ value: 'Score', angle: -90, position: 'insideLeft' }} interval={1} />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="score" name={'Note'} stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default LineChartComponent;
