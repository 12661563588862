import React, { useMemo, useState } from "react";
import { Button, Col, Form, Input, Modal, Row } from "antd";

//!COMPONENTS
import H2 from "components/libs/subtitle";

//!ASSETS
import Student from "assets/images/students/student.svg";
import WarningIcon from "assets/images/app/warning.svg";
import LottieSearch from "assets/animations/search.json";
import BadgeActived from "assets/images/students/badgeInstaProf.svg";
import BadgeDisabled from "assets/images/students/instaProfBadgeFalse.svg";

//!STYLES
import s from "./style.module.css";
import AppButton from "components/libs/button";
import LottieAnimate from "components/lotties";
import Matiers from "components/matiers";
import { useDispatch, useSelector } from "react-redux";
import CountdownTimer from "components/appCountDown";
import { INIT_MODAL } from "store/notification";

type Props = {};

const ModalSessionInfoInstaProf: React.FC<Props> = ({ }) => {
  //!HOOKS
  const dispatch = useDispatch();
  const { currentStudentInstaProf } = useSelector((state) => state.auth);
  const { openModalSession } = useSelector((state) => state.notifications);

  const THREE_DAYS_IN_MS = 5 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();
  const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;

  const handleClose = () => {
    dispatch(INIT_MODAL());
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  return useMemo(() => {
    return (
      <Modal
        title={false}
        visible={openModalSession}
        footer={false}
        width={isMobile ? "100%" : "50%"}
        onOk={handleClose}
        onCancel={handleClose}
        centered
        className={s.modal}
      >
        <Row
          justify="center"
          align="middle"
          gutter={12}
          style={{ marginBottom: "1.5em" }}
        >
          <Col>
            <div className={s.avatar}>
              <img
                src={currentStudentInstaProf?.data?.student?.profile || Student}
                alt=""
              />
            </div>
          </Col>
        </Row>

        <Row justify="center" align="middle" gutter={12}>
          <Col>
            <H2>
              Session avec {currentStudentInstaProf?.data?.student?.firstname}{" "}
              en attente de validation
            </H2>
          </Col>
        </Row>

        <Row
          justify="center"
          align="middle"
          gutter={12}
          style={{ marginBottom: "0.5em" }}
        >
          <Col>
            <div className={s.lottie}>
              <LottieAnimate animation={LottieSearch} />
            </div>
          </Col>
        </Row>

        {/* <Row justify="center" gutter={20} style={{ marginBottom: "0.5em" }}>
          <Col>
            <span className={s.timer}>05 : 34</span>

            <CountdownTimer targetDate={dateTimeAfterThreeDays} />
          </Col>
        </Row> */}

        <Row justify="center" gutter={20}>
          <Col>
            <h3
              style={{
                textTransform: "uppercase",
                color: "var(--text-color)",
                marginBottom: "var(--mb-3)",
              }}
            >
              L'élève est entrain de parcourir votre profil
            </h3>
          </Col>
        </Row>
      </Modal>
    );
  }, [openModalSession, currentStudentInstaProf]);
};

export default ModalSessionInfoInstaProf;
