import { Image } from "antd";
import { FC } from "react";

//!COMPONENTS
import Card from "components/card";

//!IMAGES
import Plus from "../../../assets/images/teacher/iconePlus.svg";

//!STYLES
import s from "./assets/card.module.css";

type Props = {
  onClick?: any;
};

const CardAddCours: FC<Props> = ({ onClick }) => {
  return (
    <Card className={s.card}>
      <Image
        preview={false}
        width={50}
        src={Plus}
        alt="logo"
        onClick={onClick}
      />
      <span>Ajouter une classe</span>
    </Card>
  );
};

export default CardAddCours;
