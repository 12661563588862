import { Table } from 'antd';
import React from 'react';

type Props = {
    data: any;
    onEdit: (studentId: string) => void;
}

const StudentsManagedTable: React.FC<Props> = ({ data, onEdit }) => {
    // Define columns for the table
    const columns = [
        {
            title: 'Eleve',
            dataIndex: ['firstname', 'lastname'],
            render: (_: any, record: { lastname: any; firstname: any; }) => `${record.lastname}, ${record.firstname}`
        },
        {
            title: 'Solde',
            dataIndex: 'montant',
            key: 'montant',
        },
        {
            title: 'Action',
            dataIndex: '_id',
            key: '_id',
            render: (text: string, record: any) => (
                <button onClick={() => handleClick(record)}>Modifier</button>
            ),
        }
    ];

    const handleClick = (record: any) => {
        onEdit(record['_id']);
    };

    return (
        <Table dataSource={data} columns={columns} />
    );
};

export default StudentsManagedTable;
