import { UserRoles } from "common/enums";
import Faqs from "components/faqs";
import * as appRoutes from "config/routes.config";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AnimatedPage from "utils/animatedPage";
import Advantages from "./components/advantages";
import ContactUs from "./components/contactUs";
import JoinCommunity from "./components/joinCommunity";
import TeachingMaterials from "./components/materialsTeaching";
import OurPlateforme from "./components/plateforme";
import Results from "./components/results";
import SlideOrganisme from "./components/slide";
import StudentsTestimonies from "./components/testimonies";

const OrganismePage = () => {

    //!HOOKS
    const { t } = useTranslation();
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();

    //!EFFECTS
    useEffect(() => {
        const isLoggedIn = auth.isLoggedIn;
        const isParent = auth.user?.roles?.includes(UserRoles.PARENT);
        const isStudent = auth.user?.roles?.includes(UserRoles.STUDENT);
        if (isLoggedIn && isParent) return navigate(appRoutes.PARENT_DASHBOARD);
        if (isLoggedIn && isStudent) return navigate(appRoutes.TEACHER_ACTIVITIES);
    }, [auth.isLoggedIn, navigate, auth.user?.roles]);

    return (
        <AnimatedPage>
            <SlideOrganisme />
            <Advantages />
            <TeachingMaterials />
            <OurPlateforme />
            <Results />
            <JoinCommunity />
            <Faqs type={"organisme"} />
            <StudentsTestimonies />
            <ContactUs />
        </AnimatedPage>
    );
};

export default OrganismePage;