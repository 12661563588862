import { Col, Form, Input, Modal, Row } from "antd";
import React, { useMemo, useState } from "react";

//!COMPONENTS
import AppButton from "components/libs/button";
import H2 from "components/libs/subtitle";

//!ASSETS
import BadgeActived from "assets/images/students/badgeInstaProf.svg";
import BadgeDisabled from "assets/images/students/instaProfBadgeFalse.svg";
import Teacher from "assets/images/teacher/maa.svg";

//!STYLES
import { useDispatch, useSelector } from "react-redux";
import { ACTIVE_MODE_INSTAPROF } from "store/notification";
import s from "./style.module.css";

type Props = {
  onActiveInstaProf: (values: any) => Promise<void>;
};

const ModalInfoInstaProf: React.FC<Props> = ({ onActiveInstaProf }) => {
  //!HOOKS
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { activeModeInstaProf } = useSelector((state) => state.notifications);

  const showModalInfoInstaProf = () => {
    dispatch(ACTIVE_MODE_INSTAPROF(!activeModeInstaProf));
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  return useMemo(() => {
    return (
      <Modal
        title={false}
        visible={activeModeInstaProf}
        footer={false}
        width={isMobile ? "100%" : "50%"}
        onOk={showModalInfoInstaProf}
        onCancel={showModalInfoInstaProf}
        centered
        className={s.modal}
      >
        <Row
          justify="center"
          align="middle"
          gutter={12}
          style={{ marginBottom: "2em" }}
        >
          <Col>
            <img src={Teacher} alt="" width={70} />
          </Col>
          <Col>
            <H2 className={s.title}>
              ACTIVE LE MODE <strong>INSTAPROF</strong>
            </H2>
          </Col>
        </Row>
        <p className={s.paragraphe}>
          <em>
            Le mode InstaProf te donne la possibilité de lister ton profil en mode <br />“en ligne et disponible pour un cours particulier”, même en dehors des créneaux de tes cours planifiés.
          </em>
        </p>

        <Row justify="center" gutter={20} style={{ marginBottom: "1em" }}>
          <Col>
            <img src={BadgeActived} alt="" width={40} />
          </Col>
          <Col>
            <p>
              Statut disponible : tu seras listé “en ligne”<br /> de manière constante sur la plateforme.{" "}
            </p>
          </Col>
        </Row>
        <Row justify="center" gutter={12} style={{ marginBottom: "0.5em" }}>
          <Col>
            <img src={BadgeDisabled} alt="" width={40} />
          </Col>
          <Col>
            <p>
              Statut désactivé : tu seras listé comme disponible<br />uniquement pour les créneaux de cours planifiés sur ton calendrier.
            </p>
          </Col>
        </Row>

        <p className={s.paragraphe}>
          <em>
            Une session instaprof dure <strong>50min</strong>
          </em>
        </p>

        <Row justify="center" gutter={12} style={{ marginBottom: "5em" }}>
          <Form onFinish={onActiveInstaProf}>
            <Row justify="center" align="middle" gutter={16}>
              <Col>
                <div className={s.field}>
                  <Form.Item
                    name={"cost"}
                    initialValue={user?.instaProfCost || 0}
                    style={{ margin: 0, padding: 0 }}
                    rules={[
                      {
                        pattern: /^(?:\d*)$/,
                        message: "Value should contain just number",
                      },
                      {
                        pattern: /^[\d]{0,5}$/,
                        message: "Value should be less than 5 character",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Votre tarif instaprof"
                      bordered={false}
                      suffix={
                        <span
                          style={{
                            fontWeight: "600",
                            position: "relative",
                            top: "2px",
                            color: "#5A5A5A",
                          }}
                        >
                          Euro/Session
                        </span>
                      }
                      className={s.input}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col>
                <AppButton htmlTypeSubmit={true} className={s.button}>
                  Activer
                </AppButton>
              </Col>
            </Row>
          </Form>
        </Row>
      </Modal>
    );
  }, [activeModeInstaProf]);
};

export default ModalInfoInstaProf;
