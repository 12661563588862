import FadeInOnScroll from "components/FadeIn";
import FadeInUpOnScroll from "components/FadeInUp";
import { useState } from "react";
import ellipse_testimony from "../../../../assets/images/app/home/ellipse_testimony.svg";
import ellipse_testimony_one from "../../../../assets/images/app/home/ellipse_testimony_one.svg";
import ellipse_testimony_two from "../../../../assets/images/app/home/ellipse_testimony_two.svg";
import ellipse_valeur from "../../../../assets/images/app/home/ellipse_valeur.svg";
import teacher_testimony_one from "../../../../assets/images/app/teachers/teacher_testimony_one.png";
import testimony_teacher_five from "../../../../assets/images/app/teachers/testimony_teacher_five.png";
import testimony_teacher_four from "../../../../assets/images/app/teachers/testimony_teacher_four.png";
import testimony_teacher_one from "../../../../assets/images/app/teachers/testimony_teacher_one.png";
import testimony_teacher_six from "../../../../assets/images/app/teachers/testimony_teacher_six.png";
import testimony_teacher_three from "../../../../assets/images/app/teachers/testimony_teacher_three.png";
import testimony_teacher_two from "../../../../assets/images/app/teachers/testimony_teacher_two.png";
import st from "../../../app/common.module.css";
import s from "./style.module.css";

const StudentsTestimonies = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.mainContainer}>
            <div className={s.testimoniesBox}>
                <FadeInUpOnScroll>
                    <h1 className={s.title}>Témoignages</h1>
                </FadeInUpOnScroll>
                <img src={ellipse_testimony} className={s.ellipseTestimonies}></img>
                <FadeInOnScroll>
                    {isMobile ? <p className={s.subtitle}>Ce que nos élèves parrainés<br />disent de nous</p> : <p className={s.subtitle}>Ce que nos élèves parrainés disent de nous</p>}
                </FadeInOnScroll>
                {isMobile ? <div className={s.testimonies}>
                    <img src={testimony_teacher_one} className={`${st.floatAnimation} ${s.testimonyOneImage}`}></img>
                    <img src={testimony_teacher_two} className={`${st.floatAnimation} ${st.floatAnimationDelayed1} ${s.testimonyTwoImage}`}></img>
                    <img src={ellipse_valeur} className={s.ellipseOne}></img>
                    <img src={testimony_teacher_three} className={`${st.floatAnimation} ${st.floatAnimationDelayed2} ${s.testimonyThreeImage}`}></img>
                    <div className={s.currentTestimony}>
                        <img src={teacher_testimony_one} className={s.currentTestimonyImage}></img>
                        <div className={s.currentTestimonyMessage}>Grâce à Mon Ami Albert et au soutien de mon organisme, j'ai pu bénéficier de cours qui m'ont vraiment aidé à progresser. Mon professeur a pris le temps de comprendre mes besoins et de m'accompagner dans mes difficultés. Je me sens beaucoup plus confiant dans mes études et je suis reconnaissant d'avoir eu cette opportunité. Je recommande vivement ce programme à tous les élèves !</div>
                        <div className={s.currentTestimonyName}>Lucas</div>
                        <div className={s.currentTestimonyUser}>Élève parrainé</div>
                        <img src={ellipse_testimony_one} className={s.ellipseTwo}></img>
                    </div>
                    <img src={testimony_teacher_four} className={`${st.floatAnimation} ${s.testimonyFourImage}`}></img>
                    <img src={testimony_teacher_five} className={`${st.floatAnimation} ${st.floatAnimationDelayed2} ${s.testimonyFiveImage}`}></img>
                    <img src={ellipse_testimony_two} className={s.ellipseThree}></img>
                    <img src={testimony_teacher_six} className={`${st.floatAnimation} ${st.floatAnimationDelayed1} ${s.testimonySixImage}`}></img>
                </div> : <div className={s.testimonies}>
                    <div style={{ width: "20%" }}>
                        <img src={testimony_teacher_one} className={`${st.floatAnimation} ${s.testimonyOneImage}`}></img>
                        <img src={testimony_teacher_two} className={`${st.floatAnimation} ${st.floatAnimationDelayed1} ${s.testimonyTwoImage}`}></img>
                        <img src={ellipse_valeur} className={s.ellipseOne}></img>
                        <img src={testimony_teacher_three} className={`${st.floatAnimation} ${st.floatAnimationDelayed2} ${s.testimonyThreeImage}`}></img>
                    </div>
                    <div className={s.currentTestimony}>
                        <img src={teacher_testimony_one} className={s.currentTestimonyImage}></img>
                        <div className={s.currentTestimonyMessage}>Grâce à Mon Ami Albert et au soutien de mon organisme, j'ai pu bénéficier de cours qui m'ont vraiment aidé à progresser. Mon professeur a pris le temps de comprendre mes besoins et de m'accompagner dans mes difficultés. Je me sens beaucoup plus confiant dans mes études et je suis reconnaissant d'avoir eu cette opportunité. Je recommande vivement ce programme à tous les élèves !</div>
                        <div className={s.currentTestimonyName}>Lucas</div>
                        <div className={s.currentTestimonyUser}>Élève parrainé</div>
                        <img src={ellipse_testimony_one} className={s.ellipseTwo}></img>
                    </div>
                    <div style={{ width: "20%" }}>
                        <img src={testimony_teacher_four} className={`${st.floatAnimation} ${s.testimonyFourImage}`}></img>
                        <img src={testimony_teacher_five} className={`${st.floatAnimation} ${st.floatAnimationDelayed2} ${s.testimonyFiveImage}`}></img>
                        <img src={ellipse_testimony_two} className={s.ellipseThree}></img>
                        <img src={testimony_teacher_six} className={`${st.floatAnimation} ${st.floatAnimationDelayed1} ${s.testimonySixImage}`}></img>
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default StudentsTestimonies;