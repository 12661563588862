import { createSlice } from "@reduxjs/toolkit";

export interface DrawerAds {
  drawerVisible: boolean;
  drawerAdsSpecific: boolean;
  drawerUpdate: boolean | undefined;
  dataAd: any;
  currentCreneau: any;
  reportDrawerVisible: boolean;
}

const initialState: DrawerAds = {
  drawerVisible: false,
  drawerUpdate: false,
  drawerAdsSpecific: false,
  dataAd: {},
  currentCreneau: null,
  reportDrawerVisible: false,
};

export const drawerAdSlice = createSlice({
  name: "drawerAds",
  initialState,
  reducers: {
    displayDrawer: (state: any, action: any) => {
      state.drawerVisible = action.payload;
    },
    displayDrawerAdsSpecific: (state: any, action: any) => {
      state.drawerAdsSpecific = action.payload;
    },
    displayDrawerUpdate: (state: any, action: any) => {
      state.drawerUpdate = action.payload;
    },
    setDataUpdate: (state: any, action: any) => {
      state.dataAd = action.payload;
    },
    setCurrentCreneau: (state: any, action: any) => {
      state.currentCreneau = action.payload;
    },
    displayReportDrawer: (state: any, action: any) => {
      state.reportDrawerVisible = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  displayDrawer,
  displayDrawerAdsSpecific,
  displayDrawerUpdate,
  setDataUpdate,
  setCurrentCreneau,
  displayReportDrawer,
} = drawerAdSlice.actions;

export default drawerAdSlice.reducer;
