import React, { FC } from "react";
import { Image } from "antd";

//!IMAGES
import Math from "../../../assets/images/teacher/iconMath.svg";
import Exo from "../../../assets/images/teacher/exo.svg";
import Classes from "../../../assets/images/teacher/inconClasses.svg";

//!STYLES
import s from "./assets/card.module.css";
import Card from "components/card";
import AppButton from "../button";
import Matiers from "components/matiers";

type Props = {
  onClick?: any;
  text?: string;
  buttonClassName?: string;
  icon: string;
  iconSize?: number;
  coursNumber?: boolean;
  count?: number;
  myContent?: any;
};

const CardContent: FC<Props> = ({
  onClick,
  text,
  buttonClassName,
  icon,
  iconSize,
  coursNumber,
  count,
  myContent,
}) => {
  return (
    <div className={s.card}>
      <div className={s.type__content}>
        <Image
          preview={false}
          width={iconSize || 80}
          src={icon}
          alt="logo"
          onClick={onClick}
        />
      </div>

      {coursNumber === true ? (
        <div className={s.cours__number}>
          <div>
            <span>{count || 0}</span>
          </div>
          <div className={s.___state__my__content}>
            <span>
              Mon contenu : <strong>{myContent}</strong>
            </span>
          </div>
        </div>
      ) : (
        <Matiers />
      )}

      <AppButton className={`${s.button} ${buttonClassName}`}>{text}</AppButton>

      <div className={s.states}>{/* <span>45</span> */}</div>
    </div>
  );
};

export default CardContent;
