import { UserRoles } from "common/enums";
import * as appRoutes from "config/routes.config";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import AnimatedPage from "utils/animatedPage";

const AuthGuard: FC = ({ children }) => {
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const isAuth =
    user?.roles?.includes(UserRoles.STUDENT) ||
    user?.roles?.includes(UserRoles.TEACHER);
  if (!isLoggedIn) return <Navigate to={appRoutes.HOME} />;
  if (isLoggedIn && !isAuth) return <Navigate to={appRoutes.FORBIDDEN} />;
  return <AnimatedPage>{children}</AnimatedPage>;
};

export default AuthGuard;
