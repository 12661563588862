import Breadcrumbs from "components/breadcrumbs";
import React from "react";
import AppScreen from "../ScreenWithSidebar";
import MainCourses from "./components/main";

const CoursesByLevel = () => {
  return (
    <React.Fragment>
      <Breadcrumbs isMycontent={true} />
      <AppScreen>
        <MainCourses />
      </AppScreen>
    </React.Fragment>
  );
};

export default CoursesByLevel;
