import { UserRoles } from "common/enums";
import * as appRoutes from "config/routes.config";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AnimatedPage from "utils/animatedPage";
import ChooseTeacher from "./components/chooseTeacher";
import ContactUs from "./components/contactUs";
import NeedHelp from "./components/needHelp";
import OurHistory from "./components/ourHistory";
import OurMission from "./components/ourMission";
import OurResources from "./components/ourResources";
import OurValues from "./components/ourValues";
import SlideHome from "./components/slide";
import StudentsTestimonies from "./components/testimonies";
import WhyChooseUs from "./components/WhyChooseUs";

const HomePage = () => {
  //!HOOKS
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  //!EFFECTS
  useEffect(() => {
    const isLoggedIn = auth.isLoggedIn;
    const isTeacher = auth.user?.roles?.includes(UserRoles.TEACHER);
    const isStudent = auth.user?.roles?.includes(UserRoles.STUDENT);
    if (isLoggedIn && isTeacher) return navigate(appRoutes.TEACHER_ACTIVITIES);
    if (isLoggedIn && isStudent) return navigate(appRoutes.TEACHER_ACTIVITIES);
  }, [auth.isLoggedIn, navigate, auth.user?.roles]);

  return (
    <AnimatedPage>
      <SlideHome />
      <OurMission />
      <ChooseTeacher />
      <NeedHelp />
      <OurResources />
      <OurValues />
      <OurHistory />
      <WhyChooseUs />
      <StudentsTestimonies />
      <ContactUs />
    </AnimatedPage>
  );
};

export default HomePage;
