import { DatePicker, Drawer, Form, Select, Switch, message } from "antd";
import { FC, useCallback, useEffect, useState } from "react";

import moment from "moment";

//!STYLE
import { AppNotification } from "components/appNotification";
import AppButton from "components/libs/button";
import { badRequest } from "messages";
import { useDispatch, useSelector } from "react-redux";
import {
  displayDrawerAdsSpecific,
  setCurrentCreneau,
} from "store/adventissements";
import { setIsCalendarUpdated } from "store/app/appSlice";
import {
  useCreateAdSpecificMutation,
  useGetAllAdSpecificQuery,
  useUpCreSpecMutation,
} from "store/services/teacher/ads.services";
import {
  filterHoursByCurrentTime,
  handleClearNiveauMatUser,
  hours
} from "utils/formats";
import s from "../../TeacherAds/components/style.module.css";

//!Images
import AlbertIcon from "../../../../assets/images/app/albert_icon.svg";
import ArrowBack from "../../../../assets/images/app/arrow_back.svg";

const { Option } = Select;

const DrawerAdsCreneauSpecific: FC = () => {
  //!HOOKS
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { refetch } = useGetAllAdSpecificQuery(user?._id);
  const [createAdSpecific, { isLoading, isError, isSuccess }] =
    useCreateAdSpecificMutation();

  const [
    upCreSpec,
    { isLoading: isLoadingUp, isSuccess: isSuccessUp, isError: isErrorUp },
  ] = useUpCreSpecMutation();

  const { drawerAdsSpecific, currentCreneau } = useSelector(
    (state) => state.drawerAds
  );

  //!STATES
  const [tempTopic, setTempTopic] = useState<any>([]);
  const [date, setDate] = useState<string>("");

  const [selectedHour, setSelectedHour] = useState<any>("");
  const [startingHour, setStartingHour] = useState<any>("08:00");
  const [endingHour, setEndingHour] = useState<any>("09:00");
  const [isThirty, setIsThirty] = useState<boolean>(false);

  const [checkedLevels, setCheckedLevels] = useState(true);
  const [selectedGourp, setSelectedGourp] = useState<any>([]);

  const handleChange = (checked: boolean) => {
    setCheckedLevels(checked);
    if (checked) {
      setSelectedGourp((prev: any) => {
        return [];
      });
    }
  };

  const handleCloseDrawer = () => {
    dispatch(displayDrawerAdsSpecific(false));
    dispatch(setCurrentCreneau({}));
    setSelectedGourp((prev: any) => {
      return [];
    });
    setCheckedLevels(true);
  }

  const handleGroupClick = (group: any) => {
    setSelectedGourp((prev: any[]) => {
      const isGroupSelected = prev.some(selected => selected.levelGroup === group.levelGroup);
      if (isGroupSelected) {
        return prev.filter(selected => selected.levelGroup !== group.levelGroup);
      } else {
        return [...prev, group];
      }
    });
  };

  const handleStartingChange = (value: any) => {
    console.log(value);
    setSelectedHour(value);
    setStartingHour(value);
  };

  const handleEndingChange = (value: any) => {
    console.log(value);
    setEndingHour(value);
  };

  function estIdMongoDB(chaine: string): boolean {
    const pattern = /^[0-9a-fA-F]{24}$/;
    return pattern.test(chaine);
  }

  const onSelectLevel = useCallback(async (levelId: string) => {
    return user?.nivMat
      .filter((nivMat: any) => {
        return nivMat?.niveau?._id === levelId;
      })
      .forEach((matiere: any) => {
        return setTempTopic(matiere?.matieres);
      });
  }, []);

  const onFinish = useCallback(
    (values: any) => {
      console.log("onFinish");
      if (Number(values.one.split(":")[0]) >= Number(values.two.split(":")[0])) {
        AppNotification("L'horaire du créneau n'est pas valide.", "AlbertSad");
        throw new Error("Invalid creneau");
      }
      const today = new Date();
      const isoDate = today.toISOString();
      const checkValueTopic = estIdMongoDB(values.matiere)
        ? values.matiere
        : currentCreneau?.topic[0]?._id;

      const concatenatedLevelGroups = selectedGourp.map((group: any) => group.levelGroup).join("$");

      const finalData = {
        teacherId: user?._id,
        niches: {
          grouplevels: checkedLevels ? [""] : [concatenatedLevelGroups],
          date: values.date._d,
          timeRanges: [
            {
              range: [
                {
                  hour: values?.one,
                },
                {
                  hour: values?.two,
                },
              ],
              type: "individuel",
            },
          ],
        },
      };

      const updateData = {
        teacherId: user?._id,
        niches: {
          grouplevels: checkedLevels ? [""] : [concatenatedLevelGroups],
          date: values.date._d,
          level: [currentCreneau?.level?._id],
          topic: [currentCreneau?.topic?._id],
          timeRanges: [
            {
              range: [
                {
                  hour: values?.one,
                },
                {
                  hour: values?.two,
                },
              ],
              type: "individuel",
            },
          ],
        },
      };

      createAdSpecific(currentCreneau?.date ? updateData : finalData);
    },
    [currentCreneau, upCreSpec, user]
  );

  useEffect(() => {
    if (isSuccess) {
      message.success("Créneau ajouté avec succès");
      dispatch(displayDrawerAdsSpecific(false));
      setTimeout(() => {
        dispatch(setIsCalendarUpdated(true));
      }, 3000);
      form.resetFields();
      setSelectedGourp((prev: any) => {
        return [];
      });
      setCheckedLevels(true);
    }
    if (isError) {
      message.error(badRequest.error);
    }
  }, [isSuccess, isLoading, isError]);

  useEffect(() => {
    console.log(currentCreneau);
    if (currentCreneau?.date) {
      form.setFieldsValue({
        date: moment(currentCreneau?.day) || "",
        one: currentCreneau?.timeRanges[0] || "",
        two: currentCreneau?.timeRanges[1] || "",
        levelIds: currentCreneau?.level?._id || "",
        matiere: currentCreneau?.topic?.title || "",
        type: currentCreneau?.creneauTypes || "",
      });
      if (currentCreneau?.grouplevels === "") {
        setCheckedLevels(true);
      }
      else {
        setCheckedLevels(false);
        const levelsArray = currentCreneau?.grouplevels.split("$");
        const levelGroups = user?.groupLevels;
        const selected = levelGroups.filter((group: any) => levelsArray.includes(group.levelGroup));
        setSelectedGourp(selected);
      }
    } else {
      form.resetFields();
    }
  }, [currentCreneau]);

  useEffect(() => {
    if (selectedHour.toString().includes("30")) {
      setIsThirty(true);
    }
    else {
      setIsThirty(false);
    }
  }, [selectedHour]);

  useEffect(() => {
    const startingKey = `one`;
    const endingKey = `two`;
    form.setFieldsValue({
      [startingKey]: "08:00",
      [endingKey]: "09:00",
    });
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [isXsScreen, setIsXsScreen] = useState(window.innerWidth > 767 && window.innerWidth <= 1151);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth > 1151 && window.innerWidth <= 1321);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 1321 && window.innerWidth <= 1606);
  const [isNormalScreen, setIsNormalScreen] = useState(window.innerWidth > 1606 && window.innerWidth <= 1799);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
    setIsXsScreen(window.innerWidth > 767 && window.innerWidth <= 1151);
    setIsSmallScreen(window.innerWidth > 1151 && window.innerWidth <= 1321);
    setIsMediumScreen(window.innerWidth > 1321 && window.innerWidth <= 1606);
    setIsNormalScreen(window.innerWidth > 1606 && window.innerWidth <= 1799);
  };
  window.addEventListener('resize', handleResize);

  return (
    <Drawer
      className="drawer"
      onClose={handleCloseDrawer}
      visible={drawerAdsSpecific}
      width={isMobile ? "100%" : isXsScreen ? "100%" : isSmallScreen ? "75%" : isMediumScreen ? "70%" : isNormalScreen ? "70%" : "70%"}
      title={<div style={{ fontSize: "20px", fontFamily: "Poppins", fontWeight: "600", color: "#393E3E", backgroundColor: "transparent" }}>{currentCreneau?.date ? <h3>Replanifier un créneau</h3> : <h3>Ajouter un créneau</h3>}</div>}
      headerStyle={{ border: "none", paddingTop: "50px", paddingLeft: "40px", backgroundColor: "transparent" }}
      closeIcon={
        <img
          src={ArrowBack}
          height={18}
          style={{}}
        ></img>
      }
      drawerStyle={{ backgroundColor: "#FFF", borderTopLeftRadius: "30px", borderBottomLeftRadius: "30px", boxShadow: "-3px 3px 34px #39393929" }}
    >
      <Form onFinish={onFinish} form={form}>
        <div className={s.drawerBody}>
          <div className={s.dateBox}>
            <span>Date</span>
            <div className={s.datePickerBox}>
              <Form.Item
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: "Ce champ est requis",
                  },
                ]}
                name={"date"}
                className={s.datePicker}
              >
                <DatePicker
                  bordered={false}
                  disabledDate={(current) => {
                    return (
                      moment().add(-1, "days") >= current ||
                      moment().add(1, "month") <= current
                    );
                  }}
                  style={{ height: "40px", width: "100%", fontSize: "10px" }}
                  onChange={(_, date: string) => setDate(date)}
                  className={s.datePicker}
                />
              </Form.Item>
            </div>
          </div>
          <div className={s.timeBox}>
            <div className={s.timeTitle}>Créneau</div>
            <div className={s.selectBox}>
              <div className={s.timePicker}>
                <Form.Item
                  style={{ margin: 0, padding: 0 }}
                  rules={[
                    {
                      required: true,
                      message: "Ce champ est requis",
                    },
                  ]}
                  name={"one"}
                >
                  <Select
                    defaultValue="08:00"
                    value={startingHour}
                    placeholder="Heures"
                    style={{ width: "100%" }}
                    bordered={false}
                    onChange={handleStartingChange}
                    options={filterHoursByCurrentTime(hours, date).map((hour) => {
                      return {
                        value: hour.value,
                        label: hour.label,
                      };
                    })}
                  />
                </Form.Item>
              </div>
              <span>À</span>
              <div className={s.timePicker}>
                <Form.Item
                  style={{ margin: 0, padding: 0 }}
                  name={["two"]}
                  rules={[
                    {
                      required: true,
                      message: "Ce champ est requis",
                    },
                  ]}
                >
                  <Select
                    value={endingHour}
                    placeholder="Heures"
                    style={{ width: "100%" }}
                    bordered={false}
                    onChange={handleEndingChange}
                    options={filterHoursByCurrentTime(hours, date).map((hour) => {
                      return {
                        value: hour.value,
                        label: hour.label,
                      };
                    })}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className={s.levelsBox}>
            Afficher ce créneau pour tous niveaux
            <Switch
              style={{ backgroundColor: checkedLevels ? "#7FB1B2" : "#868686", marginLeft: "30px" }}
              checked={checkedLevels}
              onChange={handleChange}
            />
          </div>

          {!checkedLevels && <div className={s.levelsButtons}>
            {user?.groupLevels.map((group: any) => (
              <div
                key={group?.levelGroup}
                onClick={() => handleGroupClick(group)}
                className={`${selectedGourp.some((selected: any) => selected.levelGroup === group.levelGroup) ? s.levelButtonActive : s.levelButton}`}
              >
                {group?.levelGroup}
              </div>
            ))}
          </div>}

          <div className={s.addButtonBox}>
            <Form.Item style={{ margin: 0, padding: 0 }}>
              <AppButton loading={isLoading || isLoadingUp} htmlTypeSubmit={true}>
                Ajouter le créneau
              </AppButton>
            </Form.Item>
          </div>

          <div className={s.albertBox}>
            <img src={AlbertIcon} height={300}></img>
          </div>

          <div className={s.full__content} style={{ display: "none" }}>
            <div className={s.field__content}>
              <div className={s.field}>
                <Form.Item
                  style={{ margin: 0, padding: 0 }}
                  rules={[
                    {
                      required: false,
                      message: "Ce champ est requis",
                    },
                  ]}
                  name={"levelIds"}
                >
                  <Select
                    placeholder="Choisir un niveau"
                    style={{ width: "100%" }}
                    className={s.select}
                    bordered={false}
                    onChange={(value: string, fullValues: object) => {
                      onSelectLevel(value);
                    }}
                    options={handleClearNiveauMatUser(user?.nivMat)?.map(
                      (item: any) => {
                        return {
                          label: item?.niveau?.levelGroup,
                          options: [
                            {
                              label: item?.niveau?.title,
                              value: item?.niveau?._id,
                            },
                          ],
                        };
                      }
                    )}
                  />
                </Form.Item>
              </div>

              <div className={s.field}>
                <Form.Item
                  style={{ margin: 0, padding: 0 }}
                  rules={[
                    {
                      required: false,
                      message: "Ce champ est requis",
                    },
                  ]}
                  name={"matiere"}
                >
                  <Select
                    className={s.select}
                    placeholder="Choisir une matière"
                    style={{ width: "100%" }}
                    bordered={false}
                    allowClear
                  >
                    {tempTopic?.map((topic: any) => (
                      <Option
                        key={topic?.matiere?._id}
                        value={topic?.matiere?._id}
                      >
                        {topic?.matiere?.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div className={s.field}>
                <Form.Item
                  style={{ margin: 0, padding: 0 }}
                  rules={[
                    {
                      required: false,
                      message: "Ce champ est requis",
                    },
                  ]}
                  name={"type"}
                >
                  <Select
                    defaultValue={"individuel"}
                    className={s.select}
                    placeholder="Choisir un type de créneau"
                    style={{ width: "100%" }}
                    bordered={false}
                    allowClear
                    value={"individuel"}
                  >
                    <Option value={"individuel"}>Individuel</Option>
                    <Option value={"collectif"}>Collectif</Option>
                  </Select>
                </Form.Item>
              </div>
            </div>
          </div>

        </div>
      </Form>
    </Drawer>
  );
};

export default DrawerAdsCreneauSpecific;
