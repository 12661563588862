import { getToken } from "api";
import axios from "axios";
import { ApiResponse } from "common/interfaces";
import * as API_ROUTES from "./advertissements.api.routes";

export const getAds = async (id: string) => {
  const token = await getToken();
  const response = await axios.get(API_ROUTES.GET_ADS(id), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const createAd = async (
  createAdDto: any
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.post(API_ROUTES.CREATE_AD, createAdDto, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const udpdateAd = async (
  id: string | undefined,
  values: any
): Promise<ApiResponse | undefined> => {
  try {
    // if (!id) throw new Error("Merci de passer un id");
    const token = await getToken();
    const response = await axios.patch(API_ROUTES.getAd(id), values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const udpdateCreneaux = async (
  id: string,
  values: any
): Promise<ApiResponse | undefined> => {
  try {
    // if (!id) throw new Error("Merci de passer un id");
    const token = await getToken();
    const response = await axios.patch(API_ROUTES.UPDATE_CRENEAU(id), values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const findAllByTeacher = async (
  id: string | undefined
): Promise<ApiResponse | undefined> => {
  try {
    if (!id) throw new Error("Merci de passer un id");
    const token = await getToken();
    const response = await axios.get(API_ROUTES.getTeacherAds(id), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const findAllTeacherInsta = async (
  teacher: any
): Promise<ApiResponse | undefined> => {
  try {
    // if (!id) throw new Error("Merci de passer un id");
    // const token = await getToken();
    const response = await axios.post(API_ROUTES.SEARCH_INSTA_PROF, teacher);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getTeacherInsta = async (
  idStudent: string
): Promise<ApiResponse | undefined> => {
  try {
    // if (!id) throw new Error("Merci de passer un id");
    // const token = await getToken();
    const response = await axios.get(
      API_ROUTES.STUDENT_GET_INSTA_PROF(idStudent)
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const killAllTeacherInsta = async (
  idStudent: string,
  code: string
): Promise<ApiResponse | undefined> => {
  try {
    // if (!id) throw new Error("Merci de passer un id");
    // const token = await getToken();
    const response = await axios.delete(
      API_ROUTES.STUDENT_GET_INSTA_PROF_KILL(idStudent, code)
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const findActiveModeInstaProf = async (
  idProf: string,
  cost: any
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.post(
      API_ROUTES.ACTIVE_INSTA_PROF(idProf),
      cost
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const findDisabledModeInstaProf = async (
  idProf: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.post(API_ROUTES.DISABLED_INSTA_PROF(idProf));
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const AcceptAProfByStudent = async (
  body: any
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.patch(
      API_ROUTES.STUDENT_ACCEPY_A_PROF(),
      body
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const StudentCheckSession = async (
  idTeacher: any
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(
      API_ROUTES.STUDENT_CHECK_SESSION(idTeacher)
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const ShutDownAnInstaProfRequest = async (
  idStudent: string,
  code: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.delete(
      API_ROUTES.SHUTDOWN_INSTAPROF_REQUEST(idStudent, code)
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const findAd = async (
  id: string | undefined
): Promise<ApiResponse | undefined> => {
  try {
    if (!id) throw new Error("Merci de passer un id");
    const token = await getToken();
    const response = await axios.get(API_ROUTES.getAd(id), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteAds = async (
  id: string | undefined
): Promise<ApiResponse | undefined> => {
  try {
    if (!id) throw new Error("Merci de passer un id");
    const token = await getToken();
    const response = await axios.delete(API_ROUTES.DELETE_ADS(id), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const findTeacherByFilter = async (values: any) => {
  try {
    const token = await getToken();
    // let myFilter: any = {};
    // if (values?.length == 0) {
    //   myFilter.page = 1;
    //   values = { ...myFilter };
    // } else {
    //   values.page = 1;
    // }

    const response = await axios.post(API_ROUTES.SEARCH_FILTER, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateNivMat = async (values: any) => {
  try {
    const token = await getToken();

    const response = await axios.post(API_ROUTES.UPDATE_NIVMAT(), values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
