import { EditOutlined, LeftOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import Container from "components/container";
import * as appRoutes from "config/routes.config";
import useCourse from "hooks/useCourse.hook";
import useCourseChapters from "hooks/useCourseChapters.hook";
import { FC, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { update } from "api/services/chapters/chapters.services";
import { CKEditor } from "ckeditor4-react";
import Breadcrumbs from "components/breadcrumbs";
import useChapter from "hooks/useChapter";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";
import AppScreen from "../ScreenWithSidebar";

const { Title } = Typography;

const EditChapter: FC = () => {
  //!DUMMY DATA
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Créé le",
      key: "createdAt",
      dataIndex: "createdAt",
    },
    {
      title: "Action",
      key: "action",
      render: (chapter: any) => {
        return (
          <Space size="middle">
            <Link
              to={`${appRoutes.TEACHER_COURSES}/${chapter?.course?._id}/edit/chapters/${chapter?._id}`}
            >
              <Button type="primary" ghost icon={<EditOutlined />} />
            </Link>
          </Space>
        );
      },
    },
  ];

  //!HOOKS
  const navigate = useNavigate();
  const params = useParams();
  const { loading, course } = useCourse(params.id!);
  const { data: chapter, loading: chapterLoading } = useChapter(
    params.chapterId!
  );
  const {
    chapters,
    loading: chaptersLoading,
    fetchCourseChapters,
  } = useCourseChapters(params.id!);
  const [form] = Form.useForm();

  // console.log("params", chapter)
  //!STATE
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [updateChapterLoading, setUpdateChapterLoading] = useState(false);
  const [editorLoading, setEditorLoading] = useState(true);
  const [hideEditor, setHideEditor] = useState(true);

  //!FUNCTIONS
  const goToCourses = () => navigate(appRoutes.TEACHER_COURSES);

  const updateChapter = async (values: any) => {
    try {
      // console.log("values", values)
      const description =
        values?.description?.editor?.getData() || chapter?.description;
      values.description = description;
      setUpdateChapterLoading(true);
      await update(params.chapterId!, values);
      message.success("Chapter updated successfully");
    } catch (error) {
      console.log(error);
    } finally {
      setUpdateChapterLoading(false);
    }
  };

  //!EFFECTS
  useEffect(() => {
    if (chapter) {
      setTitle(chapter?.title || "");
      setDescription(chapter?.description || "");
      form.setFieldsValue({
        title: chapter?.title || "",
        description: chapter?.description || "",
      });
    }

    if (!chapterLoading && chapter) {
      setTimeout(() => {
        setHideEditor(false);
      }, 10);
    }

    return () => {
      setHideEditor(true);
    };
  }, [chapter, form, chapterLoading]);

  //!RETURN
  if (loading)
    return (
      <Container>
        <div>Loading...</div>
      </Container>
    );

  return (
    <div style={{ overflow: "hidden" }}>
      <Breadcrumbs />
      <AppScreen>
        <Row>
          <Col span={14}>
            <Title level={3}>Title du cours : {course?.title}</Title>
          </Col>
          <Col span={10}>
            <Row justify="end">
              <Button
                onClick={goToCourses}
                type="primary"
                icon={<LeftOutlined />}
              >
                Revenir à la liste de cours
              </Button>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row gutter={20}>
          <Col span={12}>
            <Card bordered={false}>
              <Form form={form} layout="vertical" onFinish={updateChapter}>
                <Form.Item name="title" label="Titre du chapitre">
                  <Input onChange={(e) => setTitle(e.target.value)} />
                </Form.Item>
                {editorLoading && (
                  <Form.Item>
                    <Card loading />
                  </Form.Item>
                )}
                <Form.Item name="description" label="Détail du chapitre">
                  {/* <ReactQuill theme="snow" value={value} onChange={setValue} /> */}
                  {!hideEditor && (
                    <CKEditor
                      // value={chapter?.description || ''}

                      name={chapter?._id}
                      initData={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: chapter?.description || "",
                          }}
                        />
                      }
                      // data={chapter?.description || ''}
                      onChange={(changes: any) => {
                        const content = changes.editor.getData();
                        setDescription(content);
                      }}
                      onInstanceReady={() => {
                        setEditorLoading(false);
                      }}
                    />
                  )}
                </Form.Item>

                <Form.Item>
                  <Button
                    icon={<EditOutlined />}
                    loading={updateChapterLoading}
                    block
                    type="primary"
                    htmlType="submit"
                  >
                    Mettre à jour le chapitre
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Title level={3}>Apperçu du cours: {course?.title}</Title>
              <Title level={5}>{title}</Title>
              <Latex>
                {description
                  ? description
                      ?.replaceAll("amp;", "")
                      ?.replaceAll("<br />", "")
                  : ""}
              </Latex>
            </Card>
          </Col>
        </Row>
        <Title level={3}>Autres chapitres</Title>

        <Table
          loading={!!chaptersLoading}
          columns={columns}
          dataSource={chapters?.filter(
            (item: any) => item?._id !== chapter?._id
          )}
        />
      </AppScreen>
    </div>
  );
};

export default EditChapter;
