import React, { FC } from "react";
import Breadcrumbs from "components/breadcrumbs";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetProfileTeacherQuery } from "store/services/teacher/profileTeacher";

//!COMPONENTS
import AboutProfileTeacher from "screens/teacher/profileTeacher/components/about";
import HeaderProfileTeacher from "screens/teacher/profileTeacher/components/header";
import AppDrawer from "components/appDrawer";

type Props = {
  profId: string;
  openDrawerProfileTeacher: boolean;
  setOpenDrwerProfileTeacher: React.Dispatch<React.SetStateAction<boolean>>;
};

const DrawerProfileTeacher: FC<Props> = ({
  profId,
  openDrawerProfileTeacher,
  setOpenDrwerProfileTeacher,
}) => {
  //!HOOKS
  const params = useParams<"id">();
  const { user, idTeacher } = useSelector((state) => state.auth);

  const query = {
    profId: profId,
    studId: user?._id,
  };
  const { data, isLoading, isError } = useGetProfileTeacherQuery(query);

  return (
    <AppDrawer
      title={false}
      visible={openDrawerProfileTeacher}
      onClose={() => setOpenDrwerProfileTeacher(false)}
    >
      <HeaderProfileTeacher teacher={data?.data} />
      <AboutProfileTeacher teacher={data?.data} />
    </AppDrawer>
  );
};

export default DrawerProfileTeacher;
