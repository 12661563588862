import FadeInOnScroll from "components/FadeIn";
import FadeInUpOnScroll from "components/FadeInUp";
import { useState } from "react";
import ellipse_testimony from "../../../../../assets/images/app/home/ellipse_testimony.svg";
import ellipse_testimony_one from "../../../../../assets/images/app/home/ellipse_testimony_one.svg";
import ellipse_testimony_two from "../../../../../assets/images/app/home/ellipse_testimony_two.svg";
import ellipse_valeur from "../../../../../assets/images/app/home/ellipse_valeur.svg";
import student_testimony_one from "../../../../../assets/images/app/home/student_testimony_one.png";
import testimony_student_five from "../../../../../assets/images/app/home/testimony_student_five.png";
import testimony_student_four from "../../../../../assets/images/app/home/testimony_student_four.png";
import testimony_student_one from "../../../../../assets/images/app/home/testimony_student_one.png";
import testimony_student_six from "../../../../../assets/images/app/home/testimony_student_six.png";
import testimony_student_three from "../../../../../assets/images/app/home/testimony_student_three.png";
import testimony_student_two from "../../../../../assets/images/app/home/testimony_student_two.png";
import st from "../../../common.module.css";
import s from "./style.module.css";

const StudentsTestimonies = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.mainContainer}>
            <div className={s.testimoniesBox}>
                <FadeInUpOnScroll>
                    <h1 className={s.title}>Témoignages</h1>
                </FadeInUpOnScroll>
                <img src={ellipse_testimony} className={s.ellipseTestimonies}></img>
                <FadeInOnScroll>
                    {isMobile ? <p className={s.subtitle}>Ce que nos élèves et étudiants<br />disent de nous</p> : <p className={s.subtitle}>Ce que nos élèves et étudiants disent de nous</p>}
                </FadeInOnScroll>
                {isMobile ? <div className={s.testimonies}>
                    <img src={testimony_student_one} className={`${st.floatAnimation} ${s.testimonyOneImage}`}></img>
                    <img src={testimony_student_two} className={`${st.floatAnimation} ${st.floatAnimationDelayed1} ${s.testimonyTwoImage}`}></img>
                    <img src={ellipse_valeur} className={s.ellipseOne}></img>
                    <img src={testimony_student_three} className={`${st.floatAnimation} ${st.floatAnimationDelayed2} ${s.testimonyThreeImage}`}></img>
                    <div className={s.currentTestimony}>
                        <img src={student_testimony_one} className={s.currentTestimonyImage}></img>
                        <div className={s.currentTestimonyMessage}>Mon expérience avec Mon Ami Albert a été incroyable !<br />J'avais des difficultés en mathématiques et j'étais<br />un peu stressée à l'idée de passer mon examen. Grâce à la plateforme, j'ai pu trouver un professeur qui m'a aidée. J'ai enfin retrouvé confiance en moi et j'ai obtenu une excellente note ! Je recommande vivement Mon Ami Albert à tous les élèves qui veulent progresser et se sentir soutenus !</div>
                        <div className={s.currentTestimonyName}>Marine Adalie</div>
                        <div className={s.currentTestimonyUser}>Étudiante</div>
                        <img src={ellipse_testimony_one} className={s.ellipseTwo}></img>
                    </div>
                    <img src={testimony_student_four} className={`${st.floatAnimation} ${s.testimonyFourImage}`}></img>
                    <img src={testimony_student_five} className={`${st.floatAnimation} ${st.floatAnimationDelayed2} ${s.testimonyFiveImage}`}></img>
                    <img src={ellipse_testimony_two} className={s.ellipseThree}></img>
                    <img src={testimony_student_six} className={`${st.floatAnimation} ${st.floatAnimationDelayed1} ${s.testimonySixImage}`}></img>
                </div> : <div className={s.testimonies}>
                    <div style={{ width: "20%" }}>
                        <img src={testimony_student_one} className={`${st.floatAnimation} ${s.testimonyOneImage}`}></img>
                        <img src={testimony_student_two} className={`${st.floatAnimation} ${st.floatAnimationDelayed1} ${s.testimonyTwoImage}`}></img>
                        <img src={ellipse_valeur} className={s.ellipseOne}></img>
                        <img src={testimony_student_three} className={`${st.floatAnimation} ${st.floatAnimationDelayed2} ${s.testimonyThreeImage}`}></img>
                    </div>
                    <div className={s.currentTestimony}>
                        <img src={student_testimony_one} className={s.currentTestimonyImage}></img>
                        <div className={s.currentTestimonyMessage}>Mon expérience avec Mon Ami Albert a été incroyable ! J'avais des difficultés en mathématiques et j'étais un peu stressée à l'idée de passer mon examen. Grâce à la plateforme, j'ai pu trouver un professeur qui m'a aidée. J'ai enfin retrouvé confiance en moi et j'ai obtenu une excellente note ! Je recommande vivement Mon Ami Albert à tous les élèves qui veulent progresser et se sentir soutenus !</div>
                        <div className={s.currentTestimonyName}>Marine Adalie</div>
                        <div className={s.currentTestimonyUser}>Étudiante</div>
                        <img src={ellipse_testimony_one} className={s.ellipseTwo}></img>
                    </div>
                    <div style={{ width: "20%" }}>
                        <img src={testimony_student_four} className={`${st.floatAnimation} ${s.testimonyFourImage}`}></img>
                        <img src={testimony_student_five} className={`${st.floatAnimation} ${st.floatAnimationDelayed2} ${s.testimonyFiveImage}`}></img>
                        <img src={ellipse_testimony_two} className={s.ellipseThree}></img>
                        <img src={testimony_student_six} className={`${st.floatAnimation} ${st.floatAnimationDelayed1} ${s.testimonySixImage}`}></img>
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default StudentsTestimonies;