export const studentParser = (input: any, search?: any) => {
  console.log("my input", input);
  if (Array.isArray(input) && input.length > 0)
    return input
      .map((e: any, index: number) => {
        return {
          id: e._id,
          fullname: e.firstname + " " + e.lastname,
          score: e.points,
        };
      })
      .sort((a, b) => -a.score + b.score)
      .map((e: any, index: number) => ({ ...e, classement: index + 1 }));
};
export const studentsFilter = (input: any, search?: any) => {
  if (Array.isArray(input) && input.length > 0)
    return input
      .sort((a, b) => -a.score + b.score)
      .filter((e: any) => {
        return e?.fullname?.toLowerCase().indexOf(search.toLowerCase()) !== -1;
      });
};
