import {
  Alert,
  Col,
  Drawer,
  Form,
  FormInstance,
  Image,
  Input,
  Row,
  Select,
} from "antd";
import AppButton from "components/libs/button";
import AppSelectGroup from "components/libs/selectGroup";
import React, { FC, useCallback, useState } from "react";

//!API
import * as TOPICS_SERVICES from "api/services/topics/topics.services";

//!IMAGES
import { ReactComponent as BackIconBlack } from "assets/images/app/backBlack.svg";
import LogoClasses from "assets/images/teacher/teacher_classes.svg";

//!STYLE
import { StatusCode } from "common/enums";
import s from "./style.module.css";

type Props = {
  addCourse: (values: any) => void;
  showAddClasse: boolean;
  setShowAddClasse: React.Dispatch<React.SetStateAction<boolean>>;
  addCourseForm: FormInstance<any>;
  addCourseLoading: boolean;
};

const { Option } = Select;

const AddRoom: FC<Props> = ({
  addCourse,
  showAddClasse,
  setShowAddClasse,
  addCourseForm,
  addCourseLoading,
}) => {
  //!STATES
  const [tempTopic, setTempTopic] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [addCourseError, setAddCourseError] = useState<string>("");

  const OnSelectLevel = useCallback(
    async (levelId: string) => {
      setLoading(true);
      try {
        const response = await TOPICS_SERVICES.findAll(levelId);

        if (response?.statusCode === StatusCode.OK) {
          setTempTopic(response?.data);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [tempTopic, loading]
  );

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  return (
    <Drawer
      title={false}
      placement="right"
      width={isMobile ? "100%" : "50%"}
      onClose={() => setShowAddClasse(!showAddClasse)}
      visible={showAddClasse}
      closable={true}
      closeIcon={
        <BackIconBlack
          style={{
            width: "25px",
          }}
        />
      }
    >
      <div className={s.drawer}>
        <div className={s.drawer__content}>
          <div className={s.drawer___header}>
            <Image preview={false} src={LogoClasses} width={80} />
            <h3>Créer une classe</h3>
          </div>

          <Form
            name="create-room"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
            autoComplete="off"
            validateTrigger={["onFinish"]}
            onFinish={addCourse}
            form={addCourseForm}
          >
            <Row align="middle" style={{ marginBottom: "1em" }}>
              <Col span={7}>
                <label htmlFor="title">Nom de la classe :</label>
              </Col>
              <Col span={17}>
                <div className={s.field}>
                  <Form.Item label="" name="title">
                    <Input bordered={false} placeholder="6eme, Terminale" />
                  </Form.Item>
                </div>
              </Col>
            </Row>

            <Row align="middle" style={{ marginBottom: "1em" }}>
              <Col span={7}>
                <label htmlFor="levelId">Niveau :</label>
              </Col>
              <Col span={17}>
                <div className={s.field}>
                  <Form.Item name="levelId">
                    <AppSelectGroup
                      style={{
                        width: "24em",
                      }}
                      placeholder="Choisir un niveau"
                      onChange={(levelId: string) => OnSelectLevel(levelId)}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>

            <Row align="middle" style={{ marginBottom: "1em" }}>
              <Col span={7}>
                <label htmlFor="Matiere">matière :</label>
              </Col>
              <Col span={17}>
                <div className={s.field}>
                  <Form.Item name="courseId">
                    <Select
                      placeholder="Choisir une matière"
                      allowClear
                      bordered={false}
                      style={{
                        width: "24em",
                      }}
                      loading={loading}
                    >
                      {tempTopic?.map((topic: any) => (
                        <Option key={topic._id} value={topic._id}>
                          {topic.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
            </Row>

            {addCourseError && (
              <Form.Item>
                <Alert
                  message="Une erreur est survenue !"
                  description={addCourseError}
                  type="error"
                  closable
                />
              </Form.Item>
            )}

            <Row justify="center">
              <AppButton htmlTypeSubmit={true} loading={addCourseLoading}>
                valider
              </AppButton>
            </Row>
          </Form>
        </div>
      </div>
    </Drawer>
  );
};

export default AddRoom;
