import {
  Avatar,
  Badge,
  Drawer,
  Dropdown,
  Image,
  Menu,
  Result,
  Row,
  Tabs,
  Tooltip,
  message,
} from "antd";
import { motion } from "framer-motion";
import { FC, useCallback, useEffect, useState } from "react";

//!IMAGES
import Notif from "../header/assets/images/notif.svg";

import { findActiveModeInstaProf } from "api/services/advertissements/advertissements.services";
import { StatusCode, setIcon } from "common/enums";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "store/auth/authSlice";

//!COMPONENTS
import ModalAlertInstaProf from "components/modalAlertInstaProf";
import ModalInfoInstaProf from "components/modalInfoInstaProf";
import ModalInstaProfBadResp from "components/modalInstaProfBadResp";
import ModalSessionInfoInstaProf from "components/modalSessionInstaProf";
import DrawerInstaProfAllReq from "screens/teacher/components/DrawerInstaProfAllReq";
import DrawerInstaProfStudentAccepted from "screens/teacher/components/drawerInstaProfAcepted";
import { setIdReceiver, setSelectUserChat, toggleAppChat } from "store/AppChat/appChat";
import { formatDates } from "utils/formats";

//!STYLE
import { fetchChapter, fetchPropositionNew, fetchPropositionOld } from "api/services/courses/courses.services";
import { fetchIndicePropositionNew, fetchIndicePropositionOld, fetchSolutionIndiceExercice, fetchSolutionPropositionNew, fetchSolutionPropositionOld, setIndicePropositionAction, setPropositionAction, setSolutionPropositionAction, validateIndiceProposal, validateProposal, validateSolutionProposal } from "api/services/studentQuizz/studentQuizz.services";
import { FLAGS } from "common/enums";
import moment from "moment";
import Latex from "react-latex-next";
import { useNavigate } from "react-router-dom";
import { setIsDrawerInstaProf } from "store/chat/roomSlice";
import {
  ACTIVE_DRAWER_ALERT_INSTAPROF,
  ACTIVE_DRAWER_TEACHER_ACCEPTED,
  ACTIVE_MODAL_ALERT_INSTAPROF,
  ACTIVE_MODAL_INSTAPROF_BAD_RESP,
  ACTIVE_MODAL_INSTAPROF_SESSION,
  ACTIVE_MODE_INSTAPROF,
  INIT_MODAL,
} from "store/notification";
import { useFindRoomMutation } from "store/services/chat";
import {
  useAcceptNotificationOfInstaProfMutation,
  useGetCountNotifByUserQuery,
  useRefuseSessionInstaProfMutation,
  useResetCountNotifByUserMutation,
  useViewNotifByUserMutation
} from "store/services/teacher/notification.services";
import { setCookie } from "utils/cookies";
import s from "./style.module.css";

const { TabPane } = Tabs;

type Props = {
  dataNotif: any;
  isLoading: boolean;
  refetch: () => void;
};

const Notification: FC<Props> = ({ dataNotif, isLoading, refetch }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, currentStudentInstaProf } = useSelector((state) => state.auth);
  const { socket } = useSelector((state) => state.appChat);
  const {
    openModalSession,
    openModalAlertInstaProf,
    openDrawerAlertInstaProf,
  } = useSelector((state) => state.notifications);

  const { data: dataCountNotif, refetch: coutRefecth } =
    useGetCountNotifByUserQuery(user._id);

  const [findRoom, { isSuccess }] = useFindRoomMutation();
  const [
    viewNotifByUser,
    { data: viewData, isLoading: isLoadingView, isSuccess: isSuccessView },
  ] = useViewNotifByUserMutation();
  const [
    acceptNotificationOfInstaProf,
    { data: acceptNotif, isSuccess: acceptNotifIsSucces },
  ] = useAcceptNotificationOfInstaProfMutation();
  const [refuseSessionInstaProf] = useRefuseSessionInstaProfMutation();
  const [resetCountNotifByUser] = useResetCountNotifByUserMutation();

  //!STATE

  const [isCheckNotif, setIsCheckNotif] = useState<boolean>(false);
  const [notifs, setNotifs] = useState<any>([]);
  const [newNotifs, setNewNotifs] = useState<any>([]);
  const [allDataInstaProf, setAllDataInstaProf] = useState<any>();
  const [objNotifInstaProf, setObjInstaNotif] = useState<any>();
  const [currentStudentAccepted, setCurrentStudentAccepted] =
    useState<any>(null);
  const [activeSoundNotif, setActiveSoundNotif] = useState<boolean>(false);

  ///-----------------------------Tabs Notifications-----------------------------
  const [propositionNotifs, setPropositionNotifs] = useState<any>([]);
  const [currentTab, handleTabChange] = useState<string>("1");
  const [currentChapter, setCurrentChapter] = useState<any>();
  const [currentLevel, setCurrentLevel] = useState<any>();
  const [currentCourse, setCurrentCourse] = useState<any>();
  const [previousContent, setPreviousContent] = useState<any>();
  const [newContent, setNewContent] = useState<any>();
  const [idTeacher, setIdTeacher] = useState<any>();
  const [idContent, setIdContent] = useState<any>();
  const [idNotif, setIdNotif] = useState<any>();
  const [notifType, setNotifType] = useState<any>();
  const [exerciceContent, setExerciceContent] = useState<any>();

  const switchTab = async (tabIndex: string) => {
    handleTabChange(tabIndex);
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isSolutionIndiceDrawerVisible, setIsSolutionIndiceDrawerVisible] = useState(false);

  const openPorpositionDrawer = async (idOld: string, idNew: string, idChapter: string, idTeacher: string, idContent: string, idNotification: string, type: string) => {
    getOldProposition(idOld);
    getNewProposition(idNew);
    getChapterDetails(idChapter);
    setIdTeacher(idTeacher);
    setIdContent(idContent);
    setIdNotif(idNotification);
    setNotifType(type);
    setIsDrawerVisible(true);
  }

  const openSolutionPorpositionDrawer = async (idOld: string, idNew: string, idExo: string, idTeacher: string, idContent: string, idNotification: string, type: string) => {
    getOldSolutionProposition(idOld);
    getNewSolutionProposition(idNew);
    getExerciceDetails(idExo);
    setIdTeacher(idTeacher);
    setIdContent(idContent);
    setIdNotif(idNotification);
    setNotifType(type);
    setIsSolutionIndiceDrawerVisible(true);
  }

  const openIndicePorpositionDrawer = async (idOld: string, idNew: string, idExo: string, idTeacher: string, idContent: string, idNotification: string, type: string) => {
    getOldIndiceProposition(idOld);
    getNewIndiceProposition(idNew);
    getExerciceDetails(idExo);
    setIdTeacher(idTeacher);
    setIdContent(idContent);
    setIdNotif(idNotification);
    setNotifType(type);
    setIsSolutionIndiceDrawerVisible(true);
  }

  const closePorpositionDrawer = async () => {
    setIsDrawerVisible(false);
  }

  const closeSolutionIndicePorpositionDrawer = async () => {
    setIsSolutionIndiceDrawerVisible(false);
  }

  const getOldProposition = useCallback(
    async (id: string) => {
      try {
        if (id !== undefined) {
          const response = await fetchPropositionOld(id);
          if (response?.statusCode === StatusCode.OK) {
            setPreviousContent(response.data['content']);
          }
        }
      }
      catch (error) { }
    }, []
  );

  const getNewProposition = useCallback(
    async (id: string) => {
      try {
        if (id !== undefined) {
          const response = await fetchPropositionNew(id);
          if (response?.statusCode === StatusCode.OK) {
            setNewContent(response.data['content']);
          }
        }
      }
      catch (error) { }
    }, []
  );

  const getChapterDetails = useCallback(
    async (id: string) => {
      try {
        if (id !== undefined) {
          const response = await fetchChapter(id);
          if (response?.statusCode === StatusCode.OK) {
            setCurrentChapter(response.data['course']['title']);
            setCurrentLevel(response.data['course']['level']['title']);
            setCurrentCourse(response.data['title']);
          }
        }
      }
      catch (error) { }
    }, []
  );

  const getOldSolutionProposition = useCallback(
    async (id: string) => {
      try {
        if (id !== undefined) {
          const response = await fetchSolutionPropositionOld(id);
          if (response?.statusCode === StatusCode.OK) {
            setPreviousContent(response.data['content']);
          }
        }
      }
      catch (error) { }
    }, []
  );

  const getNewSolutionProposition = useCallback(
    async (id: string) => {
      try {
        if (id !== undefined) {
          const response = await fetchSolutionPropositionNew(id);
          if (response?.statusCode === StatusCode.OK) {
            setNewContent(response.data['content']);
          }
        }
      }
      catch (error) { }
    }, []
  );

  const getOldIndiceProposition = useCallback(
    async (id: string) => {
      try {
        if (id !== undefined) {
          const response = await fetchIndicePropositionOld(id);
          if (response?.statusCode === StatusCode.OK) {
            setPreviousContent(response.data['content']);
          }
        }
      }
      catch (error) { }
    }, []
  );

  const getNewIndiceProposition = useCallback(
    async (id: string) => {
      try {
        if (id !== undefined) {
          const response = await fetchIndicePropositionNew(id);
          if (response?.statusCode === StatusCode.OK) {
            setNewContent(response.data['content']);
          }
        }
      }
      catch (error) { }
    }, []
  );

  const getExerciceDetails = useCallback(
    async (id: string) => {
      try {
        if (id !== undefined) {
          const response = await fetchSolutionIndiceExercice(id);
          if (response?.statusCode === StatusCode.OK) {
            setCurrentChapter(response.data['course']['title']);
            setCurrentLevel(response.data['course']['level']['title']);
            setCurrentCourse(response.data['chapterId']['title']);
            setExerciceContent(response.data['description']);
          }
        }
      }
      catch (error) { }
    }, []
  );

  const handleValidate = async () => {
    try {
      const response = await validateProposal(
        idTeacher,
        idContent,
      );

      if (response?.statusCode === StatusCode.CREATED) {
        const successMessage = "Modifications approuvée";
        message.success(successMessage);
      }
      else {
        message.warning("Une erreur est survenue lors de la validation");
      }
    } catch (error) {
      message.error("Une erreur est survenue lors de la validation");
    } finally {
      handleNotificationAction("validated", "content_proposition");
    }
  };

  const handleSolutionIndiceValidate = async () => {
    if (notifType === "solution_proposition") {
      try {
        const response = await validateSolutionProposal(
          idTeacher,
          idContent,
        );

        if (response?.statusCode === StatusCode.CREATED) {
          const successMessage = "Modifications approuvée";
          message.success(successMessage);
        }
        else {
          message.warning("Une erreur est survenue lors de la validation");
        }
      } catch (error) {
        message.error("Une erreur est survenue lors de la validation");
      } finally {
        handleNotificationAction("validated", "solution_proposition");
      }
    }
    else {
      try {
        const response = await validateIndiceProposal(
          idTeacher,
          idContent,
        );

        if (response?.statusCode === StatusCode.CREATED) {
          const successMessage = "Modifications approuvée";
          message.success(successMessage);
        }
        else {
          message.warning("Une erreur est survenue lors de la validation");
        }
      } catch (error) {
        message.error("Une erreur est survenue lors de la validation");
      } finally {
        handleNotificationAction("validated", "indice_proposition");
      }
    }
  };

  const handleDisplayTeacherMSG = (idTeacher: string) => {
    setIsDrawerVisible(false);
    dispatch(setIsDrawerInstaProf(true));
    const finalData = {
      actor1: user?._id,
      actor2: idTeacher,
      flag: FLAGS.SINGLE_FLAG,
      type: "",
      instaprof: "",
    };
    findRoom(finalData);
    dispatch(setIdReceiver(idTeacher));
  };

  const handleNotificationAction = async (action: string, type: string) => {
    type === "content_proposition" ? setPropositionAction(idNotif, action) : type === "solution_proposition" ? setSolutionPropositionAction(idNotif, action) : setIndicePropositionAction(idNotif, action);
    const newPropositionNotifs = propositionNotifs?.data?.filter((notif: any) => notif._id !== idNotif);
    setPropositionNotifs(newPropositionNotifs);
    setIsDrawerVisible(false);
    setIsSolutionIndiceDrawerVisible(false);
  }

  useEffect(() => {
    const newPropositionNotifs = dataNotif?.data?.filter((notif: any) => (notif?.type === "content_proposition" || notif?.type === "solution_proposition" || notif?.type === "indice_proposition"));
    setPropositionNotifs(newPropositionNotifs);
  }, [dataNotif]);

  ///-----------------------------Tabs Notifications-----------------------------

  const sound = "/assets/avatars/notif.mp3";
  const audio = new Audio(sound);
  audio.loop = true;

  const showModalInfoInstaProf = () => {
    dispatch(ACTIVE_MODE_INSTAPROF(true));
    dispatch(INIT_MODAL());
  };

  const onActiveInstaProf = async (values: any) => {
    try {
      const response = await findActiveModeInstaProf(user?._id, values);
      if (response?.statusCode === StatusCode.CREATED) {
        message.success("Status InstaProf activé avec succès");
        dispatch(
          updateUser({
            ...user,
            instaProf: true,
            instaProfCost: values?.cost,
          })
        );
        dispatch(ACTIVE_MODE_INSTAPROF(false));
        dispatch(ACTIVE_DRAWER_ALERT_INSTAPROF());
      }
    } catch (error) {
      message.error("error");
    }
  };

  // useEffect(() => {
  //   setNotifs(dataNotif?.data);
  // }, [notifs, dataNotif]);

  useEffect(() => {
    socket?.on("receivedNewNotification", (newNotifResponse: any) => {
      // setNotifs((res: any) => {
      //   return [...res, newNotifResponse];
      // });
      refetch();
      coutRefecth();
      setActiveSoundNotif(true);
    });

    const filterNewNotif =
      dataNotif?.data?.filter((notif: any) => notif?.seen === false) || [];

    setNewNotifs(filterNewNotif);
    setActiveSoundNotif(false);
  }, [socket, dataNotif]);

  useEffect(() => {
    // findNotifications();
    const loopThrowInstaProf = async (dataNotifInsta: any) => {
      if (localStorage.getItem("reload")) {
        //vider localstorage
        localStorage.clear();

        await dataNotifInsta.forEach((element: any) => {
          if (!localStorage.getItem(element.data?.codeInstaProf)) {
            const bodyCode = {
              code: element.data?.code,
              codeInstaProf: element.data?.codeInstaProf,
            };
            socket.emit("acceptRequestByProf", bodyCode);

            localStorage.setItem(
              element.data?.codeInstaProf,
              element.data?.codeInstaProf
            );
          }
        });
      } else {
        if (
          dataNotifInsta.length.toString() !=
          localStorage.getItem("instaProfLength")
        ) {
          await dataNotifInsta.forEach((element: any) => {
            if (!localStorage.getItem(element.data?.codeInstaProf)) {
              const bodyCode = {
                code: element.data?.code,
                codeInstaProf: element.data?.codeInstaProf,
              };
              socket.emit("acceptRequestByProf", bodyCode);

              localStorage.setItem(
                element.data?.codeInstaProf,
                element.data?.codeInstaProf
              );
            }
          });
        }
      }
      localStorage.setItem("instaProfLength", dataNotifInsta.length.toString());
      localStorage.removeItem("reload");
    };

    const dataAllrequest =
      dataNotif?.data?.filter((notif: any) => {
        return (
          (notif.type === "insta_prof" &&
            notif?.seen === false &&
            notif.data?.etat === "in_progress") ||
          notif.data?.etat === "accepted"
        );
      }) || [];

    const firstNotifAds = dataNotif?.data?.find((notif: any) => {
      return notif?.type === "insta_prof" && notif?.data?.etat === "booked";
    });

    setObjInstaNotif(firstNotifAds);

    const checkDataInstaProf = dataAllrequest?.some((notif: any) => {
      return notif.data?.etat === "accepted";
    });

    if (acceptNotifIsSucces && checkDataInstaProf) {
      message.success("accepté avec succès");
      dispatch(ACTIVE_MODAL_INSTAPROF_SESSION());
    } else {
      if (!checkDataInstaProf) {
        if (!openDrawerAlertInstaProf) {
          if (dataAllrequest.length === 1) {
            dispatch(ACTIVE_MODAL_ALERT_INSTAPROF());
          }
        }
      }
    }

    setAllDataInstaProf(dataAllrequest);
    loopThrowInstaProf(dataAllrequest);

    dataAllrequest.forEach((element: any) => {
      const bodyCode = {
        code: element.data?.code,
        codeInstaProf: element.data?.codeInstaProf,
      };
      socket.emit("acceptRequestByProf", bodyCode);

      localStorage.setItem(
        element.data?.codeInstaProf,
        element.data?.codeInstaProf
      );
    });
  }, [dataNotif, acceptNotifIsSucces, objNotifInstaProf]);

  const onAcceptNotifInstaProf = (id: string) => {
    acceptNotificationOfInstaProf(id);
    onViewNotif(id);
    //  setModalAlertInstaProf(false);
  };

  const onViewNotif = async (id: string) => {
    if (id) {
      await viewNotifByUser(id);
    }
    if (isSuccessView) {
      console.log(viewData, "viewData");
    }
  };

  useEffect(() => {
    (async () => {
      await socket?.on("instaProfAccepted", async (res: any) => {
        setCurrentStudentAccepted(res?.data);

        if (res?.data?.profLink) {
          dispatch(ACTIVE_DRAWER_TEACHER_ACCEPTED());
          refetch();
          setCookie("currentStudentAccepted", res?.data);
        }
      });

      await socket?.on("instaProfRefused", async (res: any) => {
        if (res === "instaProf Refused") {
          dispatch(ACTIVE_MODAL_INSTAPROF_BAD_RESP());
          refetch();
          console.log(res, "instaProf Refused");
        }
      });
    })();

    (async () => {
      await socket?.on("instaProfKilled", async (res: any) => {
        if (res === "instaProf Killed") {
          dispatch(ACTIVE_MODAL_INSTAPROF_BAD_RESP());
          refetch();
        }
      });
    })();
  }, [socket, acceptNotifIsSucces, currentStudentAccepted]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setSelectUserChat(true));
      dispatch(toggleAppChat(true));
    }
  }, [isSuccess]);

  /* const handleDisplayTeacher = (idTeacher: string) => {
    const finalData = {
      actor1: user?._id,
      actor2: idTeacher,
      flag: FLAGS.SINGLE_FLAG,
      type: "",
      instaprof: "",
    };

    findRoom(finalData);
    dispatch(setIdReceiver(idTeacher));
  }; */

  const menu = (
    <Menu className={s.bodyDropDow}>
      {dataNotif?.data.length > 0 ? (
        <>
          <Tabs activeKey={currentTab} onChange={(key) => switchTab(key)} centered>
            <TabPane tab="Notifications" key="1">
              {dataNotif?.data?.map((notif: any) => (
                notif?.type !== "content_proposition" && notif?.type !== "solution_proposition" && notif?.type !== "indice_proposition" && <Menu.Item
                  key={notif?._id}
                  className={`${s.dropdow__item} ${notif?.seen && s.seen}`}
                  onClick={() => {
                    onViewNotif(notif?._id);
                    if (notif?.type === "messagerie") {
                      dispatch(toggleAppChat(true));
                    }

                    if (
                      notif?.type === "mes_annonces" &&
                      notif?.content?.includes(
                        "Nouvelle séance est réservé pour cette date"
                      )
                    ) {
                      navigate("/teacher/calendar");
                    }
                  }}
                >
                  <div
                    className={`${s.dropdown__row}`}
                    style={{
                      cursor: notif?.type === "mes_annonces" ? "pointer" : "auto",
                    }}
                  >
                    <div className="left">
                      <Avatar size={30} src={notif?.userId?.profile} />
                    </div>

                    <div className="right">
                      <div className={s.title}>
                        <h3>{notif?.title}</h3>
                      </div>

                      <div className={s.msg}>
                        <Tooltip
                          placement="top"
                          title={`${notif?.content?.split(":")[0]} : ${formatDates(
                            notif?.content?.split(":")[1]
                          )}`}
                        >
                          <p>{notif?.content?.split(":")[0]}</p>
                        </Tooltip>

                        <div style={{ display: "block" }}>
                          {!notif?.seen && notif?.type === "insta_prof" && (
                            <Row justify="end">
                              <div className={s.notif__btn}>
                                <motion.button
                                  whileHover={{ scale: 1.1 }}
                                  transition={{
                                    type: "spring",
                                    stiffness: 400,
                                    damping: 10,
                                  }}
                                  style={{
                                    backgroundColor: "#FF0000",
                                    marginRight: "8px",
                                  }}
                                  onClick={() => refuseSessionInstaProf(notif?._id)}
                                >
                                  Refuser
                                </motion.button>
                                <motion.button
                                  whileHover={{ scale: 1.1 }}
                                  transition={{
                                    type: "spring",
                                    stiffness: 400,
                                    damping: 10,
                                  }}
                                  onClick={() => onAcceptNotifInstaProf(notif?._id)}
                                >
                                  Accepter
                                </motion.button>
                              </div>
                            </Row>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Menu.Item>
              ))}
            </TabPane>
            <TabPane tab="Contributions" key="2">
              {propositionNotifs?.map((notif: any) => (
                (notif?.type === "content_proposition" || notif?.type === "solution_proposition" || notif?.type === "indice_proposition") && notif?.propositionData['action'] === "pending" && <Menu.Item
                  key={notif?._id}
                  className={`${s.dropdow__item} ${notif?.seen && s.seen}`}
                  onClick={() => {
                    onViewNotif(notif?._id);
                  }}
                >
                  <div className={s.dropdown__row} style={{ cursor: "pointer" }}>
                    <div className="left">
                      <Avatar size={30} src={notif?.userId?.profile} />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className={s.title}>
                        <h3>{notif?.title}</h3>
                      </div>

                      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                        <p>{notif?.content}</p>
                      </div>

                      <div className={s.buttonRow}>
                        <div>Reçu {moment(notif?.createdAt).fromNow()}</div>
                        <div className={s.notif__btn}>
                          <motion.button
                            whileHover={{ scale: 1.1 }}
                            transition={{
                              type: "spring",
                              stiffness: 400,
                              damping: 10,
                            }}
                            style={{
                              backgroundColor: "#FF0000",
                              marginRight: "8px",
                            }}
                            onClick={() => handleNotificationAction("ignored", notif?.type)}
                          >
                            Ignorer
                          </motion.button>
                          <motion.button
                            whileHover={{ scale: 1.1 }}
                            transition={{
                              type: "spring",
                              stiffness: 400,
                              damping: 10,
                            }}
                            onClick={() => notif?.type === "content_proposition" ? openPorpositionDrawer(notif?.propositionData['idSection'], notif?.propositionData['idPropostion'], notif?.propositionData['idChapter'], notif?.propositionData['idProf'], notif?.propositionData['idPropostion'], notif?._id, notif?.type) : notif?.type === "solution_proposition" ? openSolutionPorpositionDrawer(notif?.propositionData['idSection'], notif?.propositionData['idPropostion'], notif?.propositionData['idExo'], notif?.propositionData['idProf'], notif?.propositionData['idPropostion'], notif?._id, notif?.type) : openIndicePorpositionDrawer(notif?.propositionData['idSection'], notif?.propositionData['idPropostion'], notif?.propositionData['idExo'], notif?.propositionData['idProf'], notif?.propositionData['idPropostion'], notif?._id, notif?.type)}
                          >
                            Voir
                          </motion.button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Menu.Item>
              ))}
            </TabPane>
          </Tabs>
        </>
      ) : (
        <Result subTitle="Aucune notification" />
      )}
    </Menu>
  );

  console.log(dataCountNotif, "dataCountNotif");

  return (
    <div
      onClick={() => {
        if (dataCountNotif?.data > 0) {
          resetCountNotifByUser({ userId: user?._id });
        }
      }}
    >
      <Badge
        size="small"
        // count={isCheckNotif ? 0 : dataCountNotif?.data}
        // count={newNotifs?.length}
        count={dataCountNotif?.data}
      >
        <Dropdown
          overlay={menu}
          placement="bottomRight"
          arrow
          overlayStyle={{ right: "200px" }}
        >
          <Image preview={false} src={Notif} width={23} />
        </Dropdown>
      </Badge>

      <ModalInfoInstaProf onActiveInstaProf={onActiveInstaProf} />

      <ModalAlertInstaProf
        onViewNotif={onViewNotif}
        onAcceptNotifInstaProf={onAcceptNotifInstaProf}
        setIsCheckNotif={setIsCheckNotif}
      />

      <ModalSessionInfoInstaProf />

      <ModalInstaProfBadResp />

      <DrawerInstaProfStudentAccepted
        currentStudentAccepted={currentStudentAccepted}
      />

      <DrawerInstaProfAllReq
        dataInstaProf={allDataInstaProf}
        currentStudentAccepted={currentStudentAccepted}
        onViewNotif={onViewNotif}
        onAcceptNotifInstaProf={onAcceptNotifInstaProf}
        setCurrentStudentAccepted={setCurrentStudentAccepted}
        setIsCheckNotif={setIsCheckNotif}
        showModalInfoInstaProf={showModalInfoInstaProf}
        isLoading={isLoading}
        objNotifInstaProf={objNotifInstaProf}
        refetch={refetch}
      />

      {/* {activeSoundNotif && (
        <ReactAudioPlayer
          src={activeSoundNotif && sound}
          autoPlay={true}
          controls
          loop={true}
          style={{ display: "none" }}
          // className={s.__sound}
        />
      )} */}

      <Drawer
        placement="right"
        closable={true}
        onClose={closePorpositionDrawer}
        visible={isDrawerVisible}
        width={isMobile ? "100%" : "60%"}
        headerStyle={{ display: "flex", justifyContent: "space-between" }}
        bodyStyle={{ background: "#FFF", padding: "0px" }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className={s.notif__btn} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", paddingTop: "20px" }}>
            <motion.button
              whileHover={{ scale: 1.1 }}
              transition={{
                type: "spring",
                stiffness: 400,
                damping: 10,
              }}
              style={{
                backgroundColor: "#80B1B2",
                marginRight: "16px",
                fontSize: "1em",
                fontWeight: "normal"
              }}
              onClick={() => handleValidate()}
            >
              Valider
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.1 }}
              transition={{
                type: "spring",
                stiffness: 400,
                damping: 10,
              }}
              style={{
                backgroundColor: "red",
                marginRight: "8px",
                fontSize: "1em",
                fontWeight: "normal"
              }}
              onClick={() => handleNotificationAction("refused", notifType)}
            >
              Refuser
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.1 }}
              transition={{
                type: "spring",
                stiffness: 400,
                damping: 10,
              }}
              style={{
                backgroundColor: "#707070",
                marginLeft: "8px",
                fontSize: "1em",
                fontWeight: "normal"
              }}
              onClick={() => handleDisplayTeacherMSG(idTeacher)}
            >
              Discuter avec le prof
            </motion.button>
          </div>
          <div style={{ margin: "30px auto", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontFamily: "CartoonFont", fontSize: "1.2em" }}>
            <img src={setIcon(currentChapter)} height={30} />
            {currentChapter}
            <div style={{ height: "15px", width: "2px", background: "#C0C0C0", marginLeft: "5px", marginRight: "5px" }}></div>
            {currentLevel}
          </div>
          <div style={{ height: "50px", background: "#FAFAFA", margin: "0px auto", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", paddingLeft: "15px", paddingRight: "15px" }}>
            <span style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}>Cours : {currentCourse}</span>
          </div>
          <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between", margin: "20px auto" }}>
            <div style={{ width: "49%", height: "50%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", paddingLeft: "15px", paddingRight: "15px" }}>
              <span style={{ color: "#000", fontWeight: "bold", marginBottom: "20px" }}>Version actuelle</span>
              <div style={{ background: "#FAFAFA", padding: "20px", objectFit: "contain", width: "100%" }}>
                <Latex>
                  {previousContent
                    ? previousContent
                      ?.replaceAll("amp;", "")
                      ?.replaceAll("<br />", "")
                    : " "}
                </Latex>
                {/* <OriginalHighlighter originalText={previousContent} modifiedText={newContent} /> */}
              </div>
            </div>
            <div style={{ width: "2px", height: "500px", background: "#C0C0C0", margin: "0px auto" }}></div>
            <div style={{ width: "49%", height: "50%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", paddingLeft: "15px", paddingRight: "15px" }}>
              <span style={{ color: "#000", fontWeight: "bold", marginBottom: "20px" }}>Version améliorée</span>
              <div style={{ background: "#FAFAFA", padding: "20px", objectFit: "contain", width: "100%" }}>
                <Latex>
                  {newContent
                    ? newContent
                      ?.replaceAll("amp;", "")
                      ?.replaceAll("<br />", "")
                    : " "}
                </Latex>
                {/* <ModifiedHighlighter originalText={previousContent} modifiedText={newContent} /> */}
              </div>
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer
        placement="right"
        closable={true}
        onClose={closeSolutionIndicePorpositionDrawer}
        visible={isSolutionIndiceDrawerVisible}
        width={isMobile ? "100%" : "60%"}
        headerStyle={{ display: "flex", justifyContent: "space-between" }}
        bodyStyle={{ background: "#FFF", padding: "0px" }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className={s.notif__btn} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", paddingTop: "20px" }}>
            <motion.button
              whileHover={{ scale: 1.1 }}
              transition={{
                type: "spring",
                stiffness: 400,
                damping: 10,
              }}
              style={{
                backgroundColor: "#80B1B2",
                marginRight: "16px",
                fontSize: "1em",
                fontWeight: "normal"
              }}
              onClick={() => handleSolutionIndiceValidate()}
            >
              Valider
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.1 }}
              transition={{
                type: "spring",
                stiffness: 400,
                damping: 10,
              }}
              style={{
                backgroundColor: "red",
                marginRight: "8px",
                fontSize: "1em",
                fontWeight: "normal"
              }}
              onClick={() => handleNotificationAction("refused", notifType)}
            >
              Refuser
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.1 }}
              transition={{
                type: "spring",
                stiffness: 400,
                damping: 10,
              }}
              style={{
                backgroundColor: "#707070",
                marginLeft: "8px",
                fontSize: "1em",
                fontWeight: "normal"
              }}
              onClick={() => handleDisplayTeacherMSG(idTeacher)}
            >
              Discuter avec le prof
            </motion.button>
          </div>
          <div style={{ margin: "30px auto", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontFamily: "CartoonFont", fontSize: "1.2em" }}>
            <img src={setIcon(currentChapter)} height={30} />
            {currentChapter}
            <div style={{ height: "15px", width: "2px", background: "#C0C0C0", marginLeft: "5px", marginRight: "5px" }}></div>
            {currentLevel}
          </div>
          <div style={{ height: "50px", background: "#FAFAFA", margin: "0px auto", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", paddingLeft: "15px", paddingRight: "15px" }}>
            <span style={{ color: "#000", fontWeight: "bold", fontSize: "1em" }}>Exercice de : {currentCourse}</span>
          </div>
          <div style={{ margin: "30px auto" }}>
            <Latex>
              {exerciceContent
                ? exerciceContent
                  ?.replaceAll("amp;", "")
                  ?.replaceAll("<br />", "")
                : " "}
            </Latex>
          </div>
          <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between", margin: "40px auto" }}>
            <div style={{ width: "49%", height: "50%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", paddingLeft: "15px", paddingRight: "15px" }}>
              <span style={{ color: "#000", fontWeight: "bold", marginBottom: "20px" }}>Version actuelle</span>
              <div style={{ background: "#FAFAFA", padding: "20px", objectFit: "contain", width: "100%" }}>
                <Latex>
                  {previousContent
                    ? previousContent
                      ?.replaceAll("amp;", "")
                      ?.replaceAll("<br />", "")
                    : " "}
                </Latex>
                {/* <OriginalHighlighter originalText={previousContent} modifiedText={newContent} /> */}
              </div>
            </div>
            <div style={{ width: "2px", height: "500px", background: "#C0C0C0", margin: "0px auto" }}></div>
            <div style={{ width: "49%", height: "50%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", paddingLeft: "15px", paddingRight: "15px" }}>
              <span style={{ color: "#000", fontWeight: "bold", marginBottom: "20px" }}>Version proposée</span>
              <div style={{ background: "#FAFAFA", padding: "20px", objectFit: "contain", width: "100%" }}>
                <Latex>
                  {newContent
                    ? newContent
                      ?.replaceAll("amp;", "")
                      ?.replaceAll("<br />", "")
                    : " "}
                </Latex>
                {/* <ModifiedHighlighter originalText={previousContent} modifiedText={newContent} /> */}
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Notification;
