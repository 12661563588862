import { Button } from "antd";
import Container from "components/container";
import NavMobile from "components/navMobile";
import * as appRoutes from "config/routes.config";
import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import st from "../../screens/app/common.module.css";
import s from "./assets/headers.module.css";

interface Props { }

const WhenNotConnected: FC = () => {
  //!HOOKS

  const navigate = useNavigate();

  return (
    <header className={s.app__header}>
      <Container>
        <div className={s.nav}>
          {/* <div className={s.logo}>
            <Link to="/">
              <Image preview={false} width={140} src={logo} alt="logo" />
            </Link>
          </div> */}
          <div className={st.logoContainer}>
            <a className={st.logoTitleHeader}>MON AMI<br />ALBERT</a>
          </div>

          <NavMobile />

          <ul className={s.nav__links}>
            <li className={s.nav__item}>
              <Link to="/">Accueil</Link>
            </li>
            {/* <li className={s.nav__item}>
              <Link to="/login/teacher">
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    padding: "0 var(--mb-2)",
                  }}
                >
                  <span>Donnez cours et devenez </span> <br />
                  <span>un ami d’albert</span>
                </div>
              </Link>
            </li> */}
            <li className={s.nav__item}>
              <Link to={appRoutes.PROFESSEUR}>
                Professeur
              </Link>
            </li>
            <li className={s.nav__item}>
              <Link to={appRoutes.PARENT}>
                Parent
              </Link>
            </li>
            <li className={s.nav__item}>
              <Link to={appRoutes.ORGANISME}>
                Organisme
              </Link>
            </li>
            <li className={s.nav__item}>
              <Link to={appRoutes.STUDENT_SEARCH_BEST_TEACHER}>
                Trouver un enseignant
              </Link>
            </li>
            <li className={s.nav__item}>
              <Link to={appRoutes.STUDENT_MY_CONTENT}>Nos ressources</Link>
            </li>
            <li className={s.nav__item_button}>
              <Link to={appRoutes.LOGINS}>
                <Button className={st.newLoginButton}>
                  Se connecter
                </Button>
              </Link>
            </li>
          </ul>
        </div>
      </Container>
    </header>
  );
};

export default WhenNotConnected;
