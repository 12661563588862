import { Result } from "antd";
import React, { FC } from "react";

//!ASSETS
import { ReactComponent as AlbertNeutre } from "assets/images/albert/status/Neutre.svg";
import { ReactComponent as AlbertSad } from "assets/images/albert/status/Sad.svg";
import { ReactComponent as AlbertVeryHappy } from "assets/images/albert/status/Very happy.svg";

type Props = {
  status: "404" | "success" | "info" | "new";
  title: string | React.ReactNode;
  subTitle?: string;
  extra?: React.ReactNode;
  isMobile?: any,
};

const AppResult: FC<Props> = ({ status, title, subTitle, extra, isMobile }) => {

  const handleCheckStatus = (status: string) => {
    switch (status) {
      case "success":
        return <AlbertVeryHappy style={{ width: isMobile ? "35%" : "250px" }} />;
      case "info":
        return <AlbertNeutre style={{ width: isMobile ? "35%" : "250px" }} />;
      case "404":
        return <AlbertSad style={{ width: isMobile ? "35%" : "250px" }} />;
      case "new":
        return <AlbertNeutre style={{ width: isMobile ? "35%" : "250px" }} />;
      default:
        return null;
    }
  };
  return (
    <Result
      title={title}
      icon={handleCheckStatus(status)}
      subTitle={subTitle}
      extra={extra}
    />
  );
};

export default AppResult;
