import {
  PlusOutlined
} from "@ant-design/icons";
import { Drawer, Form, message, Popconfirm } from "antd";
import AppButton from "components/libs/button";
import { FC, useCallback, useEffect, useState } from "react";

//!STYLE
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import s from "../assets/adds.module.css";

//!ICONS
import { displayDrawer } from "store/adventissements";
import { setIsCalendarUpdated } from "store/app/appSlice";
import {
  useCreateAdMutation,
  useGetAdQuery,
  useUpadateAdMutation,
} from "store/services/teacher/ads.services";
import { translateDay } from "utils/formats";
import DrawerAdsCreneau from "./drawerAdsCreneau";

//!IMAGES
import ArrowBack from "../../../../assets/images/app/arrow_back.svg";
import ClockIcon from "../../../../assets/images/app/clock_icon_back.svg";
import CollectifIcon from "../../../../assets/images/app/collectif_icon.svg";
import DeleteIcon from "../../../../assets/images/app/delete_red_icon.svg";

import EllipseOne from "../../../../assets/images/app/ellipse_teacher_profile1.png";
import EllipseTwo from "../../../../assets/images/app/ellipse_teacher_profile2.png";
import EllipseThree from "../../../../assets/images/app/ellipse_teacher_profile3.png";
import EllipseFour from "../../../../assets/images/app/ellipse_teacher_profile4.png";

/* interface Niche {
  _id: string;
  date: string;
  level: { _id: string; title: string; levelGroup?: string }[];
  topic: { _id: string; title: string; level: string }[];
  timeRanges: { range: { hour: string; allow: boolean }[] }[];
  idCreneau: string;
} */

/* interface Data {
  _id: string;
  niches: Niche[];
} */

/* const days = [
  { name: "Monday", value: "Monday" },
  { name: "Tuesday", value: "Tuesday" },
  { name: "Wednesday", value: "Wednesday" },
  { name: "Thursday", value: "Thursday" },
  { name: "Friday", value: "Friday" },
  { name: "Saturday", value: "Saturday" },
  { name: "Sunday", value: "Sunday" },
]; */

type DayOfWeek = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';

const dayTranslations: Record<DayOfWeek, string> = {
  "Monday": "Lundi",
  "Tuesday": "Mardi",
  "Wednesday": "Mercredi",
  "Thursday": "Jeudi",
  "Friday": "Vendredi",
  "Saturday": "Samedi",
  "Sunday": "Dimanche"
};

interface Item {
  date: DayOfWeek;
  levels: any[];
  topics: any[];
  range: any[];
  type: string;
  [key: string]: any; // This allows additional properties on the item object
}

const DrawerAds: FC = () => {

  //!HOOKS
  const { t } = useTranslation();
  const { drawerVisible } = useSelector((state) => state.drawerAds);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [createAd, { isLoading, isSuccess, isError }] = useCreateAdMutation();
  const { data: crenaux } = useGetAdQuery(auth.user?._id);
  const [
    upadateAd,
    { isSuccess: isSuccessAd, isLoading: isLoadingAd, isError: isErrorAd },
  ] = useUpadateAdMutation();

  const [form] = Form.useForm();

  //!STATE
  const [openDrawerAdsCreneau, setOpenDrawerAdsCreneau] = useState<boolean>(false);
  const [keyCreneau, setKeyCrenau] = useState<string | undefined>("");
  const [allData, setAllData] = useState<any[]>([]);
  const [currCreneaux, setCurrCreneaux] = useState<any[]>([]);
  const { refetch } = useGetAdQuery(auth.user?._id);

  /* const [isDelete, setIsDelete] = useState<boolean>(false); */

  const showDrawerDrawerAdsCreneau = useCallback((key?: string) => {
    setKeyCrenau(key);
    setOpenDrawerAdsCreneau(true);
  }, []);

  const removeDuplicateRange = (myArray: any) => {
    return (
      myArray.filter((item: any, index: number, self: any) => {
        const rangeStr = JSON.stringify(item.range);
        const dateStr = item.date;

        return (
          index ===
          self.findIndex((obj: any) => {
            return (
              JSON.stringify(obj.range) === rangeStr && obj.date === dateStr
            );
          })
        );
      }) || []
    );
  };

  const days = [
    { label: "Lundi", value: "Lundi" },
    { label: "Mardi", value: "Mardi" },
    { label: "Mercredi", value: "Mercredi" },
    { label: "Jeudi", value: "Jeudi" },
    { label: "Vendredi", value: "Vendredi" },
    { label: "Samedi", value: "Samedi" },
    { label: "Dimanche", value: "Dimanche" },
  ];

  const handleAdd = (day: any) => {
    showDrawerDrawerAdsCreneau(day);
  };

  const isDataEmpty = removeDuplicateRange(allData).length <= 0;

  const onFinish = useCallback(async () => {

    if (isDataEmpty) {
      const errorMsg = "Impossible d'ajouter un créneau vide";
      message.error(errorMsg);
      throw new Error(errorMsg);
    }

    const finalData = {
      niches: removeDuplicateRange(allData)
        .reduce((acc: any, curr: any) => {
          const index = acc.findIndex((x: any) => x.date === curr.date);
          if (index > -1) {
            acc[index] = {
              ...acc[index],
              items: [...acc[index].items, curr],
            };
            return acc;
          }
          return [
            ...acc,
            {
              date: curr.date,
              items: [curr],
            },
          ];
        }, [])
        .map((niche: any) => {
          return {
            date: translateDay(niche?.date),
            grouplevels: niche?.items.map((levels: any) => levels?.grouplevels[0]),
            timeRanges: niche?.items.map((r: any) => {
              return {
                range: [...r?.range],
                type: niche?.items.map((t: any) => t?.type)[0],
              };
            }),
          };
        }),
      teacherId: auth.user._id,
    };

    if (currCreneaux?.length >= 0) {

      const updateData = {
        niches: removeDuplicateRange(allData)
          .reduce((acc: any, curr: any) => {
            const index = acc.findIndex((x: any) => x.date === curr.date);
            if (index > -1) {
              acc[index] = {
                ...acc[index],
                items: [...acc[index].items, curr],
              };
              return acc;
            }
            return [
              ...acc,
              {
                date: curr.date,
                items: [curr],
              },
            ];
          }, [])
          .map((niche: any) => {
            return {
              date: translateDay(niche?.date),
              grouplevels: niche?.items.map((levels: any) => levels?.grouplevels[0]),
              level: niche?.items.map((level: any) => level?.level?._id),
              topic: niche?.items.map((topic: any) => topic?.topic?._id),
              timeRanges: niche?.items.map((r: any) => {
                return {
                  range: [...r?.range],
                  type: niche?.items.map((t: any) => t?.type)[0],
                };
              }),
            };
          }),
        teacherId: auth.user._id,
      };

      const finalUpdateData = {
        updateData,
        calendarId: crenaux?.data[0]?._id,
      };
      upadateAd(finalUpdateData);
    } else {
      createAd(finalData);
    }
  }, [allData]);

  useEffect(() => {
    const currCreneaux = crenaux?.data[0]?.niches.map((niche: any) => {
      return {
        date: niche?.date,
        grouplevels: niche?.grouplevels?.map((l: any) => l),
        ///topic: niche?.topic?.map((t: any) => t?._id),
        timeRanges: niche?.timeRanges.map((r: any) => {
          return {
            range: [
              {
                hour: r?.range[0].hour,
              },

              { hour: r.range[r?.range.length - 1].hour },
            ],
            type: r?.type,
          };
        }),
      };
    });
    setCurrCreneaux(currCreneaux);
  }, [drawerVisible]);

  useEffect(() => {
    if (isSuccess || isSuccessAd) {
      message.success("créé avec succès !");
      setAllData([]);
      dispatch(displayDrawer(false));

      setTimeout(() => {
        dispatch(setIsCalendarUpdated(true));
      }, 3000);
    }
    if (isError || isErrorAd) {
      message.error("Une erreur inconu a été survenue");
    }
  }, [isSuccess, isError, isSuccessAd, isErrorAd]);

  const handleDelete = (index: number) => {
    let newData = [...allData];
    newData = removeDuplicateRange(newData);
    newData.splice(index, 1);
    setAllData(newData);
  };

  const renderDay = (day: any) => {
    return (
      <div key={day.value} className={s.formDayBox} style={{ zIndex: "1" }}>
        <div className={s.dayBox}>{day.label}</div>
        {removeDuplicateRange(allData)?.map((item: any, index: number) => {

          if (item.date !== day.value) {
            return null;
          }

          const levels = item?.grouplevels[0] !== "" ? item?.grouplevels[0]?.replace(/\$/g, ' - ') : "Tous les niveaux";

          return (
            <div key={index} className={s.detailsBox}>
              <div className={s.typeBox}>
                <div className={s.typeIconBox}><img src={CollectifIcon} height={12}></img></div>
                Cours
              </div>

              <div className={s.levelsBox}>{levels}</div>

              <div className={s.timeRangeDeleteBox}>
                <div className={s.timeRangeBox}>
                  <img src={ClockIcon} height={12} style={{ marginRight: "5px" }}></img>
                  De <span>{item?.range[0].hour}</span> à <span>{item?.range[1].hour}</span>
                </div>
                <img onClick={() => handleDelete(index)} src={DeleteIcon} height={20}></img>
              </div>
            </div>
          );
        })}
        <div className={s.addButton} onClick={() => handleAdd(day.value)}>
          <PlusOutlined style={{ color: "#FFF", fontWeight: 600 }} />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (crenaux?.data?.length > 0) {
      const calendarData = crenaux.data[0].niches.flatMap((item: any) => {
        // Check if timeRanges has more than one element

        const transformLevels = (levels: any[]) => {
          return levels.map((level: any) => ({
            value: level._id,  // Rename _id to value
            label: level.title,  // Rename title to label
            // Exclude levelGroup, createdAt, updatedAt, __v
          }));
        };

        const transformTopics = (topics: any[]) => {
          return topics.map((topic: any) => ({
            children: topic.title,  // Rename title to children
            key: topic._id,  // Rename _id to key
            value: topic._id,  // Rename _id to value
            // Exclude levelGroup, createdAt, updatedAt, __v
          }));
        };

        if (item.timeRanges && item.timeRanges.length > 1) {
          // Create separate entries for each timeRange
          return item.timeRanges.map((timeRange: any, index: number) => {
            // Flatten the current time range and only keep the first and last element
            const flatTimeRange = [timeRange.range[0], timeRange.range[timeRange.range.length - 1]].map((slot: any) => ({
              hour: slot.hour
              // Remove 'allow' property
            }));

            // Ensure levels and topics match the current index of timeRange
            const correspondingLevel = item.level[index % item.level.length];
            const correspondingTopic = item.topic[index % item.topic.length];

            // Build the new item with the separated time range
            return {
              ...item,
              levels: transformLevels([correspondingLevel]),   // Set the current level according to index
              topics: transformTopics([correspondingTopic]),  // Set the current topic according to index
              range: flatTimeRange,  // Add the modified time range (first and last element only)
              type: timeRange.type,  // Add the type (e.g., 'collectif' or 'individuel')
            };
          });
        } else {
          // If only one time range, just create one new item and only keep the first and last element
          const flatTimeRange = [item.timeRanges[0].range[0], item.timeRanges[0].range[item.timeRanges[0].range.length - 1]].map((slot: any) => ({
            hour: slot.hour
            // Remove 'allow' property
          }));

          return {
            ...item,
            levels: transformLevels(item.level),   // Rename level to levels
            topics: transformTopics(item.topic),  // Rename topic to topics
            range: flatTimeRange,  // Flattened time ranges (first and last element only)
            type: item.timeRanges[0].type,  // Add the type from the only timeRange
          };
        }
      });

      // Check if calendarData is defined and is an array
      if (calendarData && Array.isArray(calendarData)) {
        // Clean up the unwanted fields
        calendarData.forEach((newItem: any) => {
          delete newItem.level;
          delete newItem.topic;
          delete newItem.timeRanges;
          delete newItem.idCreneau;
        });

        // Translate date values to French
        const translatedData = calendarData.map(item => ({
          ...item,
          date: dayTranslations[item.date as DayOfWeek] || item.date
        }));

        setAllData(translatedData);
      }
    }
  }, [crenaux]);

  const [confirmVisible, setConfirmVisible] = useState(false);

  const handleClose = () => {
    const isData = removeDuplicateRange(allData).length > 0;
    if (isData) {
      setConfirmVisible(true);
    }
    else {
      dispatch(displayDrawer(false));
      setConfirmVisible(false);
    }
  };

  const handleSave = () => {
    message.success("Clique sur confirmer le planning");
    setConfirmVisible(false);
  };

  const handleDiscard = () => {
    dispatch(displayDrawer(false));
    setConfirmVisible(false);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [isXsScreen, setIsXsScreen] = useState(window.innerWidth > 767 && window.innerWidth <= 1151);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth > 1151 && window.innerWidth <= 1321);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 1321 && window.innerWidth <= 1606);
  const [isNormalScreen, setIsNormalScreen] = useState(window.innerWidth > 1606 && window.innerWidth <= 1799);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
    setIsXsScreen(window.innerWidth > 767 && window.innerWidth <= 1151);
    setIsSmallScreen(window.innerWidth > 1151 && window.innerWidth <= 1321);
    setIsMediumScreen(window.innerWidth > 1321 && window.innerWidth <= 1606);
    setIsNormalScreen(window.innerWidth > 1606 && window.innerWidth <= 1799);
  };
  window.addEventListener('resize', handleResize);

  return (
    <Drawer
      className="drawer"
      maskClosable={false}
      onClose={handleClose}
      visible={drawerVisible}
      width={isMobile ? "100%" : isXsScreen ? "100%" : isSmallScreen ? "90%" : isMediumScreen ? "85%" : isNormalScreen ? "80%" : "70%"}
      title={
        <div className={s.drawerTitle}>
          Ajouter des disponibilités
          <AppButton
            disabled={isDataEmpty}
            className={s.newMoveButton}
            /* htmlTypeSubmit={true} */
            onClick={onFinish}
            loading={isLoading || isLoadingAd}
          >
            Confirmer le planning
          </AppButton>
        </div>
      }
      headerStyle={{ border: "none", paddingTop: "50px", paddingLeft: "40px", backgroundColor: "transparent" }}
      closeIcon={
        <img
          src={ArrowBack}
          height={18}
          style={{}}
        ></img>
      }
      drawerStyle={{ backgroundColor: "#FFF", borderTopLeftRadius: "30px", borderBottomLeftRadius: "30px", boxShadow: "-3px 3px 34px #39393929" }}
    >
      {/* Popconfirm */}
      <Popconfirm
        title="Veux-tu quitter sans enregistrer les modifications ?"
        visible={confirmVisible}
        onConfirm={handleDiscard}
        onCancel={handleSave}
        okText="Oui"
        cancelText="Non"
      />

      <div>
        <img src={EllipseOne} height={76} style={{ position: "absolute", top: "75%", left: "3%", zIndex: "0" }}></img>
        <img src={EllipseTwo} height={53} style={{ position: "absolute", top: "20%", left: "4%", zIndex: "0" }}></img>
        <img src={EllipseThree} height={38} style={{ position: "absolute", top: "87%", left: "50%", zIndex: "0" }}></img>
        <img src={EllipseFour} height={52} style={{ position: "absolute", top: "70%", left: "95%", zIndex: "0" }}></img>
        <Form form={form} id="availabilityForm" name="control-hooks" /* onFinish={onFinish} */>
          <div className={s.formBox}>
            {days.map((day) => renderDay(day))}
          </div>
        </Form>
        {/* <div className={s.__teacher__MAA} /> */}
      </div>

      <DrawerAdsCreneau
        showDrawerDrawerAdsCreneau={showDrawerDrawerAdsCreneau}
        openDrawerAdsCreneau={openDrawerAdsCreneau}
        keyCreneau={keyCreneau}
        setAllData={setAllData}
        allData={allData}
        setOpenDrawerAdsCreneau={setOpenDrawerAdsCreneau}
      />
    </Drawer>
  );
};

export default DrawerAds;
