import { LoadingOutlined } from "@ant-design/icons";
import React, { FC } from "react";

import WarningIcon from "assets/images/app/warning.svg";

//!STYLES
import { Avatar, Col, Divider, Popconfirm, Row, Space } from "antd";
import Matiers from "components/matiers";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentStudentInstaProf } from "store/auth/authSlice";
import { useRefuseSessionInstaProfMutation } from "store/services/teacher/notification.services";
import s from "./assets/style.module.css";

type Props = {
  item: any;
  onViewNotif: (id: string) => void;
  setIsCheckNotif: React.Dispatch<boolean>;
  onAcceptNotifInstaProf: (id: string) => void;
};

const CardInstaProf: FC<Props> = ({
  item,
  onAcceptNotifInstaProf,
  onViewNotif,
  setIsCheckNotif,
}) => {
  //!HOOKS
  const dispatch = useDispatch();
  const [refuseSessionInstaProf, { isLoading }] =
    useRefuseSessionInstaProfMutation();
  const { socket } = useSelector((state) => state.appChat);

  return (
    <div className={s.card}>
      <Row gutter={12} align="middle" justify="space-between">
        <Col>
          <Row gutter={12} align="middle">
            <Col>
              <Space>
                <div className={s.avatar}>
                  <Avatar
                    src={item?.data?.student?.profile}
                    alt={
                      item?.data?.student?.firstname +
                      " " +
                      item?.data?.student?.lastname
                    }
                    size={35}
                    style={{ background: "var(--first-color)" }}
                  />
                </div>

                <div>
                  <h3 style={{ textTransform: "uppercase" }}>
                    {item?.data?.student?.firstname}{" "}
                    {item?.data?.student?.lastname}
                  </h3>
                </div>
              </Space>
            </Col>

            <Col>
              <Matiers
                level={item?.data?.level?.title}
                cours={item?.data?.matiere?.title}
              />
            </Col>
          </Row>
        </Col>

        <Col>
          {item?.data?.etat === "accepted" ? (
            <Space>
              <h3>
                l’Élève est entrain de parcourir <br /> votre profile{" "}
                <LoadingOutlined
                  style={{
                    marginLeft: "10px",
                    color: "var(--first-color)",
                    fontWeight: "600",
                  }}
                />
              </h3>
            </Space>
          ) : (
            <Space>
              {item?.data?.budget < item?.userId?.instaProfCost && (
                <span>
                  <img
                    src={WarningIcon}
                    alt=""
                    style={{
                      position: "relative",
                      top: "-2px",
                      marginRight: "3px",
                    }}
                  />{" "}
                  Budget Max:{" "}
                  <strong style={{ fontWeight: "600", color: "#333" }}>
                    {item?.data?.budget} Euro
                  </strong>
                </span>
              )}
              <Popconfirm
                placement="bottom"
                title={"Confirmer l'action?"}
                onConfirm={() => onAcceptNotifInstaProf(item?._id)}
                okText="Oui"
                cancelText="Non"
              >
                <button
                  className={`${s.button} ${s.accepter}`}
                  onClick={() => dispatch(setCurrentStudentInstaProf(item))}
                >
                  Accepter
                </button>
              </Popconfirm>

              <Popconfirm
                placement="bottom"
                title={"Confirmer l'action?"}
                onConfirm={async () => {
                  onViewNotif(item?._id);
                  await refuseSessionInstaProf(item?._id);
                  setIsCheckNotif(true);

                  localStorage.removeItem(item.data?.codeInstaProf);
                }}
                okText="Oui"
                cancelText="Non"
              >
                <button className={`${s.button} ${s.ignorer}`}>Ignorer</button>
              </Popconfirm>
            </Space>
          )}
        </Col>
      </Row>

      <div className={s.description}>
        <h3 style={{ color: "#333", marginBottom: "1em" }}>Detail :</h3>

        <p>{item?.data?.probleme}</p>
      </div>

      <Divider />
    </div>
  );
};

export default CardInstaProf;
