import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  Drawer,
  Form,
  Image,
  Input,
  Popconfirm,
  Progress,
  Row,
  Select,
  Steps,
  Tabs,
  message,
} from "antd";
import Container from "components/container";
import { FC, Fragment, useEffect, useState } from "react";

import { LogoutOutlined } from "@ant-design/icons";
import { AUTH_STUDENT, AUTH_TEACHER } from "api/services/auth/auth.api.routes";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import { StatusCode } from "common/enums";
import UploadAvatar from "components/uploadAvatar/UploadAvatar";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import fr from "react-phone-input-2/lang/fr.json";
import "react-phone-input-2/lib/material.css";
import { useDispatch, useSelector } from "react-redux";
import { closeProfileDrawer, logout } from "store/auth/authSlice";
import { clearRoom } from "store/chat/roomSlice";
import { clearRooms } from "store/chat/roomsSlice";
import BackBlack from "../../assets/images/app/backBlack.svg";

//!STYLES
import CertificationBadge from "components/certificationBadge";
import AppButton from "components/libs/button";
import UpdatePasswordDrawer from "components/updatePasswordDrawer";
import React from "react";
import ProfileTeacher from "screens/teacher/profileTeacher";
import { setCorrectingLevel } from "store/app/appSlice";
import s from "./assets/drawerProfile.module.css";
import ParentOrganismUpdateProfile from "./parentOrganism";
import UpdateProfileStepTwo from "./stepTwo";
import StudentUpdateProfile from "./student";
const { Panel } = Collapse;
const { TabPane } = Tabs;

const { Step } = Steps;
const { Option } = Select;
const getBase64 = (img: any, callback: any) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const AppDrawer: FC = () => {
  //!HOOKS
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [formInfos] = Form.useForm();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isCorrectingLevel } = useSelector((state) => state.app);

  const {
    profileDrawerOpen,
    isLoggedIn,
    user,
    isLoggingOut,
    isUpdating,
    updateErrors,
  } = useSelector((state) => state.auth);
  const levels = useSelector((state) => state.levels);
  const { socket } = useSelector((state) => state.appChat);

  //STATES
  const [isPhoneVerified, setIsPhoneVerified] = useState<boolean>(false);
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<string>("0");
  const [phoneState, setPhoneState] = useState<any>("");
  const [tempTopic, setTempTopic] = useState<any>([]);

  //!FUNCTIONS
  const closeDrawer = () => {
    setCurrentStep("0");
    dispatch(setCorrectingLevel(false));
    dispatch(closeProfileDrawer());
  };
  const disconnect = async () => {
    await socket.emit("unsubscribe", user?._id);
    socket?.disconnect();
    closeDrawer();
    dispatch(clearRoom());
    dispatch(clearRooms());
    dispatch(logout());
    localStorage.clear();
  };

  const updateUserInfos = async (values: any) => {

    console.log("Entered");

    setLoader(true);
    const finalData = {
      email: values?.email,
      phone: values?.phone,
      firstname: values?.firstname,
      lastname: values?.lastname,
    };

    console.log(finalData);

    try {
      const response = user?.roles.includes("teacher")
        ? await AUTH_SERVICES.updateTeacher(user?._id, {
          ...finalData,
        })
        : user?.roles.includes("student") ? await AUTH_SERVICES.updateStudent(user?._id, {
          ...finalData,
        }) : await AUTH_SERVICES.updateParentOrganisme(user?._id, {
          ...finalData,
        });
      if (response?.statusCode === StatusCode.OK) {
        message.success({
          content: "Modification effectuée ",
        });
      } else {
        message.warning({
          content: "une erreur est survenue",
        });
      }
    } catch (error: any) {
      let content = "";
      for (
        let index = 0;
        index < error?.response?.data?.message?.length;
        index++
      ) {
        content = error?.response?.data?.message[index] + ", " + content;
      }
      message.error({
        content: content,
      });
    } finally {
      setLoader(false);
    }
  };

  //!EFFECTS
  useEffect(() => {
    if (user) {
      formInfos.setFieldsValue({
        email: user?.email || "",
        phone: user?.phone,
        firstname: user?.firstname || "",
        lastname: user?.lastname || "",
      });

      form.setFieldsValue({
        career: user?.career || "",
        education: user?.education || "",
        experience: user?.experience || "",
      });
      setIsPhoneVerified(!!user?.phone || false);
      setIsEmailVerified(user?.emailVerified || false);
    }
    console.log(user?.levelId);
  }, [user, formInfos, form]);

  useEffect(() => {
    if (isCorrectingLevel) {
      setCurrentStep("2");
    }
    else {
      setCurrentStep("0");
    }
  }, [isCorrectingLevel]);

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(
    user?.profile
      ? user.profile
      : `https://gravatar.com/avatar/${user?._id}?d=identicon`
  );

  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }

    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url: any) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  const [width, setwidth] = useState<string>();
  const smallDevices = window.matchMedia("only screen and (max-width: 992px)");
  const mediumDevices = window.matchMedia("only screen and (min-width: 992px)");

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const s_videoSizeController = (x: any) => {
    if (x.matches) {
      setwidth("100%");
    }
  };

  const m_videoSizeController = (x: any) => {
    if (x.matches) {
      setwidth("78%");
    }
  };

  // !USE_EFFECT
  useEffect(() => {
    s_videoSizeController(smallDevices);
    smallDevices.addListener(s_videoSizeController);
    m_videoSizeController(mediumDevices);
    mediumDevices.addListener(m_videoSizeController);
  }, []);

  const formRef = React.createRef<HTMLFormElement>();

  const handleCustomSubmit = () => {
    if (formRef.current) {
      setIsLoading(true);
      formRef.current.submit();
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);
    }
  };

  const [passwordDrawerVisible, setPasswordDrawerVisible] = useState(false);
  const onClosePasswordDrawer = () => {
    setPasswordDrawerVisible(false);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [isXsScreen, setIsXsScreen] = useState(window.innerWidth > 767 && window.innerWidth <= 1151);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth > 1151 && window.innerWidth <= 1321);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 1321 && window.innerWidth <= 1606);
  const [isNormalScreen, setIsNormalScreen] = useState(window.innerWidth > 1606 && window.innerWidth <= 1799);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
    setIsXsScreen(window.innerWidth > 767 && window.innerWidth <= 1151);
    setIsSmallScreen(window.innerWidth > 1151 && window.innerWidth <= 1321);
    setIsMediumScreen(window.innerWidth > 1321 && window.innerWidth <= 1606);
    setIsNormalScreen(window.innerWidth > 1606 && window.innerWidth <= 1799);
  };
  window.addEventListener('resize', handleResize);

  return (
    <div>
      <Container>
        <Drawer
          headerStyle={{ width: "35vw", position: "relative" }}
          title={false}
          closable={false}
          extra={
            <div style={{ width: 100 }}>
              <Progress percent={30} size="small" status="exception" />
            </div>
          }
          width={isMobile ? "100%" : isXsScreen ? "100%" : isSmallScreen ? "85%" : isMediumScreen ? "80%" : isNormalScreen ? "75%" : "70%"}
          height={"100%"}
          onClose={closeDrawer}
          placement={width === "100%" ? "bottom" : "right"}
          visible={profileDrawerOpen && isLoggedIn}
        >

          <div className={s.back__icon}>
            <Image src={BackBlack} width={25} preview={false} onClick={closeDrawer} style={{ cursor: "pointer" }} />
            {!isMobile && currentStep === "2" && <AppButton loading={isLoading} onClick={handleCustomSubmit} style={{ marginLeft: "10px" }}>
              Mettre à jour
            </AppButton>}
          </div>

          <div className={s.headerBox}>
            <div className={s.logoutButton}>
              <Popconfirm
                placement="left"
                title={"Tu confirmes la déconnexion ?"}
                onConfirm={disconnect}
                okText="Oui"
                cancelText="Non"
              >
                <LogoutOutlined
                  style={{
                    color: "#FA0000",
                    fontSize: "1.8em",
                  }}
                />
              </Popconfirm>
            </div>
          </div>

          <div className={s.container__profile} >
            <Row justify="center">
              <Col>
                <UploadAvatar
                  path={`${user?.roles?.includes("teacher")
                    ? AUTH_TEACHER
                    : AUTH_STUDENT
                    }/${user?._id}`}
                />
              </Col>
            </Row>

            <div className={s.pseudo}>
              <h3>
                Bienvenue {/* {t("app.welcome")} */}
                {user?.firstname ? user?.firstname : user?.lastname}
                {user?.roles?.includes("teacher") && user?.certified && <a style={{ position: "relative", bottom: "5px" }}><CertificationBadge /></a>}
              </h3>

              {user?.pseudo && (
                <h4
                  style={{
                    fontFamily: "var(--body-font)",
                    fontSize: "14px",
                    color: "var(--text-color)",
                  }}
                >
                  Mon pseudo : {user?.pseudo}
                </h4>
              )}
            </div>

            {user?.roles.includes("student") && (
              <Fragment>
                <div className={s.info}>
                  <p>Complète ton profil</p>
                </div>
                <div
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "100%" : "55%",
                    margin: "auto",
                  }}
                >
                  <StudentUpdateProfile
                    isEmailVerified={isEmailVerified}
                    isPhoneVerified={isPhoneVerified}
                    isUpdating={isUpdating}
                    formInfos={formInfos}
                    updateUserInfos={updateUserInfos}
                  />
                </div>
              </Fragment>
            )}

            {user?.roles.includes("parent") && (
              <Fragment>
                <div className={s.info}>
                  <p>Mon profil</p>
                </div>
                <div
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "100%" : "55%",
                    margin: "auto",
                  }}
                >
                  <ParentOrganismUpdateProfile
                    isEmailVerified={isEmailVerified}
                    isPhoneVerified={isPhoneVerified}
                    isUpdating={isUpdating}
                    formInfos={formInfos}
                    updateUserInfos={updateUserInfos}
                  />
                </div>
              </Fragment>
            )}

            {user?.roles.includes("organisme") && (
              <Fragment>
                <div className={s.info}>
                  <p>Mon profil</p>
                </div>
                <div
                  style={{
                    width: "100%",
                    maxWidth: isMobile ? "100%" : "55%",
                    margin: "auto",
                  }}
                >
                  <ParentOrganismUpdateProfile
                    isEmailVerified={isEmailVerified}
                    isPhoneVerified={isPhoneVerified}
                    isUpdating={isUpdating}
                    formInfos={formInfos}
                    updateUserInfos={updateUserInfos}
                  />
                </div>
              </Fragment>
            )}

            {user?.roles.includes("teacher") && (
              <div
                style={{
                  width: "100%",
                  maxWidth: `${currentStep == "1" && !isMobile ? "55%" : "100%"}`,
                  margin: "auto",
                }}
              >
                <Tabs
                  activeKey={currentStep}
                  centered
                  type="line"
                  onChange={(activeKey: string) => setCurrentStep(activeKey)}
                >
                  <TabPane tab={<div className={currentStep == "0" ? s.tabTitleBoxActive : s.tabTitleBox}>Mon profil</div>} key="0">
                    <ProfileTeacher
                      displayBreadcrumbs={false}
                      displayButtonProfile={profileDrawerOpen}
                    />
                  </TabPane>
                  <TabPane tab={<div className={currentStep == "1" ? s.tabTitleBoxActive : s.tabTitleBox}>Informations personnelles</div>} key="1">
                    <Form form={formInfos} onFinish={updateUserInfos}>
                      <Form.Item
                        hasFeedback
                        validateStatus={isEmailVerified ? "success" : "warning"}
                        // help={isEmailVerified ? "Email validé" : "Email non validé"}
                        label={null}
                        name="email"
                        rules={[
                          { required: true, message: "Champ obligatoire" },
                        ]}
                        style={{ margin: 0, padding: 0 }}
                      >
                        <Input
                          size="large"
                          className={s.field}
                          placeholder={t("auth.email")}
                          readOnly={true}
                        />
                      </Form.Item>

                      <Form.Item
                        hasFeedback
                        validateStatus={isPhoneVerified ? "success" : "warning"}
                        help={
                          isPhoneVerified ? null : t("auth.phoneNotVerified")
                        }
                        label={null}
                        name="phone"
                        rules={[
                          { required: true, message: "Champ obligatoire" },
                        ]}
                        style={{
                          margin: 0,
                          padding: 0,
                          marginBottom: "15px",
                        }}
                      >
                        <PhoneInput
                          inputProps={{
                            name: "phone",
                            required: true,
                            autoFocus: true,

                            className: `ant-input ant-input-lg ${s.field}`,
                            style: {
                              paddingLeft: "50px",
                            },
                          }}
                          localization={fr}
                          enableSearch
                          specialLabel=""
                          country={"fr"}
                        />
                      </Form.Item>

                      <Form.Item
                        hasFeedback
                        validateStatus={
                          !!user?.firstname ? "success" : "warning"
                        }
                        help={!!user?.firstname ? "" : "Prénom non validé"}
                        label={null}
                        name="firstname"
                        rules={[
                          { required: true, message: "Champ obligatoire" },
                        ]}
                        style={{ margin: 0, padding: 0 }}
                      >
                        <Input
                          className={s.field}
                          size="large"
                          placeholder={t("auth.firstname")}
                        />
                      </Form.Item>

                      <Form.Item
                        hasFeedback
                        validateStatus={
                          !!user?.lastname ? "success" : "warning"
                        }
                        help={!!user?.lastname ? "" : "Nom non validé"}
                        name="lastname"
                        rules={[
                          { required: true, message: "Champ obligatoire" },
                        ]}
                        style={{ margin: 0, padding: 0 }}
                      >
                        <Input
                          size="large"
                          className={s.field}
                          bordered={false}
                          placeholder={t("auth.lastname")}
                        />
                      </Form.Item>

                      <Form.Item style={{ margin: 0, padding: 0 }}>
                        <Row gutter={20} justify="center">
                          <div
                            style={{
                              width: "50%",
                              marginLeft: "auto",
                              marginRight: "auto",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              htmlType="submit"
                              block
                              type="primary"
                              size="middle"
                              loading={isUpdating}
                              className={s["button"]}
                            >
                              Mettre à jour
                            </Button>
                          </div>
                        </Row>
                      </Form.Item>
                    </Form>
                    <div style={{ margin: "20px auto", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <AppButton loading={isLoading} htmlTypeSubmit={false} onClick={() => setPasswordDrawerVisible(true)}>Modifier le mot de passe</AppButton>
                    </div>
                  </TabPane>

                  <TabPane tab={<div className={currentStep == "2" ? s.tabTitleBoxActive : s.tabTitleBox}>Niveaux et à propos</div>} key="2">
                    <UpdateProfileStepTwo formRef={formRef} />
                  </TabPane>
                </Tabs>
              </div>
            )}
          </div>
          {!user?.roles?.includes("teacher") && <div style={{ margin: "20px auto", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <AppButton loading={isLoading} htmlTypeSubmit={false} onClick={() => setPasswordDrawerVisible(true)}>Modifier le mot de passe</AppButton>
          </div>}
          <UpdatePasswordDrawer visible={passwordDrawerVisible} onClose={onClosePasswordDrawer} />
        </Drawer>
      </Container>
    </div>
  );
};
