import Container from "components/container";
import { FC } from "react";

//!STYLES
import Breadcrumbs from "components/breadcrumbs";
import AnimatedPage from "utils/animatedPage";

const Privacy: FC = () => {

    return (
        <AnimatedPage>
            <Breadcrumbs />
            <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                <Container>
                    <p style={{ fontWeight: "bold" }}>Privacy Policy for Mon Ami Albert</p>
                    <p>This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from https://monamialbert.com (the "Site").</p>
                    <p style={{ fontWeight: "bold" }}>Personal Information We Collect</p>
                    <p>When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as "Device Information."</p>
                    <p>We collect Device Information using the following technologies:</p>
                    <p> - "Cookies" are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.</p>
                    <p> - "Log files" track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</p>
                    <p> - "Web beacons," "tags," and "pixels" are electronic files used to record information about how you browse the Site.</p>
                    <p style={{ fontWeight: "bold" }}>Sharing Your Personal Information</p>
                    <p>We aren't sharing your personal informations with third party. We also use Google Analytics to help us understand how our customers use the Site--you can read more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.</p>
                    <p>Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p>
                    <p style={{ fontWeight: "bold" }}>Changes</p>
                    <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</p>
                    <p style={{ fontWeight: "bold" }}>Contact Us</p>
                    <p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at hello@dba.ma.</p>
                </Container>
            </div>
        </AnimatedPage>
    );
};

export default Privacy;