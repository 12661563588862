import {
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Col, Drawer, Image, Row, Skeleton } from "antd";
import "katex/dist/katex.min.css";
import React, { useEffect, useState } from "react";
import Latex from "react-latex-next";
import { useNavigate, useParams } from "react-router-dom";

//!COMPONENTS
import Breadcrumbs from "components/breadcrumbs";
import AppButton from "components/libs/button";
import H2 from "components/libs/subtitle";
import AppScreen from "screens/teacher/ScreenWithSidebar";

//!STYLE
import s from "./assets/exercice.module.css";

//!IMAGES
import { ReactComponent as Back } from "../../../assets/images/app/backBlack.svg";
import Check from "../../../assets/images/app/check.svg";

//!HOOK
import { UserRoles } from "common/enums";
import AddSolutionIndiceDrawer from "components/articleEditor/addSolutionIndiceDrawer";
import useStudentExercice from "hooks/useStudentExercice.hook";
import useStudentExerciceClues from "hooks/useStudentExoClues.hook";
import useStudentExerciceSolution from "hooks/useStudentExoSolution.hook";
import { useSelector } from "react-redux";

//

const TeacherGetExercice = () => {
  //!HOOK
  const { id } = useParams();
  const navigate = useNavigate();
  const { exercice, loading, error } = useStudentExercice(id!);
  const { clues, loadingClues } = useStudentExerciceClues(id!);
  const { solutions, loadingSolution } = useStudentExerciceSolution(id!);

  //!STATE
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const [drawerIndice, setDrawerIndice] = useState<boolean>(false);
  const [drawerCorrige, setDrawerCorrige] = useState<boolean>(false);
  const [isSolutionIndiceDrawerVisible, setIsSolutionIndiceDrawerVisible] = useState(false);
  const [drawerType, setDrawerType] = useState<string>('');

  //!FUNCTION
  const showDrawerIndice = () => setDrawerIndice(!drawerIndice);
  const showDrawerCorrige = () => setDrawerCorrige(!drawerCorrige);

  const handleOpenSolutionIndiceDrawer = (type: string) => {
    setDrawerType(type);
  }

  const handleSolutionIndiceDrawerClose = () => {
    setDrawerType('');
    setIsSolutionIndiceDrawerVisible(false);
  };

  useEffect(() => {
    if (drawerType !== '') {
      setDrawerIndice(false);
      setDrawerCorrige(false);
      setIsSolutionIndiceDrawerVisible(true);
    }
  }, [drawerType, exercice]);

  return (
    <React.Fragment>
      <Breadcrumbs title={exercice?.title} />

      <AppScreen className={s.exercice}>
        {/* <H1 className={s.title}>{exercice?.title}</H1> */}
        <div className={s.header}>
          <div className={s.left}>
            <H2 className={s.subtitle}>Exercice</H2>
          </div>

          <div className={s.center} />

          <div className={s.right}>
            <AppButton
              icon={<ArrowLeftOutlined />}
              className={s.__button__back}
              onClick={() => navigate(-1)}
            // onClick={() => navigate(appRoutes.TEACHER_EXERCICES)}
            >
              Retour à la liste des exercices
            </AppButton>
          </div>
        </div>
        <div className={s.main}>
          <H2 className={s.title__main}>{exercice?.title}</H2>
          <div className={s.letter}>
            <h2 className={s.__ennnoncer}>Énoncé de l’exercice</h2>
            {loading ? (
              <>
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
              </>
            ) : (
              <div className={s.content}>
                <Latex>
                  {exercice?.description
                    ? exercice?.description
                      ?.replaceAll("amp;", "")
                      ?.replaceAll("<br />", "")
                    : ""}
                </Latex>
              </div>
            )}
          </div>

          <Row justify="center" style={{ marginTop: "2em" }}>
            <Col>
              {" "}
              <AppButton
                onClick={showDrawerIndice}
                className={s.__button__indice}
              >
                Afficher l'indice
              </AppButton>
            </Col>
            <Col>
              {" "}
              <AppButton onClick={showDrawerCorrige}>
                {" "}
                Afficher le corriger
              </AppButton>
            </Col>
          </Row>
        </div>
      </AppScreen>

      <Drawer
        title={false}
        width={"65%"}
        closable={true}
        closeIcon={
          <Back
            style={{
              width: "20px",
            }}
          />
        }
        onClose={showDrawerIndice}
        visible={drawerIndice}
      >
        <div className={s.letter}>
          <h3 className={s.title__drawer}>Indice de l'exercice</h3>
          {UserRoles.TEACHER && <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
            <AppButton
              onClick={() => handleOpenSolutionIndiceDrawer("indice")}
              style={{ padding: "0px" }}
            >
              Proposer un indice
            </AppButton>
          </div>}
          <div className={s.__icon_drawer}>
            {/* <Image preview={false} src={Check} /> */}
            <ExclamationCircleOutlined
              style={{
                color: "#EA9610",
                fontSize: "20px",
              }}
            />
          </div>
          {!loadingClues && (
            <div className={s.content}>
              <Latex>
                {clues[0]?.title
                  ? clues[0]?.title
                    ?.replaceAll("amp;", "")
                    ?.replaceAll("<br />", "")
                  : ""}
              </Latex>
            </div>
          )}
        </div>
      </Drawer>

      {/* CORRIGE */}
      <Drawer
        title={false}
        width={"65%"}
        closable={true}
        closeIcon={
          <Back
            style={{
              width: "35px",
            }}
          />
        }
        onClose={showDrawerCorrige}
        visible={drawerCorrige}
      >
        <div className={s.letter}>
          <h3 className={s.title__drawer}>Corriger de l'exercice</h3>
          {UserRoles.TEACHER && <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
            <AppButton
              onClick={() => handleOpenSolutionIndiceDrawer("solution")}
              style={{ padding: "0px" }}
            >
              Proposer un corrigé
            </AppButton>
          </div>}
          <div className={s.__icon_drawer}>
            <Image preview={false} src={Check} />
          </div>
          {!loadingSolution && (
            <div className={s.content}>
              <Latex>
                {solutions[0]?.title
                  ? solutions[0]?.title
                    ?.replaceAll("amp;", "")
                    ?.replaceAll("<br />", "")
                  : ""}
              </Latex>
            </div>
          )}
        </div>
      </Drawer>
      <AddSolutionIndiceDrawer
        visible={isSolutionIndiceDrawerVisible}
        onClose={handleSolutionIndiceDrawerClose}
        idTeacher={user?._id}
        idExo={exercice?._id}
        idContent={drawerType === "solution" ? solutions['_id'] : clues['_id']}
        type={drawerType}
      />
    </React.Fragment>
  );
};

export default TeacherGetExercice;
