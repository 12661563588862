import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiResponse } from "common/interfaces";

import * as USER_SERVICES from "api/services/chat/user-chat/user.services";

export interface UsersState {
  loading: boolean;
  items: ApiResponse;
  errors: string[];
}

const initialState: UsersState = {
  loading: false,
  errors: [],
  items: { statusCode: 400, data: [], message: "", status: "" },
};

export const searchUserByNameOrEmail: any = createAsyncThunk(
  "users/search",
  async (yourData: { userNameOrEmail: string; roles: string[] }, thunkAPI) => {
    const { userNameOrEmail, roles } = yourData;
    try {
      const response = await USER_SERVICES.findOne(userNameOrEmail, roles);
      if (response) return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const usersSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUserError: () => initialState,
    setLoadingUsers: (state: any, action: PayloadAction<boolean>) => {
      console.log("zavi", action.payload);
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [searchUserByNameOrEmail.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [searchUserByNameOrEmail.fulfilled]: (state, action) => {
      state.loading = false;
      state.items = action.payload;
    },
    [searchUserByNameOrEmail.rejected]: (state, action) => {
      state.loading = false;
      state.errors.push(
        "Error while fetching users",
        action.payload.response.data.message
      );
    },
  },
});
export const { clearUserError, setLoadingUsers } = usersSlice.actions;

const { reducer } = usersSlice;
export default reducer;
