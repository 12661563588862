import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import Teacher from "assets/images/teacher/maa.svg";
import CurrentOneImage from "assets/images/teacher/screenShot_Rooms.png";
import CurrentThreeImage from "assets/images/teacher/screen_wizard_3.png";
import AppModal from "components/appModal";
import H2 from "components/libs/subtitle";
import H1 from "components/libs/title";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToogleWizardModal } from "store/auth/authSlice";
import s from "./wizard.module.css";

const WizardTeacher = () => {
  const { openModalWizard } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [current, setCurrent] = useState<number>(1);

  const handlePreviousStep = () => {
    if (current > 1) {
      setCurrent((prevCurrent) => prevCurrent - 1);
    }
  };

  const handleNextStep = () => {
    if (current < 3) {
      setCurrent((prevCurrent) => prevCurrent + 1);
    }
  };

  const renderCurrentImage = () => {
    switch (current) {
      case 1:
      case 2:
        return {
          img: CurrentOneImage,
          size: 700,
        };
      case 3:
        return {
          img: CurrentThreeImage,
          size: 400,
        };
      default:
        return null;
    }
  };

  return (
    <AppModal
      visible={openModalWizard}
      closeIcon={false}
      handleClose={() => null}
      closable={false}
      footer={
        <Row justify="space-between" align="middle">
          <Col span={8}></Col>
          <Col span={8}>
            <div className={s.info}>
              <div className={s.arrow} onClick={handlePreviousStep}>
                <ArrowLeftOutlined />
              </div>
              <div>
                <span>{current} / 3</span>
              </div>
              <div className={s.arrow} onClick={handleNextStep}>
                <ArrowRightOutlined />
              </div>
            </div>
          </Col>
          <Col span={8}>
            {current === 3 && (
              <span
                onClick={() => dispatch(setToogleWizardModal(false))}
                style={{ cursor: "pointer" }}
              >
                Fermer
              </span>
            )}
          </Col>
        </Row>
      }
    >
      <div className={s.title}>
        <div className={s.logo}>
          <img src={Teacher} alt="Mon ami Albert" width={70} />
        </div>
        <H1>DÉCOUVREZ MON AMI ALBERT</H1>
        <H2>
          {current === 1 && "1 - Créer votre classe et invitez vos éleves"}
          {current === 2 && "2 - Créer des challenges"}
          {current === 3 &&
            "3 - Montez des échelons et devenez super ami Albert"}
        </H2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum
          doloribus quidem molestias <br /> placeat vero aspernatur magni quod.
        </p>

        <div className={s.img}>
          <img
            src={renderCurrentImage()?.img}
            alt=""
            width={renderCurrentImage()?.size}
          />
        </div>
      </div>
    </AppModal>
  );
};

export default WizardTeacher;
