import { Col, DatePicker, Drawer, Form, Row, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";

//!STYLE
import { AppNotification } from "components/appNotification";
import AppButton from "components/libs/button";
import moment from "moment";
import { useSelector } from "react-redux";
import {
    filterHoursByCurrentTime,
    hours,
    hoursMinutes,
    hoursOnly
} from "utils/formats";
import s from "./style.module.css";

type Props = {
    setScheduleData: (data?: any) => void;
    setOpenScheduleMeetingDrawer: React.Dispatch<React.SetStateAction<boolean>>;
    /// showDrawerDrawerAdsCreneau: (key?: string) => void;
    openScheduleMeetingDrawer: boolean;
    id: string;
};

const ScheduleMeeting: React.FC<Props> = ({
    /// showDrawerDrawerAdsCreneau,
    openScheduleMeetingDrawer: openScheduleMeetingDrawer,
    setScheduleData,
    setOpenScheduleMeetingDrawer: setOpenScheduleMeetingDrawer,
    id,
}) => {
    //!HOOKS
    const [form] = Form.useForm();
    const { user } = useSelector((state) => state.auth);

    //!STATES
    const [tempTopic, setTempTopic] = useState<any>([]);
    const [topicSelected, setTopicSelected] = useState<any>([]);
    const [tempLevel, setTempLevel] = useState<any>([]);
    const closeDrawerRef = useRef<any>();

    const [selectedHour, setSelectedHour] = useState<any>("");
    const [isThirty, setIsThirty] = useState<boolean>(false);
    const [date, setDate] = useState<string>("");

    // Handle input change
    const handleChange = (value: any) => {
        console.log(value);
        setSelectedHour(value);
    };


    const onFinish = (values: any) => {
        if ((values.one === "00:00" && values.two === "23:00") || (values.one === "23:00" && values.two === "00:00")) {
            // Créneau valide
        } else if (Number(values.one.split(":")[0]) >= Number(values.two.split(":")[0])) {
            AppNotification("L'horaire du créneau n'est pas valide.", "AlbertSad");
            throw new Error("Invalid creneau");
        } else {
            // Créneau valide
        }

        const data = {
            "idReservation": id,
            "newDate": values.date._d,
            "newRange": [values.one, values.two]
        };

        setScheduleData(data);
        setOpenScheduleMeetingDrawer(false);
        form.resetFields();
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    useEffect(() => {
        if (selectedHour.toString().includes("30")) {
            setIsThirty(true);
        }
        else {
            setIsThirty(false);
        }
    }, [selectedHour]);

    return (
        <Drawer
            title={
                <div className={s.__drawer__header}>
                    <h3>Replanifier un meet</h3>
                </div>
            }
            headerStyle={{ border: "none" }}
            placement="right"
            width={isMobile ? "100%" : "50%"}
            onClose={() => setOpenScheduleMeetingDrawer(false)}
            visible={openScheduleMeetingDrawer}
        >
            <Form onFinish={onFinish} form={form}>
                <Row
                    justify="center"
                    gutter={16}
                    align="middle"
                    style={{ marginBottom: "1.5em" }}
                >
                    <Col>
                        <h3 className={s.horaire}>Date</h3>
                    </Col>
                    <Col>
                        {" "}
                        <div className={s.time__picker}>
                            <Form.Item
                                style={{ margin: 0, padding: 0 }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Ce champ est requis",
                                    },
                                ]}
                                name={"date"}
                            >
                                <DatePicker
                                    bordered={false}
                                    disabledDate={(current) => {
                                        return (
                                            moment().add(-1, "days") >= current ||
                                            moment().add(1, "month") <= current
                                        );
                                    }}
                                    style={{ width: "280px", fontSize: "10px" }}
                                    onChange={(_, date: string) => setDate(date)}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <div className={s.hoursBox}>
                    <Col>
                        <h3 className={s.horaire}>Horaire</h3>
                    </Col>
                    <Col>
                        <div className={s.time__picker}>
                            <Form.Item
                                style={{ margin: 0, padding: 0 }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Ce champ est requis",
                                    },
                                ]}
                                name={"one"}
                            >
                                <Select
                                    defaultValue="08:00"
                                    placeholder="Heures"
                                    style={{ width: 120 }}
                                    bordered={false}
                                    onChange={handleChange}
                                    options={filterHoursByCurrentTime(hours).map((hour) => {
                                        return {
                                            value: hour.value,
                                            label: hour.label,
                                        };
                                    })}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col>
                        <h3 style={{ color: "var(--first-color)" }}>à</h3>
                    </Col>
                    <Col>
                        <div className={s.time__picker}>
                            <Form.Item
                                style={{ margin: 0, padding: 0 }}
                                name={["two"]}
                                rules={[
                                    {
                                        required: true,
                                        message: "Ce champ est requis",
                                    },
                                ]}
                            >
                                {isThirty ? <Select
                                    placeholder="Heures"
                                    style={{ width: 120 }}
                                    bordered={false}
                                    options={filterHoursByCurrentTime(hoursMinutes).map((hour) => {
                                        return {
                                            value: hour.value,
                                            label: hour.label,
                                        };
                                    })}
                                /> : <Select
                                    defaultValue="09:00"
                                    placeholder="Heures"
                                    style={{ width: 120 }}
                                    bordered={false}
                                    options={filterHoursByCurrentTime(hoursOnly).map((hour) => {
                                        return {
                                            value: hour.value,
                                            label: hour.label,
                                        };
                                    })}
                                />}
                            </Form.Item>
                        </div>
                    </Col>
                </div>

                <Row justify="center">
                    <Form.Item style={{ margin: 0, padding: 0 }}>
                        <AppButton htmlTypeSubmit={true}>Valider</AppButton>
                    </Form.Item>
                </Row>
            </Form>

            {/* <button
        hidden
        onClick={() => showDrawerDrawerAdsCreneau()}
        ref={closeDrawerRef}
      ></button> */}
        </Drawer>
    );
};

export default ScheduleMeeting;
