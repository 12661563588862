import { FC, useCallback, useEffect } from "react";

//!COMPONENTS
import Breadcrumbs from "components/breadcrumbs";
import Stylo from "components/stylo";
import AppScreen from "../ScreenWithSidebar";

import { updateCoins } from "store/paiement/paiementSlice";

//!STYLE
import s from "./assets/success.module.css";

import * as AUTH_SERVICES from "api/services/auth/auth.services";
import { UserRoles } from "common/enums";
import { batch, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateUser } from "store/auth/authSlice";
import AnimatedPage from "utils/animatedPage";

const Success: FC = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const updateCoinsFunction = () => {
    const sessionId = localStorage.getItem("sessionId");
    dispatch(updateCoins({ sessionId }));
    // localStorage.removeItem("sessionId");
  };
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const isParent = user?.roles?.includes(UserRoles.PARENT);
  const isOrganism = user?.roles?.includes(UserRoles.ORGANISME);

  const updateUserData = useCallback(async () => {
    try {
      const finalUser = await AUTH_SERVICES.getUserByUid(user?.uid);
      if (finalUser?.data) {
        batch(() => {
          dispatch(updateUser(finalUser.data));
        });
      }
    } catch (error) { }
  }, []);

  useEffect(() => {
    updateUserData();
    updateCoinsFunction();
  }, []);

  return (
    <AnimatedPage>
      <Stylo />
      <Breadcrumbs />
      <AppScreen>
        <div className={s.container}>
          <div className={s.card}>
            <div
              style={{
                borderRadius: "200px",
                height: "200px",
                width: "200px",
                background: " #F8FAF5",
                margin: "0 auto",
              }}
            >
              <i className={s.italic}>✓</i>
            </div>
            <h1 className={s.title}>Succès</h1>
            <p className={s.paragraph}>
              Nous avons bien reçu votre paiement
              <br />
              Vous pouvez maintenant utiliser votre solde
            </p>
          </div>
        </div>
      </AppScreen>
    </AnimatedPage>
  );
};

export default Success;
