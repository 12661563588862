import cookie from "js-cookie";

const setCookie = (key: string, value: string) => {
  const today = new Date();
  const expirationDate = new Date(today);

  expirationDate.setDate(today.getDate() + 30);
  const expireIn = parseInt(String(expirationDate.getTime() / 1000), 10);

  if (typeof window !== "undefined") {
    cookie.set(key, value, {
      expires: expireIn,
      path: "/",
    });
  }
};

const removeCookie = (key: string) => {
  if (typeof window !== "undefined") {
    cookie.remove(key);
  }
};

const getCookieFromBrowser = (key: string) => {
  return cookie.get(key);
};

const createCookie = (name: string, value: any, minutes: number) => {
  if (minutes) {
    const date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    var expires = "; expires=" + date.toString();
  } else {
    var expires = "";
  }
  document.cookie = name + "=" + value + expires + "; path=/";
};

export { setCookie, removeCookie, getCookieFromBrowser, createCookie };
