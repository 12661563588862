import * as AUTH_SERVICES from "api/services/auth/auth.services";
import Breadcrumbs from "components/breadcrumbs";
import React, { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AppScreen from "screens/teacher/ScreenWithSidebar";

import ParentOrganismeStudentHistory from "components/ParentOrganismeStudentHistory";

const StudentHistory = () => {

    const auth = useSelector((state: RootStateOrAny) => state.auth);
    const { user } = useSelector((state) => state.auth);
    const params = useParams<"id">();
    const navigate = useNavigate();
    const location = useLocation();
    const sponsorData = location.state;

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    const [loading, setLoading] = useState<boolean>(true);
    const [statsData, setStatsData] = useState<any>([]);
    const [userData, setUserData] = useState<any>();
    const [studentId, setStudentId] = useState<any>('');
    const [page, setPage] = useState<any>("1");

    const [historyData, setHistoryData] = useState<any>([]);

    const getStudentHistoryData = async () => {
        try {
            const history = await AUTH_SERVICES.getStudentHistoryData(studentId, page);
            if (history?.data) {
                setHistoryData(history['data']['results']);
            } else {
                throw new Error("L'utilisateur n'existe pas");
            }
        } catch (error) { }
        finally {
            setLoading(false);
        }
    }

    const getStudentData = async () => {
        try {
            const finalUser = await AUTH_SERVICES.getUserByUid(params.id!);
            if (finalUser?.data) {
                setUserData(finalUser['data']);
                setStudentId(finalUser['data']['_id']);
            } else {
                throw new Error("L'utilisateur n'existe pas");
            }
        } catch (error) { }
    }

    const fetchStatsData = async () => {
        try {
            const res = await AUTH_SERVICES.getStudentStats(studentId);
            const data = res?.data?.userStatsData;

            setStatsData(data);

        } catch (error) {
            console.error('Error fetching data:', error);
        }

    };

    useEffect(() => {
        console.log(sponsorData);
        getStudentData();
    }, [params, sponsorData]);

    useEffect(() => {
        if (studentId !== '') {
            fetchStatsData();
            getStudentHistoryData();
        }
    }, [studentId]);

    return (
        <React.Fragment>
            <Breadcrumbs isStudents isBack={true} />
            <AppScreen>
                <ParentOrganismeStudentHistory userData={userData} historyData={historyData} studentId={params?.id} loading={loading} user={user} sponsorData={sponsorData} />
            </AppScreen>
        </React.Fragment>
    );

}

export default StudentHistory;