import { CopyOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import React from 'react';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import s from "./style.module.css";

type Props = {
    info: any,
}

const SharingModule: React.FC<Props> = ({ info }) => {

    const handleCopy = () => {
        const textToCopy = `${window.location.origin}/teacher/activites?id=${info._id}`;
        navigator.clipboard.writeText(textToCopy).then(() => {
            message.success('Lien copié');
        }).catch(() => {
            message.error('Erreur lors de la copie!');
        });
    };

    return (
        <div key={info?._id} className={s.sharingBox}>
            <FacebookShareButton
                url={`${window.location.origin}/teacher/activites?id=${info._id}`}
                quote={"Partager"}
                hashtag={"#monamialbert"}
                className={s.sharingButton}
                style={{ visibility: "hidden" }}
            >
                <FacebookIcon size={32} round />
            </FacebookShareButton>
            <FacebookShareButton
                url={`${window.location.origin}/teacher/activites?id=${info._id}`}
                quote={"Partager"}
                hashtag={"#monamialbert"}
                className={s.sharingButton}
            >
                <FacebookIcon size={32} round />
            </FacebookShareButton>
            <FacebookMessengerShareButton
                url={`${window.location.origin}/teacher/activites?id=${info._id}`}
                appId="214689084811920"
                className={s.sharingButton}
            >
                <FacebookMessengerIcon size={32} round />
            </FacebookMessengerShareButton>
            <TwitterShareButton
                url={`${window.location.origin}/teacher/activites?id=${info._id}`}
                title={"Partager"}
                className={s.sharingButton}
            >
                <TwitterIcon size={32} round />
            </TwitterShareButton>
            <WhatsappShareButton
                url={`${window.location.origin}/teacher/activites?id=${info._id}`}
                title={"Partager"}
                className={s.sharingButton}
                separator=":: "
            >
                <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <LinkedinShareButton
                url={`${window.location.origin}/teacher/activites?id=${info._id}`}
                className={s.sharingButton}
            >
                <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <EmailShareButton
                url={`${window.location.origin}/teacher/activites?id=${info._id}`}
                className={s.sharingButton}
                subject={"Partager"}
                body={info?.content}
            >
                <EmailIcon size={32} round />
            </EmailShareButton>
            <Button
                type="primary"
                icon={<CopyOutlined />}
                onClick={handleCopy}
                style={{ borderRadius: "50%" }}
            >
            </Button>
        </div>
    );
};

export default SharingModule;