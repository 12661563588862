import { CHOICE } from "common/enums";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Course } from "common/types";
import * as COURSES_SERVICES from "api/services/courses/courses.services";

export interface CoursesState {
  loading: boolean;
  items: Course[];
  errors: string[];
  flag: CHOICE;
  idCours: string;
}

const initialState: CoursesState = {
  loading: false,
  errors: [],
  flag: CHOICE.SAVE_AS_DRAFT,
  items: [],
  idCours: "",
};

export const findAllCourses: any = createAsyncThunk(
  "courses/findall",
  async (_, thunkAPI) => {
    try {
      const response = await COURSES_SERVICES.findAll("");
      if (response?.data) return thunkAPI.fulfillWithValue(response.data);
      return thunkAPI.rejectWithValue("No data found");
    } catch (error) {
      throw thunkAPI.rejectWithValue(error);
    }
  }
);

export const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    changeFlag: (state: any, action: any) => {
      state.flag = action.payload;
    },
    onSetIdCours: (state: any, action: any) => {
      state.idCours = action.payload;
    },
  },
  extraReducers: {
    [findAllCourses.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [findAllCourses.fulfilled]: (state, action) => {
      state.loading = false;
      state.items = action.payload;
    },
    [findAllCourses.rejected]: (state) => {
      state.loading = false;
      state.errors.push("Error while fetching topics");
    },
  },
});
export const { changeFlag, onSetIdCours } = coursesSlice.actions;

const { reducer } = coursesSlice;
export default reducer;
