import React, { FC, useCallback, useEffect } from "react";
import * as ADS_SERVICES from "api/services/advertissements/advertissements.services";
import { motion, Variants } from "framer-motion";
import {
  Image,
  Select,
  Button,
  Popconfirm,
  message,
  Dropdown,
  Menu,
  Space,
  Row,
  Col,
} from "antd";

import { DownOutlined } from "@ant-design/icons";

//!COMPONENTS
import Card from "components/card";

//!IMAGES
import Math from "../../../assets/images/teacher/iconMath.svg";
import Validate from "../../../assets/images/teacher/validate.svg";
import { ReactComponent as IconDropDown } from "../../../assets/images/app/dropDownIcon.svg";

//!STYLES
import s from "./assets/cardAds.module.css";
import AppButton from "../button";
import { Option } from "antd/lib/mentions";
import { Link } from "react-router-dom";
import { StatusCode, UserRoles } from "common/enums";
import { formatDates } from "utils/formats";
import Matiers from "components/matiers";
import MatiersAds from "screens/teacher/components/matiersAds";
import { useSelector } from "react-redux";
import { useDeleteAdMutation } from "store/services/teacher/ads.services";

type AdCardProps = {
  fetchAds?: any;
  title?: string;
  id: string;
  date?: any;
  dates?: any;
  description?: string;
  topic?: any;
  price?: number;
  index?: number;
  status?: string;
  level?: any;
  language?: any;
};

const cardVariants: Variants = {
  offscreen: {
    y: 300,
  },
  onscreen: {
    y: 50,
    rotate: -10,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

const text = "Êtes-vous sûre de vouloir supprimer cette annonce ?";

const CardAds: FC<AdCardProps> = ({
  id,
  date,
  description,
  topic,
  price,
  status,
  title,
  level,
  dates,
  language,
}) => {
  //!HOOK
  const [deleteAd, { isSuccess, isError }] = useDeleteAdMutation();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isSuccess) {
      message.info("supprimer avec succes");
    }

    if (isError) {
      message.error("Une erreur inconnue a été survenue.");
    }
  }, [isSuccess, isError]);

  const menu = (
    <Menu className={s.__down}>
      {/* <Menu.Item onClick={() => onFetchAd(id)}>Modifier</Menu.Item> */}
      <Popconfirm
        placement="topRight"
        title={text}
        onConfirm={() => deleteAd(id)}
        okText="Oui"
        cancelText="Non"
      >
        <Menu.Item>Supprimer</Menu.Item>
      </Popconfirm>
    </Menu>
  );

  // <Select
  //   defaultValue="Action"
  //   style={{ width: 120 }}
  //   // onChange={handleChange}
  //   className={s.select}
  //   clearIcon={true}
  // >
  //   <Option value="Modifier">Modifier</Option>

  //   <Option value="Supprimer">
  //     <Popconfirm
  //       placement="topRight"
  //       title={text}
  //       onConfirm={() => onConfirme(id)}
  //       okText="Oui"
  //       cancelText="Non"
  //     >
  //       Modifier
  //     </Popconfirm>
  //   </Option>
  // </Select>;

  return (
    <div className={s.card}>
      <div className={s.container}>
        <div className={s.header}>
          <div className={s.left}>
            {/* <Matiers level={level} cours={topic} /> */}
            {/* <MatiersAds
              cours={topic?.topic?.map((c: any) => (
                <span>
                  {c?.title}
                  <span>, </span>
                </span>
              ))}
              level={level?.level?.map((l: any) => (
                <span>
                  {l?.title} <span>, </span>
                </span>
              ))}
              lang={language?.language?.map((c: any) => (
                <span>
                  {c}
                  <span>, </span>
                </span>
              ))}
            /> */}
            <div className={s.date}>
              <Image preview={false} width={16} src={Validate} alt="logo" />{" "}
              <span>
                <strong>Publié</strong> le {date}
              </span>
            </div>
          </div>

          {/* <div className={s.right}>
            <div className={s.code}>
              <span>{price}€</span>
            </div>
          </div> */}
        </div>

        <div className={s.text}>
          <h3 className={s.title__ads}>{title}</h3>
          <p>{description}</p>

          <span className={s.__range__date}>
            <Row>
              <Col> Planning : </Col>
              <Col>
                {/* {" "}
                {dates?.map((d: any) => (
                  <>
                    <span style={{ marginLeft: "5px" }}> {d?.date} </span>
                    <React.Fragment>
                      {d?.timeRanges?.map((timeRange: any) => (
                        <React.Fragment>
                          de {timeRange?.range[0]} à {timeRange?.range[1]}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                    <br />
                  
                  </>
                ))} */}
              </Col>
            </Row>
          </span>
        </div>

        <div className={s.footer}>
          <div className={s.reference}>
            <span>#{id?.substring(0, 8)}</span>
          </div>

          <div className={s.action}>
            {user?.roles?.includes(UserRoles.TEACHER) && (
              <Dropdown
                className={`${s.dropdown}`}
                overlay={menu}
                placement="bottomCenter"
              >
                <Button>
                  <Space>
                    Action
                    <IconDropDown
                      style={{
                        position: "relative",
                        left: "15px",
                      }}
                    />
                  </Space>
                </Button>
              </Dropdown>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardAds;
