import { FC } from "react";

//!STYLE
import s from "./assets/cardResult.module.css";

interface Props {
  res: any;
}
const CardResult: FC<Props> = ({ res }) => {
  console.log("tabo", res);
  return (
    <div className={s.body}>
      <h4 className={s.question}>{res?.question?.title}</h4>
      <div
        className={`${s.cardResult} ${
          res?.answer?.isCorrect ? `${s.isCorrect}` : `${s.isNoCorrect}`
        }`}
      >
        <span>{res?.answer?.title}</span>
      </div>
    </div>
  );
};

export default CardResult;
