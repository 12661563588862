import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiResponse } from "common/interfaces";

import * as ROOM_SERVICES from "api/services/chat/room-chat/room.services";

export interface RoomsState {
  loading: boolean;
  items: ApiResponse;
  conversationOpacity: string;
  errors: string[];
}

const initialState: RoomsState = {
  loading: false,
  errors: [],
  items: { statusCode: 400, data: [], message: "", status: "" },
  conversationOpacity: "hidden",
};

export const getAllRooms: any = createAsyncThunk(
  "talk/getAllRooms",
  async (actor1: string, thunkAPI) => {
    try {
      const response = await ROOM_SERVICES.getAllRooms(actor1);
      if (response) return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const roomSlice = createSlice({
  name: "room",
  initialState,
  reducers: {
    clearRooms: (state: any) => {
      state = { ...state, initialState };
    },
    clearRoomsError: () => initialState,
    updateRoomsTemporary: (state: any, action: any) => {
      let roomsUpdated = state.items.data.filter((x: { talkId: string }) => {
        return x.talkId !== action.payload.talkId;
      });

      return {
        ...state,
        items: {
          ...state.items,
          data: [...roomsUpdated, action.payload].sort(function compare(a, b) {
            let dateA: any = new Date(a.updatedAt);
            let dateB: any = new Date(b.updatedAt);
            return dateB - dateA;
          }),
        },
      };
    },
    updateOpacityConversation: (state: any, action: any) => {
      state.conversationOpacity = action.payload;
    },
  },
  extraReducers: {
    [getAllRooms.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [getAllRooms.fulfilled]: (state, action) => {
      state.loading = false;
      state.items = action.payload;
    },
    [getAllRooms.rejected]: (state, action) => {
      state.loading = false;
      state.errors.push(
        "Error while fetching rooms",
        action.payload.response?.data?.message
      );
    },
  },
});
export const { clearRoomsError, updateOpacityConversation, clearRooms } =
  roomSlice.actions;
export const { updateRoomsTemporary } = roomSlice.actions;

const { reducer } = roomSlice;
export default reducer;
