import { UserRoles } from "common/enums";
import Container from "components/container";
import H1 from "components/libs/title";
import * as appRoutes from "config/routes.config";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AnimatedPage from "utils/animatedPage";
import s from "../choiceProfil/assets/home.module.css";
import TypeProfile from "../choiceProfil/components/typeProfile";

const HomeLogin: FC = () => {
  //!HOOK
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const { isLoggedIn, user } = useSelector((state) => state.auth);

  //!EFFECTS
  useEffect(() => {
    const isTeacher = user?.roles?.includes(UserRoles.TEACHER);
    const isStudent = user?.roles?.includes(UserRoles.STUDENT);
    if (isLoggedIn && isTeacher) navigate(appRoutes.TEACHER_ACTIVITIES);
    if (isLoggedIn && isStudent) navigate(appRoutes.TEACHER_ACTIVITIES);
  }, [auth.isLoggedIn, navigate]);

  return (
    <AnimatedPage>
      <div className={s.home}>
        <Container>
          <H1 className={s.title}>Mon compte</H1>
          <div className={s.home__row}>
            <TypeProfile isLogin={true} />
          </div>
        </Container>
      </div>
    </AnimatedPage>
  );
};

export default HomeLogin;
