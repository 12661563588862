import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiResponse } from "common/interfaces";

import * as TALK_SERVICES from "api/services/chat/room-chat/room.services";

export interface sendMessageState {
  loading: boolean;
  items: ApiResponse;
  errors: string[];
}

const initialState: sendMessageState = {
  loading: false,
  errors: [],
  items: { statusCode: 400, data: [], message: "", status: "" },
};
export const sendMessageAPI: any = createAsyncThunk(
  "sendMessage/sendMessage",
  async (
    yourData: {
      talkId: string;
      sender: string;
      receiver: string;
      message: string;
      flag: string;
      isChatInstaProf: boolean;
    },
    thunkAPI
  ) => {
    const { talkId, sender, receiver, message, flag, isChatInstaProf } =
      yourData;
    try {
      const response = await TALK_SERVICES.sendMessage(
        talkId,
        sender,
        receiver,
        message,
        flag,
        isChatInstaProf
      );
      if (response) return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const sendMessageSlice = createSlice({
  name: "sendMessage",
  initialState,
  reducers: { clearSendMessageError: () => initialState },
  extraReducers: {
    [sendMessageAPI.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [sendMessageAPI.fulfilled]: (state, action) => {
      state.loading = false;
      state.items = action.payload;
    },
    [sendMessageAPI.rejected]: (state, action) => {
      state.loading = false;
      state.errors.push(
        "Error while sending message",
        action.payload.response.data.message
      );
    },
  },
});
export const { clearSendMessageError } = sendMessageSlice.actions;
const { reducer } = sendMessageSlice;
export default reducer;
