// RadarChart.tsx
import React, { useState } from 'react';
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer } from 'recharts';
import s from "./style.module.css";

interface RadarChartProps {
    data: { course: string; value: any }[];
}

const RadarChartComponent: React.FC<RadarChartProps> = ({ data }) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    const limitString = (str: string, maxLength: number) => {
        return str.length > maxLength ? `${str.slice(0, maxLength)}...` : str;
    };

    return (
        <ResponsiveContainer width="100%" height={isMobile ? 250 : 310} className={s.radarContainer}>
            <RadarChart data={data}>
                <PolarGrid />
                <PolarAngleAxis dataKey="course" style={{ fontSize: isMobile ? "1.6vw" : "0.8em" }} tick={(props) => (
                    <text {...props}>
                        {limitString(props.payload.value, 15)}
                    </text>
                )} />
                <PolarRadiusAxis angle={20} domain={[0, 10]} />
                <Radar name="Courses Radar Chart" dataKey="value" fill="#8884d8" fillOpacity={0.6} />
            </RadarChart>
        </ResponsiveContainer>
    );
};

export default RadarChartComponent;
