import FadeInOnScroll from "components/FadeIn";
import FadeInUpOnScroll from "components/FadeInUp";
import { useState } from "react";
import ellipse_result from "../../../../assets/images/app/home/ellipse_need_help.svg";
import check from "../../../../assets/images/app/organisme/check.svg";
import light from "../../../../assets/images/app/organisme/light.svg";
import stats from "../../../../assets/images/app/organisme/stats.svg";
import st from "../../../app/common.module.css";
import s from "./style.module.css";

const Results = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.mainContainer}>
            <div className={s.testimoniesBox}>
                <FadeInUpOnScroll>
                    <h1 className={s.title}>Résultats attendus</h1>
                </FadeInUpOnScroll>
                <img src={ellipse_result} className={s.ellipseTestimonies}></img>
                <FadeInOnScroll>
                    {isMobile ? <p className={s.subtitle}>Chez « Mon Ami Albert », nous nous<br />concentrons sur des objectifs pédagogiques<br />clairs et mesurables</p> : <p className={s.subtitle}>Chez « Mon Ami Albert », nous nous concentrons<br />sur des objectifs pédagogiques clairs et mesurables</p>}
                </FadeInOnScroll>
                <div className={s.animatedBoxes}>
                    <div className={`${st.containerBorderedOne} ${st.scaleAnimationSmall}`}>
                        <div className={st.cornerBorderedUp} style={{ bottom: "0px", right: "-3px" }}></div>
                        <div className={st.cornerBorderedUp} style={{ top: "0px", left: "-3px" }}></div>
                        <div className={st.cornerBorderedDown} style={{ top: "-4px", left: "-4px" }}></div>
                        <div className={st.cornerBorderedDown} style={{ bottom: "-4px", right: "-3px" }}></div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                            <div className={s.squareBoxOne}>
                                <img src={light} style={{ height: isMobile ? "20px" : "auto" }}></img>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", marginLeft: isMobile ? "20px" : "0px" }}>
                                <div className={s.boxTitle}>Améliorer</div>
                                <div className={s.boxMessage}>la compréhension<br />des élèves</div>
                            </div>
                        </div>
                    </div>
                    <div className={`${st.containerBorderedTwo} ${st.scaleAnimationSmall}`}>
                        <div className={st.cornerBorderedUp} style={{ bottom: "0px", right: "-3px" }}></div>
                        <div className={st.cornerBorderedUp} style={{ top: "0px", left: "-3px" }}></div>
                        <div className={st.cornerBorderedDown} style={{ top: "-4px", left: "-4px" }}></div>
                        <div className={st.cornerBorderedDown} style={{ bottom: "-4px", right: "-3px" }}></div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                            <div className={s.squareBoxTwo}>
                                <img src={stats} style={{ height: isMobile ? "20px" : "auto" }}></img>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", marginLeft: isMobile ? "20px" : "0px" }}>
                                <div className={s.boxTitle}>Accroitre</div>
                                <div className={s.boxMessage}>leur engagement</div>
                            </div>
                        </div>
                    </div>
                    <div className={`${st.containerBorderedThree} ${st.scaleAnimationSmall}`}>
                        <div className={st.cornerBorderedUp} style={{ bottom: "0px", right: "-3px" }}></div>
                        <div className={st.cornerBorderedUp} style={{ top: "0px", left: "-3px" }}></div>
                        <div className={st.cornerBorderedDown} style={{ top: "-4px", left: "-4px" }}></div>
                        <div className={st.cornerBorderedDown} style={{ bottom: "-4px", right: "-3px" }}></div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                            <div className={s.squareBoxThree}>
                                <img src={check} style={{ height: isMobile ? "20px" : "auto" }}></img>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", marginLeft: isMobile ? "20px" : "0px" }}>
                                <div className={s.boxTitle}>Renforcer</div>
                                <div className={s.boxMessage}>leur autonomie dans<br />l’apprentissage</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Results;