import { findOneProposition } from "api/services/quizz/quizz.services";
import { useState, useEffect } from "react";

type QuizzProposition = {
  _id?: string;
  title?: string;
  description?: string;
  solutions?: any[];
  clues?: any[];
  isCorrect: boolean;
  createdAt?: Date;
  updatedAt?: Date;
};

const useQuizzProposition = (id: string, propositionId: string) => {
  //!STATE
  const [data, setData] = useState<QuizzProposition | null>(null);
  const [loading, setLoading] = useState<Boolean>(true);
  const [error, setError] = useState<string>("");

  const fetchData = async (quizzId: string, passedId: string) => {
    try {
      setLoading(true);
      const response = await findOneProposition(quizzId, passedId);
      setData(response?.data);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(id, propositionId);
  }, [id, propositionId]);

  return { data, loading, error };
};

export default useQuizzProposition;
