import { RedoOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Skeleton, Tooltip, message } from "antd";
import * as EXERCISES_API from "api/services/exercises/exercises.services";
import useFindStudentExo from "hooks/useStudentExos.hook";
import React, { FC, useCallback, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//!COMPONENTS
import Breadcrumbs from "components/breadcrumbs";
import AppScreen from "screens/teacher/ScreenWithSidebar";

//!STYLES
import HeaderComponents from "screens/student/components/headerComponents";
import TeacherHeaderstabs from "../components/TeacherHeaderstabs";
import s from "./assets/styles.module.css";
// import CardExoV2 from "../components/cardExoV2";
import { StatusCode, TypeExercices } from "common/enums";
import AppResult from "components/appResults";
import CardExoV2 from "screens/student/components/cardExoV2";

const FindExoForChapter: FC = () => {
  //!HOOK
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const submit = useRef<any>(null);
  const { id } = useParams();
  const { exercices, loading, error, setExercices, setLoading } =
    useFindStudentExo(id!);

  const filterExercices: any = useCallback(async (values: any) => {
    setLoading(true);
    try {
      const response = await EXERCISES_API.teacherFilterExercices(values);
      if (response.statusCode === StatusCode.OK) {
        setExercices(response.data);
      }
    } catch (error) {
      message.error("Une erreur inconnue à été survenue");
    } finally {
      setLoading(false);
    }
  }, []);

  const { Search } = Input;
  const { Option } = Select;

  const onReset = () => {
    form.resetFields();
    filterExercices();
  };

  const handleChange = (e: any) => {
    if (e === "tout") {
      onReset();
    }
    else {
      submit.current.click();
    }
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  return (
    <React.Fragment>
      <Breadcrumbs isMycontent={true} />
      <HeaderComponents />
      <TeacherHeaderstabs textBack="Retour aux chapitres" onRequest={() => { }} onExit={() => { }} />
      <AppScreen>
        <Form
          layout="inline"
          name="control-hooks"
          autoComplete="off"
          onFinish={filterExercices}
          form={form}
        >
          <div className={s.filter} style={{ marginTop: isMobile ? "10px" : "0px" }}>
            <div className={s.search}>
              <Form.Item name="title">
                <Search
                  onChange={() => submit.current.click()}
                  style={{ paddingTop: "3px" }}
                  bordered={false}
                  placeholder="Rechercher un exo"
                  // onSearch={onSearch}
                  enterButton
                />
              </Form.Item>
            </div>

            {!isMobile && <div style={{}}>
              <Tooltip placement="left" title={"Réinitialiser tous les filtres"}>
                <Button
                  className={!isMobile ? s.__reset__btn : s.resetButton}
                  onClick={onReset}
                  shape="circle"
                  htmlType="button"
                >
                  <RedoOutlined />
                </Button>
              </Tooltip>
            </div>}

            <div className={s.select}>
              <Form.Item name="type">
                <Select
                  placeholder="Sélectionner un type "
                  style={{ width: 190, paddingTop: "3px" }}
                  bordered={false}
                  onChange={handleChange}
                  allowClear
                >
                  <Option value={TypeExercices.ApplicationsDeCours}>
                    {TypeExercices.ApplicationsDeCours}
                  </Option>
                  <Option value={TypeExercices.Classiques}>
                    {TypeExercices.Classiques}
                  </Option>
                  <Option value={TypeExercices.Annales}>
                    {TypeExercices.Annales}
                  </Option>
                  <Option value={TypeExercices.Entraînement}>
                    {TypeExercices.Entraînement}
                  </Option>
                  <Option value={"tout"}>
                    {"Afficher tout"}
                  </Option>
                </Select>
              </Form.Item>
            </div>

            {isMobile && <div style={{}}>
              <Tooltip placement="left" title={"Réinitialiser tous les filtres"}>
                <Button
                  className={!isMobile ? s.__reset__btn : s.resetButton}
                  onClick={onReset}
                  shape="circle"
                  htmlType="button"
                >
                  <RedoOutlined />
                </Button>
              </Tooltip>
            </div>}

          </div>
          <Button
            ref={submit}
            htmlType="submit"
            className={s.__submit__hidden}
          ></Button>
        </Form>

        <div className={s.main}>
          {loading && (
            <>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </>
          )}
          {!loading && exercices?.result?.length <= 0 ? (
            <AppResult status="info" title="Aucun exercice disponible !" />
          ) : (
            <>
              {!loading && (
                <>
                  {exercices?.result?.map((exercise: any) => (
                    <CardExoV2
                      title={exercise?.title}
                      description={exercise?.description}
                      date={exercise?.updatedAt}
                      nameChapter={exercise?.chapterId?.title}
                      level={exercise?.course?.level?.title}
                      cours={exercise?.course?.title}
                      clues={exercise?.clues[0]?.title}
                      solutions={exercise?.solutions[0]?.title}
                      exercise={exercise}
                    // loading={loading}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </AppScreen>
    </React.Fragment>
  );
};

export default FindExoForChapter;
