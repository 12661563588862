import { Button, Col, Form, Input, Row } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import fr from "react-phone-input-2/lang/fr.json";
import { useSelector } from "react-redux";
import s from "./assets/drawerProfile.module.css";

interface Props {
    isPhoneVerified: boolean;
    isEmailVerified: boolean;
    isUpdating: boolean;
    formInfos: any;
    updateUserInfos: (values: any) => void;
}

const ParentOrganismUpdateProfile: FC<Props> = ({
    isPhoneVerified,
    isUpdating,
    formInfos,
    isEmailVerified,
    updateUserInfos,
}) => {
    //!HOOKS
    const { t } = useTranslation();
    const { user } = useSelector((state) => state.auth);
    return (
        <Form form={formInfos} onFinish={updateUserInfos}>

            <Form.Item
                hasFeedback
                validateStatus={isEmailVerified ? "success" : "warning"}
                label={null}
                name="email"
                rules={[{ required: true, message: "Champ obligatoire" }]}
                style={{ margin: 0, padding: 0 }}
            >
                <Input
                    size="large"
                    className={s.field}
                    placeholder={t("auth.email")}
                    readOnly={true}
                />
            </Form.Item>

            <Form.Item
                hasFeedback
                validateStatus={isPhoneVerified ? "success" : "warning"}
                help={isPhoneVerified ? null : t("auth.phoneNotVerified")}
                label={null}
                name="phone"
                rules={[{ required: true, message: "Champ obligatoire" }]}
                style={{
                    margin: 0,
                    padding: 0,
                    marginBottom: "15px",
                }}
            >
                <PhoneInput
                    inputProps={{
                        name: "phone",
                        required: true,
                        autoFocus: true,
                        className: `ant-input ant-input-lg ${s.field}`,
                        style: {
                            paddingLeft: "50px",
                        },
                    }}
                    localization={fr}
                    enableSearch
                    specialLabel=""
                    country={"fr"}
                />
            </Form.Item>

            <Form.Item
                hasFeedback
                validateStatus={!!user?.firstname ? "success" : "warning"}
                help={!!user?.firstname ? "" : "Prénom non validé"}
                label={null}
                name="firstname"
                rules={[{ required: true, message: "Champ obligatoire" }]}
                style={{ margin: 0, padding: 0 }}
            >
                <Input
                    className={s.field}
                    size="large"
                    placeholder={t("auth.firstname")}
                />
            </Form.Item>

            <Form.Item
                hasFeedback
                validateStatus={!!user?.lastname ? "success" : "warning"}
                help={!!user?.lastname ? "" : "Nom non validé"}
                name="lastname"
                rules={[{ required: true, message: "Champ obligatoire" }]}
                style={{ margin: 0, padding: 0 }}
            >
                <Input
                    size="large"
                    className={s.field}
                    bordered={false}
                    placeholder={t("auth.lastname")}
                />
            </Form.Item>

            <Form.Item style={{ margin: 0, padding: 0 }}>
                <Row gutter={20} justify="center">
                    <Col span={12}>
                        <Button
                            loading={isUpdating}
                            htmlType="submit"
                            block
                            type="primary"
                            size="large"
                            className={s["button"]}
                        >
                            Mettre à jour
                        </Button>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    );
};

export default ParentOrganismUpdateProfile;
