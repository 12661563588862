import { FormOutlined } from "@ant-design/icons";
import { Avatar, Button, Divider, Space } from "antd";
import { findLevelsById } from "api/services/levels/levels.services";
import FollowIcon from "assets/images/app/Action_Follow.svg";
import AvatarTeacher from "assets/images/teacher/teacher.svg";
import { StatusCode, UserRoles } from "common/enums";
/* import AppLatex from "components/appLatex"; */
import * as appRoutes from "config/routes.config";
import { motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import LeftDrawer from "screens/teacher/components/LeftDrawer";
import { openProfileDrawer, setIdTeacher } from "store/auth/authSlice";
import { useGetPostsCountsQuery } from "store/services/teacher/activities.services";
import FileDocument from "../../../../../assets/images/app/file_document.svg";
import badgeCert from '../../../../../assets/images/app/validation.svg';
import s from "./style.module.css";

interface Props { }

interface TeacherInfosProps {
  postsCount: any;
  docsCount: any;
}

const TeacherInfos: React.FC<TeacherInfosProps> = ({ postsCount, docsCount }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openDrawer = () => dispatch(openProfileDrawer());

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => setVisible(false);

  const { user } = useSelector((state) => state.auth);

  const { data, isLoading, isError, refetch } = useGetPostsCountsQuery(user?._id);
  const [allData, setAllData] = useState<any>({});

  const isStudent = user?.roles?.includes(UserRoles.STUDENT);
  const isTeacher = user?.roles?.includes(UserRoles.TEACHER);
  const [tempLevelSelected, setTempLevelSelected] = useState<any>(null);

  const handleButtonClick = () => {
    navigate('/teacher/dashboard#publications-section');
  };

  const getLevelById = useCallback(
    async (idLevel: any) => {
      try {
        if (idLevel) {
          const response = await findLevelsById(idLevel);
          if (response.statusCode === StatusCode.OK) {
            setTempLevelSelected(response?.data['title']);
          }
        }
      } catch (error) {
        // message.error("Une erreur non connue s'est produite.");
      }
    },
    [tempLevelSelected]
  );

  const handleProfileClick = () => {
    dispatch(setIdTeacher(user?._id));
    navigate(`${appRoutes.PROFILETEACHER}/${user?._id}/${user?.firstname ? user?.firstname : "nom"}.${user?.lastname ? user?.lastname : "complet"}`);
  }

  useEffect(() => {
    if (data?.data) {
      setAllData(data.data);
    }
    if (isStudent) {
      getLevelById(user?.levelId);
    }
  }, [data, tempLevelSelected]);

  useEffect(() => {
    console.log(user?.career);
  }, [user]);

  return (
    <>
      <div className={s.teacherInfos}>
        <div className={s.topAvatar}>
          <div className={s.avatar}>
            <Avatar
              size={70}
              src={user?.profile || AvatarTeacher}
              className={s.profile}
            />
          </div>
        </div>

        <div className={s.content}>
          <div className={s.content_top}>
            <h2>
              {user?.firstname?.split(" ")[0]} {user?.lastname?.split(" ")[0]} {user?.certified && <img
                height={20}
                width={20}
                src={badgeCert}
                alt="Badge"
                style={{ marginLeft: "5px" }}
              />}
            </h2>

            {/* Title */}
            {user?.career !== "" && <div
              style={{ textAlign: "center", marginTop: "0px" }}
              dangerouslySetInnerHTML={{ __html: user?.career?.split("<p>")[1].split("</p>")[0] }}
            />}

            {isStudent && <div onClick={openDrawer} className={s.follow} style={{ background: "white", width: "120px", padding: "5px", margin: "0px auto", cursor: "pointer", lineHeight: "0px" }}>{tempLevelSelected}</div>}

            <div className={s.follow} style={{ background: "white", width: "120px", padding: "5px", margin: isStudent ? "0px auto" : "5px auto" }}>
              <motion.img
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.9 }}
                src={FollowIcon}
                alt=""
                style={{ position: "relative", top: "-3px", left: "-2px" }}
                width={15}
              />
              <span style={{ fontWeight: "500", fontSize: "12px", color: "#393E3E" }}>{user?.followNum || 0}</span>{" "}
              <span style={{ fontWeight: "500", fontSize: "12px", color: "#393E3E" }}>Follower{`(s)`}</span>{" "}
            </div>

            {/* See Profile */}
            {isTeacher && <Button className={s.newButton} style={{ marginTop: isStudent ? "10px" : "0px" }} onClick={handleProfileClick}>Voir mon profil</Button>}

            {/* Description */}
            {user?.career?.split("<p>")[2].split("</p>")[0].length > 120 ? <div style={{ marginTop: "40px", fontSize: "12px" }}>
              <div
                style={{ whiteSpace: 'pre-wrap', textAlign: "left" }}
                dangerouslySetInnerHTML={{ __html: user?.career?.split("<p>")[2].split("</p>")[0].substring(0, 120) + "..." }}
              />
            </div> : <div style={{ marginTop: "40px", fontSize: "12px" }}>
              <div
                style={{ whiteSpace: 'pre-wrap', textAlign: "left" }}
                dangerouslySetInnerHTML={{ __html: user?.career?.split("<p>")[2].split("</p>")[0] }}
              />
            </div>}
          </div>

          {!isStudent && <div onClick={showDrawer} className={s.readMore}>Lire la suite</div>}

          <Divider />

          <div>
            <Space>
              <FormOutlined style={{ color: "var(--first-color)" }} />{" "}
              <div className={s.text_stats} onClick={handleButtonClick} style={{ cursor: "pointer" }}>
                {" "}
                {postsCount === 0 || postsCount === 1 ? (
                  <span>{postsCount} publication en ligne</span>
                ) : (
                  <span>{postsCount} publications en ligne</span>
                )}
              </div>
            </Space>
            <Space>
              <img src={FileDocument} height={15} ></img>{" "}
              <div className={s.text_stats}>
                {" "}
                {docsCount === 0 || docsCount === 1 ? (
                  <span>{docsCount} document publié</span>
                ) : (
                  <span>{docsCount} documents publiés</span>
                )}
              </div>
            </Space>
          </div>
        </div>
      </div>
      <LeftDrawer data={user?.career} visible={visible} onClose={onClose} userImage={user?.profile} userName={user?.firstname} userLastname={user?.lastname} />
    </>
  );
};

export default TeacherInfos;
