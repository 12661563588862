import * as appRoutes from "config/routes.config";
import { useRef } from "react";
import { Link } from "react-router-dom";
import s from "./style.module.css";

type Props = {};

const NavMobile = (props: Props) => {
  const menuRef = useRef<HTMLInputElement>(null);
  return (
    <div className={s.menu}>
      <input
        className={s.menu_icon}
        type="checkbox"
        id="menu_icon"
        name="menu_icon"
        ref={menuRef}
      />
      <label htmlFor={"menu_icon"}></label>
      <nav className={s.nav}>
        <ul className={s.pt_5}>
          <li onClick={() => menuRef.current?.click()}>
            <Link to="/">Accueil</Link>
          </li>
          <li onClick={() => menuRef.current?.click()}>
            <Link to={appRoutes.PROFESSEUR}>
              Professeur
            </Link>
          </li>
          <li onClick={() => menuRef.current?.click()}>
            <Link to={appRoutes.PARENT}>
              Parent
            </Link>
          </li>
          <li onClick={() => menuRef.current?.click()}>
            <Link to={appRoutes.ORGANISME}>
              Organisme
            </Link>
          </li>
          <li onClick={() => menuRef.current?.click()}>
            <Link to={appRoutes.STUDENT_SEARCH_BEST_TEACHER}>
              Trouver un enseignant
            </Link>
          </li>
          <li onClick={() => menuRef.current?.click()}>
            <Link to={appRoutes.STUDENT_MY_CONTENT}>Nos ressources</Link>
          </li>
          <li onClick={() => menuRef.current?.click()}>
            <Link to={appRoutes.LOGINS}>Se connecter / S’inscrire</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default NavMobile;
