import { FC } from "react";

//!COMPONNENT
import DateTimeDisplay from "./DateTimeDisplay";
import { useCountdown } from "hooks/useCountdown";

//!STYLES
import s from "../assets/quiz.module.css";

const ExpiredNotice: FC = () => {
  return (
    <div className={s.expired}>
      <span>Temps écoulé</span>
    </div>
  );
};

interface ShowCounterProps {
  days: any;
  hours: any;
  minutes: any;
  seconds: any;
}

const ShowCounter: FC<ShowCounterProps> = ({ minutes, seconds }) => {
  return (
    <div className={s.show__counter}>
      <DateTimeDisplay value={minutes} type={"min"} isDanger={minutes <= 1} />{" "}
      <DateTimeDisplay value={seconds} type={"sec"} isDanger={false} />
    </div>
  );
};

interface CountdownTimerProps {
  targetDate: any;
  setTimeElapsed: any;
  setIsVisble: any;
  setIsFinish: any;
  setDrawer: any;
}

const CountdownTimer: FC<CountdownTimerProps> = ({
  targetDate,
  setTimeElapsed,
  setIsVisble,
  setIsFinish,
  setDrawer,
}) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    setTimeElapsed(true);
    setIsVisble(false);
    setIsFinish(true);

    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;
