import {
  Avatar,
  Card,
  Col,
  Image,
  Popconfirm,
  Result,
  Row,
  Skeleton,
  Tooltip,
  message,
} from "antd";
import { Field, Form, Formik } from "formik";
import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { useNavigate } from "react-router-dom";

import { checkAvancement, postQuizResponses, setAvancement } from "api/services/quizz/quizz.services";

import "katex/dist/katex.min.css";
import Latex from "react-latex-next";


//!COMPONENTS
import AppButton from "components/libs/button";
import H2 from "components/libs/subtitle";
import SkillBar from "components/skillbar";
import AppScreen from "screens/teacher/ScreenWithSidebar";
import CountdownTimer from "./components/CountdownTimer";
import CardResult from "./components/cardResult";

//
import useGetQuizOfCours from "hooks/useGetQuizOfCours.hook";
import useGetQuizWithInswers from "hooks/useGetQuizzWithInswers.hook";

//!IMAGES
import AlbertHappy from "assets/images/albert/status/Happy.svg";
import AlbertNeutre from "assets/images/albert/status/Neutre.svg";
import AlbertSad from "assets/images/albert/status/Sad.svg";
import AlbertVeryHappy from "assets/images/albert/status/Very happy.svg";
import Clock from "./assets/images/clock.svg";
import Trophe from "./assets/images/trophe.svg";

//!STYLES
import { checkQuiz, findFinalQuiz, giveQuizMark } from "api/services/studentQuizz/studentQuizz.services";
import { StatusCode } from "common/enums";
import AppDrawer from "components/appDrawer";
import { AppNotification } from "components/appNotification";
import { useSendRealisationTimeMutation } from "store/services/student/quizz.services";
import s from "./assets/quiz.module.css";

import { useDispatch, useSelector } from 'react-redux';
import { updateStudent } from "store/auth/authSlice";

const StudentCoursQuiz: FC<any> = ({ id, handleShowQuizzCloseDrawer }) => {
  //!HOOK
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef(null);
  const { quiz, loading, error } = useGetQuizOfCours(id!);
  const { quizWithInswers, loadingQuery, errorQuery } = useGetQuizWithInswers(id!);
  const { user } = useSelector((state) => state.auth);
  const [sendRealisationTime, { isError, isLoading, isSuccess }] =
    useSendRealisationTimeMutation();

  //!STATES
  const [current, setCurrent] = useState<number>(0);
  const [tempIdQuestion, setTempId] = useState<string>("");
  const [progress, setProgress] = useState<any>(0);
  const [isVisible, setIsVisble] = useState<boolean>(true);
  const [isFinish, setIsFinish] = useState<boolean>(false);
  const [drawer, setDrawer] = useState<boolean>(false);
  const [response, setResponse] = useState<any>([]);
  const [responseAnswers, setResponseAnswers] = useState<any>([]);
  const [TimeElapsed, setTimeElapsed] = useState<boolean>(false);
  const [ModalResult, setModalResult] = useState<boolean>(false);
  const [validation, setValidatation] = useState<boolean>(false);

  const [quizStatus, setQuizStatus] = useState<boolean>(false);

  const [finalResultQuiz, setFinalResultQuiz] = useState<any>();
  const [_, setStartTime] = useState<Date | null>(null);
  const [elapsedTime, setElapsedTime] = useState<number>(0);

  const [quizzId, setQuizzId] = useState<any>(null);
  useEffect(() => {
    setQuizzId(id);
  }, [id]);

  //!FUNCTION

  const fetchResultQuiz = async () => {
    try {
      const res = await findFinalQuiz(user?._id, id!);
      setFinalResultQuiz(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const showDrawer = () => {
    fetchResultQuiz();
    setIsVisble(false);
    setIsFinish(true);
    setDrawer(!drawer);
  };

  const handleProgress = async () => {
    let widthProgress = 100 / quiz?.questions?.length;
    setProgress((curr: number) => curr + widthProgress);

    if (current + 1 >= quiz?.questions?.length) {

      if (!quizStatus) {
        const res = await giveQuizMark(user?._id, quiz?._id, user?.etablissement);
        const newUser = res?.data;
        await dispatch(updateStudent({
          id: user?._id, user: {
            ...newUser,
          }
        }));
      }

      showDrawer();
      setIsFinish(true);
      sendRealisationTime({
        studentId: user?._id,
        quizzId: quiz?._id,
        realisationTime: elapsedTime,
      });
      localStorage.setItem("quizTime", elapsedTime as any);

      //check if quizz have avancement
      const response4AvancementChecker = await checkAvancement(
        user?._id,
        quiz?.course?.level?._id,
        quiz?.course?._id,
        quiz?._id
      );
      if (response4AvancementChecker?.statusCode === 201) {
        if (!response4AvancementChecker?.data?.found) {
          const response4AvancementSetter = await setAvancement(
            user?._id,
            quiz?.course?.level?._id,
            quiz?.course?._id,
            quiz?._id
          );
          if (response4AvancementSetter?.statusCode === 201) {
            message.info("Avancement associé à ce quiz avec succès");
          } else {
            message.info("Erreur: avancement non associé");
          }
        }
      }
    }
  };

  const handleClick = (event: any) => {
    setValidatation(true);
  };

  const handleSubmite = useCallback(
    async (values?: any) => {
      if (quiz?.questions) {
        setTempId(quiz?.questions[current]?._id);
      }
      try {
        if (values) {
          const res = await postQuizResponses(
            id!,
            values.idQuestion || tempIdQuestion,
            delete values["idQuestion"] && values
          );

          if (res?.statusCode === StatusCode.CREATED) {
            //get the bonne response
            let bonneResponseArray: any = quizWithInswers.data.questions[current].propositions.filter((field: any) => {
              return field.isCorrect === true;
            })
            setCurrent((curr) => curr + 1);
            setValidatation(false);
            handleProgress();
            setResponse([
              ...response,
              res.data,
            ]);
            setResponseAnswers([
              ...responseAnswers,
              bonneResponseArray[0],
            ]);
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    [
      current,
      tempIdQuestion,
      handleProgress,
      progress,
      isVisible,
      response,
      TimeElapsed,
      ModalResult,
      finalResultQuiz,
      validation,
    ]
  );

  const TEMPS_QUIZZ = quiz?.duration * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();
  const dateTimeAfterThreeDays = NOW_IN_MS + TEMPS_QUIZZ;

  useEffect(() => {
    handleSubmite();
  }, [
    handleSubmite,
    current,
    tempIdQuestion,
    handleProgress,
    progress,
    isVisible,
    response,
    TimeElapsed,
    ModalResult,
    finalResultQuiz,
    validation,
  ]);

  useEffect(() => {
    setQuizzId(id);
    // Date et heure de début du quiz
    const quizStartTime = new Date();

    setStartTime(quizStartTime);
    const interval = setInterval(() => {
      const currentTimestamp = Date.now();
      const elapsed = currentTimestamp - quizStartTime.getTime();
      setElapsedTime(elapsed);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTimestamp = (timestamp: number): string => {
    const seconds = Math.floor((timestamp / 1000) % 60);
    const minutes = Math.floor((timestamp / (1000 * 60)) % 60);
    const hours = Math.floor((timestamp / (1000 * 60 * 60)) % 24);

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleConfirm = () => {
    AppNotification(
      <span>
        J’espère te revoir prochainement
        <strong style={{ textTransform: "capitalize" }}>
          {" " + user?.firstname}
        </strong>{" "}
        👋
      </span>,
      "AlbertNeutre"
    );
    handleShowQuizzCloseDrawer()
  };

  const handleCancel = () => {
    AppNotification(
      <span>
        Allez{" "}
        <strong style={{ textTransform: "capitalize" }}>
          {user?.firstname}
        </strong>{" "}
        persévère un peu d'effort 🙂
      </span>,
      "AlbertHappy"
    );
  };

  const checkQuizStatus = useCallback(
    async () => {
      try {
        const res = await checkQuiz(user?._id, quiz?._id);
        setQuizStatus(res?.data);
      } catch (error) {
        console.log(error)
      }
    }, []
  );

  useEffect(() => {
    checkQuizStatus();
  }, [quiz]);

  useEffect(() => {
    const protectedTextElement = document.getElementById('protectedText');

    const handleCopy = (event: any) => {
      // Prevent the copy event
      event.preventDefault();
      alert("La copie n'est pas autorisée");
    };

    const handlePaste = (event: any) => {
      // Prevent the paste event
      event.preventDefault();
      alert("Le collage n'est pas autorisé.");
    };

    const handleContextMenu = (event: any) => {
      // Prevent the context menu
      event.preventDefault();
      alert("Le menu contextuel n'est pas autorisé.");
    };

    if (protectedTextElement) {
      protectedTextElement.addEventListener('copy', handleCopy);
      protectedTextElement.addEventListener('paste', handlePaste);
      protectedTextElement.addEventListener('contextmenu', handleContextMenu);

      return () => {
        // Remove event listeners when the component unmounts
        protectedTextElement.removeEventListener('copy', handleCopy);
        protectedTextElement.removeEventListener('paste', handlePaste);
        protectedTextElement.removeEventListener('contextmenu', handleContextMenu);
      };
    }
  }, []);

  return (
    <>
      {quizzId !== null && (
        <React.Fragment>
          {quiz?.questions && (
            <Formik
              initialValues={{
                answer: "",
                studentId: user?._id,
                idQuestion: quiz?.questions[current]?._id,
              }}
              onSubmit={handleSubmite}
            >
              {({ values }) => (
                <AppScreen className={s.quiz}>
                  <Card loading={loading} bordered={false} id="protectedText">
                    <Form className={s.quiz__content}>
                      {loading && (
                        <div>
                          <Skeleton active />
                          <Skeleton active />
                          <Skeleton active />
                        </div>
                      )}
                      {!loading && (
                        <Fragment>
                          <Row justify="space-between">
                            <Col span={6}></Col>
                            <Col span={12}>
                              <Tooltip title={quiz?.title}>
                                <H2 className={s.__title}>
                                  <Latex>
                                    {quiz?.title
                                      ? quiz?.title
                                        ?.replaceAll("amp;", "")
                                        ?.replaceAll("<br />", "")
                                      : ""}
                                  </Latex>
                                </H2>
                              </Tooltip>
                            </Col>
                            <Col span={6}>
                              <div
                                style={{
                                  textAlign: "right",
                                }}
                              >
                                <Popconfirm
                                  title="Tu veux vraiment quitter ."
                                  onConfirm={handleConfirm}
                                  onCancel={handleCancel}
                                  okText="Oui"
                                  cancelText="Non"
                                >
                                  <AppButton className={s.__btn__back}>
                                    Quitter
                                  </AppButton>
                                </Popconfirm>
                              </div>
                            </Col>
                          </Row>
                          <div className={s.header}>
                            <div className={s.left}>
                              <Avatar
                                size={84}
                                src={user?.profile}
                                alt="teacher avatar"
                                style={{
                                  position: "relative",
                                }}
                              />
                            </div>
                            <div className={s.center}>
                              {!isFinish && !TimeElapsed && (
                                <Fragment>
                                  <Row justify="center">
                                    <Image
                                      preview={false}
                                      src={Clock}
                                      alt="teacher avatar"
                                    />
                                  </Row>
                                  <Row justify="space-between" align="middle">
                                    <Col></Col>
                                    <Col span={2}>
                                      <div className={s.count__down}>
                                        <CountdownTimer
                                          targetDate={dateTimeAfterThreeDays}
                                          setIsVisble={setIsVisble}
                                          setTimeElapsed={setTimeElapsed}
                                          setIsFinish={setIsFinish}
                                          setDrawer={setDrawer}
                                        />
                                      </div>
                                    </Col>
                                    <Col>
                                      <span>
                                        {current}/{quiz?.questions?.length}
                                      </span>
                                    </Col>
                                  </Row>
                                </Fragment>
                              )}

                              <div>
                                <SkillBar width={progress} />
                              </div>
                            </div>
                            <div className={s.right}>
                              <Image
                                preview={false}
                                className={s.__iconTrophy}
                                src={Trophe}
                                alt="teacher avatar"
                              />
                            </div>
                          </div>

                          <div className={s.main}>
                            <h3>
                              <Latex>
                                {quiz?.questions[current]?.title
                                  ? quiz?.questions[current]?.title
                                    ?.replaceAll("amp;", "")
                                    ?.replaceAll("<br />", "")
                                  : ""}
                              </Latex>
                            </h3>

                            {quiz?.questions[current]?.propositions?.map(
                              (proposition: any) => (
                                <>
                                  <div
                                    className={s.card}
                                    ref={ref}
                                    onClick={handleClick}
                                  >
                                    <label>
                                      <Field
                                        name="answer"
                                        id={proposition?._id}
                                        value={proposition?._id}
                                        type="radio"
                                        className={`${s.cb} ${s.pristine}`}
                                      />{" "}
                                      <span className={s.proposition}>
                                        <Latex>
                                          {proposition?.title
                                            ? proposition?.title
                                              ?.replaceAll("amp;", "")
                                              ?.replaceAll("<br />", "")
                                            : ""}
                                        </Latex>
                                      </span>
                                    </label>
                                  </div>
                                </>
                              )
                            )}
                          </div>
                          {isVisible && (
                            <Row
                              justify="center"
                              style={{ marginTop: "var(--mb-1)" }}
                            >
                              <AppButton
                                disabled={!validation}
                                htmlTypeSubmit={true}
                              >
                                valider
                              </AppButton>
                            </Row>
                          )}

                          {isFinish && (
                            <Row justify="center" align="middle">
                              <Col>
                                <Result
                                  status="success"
                                  title="Quiz terminé !"
                                  extra={[
                                    <div
                                      style={{
                                        display: "flex",
                                        transform: "translateX(-15px)",
                                      }}
                                    >
                                      <AppButton
                                        onClick={() => setDrawer(!drawer)}
                                        className={s.___result__btn}
                                      >
                                        Resultat du quiz
                                      </AppButton>
                                    </div>,
                                  ]}
                                />
                              </Col>
                            </Row>
                          )}
                        </Fragment>
                      )}

                      <input type="studentId" hidden value={user?._id} />
                    </Form>
                  </Card>
                </AppScreen>
              )}
            </Formik>
          )}

          <AppDrawer
            onClose={() => setDrawer(!drawer)}
            visible={drawer}
            title={
              <H2 className={s.__result__title}>{`${finalResultQuiz?.content || "Quiz terminé"
                }`}</H2>
            }
            width="60%"
          >
            <div style={{ overflow: "hidden" }}>
              <div style={{ textAlign: "center" }}>
                <H2>
                  Temps de quiz :{" "}
                  {formatTimestamp(localStorage.getItem("quizTime") as any)}
                </H2>
              </div>

              <Row justify="center">
                <Col>
                  <Result
                    style={{ padding: 0 }}
                    icon={
                      <>
                        {finalResultQuiz?.max <= 24 && (
                          <Image src={AlbertSad} width={100} preview={false} />
                        )}
                        {finalResultQuiz?.max >= 25 &&
                          finalResultQuiz?.max <= 49 && (
                            <Image src={AlbertNeutre} width={100} preview={false} />
                          )}

                        {finalResultQuiz?.max >= 50 &&
                          finalResultQuiz?.max <= 74 && (
                            <Image src={AlbertHappy} width={100} preview={false} />
                          )}

                        {finalResultQuiz?.max >= 75 &&
                          finalResultQuiz?.max <= 100 && (
                            <Image
                              src={AlbertVeryHappy}
                              width={100}
                              preview={false}
                            />
                          )}
                      </>
                    }
                  />
                </Col>
              </Row>
              {response?.map((res: any, index: any) => (
                <CardResult res={res} resWithAnswer={responseAnswers[index]} />
              ))}
            </div>
          </AppDrawer>
        </React.Fragment>
      )}
    </>
  );
};

export default StudentCoursQuiz;
