import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CounterState {
  level: string;
}

const initialState: CounterState = {
  level: "",
};

export const studentQuizSlice = createSlice({
  name: "StuedentQuzz",
  initialState,
  reducers: {
    pushLevel: (state, action) => {
      state.level = action.payload;
    },
  },
});

export const { pushLevel } = studentQuizSlice.actions;

export default studentQuizSlice.reducer;
