import React, { FC, useEffect } from "react";
import { useCountdown } from "hooks/useCountdown";
import { Col, Row } from "antd";
import s from "./style.module.css";
import { AppNotification } from "components/appNotification";

interface IDateTimeDisplay {
  isDanger: boolean;
  type: string;
  value: number;
}

const DateTimeDisplay: FC<IDateTimeDisplay> = ({ value, type, isDanger }) => {
  return (
    <div className={`${s.countdown} ${isDanger && `${s.danger}`}`}>
      <span>{value < 10 ? `0${value}` : value}</span>
      <span>{type}</span>
    </div>
  );
};

interface IExpiredNotice {
  resetDrawerInstaProf: () => void;
}

const ExpiredNotice: FC<IExpiredNotice> = ({ resetDrawerInstaProf }) => {
  useEffect(() => {
    resetDrawerInstaProf();
    AppNotification(<span>Ta demande instaProf est expiré</span>, "AlbertSad");
  }, []);

  return (
    <div className="expired-notice">
      <span>Ta demande instaProf est expiré </span>
    </div>
  );
};

interface IShowCounter {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const ShowCounter: FC<IShowCounter> = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="show-counter">
      <Row justify="center" gutter={3}>
        <Col>
          <DateTimeDisplay value={minutes} type={" : "} isDanger={false} />
        </Col>

        <Col>
          <DateTimeDisplay value={seconds} type={" "} isDanger={false} />
        </Col>
      </Row>
    </div>
  );
};

interface ICountdownTimer {
  targetDate: any;
  handleCloseDrawer: () => void;
}

const CountdownTimer: FC<ICountdownTimer> = ({
  targetDate,
  handleCloseDrawer,
}) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice resetDrawerInstaProf={handleCloseDrawer} />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;
