import s from "./simpleStyling.module.css";

export const dateFormatter = (date: Date, separator: string) => {
  return `${date.getDate()}${separator}${
    date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0` + (date.getMonth() + 1)
  }${separator}${date.getFullYear()}`;
};
export const MsToTime = (props: any) => {
  let hours = props.hours;
  let minutes = props.minutes;
  let seconds = props.seconds;

  return (
    <div>
      {toApplyStyle(hours, "H ")}
      {toApplyStyle(minutes, "MN ")}
      {toApplyStyle(seconds, "S")}
    </div>
  );
};
const toApplyStyle = (entry1: any, entry2: any) => (
  <>
    <span className={`${s.time} ${s.font}`}>{entry1}</span>
    <span className={`${s.label} ${s.font}`}>{entry2}</span>
  </>
);
