import { useLottie } from "lottie-react";
import { FC } from "react";

type Props = {
  animation: any;
};

const LottieAnimate: FC<Props> = ({ animation }) => {
  const options = {
    animationData: animation,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options);

  return View;
};

export default LottieAnimate;
