import { Modal } from "antd";
import { FC } from "react";

interface Props {
  title?: string | boolean | React.ReactNode;
  footer?: string | boolean | React.ReactNode;
  visible: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  closeIcon: React.ReactNode;
  closable?: boolean;
}

const AppModal: FC<Props> = ({
  title = false,
  closable = true,
  closeIcon,
  footer,
  visible,
  handleClose,
  children,
}) => {
  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleClose}
      onCancel={handleClose}
      footer={footer}
      centered
      width={900}
      closeIcon={closeIcon}
      closable={closable}
    >
      {children}
    </Modal>
  );
};

export default AppModal;
