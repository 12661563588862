import { FC } from "react";

//!COMPONENTS
import FooterFooter from "./components/footer";

//!STYLES
import { useSelector } from "react-redux";
import s from "./assets/footer.module.css";

const Footer: FC = () => {

  const { isFullscreen, isRecording } = useSelector((state) => state.app);

  return (
    <footer className={s.footer}>
      {(!isFullscreen || !isRecording) && <FooterFooter />}
    </footer>
  );
};

export default Footer;
