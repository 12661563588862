import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Level } from "common/types";
import * as LEVELS_SERVICES from "../../api/services/levels/levels.services";

export interface LevelsState {
  loading: boolean;
  items: Level[];
  errors: string[];
}

const initialState: LevelsState = {
  loading: false,
  errors: [],
  items: [],
};

export const findAllLevels: any = createAsyncThunk(
  "levels/findall",
  async (_, thunkAPI) => {
    try {
      const response = await LEVELS_SERVICES.findAll();
      if (response?.data) return thunkAPI.fulfillWithValue(response.data);
      return thunkAPI.rejectWithValue("No data found");
    } catch (error) {
      throw thunkAPI.rejectWithValue(error);
    }
  }
);

export const levelsSlice = createSlice({
  name: "levels",
  initialState,
  reducers: {},
  extraReducers: {
    [findAllLevels.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [findAllLevels.fulfilled]: (state, action) => {
      state.loading = false;
      state.items = action.payload;
    },
    [findAllLevels.rejected]: (state) => {
      state.loading = false;
      state.errors.push("Error while fetching topics");
    },
  },
});

const { reducer } = levelsSlice;
export default reducer;
