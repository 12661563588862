import FadeInOnScroll from "components/FadeIn";
import FadeInUpOnScroll from "components/FadeInUp";
import { useState } from "react";
import ellipse_history_title from "../../../../assets/images/app/home/ellipse_history_title.svg";
import ai from "../../../../assets/images/app/teachers/ai.svg";
import book from "../../../../assets/images/app/teachers/book.svg";
import ellipse_advantages from "../../../../assets/images/app/teachers/ellipse_advantages.svg";
import pen from "../../../../assets/images/app/teachers/pen.svg";
import rocket from "../../../../assets/images/app/teachers/rocket.svg";
import s from "./style.module.css";

const Advantages = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.mainContainer}>
            <div className={s.advantageContainer}>
                <div className={s.mainBox}>
                    <FadeInUpOnScroll>
                        {isMobile ? <h1 className={s.title}>Nos avantages<br />pour les enseignants sur<br />Mon Ami Albert</h1> : <h1 className={s.title}>Nos avantages pour les enseignants sur<br />Mon Ami Albert</h1>}
                    </FadeInUpOnScroll>
                    <img src={ellipse_history_title} className={s.ellipseTitle}></img>
                    <img src={ellipse_advantages} className={s.ellipseAdvantage}></img>
                    <div className={s.boxes}>
                        <div className={s.subBox}>
                            <FadeInOnScroll>
                                <div className={s.circleOne}>
                                    <img src={rocket} style={{ height: isMobile ? "24px" : "auto" }}></img>
                                </div>
                            </FadeInOnScroll>
                            <div className={s.circleTitle}>
                                <FadeInOnScroll>Flexibilité<br />& Accessibilité</FadeInOnScroll>
                            </div>
                            <FadeInOnScroll>
                                <div style={{ marginTop: isMobile ? "10px" : "20px" }}>
                                    <a className={s.circleMessage}>Enseignement flexible à tout moment<br />et participation possible de partout</a>
                                </div>
                            </FadeInOnScroll>
                        </div>
                        <div className={s.subBox}>
                            <FadeInOnScroll>
                                <div className={s.circleTwo}>
                                    <img src={book} style={{ height: isMobile ? "24px" : "auto", position: "relative", left: isMobile ? "10px" : "16px" }}></img>
                                    <img src={ai} className={s.aiBox}></img>
                                </div>
                            </FadeInOnScroll>
                            <div className={s.circleTitle}>
                                <FadeInOnScroll>Outils interactifs<br />& Ressources numériques</FadeInOnScroll>
                            </div>
                            <FadeInOnScroll>
                                <div style={{ marginTop: isMobile ? "10px" : "20px" }}>
                                    <a className={s.circleMessage}>Mise à disposition de quiz,<br /> forums et vidéos</a>
                                </div>
                            </FadeInOnScroll>
                        </div>
                        <div className={s.subBox}>
                            <FadeInOnScroll>
                                <div className={s.circleThree}>
                                    <img src={pen} style={{ height: isMobile ? "24px" : "auto" }}></img>
                                </div>
                            </FadeInOnScroll>
                            <div className={s.circleTitle}>
                                <FadeInOnScroll>Suivi & Évaluation<br />personnalisés</FadeInOnScroll>
                            </div>
                            <FadeInOnScroll>
                                <div style={{ marginTop: isMobile ? "10px" : "20px" }}>
                                    <a className={s.circleMessage}>Suivi précis des performances des élèves<br />et feedback personnalisé</a>
                                </div>
                            </FadeInOnScroll>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Advantages;