import { badRequest } from "./../messages/index";
import { getChallengeQuizzParticipate } from "./../api/services/challenges/challenges.services";
import { useState, useCallback, useEffect } from "react";
import { message } from "antd";

const useGetChallengeOfClass = (id: string) => {
  const [quiz, setQuiz] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any[]>();

  const getQuizOfQuiz = useCallback(async (idQuiz: string) => {
    setLoading(true);
    try {
      const response = await getChallengeQuizzParticipate(idQuiz);
      if (response) {
        setQuiz(response.data);
      }
    } catch (error: any) {
      setError(error);
      message.error(badRequest.error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getQuizOfQuiz(id);
  }, [id]);

  return { quiz, loading, error };
};

export default useGetChallengeOfClass;
