export const API_BASE_URL = `${process.env.REACT_APP_API_URL_RESSOURCES}/quizz`;
export const API_BASE_URL_DRAFT = `${process.env.REACT_APP_API_URL_RESSOURCES}/quizz/brouillon`;

export const getFiltredQuizzes = (
  title: string | undefined,
  quizzLevel: string | undefined,
  duration: string | undefined
) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/quizz/?${title ? `title=${title}` : ""
  }&${quizzLevel ? `quizzLevel=${quizzLevel}` : ""}&${duration ? `duration=${duration}` : ""
  }`;

export const getQuizOfCours = (idQuiz: string) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/quizz/${idQuiz}`;

export const quizzResponses = (idQuiz: string, propositionId: string) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/quizz/${idQuiz}/responses/${propositionId}`;

export const TEACHER_FILTER_QUIZZ = (value: any): string => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const title = value?.title || "";
  const levelId = value?.levelId || "";
  const topicId = value?.topicId || "";
  const status = value?.status || "";
  const duration = value?.duration || "";
  const quizzLevel = value?.quizzLevel || "";
  const chapterId = value?.chapterId || "";
  const self = value?.self || "false";

  const url = `${apiUrl}/quizz?title=${title}&levelId=${levelId}&course=${topicId}&status=${status}&duration=${duration}&quizzLevel=${quizzLevel}&chapterId=${chapterId}&self=${self}`;
  return url;
};

export const updateQuiz = (idQuiz: any) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/${idQuiz}/brouillon`;

export const getAvancementPath = (idUser: any, level: any) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/quizz/getAvancement/${idUser}/${level}`;
export const getAvancementByCoursePath = (idUser: string, idCourse: string) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/quizz/chapters/getAvancement/${idUser}/course/${idCourse}`;

export const API_ANNALES_CHECK = `${process.env.REACT_APP_API_URL_RESSOURCES}/annales/specials/check`;
export const API_ANNALES_FETCH = `${process.env.REACT_APP_API_URL_RESSOURCES}/annales/fetch`;
export const API_SOMMAIRE_FETCH = `${process.env.REACT_APP_API_URL_RESSOURCES}/sommaires/fetch`;
