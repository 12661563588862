import * as AUTH_SERVICES from "api/services/auth/auth.services";
import ParentOrganismeDashboard from "components/ParentOrganismeDashboard";
import Breadcrumbs from "components/breadcrumbs";
import React, { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import AppScreen from "screens/teacher/ScreenWithSidebar";


const ParentDashboard = () => {

    const auth = useSelector((state: RootStateOrAny) => state.auth);
    const { user } = useSelector((state) => state.auth);

    const [sponsorData, setSponsorData] = useState<any>([]);
    const [search, setSearch] = useState<string>('');
    const [level, setLevel] = useState<string>('');
    const [page, setPage] = useState<any>(1);
    const [totalPages, setTotalPages] = useState<number>(1);

    const [loading, setLoading] = useState<boolean>(true);

    const fetchStatsData = async () => {
        try {
            if (page <= totalPages) {
                const res = await AUTH_SERVICES.getSponsoredStudents(search, level, user?._id, page);
                const data = res?.data?.results;
                const pages = res?.data?.totalPages;

                setTotalPages(pages);
                setSponsorData((prevData: any) => [...prevData, ...data]);
                console.log(data);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchStatsData();
    }, [search, level, page]);

    return (
        <React.Fragment>
            <Breadcrumbs isStudents isBack={false} />
            <AppScreen>
                <ParentOrganismeDashboard sponsorData={sponsorData} user={user} loading={loading} />
            </AppScreen>
        </React.Fragment>
    );

}

export default ParentDashboard;