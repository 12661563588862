import { Image, Space, Tooltip } from "antd";
import * as appRoutes from "config/routes.config";
import { FC } from "react";
import { Link } from "react-router-dom";
import { formatDates } from "utils/formats";

//!COMPONENTS
import Card from "components/card";
import AppButton from "components/libs/button";
import Matiers from "components/matiers";

//!STYLES
import s from "./assets/CardQuiz.module.css";

//!IMAGES
import { ReactComponent as StartIcon } from "assets/images/students/start.svg";
import { PlayLevel } from "common/enums";
import Clock from "./assets/images/clock.svg";
import Easy from "./assets/images/easy.svg";
import Hard from "./assets/images/hard.svg";
import Medium from "./assets/images/medium.svg";

type Props = {
  className?: string;
  quiz?: any;
};

const CardQuiz: FC<Props> = ({ className, quiz }) => {
  return (
    <Card>
      <div className={s.content}>
        <div className={s.top}>
          <span>Update : {formatDates(quiz?.updatedAt)}</span>
        </div>
        <Matiers
          level={quiz?.course?.title.substring(0, 4)}
          cours={quiz?.course?.level?.title || "level"}
        />
        <div className={s.center}>
          <Tooltip title={quiz?.title}>
            <h3>{quiz?.title}</h3>
          </Tooltip>
        </div>
        <div className={s.bottom}>
          <span className={s.team}>Par {quiz?.teacher?.firstname}</span>
        </div>
        <Link to={`${appRoutes.STUDENT_QUIZZ}/${quiz?._id}`}>
          <AppButton className={s.button}>
            PLay{" "}
            <Image
              style={{ paddingLeft: "5px" }}
              src={
                quiz?.quizzLevel === PlayLevel.EASY
                  ? Easy
                  : quiz?.quizzLevel === PlayLevel.MEDIUM
                  ? Medium
                  : Hard
              }
              preview={false}
              width={20}
            />
          </AppButton>
        </Link>
        <div className={s.level_quiz}>
          <span>
            {quiz?.quizzLevel === PlayLevel.EASY ? (
              <>
                <Space>
                  <div style={{ position: "relative", top: "2px" }}>
                    {" "}
                    <StartIcon width={12} />
                  </div>
                  <div>
                    <span>Facile</span>
                  </div>
                </Space>
              </>
            ) : quiz?.quizzLevel === PlayLevel.MEDIUM ? (
              <Space>
                <div style={{ position: "relative", top: "2px" }}>
                  <StartIcon width={12} />
                  <StartIcon width={12} />
                </div>
                <div>
                  <span>Moyen</span>
                </div>
              </Space>
            ) : (
              <>
                <Space>
                  <div style={{ position: "relative", top: "2px" }}>
                    <StartIcon width={12} />
                    <StartIcon width={12} />
                    <StartIcon width={12} />
                  </div>
                  <div>
                    {" "}
                    <span>Difficil</span>
                  </div>
                </Space>
              </>
            )}
          </span>
        </div>
      </div>

      <div className={s.number}>
        <Image
          preview={false}
          width={15}
          style={{ position: "relative", top: "-2px" }}
          src={Clock}
          alt="teacher avatar"
        />
        <span>{quiz?.duration}</span>
      </div>
    </Card>
  );
};

export default CardQuiz;
