import React from 'react';
import Lottie from 'lottie-react';

type Props = {
    animationData: any;
}

const LottieAnimation: React.FC<Props> = ({ animationData }) => {
    return (
        <div>
            <Lottie animationData={animationData} loop={true} />
        </div>
    );
}

export default LottieAnimation;
