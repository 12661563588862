import axios from "axios";
import { ApiResponse } from "common/interfaces";
import { API_BASE_URL } from "./room.api.routes";
import { CreateConversationDto } from "../conversation-chat/conversation.interface";
import { StringMap } from "i18next";

export const findOne = async (
  actor1: string,
  // sender: string,
  actor2: string,
  flag: string,
  type: string,
  instaprof: string
): Promise<ApiResponse | undefined> => {
  try {
    console.log();
    const response = await axios.post(`${API_BASE_URL}/findRoom`, {
      actor1: actor1,
      // sender: sender,
      actor2: actor2,
      flag: flag,
      type: type,
      instaprof: instaprof,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getAllRooms = async (
  actor1: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/getAllRooms`, {
      actor1: actor1,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateTalk = async (
  talkId: string,
  conversation: CreateConversationDto
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.patch(
      `${API_BASE_URL}/${talkId}`,
      conversation
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const sendMessage = async (
  talkId: string,
  sender: string,
  receiver: string,
  message: string,
  flag: string,
  isChatInstaProf: boolean
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/sendMessage`, {
      talkId: talkId,
      sender: sender,
      receiver: receiver,
      message: message,
      flag: flag,
      instaprof: `${isChatInstaProf ? "find" : ""}`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
