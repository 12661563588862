import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//!COMPONENTS
import * as CHALLENGES_SERVICES from "api/services/challenges/challenges.services";
import * as ROOMS_SERVICES from "api/services/rooms/rooms.services";
import AppButton from "components/libs/button";
import H2 from "components/libs/subtitle";
//!Images
import { ReactComponent as ChallengeIcon } from "./assets/images/challenge.svg";
import Student from "./assets/images/students.svg";

//!STYLES
import { Image, Select } from "antd";
import { StatusCode } from "common/enums";
import CercleChart from "components/barChart/cercleChart";
import Stylo from "components/stylo";
import s from "./assets/room.module.css";

import { TimerChallenge } from "components/libs/readyToUse/timerChallenge";
import { useDispatch } from "react-redux";
import { findAllQuizz } from "store/quizz/quizzSlice";

import AppResult from "components/appResults";
import ChallengeChart from "components/challengeCharts";
import ArrayRegistredStudents from "screens/teacher/Room/assets/columns";
import AppScreen from "screens/teacher/ScreenWithSidebar";
import { studentParser } from "./assets/utils/converters";

const Room: FC = () => {
  // !!HOOKS
  const { id } = useParams();
  const dispatch = useDispatch();
  const [hidePanel, setHidePanel] = useState(true);
  const [pieData, setPieData] = useState([]);
  const [barData, setBarData] = useState([]);
  // !!CLASSE_INFOS
  const [classe, setClasse] = useState<any>();
  // !! CHALLENGE_INFOS
  const [challenge, setChallenge] = useState<any>();
  // !!REGISTRED_INFO
  const [registredStudents, setRegistredStudents] = useState<any>();

  const [challengePercentage, setChallengePercentage] = useState<any>();

  // !!FUNCTIONS
  const fetchClasse = useCallback(async (id: string | undefined) => {
    try {
      const response = await ROOMS_SERVICES.findOne(id);
      if (response?.statusCode === StatusCode.OK) setClasse(response.data);
    } catch (error: any) { }
  }, []);

  const fetchChallengesByIdClass = useCallback(
    async (id: string | undefined) => {
      try {
        const response = await CHALLENGES_SERVICES.findByIdClasse(id);
        if (response?.statusCode === StatusCode.OK) {
          setHidePanel(!hidePanel);
          setChallenge(response?.data);
        }
      } catch (error: any) { }
    },
    []
  );

  const initialiseHandler = () => {
    setHidePanel(!hidePanel);
    setChallenge({});
  };

  const fetchingAllQuiz = () => dispatch(findAllQuizz());

  const fetchPieStatsByIdClass = useCallback(async (id: string | undefined) => {
    try {
      const response = await CHALLENGES_SERVICES.getPieStatsByIdClasse(id);
      if (response?.statusCode === StatusCode.OK) {
        console.log("this is lmy respons", response?.data);
        setPieData(response?.data);
        /// setBarData(response?.data);
      }
    } catch (error: any) { }
  }, []);

  const fetchBarStatsByIdClass = useCallback(async (id: string | undefined) => {
    try {
      const response = await CHALLENGES_SERVICES.getBarStatsByIdClasse(id);
      if (response?.statusCode === StatusCode.OK) {
        const data = response?.data;
        setBarData(
          data?.flatMap((e: any) => [
            { name: e.name, value: e.participation },
            { name: "Stat 2", value: 100 - e.participation }
          ])
        );
        setChallengePercentage(
          data?.flatMap((e: any) => {
            return e.participation
          })
        );
      }
    } catch (error: any) { }
  }, []);

  const fetchClassementByChallenge = useCallback(
    async (id: string | undefined) => {
      try {
        const response = await CHALLENGES_SERVICES.fetchClassementByChallengee(
          id
        );
        if (response?.statusCode === 200) {
          setRegistredStudents(studentParser(response.data));
        }
      } catch (error: any) { }
    },
    []
  );

  useEffect(() => {
    fetchClasse(id);
    fetchingAllQuiz();
    fetchChallengesByIdClass(id);
  }, []);

  useEffect(() => {
    fetchClassementByChallenge(challenge?._id);
    fetchPieStatsByIdClass(challenge?._id);
    fetchBarStatsByIdClass(challenge?._id);
  }, [challenge]);

  useEffect(() => {
    console.log(pieData);
    console.log(barData);
  }, [pieData, barData]);

  return (
    <>
      <Stylo />
      <AppScreen>
        <div className={s.header}>
          <div className={s.left}>
            <H2 className={s.subtitle}>{classe?.title}</H2>
          </div>
          <div className={s.center} />
          <div className={s.right}>
            {hidePanel ? (
              <>
                <div className={`${s.greenBackground} ${s.myArrangement}`}>
                  <div>Aucun challenge pour le moment</div>
                </div>
                <AppButton
                  className={`${s.__btn__challengeX}`}
                  icon={
                    <ChallengeIcon
                      style={{
                        position: "absolute",
                        top: "1px",
                        left: "10%",
                        width: "20px",
                        filter: "brightness(0.7)",
                      }}
                    />
                  }
                >
                  Participer à ce challenge
                </AppButton>
              </>
            ) : (
              <TimerChallenge
                subscribedStudentsLength={challenge?.participants.length}
                expiresAt={challenge?.expiresAt}
                initialise={initialiseHandler}
                subscribedStudents={challenge?.participants}
                challengeId={challenge?._id}
              />
            )}
          </div>
        </div>

        <div className={s.main}>
          <div className={s.main__row}>
            <div className={s.main__left}>
              <div className={s.main__left__content}>
                {pieData.length === 0 ? <div
                  style={{
                    background: "#fafafa",
                    borderRadius: "15px",
                    padding: "0 5%",
                    width: "100%"
                  }}
                >
                  <AppResult
                    status="info"
                    title={'Aucun challenge en cours !'}
                  />
                </div> : barData.length === 0 ? <div
                  style={{
                    background: "#fafafa",
                    borderRadius: "15px",
                    padding: "0 5%",
                    width: "100%"
                  }}
                >
                  <AppResult
                    status="info"
                    title={'Aucune donnée reçu pour le moment !'}
                  />
                </div> : <div className={s.graphe__left}>
                  <div className={s.graphe__left__header}>
                    <Select
                      placeholder="Quiz"
                      allowClear
                      className={`${s.select}`}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "400px",
                        height: "300px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{ fontFamily: "CartoonFont", fontSize: "25px" }}
                      >
                        Taux de participation
                      </div>
                      {/* <CercleChart pieData={barData} /> */}
                      <ChallengeChart pieData={barData} />
                      <div
                        style={{
                          fontWeight: "Bold",
                          fontSize: "0.8rem",
                          textTransform: "uppercase",
                          display: "flex",
                          flexDirection: "row",
                          gap: "30px",
                        }}
                      >
                        <div>Participation : {challengePercentage} %</div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "400px",
                        height: "300px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        fontFamily: "var(--body-font)",
                      }}
                    >
                      <div
                        style={{ fontFamily: "CartoonFont", fontSize: "25px" }}
                      >
                        Précision moyenne
                      </div>
                      <CercleChart pieData={pieData} />
                      <div
                        style={{
                          fontWeight: "Bold",
                          fontSize: "0.8rem",
                          textTransform: "uppercase",
                          display: "flex",
                          flexDirection: "row",
                          gap: "30px",
                        }}
                      >
                        <div> Mauvaise réponse</div>{" "}
                        <div
                          style={{
                            color: "#B0E0E1",
                          }}
                        >
                          Bonne réponse
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
              </div>

              <div className={s.array__conent}>
                <div className={s.__array}>
                  <ArrayRegistredStudents data={registredStudents} />
                </div>
              </div>
            </div>
            <div className={s.main__right}>
              <div className={s.__first__card}>
                <Image preview={false} src={Student} width={80} />
                <h4>
                  {classe?.registeredStudents?.length > 1
                    ? `${classe?.registeredStudents?.length} élèves`
                    : `${classe?.registeredStudents?.length} élève`}{" "}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </AppScreen>
    </>
  );
};

export default Room;
