import { FC } from "react";
import { motion } from "framer-motion";
import s from "./assets/card.module.css";
import "./assets/style.css";

type Props = {
  className?: string;
};

const Card: FC<Props> = ({ children, className }) => {
  return (
    <motion.div
      // whileHover={{ scale: 1.02 }}
      // whileTap={{ scale: 0.9 }}
      className={`${s.card} ${className} square`}
    >
      {children}
    </motion.div>
  );
};

export default Card;
