import { FC } from "react";

//!STYLES
import s from "./assets/cardQuizz.module.css";

const CardQuizz: FC = () => {
  function onChange(e: any) {
    console.log(`checked = ${e.target.checked}`);
  }

  return (
    <div className={s.card}>
      <p>Si le dénominateur est un entier.</p>
    </div>
  );
};

export default CardQuizz;
