import { Col, Divider, Image, Row } from "antd";
import AppDrawer from "components/appDrawer";
import AppResult from "components/appResults";
import H2 from "components/libs/subtitle";
import H1 from "components/libs/title";
import moment from "moment";
import React, { FC, Fragment } from "react";
import { useGetHistoriqueReservationsStudentQuery } from "store/services/teacher/classes.services";
import s from "../assets/calendar.module.css";
import CardReservations from "./card";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  user: any;
};

const DrawerHistorique: FC<Props> = ({ open, setOpen, user }) => {
  const {
    data: historiques,
    isLoading,
    isSuccess,
  } = useGetHistoriqueReservationsStudentQuery(user?._id);

  const historiquesData =
    historiques?.data.reduce((acc: any, curr: any) => {
      const index = acc.findIndex((x: any) => x.startDate === curr.startDate);

      if (index > -1) {
        acc[index] = {
          ...acc[index],
          data: [...acc[index].data, curr],
        };
        return acc;
      }

      return [
        ...acc,
        {
          startDate: curr.startDate,
          data: [curr],
        },
      ];
    }, []) || [];

  return (
    <AppDrawer
      visible={open}
      onClose={() => setOpen((open: boolean) => !open)}
      title={
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginBottom: "20px" }}>
            <Image
              preview={false}
              src={user['profile']}
              width={45}
              alt="Avatar"
            />
            <span className={s.boxTitle}>{user['lastname'] + " " + user['firstname']}</span>
          </div>
          <H1>Historique des cours</H1>
        </div>
      }
    >
      <div
        style={{
          width: "100%",
          border: " 1px solid #ccc",
          padding: "1em",
          borderRadius: "10px",
          background: "#fafafa",
        }}
      >
        {historiquesData.length > 0 ? (
          <Fragment>
            {historiquesData.map((historique: any, index: number) => (
              <div key={index} style={{ marginBottom: "2em" }}>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: "1em" }}
                >
                  <Col>
                    <H2>{moment(historique?.startDate).format("LL")}</H2>
                  </Col>
                </Row>

                {historique?.data.map((cren: any, index: number) => (
                  <CardReservations
                    key={index}
                    cren={cren}
                    isHistorique={true}
                  />
                ))}

                <Divider />
              </div>
            ))}
          </Fragment>
        ) : (
          <AppResult status={"info"} title="Aucune réservation" />
        )}
      </div>
    </AppDrawer>
  );
};

export default DrawerHistorique;
