import React from "react";
import style from "../assets/style.module.css";
import { Quiz } from "./Quiz";

interface QuizMenuInterface {
  data: any;
}
export const QuizMenu: React.FC<QuizMenuInterface> = (props) => {
  return (
    <div className={` ${style.mainX}`}>
      {props?.data?.length !== 0 ? (
        props?.data?.map((e: any) => <Quiz data={e}></Quiz>)
      ) : (
        <></>
      )}
    </div>
  );
};
export default QuizMenu;
