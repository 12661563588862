import { EditOutlined, LeftOutlined } from "@ant-design/icons";
import {
  Col,
  Divider,
  Row,
  Typography
} from "antd";
import Container from "components/container";
import * as appRoutes from "config/routes.config";
import useCourse from "hooks/useCourse.hook";
import useCourseChapters from "hooks/useCourseChapters.hook";
import { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import s from "./assets/course.module.css";

import Breadcrumbs from "components/breadcrumbs";
import AppButton from "components/libs/button";
import H2 from "components/libs/subtitle";
import Latex from "react-latex-next";
import { useSelector } from "react-redux";
import AppScreen from "../ScreenWithSidebar";

const { Title, Paragraph } = Typography;

const Course: FC = () => {
  //!DUMMY DATA

  //!HOOKS
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const params = useParams<"id">();
  const { loading, course } = useCourse(params.id!);
  const {
    chapters,
    loading: chaptersLoading,
    fetchCourseChapters,
  } = useCourseChapters(params.id!);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  //!FUNCTIONS
  const goToCourses = () => navigate(appRoutes.TEACHER_COURSES);
  const goToEditCourse = () =>
    navigate(appRoutes.TEACHER_COURSES + `/${params.id}/edit`);
  const goToPropostionCourse = () =>
    navigate(appRoutes.TEACHER_PROPOSITION_COURS + `/${params.id}/proposition`);

  //!RETURN

  if (loading)
    return (
      <Container>
        <div>Loading...</div>
      </Container>
    );

  return (
    <>
      <Breadcrumbs />
      <AppScreen className={s.course}>
        <Row style={{ marginTop: "4em", marginBottom: "2em" }}>
          <Col span={14}>
            <H2>Title du cours : {course?.title}</H2>
          </Col>
          <Col span={10}>
            <Row justify="end">
              {course?.teacherId === user?._id ? (
                !isMobile && <Row justify="center">
                  <AppButton icon={<EditOutlined />} onClick={goToEditCourse}>
                    Améliorer mon cours
                  </AppButton>
                </Row>
              ) : (
                !isMobile && <Row justify="center">
                  <AppButton
                    icon={<EditOutlined />}
                    onClick={goToPropostionCourse}
                  >
                    Améliorer ce cours
                  </AppButton>
                </Row>
              )}

              <AppButton
                onClick={goToCourses}
                style={{
                  marginLeft: "15px",
                }}
                icon={<LeftOutlined />}
              >
                Retour
              </AppButton>
            </Row>
          </Col>
        </Row>
        <Divider />
        <div className={s.appercue__continute}>
          <h3>Aperçu : {course?.title}</h3>
          {course?.contents?.map((field: any, i: number) => (
            <div key={i}>
              <div className={s.__big__title__content}>
                <h3 className={s.__big__title}>
                  {i + 1} - {field?.title}
                </h3>
              </div>

              <div className={s.appercue__continute__content}>
                {field?.subtitles?.map((x: any) => (
                  <>
                    <h4>{x?.title}</h4>
                    {x?.subtitles?.map((item: any) => (
                      <>
                        <div className={s.section__content}>
                          <h4>{item?.title}</h4>
                        </div>
                        <Latex>
                          {item?.content
                            ? item?.content
                              ?.replaceAll("amp;", "")
                              ?.replaceAll("<br />", "")
                            : ""}
                        </Latex>
                      </>
                    ))}
                  </>
                ))}
              </div>
            </div>
          ))}
        </div>
      </AppScreen>
    </>
  );
};

export default Course;
