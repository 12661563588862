import * as appRoutes from "config/routes.config";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";

//!IMAGES
import { ReactComponent as IconDropDown } from "../../../../assets/images/app/dropDownIcon.svg";
import backgroundImage from "../../../../assets/images/teacher/card.svg";

//!STYLES
import { Button, Dropdown, Menu, Space, Tooltip } from "antd";
import AppButton from "components/libs/button";
import Matiers from "components/matiers";
import { Link } from "react-router-dom";
import { formatDates } from "utils/formats";
import s from "./assets/cardExo.module.css";

interface CardExo {
  exercise?: any;
  isStudent?: boolean;
  className?: string;
}

const CardExercice: FC<CardExo> = ({ exercise, isStudent, className }) => {

  const { user } = useSelector((state) => state.auth);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  const menu = (
    <Menu className={s.__down}>
      {exercise?.status === "DRAFT" && (
        <React.Fragment>
          {exercise?.teacherId === user?._id ? (
            <React.Fragment>
              {!isMobile && <Menu.Item>
                <Link
                  to={`${appRoutes.TEACHER_EXERCICES}/${exercise._id}`}
                  key={exercise._id}
                >
                  Modifier mon exercice
                </Link>
              </Menu.Item>}

              {/* <Menu.Item>
                <Link
                  to={"#"}
                  // onClick={() => setIsDrawerChallenge(!isDrawerChallenge)}
                >
                  Supprimer
                </Link>
              </Menu.Item> */}

              <Menu.Item>
                <Link
                  to={`${appRoutes.TEACHER_GET_EXO}/${exercise._id}`}
                  key={exercise._id}
                >
                  Vue d'ensemble
                </Link>
              </Menu.Item>

              {user.roles.includes("admin") && (
                <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_EXERCICES}/${exercise._id}`}
                    key={exercise._id}
                  >
                    Modifier
                  </Link>
                </Menu.Item>
              )}
            </React.Fragment>
          ) : (
            <>
              <Menu.Item>
                <Link
                  to={`${appRoutes.TEACHER_GET_EXO}/${exercise._id}`}
                  key={exercise._id}
                >
                  Vue d'ensemble
                </Link>
              </Menu.Item>

              {user.roles.includes("admin") && (
                <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_EXERCICES}/${exercise._id}`}
                    key={exercise._id}
                  >
                    Modifier
                  </Link>
                </Menu.Item>
              )}
            </>
          )}
        </React.Fragment>
      )}

      <React.Fragment>
        {exercise?.status === "PENDING" && (
          <>
            <Menu.Item>
              <Link
                to={`${appRoutes.TEACHER_GET_EXO}/${exercise._id}`}
                key={exercise._id}
              >
                Vue d'ensemble
              </Link>
            </Menu.Item>

            {user.roles.includes("admin") && (
              <Menu.Item>
                <Link
                  to={`${appRoutes.TEACHER_EXERCICES}/${exercise._id}`}
                  key={exercise._id}
                >
                  Modifier
                </Link>
              </Menu.Item>
            )}
          </>
        )}

        {exercise?.status === "PUBLISHED" && (
          <React.Fragment>
            {exercise?.teacherId === user?._id ? (
              <>
                <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_EXERCICES}/${exercise._id}`}
                    key={exercise._id}
                  >
                    Modifier mon exercice
                  </Link>
                </Menu.Item>

                {user?.isActive && user?.step === "completed" && !isMobile && <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_GET_EXO}/${exercise._id}/duplicate`}
                    key={exercise._id}
                  >
                    Dupliquer
                  </Link>
                </Menu.Item>}

                {user.roles.includes("admin") && (
                  <Menu.Item>
                    <Link
                      to={`${appRoutes.TEACHER_EXERCICES}/${exercise._id}`}
                      key={exercise._id}
                    >
                      Modifier
                    </Link>
                  </Menu.Item>
                )}
              </>
            ) : (
              <>
                <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_GET_EXO}/${exercise._id}`}
                    key={exercise._id}
                  >
                    Vue d'ensemble
                  </Link>
                </Menu.Item>
                {!isMobile && <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_PROPOSITION_EXO}/${exercise._id}/proposition`}
                    key={exercise._id}
                  >
                    Améliorer
                  </Link>
                </Menu.Item>}
                {user?.isActive && user?.step === "completed" && !isMobile && <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_GET_EXO}/${exercise._id}/duplicate`}
                    key={exercise._id}
                  >
                    Dupliquer
                  </Link>
                </Menu.Item>}
                {user.roles.includes("admin") && (
                  <Menu.Item>
                    <Link
                      to={`${appRoutes.TEACHER_EXERCICES}/${exercise._id}`}
                      key={exercise._id}
                    >
                      Modifier
                    </Link>
                  </Menu.Item>
                )}
              </>
            )}
          </React.Fragment>
        )}

        {exercise?.status === "PROPOSITION" && (
          <>
            <Menu.Item>
              <Link
                to={`${appRoutes.TEACHER_GET_EXO}/${exercise._id}`}
                key={exercise._id}
              >
                Vue d'ensemble
              </Link>
            </Menu.Item>

            {user.roles.includes("admin") && (
              <Menu.Item>
                <Link
                  to={`${appRoutes.TEACHER_EXERCICES}/${exercise._id}`}
                  key={exercise._id}
                >
                  Modifier
                </Link>
              </Menu.Item>
            )}
          </>
        )}
      </React.Fragment>
    </Menu>
  );

  return (
    <div style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: "center" }} className={`${s.card} ${className}`}>
      <div
        style={{
          background: `
              ${exercise?.status === "PUBLISHED"
              ? "#60CD95"
              : exercise?.status === "DRAFT"
                ? "#064205"
                : exercise?.status === "PENDING"
                  ? "#FFC8BA"
                  : exercise?.status === "PROPOSITION"
                    ? "#FFDCAB"
                    : ""
            }`,
        }}
        className={s.ribbon}
      >
        <span style={{
          color: `
              ${exercise?.status === "PUBLISHED"
              ? "#FFF"
              : exercise?.status === "DRAFT"
                ? "#064205"
                : exercise?.status === "PENDING"
                  ? "#F56666"
                  : exercise?.status === "PROPOSITION"
                    ? "#9A6111"
                    : ""
            }`,
        }}>
          {exercise?.status === "DRAFT" && "Brouillon"}
          {exercise?.status === "PENDING" && "En cours de validation"}
          {exercise?.status === "PUBLISHED" && "Publié"}
          {exercise?.status === "PROPOSITION" && "Proposition"}
        </span>
      </div>

      {/* <div className={s.userGroup}>
        <AvatarGroup user={exercise?.teacher} />
      </div>
      <div className={s.publisherName}>{exercise?.teacher?.firstname + " " + exercise?.teacher?.lastname}</div>
      <div className={s.rateBox}>{exercise?.teacher?.rate || 0} <img src={Star} height={12} style={{ marginLeft: "5px", marginRight: "5px" }}></img> <span>({exercise?.teacher?.rateNum || 0} avis)</span></div> */}

      <Matiers
        cours={exercise?.course?.title.slice(0, 4) || "null"}
        level={exercise?.course?.level?.title || "null"}
        style={{ marginTop: "15px", marginBottom: "10px" }}
      />

      <Tooltip placement="top" title={
        <>
          {"Titre : " + exercise?.title}
          <br />
          {"Chapitre : " + exercise?.chapterId?.title}
        </>
      }>
        <div className={s.courseTitle}>
          <span>{exercise?.title}</span>
          <span>{exercise?.chapterId?.title}</span>
        </div>
      </Tooltip>

      <div className={s.__date}>
        <span>Update : {formatDates(exercise?.updatedAt)}</span>
      </div>

      {isStudent ? (
        <Link to={`${appRoutes.STUDENT_EXO}/${exercise?._id}`}>
          <AppButton className={s.__exo}>Lancer</AppButton>
        </Link>
      ) : (
        <div className={s.buttonsRow}>
          {user?.isActive && user?.step === "completed" && !isMobile && <Dropdown
            className={`${s.dropdown}`}
            overlay={menu}
            placement="bottomCenter"
          >
            <Button>
              <Space>
                Contribuer
                <IconDropDown
                  style={{
                    position: "relative",
                    left: "15px",
                  }}
                />
              </Space>
            </Button>
          </Dropdown>}
        </div>
      )}
    </div>

  );
};

export default CardExercice;
