import { Avatar, Drawer, Skeleton } from "antd";
import { calculateTimeDifference } from "common/enums";
import Matiers from "components/matiers";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetHistoriqueReservationsStudentQuery } from "store/services/teacher/classes.services";
import ArrowBack from "../../../../assets/images/app/arrow_back.svg";
import ClockIcon from "../../../../assets/images/app/clock_icon.svg";
import EmptyIcon from "../../../../assets/images/app/empty_icon.svg";
import s from "../assets/calendar.module.css";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

interface CoursesRoomProps {
  course?: object | any;
}

const CoursesRoom: FC<CoursesRoomProps> = ({ course }) => {

  const auth = useSelector((state) => state.auth);

  return (
    <div className={s.historyClasseCard} style={{ cursor: "pointer" }}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", paddingLeft: "20px", paddingRight: "20px" }}>
        <div className={s.avatarNameBox}>
          <Avatar.Group
            maxCount={3}
            maxStyle={{
              color: "#f56a00",
              backgroundColor: "#fde3cf",
            }}
            style={{ marginRight: "5px" }}
          >
            <Avatar
              size={45}
              style={{ backgroundColor: "#FFF2E0", boxShadow: "0px 3px 6px #00000029" }}
              src={course?.studId?.profile}
            />
            {course?.typeCreneaux !== "individuel" && <Avatar
              size={45}
              style={{ backgroundColor: "#FFF2E0", boxShadow: "0px 3px 6px #00000029", position: "relative", left: "-25px" }}
              src={course?.studId?.profile}
            />}
            {course?.typeCreneaux !== "individuel" && <Avatar
              size={45}
              style={{ backgroundColor: "#FFF2E0", boxShadow: "0px 3px 6px #00000029", position: "relative", left: "-50px" }}
              src={course?.studId?.profile}
            />}
          </Avatar.Group>
          <div style={{ position: "relative", left: course?.typeCreneaux !== "individuel" ? "-40px" : "15px", fontSize: "13px" }}>{course?.typeCreneaux !== "individuel" ? "Cours collectif" : course?.studId?.firstname + " " + course?.studId?.lastname}</div>
        </div>

        <div className={s.historyDetailsBox}>
          <div className={s.timeBox}>
            <img src={ClockIcon} height={15} style={{ marginRight: "10px" }}></img>
            <div className={s.times}>
              <span className={s.time}>De {course?.range[0]} à {course?.range[course?.range?.length - 1]}</span>
              <span className={s.timeDescription}>Séance de “{calculateTimeDifference(course?.range[0], course?.range[course?.range.length - 1])}”</span>
            </div>
          </div>
          <Matiers
            cours={course?.topic?.title.slice(0, 4) || "null"}
            level={course?.level?.title || "null"}
          />
        </div>

        <div className={s.historyPriceBox}>{course?.byCredits === "oui" ? "1 Crédit" : course?.price + " €"}</div>
      </div>
    </div>
  );
};

const DrawerHistorique: FC<Props> = ({ open, setOpen }) => {
  const { user } = useSelector((state) => state.auth);
  const {
    data: historiques,
    isLoading,
    isSuccess,
  } = useGetHistoriqueReservationsStudentQuery(user?._id);
  const [courses, setCourses] = useState<any>([]);

  useEffect(() => {
    if (historiques?.data) {
      const sortedCourses = [...historiques.data].sort(
        (a: any, b: any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      );

      const groupedByDate = sortedCourses.reduce((acc: any, course: any) => {
        const date = new Date(course.startDate).toISOString().split("T")[0];
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(course);
        return acc;
      }, {});

      setCourses(groupedByDate);
    }
  }, [historiques]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [isXsScreen, setIsXsScreen] = useState(window.innerWidth > 767 && window.innerWidth <= 1151);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth > 1151 && window.innerWidth <= 1321);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 1321 && window.innerWidth <= 1606);
  const [isNormalScreen, setIsNormalScreen] = useState(window.innerWidth > 1606 && window.innerWidth <= 1799);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
    setIsXsScreen(window.innerWidth > 767 && window.innerWidth <= 1151);
    setIsSmallScreen(window.innerWidth > 1151 && window.innerWidth <= 1321);
    setIsMediumScreen(window.innerWidth > 1321 && window.innerWidth <= 1606);
    setIsNormalScreen(window.innerWidth > 1606 && window.innerWidth <= 1799);
  };
  window.addEventListener('resize', handleResize);

  return (
    <Drawer
      className="drawer"
      visible={open}
      onClose={() => setOpen((open: boolean) => !open)}
      title={<div style={{ fontSize: "20px", fontFamily: "Poppins", fontWeight: "600", color: "#393E3E", backgroundColor: "transparent" }}>Mon historique</div>}
      width={isMobile ? "100%" : isXsScreen ? "100%" : isSmallScreen ? "85%" : isMediumScreen ? "80%" : isNormalScreen ? "70%" : "65%"}
      headerStyle={{ border: "none", paddingTop: "50px", paddingLeft: "40px", backgroundColor: "transparent" }}
      closeIcon={
        <img
          src={ArrowBack}
          height={18}
          style={{}}
        ></img>
      }
      drawerStyle={{ backgroundColor: "#FFF", borderTopLeftRadius: "30px", borderBottomLeftRadius: "30px", boxShadow: "-3px 3px 34px #39393929" }}
    >
      {isLoading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (<div className={s.historyCalendar}>
        {historiques?.data?.length > 0 ? (
          courses && Object.keys(courses)?.map((date) => {
            return (
              <>
                <div key={date} style={{ marginTop: "10px" }}>
                  <div className={s.dateBox}>
                    {new Intl.DateTimeFormat("fr-FR", {
                      weekday: "long",
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    }).format(new Date(date))}
                    <div className={s.horizontalLine}></div>
                  </div>
                  {courses[date].map((course: any) => (
                    <div key={course.id}>
                      <CoursesRoom course={course} />
                    </div>
                  ))}
                </div>
              </>
            );
          })
        ) : (
          <div className={s.emptyClass}>
            Aucune réservation
            <img src={EmptyIcon} height={120} style={{ marginTop: "20px" }}></img>
          </div>
        )}
      </div>
      )}
    </Drawer>
  );
};

export default DrawerHistorique;
