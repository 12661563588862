import { getToken } from "api";
import axios from "axios";
import { ApiResponse } from "common/interfaces";
import { API_BASE_URL, API_BASE_URL_ } from "./studentCours.api.routes";

export const findOneCourse = async (
  idChapters: string | undefined
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.get(`${API_BASE_URL(idChapters)}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const findall = async (
  cours: string | undefined
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.get(`${API_BASE_URL_}/${cours}/chapters`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
