import { UserRoles } from "common/enums";
import * as appRoutes from "config/routes.config";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import AnimatedPage from "utils/animatedPage";

const StudentRoute: FC = ({ children }) => {
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const isStudent = user?.roles?.includes(UserRoles.STUDENT);
  if (!isLoggedIn) return <Navigate to={appRoutes.HOME} />;
  if (isLoggedIn && !isStudent) return <Navigate to={appRoutes.FORBIDDEN} />;
  return <AnimatedPage> {children} </AnimatedPage>;
};

export default StudentRoute;
