import React, { useEffect, useRef, useState } from 'react';
import arrow_down from "../../assets/images/app/faq_arrow_down.svg";
import arrow_up from "../../assets/images/app/faq_arrow_up.svg";
import s from "./style.module.css";

interface CollapseProps {
    type: string;
    title: string;
    content: any;
    background: string;
}

const FaqCollapse: React.FC<CollapseProps> = ({ type, title, content, background }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [height, setHeight] = useState('140px');
    const contentRef = useRef<HTMLDivElement>(null);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
        if (contentRef.current) {
            setHeight(isOpen ? '140px' : `${contentRef.current.scrollHeight}px`);
        }
    };

    useEffect(() => {
        if (isOpen && contentRef.current) {
            setHeight(`${contentRef.current.scrollHeight}px`);
        }
    }, [isOpen]);

    return (
        <div ref={contentRef} className={type === "half" ? s.faqBox : s.faqFullBox} style={{ background: background, marginRight: isMobile ? "0px" : "20px", marginBottom: isMobile ? "20px" : "0px", overflow: 'hidden', transition: 'height 0.3s ease', height: height }}>
            <div className={s.arrowTitleBox} onClick={toggleCollapse}>
                <div className={s.faqTitle} dangerouslySetInnerHTML={{ __html: `${title}` }} />
                <div className={s.arrowBox}>
                    <img src={isOpen ? arrow_up : arrow_down}></img>
                </div>
            </div>
            {isOpen && <a className={s.faqContent}>{content}</a>}
        </div>
    );
};

export default FaqCollapse;
