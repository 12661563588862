import { Tooltip } from "antd";
import Matiers from "components/matiers";
import React, { useEffect } from "react";
import { ApercueQuiz } from "../../apercueQuiz";
import ChallengeSubmited from "../../challengeSubmitted";
import style from "../assets/style.module.css";
import { UpdateDate } from "../utils/updateDate/UpdateDate";

interface CardMenuInterface {
  data: any;
  someClasseDataNeeded: any;
  handler: any;
  setDrawerConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  setTempsChallenge: React.Dispatch<React.SetStateAction<number>>;
}
export const Card: React.FC<CardMenuInterface> = (props) => {

  useEffect(() => {
    console.log(props?.data);
  }, [props]);

  return (
    <div className={`${style.Card} ${style.relative}`}>
      <div className={style.__card__header}>
        <UpdateDate date={props.data?.updatedAt} />
        <Matiers
          className={style.__matieres}
          cours={props?.data?.course?.title}
          level={props?.data?.course?.level?.title}
        />
      </div>

      <div className={style.__card__body}>
        <h3 className={style.__card__title}>
          <Tooltip title={props?.data?.chapterId?.title}>
            {props?.data?.chapterId?.title?.substring(0, 30)}
          </Tooltip>
        </h3>

        <div
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "black",
          }}
        >
          {props.data?.duration}mins - {props.data?.quizzLevel}
        </div>
      </div>

      <div className={style.__card__footer}>
        <ApercueQuiz data={props.data?.questions} />
        <ChallengeSubmited
          handler={props.handler}
          quizz={props.data?._id}
          title={props.data?.title}
          setDrawerConfirm={props.setDrawerConfirm}
          setTempsChallenge={props.setTempsChallenge}
        />
      </div>
    </div>
  );
};

export default Card;
