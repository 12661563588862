import * as AUTH_SERVICES from "api/services/auth/auth.services";
import * as appRoutes from "config/routes.config";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import AppScreen from "../ScreenWithSidebar";

import { AutoComplete, Button, Select, Skeleton, Tooltip, notification } from "antd";
import AppResult from "components/appResults";
import Breadcrumbs from "components/breadcrumbs";
import s from "./style.module.css";

import { calculateAvg } from "common/enums";
import { useNavigate } from "react-router-dom";
import { useGetAllLevelsQuery } from "store/services/app/levels.services";
import Search from "../../../assets/images/app/search.svg";
import Star from "../../../assets/images/app/start.svg";
import StudentPicture from "../../../assets/images/students/student.svg";
import backgroundImage from "../../../assets/images/teacher/card.svg";


const TeacherStudents: FC = () => {
  //!HOOKS
  const auth = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth);
  const [page, setPage] = useState<any>(1);
  const [filteredStudents, setFilteredStudents] = useState<any>([]);
  const [studentsData, setStudentsData] = useState<any>([]);
  const [searchStudentsData, setSearchStudentsData] = useState<any>([]);
  const [levels, setLevels] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { data: levelsData, isLoading, isError } = useGetAllLevelsQuery("");
  const navigate = useNavigate();

  const getStudents = async () => {
    try {
      const students = await AUTH_SERVICES.getTeacherStudents("", user?._id, page);
      if (students?.data) {
        setStudentsData(students?.data?.results);
      } else {
        throw new Error("L'utilisateur n'existe pas");
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  }

  const FindStudent = useCallback(async (userNameOrEmail: string, item) => {
    if (userNameOrEmail.length >= 3) {
      try {
        const res = await AUTH_SERVICES.getTeacherStudents(userNameOrEmail, user?._id, "1");
        const data = res?.data?.results;
        setSearchStudentsData(data);
        if (res?.data?.results.length === 0) {
          notification["info"]({
            message: "Information",
            description: "Utilisateur non trouvé : )",
            style: {
              width: 300,
            },
          });
        }
      } catch (error) {
        if (userNameOrEmail.length >= 6) {
          notification["info"]({
            message: "Information",
            description: "Utilisateur non trouvé : )",
            style: {
              width: 300,
            },
          });
        }
      }
    }
    else {
      setSearchStudentsData([]);
    }
  }, []);

  // Handle level change
  const handleLevelChange = (value: string) => {
    if (value !== undefined) {
      const filtered = studentsData.filter((student: any) => student.levelId?.title === value);
      setFilteredStudents(filtered);
    }
    else {
      setFilteredStudents([]);
      getStudents();
    }
  };

  useEffect(() => {
    getStudents();
  }, [page]);

  useEffect(() => {
    // Define the desired order of keys
    const order = ["Primaire", "Collège", "Lycée", "Prépa"];

    // Reorder the data based on the defined order
    const reorderedData = order.reduce((result: any, key: any) => {
      if (levelsData?.data[key]) {
        result[key] = levelsData?.data[key];
      }
      return result;
    }, {});

    // Combine all levels into one array, following the new order
    const levels = Object.keys(reorderedData).flatMap(group => reorderedData[group]);
    setLevels(levels);
  }, [levelsData]);

  return (
    <React.Fragment>
      <Breadcrumbs isStudents />
      <AppScreen>
        <div className={s.studentbox}>
          <div className={s.titleBox}>
            <div className={s.avatarBox}>
              <img src={StudentPicture} height={55}></img>
            </div>
            Mes élèves
          </div>
          <div className={s.filterMainBox}>
            <Select
              showSearch
              style={{ padding: "5px 0px 5px 0px", fontSize: "16px" }}
              className={s.selectInput}
              placeholder={<div className={s.placeholderStyleSelect}>Filtrer par niveau</div>}
              optionFilterProp="children"
              bordered={false}
              allowClear
              dropdownStyle={{ padding: "3px" }}
              showArrow={true}
              onChange={handleLevelChange}
              onClear={() => setFilteredStudents([])}
            >
              {levels.map((item: any, i: any) => (<Select.Option key={i} value={item.title}>{item.title}</Select.Option>))}
            </Select>
            <div className={s.searchBox}>
              <AutoComplete
                bordered={false}
                className={s.search}
                style={{ margin: "0px", paddingLeft: "10px", paddingRight: "10px", width: "100%" }}
                onChange={FindStudent}
                placeholder={<div className={s.placeholderStyle}>Trouver un élève</div>}
                showSearch={true}
              >
              </AutoComplete>
              <div className={s.searchIconBox}><img src={Search} height={15} style={{ filter: 'brightness(0) invert(1)' }}></img></div>
            </div>
          </div>

          {loading ? <Skeleton active={true} /> : <div className={s.lineBox}>
            {filteredStudents?.length !== 0 ? filteredStudents?.map((student: any, index: number) => (
              student?.roles[0] !== "teacher" && <div style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: "center" }} className={s.card} key={index} onClick={() => { navigate(`${appRoutes.TEACHER_STUDENT_HISTORY}/${student?.uid}`, { state: student }) }}>
                <div className={s.detailsAvatarBox}><img src={student?.profile} height={65} style={{ borderRadius: "50%" }}></img></div>
                <div className={s.studentName}>{student?.firstname} {student?.lastname}</div>
                <div className={s.levelBox}>
                  {student?.levelId?.title}
                </div>

                <Tooltip
                  placement="bottom"
                  color="#FFF"
                  style={{ padding: "0px", margin: "0px", height: "80px", borderRadius: "16px" }}
                  overlayStyle={{ padding: "0px", margin: "0px", height: "80px", borderRadius: "16px" }}
                  title={
                    <div className={s.tooltipBox}>
                      <div className={s.dashedBox}>
                        Cours
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontSize: "12px" }}>
                          {student?.rateCours}
                          <img src={Star} height={10} className={s.rateBoxStarTooltip}></img>
                        </div>
                      </div>
                      <div className={s.dashedBox}>
                        Application
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontSize: "12px" }}>
                          {student?.rateApplication}
                          <img src={Star} height={10} className={s.rateBoxStarTooltip}></img>
                        </div>
                      </div>
                      <div className={s.dashedBox}>
                        Exercice
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontSize: "12px" }}>
                          {student?.rateExercice}
                          <img src={Star} height={10} className={s.rateBoxStarTooltip}></img>
                        </div>
                      </div>
                    </div>
                  }
                >
                  <Button type="link" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={s.studentAvgBox}>
                      Note moyenne
                      <span className={s.studentAvg}>{calculateAvg(student?.rateApplication, student?.rateExercice, student?.rateCours)}</span>
                      <img src={Star} height={10} className={s.rateBoxStar}></img>
                    </div>
                  </Button>
                </Tooltip>

                <button className={s.defaultButton} onClick={() => { navigate(`${appRoutes.TEACHER_STUDENT_HISTORY}/${student?.uid}`, { state: student }) }}>Historique de cours</button>
              </div>
            )) : searchStudentsData?.length !== 0 ? searchStudentsData?.map((student: any, index: number) => (
              student?.roles[0] !== "teacher" && <div style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: "center" }} className={s.card} key={index} onClick={() => { navigate(`${appRoutes.TEACHER_STUDENT_HISTORY}/${student?.uid}`, { state: student }) }}>
                <div className={s.detailsAvatarBox}><img src={student?.profile} height={65} style={{ borderRadius: "50%" }}></img></div>
                <div className={s.studentName}>{student?.firstname} {student?.lastname}</div>
                <div className={s.levelBox}>
                  {student?.levelId?.title}
                </div>

                <Tooltip
                  placement="bottom"
                  color="#FFF"
                  style={{ padding: "0px", margin: "0px", height: "80px", borderRadius: "16px" }}
                  overlayStyle={{ padding: "0px", margin: "0px", height: "80px", borderRadius: "16px" }}
                  title={
                    <div className={s.tooltipBox}>
                      <div className={s.dashedBox}>
                        Cours
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontSize: "12px" }}>
                          {student?.rateCours}
                          <img src={Star} height={10} className={s.rateBoxStarTooltip}></img>
                        </div>
                      </div>
                      <div className={s.dashedBox}>
                        Application
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontSize: "12px" }}>
                          {student?.rateApplication}
                          <img src={Star} height={10} className={s.rateBoxStarTooltip}></img>
                        </div>
                      </div>
                      <div className={s.dashedBox}>
                        Exercice
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontSize: "12px" }}>
                          {student?.rateExercice}
                          <img src={Star} height={10} className={s.rateBoxStarTooltip}></img>
                        </div>
                      </div>
                    </div>
                  }
                >
                  <Button type="link" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={s.studentAvgBox}>
                      Note moyenne
                      <span className={s.studentAvg}>{calculateAvg(student?.rateApplication, student?.rateExercice, student?.rateCours)}</span>
                      <img src={Star} height={10} className={s.rateBoxStar}></img>
                    </div>
                  </Button>
                </Tooltip>

                <button className={s.defaultButton} onClick={() => { navigate(`${appRoutes.TEACHER_STUDENT_HISTORY}/${student?.uid}`, { state: student }) }}>Historique de cours</button>
              </div>
            )) : studentsData?.length > 0 ? (
              studentsData?.map((student: any, index: number) => (
                student?.roles[0] !== "teacher" && <div style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: "center" }} className={s.card} key={index} onClick={() => { navigate(`${appRoutes.TEACHER_STUDENT_HISTORY}/${student?.uid}`, { state: student }) }}>
                  <div className={s.detailsAvatarBox}><img src={student?.profile} height={65} style={{ borderRadius: "50%" }}></img></div>
                  <div className={s.studentName}>{student?.firstname} {student?.lastname}</div>
                  <div className={s.levelBox}>
                    {student?.levelId?.title}
                  </div>

                  <Tooltip
                    placement="bottom"
                    color="#FFF"
                    style={{ padding: "0px", margin: "0px", height: "80px", borderRadius: "16px" }}
                    overlayStyle={{ padding: "0px", margin: "0px", height: "80px", borderRadius: "16px" }}
                    title={
                      <div className={s.tooltipBox}>
                        <div className={s.dashedBox}>
                          Cours
                          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontSize: "12px" }}>
                            {student?.rateCours}
                            <img src={Star} height={10} className={s.rateBoxStarTooltip}></img>
                          </div>
                        </div>
                        <div className={s.dashedBox}>
                          Application
                          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontSize: "12px" }}>
                            {student?.rateApplication}
                            <img src={Star} height={10} className={s.rateBoxStarTooltip}></img>
                          </div>
                        </div>
                        <div className={s.dashedBox}>
                          Exercice
                          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontSize: "12px" }}>
                            {student?.rateExercice}
                            <img src={Star} height={10} className={s.rateBoxStarTooltip}></img>
                          </div>
                        </div>
                      </div>
                    }
                  >
                    <Button type="link" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <div className={s.studentAvgBox}>
                        Note moyenne
                        <span className={s.studentAvg}>{calculateAvg(student?.rateApplication, student?.rateExercice, student?.rateCours)}</span>
                        <img src={Star} height={10} className={s.rateBoxStar}></img>
                      </div>
                    </Button>
                  </Tooltip>

                  <button className={s.defaultButton} onClick={() => { navigate(`${appRoutes.TEACHER_STUDENT_HISTORY}/${student?.uid}`, { state: student }) }}>Historique de cours</button>
                </div>
              ))
            ) : <div></div>}
          </div>}
          {!loading && filteredStudents?.length === 0 && searchStudentsData?.length === 0 && studentsData?.length < 1 && <div className={s.emptyData}><AppResult status={"info"} title="Aucun élève" /></div>}
        </div>
      </AppScreen>
    </React.Fragment>
  );
};

export default TeacherStudents;
