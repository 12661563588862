import { Alert, Button, Image } from "antd";
import { AuthMethods } from "common/enums";
import * as appRoutes from "config/routes.config";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { registerTeacherWithGoogle } from "store/auth/authSlice";

//!COMPONENTS
import Container from "components/container";
import H1 from "components/libs/title";

//!IMAGES
import AvatarTeacher from "../../../assets/images/teacher/teacher.svg";
import { ReactComponent as IconGoogle } from "../../../assets/images/teacher/ui.svg";

//!STYLES
import Breadcrumbs from "components/breadcrumbs";
import AnimatedPage from "utils/animatedPage";
import s from "./assets/register.module.css";
// import RegisterTeacherForm from "components/teacher/registerForm";

const RegisterTeacher: FC = () => {
  //!HOOKS
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isRegistering, isLoggedIn, registerErrors, authMethod } = useSelector(
    (state) => state.auth
  );

  //!STATES

  //!FUNCTIONS
  const registerWithGoogle = () => dispatch(registerTeacherWithGoogle());
  const goToRegisterByEmail = () =>
    navigate(appRoutes.REGISTER_TEACHER_BY_EMAIL);

  //!EFFECTS
  useEffect(() => {
    if (isLoggedIn && !isRegistering) navigate(appRoutes.TEACHER_ACTIVITIES);
  }, [isLoggedIn, navigate, isRegistering]);

  return (
    <AnimatedPage>
      <Breadcrumbs />
      <div className={s.register__teacher}>
        <Container>
          <H1>Créez votre compte</H1>

          <div className={s.content__action}>
            <div className={s.teacher}>
              <div className={s.avatar}>
                <Image
                  preview={false}
                  width={120}
                  src={AvatarTeacher}
                  alt="teacher avatar"
                />
              </div>
              <div className={s.text}>
                <p>
                  Je suis un <strong>Professeur.</strong>{" "}
                  <Link to={""}>Modifier</Link>
                </p>
              </div>
            </div>

            {/* <RegisterTeacherForm /> */}
            <Button
              className={`${s.button} ${s.google}`}
              block
              loading={isRegistering && authMethod === AuthMethods.GOOGLE}
              type="primary"
              icon={<IconGoogle />}
              onClick={registerWithGoogle}
            >
              {t("auth.register.registerWithGoogle")}
            </Button>
            {/* <Button
            className={`${s.button} ${s.button__fb}`}
            block
            loading={isRegistering && authMethod === AuthMethods.FACEBOOK}
            icon={<IconFb strokeWidth={20} />}
            type="primary"
          >
            {t("auth.register.registerWithFacebook")}
          </Button> */}

            <Button
              className={`${s.button} ${s.gmail}`}
              block
              loading={isRegistering && authMethod === AuthMethods.EMAIL}
              type="primary"
              onClick={goToRegisterByEmail}
            >
              {t("auth.register.registerWithEmailAndPassword")}
            </Button>
          </div>

          {registerErrors.length > 0 &&
            registerErrors.map((error, index) => (
              <Alert key={index} message={error} type="error" showIcon />
            ))}
          <p className={s.alreadyAccount}>
            {" "}
            Vous avez déjà un compte ?{" "}
            <Link to={appRoutes.LOGIN}>
              {" "}
              {t("auth.login.loginTitle")}{" "}
            </Link>{" "}
          </p>
        </Container>
      </div>
    </AnimatedPage>
  );
};

export default RegisterTeacher;
