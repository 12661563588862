import { message, Skeleton } from "antd";
import { fetchFaq } from "api/services/faq/faq.services";
import { FC, useCallback, useEffect, useState } from "react";

//!COMPONENTS
import Breadcrumbs from "components/breadcrumbs";
import Stylo from "components/stylo";
import AppScreen from "../ScreenWithSidebar";

//!STYLE
import s from "./assets/faq.module.css";

//!IMAGES
import { albertFaqData, StatusCode } from "common/enums";
import FadeInUpOnScroll from "components/FadeInUp";
import { badRequest } from "messages";
import { useSelector } from "react-redux";
import AnimatedPage from "utils/animatedPage";
import { ReactComponent as Plus } from "./assets/images/plus.svg";

import albert from "../../../assets/images/app/faqs/albert.svg";
import arrow from "../../../assets/images/app/faqs/arrow.svg";
import bottomLeftEllipse from "../../../assets/images/app/faqs/bottom-left-ellipse.svg";
import bottomRightEllipse from "../../../assets/images/app/faqs/bottom-right-ellipse.svg";
import midleLeftEllipse from "../../../assets/images/app/faqs/midle-left-ellipse.svg";
import midleRightEllipse from "../../../assets/images/app/faqs/midle-right-ellipse.svg";
import organisation from "../../../assets/images/app/faqs/organisation.svg";
import parent from "../../../assets/images/app/faqs/parent.svg";
import student from "../../../assets/images/app/faqs/student.svg";
import teacher from "../../../assets/images/app/faqs/teacher.svg";
import topLeftEllipse from "../../../assets/images/app/faqs/top-left-ellipse.svg";
import trace from "../../../assets/images/app/faqs/top-right-trace.svg";
import st from '../../app/common.module.css';
import FaqsTabs from './components/faqTabs';

// import TestMathType from "./test";

function callback(key: any) {
  console.log(key);
}

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const genExtra = () => (
  <Plus
    style={{
      width: "25px",
      position: "relative",
      right: "-15px",
    }}
    onClick={callback}
  />
);

const FAQ: FC = () => {
  //!STATE
  const [loading, setLoading] = useState<boolean>(false);
  const [faqs, setFaqs] = useState<any>([]);
  const [error, setError] = useState<any>(null);
  const { user } = useSelector((s) => s.auth);
  const [visible, setVisible] = useState(false);
  const [teacherFaqs, setTeacherFaqs] = useState<any>([]);
  const [studentFaqs, setStudentFaqs] = useState<any>([]);
  const [parentFaqs, setParentFaqs] = useState<any>([]);
  const [organisationFaqs, setOrganisationFaqs] = useState<any>([]);
  const [albertFaqs, setAlbertFaqs] = useState<any>([]);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const getFaqs = useCallback(async (type: string) => {
    setLoading(true);
    try {
      const response = await fetchFaq(type);
      if (response.statusCode === StatusCode.OK) {
        const data = transformData(response.data);
        if (type === "teacher") {
          setTeacherFaqs(data);
        }
        else if (type === "student") {
          setStudentFaqs(data);
        }
        else if (type === "parent") {
          setParentFaqs(data);
        }
        else if (type === "organisme") {
          setOrganisationFaqs(data);
        }
        else if (type === "albert") {
          setAlbertFaqs(data);
        }
      }
    } catch (error) {
      message.error(badRequest.error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const [activeIndex, setActiveIndex] = useState(0);

  const handleDivClick = (index: any) => {
    setActiveIndex(index);
    if (index === 0) {
      if (teacherFaqs.length === 0) {
        getFaqs("teacher");
      }
    }
    else if (index === 1) {
      if (studentFaqs.length === 0) {
        getFaqs("student");
      }
    }
    else if (index === 2) {
      if (parentFaqs.length === 0) {
        getFaqs("parent");
      }
    }
    else if (index === 3) {
      if (organisationFaqs.length === 0) {
        getFaqs("organisme");
      }
    }
    else if (index === 4) {
      if (albertFaqs.length === 0) {
        getFaqs("albert");
      }
    }
  };

  const actors = [
    {
      "image": teacher,
      "title": "Je suis professeur"
    },
    {
      "image": student,
      "title": "Je suis étudiant"
    },
    {
      "image": parent,
      "title": "Je suis parent"
    },
    {
      "image": organisation,
      "title": "Je suis organisme"
    },
    {
      "image": albert,
      "title": "À propos d’Albert"
    },
  ];

  const transformData = (data: any) => {
    return Object.keys(data).map(category => ({
      title: category,
      data: data[category].map((item: { title: any; description: any; }) => ({
        question: item?.title,
        answer: item?.description,
      }))
    }));
  };

  useEffect(() => {
    getFaqs("teacher");
  }, []);

  useEffect(() => {
    console.log(teacherFaqs);
  }, [teacherFaqs]);

  return (
    <AnimatedPage>
      <Stylo />
      <Breadcrumbs isFAQ />
      <AppScreen>
        {/* <H1 className={s.title}>FAQ</H1> */}
        <img src={topLeftEllipse} height={50} className={`${st.floatAnimation}`} style={{ position: "relative", top: "60px", left: "-20px", zIndex: "2" }}></img>
        <img src={trace} height={80} style={{ position: "relative", top: "7px", left: "90%", zIndex: "0" }}></img>
        <div className={s.main}>
          {/* <div className={s.collaps}>
            <Collapse
              // defaultActiveKey={["1"]}
              onChange={callback}
              expandIconPosition={"right"}
              ghost
            >
              {faqs &&
                faqs?.map((e: any) => (
                  <Panel
                    header={<h3>{e?.title}</h3>}
                    key={e?._id}
                    extra={genExtra()}
                    className={s.__header__panel}
                  >
                    <div className={s.panel}>
                      <p>{e?.description}</p>
                    </div>
                  </Panel>
                ))}
            </Collapse>
          </div> */}

          <FadeInUpOnScroll>
            <h1 className={s.title}>Comment pouvons-nous vous aider ?</h1>
          </FadeInUpOnScroll>
          <img src={arrow} height={70} style={{ position: "relative", top: "-20px", left: "-30px", zIndex: "1" }}></img>

          <div style={{ display: "flex", flexDirection: "row", marginTop: "40px" }}>
            {actors.map((actor, index) => (
              <div key={index} className={s.actorBox} onClick={() => handleDivClick(index)} style={{ boxShadow: activeIndex === index ? '0px 4px 15px rgba(0, 0, 0, 0.2)' : 'none', transition: 'box-shadow 0.3s ease' }}>
                <div>
                  <img src={actor.image} alt={actor.title} style={{ width: '45px', height: '45px' }} />
                </div>
                <div className={s.actorBoxTitle} style={{ color: activeIndex === index ? "#8AB0B1" : "#393E3E" }}>{actor.title}</div>
              </div>
            ))}
          </div>

          {loading ? <Skeleton /> : <FaqsTabs data={activeIndex === 0 ? teacherFaqs : activeIndex === 1 ? studentFaqs : activeIndex === 2 ? parentFaqs : activeIndex === 3 ? organisationFaqs : albertFaqs.length !== 0 ? albertFaqs : albertFaqData} activeIndex={activeIndex} />}
        </div>
        <img src={midleLeftEllipse} height={150} className={`${st.floatAnimationOne}`} style={{ position: "relative", top: "-650px", left: "-50px", zIndex: "-1" }}></img>
        <img src={midleRightEllipse} height={150} className={`${st.floatAnimation}`} style={{ position: "relative", top: "-750px", left: "80%", zIndex: "-1" }}></img>
        <img src={bottomLeftEllipse} height={200} className={`${st.floatAnimation}`} style={{ position: "relative", top: "-350px", left: "-370px", zIndex: "-1" }}></img>
        <img src={bottomRightEllipse} height={70} className={`${st.floatAnimationOne}`} style={{ position: "relative", top: "-310px", left: "54%", zIndex: "2" }}></img>
      </AppScreen>
    </AnimatedPage>
  );
};

export default FAQ;
