import { getToken } from "api";
import axios from "axios";
import { ApiResponse } from "common/interfaces";
import { API_RESSOURCES } from "../teacher/teacher.routes";
import {
  FIND_ALL,
  FIND_ALL_QUERIES,
  GET_TOPIC_BY_ID,
  GET_TOPIC_BY_ID_STUDENT,
} from "./topics.api.routes";

export const findAll = async (
  levelId: string | any
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.get(FIND_ALL(levelId), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const findAllQueries = async (
  levelId: string
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.get(FIND_ALL_QUERIES(levelId), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const findTopicById = async (idTopic: string) => {
  try {
    const token = await getToken();
    const response = await axios.get(GET_TOPIC_BY_ID(idTopic), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const findTopicByIdStudent = async (idTopic: string) => {
  try {
    const token = await getToken();
    const response = await axios.get(GET_TOPIC_BY_ID_STUDENT(idTopic), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchLevels = async () => {
  try {
    const token = await getToken();
    const response = await axios.get(`${API_RESSOURCES}/levels/findAll/newLogic`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchCourses = async () => {
  try {
    const token = await getToken();
    const response = await axios.get(`${API_RESSOURCES}/users/inscription/static/liste?type=matieres`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
