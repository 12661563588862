import { RedoOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Row,
  Select,
  Skeleton,
  Tooltip,
  message
} from "antd";
import { deleteCourse } from "api/services/chapters/chapters.services";
import { StatusCode, StatusEntity } from "common/enums";
import AppResult from "components/appResults";
import Breadcrumbs from "components/breadcrumbs";
import HelpPopover from "components/help";
import AppButton from "components/libs/button";
import AppSelectGroup from "components/libs/selectGroup";
import Pagination from "components/pagination";
import * as appRoutes from "config/routes.config";
import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useGetAllCoursesMutation } from "store/services/teacher/courses";
import * as TOPICS_SERVICES from "../../../api/services/topics/topics.services";
import CoursPicture from "../../../assets/images/app/Cours.svg";
import SearchIcon from "../../../assets/images/app/search.svg";
import AppScreen from "../ScreenWithSidebar";
import CardCourses from "../components/cardCourses";
import HeaderTabContent from "../components/headerTabContent";
import styles from "./assets/course.module.css";

const { Option } = Select;
const { Search } = Input;

const Courses: FC = () => {
  //!HOOKS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth);
  const [values, setValues] = useState<any>({});
  const [currentPage, setcurrentPage] = useState<number>(1);
  const [getAllCourses, { data, isLoading, isSuccess, isError }] =
    useGetAllCoursesMutation();
  const [form] = Form.useForm();

  //!STATE
  const [tempTopic, setTempTopic] = useState<any>();
  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [reload, setReload] = useState(false);
  const [isloadingTopic, setIsloadingTopic] = useState<boolean>(false);
  const submit = useRef<any>(null);

  const [title, setTitle] = useState<string>("Cours");

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  const deleteCourseFunction = async (idCourse: string) => {
    try {
      const response = await deleteCourse(idCourse);

      if (response?.statusCode === StatusCode.OK) {
        message.success("Suppression a été bien effectuée");
      } else {
        message.warning("Suppression non effectuée");
      }
    } catch (e: any) {
      message.error("Une erreur a été survenue");
    }
  };

  const remove = (e: any) => {
    deleteCourseFunction(e);
    setReload(!reload);
  };

  const OnSelectLevel = useCallback(
    async (levelId: string) => {
      setIsloadingTopic(true);
      try {
        const response = await TOPICS_SERVICES.findAll(levelId);
        if (response?.statusCode === StatusCode.OK) {
          setTempTopic(response?.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsloadingTopic(false);
      }
    },
    [tempTopic]
  );

  const filterCourses = useCallback(
    async (values: any) => {
      getAllCourses(values);
    },
    [values]
  );

  const onReset = () => {
    form.resetFields();
    filterCourses({
      title: "",
      levelId: "",
      topicId: "",
      status: "",
      self: false,
    });
  };

  useEffect(() => {
    getAllCourses({
      ...values,
      page: currentPage,
    });
  }, [currentPage]);

  return (
    <React.Fragment>
      <Breadcrumbs isMycontent={true} />
      <AppScreen>
        <div className={styles.headerBox}>
          <div className={styles.titleBox}>
            <div className={styles.titlePictureBox}>
              <img src={CoursPicture} height={35}></img>
            </div>
            {title}
          </div>
          <div className={styles.buttonBox}>
            <Button className={styles.newButton} onClick={() => navigate(appRoutes.TEACHER_CREATE__COURS)}>
              Créer un cours
            </Button>
            <div style={{ marginLeft: "10px", marginTop: "5px" }}>
              <HelpPopover id={"mon_contenu_creer_un_cours"} />
            </div>
          </div>
        </div>

        <HeaderTabContent />

        <Form
          layout="inline"
          name="search"
          autoComplete="off"
          onFinish={filterCourses}
          form={form}
        >
          <div className={styles.searchBox}>
            <div className={styles.selectBox}>
              <span style={{ color: "#393E3E", opacity: "0.3", fontSize: "14px" }}>Choisir ton niveau</span>
              <Form.Item name="levelId">
                <AppSelectGroup
                  placeholder="Niveau"
                  style={{ width: "205px", position: "relative", left: "-12px" }}
                  onChange={(value: string) => (
                    OnSelectLevel(value), submit.current.click()
                  )}
                />
              </Form.Item>
            </div>

            <div className={styles.separation}></div>

            <div className={styles.selectBox}>
              <span style={{ color: "#393E3E", opacity: "0.3", fontSize: "14px" }}>Choisir ta matière</span>
              <Form.Item name="topicId">
                <Select
                  placeholder={<span style={{ color: "#000", opacity: "0.8" }}>Matière</span>}
                  allowClear
                  loading={isloadingTopic}
                  bordered={false}
                  style={{ width: "205px", position: "relative", left: "-12px" }}
                  onChange={() => submit.current.click()}
                >
                  {tempTopic?.map((topic: any) => (
                    <Option key={topic._id} value={topic._id}>
                      {topic.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className={styles.inputBox}>
              <div className={styles.filedSearch}>
                <Form.Item name="title">
                  <Input
                    placeholder="Chercher ici"
                    bordered={false}
                    style={{ textAlign: "left", paddingTop: "10px", paddingLeft: "30px", paddingRight: "30px" }}
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item>
              <Button
                ref={submit}
                shape="circle"
                htmlType="submit"
                style={{ background: "#8AB0B1", height: "45px", width: "45px" }}
              >
                <img src={SearchIcon} height={15} style={{ filter: 'brightness(0) invert(1)' }}></img>
              </Button>
            </Form.Item>

            <Tooltip placement="left" title={"Réinitialiser tous les filtres"}>
              <Button
                onClick={onReset}
                shape="circle"
                htmlType="button"
                style={{ height: "45px", width: "45px" }}
              >
                <RedoOutlined />
              </Button>
            </Tooltip>
          </div>
          <div className={`${title === "Cours" ? styles.myCoursesBox : styles.myCoursesBoxActive}`}>
            <Form.Item name="self" valuePropName="checked">
              <Checkbox
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  if (isChecked) {
                    setTitle("Mes cours");
                  } else {
                    setTitle("Cours");
                  }
                  submit.current.click();
                }}
                style={{ color: title === "Cours" ? "#FFF" : "#707070" }}
              >
                Mes cours
              </Checkbox>
            </Form.Item>
          </div>
        </Form>

        <div style={{ marginBottom: "80px", marginTop: "40px" }}>
          {isLoading || isFiltering ? (
            <>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </>
          ) : (
            <>
              {data?.data?.result.length > 0 && !(data?.data?.result.length === 1 && data?.data?.result[0]?.status === "REJECTED") ? (
                <Fragment>
                  <div className={styles.coursesBox}>
                    {data?.data?.result.map((course: any) => {
                      if (course?.status !== StatusEntity.REJECTED)
                        return (
                          <React.Fragment key={course?._id}>
                            <CardCourses
                              course={course}
                              user={user}
                              remove={remove}
                            />
                          </React.Fragment>
                        );
                    })}
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  {!isMobile && <Row justify="center">
                    <Link to={appRoutes.TEACHER_CREATE__COURS}>
                      <AppButton>
                        Créer un cours
                      </AppButton>
                    </Link>
                  </Row>}
                  <AppResult
                    status="info"
                    title={!isMobile ? "Ajoute ton premier cours depuis ton ordinateur portable !" : "Ajoute ton premier cours !"}
                  />
                </Fragment>
              )}
            </>
          )}
        </div>
      </AppScreen>
      {!isLoading && data?.data?.result.length > 0 && !(data?.data?.result.length === 1 && data?.data?.result[0]?.status === "REJECTED") && <Pagination
        totalPages={data?.data?.count}
        setcurrentPage={setcurrentPage}
        currentPage={currentPage}
      />}
    </React.Fragment>
  );
};

export default Courses;
