import { Button, Col, Image, Row, Skeleton, Space } from "antd";
import Breadcrumbs from "components/breadcrumbs";
import useStudentOneCourse from "hooks/useStudentCourse";
import "katex/dist/katex.min.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactFullscreen from "react-easyfullscreen";
import { Link, useParams } from "react-router-dom";
import HeaderComponents from "screens/student/components/headerComponents";
import AppScreen from "screens/teacher/ScreenWithSidebar";

//!IMAGES
import close from "assets/images/app/delete.svg";
import logo from "assets/images/app/levieuxalbert.svg";

//!STYLE
import { UserRoles } from "common/enums";
import ArticleEditor from "components/articleEditor";
import AppButton from "components/libs/button";
import * as appRoutes from "config/routes.config";
import { useDispatch, useSelector } from "react-redux";
import { setFullscreen } from "store/app/appSlice";
import TeacherHeaderstabs from "../components/TeacherHeaderstabs";
import s from "./assets/cours.module.css";

const TeacherFindChapter = () => {
  //!HOOKS
  const dispatch = useDispatch();
  const params = useParams<"id">();
  const { loading, data } = useStudentOneCourse(params.id!);
  const { user } = useSelector((state) => state.auth);
  const divContentRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLButtonElement>(null);
  const { isFullscreen } = useSelector((state) => state.app);
  const isTeacher = user?.roles?.includes(UserRoles.TEACHER);

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape" && isFullscreen) {
        btnRef.current?.click();
      }
    },
    [isFullscreen]
  );

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  useEffect(() => {
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyUp]);

  return (
    <>
      <ReactFullscreen>
        {({ ref, onRequest, onExit }) => (
          <div style={{ overflow: "hidden", marginBottom: "80px" }} ref={divContentRef}>
            {isFullscreen && <header className={s.header}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: isMobile ? "center" : "flex-start", justifyContent: isMobile ? "center" : "space-between", width: isMobile ? "75%" : "70%" }}>
                <div className={s.menu}>
                  {!isMobile && <div className={s.logo}>
                    <Link to={UserRoles.TEACHER ? user?.step === "completed" ? "/" : appRoutes.COMPLETE_PROFILE : "/"}>
                      <Image preview={false} src={logo} width={60} />
                    </Link>
                  </div>}
                  {!isMobile && <div style={{ fontSize: "1.2em", color: "#333333", fontWeight: "bold", marginLeft: "10px" }}>
                    Accueil
                    <span style={{ fontSize: "1em", color: "#70B2B3", fontWeight: "bold" }}>{" > Cours"}</span>
                  </div>}
                  {!isMobile && <div><HeaderComponents coursId={params.id!} /></div>}
                  <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <TeacherHeaderstabs
                      idChapter={params.id}
                      textBack="Retour aux chapitres"
                      isFullscreen={isFullscreen}
                      onRequest={onRequest}
                      onExit={onExit}
                    />
                  </div>
                  {!isMobile && <div>
                    <Button onClick={() => {
                      onExit();
                      dispatch(setFullscreen(false));
                    }}
                      className={s.newButton}
                    >
                      <img src={close} width={15} style={{ marginRight: "5px" }} />
                      Fermer
                    </Button>
                  </div>}
                </div>
              </div>
            </header>}
            {!isFullscreen && <Breadcrumbs isMycontent={true} />}
            <AppScreen>
              {!isFullscreen && (
                <>
                  <HeaderComponents coursId={params.id!} />
                  <TeacherHeaderstabs
                    idChapter={params.id}
                    textBack="Retour aux chapitres"
                    isFullscreen={isFullscreen}
                    onRequest={onRequest}
                    onExit={onExit}
                  />
                </>
              )}

              <div className={isFullscreen ? s.full_appercue__continute : s.appercue__continute}>
                {!loading && (
                  <Row
                    justify="space-between"
                    style={{ marginBottom: "2em" }}
                    align="top"
                  >
                    <Col>
                      <h3>Cours : {data?.title}</h3>
                    </Col>
                    {isTeacher && user?.isActive && user?.step === "completed" && <Col>
                      <Space className={s.spaceButton} >
                        {user.roles.includes("admin") && (
                          <AppButton style={{ top: "-10px" }}>
                            <Link
                              to={`${appRoutes.TEACHER_COURSES}/${data._id}/editCourse`}
                            >
                              Modifier
                            </Link>
                          </AppButton>
                        )}

                        {data?.teacherId === user?._id ? (
                          !isMobile && <Link
                            to={`${appRoutes.TEACHER_PROPOSITION_COURS}/${data._id}/proposition`}
                          >
                            <AppButton style={{ top: "-10px" }}>
                              Améliorer mon cours
                            </AppButton>
                          </Link>
                        ) : (
                          !isMobile && <Link
                            to={`${appRoutes.TEACHER_PROPOSITION_COURS}/${data._id}/proposition`}
                          >
                            <AppButton style={{ top: "-10px" }}>
                              Proposer une amélioration
                            </AppButton>
                          </Link>
                        )}
                      </Space>
                    </Col>}
                  </Row>
                )}

                {loading ? (
                  <React.Fragment>
                    <Skeleton active />
                    <Skeleton active />
                    <Skeleton active />
                    <Skeleton active />
                    <Skeleton active />
                  </React.Fragment>
                ) : (
                  <>
                    {data?.contents?.map((field: any, i: number) => {
                      return (< div key={i} className={s.__body} >
                        {data?.contents?.length > 1 && <div className={s.__big__title__content}>
                          <h3 className={s.__big__title}>
                            {i + 1} - {field?.title}
                          </h3>
                        </div>}

                        <div className={s.appercue__continute__content}>
                          {field?.subtitles?.map((x: any) => (
                            <>
                              <h4>{x?.title}</h4>
                              {x?.subtitles?.map((item: any) => {
                                let bgColor = "";
                                bgColor = item?.title?.includes("finition") ? "#30d1c7" :
                                  item?.title?.includes("orème") ? "#2eb71e" :
                                    item?.title?.includes("oreme") ? "#2eb71e" :
                                      item?.title?.includes("emarque") ? "#2eb71e" :
                                        item?.title?.includes("xemple") ? "#2eb71e" :
                                          item?.title?.includes("imites") ? "#2eb71e" :
                                            item?.title?.includes("ropriété") ? "#29a4e1" :
                                              item?.title?.includes("roprieté") ? "#29a4e1" : "var(--first-color)";
                                return (<>
                                  <div className={s.section__content} style={{ background: bgColor }}>
                                    <h4>{item?.title}</h4>
                                  </div>

                                  {/* <Latex>
                                    {item?.content
                                      ? item?.content
                                        ?.replaceAll("amp;", "")
                                        ?.replaceAll("<br />", "")
                                      : ""}
                                  </Latex> */}
                                  <ArticleEditor idTeacher={user?._id} content={item?.content} idChapter={params.id!} idSection={item?._id} />
                                </>
                                )
                              })}
                            </>
                          ))}
                        </div>
                      </div>
                      )
                    })}
                  </>
                )}
              </div>
            </AppScreen>
          </div >
        )}
      </ReactFullscreen >
    </>
  );
};

export default TeacherFindChapter;
