import { Button, Form, Input, Select } from "antd";
import { TransferItem } from "antd/es/transfer";
import { StatusCode } from "common/enums";
import AppResult from "components/appResults";
import Breadcrumbs from "components/breadcrumbs";
import AppButton from "components/libs/button";
import React, { Dispatch, FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as TEACHER_SERVICES from "../../../api/services/teacher/teacher.services";
import Wallet from "../../../assets/images/app/header/wallet.svg";
import AppScreen from "../ScreenWithSidebar";
import TableTransfer from "./components/tableTransfert";
import CreditOrganisme from "./credit";
import s from "./style.module.css";

// Define the shape of a student item
interface Student extends TransferItem {
    lastname: string;
    firstname: string,
    level: string;
    credits: number;
}

const GestionOrganisme: FC = () => {

    const { user } = useSelector((state) => state.auth);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingCredit, setIsLoadingCredit] = useState<boolean>(false);
    const [current, setCurrent] = useState<number>(1);

    const [dataList] = useState<any>([]);
    const [form] = Form.useForm();

    const [organismeId, setOrganismeId] = useState<any>();
    const [organismeName, setOrganismeName] = useState<any>();
    const [organismeCredit, setOrganismeCredit] = useState<any>();

    const [organismeList, setOrganismeList] = useState<any>([]);
    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [organismeStudents, setOrganismeStudents] = useState<any[]>([]);
    const [studentsList, setStudentsList] = useState<any[]>([]);

    const validateMessages = {
        required: t("auth.error.required"),
        types: {
            email: t("auth.error.emailInvalid"),
        },
    };

    const fetchStudentsList = async (organismeId: string) => {
        try {
            setIsLoading(true);
            const response = await TEACHER_SERVICES.getOrganismeStudents(organismeId);
            if (response?.statusCode === StatusCode.OK) {
                setOrganismeStudents(response?.data);
            }
        } catch (error) {
            console.error("Failed to fetch students:", error);
        }
        finally {
            setIsLoading(false);
        }
    };

    const updateOrganismeCredit = async (values: any) => {
        try {
            setIsLoadingCredit(true);
            const response = await TEACHER_SERVICES.updateOrganismeCredit(organismeId, values.credit);
            if (response?.statusCode === StatusCode.CREATED) {
                setOrganismeCredit(response?.data?.credits);
                form.resetFields();
            }
        } catch (error) {
            console.error("Failed to add credit:", error);
        }
        finally {
            setIsLoadingCredit(false);
        }
    };

    const getOrganismes = useCallback(
        async (dataList: any, state: Dispatch<any>) => {
            const response = await TEACHER_SERVICES.getOrganismeList();
            if (response?.statusCode === StatusCode.OK) {
                state(response?.data);
            }
        },
        [dataList]
    );

    // Sample data for students
    const students: Student[] = organismeStudents.map((student, index) => ({
        key: student?.id,
        firstname: student?.firstname,
        lastname: student?.lastname,
        level: student?.level,
        credits: student?.credits,
    }));

    const onChange = (nextTargetKeys: string[], direction: string, moveKeys: string[]) => {
        setTargetKeys(nextTargetKeys);

        // Find students that were moved to the right column
        if (direction === 'right') {
            const movedStudents = students.filter(student =>
                student.key && moveKeys.includes(student.key)  // Ensure student.key is defined
            );
            setStudentsList(prevStudents => [...prevStudents, ...movedStudents]);
        }

        // Handle students moved to the left column
        if (direction === 'left') {
            const removedStudents = students.filter(student =>
                student.key && moveKeys.includes(student.key)  // Ensure student.key is defined
            );
            setStudentsList(prevStudents =>
                prevStudents.filter(student => !removedStudents.some(s => s.key === student.key))
            );
        }
    };

    // Define columns for the transfer table
    const leftColumns = [
        { dataIndex: 'lastname', title: 'Nom', width: 250 },
        { dataIndex: 'firstname', title: 'Prénoms', width: 250 },
        {
            dataIndex: 'level',
            title: 'Niveau',
            width: 250,
            render: (levelId: string) => {
                const backgroundColor = '#ebfefb';
                return (
                    <div style={{ backgroundColor, padding: '4px 8px', borderRadius: '8px' }}>
                        {levelId}
                    </div>
                );
            }
        },
    ];

    const rightColumns = [
        { dataIndex: 'lastname', title: 'Nom', width: 250 },
        { dataIndex: 'firstname', title: 'Prénoms', width: 250 },
        {
            dataIndex: 'level',
            title: 'Niveau',
            width: 250,
            render: (levelId: string) => {
                const backgroundColor = '#ebfefb';
                return (
                    <div style={{ backgroundColor, padding: '4px 8px', borderRadius: '8px' }}>
                        {levelId}
                    </div>
                );
            }
        },
    ];

    const handleSelectChange = (value: string) => {
        if (value) {
            fetchStudentsList(value);
            const selectedItem = organismeList.find((item: any) => item._id === value);
            if (selectedItem) {
                setOrganismeId(value);
                setOrganismeName(selectedItem.organisationName);
                setOrganismeCredit(selectedItem.credits)
            }
        }
    };

    useEffect(() => {
        getOrganismes(organismeList, setOrganismeList);
    }, []);

    useEffect(() => {
        console.log(studentsList);
    }, [studentsList]);

    return (
        <React.Fragment>
            <Breadcrumbs isBack={false} />
            <AppScreen>
                {current === 1 && <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "row", background: "#FAFAFA", alignItems: "center", justifyContent: organismeCredit !== undefined ? "space-around" : "flex-start", height: "120px", paddingLeft: "5%", paddingRight: "5%", borderRadius: "12px" }}>
                        <div style={{ fontSize: "16px", color: "#000", marginRight: "20px" }}> Sélectionner un organisme</div>
                        <Form
                            validateTrigger={["onFinish"]}
                            validateMessages={validateMessages}
                            name="registerParent"
                            autoComplete="off"
                            onFinish={() => console.log("Form submitted")}
                        >
                            <Form.Item
                                style={{ marginBottom: "0px", width: "300px" }}
                                name="countries"
                                rules={[{ required: true }]}
                            >
                                <Select
                                    showSearch
                                    style={{ padding: "5px 0px 5px 0px", fontSize: "16px" }}
                                    className={s.selectInput}
                                    placeholder={<div className={s.placeholderStyle}>Tapez ici votre recherche</div>}
                                    optionFilterProp="children"
                                    bordered={false}
                                    allowClear
                                    dropdownStyle={{ padding: "3px" }}
                                    showArrow={true}
                                    onChange={handleSelectChange}
                                >
                                    {organismeList.map((item: { _id: string, organisationName: string }, i: number) => (<Select.Option key={i} value={item._id}>{item.organisationName}</Select.Option>))}
                                </Select>
                            </Form.Item>
                        </Form>
                        {organismeCredit !== undefined && <div style={{ fontSize: "16px", color: "#000", marginLeft: "20px", marginRight: "20px" }}><img src={Wallet} height={40}></img> {organismeCredit} CRÉDITS</div>}
                        {organismeCredit !== undefined && <Form
                            form={form}
                            layout="inline"
                            validateTrigger={["onFinish"]}
                            validateMessages={validateMessages}
                            name="addCredit"
                            autoComplete="off"
                            onFinish={updateOrganismeCredit}
                        >
                            <Form.Item
                                style={{ marginBottom: "0px", width: "170px" }}
                                name="credit"
                                rules={[{ required: true }]}
                            >
                                <Input
                                    type="number"
                                    min={0}
                                    className={s.inoutField}
                                    placeholder={"Nombre de crédits"}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    ghost
                                    loading={isLoadingCredit}
                                    block
                                    type="primary"
                                    htmlType="submit"
                                    className={s.submitButton}
                                >
                                    Valider
                                </Button>
                            </Form.Item>
                        </Form>}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", background: "#FAFAFA", alignItems: "center", justifyContent: "center", height: "auto", paddingLeft: "10%", paddingRight: "10%", paddingTop: "2%", paddingBottom: "5%", borderRadius: "12px", marginTop: "20px", marginBottom: "100px" }}>
                        {organismeStudents.length !== 0 && <div style={{ fontSize: "16px", color: "#000", marginBottom: "20px" }}> Sélectionner les étudiants</div>}
                        {organismeStudents.length === 0 ? <AppResult status={"info"} title={"Aucun élève trouvé dans ta recherche. Choisis un organisme."} /> : <TableTransfer
                            dataSource={students}
                            targetKeys={targetKeys}
                            onChange={onChange}
                            filterOption={(inputValue, item) =>
                                item.lastname.toLowerCase().includes(inputValue.toLowerCase()) ||
                                item.firstname.toLowerCase().includes(inputValue.toLowerCase()) ||
                                item.level.toLowerCase().includes(inputValue.toLowerCase())
                            }
                            leftColumns={leftColumns}
                            rightColumns={rightColumns}
                            showSearch={true}
                        />}
                        {studentsList.length !== 0 && <div style={{ marginTop: "40px", width: "100%", display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-end" }}>
                            <AppButton style={{ width: "150px !important" }} onClick={() => setCurrent(2)}>SUIVANT</AppButton>
                        </div>}
                    </div>
                </div>}
                {current === 2 && (
                    <React.Fragment>
                        <CreditOrganisme organimseId={organismeId} organimseName={organismeName} organismeCredit={organismeCredit} students={studentsList} setCurrent={setCurrent} setOrganismeCredit={setOrganismeCredit} setStudents={setStudentsList} />
                    </React.Fragment>
                )}
            </AppScreen>
        </React.Fragment>
    );
};

export default GestionOrganisme;