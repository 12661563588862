import React, { useMemo, useState } from "react";
import { Button, Col, Form, Input, Modal, Row, Space } from "antd";

//!COMPONENTS
import H2 from "components/libs/subtitle";

//!ASSETS
import Teacher from "assets/images/teacher/maa.svg";
import WarningIcon from "assets/images/app/warning.svg";
import Lottie from "assets/animations/104285-alert.json";
import BadgeActived from "assets/images/students/badgeInstaProf.svg";
import BadgeDisabled from "assets/images/students/instaProfBadgeFalse.svg";

//!STYLES
import s from "./style.module.css";
import AppButton from "components/libs/button";
import LottieAnimate from "components/lotties";
import Matiers from "components/matiers";
import { useDispatch, useSelector } from "react-redux";
import { ACTIVE_DRAWER_ALERT_INSTAPROF, INIT_MODAL } from "store/notification";

type Props = {
  onAcceptNotifInstaProf: (id: string) => void;
  onViewNotif: (id: string) => void;
  setIsCheckNotif: React.Dispatch<boolean>;
};

const ModalAlertInstaProf: React.FC<Props> = ({
  onAcceptNotifInstaProf,
  onViewNotif,
  setIsCheckNotif,
}) => {
  //!HOOKS
  const dispatch = useDispatch();
  const { currentStudentInstaProf } = useSelector((state) => state.auth);
  const { openModalAlertInstaProf } = useSelector(
    (state) => state.notifications
  );

  const THREE_DAYS_IN_MS = 5 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();
  const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;

  const handleClose = () => {
    dispatch(INIT_MODAL());
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  return useMemo(() => {
    return (
      <Modal
        title={false}
        visible={openModalAlertInstaProf}
        footer={false}
        width={isMobile ? "100%" : "50%"}
        onOk={handleClose}
        onCancel={handleClose}
        centered
        className={s.modal}
      >
        <Row justify="center" align="middle" gutter={12}>
          <Col>
            <LottieAnimate animation={Lottie} />
          </Col>
        </Row>

        <H2 className={s.title}>Alert Instaprof</H2>

        <Row
          justify="center"
          align="middle"
          gutter={12}
          style={{ marginBottom: "1em" }}
        >
          <Col>
            <div className={s.img__albert}>
              <img src={Teacher} alt="" />
            </div>
          </Col>
          <Col>
            <H2>Psst ! tu as une nouvelle demande instaprof</H2>
          </Col>
        </Row>

        {/* <Row justify="center" gutter={20}>
          <Col>
            <img
              src={currentStudentInstaProf?.data?.student?.profile}
              alt=""
              width={40}
              style={{
                position: "relative",
                textTransform: "uppercase",
                top: "-8px",
              }}
            />
          </Col>
          <Col>
            <h3
              style={{ textTransform: "uppercase", color: "var(--text-color)" }}
            >
              {currentStudentInstaProf?.data?.student?.lastname}{" "}
              {currentStudentInstaProf?.data?.student?.firstname} Demande de
              l'aide
            </h3>
          </Col>
        </Row> */}

        <Row justify="center" gutter={20} style={{ marginBottom: "0.5em" }}>
          <Col>
            <AppButton
              onClick={() => {
                dispatch(ACTIVE_DRAWER_ALERT_INSTAPROF());
              }}
            >
              Consulter
            </AppButton>
          </Col>
        </Row>

        {/* <Row justify="center" gutter={20} style={{ marginBottom: "0.5em" }}>
          <Col>
            <Matiers
              level={currentStudentInstaProf?.content?.split(" ")[11]}
              cours={currentStudentInstaProf?.content?.split(" ")[15]}
            />
          </Col>
        </Row> */}

        {/* {currentStudentInstaProf?.data?.budget <
          currentStudentInstaProf?.userId?.instaProfCost && (
          <Row justify="center" gutter={20} style={{ marginBottom: "1em" }}>
            <Col>
              <span>
                <img
                  src={WarningIcon}
                  alt=""
                  style={{
                    position: "relative",
                    top: "-2px",
                    marginRight: "3px",
                  }}
                />{" "}
                Budget Max:{" "}
                <strong style={{ fontWeight: "600", color: "#333" }}>
                  {currentStudentInstaProf?.data?.budget} Euro
                </strong>
              </span>
            </Col>
          </Row>
        )} */}

        {/* <Row justify="center" gutter={20} style={{ marginBottom: "2em" }}>
          <Col>
            <button
              onClick={() => {
                onViewNotif(currentStudentInstaProf?._id);
                handleClose();
                setIsCheckNotif(true);
              }}
              className={`${s.button} ${s.ignorer}`}
            >
              Ignorer
            </button>
          </Col>
          <Col>
            <button
              onClick={() =>
                onAcceptNotifInstaProf(currentStudentInstaProf?._id)
              }
              className={`${s.button} ${s.accepter}`}
            >
              Accepter
            </button>
          </Col>
        </Row> */}

        {/* <p className={s.paragraphe}>
          Votre profil prof sera listé parmi d’autres professeurs, l’étudiant
          aura le choix <br /> de vous contacter si votre profil match avec son
          besoin
        </p> */}
      </Modal>
    );
  }, [
    openModalAlertInstaProf,
    handleClose,
    onAcceptNotifInstaProf,
    onViewNotif,
    currentStudentInstaProf,
    setIsCheckNotif,
  ]);
};

export default ModalAlertInstaProf;
