import {
  DeleteOutlined,
  EllipsisOutlined,
  LockOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { Button, Col, Popconfirm, Popover, Row, Space, message } from "antd";
import { ReactComponent as DownloadIcon } from "assets/images/icons/download.svg";
import AppLatex from "components/appLatex";
import Matiers from "components/matiers";
import moment from "moment";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useChangeContributionStateMutation,
  useDeleteContributionMutation,
} from "store/services/teacher/contributions.services";
import s from "./style.module.css";

type Props = {
  contribution?: any;
};

const CardContribution: FC<Props> = ({ contribution }) => {

  const [
    deleteContribution,
    { isSuccess: isSuccessDelete, isError, isLoading: isLoadingDelete },
  ] = useDeleteContributionMutation();
  const [
    changeContributionState,
    { isLoading: isLoadingState, isSuccess: isSuccessState },
  ] = useChangeContributionStateMutation();
  const { user, idTeacher } = useSelector((state) => state.auth);
  function convertToKiloOctets(bytes: number): number {
    const KILO: number = 1024;
    const kiloOctets: number = bytes / KILO;
    return Math.round(kiloOctets);
  }

  useEffect(() => {
    if (isSuccessDelete) {
      message.success("Supprimer avec succès");
    }
  }, [isSuccessDelete]);

  useEffect(() => {
    if (isSuccessState) {
      message.success("Statut modifié avec succès");
    }
  }, [isSuccessState]);

  return (
    <div className={s.card}>
      <div className={s.container}>
        <div className={s.header}>
          <div className={s.user__infos}>
            <div>
              <h3>{contribution?.title}</h3>
              <span>
                publiée le {moment(contribution?.createdAt).format("LL")}
              </span>
            </div>
          </div>

          <div className="right">
            <Space>
              <Matiers
                level={contribution?.classe[0]?.level?.title}
                cours={contribution?.classe[0]?.course?.title}
              />

              {user && user.roles.includes("teacher") &&
                user?._id === contribution?.classe[0]?.teacher && (
                  <Popover
                    placement="top"
                    title={false}
                    content={
                      <div>
                        <div style={{ marginBottom: "1em" }}>
                          {contribution?.display ? (
                            <Button
                              type="primary"
                              icon={<UnlockOutlined />}
                              onClick={() =>
                                changeContributionState({
                                  idContribution: contribution?._id,
                                  action: "disable",
                                })
                              }
                              loading={isLoadingState}
                            >
                              Rendre privé
                            </Button>
                          ) : (
                            <Button
                              type="primary"
                              icon={<LockOutlined />}
                              onClick={() =>
                                changeContributionState({
                                  idContribution: contribution?._id,
                                  action: "enable",
                                })
                              }
                              loading={isLoadingState}
                            >
                              Rendre publique
                            </Button>
                          )}
                        </div>

                        <div style={{ width: "100%" }}>
                          <Popconfirm
                            title="Êtes-vous sûr ?"
                            onConfirm={async () =>
                              await deleteContribution(contribution?._id)
                            }
                            okText="Oui"
                            cancelText="Non"
                          >
                            <Button
                              style={{ width: "100%" }}
                              type="primary"
                              danger
                              icon={<DeleteOutlined />}
                              loading={isLoadingDelete}
                            >
                              Supprimer
                            </Button>
                          </Popconfirm>
                        </div>
                      </div>
                    }
                    trigger="click"
                  >
                    <div className={s.cercle}>
                      <EllipsisOutlined />
                    </div>
                  </Popover>
                )}

              {user?._id === contribution?.classe[0]?.teacher && (
                <>
                  {contribution?.display ? (
                    <UnlockOutlined />
                  ) : (
                    <LockOutlined />
                  )}
                </>
              )}
            </Space>
          </div>
        </div>

        <div className={s.body}>
          <AppLatex content={contribution?.content} />
        </div>

        <div className={s.footer}>
          <Row gutter={24}>
            {contribution?.links?.map((link: any, index: any) => (
              <Col>
                <Space>
                  <div>
                    <a href={link?.url} target="_blank">
                      <DownloadIcon width={28} />
                    </a>
                  </div>
                  <div>
                    <a href={link?.url} target="_blank">
                      <h4>{link?.name ?? `Fichier ${link?.type.split("/")[1].toUpperCase()}`}</h4>
                    </a>
                    <span>
                      Télécharger le {link?.type.split("/")[1].toUpperCase()} | {convertToKiloOctets(link?.size)}ko
                    </span>
                  </div>
                </Space>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default CardContribution;
