import * as appRoutes from "config/routes.config";
import { useNavigate } from "react-router-dom";
import molecules_slide from "../../../../assets/images/app/parents/molecules_slide.png";
import parent_four from "../../../../assets/images/app/parents/parent_slide_student_four.png";
import parent_one from "../../../../assets/images/app/parents/parent_slide_student_one.png";
import parent_three from "../../../../assets/images/app/parents/parent_slide_student_three.png";
import parent_two from "../../../../assets/images/app/parents/parent_slide_student_two.png";
import join_community_lines from "../../../../assets/images/app/teachers/join_community_lines.svg";
/* import parents_students from "../../../../assets/images/app/parents/parents_students.png"; */
import { useState } from "react";
import ellipse_materials from "../../../../assets/images/app/organisme/ellipse_materials.svg";
import ellipse_slide_student_four from "../../../../assets/images/app/parents/ellipse_slide_student_four.svg";
import ellipse_slide_student_three from "../../../../assets/images/app/parents/ellipse_slide_student_three.svg";
import ellipse_slide_student_two from "../../../../assets/images/app/parents/ellipse_slide_student_two.svg";
import trace_instaprof from "../../../../assets/images/app/teachers/trace_instaprof.svg";
import trace_teachers_button from "../../../../assets/images/app/teachers/trace_teachers_button.svg";
import trace_teachers_title from "../../../../assets/images/app/teachers/trace_teachers_title.svg";
import st from "../../../app/common.module.css";
import s from "./style.module.css";

const SlideParent = () => {

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate(appRoutes.LOGINS);
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.mainContainer}>
            <div className={s.mainBox}>
                <div className={s.leftBox}>
                    <img src={trace_teachers_title} className={s.titleLines}></img>
                    <h1 className={s.title}>Ensemble pour<br />l’Excellence Éducative<br />de Vos Enfants</h1>
                    {isMobile ? <p className={s.message}>Notre soutien scolaire innovant combine des<br />techniques d’apprentissage modernes et un<br />accompagnement personnalisé pour assurer<br />le succès académique de vos enfants.</p> : <p className={s.message}>Notre soutien scolaire innovant combine des techniques<br />d’apprentissage modernes et un accompagnement personnalisé<br />pour assurer le succès académique de vos enfants.</p>}
                    <button className={st.button} style={{ marginTop: "40px" }} onClick={handleButtonClick}>
                        S’inscrire
                    </button>
                    {!isMobile && <img src={trace_teachers_button} style={{ height: "170px", position: "relative", left: "12%", bottom: "18px" }}></img>}
                </div>
                <div className={s.imagesBox}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <img src={parent_one} className={`${st.floatAnimation} ${s.parentOne}`}></img>
                        <img src={parent_two} className={`${st.floatAnimationOne} ${s.parentTwo}`}></img>
                        <img src={join_community_lines} className={`${st.floatAnimation} ${s.ellipseLines}`}></img>
                        <img src={ellipse_slide_student_two} className={`${st.floatAnimationOne} ${s.ellipseStudentTwo}`}></img>
                        <img src={ellipse_materials} className={`${st.floatAnimationOne} ${s.ellipseMaterial}`}></img>
                        <img src={trace_instaprof} className={`${st.floatAnimationOne} ${s.traceInstaProf}`}></img>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <img src={parent_three} className={`${st.floatAnimationOne} ${s.parentThree}`}></img>
                        <img src={parent_four} className={`${st.floatAnimation} ${s.parentFour}`}></img>
                        <img src={ellipse_slide_student_three} className={`${st.floatAnimationOne} ${s.ellipseStudentThree}`}></img>
                        <img src={ellipse_slide_student_four} className={`${st.floatAnimation} ${s.ellipseStudentFour}`}></img>
                    </div>
                    {!isMobile && <img src={molecules_slide} style={{ height: "120px", width: "120px", position: "relative", left: "-50%", bottom: "5%" }}></img>}
                </div>
            </div>
        </div>
    );
};

export default SlideParent;