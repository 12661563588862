import FadeInOnScroll from "components/FadeIn";
import FadeInUpOnScroll from "components/FadeInUp";
import * as appRoutes from "config/routes.config";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import arrow_need_help from "../../../../assets/images/app/home/arrow_need_help.svg";
import ellipse_history_title from "../../../../assets/images/app/home/ellipse_history_title.svg";
import ellipse_need_help from "../../../../assets/images/app/home/ellipse_need_help.svg";
import join_community_lines from "../../../../assets/images/app/teachers/join_community_lines.svg";
import join_community_teacher from "../../../../assets/images/app/teachers/join_community_teacher.png";
import st from "../../../app/common.module.css";
import s from "./style.module.css";

const JoinCommunity = () => {

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate(appRoutes.LOGINS);
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.mainContainer}>
            <div className={s.needHelpBox}>
                <div className={s.mainBox}>
                    <div className={s.leftBox}>
                        <FadeInUpOnScroll>
                            <h1 className={s.title}>Rejoignez<br />la communauté<br />Mon Ami Albert</h1>
                        </FadeInUpOnScroll>
                        <img src={ellipse_history_title} className={s.ellipseTitle}></img>
                        <FadeInOnScroll>
                            {isMobile ? <p className={s.message}>Rejoignez la communauté Mon Ami Albert et transformez votre passion pour l'enseignement en opportunité ! En tant que professeur, vous pouvez bénéficier et créer du contenu éducatif, gagner de l'argent en dispensant des cours, et faire partie d'une grande communauté de professionnels partageant les mêmes valeurs. Ensemble, nous contribuons à l'éducation de demain !</p> : <p className={s.message}>Rejoignez la communauté Mon Ami Albert et transformez votre passion pour l'enseignement en opportunité ! En tant que professeur, vous pouvez bénéficier et créer du contenu éducatif, gagner de l'argent en dispensant des cours, et faire partie d'une grande communauté de professionnels partageant les mêmes valeurs. Ensemble, nous contribuons à l'éducation de demain !</p>}
                        </FadeInOnScroll>
                        <img src={arrow_need_help} className={s.buttonArrow}></img>
                        <button className={st.button} style={{ marginTop: "0px", position: "relative", top: isMobile ? "0px" : "-45px" }} onClick={handleButtonClick}>
                            Créer votre compte
                        </button>
                        <img src={join_community_lines} className={s.ellipseLines}></img>
                    </div>
                    <div className={s.rightBox}>
                        <img src={ellipse_need_help} className={s.ellipseImage}></img>
                        <img src={join_community_teacher} style={{ height: isMobile ? "260px" : "540px", cursor: "pointer" }}></img>
                    </div>
                </div>
                {!isMobile && <a className={s.quote}>“</a>}
            </div>
        </div>
    );
};

export default JoinCommunity;