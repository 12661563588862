import {
  ArrowLeftOutlined,
  LoadingOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Avatar, Button, message, Popconfirm, Popover, Result, Row, Spin } from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import React, { FC, useEffect, useRef, useState } from "react";
import Linkify from "react-linkify";
import { useDispatch, useSelector } from "react-redux";

//!ASSETS
import TeacherIcon from "assets/images/teacher/teacher.svg";
import { ReactComponent as Albert } from "assets/images/teacher/teacherMaa.svg";

import MoreOptions from "../../../../assets/images/app/bars_icon.png";
import InstaIcon from "../../../../assets/images/app/insta_icon.svg";
import PlanningButtonIcon from "../../../../assets/images/app/planning_icon.svg";
import ReportIcon from "../../../../assets/images/app/report_icon.png";
import UserBlockIcon from "../../../../assets/images/app/user_lock_icon.svg";

import H2 from "components/libs/subtitle";
import moment from "moment";
import { setCurrentChat } from "store/AppChat/appChat";

//!STYLE
import { API_RESSOURCES } from "api/services/teacher/teacher.routes";
import axios from "axios";
import { StatusCode } from "common/enums";
import TeacherCalendarForStudent from "screens/student/components/calendarTeacher";
import DrawerSearchInstaProf from "screens/student/searchTeacher/drawerSearchInstaProf";
import { displayReportDrawer } from "store/adventissements";
import s from "./assets/style/chat.module.css";

type Props = {
  conversation: any;
  isTyping: boolean;
  isBlocked: boolean;
  setIsBlocked: React.Dispatch<any>;
};

const ChatSpace: FC<Props> = ({ conversation, isTyping, isBlocked, setIsBlocked }) => {
  //!HOOKS
  const dispatch = useDispatch();
  const { idSender, idReceiver, currentChat, startChat } = useSelector(
    (state) => state.appChat
  );
  const { user } = useSelector((state) => state.auth);
  const { isChatInstaProf } = useSelector((state) => state.room);
  const refChat = useRef<any>();

  const [openDrawerInstaProf, setOpenDrawerInstaProf] = useState<boolean>(false);
  const [showDrawerTeacherCalendar, setShowDrawerTeacherCalendar] = useState<boolean>(false);
  const [teacherData, setTeacherData] = useState<any>();
  const [teacherID, setTeacherID] = useState<any>();

  const [isBtnInstaProf, setIsBtnInstaProf] = useState<boolean>(true);
  const showDrawerSearchInstaProf = () => {
    setOpenDrawerInstaProf(!openDrawerInstaProf);
  };

  const onShowDrawerTeacherCalendar = () => {
    setShowDrawerTeacherCalendar(true);
  };

  const getTeacherData = async (teacherId: string) => {
    try {
      const response = await AUTH_SERVICES.getTeacherData(user?._id, teacherId);
      console.log(response?.data);
      setTeacherData(response?.data);
    } catch (error: any) { }
  };

  const [isBlocking, setIsBlocking] = useState<boolean>(false);

  const [visibleBlock, setVisibleBlock] = useState(false);
  const [visibleReport, setVisibleReport] = useState(false);
  const [visibleOptions, setVisibleOptions] = useState(false);

  const showPopconfirmReport = () => {
    setVisibleReport(true);
    setVisibleOptions(false);
  };

  const showPopconfirmBlock = () => {
    setVisibleBlock(true);
    setVisibleOptions(false);
  };

  const showReportDrawer = () => {
    const reportingUser = {
      "id": user?._id,
      "name": user?.firstname + " " + user?.lastname,
      "role": user?.roles[0]
    };

    const reportedUser = {
      "id": user?._id === currentChat?.senderId?._id ? currentChat?.receiverId?._id : currentChat?.senderId?._id,
      "name": user?._id === currentChat?.senderId?._id ? currentChat?.receiverId?.firstname + " " + currentChat?.receiverId?.lastname : currentChat?.senderId?.firstname + " " + currentChat?.senderId?.lastname,
      "role": user?._id === currentChat?.senderId?._id ? currentChat?.receiverId?.roles[0] : currentChat?.senderId?.roles[0]
    };

    localStorage.setItem("reportingUser", JSON.stringify(reportingUser));
    localStorage.setItem("reportedUser", JSON.stringify(reportedUser));
    setVisibleReport(false);
    dispatch(displayReportDrawer(true));
  };

  const content = (
    <div>
      <div onClick={showPopconfirmReport} style={{ fontSize: "15px", color: "#393E3E", cursor: "pointer" }}><img src={ReportIcon} height={20} ></img> Signaler un abus</div>
      <div style={{ height: "1px", width: "90%", margin: "0px auto", background: "#BFBCBC", marginTop: "5px", marginBottom: "5px" }}></div>
      <div onClick={showPopconfirmBlock} style={{ fontSize: "15px", color: "#F56666", cursor: "pointer" }}><img src={UserBlockIcon} height={12} style={{ marginRight: "5px" }}></img>{isBlocked ? "Débloquer cet utilisateur" : "Bloquer cet utilisateur"}</div>
    </div>
  );

  const handleCancelBlock = () => {
    setVisibleBlock(false);
  };

  const handleCancelReport = () => {
    setVisibleReport(false);
  };

  const checkBlocking = async () => {
    try {
      const response = await axios.get(`${API_RESSOURCES}/abuse-reports/${user?._id}/blocked/${user?._id === currentChat?.senderId?._id ? currentChat?.receiverId?._id : currentChat?.senderId?._id}`);
      if (response.status === StatusCode.OK) {
        setIsBlocked(response?.data?.data);
      }
    } catch (error) { }
  }

  const blockUser = async (values: any) => {
    try {
      setVisibleBlock(false);
      setIsBlocking(true);
      const myId = user._id;
      const userId = user?._id === currentChat?.senderId?._id ? currentChat?.receiverId?._id : currentChat?.senderId?._id;
      const response = await axios.post(`${API_RESSOURCES}/abuse-reports/block`, { myId, userId });
      if (response.status === StatusCode.CREATED) {
        message.success("Utilisateur bloqué avec succès");
        setIsBlocked(true);
      }
    } catch (error) { }
    finally {
      setIsBlocking(false);
    }
  }

  const unblockUser = async (values: any) => {
    try {
      setVisibleBlock(false);
      setIsBlocking(true);
      const myId = user._id;
      const userId = user?._id === currentChat?.senderId?._id ? currentChat?.receiverId?._id : currentChat?.senderId?._id;
      const response = await axios.put(`${API_RESSOURCES}/abuse-reports/${myId}/unblock/${userId}`);
      if (response.status === StatusCode.OK) {
        message.success("Utilisateur débloqué avec succès");
        setIsBlocked(false);
      }
    } catch (error) { }
    finally {
      setIsBlocking(false);
    }
  }

  useEffect(() => {
    refChat?.current?.scrollIntoView({
      behavior: "smooth",
    });
    if (currentChat?.receiverId?.roles[0] === "teacher") {
      setTeacherID(currentChat?.receiverId?._id);
      getTeacherData(currentChat?.receiverId?._id);
    }
  }, [conversation, isTyping, currentChat]);

  useEffect(() => {
    checkBlocking();
  }, [conversation, currentChat]);

  return (
    <React.Fragment>
      <div
        className={`${s.chat__space} ${currentChat?.instaProf && s.isInsta}`}
      >
        <div className={s.header}>
          <div
            className={s.back}
            onClick={() => dispatch(setCurrentChat(null))}
          >
            <ArrowLeftOutlined style={{ fontSize: "1em" }} />
          </div>
          <span>
            {startChat ? (
              <React.Fragment>
                {currentChat?.receiverIdC ? (
                  <span>
                    {currentChat?.senderId?.firstname}{" "}
                    {currentChat?.senderId?.lastname}
                  </span>
                ) : (
                  <React.Fragment>
                    <div className={s.receiverDetails}>
                      <div className={s.receiverNameAvatarBox}>
                        <span className={s.receiverName}>
                          Discussion Avec {currentChat?.receiverId?.roles[0] === "student" ? "l'élève" : currentChat?.receiverId?.roles[0] === "teacher" ? "le Prof" : currentChat?.receiverId?.roles[0] === "parent" ? "le parent" : ""}{" "}
                          {user?._id === currentChat?.receiverId?._id ? (
                            <>
                              {currentChat?.senderId?.firstname?.split(" ")[0]}{" "}
                              {/* {currentChat?.senderId?.lastname} */}
                            </>
                          ) : (
                            <>
                              {currentChat?.receiverId?.firstname?.split(" ")[0]}{" "}
                              {/* {currentChat?.receiverId?.lastname} */}
                            </>
                          )}{" "}
                        </span>
                        <Avatar
                          size={34}
                          style={{
                            position: "relative",
                            top: "-2px",
                            left: "2px",
                            height: "34px",
                            width: "34px"
                          }}
                          src={
                            currentChat?.receiverIdC ? (
                              <UsergroupAddOutlined
                                style={{
                                  color: "var(--first-color)",
                                  fontSize: "26px",
                                }}
                              />
                            ) : user?._id === currentChat?.receiverId?._id ? (
                              currentChat?.senderId?.profile || TeacherIcon
                            ) : (
                              currentChat?.receiverId?.profile || TeacherIcon
                            )
                          }
                        />
                      </div>

                      <div className={s.receiverButtonsBox}>
                        {currentChat?.receiverId?.roles[0] === "teacher" && <Button className={s.newPlanningButton} onClick={onShowDrawerTeacherCalendar} style={{ marginRight: "10px" }} disabled={isBlocking}>
                          <img
                            src={PlanningButtonIcon}
                            alt=""
                            style={{
                              position: "relative",
                              top: "-1px",
                              marginRight: "5px",
                            }}
                          />
                          Calendrier
                        </Button>}

                        {currentChat?.receiverId?.roles[0] === "teacher" && <Button onClick={showDrawerSearchInstaProf} className={s.newInstaButton} style={{ marginRight: "10px" }} disabled={isBlocking}>
                          <img
                            src={InstaIcon}
                            alt=""
                            style={{ height: "20px", marginRight: "5px" }}
                          />
                          InstaProf
                        </Button>}

                        <Popover
                          visible={visibleOptions}
                          placement="bottom"
                          content={content}
                          trigger="click"
                          style={{ height: "70px !important", borderRadius: "8px", boxShadow: "4px 8px 16px #8B8B8B38", border: "0.5px solid #BFBCBC" }}
                          className={s.customPopover}
                          overlayClassName={s.customPopover}
                        >
                          <div className={s.optionButton} onClick={() => setVisibleOptions(!visibleOptions)}><img src={MoreOptions} height={12}></img></div>
                        </Popover>

                        <Popconfirm
                          placement="top"
                          title={
                            "Confirmer l'action ?"
                          }
                          onConfirm={isBlocked ? unblockUser : blockUser}
                          visible={visibleBlock}
                          okText={isBlocking ? <Spin size="small" /> : "Oui"}
                          cancelText="Non"
                          onCancel={handleCancelBlock}
                          okButtonProps={{ disabled: isBlocking }}
                        >
                          <Button style={{ visibility: "hidden" }}></Button>
                        </Popconfirm>

                        <Popconfirm
                          placement="top"
                          title={
                            "Confirmer l'action ?"
                          }
                          onConfirm={showReportDrawer}
                          visible={visibleReport}
                          okText={"Oui"}
                          cancelText="Non"
                          onCancel={handleCancelReport}
                        >
                          <Button style={{ visibility: "hidden" }}></Button>
                        </Popconfirm>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <></>
            )}
          </span>
        </div>
        <div className={s.chat__content}>
          {startChat && conversation.length > 0 ? (
            <React.Fragment>
              {conversation?.map((c: any) => (
                <>
                  <div ref={refChat}>
                    <div
                      className={`${s.chat__msg} ${user?._id === c?.senderId?._id ? s.owner : ""
                        }`}
                    >
                      <div className={s.chat__msg__profile}>
                        <img
                          className={s.chat__msg__img}
                          src={
                            c?.senderId?.profile
                              ? c?.senderId?.profile
                              : TeacherIcon
                          }
                          alt=""
                        />
                        <div className={s.chat__msg__date}>
                          {moment(c?.createdAt).fromNow().replace(/^\w/, (c) => c.toUpperCase())}
                        </div>
                      </div>
                      <div className={s.chat__msg__content}>
                        <div className={s.chat__msg__text}>
                          <Linkify>{c?.message}</Linkify>
                        </div>
                        {c?.receiverIdC && (
                          <div>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "var(--first-color)",
                              }}
                            >
                              {c.senderId?.lastname} {c.senderId?.firstname}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ))}

              {isTyping && (
                <div className={`${s.chat__msg}`}>
                  <div className={s.chat__msg__profile}>
                    <img
                      className={s.chat__msg__img}
                      src={
                        user?._id === currentChat?.receiverId?._id
                          ? currentChat?.senderId?.profile || TeacherIcon
                          : currentChat?.receiverId?.profile || TeacherIcon
                      }
                      alt=""
                    />
                    <div className={s.chat__msg__date}>
                      <LoadingOutlined />
                    </div>
                  </div>
                  <div className={s.chat__msg__content}>
                    <div className={s.chat__msg__text}>
                      <span style={{ color: "var(--first-color)" }}>
                        En cours d'écrire
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {startChat ? (
                <Row
                  justify="center"
                  style={{ minHeight: "40vh" }}
                  align="middle"
                >
                  <Result
                    icon={<Albert className={s.Albert} />}
                    title={<H2>Fil de discussion vide </H2>}
                  />
                </Row>
              ) : (
                <Result title={<H2>Choisis une discussion</H2>} />
              )}
            </React.Fragment>
          )}
        </div>
      </div>
      <TeacherCalendarForStudent
        open={showDrawerTeacherCalendar}
        setOpen={setShowDrawerTeacherCalendar}
        currentTeacher={teacherData}
      />

      <DrawerSearchInstaProf
        openDrawerInstaProf={openDrawerInstaProf}
        showDrawerSearchInstaProf={showDrawerSearchInstaProf}
        isBtnInstaProf={isBtnInstaProf}
        setIsBtnInstaProf={setIsBtnInstaProf}
        dataOfTeacher={teacherID}
      />
    </React.Fragment>
  );
};

export default ChatSpace;
