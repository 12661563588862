import { useCallback, useEffect } from "react";

//!COMPONENTS
import Breadcrumbs from "components/breadcrumbs";


//!STYLE
import s from "../assets/success.module.css";

import * as AUTH_SERVICES from "api/services/auth/auth.services";
import AppButton from "components/libs/button";
import * as appRoutes from "config/routes.config";
import React from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppScreen from "screens/teacher/ScreenWithSidebar";
import { updateUser } from "store/auth/authSlice";

const Success = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoggedIn, user } = useSelector((state) => state.auth);

  const updateUserData = useCallback(async () => {
    try {
      const finalUser = await AUTH_SERVICES.getUserByUid(user?.uid);
      if (finalUser?.data) {
        batch(() => {
          dispatch(updateUser(finalUser.data));
        });
      }
    } catch (error) { }
  }, []);

  useEffect(() => {
    updateUserData();
  }, [user]);

  return (
    <React.Fragment>
      <Breadcrumbs isWallet />
      <AppScreen>
        <div className={s.container}>
          <div className={s.card}>
            <div
              style={{
                borderRadius: "200px",
                height: "200px",
                width: "200px",
                background: " #F8FAF5",
                margin: "0 auto",
              }}
            >
              <i className={s.italic}>✓</i>
            </div>
            <h1 className={s.title}>Succès</h1>
            <p className={s.paragraph}>
              Nous avons bien reçu votre paiement
              <br />
              Vous pouvez maintenant utiliser votre solde
            </p>
          </div>
          <div style={{ display: "flex", flexDirection: "row", margin: "40px auto", alignItems: "center", justifyContent: "center" }}>
            <AppButton style={{ margintop: "20px" }} onClick={() => navigate(appRoutes.WALLETS)}>Voir mon portefeuille</AppButton>
          </div>
        </div>

      </AppScreen>
    </React.Fragment>
  );
};

export default Success;
