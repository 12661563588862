import axios from "axios";
import * as apiRoutes from "./studentJoinClass.routes";
import { getToken } from "api";

export const StudentJoinClass = async (
  code: string,
  idString: string | any
) => {
  try {
    const response = await axios.post(apiRoutes.API_BASE_URL(code, idString));
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const findAll = async () => {
  try {
    const token = await getToken();
    const response = await axios.get(apiRoutes.API_BASE_URL_CLASS, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
