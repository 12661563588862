import { Col, Row, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//!IMAGES
import Prof from "./maa.svg";

//!STYLE
import s from "./style.module.css";

const Cercle = () => {
  //!HOOK
  const { user } = useSelector((state) => state.auth);
  //!STATE
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(function () {
      setOpen(true);
    }, 500);
  }, [open]);

  return (
    <nav className={`${s.top_righ} ${open && s.open}`}>
      <div className={`${s.disc} ${s.l1}`}>
        <div className={s.content}>
          <Row align="middle">
            <div className="title">
              <h3>Mon ami</h3>
            </div>
            <div className={s.__tag}>
              <span>
                <strong>30%</strong> commission
              </span>
              <Tooltip
                placement="right"
                title={"Je prends 30% de commission sur les cours particuliers"}
              >
                <div className={s.__info}>
                  <span>i</span>
                </div>
              </Tooltip>
            </div>
          </Row>
          <span>0 - 1000 Pts</span>
        </div>

        {user?.points > 0 && user?.points <= 1000 && (
          <div className={s.content__avatar}>
            {" "}
            <img
              aria-label="close modal"
              className={s.avatar}
              src={user?.profile || Prof}
              width={40}
              style={{
                position: "relative",
                top: "-1.8em",
              }}
            />
          </div>
        )}
      </div>

      <a className={`${s.disc} ${s.l2}`}>
        <div className={s.content}>
          <Row align="middle">
            <div className="title">
              <h3>Mon grand ami</h3>
            </div>
            <div className={s.__tag}>
              <span>
                <strong>25%</strong> commission
              </span>
              <Tooltip
                placement="right"
                title={"Je prends 25% de commission sur les cours particuliers"}
              >
                <div className={s.__info}>
                  <span>i</span>
                </div>
              </Tooltip>
            </div>
          </Row>
          <span>1000 - 5000 Pts</span>
        </div>

        {user?.points > 1000 && user?.points <= 5000 && (
          <div className={s.content__avatar}>
            {" "}
            <img
              aria-label="close modal"
              className={s.avatar}
              src={user?.profile || Prof}
              width={40}
              style={{
                position: "relative",
                top: "-1.6em",
              }}
            />
          </div>
        )}
      </a>

      <a className={`${s.disc} ${s.l3}`}>
        <div className={s.content}>
          <Row align="middle">
            <div className="title">
              <h3>Mon vieil ami</h3>
            </div>
            <div className={s.__tag}>
              <span>
                <strong>15%</strong> commission
              </span>
              <Tooltip
                placement="right"
                title={"Je prends 15% de commission sur les cours particuliers"}
              >
                <div className={s.__info}>
                  <span>i</span>
                </div>
              </Tooltip>
            </div>
          </Row>
          <span>5000 - 10 000 Pts</span>
        </div>
        {user?.points > 5000 && user?.points <= 10000 && (
          <div className={s.content__avatar}>
            {" "}
            <img
              aria-label="close modal"
              className={s.avatar}
              src={user?.profile || Prof}
              width={40}
              style={{
                position: "relative",
                top: "-1.6em",
              }}
            />
          </div>
        )}
      </a>

      <a className={`${s.disc} ${s.l4}`}>
        <div className={s.content}>
          <div className={s.__row}>
            <div className="title">
              <h3>Mon meilleur ami </h3>
            </div>
            <div className={s.__tag}>
              <span>
                <strong>5%</strong> commission
              </span>
              <Tooltip
                placement="right"
                title={"Je prends 5% de commission sur les cours particuliers"}
              >
                <div className={s.__info}>
                  <span>i</span>
                </div>
              </Tooltip>
            </div>
          </div>
          <span>+ 10 000 Pts</span>
        </div>
      </a>

      <a className={`${s.disc} ${s.l5}`}>
        {user?.points > 10000 ? (
          <Row justify="center">
            <Col>
              <img
                aria-label="close modal"
                className={s.avatar}
                src={Prof}
                width={50}
                style={{
                  position: "relative",
                  top: "0.6em",
                }}
              />
            </Col>
            <Col>
              <img
                aria-label="close modal"
                className={s.avatar}
                src={user?.profile || Prof}
                width={50}
                style={{
                  position: "relative",
                  top: "0.6em",
                }}
              />
            </Col>
          </Row>
        ) : (
          <img
            aria-label="close modal"
            className={s.avatar}
            src={Prof}
            width={50}
            style={{
              position: "relative",
              top: "0.6em",
            }}
          />
        )}
      </a>
    </nav>
  );
};

export default Cercle;
