import { useState, useEffect } from "react";
import { findExerciceSolution } from "api/services/studentExercice/studentExercice";

const useStudentExerciceSolution = (id: string) => {
  //!STATE
  const [solutions, setClues] = useState<any>([]);
  const [loadingSolution, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  const fetchExercice = async (idExercice: string) => {
    setLoading(true);
    try {
      const response = await findExerciceSolution(idExercice);
      if (response) {
        return setClues(response.data);
      }
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExercice(id);
  }, [id]);

  return { solutions, loadingSolution, error };
};

export default useStudentExerciceSolution;
