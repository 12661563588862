import { CHOICE } from "common/enums";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Course } from "common/types";
import * as QUIZZ_SERVICES from "api/services/quizz/quizz.services";

export interface QuizzState {
  loading: boolean;
  items: Course[];
  errors: string[];
  flag: CHOICE;
  isDraft: boolean;
  currentQuizz: string;
  currentListMatiere: any;
  currentChapter: string;
}

const initialState: QuizzState = {
  loading: false,
  errors: [],
  items: [],
  flag: CHOICE.SAVE_AS_DRAFT,
  isDraft: false,
  currentQuizz: "",
  currentChapter: "",
  currentListMatiere: {},
};

export const findAllQuizz: any = createAsyncThunk(
  "quizz/findAll",
  async (_, thunkAPI) => {
    try {
      const response = await QUIZZ_SERVICES.findAll();
      if (response?.data) return thunkAPI.fulfillWithValue(response.data);
      return thunkAPI.rejectWithValue("No data found");
    } catch (error) {
      throw thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchQuizzesByfilter: any = createAsyncThunk(
  "quizz/findByFilter",
  async (
    yourData: {
      title: string | undefined;
      quizzLevel: string | undefined;
      duration: string | undefined;
    },
    thunkAPI
  ) => {
    try {
      const response = await QUIZZ_SERVICES.fetchQuizzesByfilter(
        yourData.title,
        yourData.quizzLevel,
        yourData.duration
      );
      if (response) return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const quizzSlice = createSlice({
  name: "quizz",
  initialState,
  reducers: {
    changeFlag: (state: any, action: any) => {
      state.flag = action.payload;
    },
    onValidetedDraft: (state: any, action: any) => {
      state.isDraft = action.payload;
    },
    setCurrentQuizz: (state: any, action: any) => {
      state.currentQuizz = action.payload;
    },
    setCurrentChapter: (state: any, action: any) => {
      state.currentChapter = action.payload;
    },
    setCurrentListMatiere: (state: any, action: any) => {
      state.currentListMatiere = action.payload;
    },
    updateItemsQuizzes: (state: any, action: any) => {
      for (let i = 0; i < state.items.length; i++) {
        let obj = state.items[i];
        if (obj._id === action.payload) {
          state.items.splice(i, 1);
          i--;
        }
      }
      return state;
    },
  },
  extraReducers: {
    [findAllQuizz.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [findAllQuizz.fulfilled]: (state, action) => {
      state.loading = false;
      state.items = action.payload;
    },
    [findAllQuizz.rejected]: (state) => {
      state.loading = false;
      state.errors.push("Error while fetching topics");
    },

    [fetchQuizzesByfilter.pending]: (state) => {
      state.errors = [];
      state.loading = true;
    },
    [fetchQuizzesByfilter.fulfilled]: (state, action) => {
      state.loading = false;
      state.items = action.payload;
    },
    [fetchQuizzesByfilter.rejected]: (state) => {
      state.loading = false;
      state.errors.push("Error while fetching quizzes");
    },
  },
});
export const {
  onValidetedDraft,
  changeFlag,
  updateItemsQuizzes,
  setCurrentQuizz,
  setCurrentChapter,
  setCurrentListMatiere,
} = quizzSlice.actions;
const { reducer } = quizzSlice;
export default reducer;
