import { UserRoles } from "common/enums";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import UserParentOrganisation from "./header.parent.organisme";
import UserStudent from "./header.student";
import User from "./header.user";

export const Header: FC = () => {

  const { user } = useSelector((state) => state.auth);
  const isStudent = user?.roles?.includes(UserRoles.STUDENT);
  const isParent = user?.roles?.includes(UserRoles.PARENT);
  const isOrganisme = user?.roles?.includes(UserRoles.ORGANISME);
  const { isFullscreen } = useSelector((state) => state.app);

  useEffect(() => {
    console.log(isFullscreen);
  }, []);

  return <>{isStudent ? <UserStudent /> : isParent ? <UserParentOrganisation /> : isOrganisme ? <UserParentOrganisation /> : <User />}</>;
};
