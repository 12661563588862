import { SmileOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { NotifStatusAlbert } from "common/types";

//!ASSETS
import AlbertHappy from "assets/images/albert/status/Happy.svg";
import AlbertNeutre from "assets/images/albert/status/Neutre.svg";
import AlbertSad from "assets/images/albert/status/Sad.svg";
import AlbertVeryHappy from "assets/images/albert/status/Very happy.svg";

export const AppNotification = (
  description: React.ReactNode | string,
  status: NotifStatusAlbert,
  label?: React.ReactNode | string,
) => {
  notification.open({
    message: label ?? "Notification",
    description: description,
    icon:
      status === "AlbertSad" ? (
        <img src={AlbertSad} alt="" width={40} />
      ) : status === "AlbertNeutre" ? (
        <img src={AlbertNeutre} alt="" width={40} />
      ) : status === "AlbertHappy" ? (
        <img src={AlbertHappy} alt="" width={40} />
      ) : status === "AlbertVeryHappy" ? (
        <img src={AlbertVeryHappy} alt="" width={40} />
      ) : (
        <SmileOutlined />
      ),
  });
};
