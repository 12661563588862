import { FC, useState, useEffect } from "react";
import { findExercices } from "api/services/studentExercice/studentExercice";
import { message } from "antd";

const useFindStudentExo = (id: string) => {
  //!HOOK
  const [exercices, setExercices] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  const fetchExercice = async (idChapter: string) => {
    try {
      setLoading(true);
      const response = await findExercices(idChapter);
      if (response) {
        setExercices(response.data);
      }
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  /*   if (error) {
    message.error("Une erreur inconue à été survennu");
  } */

  useEffect(() => {
    fetchExercice(id);
  }, [id]);

  return { exercices, loading, error, setLoading, setExercices };
};

export default useFindStudentExo;
