import FadeInOnScroll from "components/FadeIn";
import FadeInUpOnScroll from "components/FadeInUp";
import * as appRoutes from "config/routes.config";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import arrow_need_help from "../../../../assets/images/app/home/arrow_need_help.svg";
import ellipse_history_title from "../../../../assets/images/app/home/ellipse_history_title.svg";
import ellipse_need_help from "../../../../assets/images/app/home/ellipse_need_help.svg";
import ellipse_join_community_organisme from "../../../../assets/images/app/organisme/ellipse_join_community_organisme.svg";
import join_community_organisme from "../../../../assets/images/app/organisme/join_community_organisme.png";
import join_community_background from "../../../../assets/images/app/parents/join_community_background.svg";
import join_community_lines from "../../../../assets/images/app/teachers/join_community_lines.svg";
import trace_instaprof from "../../../../assets/images/app/teachers/trace_instaprof.svg";
import st from "../../../app/common.module.css";
import s from "./style.module.css";

const JoinCommunity = () => {

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate(appRoutes.LOGINS);
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.mainContainer}>
            <div className={s.needHelpBox}>
                {!isMobile && <img src={trace_instaprof} style={{ position: "relative", right: "-30%", top: "-35px", height: "130px", zIndex: "1" }}></img>}
                <div className={s.mainBox}>
                    <div className={s.leftBox}>
                        <FadeInUpOnScroll>
                            <h1 className={s.title}>Rejoignez<br />la communauté<br />Mon Ami Albert</h1>
                        </FadeInUpOnScroll>
                        <img src={ellipse_history_title} className={s.ellipseTitle}></img>
                        <FadeInOnScroll>
                            {isMobile ? <p className={s.message}>Participez à une dynamique d'échange et de collaboration au sein de Mon Ami Albert. En rejoignant notre plateforme, vous aurez accès à un réseau d'enseignants et de ressources pédagogiques enrichissantes, ainsi qu'à un suivi des progrès de vos élèves. Ensemble, travaillons à améliorer l'éducation et à offrir aux élèves les outils nécessaires pour réussir. Votre contribution est essentielle pour bâtir un avenir éducatif prometteur !</p> : <p className={s.message}>Participez à une dynamique d'échange et de collaboration au sein de Mon Ami Albert. En rejoignant notre plateforme, vous aurez accès à un réseau d'enseignants et de ressources pédagogiques enrichissantes, ainsi qu'à un suivi des progrès de vos élèves. Ensemble, travaillons à améliorer l'éducation et à offrir aux élèves les outils nécessaires pour réussir. Votre contribution est essentielle pour bâtir un avenir éducatif prometteur !</p>}
                        </FadeInOnScroll>
                        <img src={arrow_need_help} className={s.buttonArrow}></img>
                        <button className={st.button} style={{ marginTop: "0px", position: "relative", top: isMobile ? "0px" : "-45px" }} onClick={handleButtonClick}>
                            Créer votre compte
                        </button>
                        <img src={join_community_lines} className={s.ellipseLines}></img>
                    </div>
                    <div className={s.rightBox}>
                        {isMobile && <img src={ellipse_need_help} className={s.ellipseImage}></img>}
                        <img src={ellipse_join_community_organisme} className={s.ellipseCommunity}></img>
                        <img src={join_community_organisme} className={s.parentImage}></img>
                        {isMobile && <img src={join_community_background} className={s.backgroundParent}></img>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JoinCommunity;