import { useState, useEffect } from "react";
import { findall } from "api/services/StudentCours/studentCours";

type Chapters = {
  _id?: string;
  title?: string;
  description?: string;
  createdAt?: Date;
  updatedAt?: Date;
};

const useChapters = (id: string) => {
  //!STATE
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<Boolean>(true);
  const [error, setError] = useState<string>("");

  const fetcChapters = async (passedId: string) => {
    try {
      if (passedId) {
        const response = await findall(passedId);
        setData(response?.data);
      }
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetcChapters(id);
  }, [id]);

  return { data, loading, error };
};

export default useChapters;
