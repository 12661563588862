import {
  Avatar,
  Button,
  Drawer,
  Form,
  Image,
  Input,
  message,
  Modal,
  Popover,
  Select,
  Skeleton,
  Tabs,
  Typography
} from "antd";
import SOCIAL_SHARING from "../../../components/libs/readyToUse/socialNetworks";

import React, { FC, useCallback, useEffect, useRef, useState } from "react";
// import { RoomCard } from '../dashboard'
import * as ROOMS_SERVICES from "api/services/rooms/rooms.services";
import { calculateTimeDifference, isToday, StatusCode } from "common/enums";
import Breadcrumbs from "components/breadcrumbs";
import AppButton from "components/libs/button";
import CardAddCours from "components/libs/cardAddCours";
import * as appRoutes from "config/routes.config";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as TOPICS_SERVICES from "../../../api/services/topics/topics.services";
import AppScreen from "../ScreenWithSidebar";
import { default as s, default as styles } from "./style.module.css";

// !COPYtoCLIP-BOARD
import { CopyToClipboard } from "react-copy-to-clipboard";
//!IMAGES
import AppResult from "components/appResults";
import Flex from "components/libs/flex";
import CardClasses from "screens/teacher/components/cardClasses";
import { useGetAdHistoriqueQuery } from "store/services/teacher/ads.services";
import { useGetHistoriqueQuery } from "store/services/teacher/classes.services";
import ArrowBack from "../../../assets/images/app/arrow_back.svg";
import Camera from "../../../assets/images/app/camera.svg";
import ClockIcon from "../../../assets/images/app/clock_icon.svg";
import Close from "../../../assets/images/app/close.svg";
import CoursesIcons from "../../../assets/images/app/Cours.svg";
import EllipseTeacherProfileFour from "../../../assets/images/app/ellipse_teacher_profile4.png";
import EllipseTeacherProfileFive from "../../../assets/images/app/ellipse_teacher_profile5.png";
import EmptyIcon from "../../../assets/images/app/empty_icon.svg";
import ClassesIcons from "../../../assets/images/icons/teacher_classes.svg";
import Success from "../../../assets/images/teacher/success.svg";

import Matiers from "../../../components/matiers";
import AddRoom from "../components/addRoom";

interface CoursesRoomProps {
  course?: object | any;
}

const CoursesRoom: FC<CoursesRoomProps> = ({ course }) => {

  const auth = useSelector((state) => state.auth);

  return (
    <div className={s.classeCard} style={{ cursor: "pointer" }}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", paddingLeft: "20px", paddingRight: "20px" }}>
        <div className={s.avatarNameBox}>
          <Avatar.Group
            maxCount={3}
            maxStyle={{
              color: "#f56a00",
              backgroundColor: "#fde3cf",
            }}
            style={{ marginRight: "7px" }}
          >
            <Avatar
              size={45}
              style={{ backgroundColor: "#FFF2E0", boxShadow: "0px 3px 6px #00000029" }}
              src={course?.studId?.profile}
            />
          </Avatar.Group>
          <div style={{ fontSize: "13px", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
            {course?.studId?.firstname + " " + course?.studId?.lastname}
          </div>
        </div>

        <div className={s.detailsBox}>
          <div className={s.timeBox}>
            <img src={ClockIcon} height={15} style={{ marginRight: "10px" }}></img>
            <div className={s.times}>
              <span className={s.time}>De {course?.range[0]} à {course?.range[course?.range?.length - 1]}</span>
              <span className={s.timeDescription}>Séance de “{calculateTimeDifference(course?.range[0], course?.range[course?.range.length - 1])}”</span>
            </div>
          </div>
          <Matiers
            cours={course?.topic?.title.slice(0, 4) || "null"}
            level={course?.level?.title || "null"}
          />
          <div className={s.amountBox}>
            Séance réservée pour
            <div className={s.priceBox}>{course?.byCredits === "oui" ? "1 Crédit" : course?.price + " €"}</div>
          </div>
        </div>

        <a href={course?.profLink} target="_blank">
          <Button className={s.meetButton} icon={<img src={Camera} height={10} style={{ marginRight: "10px" }} />}>
            Lien vidéoconf
          </Button>
        </a>
      </div>
    </div>
  );
};

const { TabPane } = Tabs;

const { Paragraph, Text } = Typography;
const { Option } = Select;
const { Search } = Input;
const MESSAGE_PREFIX_PARTAGE_RESEAUX_SOCIAUX = "Bonjour ! Rejoins ma classe sur Monamilabert.com en utilisant ce code :";

const Rooms: FC = () => {
  //!HOOKS
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const [addCourseForm] = Form.useForm();
  const topics = useSelector((state) => state.topics);
  const levels = useSelector((state) => state.levels);
  const { data, isLoading, isError } = useGetHistoriqueQuery(auth?.user?._id);
  const {
    data: adHistorique,
    isLoading: adHistoriqueLoading,
    isError: adHistoriqueisError,
  } = useGetAdHistoriqueQuery(auth?.user?._id);

  const [form] = Form.useForm();
  const [values, setValues] = useState<any>({});
  const submit = useRef<any>(null);
  const [isloadingTopic, setIsloadingTopic] = useState<boolean>(false);

  //!STATE
  const [showAddCourseModal, setShowAddCourseModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [coursesError, setCoursesError] = useState("");
  const [rooms, setRooms] = useState<any>([]);
  const [addCourseLoading, setAddCourseLoading] = useState(false);
  const [addCourseError, setAddCourseError] = useState("");
  const [showAddClasse, setShowAddClasse] = useState(false);
  const [isModalSuccessAddCours, setIsModalSuccessAddCours] =
    useState<boolean>(false);
  const [tempRoom, setTempRoom] = useState<any>(null);
  const [tempTopic, setTempTopic] = useState<any>();
  const [visibleSocialMedia, setVisibleSocialMedia] = useState(false);

  const [code, setCode] = useState(null);

  const [currentTab, setCurrentTab] = useState<any>("1");
  const [courses, setCourses] = useState<any>([]);
  const [coursesHistory, setCoursesHistory] = useState<any>([]);
  /* const [levels, setLevels] = useState<any>([]); */

  //!FUNCTIONS
  const handleAddCourseOk = () => setShowAddCourseModal(true);
  const openAddCourseModal = () => setShowAddCourseModal(true);
  const goToTeacherRooms = () => navigate(appRoutes.TEACHER_ROOMS);
  const closeAddCourseModal = () => setShowAddCourseModal(false);
  const handleVisibleChange = () => setVisibleSocialMedia(!visibleSocialMedia);

  const fetchCourses = useCallback(async (id: string | undefined) => {
    try {
      setLoading(true);
      const response = await ROOMS_SERVICES.findByLevel(id, auth?.user?._id);
      if (response?.statusCode === StatusCode.OK) {
        setRooms(response.data);
      } else {
        setCoursesError(response?.message || "Erreur serveur");
      }
    } catch (error: any) {
      console.log(error);
      setCoursesError(error?.message || "error");
    } finally {
      setLoading(false);
    }
  }, []);

  const addCourse = useCallback(
    async (course: any) => {
      try {
        setAddCourseLoading(true);
        setAddCourseError("");

        const final = {
          ...course,
          teacherId: auth.user?._id,
        };

        const response = await ROOMS_SERVICES.createRoom(final);
        if (response?.statusCode === StatusCode.CREATED) {
          setTempRoom(response?.data);
          setRooms((prev: any) => [...prev, response.data]);
          setShowAddCourseModal(false);
          message.success("créé avec succès !");
          fetchCourses(auth.user?._id);
          addCourseForm.resetFields();
          setShowAddClasse(false);
          setTimeout(() => {
            setIsModalSuccessAddCours(true);
            setCode(response.data);
          }, 1500);
        } else {
          setAddCourseError(response?.message || "Erreur serveur");
        }
      } catch (error: any) {
        console.log(error);
        setAddCourseError(error?.message || "error");
        message.error(error?.message || "error");
      } finally {
        setAddCourseLoading(false);
      }
    },
    [addCourseForm]
  );
  const hide = () => setVisibleSocialMedia(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [isXsScreen, setIsXsScreen] = useState(window.innerWidth > 767 && window.innerWidth <= 1151);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth > 1151 && window.innerWidth <= 1321);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 1321 && window.innerWidth <= 1606);
  const [isNormalScreen, setIsNormalScreen] = useState(window.innerWidth > 1606 && window.innerWidth <= 1799);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
    setIsXsScreen(window.innerWidth > 767 && window.innerWidth <= 1151);
    setIsSmallScreen(window.innerWidth > 1151 && window.innerWidth <= 1321);
    setIsMediumScreen(window.innerWidth > 1321 && window.innerWidth <= 1606);
    setIsNormalScreen(window.innerWidth > 1606 && window.innerWidth <= 1799);
  };
  window.addEventListener('resize', handleResize);

  const OnSelectLevel = useCallback(
    async (levelId: string) => {
      setIsloadingTopic(true);
      try {
        const response = await TOPICS_SERVICES.findAll(levelId);
        if (response?.statusCode === StatusCode.OK) {
          setTempTopic(response?.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsloadingTopic(false);
      }
    },
    [tempTopic]
  );

  const filterCourses = useCallback(
    async (values: any) => {
      ///
    },
    [values]
  );

  const onReset = () => {
    form.resetFields();
    filterCourses({
      title: "",
      levelId: "",
      topicId: "",
      status: "",
      self: false,
    });
  };

  //!EFFECTS
  useEffect(() => {
    fetchCourses(auth.user?._id);
  }, [auth.user?._id, fetchCourses]);

  useEffect(() => {
    if (data?.data) {
      const sortedCourses = [...data.data].sort(
        (a: any, b: any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      );

      const groupedByDate = sortedCourses.reduce((acc: any, course: any) => {
        const date = new Date(course.startDate).toISOString().split("T")[0];
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(course);
        return acc;
      }, {});

      setCourses(groupedByDate);
    }
  }, [data]);

  useEffect(() => {
    if (adHistorique?.data) {
      const sortedCourses = [...adHistorique.data].sort(
        (a: any, b: any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      );

      const groupedByDate = sortedCourses.reduce((acc: any, course: any) => {
        const date = new Date(course.startDate).toISOString().split("T")[0];
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(course);
        return acc;
      }, {});

      setCoursesHistory(groupedByDate);
    }
  }, [adHistorique]);

  return (
    <>
      <Breadcrumbs isRooms={true} />
      <AppScreen>
        <img src={EllipseTeacherProfileFour} height={106} style={{ position: "absolute", top: "25%", left: "0px", zIndex: "-1" }}></img>
        <img src={EllipseTeacherProfileFive} height={54} style={{ position: "absolute", top: "35%", left: "94%", zIndex: "-1" }}></img>
        <div style={{ marginBottom: "80px" }}>
          <Tabs
            tabBarStyle={{ borderRadius: "0px", overflow: "hidden", border: "0px solid transparent !important", backgroundColor: "transparent !important" }}
            activeKey={currentTab}
            onChange={(key) => setCurrentTab(key)}
            type="line"
          >
            <TabPane
              tab={
                <div className={currentTab == "1" ? s.tabTitleBoxActive : s.tabTitleBox}>
                  <img src={CoursesIcons} height={18} style={{ marginRight: "10px" }}></img> Cours particuliers
                </div>
              }
              key="1"
            >
              <div className={s.informationBox} style={{ display: data?.data?.length > 0 ? "block" : "flex", flexDirection: "column", justifyContent: data?.data?.length === 0 ? "center" : "flex-start", alignItems: "center" }}>
                {!isLoading && data?.data?.length > 0 && <div className={s.newClassBox}>
                  <Button className={s.newButton} onClick={handleAddCourseOk}>
                    Historique des cours
                  </Button>
                </div>}
                {isLoading && (
                  <React.Fragment>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </React.Fragment>
                )}

                {!isLoading && data?.data?.length > 0 ? (
                  courses && Object.keys(courses)?.map((date) => {
                    return (
                      <>
                        <div key={date} style={{ marginTop: "10px" }}>
                          <div className={s.dateBox}>
                            {new Intl.DateTimeFormat("fr-FR", {
                              weekday: "long",
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            }).format(new Date(date))}
                            {isToday(date) && <div className={s.todayBox}>Aujourd’hui</div>}
                            <div className={s.horizontalLine}></div>
                          </div>
                          {courses[date].map((course: any) => (
                            <div key={course.id}>
                              <CoursesRoom course={course} />
                            </div>
                          ))}
                        </div>
                      </>
                    );
                  })
                ) : (
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", margin: "0px auto" }}>
                    Aucune réservation
                    <img src={EmptyIcon} height={120} style={{ marginTop: "20px" }}></img>
                  </div>
                )}
              </div>
            </TabPane>
            <TabPane
              tab={
                <div className={currentTab == "2" ? s.tabTitleBoxActive : s.tabTitleBox}>
                  <img src={ClassesIcons} height={18} style={{ marginRight: "10px" }}></img> Mes classes
                </div>
              }
              key="2"
            >
              <div className={s.classesBox}>
                <div className={s.newClassBox}>
                  <Button className={s.newButton} onClick={() => setShowAddClasse(!showAddClasse)}>
                    Créer une classe
                  </Button>
                </div>

                <div style={{ marginTop: "30px", overflowY: "scroll", scrollbarWidth: "thin" }}>
                  {loading && (
                    <React.Fragment>
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                    </React.Fragment>
                  )}

                  {!loading && (
                    <Flex>
                      {rooms.length > 0 && rooms?.map((course: any) => (
                        <React.Fragment>
                          <CardClasses course={course} />
                        </React.Fragment>
                      ))}
                      {rooms.length === 0 && (
                        <CardAddCours
                          onClick={() => setShowAddClasse(!showAddClasse)}
                        />
                      )}
                    </Flex>
                  )}
                </div>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </AppScreen>

      {/* Drawer add Cours */}
      <Drawer
        className="drawer"
        onClose={() => setShowAddCourseModal(!showAddCourseModal)}
        visible={showAddCourseModal}
        width={isMobile ? "100%" : isXsScreen ? "100%" : isSmallScreen ? "100%" : isMediumScreen ? "97%" : isNormalScreen ? "92%" : "70%"}
        title={<div style={{ fontSize: "20px", fontFamily: "Poppins", fontWeight: "600", color: "#393E3E", backgroundColor: "transparent" }}>Historique des cours</div>}
        headerStyle={{ border: "none", paddingTop: "50px", paddingLeft: "40px", backgroundColor: "transparent" }}
        closeIcon={
          <img
            src={ArrowBack}
            height={18}
            style={{}}
          ></img>
        }
        drawerStyle={{ backgroundColor: "#FFF", borderTopLeftRadius: "50px", borderBottomLeftRadius: "50px", boxShadow: "-3px 3px 34px #39393929" }}
      >
        <div className={styles.__first_drawer}>
          <div className={styles.drawer__content}>

            {adHistoriqueLoading ? (
              <>
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
              </>
            ) : (
              <>
                {!adHistoriqueLoading && adHistorique?.data.length <= 0 ? (
                  <AppResult
                    status={"info"}
                    title="Tu n’as aucune réservation"
                  />
                ) : (
                  <div className={s.historyBox}>
                    {coursesHistory && Object.keys(coursesHistory)?.map((date) => {
                      return (
                        <>
                          <div key={date} style={{ marginTop: "10px" }}>
                            <div className={s.dateBox}>
                              {new Intl.DateTimeFormat("fr-FR", {
                                weekday: "long",
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              }).format(new Date(date))}
                              {isToday(date) && <div className={s.todayBox}>Aujourd’hui</div>}
                              <div className={s.horizontalLine}></div>
                            </div>
                            {coursesHistory[date].map((course: any) => (
                              <div key={course.id}>
                                <CoursesRoom course={course} />
                              </div>
                            ))}
                          </div>
                        </>
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Drawer>

      {/* Drawer Add Rooms */}
      <AddRoom
        addCourse={addCourse}
        addCourseLoading={addCourseLoading}
        showAddClasse={showAddClasse}
        setShowAddClasse={setShowAddClasse}
        addCourseForm={addCourseForm}
      />

      {/* Modal add cours */}
      <Modal
        title={null}
        closable={false}
        centered
        visible={isModalSuccessAddCours}
        onCancel={() => setIsModalSuccessAddCours(false)}
        footer={null}
        width={800}
      >
        <div className={styles.__success__modal_container}>
          <div className={styles.modal__card}>
            <h3 className={styles.modal__card__title}>
              Classe de {tempRoom?.title}
            </h3>
            <Matiers
              level={tempRoom?.level?.title}
              cours={tempRoom?.course?.title}
              className={styles.__matier}
              classNameIcon={styles.__icon_matiere}
            />
            <div className={styles.__modal__img__success}>
              <Image preview={false} src={Success} width={100} />
            </div>

            <span className={styles.__code__access__title}>
              Code d’accès{" "}
            </span>

            <div className={styles.__code}>
              <span>
                {tempRoom?.accessCode.slice(0, 3) +
                  " - " +
                  tempRoom?.accessCode.slice(3, 6)}
              </span>
            </div>

            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
              <div>
                <CopyToClipboard
                  text={tempRoom?.accessCode}
                  onCopy={() =>
                    message.success({
                      content: "copié dans le presse-papiers",
                    })
                  }
                >
                  <AppButton className={styles.__btn}>Copier</AppButton>
                </CopyToClipboard>{" "}
              </div>
              <div>
                <Popover
                  content={
                    <>
                      <div>
                        {" "}
                        <SOCIAL_SHARING
                          prefix={MESSAGE_PREFIX_PARTAGE_RESEAUX_SOCIAUX}
                          codeAcces={tempRoom?.accessCode + ", à bientôt"}
                        />
                      </div>
                      <div style={{ width: "100%", textAlign: "center" }}>
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            hide();
                          }}
                          href="/"
                        >
                          Fermer
                        </a>
                      </div>
                    </>
                  }
                  trigger="click"
                  visible={visibleSocialMedia}
                  onVisibleChange={handleVisibleChange}
                >
                  {" "}
                  <AppButton className={styles.__btn}>partager</AppButton>
                </Popover>{" "}
              </div>
            </div>
          </div>

          <div
            className={styles.close}
            onClick={() => setIsModalSuccessAddCours(false)}
          >
            <Image preview={false} src={Close} width={25} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Rooms;
