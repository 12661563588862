import { FormOutlined } from "@ant-design/icons";
import {
  Alert,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Radio,
  Row,
  Select,
  Skeleton,
  Steps,
} from "antd";
import { registerUserDto } from "api/services/auth/dto/login-user.dto";
import Container from "components/container";
import CryptoJS from 'crypto-js';
import React, { Dispatch, FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageUploading from "react-images-uploading";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { registerStudentWithEmailAndPassword } from "store/auth/authSlice";
import * as TEACHER_SERVICES from "../../../api/services/teacher/teacher.services";
//!COMPONENTS
import H1 from "components/libs/title";

//!IMAGES

import arrowDown from "../../../assets/images/app/arrow_bottom_down.svg";
import arrowUp from "../../../assets/images/app/arrow_top_up.svg";
import Avatar from "../../../assets/images/students/student.svg";

//!STYLES
import s from "./assets/register.module.css";
import style from "./assets/stepTwo.module.css";

//!ROUTES
import { fetchSchools } from "api/services/courses/courses.services";
import { findLevelsById } from "api/services/levels/levels.services";
import { getSystemList } from "api/services/studentQuizz/studentQuizz.services";
import { StatusCode } from "common/enums";
import AppButton from "components/libs/button";
import H2 from "components/libs/subtitle";
import * as appRoutes from "config/routes.config";
import { useGetAllLevelsQuery } from "store/services/app/levels.services";
import AnimatedPage from "utils/animatedPage";

const { Step } = Steps;
const { Search } = Input;

const RegisterStudent = () => {
  //!HOOK
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { data: levelsData, isLoading, isError } = useGetAllLevelsQuery("");
  const levels = useSelector((state) => state.levels);
  const [form] = Form.useForm();
  const location = useLocation();

  //!STATE
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [firstStepData, setFirstStepData] = useState<any>({});
  const [tempLevelSelected, setTempLevelSelected] = useState<any>(null);
  const [countriesList, setCountries] = useState<any>([]);
  const [schoolList, setSchools] = useState<string[]>([]);
  const [dataList, setDataList] = useState<any>([]);
  const [educationSystemList, setEducationSystem] = useState<string[]>([]);

  const [country, setCountry] = useState<string>();
  const [system, setSystem] = useState<string>();
  const [studentEmail, setStudentEmail] = useState<string>();

  const [checkingPrimary, setCheckPrimary] = useState<boolean>(false);
  const [checkingCollege, setCheckCollege] = useState<boolean>(false);
  const [checkingLycee, setCheckLycee] = useState<boolean>(false);
  const [checkingPrepa, setCheckPrepa] = useState<boolean>(false);

  const [loadingSystems, setLoadingSystems] = useState<boolean>(false);
  const [loadingSchools, setLoadingSchools] = useState<boolean>(false);

  const [images, setImages] = React.useState<any>([]);
  const maxNumber = 69;
  const studentIcon = "/assets/avatars/student.svg";

  //!CONSTANTS
  const validateMessages = {
    required: t("auth.error.required"),
    types: {
      email: t("auth.error.emailInvalid"),
    },
  };

  //!FUNCTIONS
  const registerUser = (user: registerUserDto) => {
    if (currentStep === 1) {
      setFirstStepData(user);
      window.scrollTo(0, 0);
      return setCurrentStep(2);
    }
    const firstStep = {
      ...firstStepData,
      profile: images[0]?.data_url || studentIcon,
    };
    user = { ...firstStep, levelId: tempLevelSelected?._id, ...user };
    console.log(user);

    dispatch(registerStudentWithEmailAndPassword(user));
  };

  const getStaticLists = useCallback(
    async (type: string, dataList: any, state: Dispatch<any>) => {
      const response = await TEACHER_SERVICES.getDataList(type);
      if (response?.statusCode === StatusCode.OK) {
        state(response?.data);
      }
    },
    [dataList]
  );

  const handleCountryChange = (value: string) => {
    setCountry(value);
  };

  const handleSystemChange = (value: string) => {
    setSystem(value);
  };

  const getSchoolsLists = useCallback(
    async () => {
      try {
        setLoadingSchools(true);
        if (country !== undefined && system !== undefined) {
          const response = await fetchSchools(country, system);
          if (response?.statusCode === StatusCode.OK) {
            console.log(response.data);
            const namesArray = response.data.map((item: { name: any; }) => item);
            setSchools(namesArray);
          }
        }
      }
      catch (error) {
        ///message.error("Une erreur non connue s'est produite.");
      }
      finally {
        setLoadingSchools(false);
      }
    },
    [country, system]
  );

  const getSystemLists = useCallback(
    async () => {
      try {
        setLoadingSystems(true);
        if (country !== undefined) {
          const response = await getSystemList(country);
          if (response?.statusCode === StatusCode.OK) {
            console.log(response.data);
            const systemArray = response.data.map((item: { name: any; }) => item);
            setEducationSystem(systemArray);
          }
        }
      }
      catch (error) {
        ///message.error("Une erreur non connue s'est produite.");
      }
      finally {
        setLoadingSystems(false);
      }
    },
    [country]
  );

  const getLevelById = useCallback(
    async (idLevel: any) => {
      try {
        if (idLevel) {
          const response = await findLevelsById(idLevel);
          if (response.statusCode === StatusCode.OK) {
            setTempLevelSelected(response?.data);
          }
        }
      } catch (error) {
        // message.error("Une erreur non connue s'est produite.");
      }
    },
    [tempLevelSelected]
  );

  const onChange = (imageList: any, addUpdateIndex: any) => {
    setImages(imageList);
  };

  const allLevels = levelsData?.data;

  //!EFFECTS
  useEffect(() => {
    getStaticLists('pays', countriesList, setCountries);
    if (auth.isLoggedIn) navigate(appRoutes.STUDENT_DASHBOARD);
  }, [auth.isLoggedIn, navigate]);

  useEffect(() => {
    getSchoolsLists();
  }, [country, system]);

  useEffect(() => {
    getSystemLists();
  }, [country]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams !== undefined) {
      const token = searchParams.get('token');
      if (token !== null) {
        const decodedToken = token!.replace(/\s/g, '+');
        // Decrypt
        const secretKey = "maa-email-encrypt-token";
        const decryptedBytes = CryptoJS.AES.decrypt(decodedToken, secretKey!);
        const decodedEmail = decryptedBytes.toString(CryptoJS.enc.Utf8);
        setStudentEmail(decodedEmail);
      }
    }
  }, [location.search]);

  return (
    <AnimatedPage>
      <div className={s.register}>
        <Form
          form={form}
          validateTrigger={["onFinish"]}
          validateMessages={validateMessages}
          initialValues={{ acceptedTermsAndConditions: true }}
          name="registerTeacher"
          autoComplete="off"
          onFinish={registerUser}
        >
          {currentStep === 1 && (
            <Container>
              <div className={s.header}>
                <H1>Crée ton compte</H1>
                <h3 className={s.__title_choice__avatar}>Choisis ton avatar</h3>
                <Form.Item name="avatar" hidden initialValue="null"></Form.Item>

                <Row justify="center">
                  <Form.Item name="profile" style={{ margin: 0 }}>
                    <div className={s.avatar}>
                      <ImageUploading
                        multiple
                        value={images}
                        onChange={onChange}
                        maxNumber={maxNumber}
                        // maxFileSize={2}
                        dataURLKey="data_url"
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          <React.Fragment>
                            <button
                              type="button"
                              className={s.btn__Upload}
                              style={isDragging ? { color: "red" } : undefined}
                              onClick={() => {
                                imageList[0]?.data_url
                                  ? onImageUpdate(0)
                                  : onImageUpload();
                              }}
                              {...dragProps}
                            >
                              <FormOutlined
                                style={{
                                  fontWeight: "600 !important",
                                  color: "var(--first-color)",
                                  fontSize: "15px",
                                }}
                              />
                            </button>

                            <img
                              src={imageList[0]?.data_url || Avatar}
                              alt=""
                            />
                          </React.Fragment>
                        )}
                      </ImageUploading>
                    </div>
                  </Form.Item>
                </Row>
              </div>

              <div className={s.form__content}>
                <h3>Choisis ton pseudo</h3>
                <div className={s.__input_choice_pseudo}>
                  <Form.Item
                    name="pseudo"
                    style={{ margin: 0, padding: 0 }}
                    rules={[
                      { required: true },
                      { max: 12, message: "12 caractère maximum" },
                    ]}
                  >
                    <Input
                      placeholder="pseudo"
                      bordered={false}
                      defaultValue={firstStepData?.pseudo}
                    />
                  </Form.Item>
                </div>

                <h3>Pays de Résidence</h3>
                <div className={s.__input_choice_pseudo}>
                  <Form.Item
                    style={{ margin: "0px" }}
                    name="pays"
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      style={{ padding: "0px", fontSize: "16px", color: "#333", textTransform: "uppercase" }}
                      className={s.selectInput}
                      placeholder={<div style={{ fontSize: "16px", color: "#C0C0C0", textTransform: "uppercase" }}>Choisis ton pays</div>}
                      bordered={false}
                      allowClear
                      dropdownStyle={{ padding: "3px" }}
                      showArrow={true}
                      value={country}
                      onSelect={handleCountryChange}
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) =>
                        option?.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {countriesList.map((item: any, i: any) => (<Select.Option key={i} value={item}>{item}</Select.Option>))}
                    </Select>
                  </Form.Item>
                </div>

                <h3>Système d’éducation</h3>
                <div className={s.__input_choice_system}>
                  <Form.Item
                    style={{ margin: "0px" }}
                    name="educSystem"
                    rules={[{ required: true }]}
                  >
                    <Select
                      style={{ padding: "0px", fontSize: "16px", color: "#333", textTransform: "uppercase" }}
                      className={s.selectInput}
                      placeholder={<div style={{ fontSize: "16px", color: "#C0C0C0", textTransform: "uppercase" }}>Choisis ton système</div>}
                      bordered={false}
                      allowClear
                      dropdownStyle={{ padding: "3px" }}
                      showArrow={true}
                      value={system}
                      loading={loadingSystems}
                      optionFilterProp="children"
                      onSelect={handleSystemChange}
                    >
                      {!loadingSystems && educationSystemList.map((item: any, i: any) => (<Select.Option key={i} value={item}>{item}</Select.Option>))}
                    </Select>
                  </Form.Item>
                </div>

                <h3>Établissement Scolaire</h3>
                <div className={s.__input_choice_school}>
                  <Form.Item
                    style={{ margin: "0px" }}
                    name="etablissement"
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      style={{ padding: "0px", fontSize: "16px", color: "#333", textTransform: "uppercase" }}
                      className={s.selectInput}
                      placeholder={<div style={{ fontSize: "16px", color: "#C0C0C0", textTransform: "uppercase" }}>Choisis ton établissement</div>}
                      bordered={false}
                      allowClear
                      dropdownStyle={{ padding: "3px" }}
                      showArrow={true}
                      loading={loadingSchools}
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) =>
                        option?.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {!loadingSchools && schoolList.map((item: any, i: any) => (<Select.Option key={i} value={item}>{item}</Select.Option>))}
                    </Select>
                  </Form.Item>
                </div>

                <h3>En quelle classe es-tu ?</h3>

                <div className={s.form}>
                  {isLoading ? (
                    <div>
                      <Skeleton active />
                      <Skeleton active />
                    </div>
                  ) : (
                    <Form.Item name="levelId">
                      <Radio.Group
                        className={s.radio__group}
                        onChange={(id: any) => getLevelById(id?.target?.value)}
                      >

                        <div onClick={() => setCheckPrimary(!checkingPrimary)} style={{ cursor: "pointer", display: "flex", flexDirection: "row" }}><H2 className={s.title__level}>Primaire </H2><img src={checkingPrimary ? arrowUp : arrowDown} style={{ position: "relative", top: "4px", left: "5px", height: "20px" }} /></div>
                        <Row justify="center">
                          {allLevels?.Primaire?.map((level: any, i: number) => (
                            <div key={i} style={{ display: checkingPrimary ? "block" : "none" }}>
                              <Radio.Button
                                className={s.field}
                                value={level._id}
                              >
                                {level.title}
                              </Radio.Button>
                            </div>
                          ))}
                        </Row>

                        <div onClick={() => setCheckCollege(!checkingCollege)} style={{ cursor: "pointer", display: "flex", flexDirection: "row" }}><H2 className={s.title__level}>Collège </H2><img src={checkingCollege ? arrowUp : arrowDown} style={{ position: "relative", top: "4px", left: "5px", height: "20px" }} /></div>
                        <Row justify="center">
                          {allLevels?.Collège?.map((level: any, i: number) => (
                            <div key={i} style={{ display: checkingCollege ? "block" : "none" }}>
                              <Radio.Button
                                className={s.field}
                                value={level._id}
                              >
                                {level.title}
                              </Radio.Button>
                            </div>
                          ))}
                        </Row>

                        <div onClick={() => setCheckLycee(!checkingLycee)} style={{ cursor: "pointer", display: "flex", flexDirection: "row" }}><H2 className={s.title__level}>Lycée </H2><img src={checkingLycee ? arrowUp : arrowDown} style={{ position: "relative", top: "4px", left: "5px", height: "20px" }} /></div>
                        <Row justify="center">
                          {allLevels?.Lycée?.map((level: any, i: number) => (
                            <div key={i} style={{ display: checkingLycee ? "block" : "none" }}>
                              <Radio.Button
                                className={s.field}
                                value={level._id}
                              >
                                {level.title}
                              </Radio.Button>
                            </div>
                          ))}
                        </Row>

                        <div onClick={() => setCheckPrepa(!checkingPrepa)} style={{ cursor: "pointer", display: "flex", flexDirection: "row" }}><H2 className={s.title__level}>Prépa </H2><img src={checkingPrepa ? arrowUp : arrowDown} style={{ position: "relative", top: "4px", left: "5px", height: "20px" }} /></div>
                        <Row justify="center">
                          {allLevels?.Prépa?.map((level: any, i: number) => (
                            <div key={i} style={{ display: checkingPrepa ? "block" : "none" }}>
                              <Radio.Button
                                className={s.field}
                                value={level._id}
                              >
                                {level.title}
                              </Radio.Button>
                            </div>
                          ))}
                        </Row>
                      </Radio.Group>
                    </Form.Item>
                  )}
                </div>

                <Row justify="center">
                  <Form.Item style={{ margin: 0 }}>
                    <AppButton className={`${s.button}`} htmlTypeSubmit={true}>
                      Suivant
                    </AppButton>
                  </Form.Item>
                </Row>

                <div className={style.info}>
                  <p>
                    Vous avez déjà un compte ?{" "}
                    <Link to={appRoutes.LOGINS}> Connecte-toi</Link>{" "}
                  </p>
                </div>
              </div>
            </Container>
          )}

          {currentStep === 2 && (
            <div>
              <div className={style.register__teacher}>
                <Container>
                  <H1>Créez votre compte</H1>

                  <div className={style.content__action}>
                    <div className={style.student__action}>
                      <div className={style.avatar}>
                        <Image
                          preview={false}
                          src={images[0]?.data_url || studentIcon}
                          alt="teacher avatar"
                        />
                      </div>
                      <div className={style.text}>
                        <p>
                          Hello <strong>{firstStepData?.pseudo}</strong>
                          <br />
                          Niveau <strong>{tempLevelSelected?.title}</strong>
                          <br />
                          <Link to={"#"} onClick={() => setCurrentStep(1)}>
                            Modifier
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className={style.form__container}>
                    <Row gutter={10}>
                      <Col span={12}>
                        <Form.Item
                          name="firstname"
                          rules={[{ required: true }]}
                          style={{ marginBottom: "20px" }}
                        >
                          <Input
                            className={style.field}
                            placeholder={t("auth.firstname")}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="lastname"
                          rules={[{ required: true }]}
                          style={{ marginBottom: "20px" }}
                        >
                          <Input
                            className={style.field}
                            placeholder={t("auth.lastname")}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      name="email"
                      rules={[{ required: true }, { type: "email" }]}
                      style={{ marginBottom: "20px" }}
                      initialValue={studentEmail !== undefined ? studentEmail : ""}
                    >
                      <Input
                        className={style.field}
                        type="email"
                        placeholder={t("auth.email")}
                        readOnly={studentEmail !== undefined ? true : false}
                      />
                    </Form.Item>
                    <Form.Item
                      name="phone"
                      rules={[{ required: false }]}
                      style={{ marginBottom: "20px" }}
                    >
                      <Input
                        className={style.field}
                        placeholder={"Numéro de téléphone (optionnel)"}
                        type="number"
                        pattern="[0-9]*"
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      rules={[
                        { required: true },
                        {
                          pattern: /^.{6,}$/,
                          message:
                            "Le mot de passe doit contenir au moins 6 caractères !",
                        },
                      ]}
                      style={{ marginBottom: "20px" }}
                    >
                      <Input.Password
                        className={style.field}
                        placeholder={t("auth.password")}
                      />
                    </Form.Item>
                    <Form.Item
                      name="confirmPassword"
                      rules={[
                        { required: true },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("Les mots de passe ne correspondent :(")
                            );
                          },
                        }),
                      ]}
                      style={{ marginBottom: "20px" }}
                    >
                      <Input.Password
                        className={style.field}
                        placeholder={t("auth.confirmPassword")}
                      />
                    </Form.Item>

                    <div className={style.termes__legals}>
                      <Form.Item
                        name="acceptedTermsAndConditions"
                        valuePropName="checked"
                        style={{ marginBottom: "20px" }}
                      >
                        <Checkbox className={style.policy}>
                          {" "}
                          J'accepte de recevoir des conseils pédagogiques et des
                          offres <br /> promotionnelles de Mon ami Albert par
                          e-mail.{" "}
                        </Checkbox>
                      </Form.Item>
                    </div>

                    <AppButton
                      loading={auth.isRegistering}
                      className={style.button}
                      htmlTypeSubmit={true}
                    >
                      Terminer
                    </AppButton>

                    <div className={style.info}>
                      <span>
                        En cliquant sur terminer, vous acceptez les conditions
                        générales d'utilisation et la politique de
                        confidentialité de Mon ami Albert.
                      </span>
                    </div>
                    <div className={style.info}>
                      <p>
                        Vous avez déjà un compte ?{" "}
                        <Link to={appRoutes.LOGINS}> Connecte-toi</Link>{" "}
                      </p>
                    </div>

                    {auth.registerErrors.length > 0 &&
                      auth.registerErrors.map((error, index) => (
                        <Alert
                          key={index}
                          message={error}
                          type="error"
                          showIcon
                        />
                      ))}
                  </div>

                  {/* <RegisterTeacherForm /> */}
                </Container>
              </div>
            </div>
          )}
        </Form>
      </div>
    </AnimatedPage>
  );
};

type Props = {
  file: any;
};

const ImagePreview: FC<Props> = ({ file }) => {
  const [preview, setPreview] = useState<any>(null);

  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    setPreview(reader.result);
  };

  return preview ? <img src={preview} /> : <p>loading...</p>;
};

export default RegisterStudent;
