import { Image, Skeleton } from "antd";
import useStudentOneCourse from "hooks/useStudentCourse";
import "katex/dist/katex.min.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Latex from "react-latex-next";
import { Link, useParams } from "react-router-dom";

import Breadcrumbs from "components/breadcrumbs";
import ReactFullscreen from "react-easyfullscreen"; // Assurez-vous d'importer le bon type pour FullscreenHandle
import AppScreen from "screens/teacher/ScreenWithSidebar";
import HeaderComponents from "../components/headerComponents";
import TabsStudent from "../components/tabs";

//!IMAGES
import close from "assets/images/app/delete.svg";
import logo from "assets/images/app/levieuxalbert.svg";

//!STYLE
import AppButton from "components/libs/button";
import { useDispatch, useSelector } from "react-redux";
import { setFullscreen } from "store/app/appSlice";
import s from "./assets/cours.module.css";

const StudentCours = () => {
  //!HOOKS
  const dispatch = useDispatch();
  const params = useParams<"id">();
  const { loading, data } = useStudentOneCourse(params.id!);
  const divRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLButtonElement>(null);
  const divContentRef = useRef<HTMLDivElement>(null);
  const { isFullscreen } = useSelector((state) => state.app);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  const handleScroll = useCallback(() => {
    const divScrollTop = divRef.current?.scrollTop || 0;
    const bodyScrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    if (
      divScrollTop > 0.01 * window.innerHeight &&
      bodyScrollTop < 0.12 * document.body.scrollHeight
    ) {
      window.scrollTo({
        top: 0.12 * document.body.scrollHeight,
        behavior: "smooth",
      });
      divRef.current?.removeEventListener("scroll", handleScroll);
    }
  }, []);

  useEffect(() => {
    const scrollHandler = () => {
      const bodyScrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (bodyScrollTop < 0.12 * document.body.scrollHeight) {
        divRef.current?.addEventListener("scroll", handleScroll);
      }
    };
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
      divRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape" && isFullscreen) {
        btnRef.current?.click();
      }
    },
    [isFullscreen]
  );

  useEffect(() => {
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyUp]);

  useEffect(() => {
    const protectedTextElement = document.getElementById('protectedText');

    const handleCopy = (event: any) => {
      // Prevent the copy event
      event.preventDefault();
      alert("La copie n'est pas autorisée");
    };

    const handlePaste = (event: any) => {
      // Prevent the paste event
      event.preventDefault();
      alert("Le collage n'est pas autorisé.");
    };

    const handleContextMenu = (event: any) => {
      // Prevent the context menu
      event.preventDefault();
      alert("Le menu contextuel n'est pas autorisé.");
    };

    if (protectedTextElement) {
      protectedTextElement.addEventListener('copy', handleCopy);
      protectedTextElement.addEventListener('paste', handlePaste);
      protectedTextElement.addEventListener('contextmenu', handleContextMenu);

      return () => {
        // Remove event listeners when the component unmounts
        protectedTextElement.removeEventListener('copy', handleCopy);
        protectedTextElement.removeEventListener('paste', handlePaste);
        protectedTextElement.removeEventListener('contextmenu', handleContextMenu);
      };
    }
  }, []);

  return (
    <ReactFullscreen>
      {({ ref, onRequest, onExit }) => (
        <div ref={divContentRef} style={{ overflow: "hidden" }} id="protectedText">
          {isFullscreen && <header className={s.header}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between", width: "auto" }}>
              <div className={s.menu}>
                {!isMobile && <div className={s.logo}>
                  <Link to={"/"}>
                    <Image preview={false} src={logo} width={60} />
                  </Link>
                </div>}
                {!isMobile && <div style={{ fontSize: "1.2em", color: "#333333", fontWeight: "bold", marginLeft: "10px" }}>
                  Accueil
                  <span style={{ fontSize: "1em", color: "#70B2B3", fontWeight: "bold" }}>{" > Cours"}</span>
                </div>}
                {!isMobile && <div><HeaderComponents coursId={params.id!} /></div>}
                <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <TabsStudent
                    idChapter={params.id}
                    textBack="RETOUR AUX CHAPITRES"
                    isFullscreen={isFullscreen}
                    onRequest={onRequest}
                    onExit={onExit}
                  />
                </div>
                {!isMobile && <div>
                  <AppButton onClick={() => {
                    onExit();
                    dispatch(setFullscreen(false));
                  }}>
                    <Image preview={false} src={close} width={15} />
                    <span style={{ fontSize: "12px", marginLeft: "5px", marginTop: "2px" }}> Fermer</span>
                  </AppButton>
                </div>}
              </div>
            </div>
          </header>}
          {!isFullscreen && <Breadcrumbs isMycontent={true} isBack={true} />}
          <AppScreen>
            {!isFullscreen && (
              <>
                <HeaderComponents coursId={params.id!} />
                <TabsStudent
                  idChapter={params.id}
                  textBack="RETOUR AUX CHAPITRES"
                  isFullscreen={isFullscreen}
                  onRequest={onRequest}
                  onExit={onExit}
                />
              </>
            )}

            {/* <Row justify="center">
              {!isFullScren ? (
                <Button
                  onClick={() => {
                    onRequest();
                    setFullScreen(true);
                  }}
                >
                  Mode plein écran
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    onExit();
                    setFullScreen(false);
                  }}
                >
                  Sortir plein écran
                </Button>
              )}
            </Row> */}

            <div
              className={s.appercue__continute}
              ref={divRef}
              onScroll={handleScroll}
            >
              {!loading && <h3>Cours : {data?.title}</h3>}

              {loading ? (
                <React.Fragment>
                  <Skeleton active />
                  <Skeleton active />
                  <Skeleton active />
                  <Skeleton active />
                  <Skeleton active />
                </React.Fragment>
              ) : (
                <>
                  {data?.contents?.map((field: any, i: number) => {
                    console.log(field);
                    return (<div key={i} className={s.__body}>
                      {data?.contents?.length > 1 && <div className={s.__big__title__content}>
                        <h3 className={s.__big__title}>
                          {i + 1} - {field?.title}
                        </h3>
                      </div>}

                      <div className={s.appercue__continute__content}>
                        {field?.subtitles?.map((x: any) => (
                          <>
                            <h4>{x?.title}</h4>
                            {x?.subtitles?.map((item: any) => {
                              let bgColor = "";
                              bgColor = item?.title?.includes("finition") ? "#30d1c7" :
                                item?.title?.includes("orème") ? "#2eb71e" :
                                  item?.title?.includes("oreme") ? "#2eb71e" :
                                    item?.title?.includes("emarque") ? "#2eb71e" :
                                      item?.title?.includes("xemple") ? "#2eb71e" :
                                        item?.title?.includes("imites") ? "#2eb71e" :
                                          item?.title?.includes("ropriété") ? "#29a4e1" :
                                            item?.title?.includes("roprieté") ? "#29a4e1" : "var(--first-color)";
                              return (<>
                                <div className={s.section__content} style={{ background: bgColor }}>
                                  <h4>{item?.title}</h4>
                                </div>

                                <Latex>
                                  {item?.content
                                    ? item?.content
                                      ?.replaceAll("amp;", "")
                                      ?.replaceAll("<br />", "")
                                    : ""}
                                </Latex>
                              </>
                              )
                            })}
                          </>
                        ))}
                      </div>
                    </div>
                    )
                  })}
                </>
              )}
            </div>
          </AppScreen>
        </div>
      )}
    </ReactFullscreen>
  );
};

export default StudentCours;
