import { Button, Popconfirm, Tooltip } from "antd";
import React, { FC, Fragment, useEffect } from "react";

import "katex/dist/katex.min.css";
import Latex from "react-latex-next";

//IMAGES
import EnglishIcon from "assets/images/students/englishIcon.svg";
import FrenchIcon from "assets/images/students/frenchIcon.svg";
import StartIcon from "assets/images/students/teacherStart.svg";
import TEACHER from "../../../../assets/images/teacher/teacher.svg";

import InstaIcon from "../../../../assets/images/app/insta_icon.svg";
import LikeIcon from "../../../../assets/images/app/like_icon.svg";
import MessageIcon from "../../../../assets/images/app/message_icon.svg";
import PlanningButtonIcon from "../../../../assets/images/app/planning_icon.svg";


//!STYLES
import { FLAGS } from "common/enums";
import { useDispatch, useSelector } from "react-redux";
import { setIsDrawerInstaProf } from "store/chat/roomSlice";
import s from "./assets/cardTeacher.module.css";

import * as appRoutes from "config/routes.config";

import { useNavigate } from "react-router-dom";
import { setIdTeacher, setSeeAvis, setUserConnect } from "store/auth/authSlice";
import { openPaiementDrawer } from "store/chat/mainSlice";
import { handleClearNiveau, handleClearTopic } from "utils/formats";

import CertificationBadge from "components/certificationBadge";
import {
  setIdReceiver,
  setSelectUserChat,
  toggleAppChat,
} from "store/AppChat/appChat";
import { useFindRoomMutation } from "store/services/chat";
import IconTopic from "./iconTopic";

interface CardAdsTeacher {
  ad: any;
  PlanningIsVisble?: boolean;
  isInstaProf?: boolean;
  instaProfData?: any;
  infoTeacherAcceptedReservation?: any;
  currentProfAccepted?: any;
  setCurrentProfAccepted?: any;
  isBtnInstaProf: boolean;
  resetDrawerInstaProf?: () => void;
  handleStudentAcceptTeacher?: any;
  showDrawerSearchInstaProf: () => void;
  handleDisplayTeacher: (id: string) => void;
  onShowDrawerCalendarProf: () => void;
  handleOpenDrwerProfileTeacher: () => void;
  setIsBtnInstaProf: React.Dispatch<React.SetStateAction<boolean>>;
  suggestedTeachers?: boolean;
}

const CardAdsTeacher: FC<CardAdsTeacher> = ({
  ad,
  PlanningIsVisble = true,
  isInstaProf,
  isBtnInstaProf,
  handleStudentAcceptTeacher,
  setCurrentProfAccepted,
  onShowDrawerCalendarProf,
  setIsBtnInstaProf,
  handleDisplayTeacher,
  handleOpenDrwerProfileTeacher,
  showDrawerSearchInstaProf,
  suggestedTeachers,
}) => {
  //!HOOKS
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [findRoom, { isSuccess }] = useFindRoomMutation();

  const closeDrawerPaiement = () => {
    dispatch(openPaiementDrawer());
  };

  const day = new Date();
  const handleDisplayTeacherMSG = (idTeacher: string) => {
    if (isInstaProf) {
      dispatch(setIsDrawerInstaProf(true));
    }
    const finalData = {
      actor1: user?._id,
      actor2: idTeacher,
      flag: FLAGS.SINGLE_FLAG,
      type: `${isInstaProf ? "instaprof" : ""}`,
      instaprof: `${isInstaProf ? "find" : ""}`,
    };

    findRoom(finalData);
    dispatch(setIdReceiver(idTeacher));
  };

  const handleProfileClick = () => {
    if (isLoggedIn) {
      dispatch(setIdTeacher(ad?._id));
      isInstaProf && handleOpenDrwerProfileTeacher();
      navigate(`${appRoutes.PROFILETEACHER}/${ad?.teacher?._id}/${ad?.teacher?.firstname ? ad?.teacher?.firstname : "nom"}.${ad?.teacher?.lastname ? ad?.teacher?.lastname : "complet"}`);
    } else {
      dispatch(setUserConnect(true));
    }
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(setSelectUserChat(true));
      dispatch(toggleAppChat(true));
    }
    console.log("Teacher ad : ", ad);
  }, [isSuccess, ad]);

  return (
    <React.Fragment>
      <div className={`${s.card} ${!isInstaProf && `square`}`} >
        {suggestedTeachers && user?.accountManaged !== "" && <div className={s.ribbon}>
          <img src={LikeIcon} height={12} style={{ position: "relative", bottom: "2px", marginRight: "5px" }}></img> Recommandé
          {ad?.credits > 0 && <div> : {ad?.credits} <span className={s.credit}>CDT</span></div>}
        </div>}
        <div className={s.avatarNameBox} onClick={handleProfileClick}>
          <div className={s.avatarBox}><img src={ad?.teacher?.profile || TEACHER} height={70} style={{ borderRadius: "50%" }} /></div>
          <div className={s.nameRateBox}>
            <Tooltip
              title={
                <span style={{ textTransform: "capitalize" }}>
                  {ad?.teacher?.firstname + " " + ad?.teacher?.lastname ||
                    "-"}
                </span>
              }
            >
              <div className={s.nameBox}>
                {ad?.teacher?.firstname?.split(" ")[0] || "-"}{" "}
                {ad?.teacher?.lastname?.split(" ")[0] || "-"}
                {ad?.teacher?.certified && <CertificationBadge />}
              </div>
            </Tooltip>

            <span style={{ textTransform: "uppercase", lineHeight: "14px" }}>
              {ad?.prof?.points > 0 &&
                ad?.prof?.points <= 1000 &&
                "C'est mon ami"}
              {ad?.prof?.points > 1000 &&
                ad?.prof?.points <= 5000 &&
                "C'est mon grand ami"}
              {ad?.prof?.points > 5000 &&
                ad?.prof?.points <= 10000 &&
                "C'est mon vieil ami"}
              {ad?.prof?.points > 10000 && "Tu es non meilleur ami"}
            </span>

            <div className={s.rate} style={{ lineHeight: "20px" }} onClick={() => {
              dispatch(setSeeAvis(true));
              handleProfileClick();
            }}>
              <span className={s.rateNumber}>
                {ad?.teacher?.rate || 0}
                <img src={StartIcon} style={{ position: "relative", top: "-2px", marginLeft: "5px", marginRight: "5px" }} />
                {`(${ad?.teacher?.rateNum || 0} avis)`}
              </span>
            </div>

            <div style={{ color: "#707070", lineHeight: "10px" }}>
              {!isInstaProf && (
                <>
                  <span>
                    Je parle
                  </span>{" "}
                  {ad?.language?.includes("en") && (
                    <>
                      <img src={EnglishIcon} alt="" />{" "}
                    </>
                  )}
                  {ad?.language?.includes("fr") && (
                    <img src={FrenchIcon} alt="" />
                  )}
                </>
              )}

              {isInstaProf && (
                <Fragment>
                  <span>
                    Je parle
                  </span>{" "}
                  {ad?.prof?.langues?.includes("en") && (
                    <>
                      <img src={EnglishIcon} alt="" />{" "}
                    </>
                  )}
                  {ad?.prof?.langues?.includes("fr") && (
                    <img src={FrenchIcon} alt="" />
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </div>
        <div className={s.__description}>
          <Latex>
            {ad?.teacher?.career
              ? ad?.teacher?.career?.substring(0, 300)?.concat("...")
              : "Aucune description disponible"}
          </Latex>
        </div>
        <div className={s.matiers}>
          {handleClearTopic(ad?.topic)?.map((item: any) => (
            <div className={s.__tag__topic}>
              <IconTopic data={item} />
              {item?.title?.substring(0, 4)}
            </div>
          ))}
        </div>
        <div className={s.levelGroup}>
          {handleClearNiveau(ad?.teacher?.nivMat)?.map((niv: any) => {
            return (
              (niv?.niveau?.levelGroup?.includes("Primaire") && (
                <>
                  <span>Primaire </span> <span>- </span>
                </>
              )) ||
              (niv?.niveau?.levelGroup?.includes("Collège") && (
                <>
                  <span>Collège </span>
                  <span>- </span>
                </>
              )) ||
              (niv?.niveau?.levelGroup?.includes("Lycée") && (
                <>
                  <span>Lycée </span>
                  <span>- </span>
                </>
              )) ||
              (niv?.niveau?.levelGroup?.includes("Prépa") && (
                <>
                  <span>Prépa </span>
                  <span>- </span>
                </>
              ))
            );
          })}
          {ad?.prof?.nivMat?.length <= 0 && (
            <>
              <span>Non renseigné</span>
              <span>-</span>
            </>
          )}
        </div>
        <div className={s.tarif}>
          <span>Mes cours à partir de</span>{" "}
          {isInstaProf ? ad?.studentCost : ad?.minPrice}€/séance
        </div>
        <div className={s.rowButtons}>
          {PlanningIsVisble && (
            <Button className={s.newPlanningButton} onClick={onShowDrawerCalendarProf}>
              <img
                src={PlanningButtonIcon}
                alt=""
                style={{
                  position: "relative",
                  top: "-1px",
                  marginRight: "5px",
                }}
              />
              Mon Planning
            </Button>
          )}

          {isInstaProf && (
            <Popconfirm
              placement="top"
              title={"Voulez-vous accepter ce prof ?"}
              onConfirm={() => handleStudentAcceptTeacher(ad)}
              okText="Yes"
              cancelText="No"
            >
              <button onClick={() => setCurrentProfAccepted(ad)}>
                Accepter
              </button>
            </Popconfirm>
          )}

          {!isInstaProf && (
            <Button
              onClick={() => {
                setIsBtnInstaProf(true);
                showDrawerSearchInstaProf();
              }}
              className={s.newInstaButton}
            >
              <img
                src={InstaIcon}
                alt=""
                style={{ height: "20px", marginRight: "5px" }}
              />
              InstaProf
            </Button>
          )}

          {user?._id && (
            <Button
              onClick={() => {
                isInstaProf
                  ? handleDisplayTeacher(ad?.teacher?._id)
                  : handleDisplayTeacherMSG(ad?.teacher?._id);
              }}
              className={s.newMessageButton}
            >
              <img src={MessageIcon} height={14} />
            </Button>
          )}
        </div>
      </div>
    </React.Fragment >
  );
};

export default CardAdsTeacher;
