import AppResult from "components/appResults";
import Grid from "components/libs/grid";
import React from "react";
import style from "../assets/style.module.css";
import Card from "./Card";

interface CardMenuInterface {
  data: any;
  handler: any;
  someClasseDataNeeded: any;
  setDrawerConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  setTempsChallenge: React.Dispatch<React.SetStateAction<number>>;
}
export const CardMenu: React.FC<CardMenuInterface> = (props) => {
  return (
    <div className={`main ${style.mainX}`}>
      <Grid className={style.grid}>
        {props.data.result.length !== 0 ? (
          props.data?.result.map((e: any) => (
            <Card
              setDrawerConfirm={props.setDrawerConfirm}
              setTempsChallenge={props.setTempsChallenge}
              data={e}
              someClasseDataNeeded={props.someClasseDataNeeded}
              handler={props.handler}
            />
          ))
        ) : (
          <AppResult
            status="info"
            title="Ouups, aucun quiz trouvé, merci de rechercher autre chose"
            isMobile={false}
          />
        )}
      </Grid>
    </div>
  );
};

export default CardMenu;
