import { SearchOutlined } from "@ant-design/icons";
import { Avatar, Button, Image, Skeleton, Tabs, message } from "antd";
import {
  StudentJoinClass,
  findAll,
} from "api/services/studentJoinClass/studentJoinClass.services";
import { Field, Form, Formik } from "formik";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AppScreen from "screens/teacher/ScreenWithSidebar";
import { SudentJoinClassSchema } from "validations";

//!STYLE
import Breadcrumbs from "components/breadcrumbs";
import s from "./assets/myClasses.module.css";

//!IMAGE
import { StatusCode, calculateTimeDifference, isToday } from "common/enums";
import Flex from "components/libs/flex";
import * as appRoutes from "config/routes.config";
import Camera from "../../../assets/images/app/camera.svg";
import ClockIcon from "../../../assets/images/app/clock_icon.svg";
import CoursesIcons from "../../../assets/images/app/Cours.svg";
import EllipseTeacherProfileFour from "../../../assets/images/app/ellipse_teacher_profile4.png";
import EllipseTeacherProfileFive from "../../../assets/images/app/ellipse_teacher_profile5.png";
import EmptyIcon from "../../../assets/images/app/empty_icon.svg";
import StarIcon from "../../../assets/images/app/start.svg";
import ClassesIcons from "../../../assets/images/icons/teacher_classes.svg";
import CardTeacher from "../components/cardTeacher";
import Key from "./assets/key.svg";

import AppDrawer from "components/appDrawer";
import { AppNotification } from "components/appNotification";
import AppResult from "components/appResults";
import CertificationBadge from "components/certificationBadge";
import AppButton from "components/libs/button";
import H1 from "components/libs/title";
import Matiers from "components/matiers";
import { useNavigate } from "react-router-dom";
import { useGetHistoriqueStudentQuery } from "store/services/teacher/classes.services";


interface CoursesRoomProps {
  course?: object | any;
}

const CoursesRoom: FC<CoursesRoomProps> = ({ course }) => {

  const auth = useSelector((state) => state.auth);

  return (
    <div className={s.classeCard} style={{ cursor: "pointer" }}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", paddingLeft: "20px", paddingRight: "20px" }}>
        <div className={s.avatarNameBox}>
          <Avatar.Group
            maxCount={3}
            maxStyle={{
              color: "#f56a00",
              backgroundColor: "#fde3cf",
            }}
            style={{ marginRight: "5px" }}
          >
            <Avatar
              size={45}
              style={{ backgroundColor: "#FFF2E0", boxShadow: "0px 3px 6px #00000029" }}
              src={course?.profId?.profile}
            />
          </Avatar.Group>
          <div style={{ fontSize: "13px", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", textTransform: "capitalize" }}>{course?.profId?.firstname + " " + course?.profId?.lastname} {course?.profId?.certified && <CertificationBadge />}</div>
            <span style={{ color: "#707070" }}>{course?.profId?.rateCours} <img src={StarIcon} height={10}></img> ({course?.profId?.reservationsRateNum || 0} avis)</span>
          </div>
        </div>

        <div className={s.detailsBox}>
          <div className={s.timeBox}>
            <img src={ClockIcon} height={15} style={{ marginRight: "10px" }}></img>
            <div className={s.times}>
              <span className={s.time}>De {course?.range[0]} à {course?.range[course?.range?.length - 1]}</span>
              <span className={s.timeDescription}>Séance de “{calculateTimeDifference(course?.range[0], course?.range[course?.range.length - 1])}”</span>
            </div>
          </div>
          <Matiers
            cours={course?.topic?.title.slice(0, 4) || "null"}
            level={course?.level?.title || "null"}
          />
          <div className={s.amountBox}>
            Séance réservée pour
            <div className={s.priceBox}>{course?.byCredits === "oui" ? "1 Crédit" : course?.price + " €"}</div>
          </div>
        </div>

        <a href={course?.studentLink} target="_blank">
          <Button className={s.meetButton} icon={<img src={Camera} height={10} style={{ marginRight: "10px" }} />}>
            Lien vidéoconf
          </Button>
        </a>
      </div>
    </div>
  );
};

const { TabPane } = Tabs;

const MyClasses: FC = () => {
  //!HOOK
  const { user } = useSelector((state) => state.auth);
  const { data, isLoading, isError, refetch } = useGetHistoriqueStudentQuery(
    user?._id
  );

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(appRoutes.STUDENT_SEARCH_BEST_TEACHER);
  }

  //!STATE
  const [drawer, setDrawer] = useState<boolean>(false);
  const [teachers, setTeachers] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentTab, setCurrentTab] = useState<any>("1");

  const [courses, setCourses] = useState<any>([]);

  //!FUNCTION
  const showDrawer = () => setDrawer(true);
  const onClose = () => setDrawer(false);

  const getTeacher = useCallback(async () => {
    setLoading(true);
    try {
      const response = await findAll();
      if (response) {
        setTeachers(response.data);
      }
    } catch (error: any) {
      message.error("Error");
    } finally {
      setLoading(false);
    }
  }, []);

  const joinClass = useCallback(async (values: any) => {
    values?.code.toLowerCase();
    try {
      if (values?.code && user?._id) {
        const response = await StudentJoinClass(values?.code, user?._id);
        if (response.statusCode === StatusCode.CREATED) {
          getTeacher();
          setDrawer(false);
          AppNotification(
            "Vous avez rejoint la classe avec succes",
            "AlbertVeryHappy"
          );
        }
      }
    } catch (error: any) {
      console.log(error.response.status, "er");
      if (error.response.status === 409) {
        message.warning("Vous avez déjà rejoint cette classe !");
      } else if (error.response.status === 404) {
        AppNotification("Le code entrer n'est pas valide", "AlbertSad");
      } else {
        message.error("Une erreur inconnue a été survenue");
      }
    }
  }, []);

  useEffect(() => {
    getTeacher();
  }, [getTeacher]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (data?.data) {
      const sortedCourses = [...data.data].sort(
        (a: any, b: any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      );

      const groupedByDate = sortedCourses.reduce((acc: any, course: any) => {
        const date = new Date(course.startDate).toISOString().split("T")[0];
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(course);
        return acc;
      }, {});

      setCourses(groupedByDate);
    }
  }, [data]);

  return (
    <React.Fragment>
      <Breadcrumbs isRooms={true} />
      <AppScreen>
        <img src={EllipseTeacherProfileFour} height={106} style={{ position: "absolute", top: "25%", left: "0px", zIndex: "-1" }}></img>
        <img src={EllipseTeacherProfileFive} height={54} style={{ position: "absolute", top: "35%", left: "94%", zIndex: "-1" }}></img>
        <div style={{ marginBottom: "80px" }}>
          <Tabs
            tabBarStyle={{ borderRadius: "0px", overflow: "hidden", border: "0px solid transparent !important", backgroundColor: "transparent !important" }}
            activeKey={currentTab}
            onChange={(key) => setCurrentTab(key)}
            type="line"
          >
            <TabPane
              tab={
                <div className={currentTab == "1" ? s.tabTitleBoxActive : s.tabTitleBox}>
                  <img src={CoursesIcons} height={18} style={{ marginRight: "10px" }}></img> Mes cours particuliers
                </div>
              }
              key="1"
            >
              <div className={s.informationBox} style={{ justifyContent: data?.data?.length === 0 ? "center" : "flex-start", alignItems: "center" }}>

                {isLoading && (
                  <React.Fragment>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </React.Fragment>
                )}

                {!isLoading && data?.data?.length > 0 ? (
                  courses && Object.keys(courses)?.map((date) => {
                    return (
                      <>
                        <div key={date} style={{ marginTop: "10px" }}>
                          <div className={s.dateBox}>
                            {new Intl.DateTimeFormat("fr-FR", {
                              weekday: "long",
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            }).format(new Date(date))}
                            {isToday(date) && <div className={s.todayBox}>Aujourd’hui</div>}
                            <div className={s.horizontalLine}></div>
                          </div>
                          {courses[date].map((course: any) => (
                            <div key={course.id}>
                              <CoursesRoom course={course} />
                            </div>
                          ))}
                        </div>
                      </>
                    );
                  })
                ) : (
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    Aucune réservation
                    <img src={EmptyIcon} height={120} style={{ marginTop: "20px" }}></img>
                    <Button className={s.newButton} icon={<SearchOutlined />} onClick={handleClick} style={{ marginTop: "20px" }}>
                      Trouver un prof
                    </Button>
                  </div>
                )}
              </div>
            </TabPane>
            <TabPane
              tab={
                <div className={currentTab == "2" ? s.tabTitleBoxActive : s.tabTitleBox}>
                  <img src={ClassesIcons} height={18} style={{ marginRight: "10px" }}></img> Mes classes
                </div>
              }
              key="2"
            >
              <div className={s.classesBox}>
                <div className={s.newClassBox}>
                  <Button className={s.newButton} onClick={showDrawer}>
                    Rejoindre une classe
                  </Button>
                </div>

                <div style={{ marginTop: "30px", overflowY: "scroll", scrollbarWidth: "thin" }}>
                  {loading && (
                    <React.Fragment>
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                    </React.Fragment>
                  )}

                  {!loading && teachers.length > 0 && (
                    <Flex>
                      <React.Fragment>
                        {teachers?.map((teacher: any) => (
                          <CardTeacher teacher={teacher} />
                        ))}
                      </React.Fragment>
                    </Flex>
                  )}

                  {!loading && teachers.length <= 0 && (
                    <div className={s.__result}>
                      <AppResult
                        status="info"
                        title="Aucune classe"
                      />
                    </div>
                  )}
                </div>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </AppScreen>
      <AppDrawer
        title={false}
        width={"60%"}
        onClose={onClose}
        visible={drawer}
      >
        <div className={s.__drawer__body}>
          <H1>Rejoindre une classe</H1>

          <p>
            Pour participer à une classe virtuelle, entre le code que ton professeur t'a fourni ci-dessous. Une fois le code validé, tu seras ajouté à la classe et pourras accéder à tous les matériaux partagés, participer à des quiz, et voir ton classement parmi tes camarades.
          </p>
          <p>
            C'est une excellente manière de renforcer ton apprentissage et de te mesurer à tes pairs en toute convivialité.{" "}
          </p>

          <Formik
            initialValues={{
              code: "",
            }}
            validationSchema={SudentJoinClassSchema}
            onSubmit={joinClass}
          >
            {({ errors, touched }) => (
              <Form style={{ position: "relative" }}>
                <div className={s.__join__class}>
                  <Field
                    name="code"
                    type="text"
                    placeholder="Insérer le code de la classe "
                  />
                  <Image
                    src={Key}
                    preview={false}
                    width={15}
                    onClick={onClose}
                    className={s.__key}
                  />
                </div>
                {errors.code && touched.code ? (
                  <div className={s.__error}>
                    <span>{errors.code}</span>
                  </div>
                ) : null}
                <AppButton
                  loading={loading}
                  htmlTypeSubmit={true}
                  className={s.__drawer__btn}
                >
                  Rejoindre la classe
                </AppButton>
              </Form>
            )}
          </Formik>
        </div>
      </AppDrawer>
    </React.Fragment>
  );
};

export default MyClasses;
