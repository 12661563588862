import React from 'react';
import s from "./assets/step.module.css";

interface FormStepProps {
    index: number;
    title: string;
    current: number;
    onClick: () => void;
}

const FormStep: React.FC<FormStepProps> = ({ index, title, current, onClick }) => {
    return (
        <div className={s.formstep} style={{ width: "auto" }} onClick={onClick}>
            <div className={current === index ? s.step_number_active : s.step_number}>{index}</div>
            <div className={current === index ? s.step_title_active : s.step_title}>{title}</div>
        </div>
    );
};

export default FormStep;