import FadeInUpOnScroll from "components/FadeInUp";
import { useState } from "react";
import ellipse_testimony from "../../../../assets/images/app/home/ellipse_testimony.svg";
import ellipse_testimony_one from "../../../../assets/images/app/home/ellipse_testimony_one.svg";
import ellipse_testimony_two from "../../../../assets/images/app/home/ellipse_testimony_two.svg";
import ellipse_valeur from "../../../../assets/images/app/home/ellipse_valeur.svg";
import teacher_testimony_one from "../../../../assets/images/app/teachers/teacher_testimony_one.png";
import testimony_teacher_five from "../../../../assets/images/app/teachers/testimony_teacher_five.png";
import testimony_teacher_four from "../../../../assets/images/app/teachers/testimony_teacher_four.png";
import testimony_teacher_one from "../../../../assets/images/app/teachers/testimony_teacher_one.png";
import testimony_teacher_six from "../../../../assets/images/app/teachers/testimony_teacher_six.png";
import testimony_teacher_three from "../../../../assets/images/app/teachers/testimony_teacher_three.png";
import testimony_teacher_two from "../../../../assets/images/app/teachers/testimony_teacher_two.png";
import st from "../../../app/common.module.css";
import s from "./style.module.css";

const ParentsTestimonies = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.mainContainer}>
            <div className={s.testimoniesBox}>
                <FadeInUpOnScroll>
                    {isMobile ? <h1 className={s.title}>Pourquoi<br />d’autres parents<br />comme vous aiment<br />Mon Ami Albert ?</h1> : <h1 className={s.title}>Pourquoi d’autres parents comme vous<br />aiment Mon Ami Albert ?</h1>}
                </FadeInUpOnScroll>
                <img src={ellipse_testimony} className={s.ellipseTestimonies}></img>
                {isMobile ? <div className={s.testimonies}>
                    <img src={testimony_teacher_one} className={`${st.floatAnimation} ${s.testimonyOneImage}`}></img>
                    <img src={testimony_teacher_two} className={`${st.floatAnimation} ${st.floatAnimationDelayed1} ${s.testimonyTwoImage}`}></img>
                    <img src={ellipse_valeur} className={s.ellipseOne}></img>
                    <img src={testimony_teacher_three} className={`${st.floatAnimation} ${st.floatAnimationDelayed2} ${s.testimonyThreeImage}`}></img>
                    <div className={s.currentTestimony}>
                        <img src={teacher_testimony_one} className={s.currentTestimonyImage}></img>
                        <div className={s.currentTestimonyMessage}>Mon Ami Albert a été un véritable atout pour la réussite scolaire de mes enfants. Grâce à cette plateforme, ils ont pu bénéficier de cours adaptés à leurs besoins et d'un suivi personnalisé par des enseignants compétents. J'apprécie particulièrement la facilité de communication avec les professeurs et le soutien qu'ils apportent. En tant que parent, je me sens rassurée de voir mes enfants progresser et gagner en confiance. Je recommande vivement Mon Ami Albert à toutes les familles !</div>
                        <div className={s.currentTestimonyName}>Sophie Martin</div>
                        <div className={s.currentTestimonyUser}>Parent</div>
                        <img src={ellipse_testimony_one} className={s.ellipseTwo}></img>
                    </div>
                    <img src={testimony_teacher_four} className={`${st.floatAnimation} ${s.testimonyFourImage}`}></img>
                    <img src={testimony_teacher_five} className={`${st.floatAnimation} ${st.floatAnimationDelayed2} ${s.testimonyFiveImage}`}></img>
                    <img src={ellipse_testimony_two} className={s.ellipseThree}></img>
                    <img src={testimony_teacher_six} className={`${st.floatAnimation} ${st.floatAnimationDelayed1} ${s.testimonySixImage}`}></img>
                </div> : <div className={s.testimonies}>
                    <div style={{ width: "20%" }}>
                        <img src={testimony_teacher_one} className={`${st.floatAnimation} ${s.testimonyOneImage}`}></img>
                        <img src={testimony_teacher_two} className={`${st.floatAnimation} ${st.floatAnimationDelayed1} ${s.testimonyTwoImage}`}></img>
                        <img src={ellipse_valeur} className={s.ellipseOne}></img>
                        <img src={testimony_teacher_three} className={`${st.floatAnimation} ${st.floatAnimationDelayed2} ${s.testimonyThreeImage}`}></img>
                    </div>
                    <div className={s.currentTestimony}>
                        <img src={teacher_testimony_one} className={s.currentTestimonyImage}></img>
                        <div className={s.currentTestimonyMessage}>Mon Ami Albert a été un véritable atout pour la réussite scolaire de mes enfants. Grâce à cette plateforme, ils ont pu bénéficier de cours adaptés à leurs besoins et d'un suivi personnalisé par des enseignants compétents. J'apprécie particulièrement la facilité de communication avec les professeurs et le soutien qu'ils apportent. En tant que parent, je me sens rassurée de voir mes enfants progresser et gagner en confiance. Je recommande vivement Mon Ami Albert à toutes les familles !</div>
                        <div className={s.currentTestimonyName}>Sophie Martin</div>
                        <div className={s.currentTestimonyUser}>Parent</div>
                        <img src={ellipse_testimony_one} className={s.ellipseTwo}></img>
                    </div>
                    <div style={{ width: "20%" }}>
                        <img src={testimony_teacher_four} className={`${st.floatAnimation} ${s.testimonyFourImage}`}></img>
                        <img src={testimony_teacher_five} className={`${st.floatAnimation} ${st.floatAnimationDelayed2} ${s.testimonyFiveImage}`}></img>
                        <img src={ellipse_testimony_two} className={s.ellipseThree}></img>
                        <img src={testimony_teacher_six} className={`${st.floatAnimation} ${st.floatAnimationDelayed1} ${s.testimonySixImage}`}></img>
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default ParentsTestimonies;