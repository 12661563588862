import React, { useEffect } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

// Define the type for each data point
interface PieData {
    name: string;
    value: number;
}

interface CercleChartProps {
    pieData: PieData[];
}

const COLORS = ["#80B1B2", "#B0E0E1", "#707070", "#FF8042"];

const RADIAN = Math.PI / 180;

// Function to render customized labels
const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
}: {
    cx: number;
    cy: number;
    midAngle: number;
    innerRadius: number;
    outerRadius: number;
    percent: number;
    index: number;
}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor="central"
            dominantBaseline="central"
        >
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

// Main pie chart component
const ChallengeChart: React.FC<CercleChartProps> = ({ pieData }) => {

    useEffect(() => {
        console.log(pieData);
    }, [pieData]);


    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart width={600} height={600}>
                <Pie
                    data={pieData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                >
                    {pieData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
};

export default ChallengeChart;
