import { Drawer } from 'antd';
import 'antd/dist/antd.css';
import AppLatex from 'components/appLatex';
import React, { useState } from 'react';
import ArrowBack from "../../../../assets/images/app/arrow_back.svg";
import s from "./style.module.css";

type Props = {
    data: any,
    visible: boolean,
    onClose: () => void,
    userImage: any,
    userName: any,
    userLastname: any,
}

const LeftDrawer: React.FC<Props> = ({ data, visible, onClose, userImage, userName, userLastname }) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <Drawer
            className="drawer"
            title={<div style={{ fontSize: "20px", fontFamily: "Poppins", fontWeight: "600", color: "#393E3E", backgroundColor: "transparent" }}>Centre d’intérêt</div>}
            placement="left"
            closable={true}
            onClose={onClose}
            visible={visible}
            width={isMobile ? "100%" : "40%"}
            headerStyle={{ marginTop: "40px", marginLeft: "14%", border: "none", backgroundColor: "transparent" }}
            closeIcon={
                <img
                    src={ArrowBack}
                    height={18}
                    style={{}}
                ></img>
            }
            drawerStyle={{ backgroundColor: "transparent", borderTopRightRadius: "30px", borderBottomRightRadius: "30px", boxShadow: "-3px 3px 34px #39393929", backdropFilter: "blur(24px) brightness(100%)" }}
        >
            <div className={s.drawerBox}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginBottom: "30px" }}>
                    <div style={{ borderRadius: "50%" }}>
                        <img src={userImage} height={90} width={90} style={{ borderRadius: "50%" }}></img>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", marginLeft: "20px", width: "200px" }}>
                        <div style={{ fontSize: "16px", color: "#8AB0B1", fontWeight: "600" }}>{userName} {userLastname}</div>
                        {/* Title */}
                        {data !== "" && <div
                            style={{ textAlign: "left", marginTop: "0px", fontSize: "12px", color: "#707070" }}
                            dangerouslySetInnerHTML={{ __html: data?.split("<p>")[1].split("</p>")[0] }}
                        />}
                    </div>
                </div>

                <AppLatex content={data?.split("<p>")[2]?.split("</p>")[0]} />
                <AppLatex content={data?.split("<p>")[3]?.split("</p>")[0]} />
                <AppLatex content={data?.split("<p>")[4]?.split("</p>")[0]} />
            </div>
        </Drawer>
    );

};

export default LeftDrawer;
