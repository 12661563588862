import { CheckCircleOutlined } from "@ant-design/icons";
import { Avatar, Button, Skeleton, Tag, Typography } from "antd";
import { calculateTimeDifference, isToday } from "common/enums";
import Matiers from "components/matiers";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useGetHistoriqueQuery } from "store/services/teacher/classes.services";
import ClockIcon from "../../../assets/images/app/clock_icon.svg";
import EllipseTeacherProfileFour from "../../../assets/images/app/ellipse_teacher_profile4.png";
import EllipseTeacherProfileFive from "../../../assets/images/app/ellipse_teacher_profile5.png";
import EmptyIcon from "../../../assets/images/app/empty_icon.svg";
import s from "./assets/calendar.module.css";

interface CoursesRoomProps {
  course?: object | any;
}

const CoursesRoom: FC<CoursesRoomProps> = ({ course }) => {

  const auth = useSelector((state) => state.auth);

  return (
    <div className={s.classeCard} style={{ cursor: "pointer" }}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", paddingLeft: "20px", paddingRight: "20px" }}>
        <div className={s.avatarNameBox}>
          <Avatar.Group
            maxCount={3}
            maxStyle={{
              color: "#f56a00",
              backgroundColor: "#fde3cf",
            }}
            style={{ marginRight: "5px" }}
          >
            <Avatar
              size={45}
              style={{ backgroundColor: "#FFF2E0", boxShadow: "0px 3px 6px #00000029" }}
              src={course?.studId?.profile}
            />
            {course?.typeCreneaux !== "individuel" && <Avatar
              size={45}
              style={{ backgroundColor: "#FFF2E0", boxShadow: "0px 3px 6px #00000029", position: "relative", left: "-25px" }}
              src={course?.studId?.profile}
            />}
            {course?.typeCreneaux !== "individuel" && <Avatar
              size={45}
              style={{ backgroundColor: "#FFF2E0", boxShadow: "0px 3px 6px #00000029", position: "relative", left: "-50px" }}
              src={course?.studId?.profile}
            />}
          </Avatar.Group>
          <div style={{ position: "relative", left: course?.typeCreneaux !== "individuel" ? "-40px" : "15px", fontSize: "13px", textTransform: "capitalize" }}>{course?.typeCreneaux !== "individuel" ? "Cours collectif" : course?.studId?.firstname + " " + course?.studId?.lastname}</div>
        </div>

        <div className={s.detailsBox}>
          <div className={s.timeBox}>
            <img src={ClockIcon} height={15} style={{ marginRight: "10px" }}></img>
            <div className={s.times}>
              <span className={s.time}>De {course?.range[0]} à {course?.range[course?.range?.length - 1]}</span>
              <span className={s.timeDescription}>Séance de “{calculateTimeDifference(course?.range[0], course?.range[course?.range.length - 1])}”</span>
            </div>
          </div>
          <Matiers
            cours={course?.topic?.title.slice(0, 4) || "null"}
            level={course?.level?.title || "null"}
          />
          <div className={s.amountBox}>
            Séance réservée pour
            <div className={s.priceBox}>{course?.byCredits === "oui" ? "1 Crédit" : course?.price + " €"}</div>
          </div>
        </div>

        {false ? (
          <Tag
            icon={<CheckCircleOutlined />}
            color="success"
          >
            success
          </Tag>
        ) : (
          <a href={course?.profLink} target="_blank">
            <Button className={s.meetButton}>
              Rejoindre le meet
            </Button>
          </a>
        )}
      </div>
    </div>
  );
};

const { Text } = Typography;

const CalendarOfReservationTeacher = () => {

  const { user } = useSelector((state) => state.auth);
  const { data, isLoading, isError, refetch } = useGetHistoriqueQuery(
    user?._id
  );
  const [courses, setCourses] = useState<any>([]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (data?.data) {
      const sortedCourses = [...data?.data].sort(
        (a: any, b: any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      );

      const groupedByDate = sortedCourses.reduce((acc: any, course: any) => {
        const date = new Date(course.startDate).toISOString().split("T")[0];
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(course);
        return acc;
      }, {});

      setCourses(groupedByDate);
    }
  }, [data]);

  return (
    <>
      <img src={EllipseTeacherProfileFour} height={106} style={{ position: "absolute", top: "25%", left: "0px", zIndex: "-1" }}></img>
      <img src={EllipseTeacherProfileFive} height={54} style={{ position: "absolute", top: "35%", left: "94%", zIndex: "-1" }}></img>
      {isLoading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <div className={s.calendar}>
          {data?.data?.length > 0 ? (
            courses && Object.keys(courses)?.map((date) => {
              return (
                <>
                  <div key={date} style={{ marginTop: "10px" }}>
                    <div className={s.dateBox}>
                      {new Intl.DateTimeFormat("fr-FR", {
                        weekday: "long",
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      }).format(new Date(date))}
                      {isToday(date) && <div className={s.todayBox}>Aujourd’hui</div>}
                      <div className={s.horizontalLine}></div>
                    </div>
                    {courses[date].map((course: any) => (
                      <div key={course.id}>
                        <CoursesRoom course={course} />
                      </div>
                    ))}
                  </div>
                </>
              );
            })
          ) : (
            <div className={s.emptyClass}>
              Aucune réservation
              <img src={EmptyIcon} height={120} style={{ marginTop: "20px" }}></img>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CalendarOfReservationTeacher;
