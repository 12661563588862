import { Table, Transfer, TransferProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TransferItem } from 'antd/es/transfer';
import React from 'react';

interface TableTransferProps extends TransferProps<TransferItem> {
    leftColumns: ColumnsType<TransferItem>;
    rightColumns: ColumnsType<TransferItem>;
}

const TableTransfer: React.FC<TableTransferProps> = ({
    leftColumns,
    rightColumns,
    ...restProps
}) => (
    <Transfer {...restProps} showSelectAll={false}>
        {({ direction, filteredItems, onItemSelectAll, onItemSelect, selectedKeys: listSelectedKeys, disabled }) => {
            const columns = direction === 'left' ? leftColumns : rightColumns;

            const rowSelection = {
                onSelectAll(selected: boolean, selectedRows: TransferItem[]) {
                    const keys = selectedRows.map(({ key }) => key as string);
                    onItemSelectAll(keys, selected);
                },
                onSelect({ key }: TransferItem, selected: boolean) {
                    onItemSelect(key as string, selected);
                },
                selectedRowKeys: listSelectedKeys,
            };

            return (
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={filteredItems}
                    size="large"
                    pagination={false}
                    style={{ pointerEvents: disabled ? 'none' : undefined }}
                    onRow={({ key }) => ({
                        onClick: () => {
                            onItemSelect(key as string, !listSelectedKeys.includes(key as string));
                        },
                    })}
                />
            );
        }}
    </Transfer>
);

export default TableTransfer;
