import React, { FC } from "react";
import AppDrawer from "components/appDrawer";
import { useSelector, useDispatch } from "react-redux";
import { setLastRouteActionAuth, setUserConnect } from "store/auth/authSlice";
import AppResult from "components/appResults";
import * as appRoutes from "config/routes.config";
import AppButton from "components/libs/button";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {}

const UserConnect: FC<Props> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { userConnect } = useSelector((state) => state.auth);
  return (
    <AppDrawer
      visible={userConnect}
      onClose={() => dispatch(setUserConnect(false))}
    >
      <AppResult
        status="info"
        title="Connecte-toi pour découvrir des nouvelles fonctionnalités."
        extra={
          <AppButton
            onClick={() => {
              dispatch(setLastRouteActionAuth(location.pathname));
              dispatch(setUserConnect(false));
              navigate(appRoutes.LOGINS);
            }}
          >
            connexion
          </AppButton>
        }
      />
    </AppDrawer>
  );
};

export default UserConnect;
