import { Table } from 'antd';
import React from 'react';

type Props = {
    payments: any;
}

const PaymentTable: React.FC<Props> = ({ payments }) => {
    // Define columns for the table
    const columns = [
        {
            title: 'Date du cours',
            dataIndex: 'date',
            key: 'date',
            render: (text: string) => {
                const formattedDate = text.substring(8, 10) + '-' + text.substring(5, 7) + '-' + text.substring(0, 4) + ' ' + text.substring(11, 16);
                return formattedDate;
            }
        },
        {
            title: 'Professeur',
            dataIndex: ['prof', 'lastname'],
            key: 'professor',
            render: (_: any, record: { prof: { lastname: any; firstname: any; }; }) => `${record.prof.lastname}, ${record.prof.firstname}`
        },
        {
            title: 'Durée',
            dataIndex: 'time',
            key: 'time',
            render: (text: string) => {
                const hours = parseInt(text.split(' ')[0]);
                return `${hours}h`;
            }
        },
        {
            title: 'Coût',
            dataIndex: 'montant',
            key: 'montant',
            render: (amount: string) => {
                return `${amount} €`;
            }
        }
    ];

    return (
        <Table dataSource={payments} columns={columns} />
    );
};

export default PaymentTable;
