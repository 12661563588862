import Container from "components/container";
import { FC } from "react";

type Props = {
  className?: string;
};

const AppScreen: FC<Props> = ({ className, children }) => {
  return (
    <div className={className}>
      <Container>{children}</Container>
    </div>
  );
};

export default AppScreen;
